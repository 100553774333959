<template>
  <el-container class="map" v-bind:style="mapStyle">
    <el-container>
      <el-main class="map-element" :id="mapId"></el-main>
      <el-footer class="info-element">
        <div ref="cursor_coordinates" class="cursor-coordinates">
          <el-input size="mini" v-model="cursorX"></el-input>&nbsp;
          <el-input size="mini" v-model="cursorY"></el-input>&nbsp;
          <coordinate-system-combobox :value="sridValue"></coordinate-system-combobox>&nbsp;
          <el-tooltip class="item" effect="dark" content="Перейти на карте" placement="top">  
            <el-button plain size="mini" icon="el-icon-location-outline" @click="goTo"/>
          </el-tooltip>  
        </div>
      </el-footer>
    </el-container>    
  </el-container>
</template>

<script>
import CoordinateSystemCombobox from '@/services/MapEditor/infrastructure/components/CoordinateSystemCombobox'
import MapManager from '@bingo_soft/mapmanager'
import EventType from '@bingo_soft/mapmanager/src/Domain/Model/EventHandlerCollection/EventType'
import InteractionType from '@bingo_soft/mapmanager/src/Domain/Model/Interaction/InteractionType'

export default {
  name: 'MapPanel',
  props: ['mapId'],
  inject: ['getEventBus', 'getQueryBus'],
  components: {
    CoordinateSystemCombobox
  },
  data () {
    return {
      guid: null,
      mapInstance: null,
      cursorX: null,
      cursorY: null,
      sridValue: 3857,
      mapStyle: {
        width: null,
        height: "calc(100vh - 161px)"
      }
    }
  },
  methods: {
    updateMapSize(mode, callback) {
      if (mode == "mini") {
        this.mapStyle.width = "0px";
      } else {
        this.mapStyle.width = null;
      }
      setTimeout(() => {        
        this.mapInstance.updateSize();
        if (typeof callback == "function") {
          callback();
        }
      }, 250);
    },
    goTo() {
      MapManager.setCenter(this.mapInstance, {
        x: this.cursorX,
        y: this.cursorY,
        declared_coordinate_system_id: this.sridValue
      });
    },
    setMapCoordinatesInteraction(sridValue) {
      this.sridValue = sridValue;
      MapManager.clearInteractions(this.mapInstance,[InteractionType.MapCoordinates]);
      MapManager.setMapCoordinatesInteraction(this.mapInstance, {
        "type": EventType.PointerMove,
        "map_coordinates_callback": (coordinates, mapProjection) => {
          this.cursorX = coordinates[0];
          this.cursorY = coordinates[1];
        },
        "declared_coordinate_system_id": this.sridValue
      });
    },
  },
  mounted() {
    this.mapInstance = MapManager.createMap(this.mapId, {
      "base_layer": "osm",
      "controls": ["scaleline"],
      "source_change_callback": () => {
        this.getEventBus().$emit('mapSourceChanged', this.mapInstance); 
      }
    });
    this.setMapCoordinatesInteraction();
    this.updateMapSize();
    this.getEventBus().$on('updateMapSize', this.updateMapSize);
    this.getEventBus().$on('coordinateSystemChanged', this.setMapCoordinatesInteraction);
    this.getEventBus().$emit('mapInstanceCreated', this.mapInstance, this.mapId);
  }
}
</script>


<style scoped>
    input {
        height: 35px;
    }
    .cursor-coordinates {
        padding: 5px;
    }
    .el-input {
        width: 100px;
    }
    .el-select {
        width: 200px;
        margin-right: 10px;
    }
</style>