import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import BindServiceQueryRepositoryInterface from '@/services/ReleaseEditor/domain/repository/BindServiceQueryRepositoryInterface'
import store from '@/store'
import { GetBindServices } from '@/services/ReleaseEditor/infrastructure/store/modules/BindService/action-types'

export default class BindServiceQueryRepository extends AbstractQueryRepository implements BindServiceQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetBindServices(payload));
    }
}