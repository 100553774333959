<script type="ts">
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import StringInput from '@/core/infrastructure/components/StringInput.vue'
//import ElInput from 'element-ui/packages/input/src/input.vue'

export default {
  name: "RouteProperties",
  inject: {
    getEventBus: {
      default: () => () => {
        return {
          $emit: () => {},
          $on: () => {}
        }
      }
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }, 
    dto: Object
  },
  data() {
    return {   }
  },
  methods: {
    createProperties() {
      return this.createFields();
    },
    createFields() {
      let fields = [];
      this.items.forEach((field) => {
        let el;
        switch(field.type) {
          case 'varchar':
            el = this.createStringField(field);
            break;
          default:
            break;
        }
        fields.push(el);
      });
      return fields;
    },
    createStringField(field) {
      let initValueKey = this.getValueKey(field);
      let defaultValue = field[initValueKey]
      this.setProperty(field.id, defaultValue);
      return this.$createElement(ElFormItem, {
        props: {
          prop: field.id,
          label: this.$locale.databus.properties[field.name],   
        }
      },[
        this.$createElement(StringInput, {
          props: {
            value: defaultValue
          },
          on: {
            input: (val) => {
              this.setProperty(field.id, val);
            }
          }
        })
      ]);
    },
    getValueKey(field) {
      if (field.hasOwnProperty("value")) {
        return "value";
      }
      return "default_value";
    },
    setProperty(name, value) {
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          this.$set(this.dto.properties[i], 'value', value);
          break;
        }
      }
    },
    getProperty(name) {
      for (let i = 0; i < this.dto.properties.length; i += 1) {
        if (this.dto.properties[i].id == name) {
          return this.dto.properties[i].value;
        }
      }
      return null;
    }
  },
  mounted () {
    //this.getEventBus().$emit('routePropertiesMounted', this);
  },
  render (createElement) {
    return createElement("div", {
      class: {
        "property-set": true
      }
    }, this.createProperties());
  }
}

</script>