import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LoaderDTO } from '@/services/EtlEditor/domain/model/Loader'
import LoaderCreateCommand from '@/services/EtlEditor/application/command/LoaderCreateCommand'
import LoaderCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderCommandRepositoryInterface'

export default class LoaderCreateHandler implements CommandHandlerInterface {
    private repository: LoaderCommandRepositoryInterface;

    constructor (repository: LoaderCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LoaderCreateCommand): Promise<void> {
      let item: LoaderDTO = {
        name: command.getName(),
        loader_type_id: command.getLoaderTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
