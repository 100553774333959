<template>
  <div class="group_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.etl_editor_v2.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.alias" prop="alias">
        <el-input v-model="model.alias" :placeholder="$locale.etl_editor_v2.labels.alias" size="mini"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import GroupByGuidQuery from '@/services/EtlEditor/application/query/GroupByGuidQuery'
import Group from '@/services/EtlEditor/domain/model/Group'
import GroupCreateCommand from '@/services/EtlEditor/application/command/GroupCreateCommand'
import GroupUpdateCommand from '@/services/EtlEditor/application/command/GroupUpdateCommand'

export default {
  name: 'GroupForm',

  props: {
    value: Object
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    groupCreated () {
      return this.$store.getters['Group/getGroupLocation']
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    groupCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new GroupByGuidQuery(
            location.replace('/groups/', '')
          )
        ).then((response) => {
          if (response.guid) {
            this.$emit('input', response)
          }
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: this.$locale.etl_editor_v2.rules.name, trigger: 'blur' }
        ]
      },

      isLoaded: false
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let model = Group.create(this.value)

      if (this.isNew) {
        return new GroupCreateCommand(
          model.getName(),
          model.getAlias(),
          model.getParentTreeElementId()
        )
      }

      return new GroupUpdateCommand(
        model.getGuid(),
        model.getName(),
        model.getAlias()
      )
    }
  }
}
</script>

<style scoped></style>
