import BindServiceCommandRepositoryInterface from '@/services/ReleaseEditor/domain/repository/BindServiceCommandRepositoryInterface'
import { BindServiceDTO } from '@/services/ReleaseEditor/domain/model/BindService/BindService'
import store from '@/store'
import { AddBindService, DeleteBindService } from '@/services/ReleaseEditor/infrastructure/store/modules/BindService/action-types'

export default class BindServiceCommandRepository implements BindServiceCommandRepositoryInterface
{
    insert(item: BindServiceDTO): Promise<void>
    {
        return store.dispatch(new AddBindService(item));
    }

    delete(guid: string, payload: string[][]): Promise<void>
    {
        return store.dispatch(new DeleteBindService(guid, payload));
    }
}
