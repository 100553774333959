var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-popover',{attrs:{"trigger":"click","placement":"bottom","width":"380","popper-class":"notification-popper"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"el-dropdown-link",attrs:{"slot":"reference","title":_vm.$t('main.notification.notify')},slot:"reference"},[(_vm.numberUnreadMessages > 0)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.numberUnreadMessages,"max":9}},[_c('i',{staticClass:"el-icon-bell font-size-25"})]):_c('i',{staticClass:"el-icon-bell font-size-25"})],1),_c('div',{staticClass:"notification-popper__header"},[_c('el-badge',{staticClass:"item",attrs:{"is-dot":"","type":_vm.isConnected ? 'success' : 'danger'}},[_c('span',{staticClass:"notification-popper__title"},[_vm._v(_vm._s(_vm.$t('main.notification.notify')))])]),(_vm.numberUnreadMessages > 0)?_c('span',{staticClass:"notification-popper__readBtn el-icon-view",attrs:{"title":_vm.$t('main.notification.read_all')},on:{"click":function($event){return _vm.onReadMessages()}}}):_vm._e()],1),_c('div',{staticClass:"notification-list custom_scrollbar"},[_vm._l((_vm.messages),function(message){return _c('div',{key:message.id,class:{
        'notification-list-item': true,
        'is-interaction': !!message.notification.interaction_type,
        'is-unread': !message.read_date,
        'is-show': message.is_show
      },on:{"click":function($event){return _vm.onOpenCard(message)}}},[_c('div',{staticClass:"notification-list-item__avatar"},[(message.notification && message.notification.icon)?_c('span',{class:message.notification.icon}):_vm._e(),(message.author && message.author.avatar)?_c('el-avatar',{attrs:{"fit":"cover","size":"medium","src":_vm.getAvatarLink(message.author.avatar)}}):_vm._e()],1),_c('div',{staticClass:"notification-list-item__content"},[_c('div',{staticClass:"notification-list-item__title",domProps:{"innerHTML":_vm._s(message.title)}}),_c('div',{staticClass:"notification-list-item__message",domProps:{"innerHTML":_vm._s(message.content)}}),_c('div',{staticClass:"notification-list-item__date"},[_vm._v(" "+_vm._s(_vm.$moment(message.create_date).format('D MMM Y, H:mm'))+" ")]),_c('div',{staticClass:"notification-list-item__actions"},[_c('div',{staticClass:"notification-list-item__btn",on:{"click":function($event){$event.stopPropagation();return _vm.onShowMessage(message)}}},[_c('i',{class:{
                'el-icon-arrow-up': !message.is_show,
                'el-icon-arrow-down': message.is_show
              },attrs:{"title":_vm.$t('main.notification.show')}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!message.read_date),expression:"!message.read_date"}],staticClass:"notification-list-item__btn"},[_c('i',{staticClass:"el-icon-view",attrs:{"title":_vm.$t('main.notification.read_notify')},on:{"click":function($event){$event.stopPropagation();return _vm.onReadMessages(message)}}})])])])])}),(!_vm.isEnd)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"notification-more"},[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.loadMessages()}}},[_vm._v(" "+_vm._s(_vm.$t('main.button.more'))+" ")])],1):_vm._e()],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.cardLoading),expression:"cardLoading"}],staticClass:"notification-open-center"},[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.onOpenNotifyCenter()}}},[_vm._v(" "+_vm._s(_vm.$t('main.notification.go_to_notify'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }