/**
 * Универсальная функция асинхронной подгрузки JS скриптов
 *
 * @param src
 * @return {Promise<unknown>}
 */
export function loadScript (src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = () => {
      resolve()
    }
    script.src = src
    document.head.appendChild(script)
  })
}

/**
 * Функция асинхронной загрузки скриптов стимула
 *
 * @param $config
 * @return {Promise<void>}
 */
export async function loadScripts ($config) {
  await loadScript(`${$config.api}/stimulsoftreports/scripts/stimulsoft.reports.pack.js`)
  await loadScript(`${$config.api}/stimulsoftreports/scripts/stimulsoft.reports.maps.pack.js`)
  await loadScript(`${$config.api}/stimulsoftreports/scripts/stimulsoft.viewer.pack.js`)
  await loadScript(`${$config.api}/stimulsoftreports/scripts/stimulsoft.designer.pack.js`)
  await loadScript(`${$config.api}/stimulsoftreports/scripts/stimulsoft.dashboards.pack.js`)
}

/**
 * Загрузка CSS стимула
 *
 * @param $config
 */
export function loadStyles ($config) {
  [
    `${$config.api}/stimulsoftreports/css/stimulsoft.designer.office2013.whiteblue.css`,
    `${$config.api}/stimulsoftreports/css/stimulsoft.viewer.office2013.whiteblue.css`
  ].forEach((src) => {
    const style = document.createElement('link')
    style.rel = 'stylesheet'
    style.href = src
    document.head.appendChild(style)
  })
}

/**
 * Загрузка и первиная настройка стимула
 *
 * @param $config
 * @return {Promise<void>}
 */
export async function loadStimulsoft ($config) {
  console.info('Загрузка ресурсов для StimulSoft!')

  if (typeof window.Stimulsoft === 'undefined') {
    loadStyles($config)
    await loadScripts($config)

    window.Stimulsoft.Base.StiLicense.loadFromFile(`${$config.api}/stimulsoftreports/stimulsoft/license.php`)
    window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
      `${$config.api}/stimulsoftreports/localization/ru.xml`, false, 'Russian'
    )

    window.Stimulsoft.Base.Localization.StiLocalization.cultureName = 'Russian'
  }
}
