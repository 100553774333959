import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetRuleTreeElements, GetRuleTreeElementByGuid, GetRuleTreeElementByElementGuid } from '@/services/TaskEditor/infrastructure/store/modules/RuleTreeElement/action-types'
import RuleTreeElementQueryRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleTreeElementQueryRepositoryInterface'

export default class RuleTreeElementQueryRepository extends AbstractQueryRepository implements RuleTreeElementQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetRuleTreeElements(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetRuleTreeElementByGuid(guid))
  }

  getByElementGuid (elementGuid: string): Promise<any> {
    return store.dispatch(new GetRuleTreeElementByElementGuid(elementGuid))
  }
}
