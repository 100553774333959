
import mixins from 'vue-typed-mixins'
import ConstraintByGuidQuery from '@/services/LogicEditor/application/query/ConstraintByGuidQuery'
import ConstraintCreateCommand from '@/services/LogicEditor/application/command/ConstraintCreateCommand'
import ConstraintUpdateCommand from '@/services/LogicEditor/application/command/ConstraintUpdateCommand'
import ObjectTree from '@/components/Common/ObjectTree.vue'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RoleAPI } from '@/services/AccessEditor/infrastructure/api/RoleAPI'
import TagInput from "@/core/infrastructure/components/TagInput.vue";

export default mixins(FormMixin).extend({
  name: 'DisableConstraintForm',

  components: {
    TagInput,
    ObjectTree
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null || typeof this.model.guid === 'undefined'
    },

    isLoading () {
      return this.$store.getters['Constraint/isConstraintLoading']
    },

    constraintCreated () {
      return this.$store.getters['Constraint/getConstraintLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    constraintCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ConstraintByGuidQuery(
            location.replace('/constraints/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      logicOperators: [
        { id: 'all', name: 'Все условия' },
        { id: 'any', name: 'Любое из перечисленных' }
      ],

      cards: [],
      roles: [],
      functions: [],
      conditionTypes: [
        { id: 'state', name: 'Состояние' },
        { id: 'procedure', name: 'Процедура (функция)' }
      ]
    }
  },

  async mounted () {
    if (!this.cards.length) {
      this.$http
        .get(`${this.$config.api}/interfaceeditor/cards?entity_id=${this.objectId}`)
        .then((response) => {
          this.cards.push(...response.data.map(v => ({ id: v.id, name: `${v.name} (id: ${v.id})` })))
        })
        .catch((error) => console.error(error))
    }

    if (!this.roles.length) {
      // this.$http
      //   .get(`${this.$config.api}/accesseditor/roles?fields=id,name`)
      //   .then((response) => {
      //     this.roles.push(...response.data)
      //   })
      //   .catch((error) => console.error(error))
      try {
        const response = await APIClient.shared.request(new RoleAPI.GetRoles([['fields', 'id,name']]))

        this.roles.push(
          ...response
            .map(v => ({ id: v.id, name: `${v.name} (id: ${v.id})` }))
        )
      } catch (error) {
        console.log({ error })
      }
    }

    if (!this.functions.length) {
      this.functions = await this.$http
        .get(`${this.$config.api}/v2/logiceditor/procedures?fields=id,name&type=function`)
        .then((response) => {
          if (Array.isArray(response.data)) {
            return response.data
          }

          return []
        })
        .catch(() => {
          return []
        })
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      if (this.isNew) {
        return new ConstraintCreateCommand(this.model)
      }

      return new ConstraintUpdateCommand(this.model)
    }
  }
})
