import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import GarTableQueryRepositoryInterface from "@/services/GarAddressService/domain/repository/GarTableQueryRepository";
import GarTablesQuery from "@/services/GarAddressService/application/query/GarTablesQuery";

export default class GarTablesHandler implements QueryHandlerInterface {
  private repository: GarTableQueryRepositoryInterface;

  constructor (repository: GarTableQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: GarTablesQuery): Promise<any> {
    return this.repository.get(query.getPayload())
  }
}
