import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerTreeElementCommandRepositoryInterface'
import LayerTreeAddChildElementCommand from '@/services/MapEditor/application/command/LayerTreeAddChildElementCommand'
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export default class LayerTreeAddChildElementHandler implements CommandHandlerInterface
{
    private repo: LayerTreeElementCommandRepositoryInterface;

    constructor(repo: LayerTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerTreeAddChildElementCommand): Promise<void>
    {
        let item: LayerTreeElementDTO = {
            id: command.getChildId(),
            parent_id: command.getParentId()
        };
        return this.repo.updateParent(item);
    }
}