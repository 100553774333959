<template>
  <div :id="`stimulsoft_viewer_${componentId}`" :key="componentId" class="viewer" v-loading="screenLoading"></div>
</template>

<script>
import Vue from 'vue'
import StiHelper from '../StimulsoftReport/StiHelper'

export default Vue.extend({
  name: 'StimulsoftViewer',

  props: {
    filename: {
      type: String,
      default () {
        return 'SimpleList.mrt'
      }
    },

    variables: {
      type: Array,
      default () {
        return []
      }
    },

    sourceFilters: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      componentId: this.generateGuid(),
      screenLoading: false
    }
  },

  async mounted () {
    window.Stimulsoft.Helper = new StiHelper(`${this.$config.api}/stimulsoftreports/handler.php`, 300, {
      sourceFilters: this.sourceFilters
    })

    const options = new window.Stimulsoft.Viewer.StiViewerOptions()
    options.appearance.fullScreenMode = false
    options.appearance.scrollbarsMode = true
    options.toolbar.showSendEmailButton = true
    options.height = '100%'

    window.StiOptions.Export.Excel.AllowExportDateTime = true

    const viewer = new window.Stimulsoft.Viewer.StiViewer(options, 'StiViewer_' + this.componentId, false)

    viewer.onBeginProcessData = function (event, callback) {
      window.Stimulsoft.Helper.process(arguments[0], arguments[1])
    }

    viewer.onBeginExportReport = function (args) {
    }

    viewer.onEmailReport = function (event) {
      window.Stimulsoft.Helper.process(arguments[0], arguments[1])
    }

    const report = new window.Stimulsoft.Report.StiReport()
    report.loadFile(`${this.$config.api}/reports/${this.filename}`)
    this.variables.forEach((item) => {
      report.setVariable(item.key, item.value)
    })
    viewer.report = report

    viewer.renderHtml(`stimulsoft_viewer_${this.componentId}`)
  }
})
</script>

<style scoped>
.viewer {
  height: 100%;
}

.viewer > div /*deep*/ {
  height: 100% !important;
}
</style>
