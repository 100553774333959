
import Vue from 'vue'
import RuleGroupByGuidQuery from '@/services/TaskEditor/application/query/RuleGroupByGuidQuery'
import RuleGroupCreateCommand from '@/services/TaskEditor/application/command/RuleGroupCreateCommand'
import RuleGroupUpdateCommand from '@/services/TaskEditor/application/command/RuleGroupUpdateCommand'
import CommandInterface from '@/core/application/command/CommandInterface'

export default Vue.extend({
  name: 'RuleGroupForm',

  props: {
    value: Object
  },

  inject: ['getCommandBus', 'getQueryBus'],

  computed: {
    isNew (): boolean {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    isLoading (): boolean {
      return this.$store.getters['RuleGroup/isRuleGroupLoading']
    },

    ruleGroupCreated (): string {
      return this.$store.getters['RuleGroup/getRuleGroupLocation']
    }
  },

  watch: {
    value: {
      handler: function (value: object): void {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value: object): void {
        this.$emit('change', value)
      },
      deep: true
    },

    ruleGroupCreated (location: string): void {
      if (location) {
        this.getQueryBus().execute(
          new RuleGroupByGuidQuery(
            location.replace('/rule_groups/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: 'Please input field "Name"', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    submit (callback: Function): void {
      this.$emit('input', this.model)

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand (): CommandInterface {
      if (this.isNew) {
        return new RuleGroupCreateCommand(this.model)
      }

      return new RuleGroupUpdateCommand(this.model)
    }
  }
})
