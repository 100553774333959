import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ApprovalStageDeleteCommand from '@/services/LogicEditor/application/command/ApprovalStageDeleteCommand'
import ApprovalStageCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalStageCommandRepositoryInterface'

export default class ApprovalStageDeleteHandler implements CommandHandlerInterface {
    private repository: ApprovalStageCommandRepositoryInterface;

    constructor (repository: ApprovalStageCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ApprovalStageDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
