<template>
    <div style="height: 100%">
        <div v-show="false">
            <component
                    v-for="(component, index) in components"
                    :key="component.guid"
                    v-bind="component.properties"
                    :guid="component.guid"
                    :ref="'component_' + component.guid"
                    :is="component.type"
                    v-model="model[component.properties.name]"
                    @change-property="$emit('change-property', { name: $event.name, value: $event.value, guid: component.guid })"
                    @change-prop-property="$emit('change-prop-property', { prop: $event.prop, name: $event.name, value: $event.value, guid: component.guid })"
            ></component>
        </div>
        <ckeditor
                :editor="editor"
                ref="editor"
                style="height: calc(100% - 2px); z-index: 101; position: relative"
                v-model="localModel"
                @ready="editorRendered = true"
        ></ckeditor>
    </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import InlineEditor from '@/components/HTMLEditor/ckeditor.js'
import CKEditorMixin from '@/components/InterfaceEditor/components/CKEditorMixin'
export default {
  name: 'html-container',
  components: {
    ckeditor: CKEditor.component
  },
  inject: {
    setActiveComponent: {
      default: () => {}
    }
  },
  mixins: [CKEditorMixin],
  props: {
    value: {
      type: String,
      default: null
    },
    activeComponentGuid: {
      type: String,
      default: null
    },
    components: {
      type: Array,
      default () { return [] }
    },
    model: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      localModel: this.value,
      editor: InlineEditor,
      editorRendered: false
    }
  },
  watch: {
    localModel (value) {
      this.$emit('input', value)
    },
    components (value, oldValue) {
      if (value.length > oldValue.length && this.editorRendered) {
        let component = value.filter(x => !oldValue.includes(x))[0]
        console.log(this.$refs.editor)
        this.$refs.editor.$_instance.execute('placeholder', { value: component.name, guid: component.guid })
      }
    }
  }
}
</script>

<style scoped>

</style>
