import QueryInterface from "@/core/application/query/QueryInterface";

export default class SourceByIdAndTypeQuery implements QueryInterface {
  private id: number;
  private type: string;

  constructor (id: number, type: string) {
    this.id = id
    this.type = type
  }

  getClassName (): string {
    return 'SourceByIdAndTypeQuery'
  }

  getId (): number {
    return this.id
  }

  getType (): string {
    return this.type
  }
}
