
import Vue from 'vue'
import GroupCreateCommand from '@/services/CssEditor/application/command/GroupCreateCommand'
import GroupUpdateCommand from '@/services/CssEditor/application/command/GroupUpdateCommand'

export default Vue.extend({
  name: 'GroupForm',

  inject: ['getCommandBus'],

  props: {
    value: Object
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.modelValue)) {
          this.modelValue = value
        }
      }
    },

    modelValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      }
    }
  },

  computed: {
    isNew () {
      return typeof this.modelValue.guid === 'undefined'
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('css_editor.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  data () {
    return {
      modelValue: {
        id: undefined,
        guid: undefined,
        name: 'string',
        description: '',
        alias: null
      }
    }
  },

  mounted () {
    this.modelValue = this.value
  },

  methods: {
    submit (callback?: () => void) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.executeCommand(callback)
        } else {
          return false
        }
      })
    },

    executeCommand (callback?: () => void) {
      this.getCommandBus()
        .execute(
          this.isNew
            ? new GroupCreateCommand(this.modelValue)
            : new GroupUpdateCommand(this.modelValue)
        ).then(() => {
          if (callback) {
            callback()
          }
        })
    }
  }
})
