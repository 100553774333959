import QueryInterface from '@/core/application/query/QueryInterface'

export default class RuleTreeElementByElementGuidQuery implements QueryInterface {
  public elementGuid: string

  constructor (elementGuid: string) {
    this.elementGuid = elementGuid
  }

  getClassName (): string {
    return 'RuleTreeElementByElementGuidQuery'
  }

  getElementGuid (): string {
    return this.elementGuid
  }
}
