
import Vue from 'vue'

export default Vue.extend({
  name: 'TypographyPreview',

  props: {
    variables: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },

  computed: {
    h1 () {
      return {
        'font-size': this.variables['$--font-size-extra-large'] || '20px'
      }
    },

    h2 () {
      return {
        'font-size': this.variables['$--font-size-large'] || '18px'
      }
    },

    h3 () {
      return {
        'font-size': this.variables['$--font-size-medium'] || '16px'
      }
    },

    h4 () {
      return {
        'font-size': this.variables['$--font-size-base'] || '14px'
      }
    },

    h5 () {
      return {
        'font-size': this.variables['$--font-size-small'] || '13px'
      }
    },

    h6 () {
      return {
        'font-size': this.variables['$--font-size-extra-small'] || '12px'
      }
    },

    bodyText () {
      return {
        'font-size': this.variables['$--font-size-base'] || '14px',
        'font-weight': this.variables['$--font-weight-primary'] || '500',
        'line-height': this.variables['$--font-line-height-primary'] || '24px'
      }
    },

    smallText () {
      return {
        'font-size': this.variables['$--font-size-extra-small'] || '12px',
        'font-weight': this.variables['$--font-weight-secondary'] || '100',
        'line-height': this.variables['$--font-line-height-secondary'] || '16px'
      }
    }
  }
})
