
import { PropType } from 'vue'
import InputMixin from '@/services/CssEditor/infrastructure/components/InputMixin'
import mixins from 'vue-typed-mixins'

export default mixins(InputMixin).extend({
  name: 'InputWeight',

  props: {
    value: {
      type: [Number, String] as PropType<number|string>
    }
  },

  watch: {
    value () {
      this.localValue = String(this.value || 100)
    },

    localValue () {
      const value = parseInt(this.localValue)

      this.$emit('change', [isNaN(value) ? this.localValue : value, this.value])
      this.$emit('input', isNaN(value) ? this.localValue : value)
    }
  },

  data () {
    return {
      localValue: this.value,

      options: [
        'inherit', 'normal', 'bold', 'bolder', 'lighter', '100', '200', '300', '400', '500', '600', '700', '800', '900'
      ]
    }
  }
})
