import Modeler from 'bpmn-js/lib/Modeler'

export default class PropertiesService {
  public static parse (element: { id: string, type: string, businessObject: { [index: string]: any } }): object {
    let elementType = element.type.replace('bpmn:', '')
    let object = {
      id: element.id,
      type: elementType,
      attributes: undefined,
      extensions: undefined
    }
    object.attributes = this.prepareAttributes(element)
    object.extensions = this.prepareExtensions(element)

    return object
  }

  private static prepareAttributes (element: {
    businessObject: { [index: string]: any }
  }): object {
    let result = {}
    Object.keys(element.businessObject).forEach((key) => {
      if (!key.startsWith('$') && key !== 'extensionElements') {
        result[key] = element.businessObject[key]
      }
    })

    Object.keys(element.businessObject.$attrs).forEach((key) => {
      if (!key.startsWith('$') && key !== 'extensionElements') {
        result[key] = element.businessObject.$attrs[key]
      }
    })

    return result
  }

  private static prepareExtensions (element: {
    businessObject: { [index: string]: any }
  }): object {
    let result = {}
    element.businessObject?.extensionElements?.values[0]?.properties?.forEach((property) => {
      if (property?.name) {
        result[property.name] = property?.value || null
      }
    })
    return result
  }

  public static change (
    modeler: Modeler,
    element: object,
    type: string,
    property: string,
    value: any
  ): void {
    const functionName = `change_${type}`
    if (typeof this[functionName] === 'function') {
      this[functionName](modeler, element, property, value)
    }
  }

  private static change_attributes (modeler: Modeler, element: object, property: string, value: any) {
    modeler.get('modeling').updateProperties(element, {
      [property]: value
    })
  }

  private static change_extensions (
    modeler: Modeler,
    element: { type: string, businessObject: { extensionElements: any } },
    property: string,
    value: any
  ) {
    if (!value || (Array.isArray(value) && value.length === 0) || value === '[]') {
      this.remove_extensions(modeler, element, (_) => _ === property)
      return
    }
    const moddle = modeler.get('moddle')
    if (Object.keys(element.businessObject?.extensionElements || {}).length === 0) {
      const extensionElements = moddle.create('bpmn:ExtensionElements')
      const extensionProperties = moddle.create('extension:Properties')
      extensionElements.get('values').push(extensionProperties)
      modeler.get('modeling').updateProperties(element, { extensionElements })
    }
    let extensionElementsToUpdate = element.businessObject.extensionElements
    let properties = extensionElementsToUpdate.get('values')[0].get('properties')
    const extensionPropertyIndex = properties.findIndex((_) => _.name === property)
    if (extensionPropertyIndex === -1) {
      let prop = moddle.create('extension:Property', {
        name: property,
        value: value
      })
      properties.push(prop)
    } else {
      properties[extensionPropertyIndex].value = value
    }

    extensionElementsToUpdate.get('values')[0].properties = properties

    modeler.get('modeling').updateProperties(element, { extensionElements: extensionElementsToUpdate })
  }

  public static remove (
    modeler: Modeler,
    element: object,
    type: string,
    filterFunction: (name: string) => boolean
  ): void {
    const functionName = `remove_${type}`
    if (typeof this[functionName] === 'function') {
      this[functionName](modeler, element, filterFunction)
    }
  }

  private static remove_extensions (
    modeler: Modeler,
    element: { type: string, businessObject: { extensionElements: any } },
    filterFunction: (name: string) => boolean
  ) {
    if (Object.keys(element.businessObject?.extensionElements || {}).length === 0) {
      return
    }
    let extensionElementsToUpdate = element.businessObject.extensionElements
    let properties = extensionElementsToUpdate.get('values')[0].get('properties')

    properties = properties.filter((_) => !filterFunction(_.name))

    extensionElementsToUpdate.get('values')[0].properties = properties

    modeler.get('modeling').updateProperties(element, { extensionElements: extensionElementsToUpdate })
  }
}
