export class MemoryStorageCache {
  private static items: Map<string, string> = new Map();
  private static timers: Map<string, number> = new Map();

  public static set (key: string, value: any, ttl?: number): void {
    this.items.set(key, value)
    if (ttl) {
      if (this.timers.has(key)) {
        clearTimeout(this.timers.get(key))
      }
      const timer: number = window.setTimeout(() => {
        this.items.delete(key)
      }, ttl * 1000)
      this.timers.set(key, timer)
    }
  }

  public static get (key: string): any {
    return this.items.get(key)
  }

  public static delete (key: string): any {
    return this.items.delete(key)
  }
}
