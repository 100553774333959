<template>
  <div class="registry">
    <toolbar
      class="toolbar-box"
      @edit-record="editRecord"
      @add-record="initAddRecord"
      @add-existing="initAddExisting"
      @detach="initDetachRecord"
      @query-search="$emit('query-search', $event)"
      @clear-filters="clearFilters"
      @expand="expand"
      @export="executeExport"
      :readonly="readonly"
      :edit-loading="loading"
      :export-loading="exportLoading"
      :cssQuickSearch="cssQuickSearch"
      :show-button-ag-grid="showButtonAgGrid"
      :is-show-toolbar="isShowToolbar"
      :placeholderQuickSearch="placeholderQuickSearch"
      :export-templates="exportTemplates"
      :is-outer-xref="isOuterXref"
      :grid-api="gridApi"
      :guid="guid"
    ></toolbar>
    <table-body
      class="table-box"
      ref="tableBody"
      :key="registryId"
      :registry-id="registryId"
      :custom-columns="customColumns"
      :is-custom-columns-sort="isCustomColumnsSort"
      :is-custom-columns-width="isCustomColumnsWidth"
      :external-filters="externalFilters"
      :filtersForFilterCmp="filtersForFilterCmp"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :row-double-clicked="rowDoubleClicked"
      :groupUseEntireRow="groupUseEntireRow"
      :is-pivot-mode="isPivotMode"
      :check-box-selection="checkBoxSelection"
      :header-check-box-selection="headerCheckBoxSelection"
      :show-count="showCount"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :state="state"
      :optimize-options="optimizeOptions"
      :readonly="readonly"
      :theme="theme"
      type="registry"
      :CSS="CSS"
      :CSSClasses="CSSClasses"
      :context-menu="getContextMenu()"
      :rowClassRules="rowClassRules"
      :page-size="pageSize"
      :cache-block-size="cacheBlockSize"
      :rowHeight="rowHeight"
      :suppressGroupRowsSticky="suppressGroupRowsSticky"
      @edit-record="$emit('edit-record', $event)"
      @grid-ready="$emit('grid-ready', $event)"
      @loaded="$emit('loaded', $event); gridApi = $event"
    ></table-body>
    <div class="footer-box"></div>
    <slot></slot>
  </div>
</template>

<script>
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import StructureHandler from '@/services/RegistryTable/application/handler/query/StructureHandler'
import StructureRepository from '@/services/RegistryTable/infrastructure/domain/repository/StructureRepository'
import TableBody from '@/services/RegistryTable/infrastructure/components/TableBody/index.vue'
import Vue from 'vue'
import DataHandler from '@/services/RegistryTable/application/handler/query/DataHandler'
import DataRepository from '@/services/RegistryTable/infrastructure/domain/repository/DataRepository'
import Toolbar from '@/services/RegistryTable/infrastructure/components/Toolbar/index.vue'

import Registry from '@/components/Registry/Models/Registry'
import RegistryCard from '@/components/RegistryCard'
// import { eventBus } from '@/eventBus'
export default {
  name: 'registry-table',
  inject: {
    openRegistryCard: { default: () => {} },
    getModel: { default: () => {} },
    $computedShowCard: { default: () => () => {} },
    openTabModalWindow: { default: () => {} }
  },
  props: {
    registryId: {
      type: Number
    },
    guid: {
      type: String,
      frozen: true
    },
    outerXref: {
      type: Object
    },
    tableAddExisting: {
      type: Object,
      default: () => ({}),
      editor: 'TableAddExisting'
    },
    loading: {
      type: Boolean
    },
    customColumns: {
      type: Array
    },
    isCustomColumnsSort: {
      type: Boolean,
      default: false
    },
    isCustomColumnsWidth: {
      type: Boolean,
      default: false
    },
    externalFilters: {
      type: Array
    },
    defaults: {
      type: Array
    },
    filtersForFilterCmp: {
      type: Array
    },
    isPivotMode: {
      type: Boolean
    },
    sideBar: {
      type: Boolean
    },
    closeToolPanel: {
      type: Boolean
    },
    floatingFilter: {
      type: Boolean
    },
    rowDoubleClicked: {
      type: Boolean
    },
    groupUseEntireRow: {
      type: Boolean
    },
    cssQuickSearch: {
      type: String
    },
    placeholderQuickSearch: {
      type: String
    },
    showButtonAgGrid: {
      type: Object
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    showCount: {
      type: Boolean
    },
    disabledColumnHeader: {
      type: Boolean
    },
    hideHeader: {
      type: Boolean
    },
    suppressGroupRowsSticky: {
      type: Boolean
    },
    wrapHeader: {
      type: Boolean
    },
    state: {
      type: Object
    },
    checkBoxSelection: {
      type: Boolean
    },
    headerCheckBoxSelection: {
      type: Boolean
    },
    optimizeOptions: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    isFastCard: {
      type: Boolean
    },
    theme: {
      type: String
    },
    hideExport: {
      type: Boolean,
      default: false
    },
    CSS: {
      type: String
    },
    CSSClasses: {
      type: String
    },
    rowClassRules: {
      type: Array
    },
    pageSize: {
      type: Number
    },
    cacheBlockSize: {
      type: Number
    },
    rowHeight: {
      type: Number
    }
  },
  components: {
    TableBody,
    Toolbar,
    RegistryCard
  },
  watch: {
    computedShowCard: {
      handler: function (val) {
        // принудительно обновить таблицу после закрытия карточки (баг слетает внешний вид таблицы)
        // в ag-grid v.28 баг исправлен на уровне библиотеки - нужен тест
        // if (!val && this.isLoadTable) {
        //   this.getEventBus().$emit('load')
        //   this.isLoadTable = false
        // }
      }
    }
  },
  beforeDestroy () {
    this.getEventBus().$off('selectedRows', this.selectedRow)
  },
  mounted () {
    // console.warn('%c%s', 'color: skyblue;', 'registryTable mounted')
    // регистрации события сохранения карточки
    // принудительно обновить таблицу после сохранения карточки (баг слетает внешний вид таблицы)
    // eventBus.$on('registry-card-saved', () => {
    //   this.getEventBus().$emit('load')
    // })
    if (this.getEventBus) {
      this.getEventBus().$on('selectedRows', this.selectedRow)
      this.getEventBus().$on('update-record', this.updateRecord)
    }

    this.loadExportTemplates()
  },
  computed: {
    computedShowCard () {
      return this.$computedShowCard()
    },
    isOuterXref () {
      return !!this.outerXref
    }
  },
  data () {
    return {
      eventBus: new Vue(),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'StructureQuery': new StructureHandler(
            new StructureRepository()
          ),
          'DataQuery': new DataHandler(
            new DataRepository()
          )
        })
      ),
      selectedRows: [],
      isLoadTable: false,
      exportTemplates: [],
      allowExportColumns: [
        'addressField', 'booleanField', 'dateField',
        'datetimeField', 'fileField', 'stringField',
        'integerField', 'floatField', 'textField',
        'timeField', 'xrefField', 'xrefMultiField',
        'fieldGroup'
      ],
      exportLoading: false,
      gridApi: {}
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    clearFilters () {
      if (!this.$refs.tableBody?.$refs.grid) {
        return
      }
      // setFilterModel - очистить стандартные фильтры
      this.$refs.tableBody.$refs.grid.gridApi.setFilterModel(null)
      // this.$refs.tableBody.$refs.grid.gridApi.setRowGroupColumns([])
      // this.$refs.tableBody.$refs.grid.gridApi.setSortModel(null)
      // очистить custom  фильтры
      let savedFilterModel = this.$refs.tableBody.$refs.grid.gridApi.getFilterModel()
      let keys = Object.keys(savedFilterModel)

      if (keys.length) {
        keys.forEach(item => {
          let filter = this.$refs.tableBody.$refs.grid.gridApi.getFilterInstance(item)
          filter.setModel(null)
        })
      }
      this.$refs.tableBody.$refs.grid.gridApi.onFilterChanged()
    },
    selectedRow (selectedRows) {
      this.selectedRows = selectedRows
    },
    expand () {
      this.$emit('expand')
    },
    editRecord (event) {
      this.isLoadTable = false
      this.$emit('edit-record', event)
    },
    async getCardData (recordid) {
      let dataCard = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/card/${recordid}`)
      return dataCard.data
    },
    loadExportTemplates () {
      if (this.registryId) {
        this.$http
          .get(`${this.$config.api}/etleditor/objects/tasks?extractor_object_id=${this.registryId}&is_visible_in_registry=true`)
          .then((response) => {
            this.exportTemplates = response.data
          })
      }
    },
    getEventBus () {
      // console.log('%c%s', 'color: skyblue;', 'registryTable getEventBus')
      return this.eventBus
    },
    getQueryBus () {
      // console.log('%c%s', 'color: skyblue;', 'registryTable getQueryBus')
      return this.queryBus
    },
    async getCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    async initAddRecord () {
      this.isLoadTable = true
      let quickAddCard = await this.isQuickAddCard()
      if (quickAddCard) {
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (quickAddCard.value.width) {
          customClass += `window_width_${quickAddCard.value.width}`
        }
        let me = this
        let initialData = {}
        if (this.outerXref && this.outerXref.id && this.outerXref.value) {
          initialData[`attr_${this.outerXref.id}_`] = this.outerXref.value
        }

        let defaults = this.getDefaultsForCard()
        defaults.forEach((item) => {
          initialData[item.key] = item.value
        })

        this.$msgbox({
          customClass: customClass,
          message: h('registry-card', {
            style: {
              height: quickAddCard.value.height
            },
            props: {
              cardId: quickAddCard.value.card_id,
              registryId: this.registryId,
              parentContext: null,
              model: {},
              quick: true,
              initialData: initialData
            },
            on: {
              'quick-add': async function (data) {
                let card = await me.getCard(data)
                if (card) {
                  me.openRegistryCard({
                    registryId: me.registryId,
                    cardId: card.id,
                    cardName: card.name,
                    recordId: null,
                    initialData: data,
                    registry: me,
                    preventUserCard: true
                  })
                }
                me.$msgbox.close()
              },
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid() }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      } else {
        this.$emit('add-record')
      }
    },
    async isQuickAddCard () {
      // Проверка на карточку быстрого добавления
      let registryData = new Registry({ id: this.registryId })
      let me = this
      let structure = await registryData.structure().first()
        .catch(() => { me.error = true })
      if (!structure) {
        return false
      }
      let quickAddCard = (structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id) {
        return quickAddCard
      } else {
        return false
      }
    },
    async updateRecord (recordId) {
      console.log(recordId, 'updateRecord')
      try {
        let dataCard = await this.getCardData(recordId)
        this.getEventBus().$emit('edit', dataCard)
      } catch (error) {
        console.log('не удалось получить данные карточки')
      }
    },
    async addRecord (recordId) {
      console.log(recordId, 'addRecord')
      try {
        let dataCard = await this.getCardData(recordId)
        this.getEventBus().$emit('add', dataCard)
      } catch (error) {
        console.log('не удалось получить данные карточки')
      }
    },
    formatFields (columns, hideColumns) {
      // Убрать поля не соответсвующие типу и скрытые поля
      const allowColumns = columns
        .filter(
          (col) => this.allowExportColumns.includes(col.cellRenderer) && !hideColumns.includes(col.field)
        )

      // Групповые поля абстракция, создаются AgGrid (colId === index).
      // Их нет в getColumnState, что бы исключить пустые группы (если все подчинённые поля скрыты),
      // нужна дополнительная фильтрация результата "где Обычное поле, либо групповое поле с непустым подчин. списком"
      return allowColumns
        .map(({ field, cellRenderer = null, headerName, children = [] }) => {
          const result = {
            name: headerName
          }

          if (children.length > 0) {
            result.children = this.formatFields(children, hideColumns)
          } else {
            result.field = field
            result.type = cellRenderer.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
          }

          return result
        })
        .filter((col) => {
          return typeof col.children === 'undefined' || (typeof col.children !== 'undefined' && col.children.length > 0)
        })
    },
    getContextMenu () {
      const subMenu = [
        'csvExport',
        'excelExport'
      ]

      if (!this.hideExport) {
        subMenu.push('separator')
        subMenu.push({
          name: 'Экспорт всей таблицы',
          action: () => {
            this.executeExport()
          }
        })

        if (this.exportTemplates.length > 0) {
          subMenu.push('separator')
          for (const item of this.exportTemplates) {
            subMenu.push({
              name: item.task_name,
              action: () => {
                this.executeExport('task', item)
              }
            })
          }
        }
      }

      return [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'chartRange',
        {
          name: 'Экспорт',
          icon: '<span class="el-icon-download"></span>',
          subMenu
        }
      ]
    },

    /**
     * Вернёт фильтры в формате ApiQL
     *
     * @return {Nullable<Object[]>|Object[]|null}
     */
    getPayload () {
      const dataSource = this.$refs.tableBody
        .getDataSourceService()

      if (!dataSource) {
        return null
      }

      return dataSource.getLastPayload()
    },
    initAddExisting () {
      let existingColumns = this.customColumns.map((el) => {
        el.value = el.field
        return el
      })
      if (this.outerXref) {
        // Вн. ссылка
        return this.openTabModalWindow('RegistryAddExisting', { registryId: this.registryId, outerXref: this.outerXref, grid: this, columns: existingColumns })
      }
    },
    initDetachRecord () {
      if (!this.selectedRows.length) {
        this.$alert(this.$locale.registry.errors.not_selected, this.$locale.main.message.error, {
          confirmButtonText: this.$locale.main.button.ok
        })

        return false
      }

      if (!this.outerXref || !this.outerXref.id || !this.outerXref.value) {
        this.$alert(this.$locale.registry.errors.not_selected, this.$locale.main.message.error, {
          confirmButtonText: this.$locale.main.button.ok
        })

        return false
      }
      let me = this
      this.$confirm(this.$locale.registry.message_detach.message, this.$locale.registry.message_detach.title, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        this.selectedRows.forEach(function (selectedRecord) {
          let data = {
            xrefId: me.outerXref.id,
            xrefValueId: me.outerXref.value
          }
          me.$http.put(`${me.$config.api}/registryservice/registry/${selectedRecord.object_id}/records/${selectedRecord.id}/detach`, data, { hideNotification: true })
            .then(() => {
              me.$notify({
                title: me.$locale.main.message.success,
                message: me.$locale.main.message.deleted,
                type: 'success'
              })
              me.getEventBus().$emit('load')
            })
            .catch(() => {
              me.$notify.error({
                title: me.$locale.main.message.error,
                message: me.$locale.main.message.not_saved
              })
            })
        })
      })
    },
    executeExport (type = 'default', item = undefined) {
      const title = this.$t('registry.message_export.title')
      const message = type === 'default'
        ? this.$t('registry.message_export.message')
        : `${this.$t('registry.message_export.task_message')} "${item.task_name}"?`

      this.$confirm(message, title, {
        confirmButtonText: this.$t('main.button.ok'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(async () => {
        this.exportLoading = true

        try {
          const body = { ids: null, xrefs: null, fields: null, payload: null }

          // ids
          if (this.selectedRows.length > 0) {
            body.ids = this.selectedRows.map(({ id }) => {
              return id
            })
          }

          // Подготовка всех полей грида
          const columns = this.$refs.tableBody.getColumns()
          const columnsState = this.$refs.tableBody.getColumnsState()
          const columnApi = this.$refs.tableBody.getColumnApi()
          // сохранить порядок столбцов как в таблице
          const sortedColumns = []
          columnsState.forEach(colState => {
            const targetCol = columns.find(col => col.field === colState.colId)
            if (targetCol) {
              sortedColumns.push(targetCol)
            }
          })

          // Получить скрытые поля
          const hideColumns = columnApi.getColumnState().filter(s => s.hide).map(s => s.colId) // result: [attr_1_, attr_2_, ...]

          if (columns.length > 0) {
            body.fields = this.formatFields(columns, hideColumns)
          }

          const roleId = this.$store.getters['Authorization/roleId']

          // Собрка фильтров данных
          // Первыми идут настроенные фильтры грида
          const { where: gridFilter = null } = this.$refs.tableBody.getDataSourceService().getLastPayload()

          // Следующими фильтры из ограничения на чтение + фильтр по указанному состоянию
          const constParams = this.state && this.state.stateId
            ? `?state_id=${this.state.stateId}`
            : ''
          const constraintFilter = await this.$http
            .get(`${this.$config.api}/registryservice/registry/${this.registryId}/role/${roleId}/filters${constParams}`)
            .then(res => res.data)
            .then(data => data[0] || null)

          // Собираем тело запроса
          if (gridFilter || (constraintFilter !== null && constraintFilter.and.length > 0)) {
            body.payload = {
              where: {
                and: []
              }
            }
          }

          // Добавляем фильтры грида
          if (gridFilter) {
            body.payload.where.and.push(gridFilter)
          }

          // Добавляем фильтры ограничения на чтение и указанного состояния
          if (constraintFilter !== null && constraintFilter.and.length > 0) {
            body.payload.where.and.push(constraintFilter)
          }

          // Выполняем запрос к сервису импорта экспорта
          // Способ выгрузки данных делится на два типа, по задаче настроенной в ETL, или дефолтный экспорт всей таблицы
          const response = await this.$http({
            method: 'post',
            url: type === 'default'
              ? `${this.$config.api}/etleditor/export/${this.registryId}/${this.$store.getters['Authorization/roleId']}`
              : `${this.$config.api}/etleditor/export/${item.task_id}`,
            responseType: 'arraybuffer',
            data: body
          })

          // Сохраняем резльтат (файл) на ПК, посредством скачивания по ссылке
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: response.headers['content-type'] })
          )

          window.open(url)
        } catch (e) {
          console.error(e)
        } finally {
          this.exportLoading = false
        }
      })
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    }
  }
}
</script>

<style scoped src="./RegistryTable.css">

</style>
