<template>
  <div style="height: 100%">
    <div class="tool_box">
      <el-button icon="el-icon-refresh-right" size="small" plain @click="refresh(null)">{{ $t('main.button.update') }}</el-button>
      <el-tooltip slot="reference" disabled>
        <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
          <el-button icon="el-icon-plus" size="small" style="margin-left: 10px;" plain>{{ $t('main.button.add') }}</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="type" v-for="(translate, type) in root_elements" :key="type">{{ translate }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-tooltip>
    </div>
    <el-tree
      ref="schema_tree"
      v-loading="loading"
      class="schema_tree"
      node-key="guid"
      :data="data"
      :props="tree_props"
      :expand-on-click-node="false"
      :load="loadNode"
      @node-click="handleSelected"
      lazy
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <b>{{ data.name }}</b>
          <span class="request-node-element-type">{{ schema_entity_types[data.entity_type_id] }}</span>
          <span> (id - {{ data.id }})</span>
        </span>
        <span v-if="selectedNodeData.id === data.id">
          <el-dropdown trigger="click" @command="addEntity($event)" style="margin-right: 10px">
            <el-button type="text" size="small">
              <i class="el-icon-plus">{{ $t('api_service.request.request_adding_form.add') }}</i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="type" v-for="(translate, type) in schema_entity_types" :key="type">
                {{ translate }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="text" size="small" style="color:red" @click="deleteSchemaEntity(node)" slot="reference">
              <i class="el-icon-delete el-icon--left">{{ $t('api_service.request.request_adding_form.delete') }}</i>
          </el-button>
        </span>
      </span>
    </el-tree>
    <modal-window :active.sync="showForm" show-close-icon>
      <SchemaForm
        :label="schema_entity_types[addingForm.type]"
        :schema-entity-type="addingForm.type"
        :parent="selectedNodeData">
      </SchemaForm>
    </modal-window>
  </div>
</template>
<script>

import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import SchemasQuery from '@/services/ApiService/application/query/SchemasQuery'
import SchemaForm from '@/services/ApiService/infrastructure/components/SchemaPanel/SchemaForm'
import DeleteSchemaCommand from '@/services/ApiService/application/command/DeleteSchemaCommand'
import DeleteRequestCommand from '@/services/ApiService/application/command/DeleteRequestCommand'
import { RequestTreeDTO } from '@/services/ApiService/domain/model/Request/RequestTree'

export default {
  name: 'SchemaPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  components: {
    ModalWindow,
    SchemaForm
  },
  data () {
    return {
      loading: false,
      tree_props: {
        isLeaf: 'is_leaf',
        label: 'name'
      },
      selectedNode: {},
      selectedNodeData: {},
      root_elements: {
        'group': this.$t('api_service.request.tree_element_type.group')
      },
      schema_entity_types: {
        'group': this.$t('api_service.data_type.group'),
        'string': this.$t('api_service.data_type.string'),
        'number': this.$t('api_service.data_type.number'),
        'integer': this.$t('api_service.data_type.integer'),
        'boolean': this.$t('api_service.data_type.boolean'),
        'array': this.$t('api_service.data_type.array'),
        'object': this.$t('api_service.data_type.object'),
        'schema': this.$t('api_service.data_type.schema'),
        'one_of': this.$t('api_service.data_type.one_of')
      },
      showForm: false,
      addingForm: {
        name: null,
        type: null
      },
      data: [],
      showDeleteWindow: false
    }
  },
  async mounted () {
    this.getEventBus().$on('refresh_schema', (node) => {
      this.refresh(node)
    })
    this.getEventBus().$on('show_schema_window', (value) => {
      this.showForm = value
    })
  },
  methods: {
    async loadNode (node, resolve) {
      this.loading = true
      let data = []
      if (node.level === 0) {
        data = await this.getQueryBus().execute(new SchemasQuery())
      } else {
        data = await this.getQueryBus().execute(new SchemasQuery({
          parent_id: node.data.id
        }))
      }
      this.loading = false
      resolve(data)
    },
    async handleSelected (data, node) {
      this.selectedNodeData = data
      this.selectedNode = node
      this.getEventBus().$emit('show_schema_form', node)
    },
    addEntity (type) {
      this.addingForm.type = type
      this.showForm = true
    },
    async refresh (node) {
      this.selectedNodeData = {}
      this.loading = true
      if (node) {
        let nodeGuid = node?.data?.guid ? node?.data?.guid : node.guid
        let currentParentNode = await this.$refs.schema_tree.getNode(nodeGuid)
        if (currentParentNode) {
          currentParentNode.loaded = false
          currentParentNode.loadData()
        } else {
          this.data = await this.loadTreeRootLevel()
        }
      } else {
        this.data = await this.loadTreeRootLevel()
      }
      this.loading = false
    },
    async deleteSchemaEntity (entity) {
      this.$confirm(this.$t('api_service.dialog.delete_node_message'), this.$t('api_service.dialog.delete_node_title'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(async () => {
        await this.getCommandBus().execute(
          new DeleteSchemaCommand(entity.data.guid)
        )

        await this.refresh(entity.parent.data)
      }).catch(() => {})
    },
    async loadTreeRootLevel () {
      return this.getQueryBus().execute(new SchemasQuery())
    }
  }
}
</script>

<style scoped lang="scss">
  .schema_tree {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .request-node-element-type {
    color: #7C838B;
    padding-left: 6px;
  }
</style>
