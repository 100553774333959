import CommandInterface from '@/core/application/command/CommandInterface'
import Rule from '@/services/TaskEditor/domain/model/Rule'

export default class RuleUpdateCommand implements CommandInterface {
  private model: Rule;

  constructor (model: Rule) {
    this.model = model
  }

  getClassName (): string {
    return 'RuleUpdateCommand'
  }

  getModel (): Rule {
    return this.model
  }
}
