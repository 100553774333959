import StateCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/StateCommandRepositoryInterface'
import store from '@/store'
import { CreateState, UpdateState, DeleteState } from '@/services/LogicEditor/infrastructure/store/modules/State/action-types'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'

export default class StateCommandRepository implements StateCommandRepositoryInterface {
  insert (item: StateDTO): Promise<void> {
    return store.dispatch(new CreateState(item))
  }

  update (item: StateDTO): Promise<void> {
    return store.dispatch(new UpdateState(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteState(guid))
  }
}
