import QueryInterface from '@/core/application/query/QueryInterface'

export default class CoordinateSystemsByUserIdQuery implements QueryInterface
{
    private userId: number;

    constructor(userId: number)
    {
        this.userId = userId;
    }

    getUserId(): number
    {
        return this.userId;
    }

    getClassName(): string
    {
        return 'CoordinateSystemsByUserIdQuery';
    }
}