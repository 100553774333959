import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RequestParametersQuery from '@/services/ApiService/application/query/RequestParametersQuery'

export default class RequestParametersHandler implements QueryHandlerInterface {
  private repo: RequestRepositoryInterface
  constructor (repo: RequestRepositoryInterface) {
    this.repo = repo
  }

  execute (query: RequestParametersQuery): Promise<any> {
    return this.repo.getRequestParameters(query.getGuid())
  }
}
