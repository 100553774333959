import CommandInterface from '@/core/application/command/CommandInterface'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export default class DeleteRequestParameterCommand implements CommandInterface {
  private dto: RequestParameter;

  constructor (
    dto: RequestParameter
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'DeleteRequestParameterCommand'
  }

  getDto (): RequestParameter {
    return this.dto
  }
}
