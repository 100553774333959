<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <tree-select
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      node-key="id"
      clearable
      :data="data"
      :props="treeProps"
      :render-content="renderTree"
      :placeholder="$locale.main.placeholder.select">
    </tree-select>
  </el-form-item>
</template>

<script>
import TreeSelect from '../../Common/TreeSelect'

export default {
  name: 'Field',

  props: ['value', 'size', 'title', 'titleWidth', 'registryId'],

  components: {
    TreeSelect
  },

  watch: {
    registryId () {
      this.getObjects()

      this.$emit('input', null)
    }
  },

  data () {
    return {
      data: [],
      treeProps: {
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id === 'field_group'
        },
        isLeaf: 'leaf'
      }
    }
  },

  mounted () {
    this.getObjects()
  },

  methods: {
    getObjects () {
      if (this.registryId !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.registryId}&show_children=true`)
          .then(response => { this.data = this.buildXrefFields(response.data.data) })
          .catch(error => console.log(error))
      }
    },

    buildXrefFields (fields) {
      let xrefFields = []

      fields.forEach(field => {
        if (field.entity_type_id === 'xref_field' || field.entity_type_id === 'xref_multi_field') {
          xrefFields.push(field)
        } else if (field.entity_type_id === 'field_group') {
          field.children = this.buildXrefFields(field.children)

          if (field.children.length > 0) {
            xrefFields.push(field)
          }
        }
      })

      return xrefFields
    },

    renderTree (h, { node, data, store }) {
      const attr = `attr_${data.id}_`

      return h('span', {
        class: {
          'custom-tree-node': true
        }
      }, [
        h('span', node.label),
        h(
          'span',
          {
            class: 'el-tree-node__attribute'
          },
          attr
        )
      ])
    }
  }
}
</script>

<style type="text/css">
.tree-xref.el-tree {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #dcdfe6;
}
</style>
