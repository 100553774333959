import { Nullable } from '@/core/domain/type/types'

export default class LayerGroupCreateCommand
{
    private name: string;
    private projectId: number;
    private parentId: Nullable<number>;

    constructor(
        name: string,
        projectId: number,
        parentId: Nullable<number>
    ) {
        this.name = name;
        this.projectId = projectId;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'LayerGroupCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}