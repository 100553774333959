import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'
import TemplateUpdateCommand from '@/services/EtlEditor/application/command/TemplateUpdateCommand'
import TemplateCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TemplateCommandRepositoryInterface'

export default class TemplateUpdateHandler implements CommandHandlerInterface {
    private repository: TemplateCommandRepositoryInterface;

    constructor (repository: TemplateCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TemplateUpdateCommand): Promise<void> {
      let item: TemplateDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        alias: command.getAlias(),
        file_id: command.getFileId()
      }

      return this.repository.update(item)
    }
}
