import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RuleGroupDeleteCommand from '@/services/TaskEditor/application/command/RuleGroupDeleteCommand'
import RuleGroupCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleGroupCommandRepositoryInterface'

export default class RuleGroupDeleteHandler implements CommandHandlerInterface {
    private repository: RuleGroupCommandRepositoryInterface;

    constructor (repository: RuleGroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RuleGroupDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
