<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <el-select
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      :placeholder="$t('main.placeholder.select')"
      clearable
      filterable
      @clear="$emit('input', null)"
    >
      <el-option
        v-for="item in procedures"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Procedure',

  props: ['value', 'size', 'title', 'titleWidth', 'registryId'],

  data () {
    return {
      procedures: []
    }
  },

  async mounted () {
    this.procedures = await this.$http
      .get(`${this.$config.api}/v2/logiceditor/procedures?fields=id,name&type=procedure`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          return response.data
        }

        return []
      })
      .catch(() => {
        return []
      })
  }
})
</script>

<style type="text/css">
.tree-xref.el-tree {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #dcdfe6;
}
</style>
