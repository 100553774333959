<template>
 <div>
   <div v-for="(group, index) in _dates" :key="index" class="date-group">
     <span class="group-name" >{{ group.name }} </span>
     <ul>
       <li v-for="(date, dateIndex) in group.dates" :key="`${index}_${dateIndex}`">
         <a class="date" :class="{'active': date.getTime().toString() === active }" @click="$emit('timeClick', date.getTime())">{{date | moment("DD MMM в H:mm:ss.SSS") }}</a>
       </li>
     </ul>
   </div>
 </div>
</template>

<script>
export default {
  name: 'timings',
  props: {
    dates: Object,
    active: String
  },
  computed: {
    _dates () {
      let result = []
      for (let date in this.dates) {
        let group = this.$moment(new Date(...date.split('-'))).format('MMMM Y')

        result.push({
          name: group.charAt(0).toUpperCase() + group.substr(1),
          dates: this.dates[date]
        })
      }

      return result
    }
  }
}
</script>

<style scoped src="./Timing.css">

</style>
