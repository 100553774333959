<template>
  <el-form-item :label="label">
    <el-tree
      v-if="['xref_field','xref_multi_field'].includes(entityTypeId) && xrefField != null"
      v-loading="loading"
      style="height: 200px; overflow-y:scroll; width: 100%"
      ref="tree"
      :props="props"
      :data="treeData"
      show-checkbox
      check-strictly
      node-key="id"
      @check-change="handleCheckChange"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
      </span>
    </el-tree>
  </el-form-item>
</template>

<script>
export default {
  name: 'field_array',

  props: ['value', 'entity-type-id', 'properties', 'label'],

  data () {
    return {
      expanded: [],
      loading: true,
      xrefField: (this.properties.find(item => item.id === 'xref') || {}).value || null,
      localValue: this.value || [],
      treeData: [],
      props: {
        isLeaf: 'leaf',
        label: 'name'
      }
    }
  },

  watch: {
    xrefField (value) {
      this.$emit('input', [])
      if (!value) {
        this.treeData = []
      } else {
        this.loadTree(value)
      }
    },

    properties: {
      deep: true,
      handler (value) {
        this.xrefField = (value.find(item => item.id === 'xref') || {}).value || null
      }
    }
  },

  mounted () {
    this.loadTree(this.xrefField)
  },

  methods: {
    handleCheckChange () {
      this.$emit('input', this.$refs.tree.getCheckedKeys())
    },

    async loadTree (xrefField) {
      if (!xrefField) {
        return false
      }
      this.loading = true
      let registryData = await this.$http.get(`${this.$config.api}/objecteditor/entities/${xrefField}`)
      let registryId = registryData.data.data.object_id
      if (!registryId) {
        return false
      }
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      this.treeData = data.data.data
      this.$refs.tree.setCheckedKeys(this.localValue)
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
