export default {
  getTreeData,
  each,
  parseFilters
}

export function each (data, callback, childName = 'children') {
  let current
  let children
  for (let i = 0, len = data.length; i < len; i++) {
    current = data[i]
    children = []
    if (current[childName] && current[childName].length > 0) {
      children = current[childName]
    }
    callback && callback(current, children)
    if (children.length > 0) {
      each(children, callback, childName)
    }
  }
}

export function getTreeData (
  data,
  id = 'id',
  val = '',
  name = 'name',
  children = 'children',
  isRow = false
) {
  let arr = []
  each(
    data,
    item => {
      if (item[id] === val) {
        arr.push(item)
      }
    },
    children
  )
  return arr.length > 0 ? (isRow ? arr : arr[0][name]) : null
}

export function parseFilters (filters, fields = [], root = true) {
  if (!filters) {
    return {
      logical_operator: 'and',
      children: []
    }
  }

  let data = null

  for (const [key, value] of Object.entries(filters)) {
    if (['and', 'or'].includes(key)) {
      // Group
      if (root) {
        data = {
          logical_operator: key,
          children: []
        }

        if (typeof value === 'object') {
          value.forEach((filter) => {
            const result = parseFilters(filter, fields, false)

            if (result) {
              data.children.push(result)
            }
          })
        }
      } else {
        data = {
          type: 'condition_group',
          query: {
            logical_operator: key,
            children: []
          }
        }

        if (typeof value === 'object') {
          value.forEach((filter) => {
            const result = parseFilters(filter, fields, false)

            if (result) {
              data.query.children.push(result)
            }
          })
        }
      }
    }

    const contains = { contains_string: 'like', not_contains_string: 'not_like' }
    if (['eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte'].includes(key)) {
      // Rule
      const fieldName = Object.keys(value)[0]
      const field = fields.find(f => f.name === fieldName)
      if (field) {
        data = {
          type: 'condition',
          query: {
            id: field.id,
            field: fieldName, // Из value Получить имя свойства
            field_type: field.entity_type_id,
            filter_type: 'constant',
            operator: key,
            value: value[fieldName] // Из value[имя свойства] Получить значение
          }
        }
      }
    } else if (['contains_string', 'not_contains_string'].includes(key)) {
      const fieldName = Object.keys(value)[0]
      const field = fields.find(f => f.name === fieldName)
      if (field) {
        data = {
          type: 'condition',
          query: {
            id: field.id,
            field: fieldName, // Из value Получить имя свойства
            field_type: field.entity_type_id,
            filter_type: 'constant',
            operator: contains[key],
            value: value[fieldName] // Из value[имя свойства] Получить значение
          }
        }
      }
    } else if (['is_null', 'is_not_null'].includes(key)) {
      const field = fields.find(f => f.name === value)
      if (field) {
        data = {
          type: 'condition',
          query: {
            id: field.id,
            field: value,
            field_type: field.entity_type_id,
            filter_type: 'constant',
            operator: key,
            value: null
          }
        }
      }
    }
  }

  return data
}
