import CommandInterface from '@/core/application/command/CommandInterface'
import { Request } from '@/services/ApiService/domain/model/Request/Request'

export default class UpdateRequestEntityCommand implements CommandInterface {
  private dto: Request;
  private entityType: string;

  constructor (
    dto: Request,
    entityType: string
  ) {
    this.dto = dto
    this.entityType = entityType
  }

  getClassName (): string {
    return 'UpdateRequestEntityCommand'
  }

  getDto (): Request {
    return this.dto
  }

  getEntityType (): string {
    return this.entityType
  }
}
