
import mixins from 'vue-typed-mixins'
import MessageNotify from '@/components/System/Notification/MessageNotify.vue'
import mixin from '@/components/System/Notification/mixins/index'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default mixins(mixin).extend({
  name: 'Notification',

  components: {
    MessageNotify
  },

  props: ['addMainTab'],

  watch: {
    message: {
      handler: function (message) {
        this.onSoketMessage(message)
      }
    }
  },

  computed: {
    ...mapGetters([
      'message',
      'isConnected'
    ]),

    ...mapGetters('Notify', ['isEnd']),

    ...mapState('Notify', [
      'messages',
      'loading',
      'numberUnreadMessages'
    ])
  },

  data () {
    return {
      key: 1,
      visible: false
    }
  },

  methods: {
    ...mapMutations('Notify', [
      'soketPushMessage'
    ]),

    ...mapActions('Notify', [
      'firstLoad',
      'loadMessages',
      'readMessage',
      'readAllMessages'
    ]),

    async onSoketMessage (message) {
      if (!message) {
        return
      }

      try {
        // await new Messages().params({ limit: 1 }).$get()

        await this.soketPushMessage(message)

        if (message.notification.shows_popup_message) {
          const h = this.$createElement

          this.$notify({
            title: '',
            message: h(MessageNotify, {
              key: this.key++,
              props: {
                message,
                addMainTab: this.addMainTab
              }
            }),
            customClass: 'platform-notification',
            duration: 5000,
            position: 'bottom-right',
            showClose: false
          })
        }
      } catch (error) {
        this.$store.dispatch('WSDisconnect')
        console.log(error, 'not messages')
      }
    },

    async onReadMessages (message) {
      if (message) {
        await this.readMessage(message)
      } else {
        await this.readAllMessages()
      }
    },

    onShowMessage (message) {
      message.is_show = !message.is_show
    },

    onOpenNotifyCenter () {
      this.addMainTab({
        name: 'Центр уведомлений',
        componentType: 'NotificationCenter'
      })

      this.visible = false
    }
  },

  async created () {
    await this.firstLoad()
  }
})
