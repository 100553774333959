import CommandInterface from '@/core/application/command/CommandInterface'
import { ProcedureDTO } from '@/services/LogicEditor/domain/model/Procedure'

export default class ProcedureCreateCommand implements CommandInterface {
  constructor (private readonly dto: ProcedureDTO) {}

  getClassName (): string {
    return 'ProcedureCreateCommand'
  }

  getDTO (): ProcedureDTO {
    return this.dto
  }
}
