
import Vue from 'vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import TaskRegistryEntityUpdateCommand from '@/services/TaskEditor/application/command/TaskRegistryEntityUpdateCommand'
import TreeSelect from '@/components/Common/TreeSelect.vue'

export default Vue.extend({
  name: 'TaskRegistryForm',

  props: {
    value: Object
  },

  components: {
    RegistrySelectTree,
    TreeSelect
  },

  inject: ['getCommandBus'],

  computed: {
    isLoading () {
      return this.$store.getters['TaskRegistryEntity/isTaskRegistryEntitiesLoading']
    },

    rules () {
      const uniqueValidator = async (rule, value, callback) => {
        if (value) {
          const res = await this.$http.post(`${this.$config.api}/taskeditor/task_registry_entities/exists`, {
            property: rule.field,
            entity_id: value
          }, {
            hideNotification: true
          })

          if (res.data && res.data.exists) {
            callback(new Error('Выбранное поле уже занято другим свойством'))
          } else {
            callback()
          }
        }
      }

      return {
        registry: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.registry')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        create_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.create_date')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        author: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.author')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        executors: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.executors')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        type: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.type')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        status: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.status')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        plan_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.plan_date')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        name: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.name')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        content: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.content')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        fact_date: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.fact_date')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        status_id: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.status_id')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        rule_id: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.rule_id')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ],
        is_done: [
          { required: true, message: `Выберите значение в поле "${this.$t('task_editor.config.properties.is_done')}"`, trigger: 'change' },
          { validator: uniqueValidator, trigger: 'change' }
        ]
      }
    }
  },

  data () {
    return {
      model: {
        registry: null,
        create_date: null,
        author: null,
        executors: null,
        type: null,
        status: null,
        plan_date: null,
        name: null,
        content: null,
        fact_date: null,
        status_id: null,
        rule_id: null,
        is_done: null
      },

      fields: [],

      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id === 'field_group'
        }
      }
    }
  },

  mounted () {
    this.model = this.value

    this.loadFields(this.model.registry)
  },

  methods: {
    submit (callback: Function): void {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('input', this.model)

          this.getCommandBus()
            .execute(new TaskRegistryEntityUpdateCommand(this.model))
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    loadFields (value: number) {
      if (value) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${value}&show_children=true`)
          .then((res) => {
            this.fields = res.data.data
          })
      }
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    }
  }
})
