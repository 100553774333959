import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TaskRegistryEntityCommandRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityCommandRepositoryInterface'
import TaskRegistryEntityUpdateCommand from '@/services/TaskEditor/application/command/TaskRegistryEntityUpdateCommand'

export default class TaskRegistryEntityUpdateHandler implements CommandHandlerInterface {
    private repository: TaskRegistryEntityCommandRepositoryInterface;

    constructor (repository: TaskRegistryEntityCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TaskRegistryEntityUpdateCommand): Promise<void> {
      return this.repository.update(command.getPayload())
    }
}
