
import Vue from 'vue'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

export const getDefaultValue = function () {
  return Object.assign({}, {
    user_type: 'list',
    user_id: null,
    state_id: null,
    field_id: null
  })
}

export default Vue.extend({
  name: 'UsersSelect',

  components: {
    RegistrySelectTree
  },

  props: {
    value: {
      type: Object,
      default () {
        return getDefaultValue()
      }
    },

    userObjectId: Number,
    targetObjectId: Number
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  computed: {
    types () {
      return [
        { label: this.$t('logic_editor_v2.labels.list_users'), value: 'list' },
        { label: this.$t('logic_editor_v2.labels.table_field'), value: 'field' },
        { label: this.$t('logic_editor_v2.labels.state'), value: 'state' }
      ]
    }
  },

  data () {
    return {
      model: getDefaultValue(),
      users: []
    }
  },

  async mounted () {
    if (this.value !== null) {
      this.model = this.value
    } else {
      this.model = getDefaultValue()
    }

    if (!this.users.length) {
      try {
        this.users = await APIClient.shared.request(new UserAPI.GetUsers([]))
      } catch (error) {
        console.log({ error })
      }
    }
  },

  methods: {
    changeType (value: string): void {
      this.model.state_id = null
      this.model.user_id = null
      this.model.field_id = null
    }
  }
})
