import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CoordinateSystemCommandRepositoryInterface from '@/services/MapEditor/domain/repository/CoordinateSystemCommandRepositoryInterface'
import CoordinateSystemUpdateCommand from '@/services/MapEditor/application/command/CoordinateSystemUpdateCommand'
import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export default class CoordinateSystemUpdateHandler implements CommandHandlerInterface
{
    private repo: CoordinateSystemCommandRepositoryInterface;

    constructor(repo: CoordinateSystemCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: CoordinateSystemUpdateCommand): Promise<void>
    {
        let item: CoordinateSystemDTO = {
            srid: command.getSrid(),
            auth_name: command.getName(),
            proj4text: command.getProj4text()
        };
        return this.repo.update(item);
    }
}