import CommandInterface from '@/core/application/command/CommandInterface'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'

export default class CommandCreateCommand implements CommandInterface {
  constructor (private readonly dto: CommandDTO) {}

  getClassName (): string {
    return 'CommandCreateCommand'
  }

  getDTO (): CommandDTO {
    return this.dto
  }
}
