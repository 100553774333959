import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LogicTreeElementsTreeQuery from '@/services/LogicEditor/application/query/LogicTreeElementsTreeQuery'
import LogicTreeElementQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicTreeElementQueryRepositoryInterface'

export default class LogicTreeElementsTreeHandler implements QueryHandlerInterface {
  private repository: LogicTreeElementQueryRepositoryInterface

  constructor (repository: LogicTreeElementQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: LogicTreeElementsTreeQuery): Promise<any> {
    return this.repository.getTree(query.getElementType(), query.getLogicId())
  }
}
