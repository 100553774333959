<template>
  <el-dropdown size="small" trigger="click" @command="change">
    <span class="label">
      {{ text }}
    </span>
    <!--    <div class="options" slot="dropdown">
          <div v-for="(option, index) in options"
               class="list_item"
               :key="index"
               @click="change(option.id)"
          >
            {{ option.name }}
          </div>
        </div>-->
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="option in options"
        :key="option.id"
        :command="option.id"
      >{{ option.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'StringSelect',
  props: {
    value: {},
    options: {
      type: Array,
      default: () => []
    },
    placeholder: String
  },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    text () {
      return this.options.find((item) => item.id === this.value)?.name
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../variables.scss';

.label {
  font-size: 14px;
  font-weight: bold;
  color: #949FA8;
  cursor: pointer;
}

.options {
  & .list_item {
    font-size: 14px;
    padding: 10px 20px;

    &:hover {
      background: $hover_block_color;
      cursor: pointer;
      color: $active_text_color;
    }
  }
}
</style>
