import CssClassQueryRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassQueryRepositoryInterface'
import CssClassTreeByGuidQuery from '@/services/CssEditor/application/query/CssClassTreeByGuidQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import { CssClassTreeDTO } from '@/services/CssEditor/domain/model/CssClass'

export default class CssClassTreeByGuidQueryHandler implements QueryHandlerInterface {
  private repo: CssClassQueryRepositoryInterface

  constructor (repository: CssClassQueryRepositoryInterface) {
    this.repo = repository
  }

  execute (query: CssClassTreeByGuidQuery): Promise<CssClassTreeDTO|null> {
    return this.repo.getTreeByGuid(query.getGuid())
  }
}
