
import Vue from 'vue'
import MainEditorLayout from '@/services/TaskEditor/infrastructure/components/MainEditorLayout.vue'
import mixins from '@/services/TaskEditor/mixins'

export default Vue.extend({
  name: 'TaskEditor',

  mixins: [mixins],

  components: {
    MainEditorLayout
  },

  computed: {
    isConfiguredTaskRegistry () {
      return this.taskRegistryId !== null
    },

    isLoading () {
      return this.$store.getters['TaskRegistryEntity/isTaskRegistryEntitiesLoading']
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  methods: {
    getEventBus () {
      return this.eventBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getQueryBus () {
      return this.queryBus
    }
  }
})
