import CommandCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandCommandRepositoryInterface'
import store from '@/store'
import { CreateCommand, UpdateCommand, DeleteCommand } from '@/services/LogicEditor/infrastructure/store/modules/Command/action-types'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'

export default class CommandCommandRepository implements CommandCommandRepositoryInterface {
  insert (item: CommandDTO): Promise<void> {
    return store.dispatch(new CreateCommand(item))
  }

  update (item: CommandDTO): Promise<void> {
    return store.dispatch(new UpdateCommand(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteCommand(guid))
  }
}
