import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import ProjectTreeElementType from './ProjectTreeElementType'
import Project from './Project'
import ProjectGroup from './ProjectGroup'


export class ProjectTreeElementDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    row_order?: Nullable<number>;
    parent_id?: Nullable<number>;
    tree_id?: Nullable<number>;
    project_id?: Nullable<number>;
    group_id?: Nullable<number>;
    element_type?: Nullable<string>;
    element_guid?: Nullable<string>;

    constructor({ element_type }: {element_type?: Nullable<string>}) {
        this.element_type = element_type;
    }
}

export default class ProjectTreeElement extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private description?: Nullable<string>;
    private rowOrder?: Nullable<number> = 0;
    private parentId?: Nullable<number>;
    private treeId?: Nullable<number>;
    private projectId?: Nullable<number>;
    private groupId?: Nullable<number>;
    private elementType?: Nullable<string>;
    private elementGuid?: Nullable<string>;
    private project?: Nullable<Project>;
    private group?: Nullable<ProjectGroup>;

    constructor(
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name?: Nullable<string>,
        description?: Nullable<string>,
        rowOrder?:Nullable<number>,
        parentId?: Nullable<number>,
        treeId?: Nullable<number>,
        projectId?: Nullable<number>,
        groupId?: Nullable<number>,
        elementType?: Nullable<string>,
        elementGuid?: Nullable<string>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.rowOrder = rowOrder;
        this.parentId = parentId;
        this.treeId = treeId;
        this.projectId = projectId;
        this.groupId = groupId;
        this.elementType = elementType;
        this.elementGuid = elementGuid;
        if (elementType == ProjectTreeElementType.PROJECT) {
            this.project = Project.create({id: projectId, guid: elementGuid, name: name, description: description});
            this.group = null;
        } else if (elementType == ProjectTreeElementType.GROUP) {
            this.group = ProjectGroup.create({id: groupId, guid: elementGuid, name: name});
            this.project = null;
        }
    }

    static create(dto: ProjectTreeElementDTO): ProjectTreeElement
    {
        return new ProjectTreeElement(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.row_order,
            dto.parent_id,
            dto.tree_id,
            dto.project_id,
            dto.group_id,
            dto.element_type,
            dto.element_guid
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setRowOrder(rowOrder: number): void
    {
        this.rowOrder = rowOrder;
    }

    getRowOrder(): number
    {
        return this.rowOrder;
    }

    getProject(): Nullable<Project>
    {
        return this.project;
    }

    getProjectId(): Nullable<number>
    {
        return this.projectId;
    }

    getGroup(): Nullable<ProjectGroup>
    {
        return this.group;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getElementType(): string
    {
        return this.elementType;
    }

    getElementGuid(): string
    {
        return this.elementGuid;
    }
}