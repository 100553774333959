import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import LayerType from './LayerType'
import Source from '../Source/Source'
import LayerTreeElementType from './LayerTreeElementType'

export class LayerDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    description?: string;
    source_id?: number;
    type?: string;
    layer_type_id?: string;
    projection_type?: string = 'coordinate_system';
    srs_handling_type?: string = 'keep_native';    
    declared_coordinate_system_id?: number;
    native_coordinate_system_id?: Nullable<number>;
    declared_coordinate_system?: any;
    native_coordinate_system?: any;
    point_style_id?: Nullable<number>;
    linestring_style_id?: Nullable<number>;
    polygon_style_id?: Nullable<number>;
    label_style_id?: Nullable<number>;
    point_style?: any;
    linestring_style?: any;
    polygon_style?: any;
    label_style?: any;
    icon_style_type_id?: Nullable<string>;
    constraint_filter?: Nullable<string>;
    is_clustered?: boolean = false;
    native_bbox_xmin?: Nullable<number>;
    native_bbox_ymin?: Nullable<number>;
    native_bbox_xmax?: Nullable<number>;
    native_bbox_ymax?: Nullable<number>;
    latlon_bbox_xmin?: Nullable<number>;
    latlon_bbox_ymin?: Nullable<number>;
    latlon_bbox_xmax?: Nullable<number>;
    latlon_bbox_ymax?: Nullable<number>;
    properties?: object = {};
    source?: object = {};
    project_id?: number;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, description, source_id, type, projection_type, srs_handling_type, declared_coordinate_system_id, native_coordinate_system_id, declared_coordinate_system, native_coordinate_system, point_style_id, linestring_style_id, polygon_style_id, label_style_id, point_style, linestring_style, polygon_style, label_style, icon_style_type_id, constraint_filter, is_clustered, native_bbox_xmin, native_bbox_ymin, native_bbox_xmax, native_bbox_ymax, latlon_bbox_xmin, latlon_bbox_ymin, latlon_bbox_xmax, latlon_bbox_ymax, properties, source, project_id, parent_id}: {id?: Nullable<number>, guid?: Nullable<string>, name?: string, description?: string, source_id?: number, type?: string, projection_type?: string, srs_handling_type?: string, declared_coordinate_system_id?: number, native_coordinate_system_id?: Nullable<number>, declared_coordinate_system?: any, native_coordinate_system?: any, point_style_id?: Nullable<number>, linestring_style_id?: Nullable<number>, polygon_style_id?: Nullable<number>, label_style_id?: Nullable<number>, point_style?: any, linestring_style?: any, polygon_style?: any, label_style?: any, icon_style_type_id?: Nullable<string>, constraint_filter?: Nullable<string>, is_clustered?: boolean, native_bbox_xmin?: Nullable<number>, native_bbox_ymin?: Nullable<number>, native_bbox_xmax?: Nullable<number>, native_bbox_ymax?: Nullable<number>, latlon_bbox_xmin?: Nullable<number>, latlon_bbox_ymin?: Nullable<number>, latlon_bbox_xmax?: Nullable<number>, latlon_bbox_ymax?: Nullable<number>, properties?: object, source?: object, project_id?: number, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.source_id = source_id;
        this.type = type;
        this.projection_type = projection_type;
        this.srs_handling_type = srs_handling_type ?? 'keep_native';
        this.declared_coordinate_system_id = declared_coordinate_system_id;
        this.native_coordinate_system_id = native_coordinate_system_id;
        this.declared_coordinate_system = declared_coordinate_system;
        this.native_coordinate_system = native_coordinate_system;
        this.point_style_id = point_style_id;
        this.linestring_style_id = linestring_style_id;
        this.polygon_style_id = polygon_style_id;
        this.label_style_id = label_style_id;
        this.point_style = point_style;
        this.linestring_style = linestring_style;
        this.polygon_style = polygon_style;
        this.label_style = label_style;
        this.icon_style_type_id = icon_style_type_id;
        this.constraint_filter = constraint_filter;
        this.is_clustered = is_clustered;
        this.native_bbox_xmin = native_bbox_xmin;
        this.native_bbox_ymin = native_bbox_ymin;
        this.native_bbox_xmax = native_bbox_xmax;
        this.native_bbox_ymax = native_bbox_ymax;
        this.latlon_bbox_xmin = latlon_bbox_xmin;
        this.latlon_bbox_ymin = latlon_bbox_ymin;
        this.latlon_bbox_xmax = latlon_bbox_xmax;
        this.latlon_bbox_ymax = latlon_bbox_ymax;
        this.properties = properties;
        this.source = source;
        this.project_id = project_id;
        this.parent_id = parent_id;
    }
}

export default class Layer extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private sourceId: number;
    private type: LayerType;
    private projectionType: string;
    private srsHandling: string;
    private declaredCoordinateSystemId: number;
    private nativeCoordinateSystemId: Nullable<number>;
    private declaredCoordinateSystem: any;
    private nativeCoordinateSystem: any;
    private pointStyleId: Nullable<number>;
    private linestringStyleId: Nullable<number>;
    private polygonStyleId: Nullable<number>;
    private labelStyleId: Nullable<number>;
    private pointStyle: any;
    private iconStyleTypeId: Nullable<string>;
    private linestringStyle: any;
    private polygonStyle: any;
    private labelStyle: any;
    private constraintFilter: Nullable<string>;
    private isClustered: boolean;
    private nativeBboxXmin: Nullable<number>;
    private nativeBboxYmin: Nullable<number>;
    private nativeBboxXmax: Nullable<number>;
    private nativeBboxYmax: Nullable<number>;
    private latlonBboxXmin: Nullable<number>;
    private latlonBboxYmin: Nullable<number>;
    private latlonBboxXmax: Nullable<number>;
    private latlonBboxYmax: Nullable<number>;
    private properties: object;
    private source: Nullable<Source>;
    private projectId: number;
    private parentId: Nullable<number>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        sourceId: number,
        type: LayerType,
        projectionType: string,
        srsHandling: string,
        declaredCoordinateSystemId: number,
        nativeCoordinateSystemId: Nullable<number>,
        declaredCoordinateSystem: any,
        nativeCoordinateSystem: any,
        pointStyleId: Nullable<number>,
        linestringStyleId: Nullable<number>,
        polygonStyleId: Nullable<number>,
        labelStyleId: Nullable<number>,
        pointStyle: any,
        linestringStyle: any,
        polygonStyle: any,
        labelStyle: any,
        iconStyleTypeId: Nullable<string>,
        constraintFilter: Nullable<string>,
        isClustered: boolean = false,
        nativeBboxXmin: Nullable<number>,
        nativeBboxYmin: Nullable<number>,
        nativeBboxXmax: Nullable<number>,
        nativeBboxYmax: Nullable<number>,
        latlonBboxXmin: Nullable<number>,
        latlonBboxYmin: Nullable<number>,
        latlonBboxXmax: Nullable<number>,
        latlonBboxYmax: Nullable<number>,
        properties: object = {},
        source: Nullable<Source>,
        projectId: number,
        parentId: Nullable<number>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.sourceId = sourceId;
        this.type = type;
        this.projectionType = projectionType;
        this.srsHandling = srsHandling;
        this.declaredCoordinateSystemId = declaredCoordinateSystemId;
        this.nativeCoordinateSystemId = nativeCoordinateSystemId;
        this.declaredCoordinateSystem = declaredCoordinateSystem;
        this.nativeCoordinateSystem = nativeCoordinateSystem;
        this.pointStyleId = pointStyleId;
        this.linestringStyleId = linestringStyleId;
        this.polygonStyleId = polygonStyleId;
        this.labelStyleId = labelStyleId;
        this.pointStyle = pointStyle;
        this.linestringStyle = linestringStyle;
        this.polygonStyle = polygonStyle;
        this.labelStyle = labelStyle;
        this.iconStyleTypeId = iconStyleTypeId;
        this.constraintFilter = constraintFilter;
        this.isClustered = isClustered;
        this.nativeBboxXmin = nativeBboxXmin;
        this.nativeBboxYmin = nativeBboxYmin;
        this.nativeBboxXmax = nativeBboxXmax;
        this.nativeBboxYmax = nativeBboxYmax;
        this.latlonBboxXmin = latlonBboxXmin;
        this.latlonBboxYmin = latlonBboxYmin;
        this.latlonBboxXmax = latlonBboxXmax;
        this.latlonBboxYmax = latlonBboxYmax;
        this.properties = properties;
        this.source = source;
        this.projectId = projectId;
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: LayerDTO): Layer
    {
        let type = dto.type || dto.layer_type_id;
        return new Layer(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.source_id,
            LayerType[type.toUpperCase()],
            dto.projection_type,
            dto.srs_handling_type,
            dto.declared_coordinate_system_id,
            dto.native_coordinate_system_id,
            dto.declared_coordinate_system,
            dto.native_coordinate_system,
            dto.point_style_id,
            dto.linestring_style_id,
            dto.polygon_style_id,
            dto.label_style_id,
            dto.point_style,
            dto.linestring_style,
            dto.polygon_style,
            dto.label_style,
            dto.icon_style_type_id,
            dto.constraint_filter,
            dto.is_clustered,
            dto.native_bbox_xmin,
            dto.native_bbox_ymin,
            dto.native_bbox_xmax,
            dto.native_bbox_ymax,
            dto.latlon_bbox_xmin,
            dto.latlon_bbox_ymin,
            dto.latlon_bbox_xmax,
            dto.latlon_bbox_ymax,
            dto.properties,
            typeof dto.source != "undefined" ? Source.create(dto.source) : null,
            dto.project_id,
            dto.parent_id
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: Nullable<string>): void
    {
        this.description = description;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    setSourceId(sourceId: number): void
    {
        this.sourceId = sourceId;
    }

    getSourceId(): number
    {
        return this.sourceId;
    }

    setLayerType(type: LayerType): void
    {
        this.type = type;
    }

    getLayerType(): LayerType
    {
        return this.type;
    }

    setProjectionType(projectionType: string): void
    {
        this.projectionType = projectionType;
    }

    getProjectionType(): string
    {
        return this.projectionType;
    }

    setSrsHandling(srsHandling: string): void
    {
        this.srsHandling = srsHandling;
    }

    getSrsHandling(): string
    {
        return this.srsHandling;
    }

    setDeclaredCoordinateSystemId(declaredCoordinateSystemId: number): void
    {
        this.declaredCoordinateSystemId = declaredCoordinateSystemId;
    }

    getDeclaredCoordinateSystemId(): number
    {
        return this.declaredCoordinateSystemId;
    }

    setNativeCoordinateSystemId(nativeCoordinateSystemId: Nullable<number>): void
    {
        this.nativeCoordinateSystemId = nativeCoordinateSystemId;
    }

    getNativeCoordinateSystemId(): Nullable<number>
    {
        return this.nativeCoordinateSystemId;
    }

    setDeclaredCoordinateSystem(declaredCoordinateSystem: any): void
    {
        this.declaredCoordinateSystem = declaredCoordinateSystem;
    }

    getDeclaredCoordinateSystem(): any
    {
        return this.declaredCoordinateSystem;
    }

    setNativeCoordinateSystem(nativeCoordinateSystem: any): void
    {
        this.nativeCoordinateSystem = nativeCoordinateSystem;
    }

    getNativeCoordinateSystem(): any
    {
        return this.nativeCoordinateSystem;
    }

    setPointStyleId(pointStyleId: Nullable<number>): void
    {
        this.pointStyleId = pointStyleId;
    }

    getPointStyleId(): Nullable<number>
    {
        return this.pointStyleId;
    }

    setLinestringStyleId(linestringStyleId: Nullable<number>): void
    {
        this.linestringStyleId = linestringStyleId;
    }

    getLinestringStyleId(): Nullable<number>
    {
        return this.linestringStyleId;
    }

    setPolygonStyleId(polygonStyleId: Nullable<number>): void
    {
        this.polygonStyleId = polygonStyleId;
    }

    getPolygonStyleId(): Nullable<number>
    {
        return this.polygonStyleId;
    }

    setLabelStyleId(labelStyleId: Nullable<number>): void
    {
        this.labelStyleId = labelStyleId;
    }

    getLabelStyleId(): Nullable<number>
    {
        return this.labelStyleId;
    }

    setPointStyle(pointStyle: any): void
    {
        this.pointStyle = pointStyle;
    }

    getPointStyle(): any
    {
        return this.pointStyle;
    }

    setLinestringStyle(linestringStyle: any): void
    {
        this.linestringStyle = linestringStyle;
    }

    getLinestringStyle(): any
    {
        return this.linestringStyle;
    }

    setPolygonStyle(polygonStyle: any): void
    {
        this.polygonStyle = polygonStyle;
    }

    getPolygonStyle(): any
    {
        return this.polygonStyle;
    }

    setLabelStyle(labelStyle: any): void
    {
        this.labelStyle = labelStyle;
    }

    getLabelStyle(): any
    {
        return this.labelStyle;
    }

    setIconStyleTypeId(iconStyleTypeId: Nullable<string>): void
    {
        this.iconStyleTypeId = iconStyleTypeId;
    }

    getIconStyleTypeId(): Nullable<string>
    {
        return this.iconStyleTypeId;
    }

    setConstraintFilter(constraintFilter: Nullable<string>): void
    {
        this.constraintFilter = constraintFilter;
    }

    getConstraintFilter(): Nullable<string>
    {
        return this.constraintFilter;
    }

    setIsClustered(isClustered: boolean): void
    {
        this.isClustered = isClustered;
    }

    getIsClustered(): boolean
    {
        return this.isClustered;
    }

    setNativeBboxXmin(nativeBboxXmin: Nullable<number>): void
    {
        this.nativeBboxXmin = nativeBboxXmin;
    }

    getNativeBboxXmin(): Nullable<number>
    {
        return this.nativeBboxXmin;
    }

    setNativeBboxYmin(nativeBboxYmin: Nullable<number>): void
    {
        this.nativeBboxYmin = nativeBboxYmin;
    }

    getNativeBboxYmin(): Nullable<number>
    {
        return this.nativeBboxYmin;
    }

    setNativeBboxXmax(nativeBboxXmax: Nullable<number>): void
    {
        this.nativeBboxXmax = nativeBboxXmax;
    }

    getNativeBboxXmax(): Nullable<number>
    {
        return this.nativeBboxXmax;
    }

    setNativeBboxYmax(nativeBboxYmax: Nullable<number>): void
    {
        this.nativeBboxYmax = nativeBboxYmax;
    }

    getNativeBboxYmax(): Nullable<number>
    {
        return this.nativeBboxYmax;
    }

    setLatlonBboxXmin(latlonBboxXmin: Nullable<number>): void
    {
        this.latlonBboxXmin = latlonBboxXmin;
    }

    getLatlonBboxXmin(): Nullable<number>
    {
        return this.latlonBboxXmin;
    }

    setLatlonBboxYmin(latlonBboxYmin: Nullable<number>): void
    {
        this.latlonBboxYmin = latlonBboxYmin;
    }

    getLatlonBboxYmin(): Nullable<number>
    {
        return this.latlonBboxYmin;
    }

    setLatlonBboxXmax(latlonBboxXmax: Nullable<number>): void
    {
        this.latlonBboxXmax = latlonBboxXmax;
    }

    getLatlonBboxXmax(): Nullable<number>
    {
        return this.latlonBboxXmax;
    }

    setLatlonBboxYmax(latlonBboxYmax: Nullable<number>): void
    {
        this.latlonBboxYmax = latlonBboxYmax;
    }

    getLatlonBboxYmax(): Nullable<number>
    {
        return this.latlonBboxYmax;
    }

    setProperties(properties: object): void
    {
        this.properties = properties;
    }

    getProperties(): object
    {
        return this.properties;
    }

    setProjectId(projectId: number): void
    {
        this.projectId = projectId;
    }

    getSource(): Source
    {
        return this.source;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getType(): LayerTreeElementType
    {
        return LayerTreeElementType.LAYER;
    }
}