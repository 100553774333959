import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  GetCssClasses,
  GetCssClassesTree,
  GetCssClassesSearch,
  GetCssClassByGuid,
  GetCssClassTreeByGuid
} from '@/services/CssEditor/infrastructure/store/modules/CssClass/action-types'
import CssClassQueryRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassQueryRepositoryInterface'
import { CssClassDTO, CssClassTreeDTO } from '@/services/CssEditor/domain/model/CssClass'

export default class CssClassQueryRepository extends AbstractQueryRepository implements CssClassQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<CssClassDTO[]> {
    return store.dispatch(new GetCssClasses(payload))
  }

  getByGuid (guid: string): Promise<CssClassDTO> {
    return store.dispatch(new GetCssClassByGuid(guid))
  }

  getTree (payload: string[][] | object): Promise<CssClassTreeDTO[]> {
    return store.dispatch(new GetCssClassesTree(payload))
  }

  search (payload: string[][] | object): Promise<CssClassTreeDTO[]> {
    return store.dispatch(new GetCssClassesSearch(payload))
  }

  getTreeByGuid (guid: string): Promise<CssClassTreeDTO> {
    return store.dispatch(new GetCssClassTreeByGuid(guid))
  }
}
