<template>
  <div class="array_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.mapping.labels[`name_${model.element_type_id}`]" prop="source_column">
        <el-input v-model="model.source_column" :placeholder="$locale.etl_editor_v2.mapping.labels[`name_${model.element_type_id}`]" @input="maskedElement"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.alias">
        <el-input v-model="model.source_alias" :placeholder="$locale.etl_editor_v2.mapping.labels.alias"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.description">
        <el-input v-model="model.description" :placeholder="$locale.etl_editor_v2.mapping.labels.description"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.loader_id">
        <el-select v-model="model.loader_id" :placeholder="$locale.etl_editor_v2.mapping.labels.loader_id" size="mini" clearable>
          <el-option v-for="item in loaders" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ArrayForm',

  props: {
    value: Object
  },

  inject: ['getLoaders'],

  watch: {
    value () {
      this.model = this.value
    }
  },

  data () {
    return {
      model: this.model,
      isLoaded: false,
      loaders: [],
      rules: {
        source_column: [{ required: true, message: this.$locale.etl_editor_v2.rules.required, trigger: 'blur' }]
      }
    }
  },

  mounted () {
    this.loaders = this.getLoaders()

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    maskedElement (value) {
      value = value.replace(/(\s)+/g, '-').replace(/[^_:.\-0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.model.source_column = value
    },

    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          callback()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped></style>
