import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RasterCommandRepositoryInterface from '@/services/RasterLibrary/domain/repository/RasterCommandRepositoryInterface'
import RasterCreateCommand from '@/services/RasterLibrary/application/command/RasterCreateCommand'
import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'

export default class RasterCreateHandler implements CommandHandlerInterface
{
    private repo: RasterCommandRepositoryInterface;

    constructor(repo: RasterCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RasterCreateCommand): Promise<void>
    {
        return this.repo.insert(command.getFormData());
    }
}