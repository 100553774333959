<template>
  <div class="temlate-interface">
    <el-dialog
      title="Вставить шаблон"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :modal="false"
    >
      <!-- <span>Куда вставить шаблон?</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Отмена</el-button>
        <el-button
          type="primary"
          @click="pasteTemplateIn(activeTemplate.content, $event)"
        >Выбранный контейнер</el-button
        >
        <el-button
          type="primary"
          @click="pasteTemplateOut(activeTemplate.content, $event)"
        >Отдельно</el-button
        >
      </span>
    </el-dialog>
    <div class="tag custom_scrollbar">
      <template>
        <el-tag
          v-show="tag.length"
          v-for="tag in tags"
          :key="tag"
          :type="activeTags.includes(tag) ? 'warning' : 'info'"
          style="cursor: pointer; margin: 5px"
          @click="selectTag(tag)"
        >
          {{ tag }}
        </el-tag>
      </template>
    </div>
    <div class="preview custom_scrollbar" v-loading="loading">
      <div class="image_box">
        <!-- <el-image v-show="loading" class="templateImage" v-loading="loading">
          <div slot="error" class="image-slot"></div>
        </el-image> -->
        <template v-if="templates.length > 1">
          <div class="main_block_template" v-for="template in templates" :key="template.id">
            <div
              class="templateImage"
              :style="{backgroundImage:`url(${$config.api}/files/${template.report_files.image.filename})`}"
              style="background-size: cover; background-repeat: no-repeat;height: calc(100% - 50px)"
              @click="selectTemplate(template.id)"
            >
            </div>

            <div class="description_box" @click="selectTemplate(template.id)">
              <div class="name">{{ template.name }}</div>
            </div>
          </div>
        </template>
        <!--        <template>
                  <div v-for="template in templates" :key="template.id">
                    <figure class="image-wrapper" @click="selectTemplate(template.id)">
                      <el-image
                        v-if="template.report_files && template.report_files.image.filename !== null"
                        style="height: 250px; width: 100%;"
                        :fit="'contain'"
                        :class="{ imageActive: template.id === activeTemplate.id }"
                        :src="`${$config.api}/files/${template.report_files.image.filename}`"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <el-image v-else style="height: 250px; width: 100%;">
                        <div slot="error" class="image-slot">
                          Изображение еще не загружено
                        </div>
                      </el-image>
                      <figcaption class="image-caption custom_scrollbar">
                        {{ template.name }}
                      </figcaption>
                    </figure>
                  </div>
                </template>-->
      </div>
    </div>
  </div>
</template>

<script>
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { interfaceTemplateAPI } from '@//services/InterfaceEditor/infrastructure/api/interfaceTemplateAPI'

export default {
  inject: ['getEventBus'],
  props: {
    activeBlock: Object
  },
  data () {
    return {
      templates: [
        { id: '', tags: '', name: '', report_files: { image: { filename: null } } }
      ],
      tags: [],
      centerDialogVisible: false,
      activeTemplate: {},
      activeTags: [],
      loading: false
    }
  },
  methods: {
    selectTag (tag) {
      if (this.activeTags.includes(tag)) {
        this.activeTags = this.activeTags.filter(item => item !== tag)
        return
      }
      this.activeTags.push(tag)
    },
    async selectTemplate (id) {
      let { content } = await APIClient.shared.request(new interfaceTemplateAPI.GetTemplateContent(id))
      this.activeTemplate = { id, content }
      this.activeBlock ? this.centerDialogVisible = true : this.pasteTemplateOut(content)
    },
    pasteTemplateOut (data) {
      console.log('pasteTemplateOut', data)
      this.getEventBus().$emit('pasteTemplateOut', data)
      this.centerDialogVisible = false
      this.activeTemplate = {}
      this.getEventBus().$emit('closePopover')
    },
    pasteTemplateIn (data) {
      console.log('pasteTemplateIn', data)
      this.getEventBus().$emit('pasteTemplateIn', data)
      this.centerDialogVisible = false
      this.activeTemplate = {}
      this.getEventBus().$emit('closePopover')
    },
    init () {
      // setTimeout нужен для плавной анимации изменение шиирины
      setTimeout(async () => {
        this.loading = true
        let templates = await APIClient.shared.request(new interfaceTemplateAPI.GetListInterfaceTemplate())
        this.templates = templates
        let tags = templates.reduce((acc, { tags }) => ((acc.push(tags), acc)), []).filter(item => !!item)[0]
        if (tags) {
          this.tags = tags.split(', ')
          // Оставить только уникальные теги
          this.tags = [...new Set(this.tags)]
        }
        this.loading = false
      }, 500)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.temlate-interface {
  padding: 20px;
  padding-top: 0;
}

.temlate-interface .tag {
  margin-bottom: 20px;
  margin-top: 12px;
  display: flex;
  min-height: 33px;
  flex-wrap: wrap;
  max-height: 85px;
  overflow-y: auto;
}

.temlate-interface .preview {
  background: #d2d6da;
  padding: 15px;
  height: 500px;
  overflow-y: auto;
}

.temlate-interface .image_box {
  position: relative;
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  grid-auto-rows: 50%;
  column-gap: 10px;
  row-gap: 10px;
}

.image-wrapper {
  position: relative;
  width: 250px;
  cursor: pointer;
  margin: 5px;
}

.image-wrapper img {
  position: relative;
  border-radius: 8px;
  border: 1px solid #4fa9f3;
  width: 100%;
}

.temlate-interface .el-image {
  height: 100%;
  background: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper img:hover,
.temlate-interface .imageActive {
  box-shadow: 0px -1px 10px 2px rgba(241, 206, 9, 1);
}

.image-caption {
  position: absolute;
  background-color: #ff000075;
  bottom: 6px;
  width: 100%;
  height: 50px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.562);
  margin-left: 1px;
  border-radius: 0 0 8px 8px;
  padding: 16px;
  padding-top: 12px;
  transition: 0.5s;
  box-shadow: 0px -20px 20px 0px rgb(34 60 80 / 20%);
  box-sizing: border-box;
}

.description_box {
  position: absolute;
  display: block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  height: 50px;
  overflow-y: auto;
  background: #fff;
  bottom: 0;
  border-radius: 0 0 8px 8px;
  padding: 16px;
  padding-top: 12px;
  transition: .5s;
  cursor: pointer;
  box-shadow: 0px -20px 20px 0px rgb(34 60 80 / 20%);
}

.description_box .name {
  color: #2D3943;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description_box .description {
  color: #788591;
  font-size: 13px;
}

.templateImage {
  transition: .5s;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  background: #fff;
}

.main_block_template {
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: .3s;
}
.main_block_template:hover{
  border: 1px solid #4FA9F3;
  /* filter: grayscale(0); */
}
</style>
