import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ConnectionDTO {
  id?: Nullable<number>;
  guid?: Nullable<string>;
  name?: Nullable<string>;
  description?: Nullable<string>;
  connection_type_id?: Nullable<string>;
  properties?: Array<string> = [];

  constructor ({ id, guid, name, description, connection_type_id, properties }:
    {
      id?: Nullable<number>,
      guid?: Nullable<string>,
      name?: Nullable<string>,
      description?: Nullable<string>,
      connection_type_id?: Nullable<string>,
      properties?: Array<string>
    }) {
          this.id = id
          this.guid = guid
          this.name = name
          this.description = description
          this.connection_type_id = connection_type_id
          this.properties = properties
  }
}

export default class Connection extends AbstractBaseEntity {
  private id?: Nullable<number>;
  private guid?: Nullable<string>;
  private name?: Nullable<string>;
  private description?: Nullable<string>;
  private connection_type_id?: Nullable<string>;
  private properties: Array<string>;

  constructor (
    id: Nullable<number>,
    guid: Nullable<string>,
    name: Nullable<string>,
    description: Nullable<string>,
    connection_type_id: Nullable<string>,
    properties: Array<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.connection_type_id = connection_type_id
    this.properties = properties
  }

  static create (dto: ConnectionDTO): Connection {
    return new Connection(
      dto.id,
      dto.guid,
      dto.name,
      dto.description,
      dto.connection_type_id,
      dto.properties
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setDescription (description: string): void {
    this.description = description
  }

  getDescription (): string {
    return this.description
  }

  setType (connection_type_id: string): void {
    this.connection_type_id = connection_type_id
  }

  getType (): string {
    return this.connection_type_id
  }

  setProperties (properties: Array<string>): void {
    this.properties = properties
  }

  getProperties (): Array<string> {
    return this.properties
  }
}
