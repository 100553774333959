import TransformerQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TransformerTypePropertiesQuery from '@/services/EtlEditor/application/query/TransformerTypePropertiesQuery'

export default class TransformerTypePropertiesHandler implements QueryHandlerInterface {
  private repository: TransformerQueryRepositoryInterface

  constructor (repository: TransformerQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TransformerTypePropertiesQuery): Promise<any> {
    return this.repository.getTypeProperties(query.getType())
  }
}
