import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'
import LogicGroupCreateCommand from '@/services/LogicEditor/application/command/LogicGroupCreateCommand'
import LogicGroupCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicGroupCommandRepositoryInterface'

export default class LogicGroupCreateHandler implements CommandHandlerInterface {
    private repository: LogicGroupCommandRepositoryInterface;

    constructor (repository: LogicGroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicGroupCreateCommand): Promise<void> {
      let item: LogicGroupDTO = {
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        logic_id: command.getLogicId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
