
import Vue from 'vue'
import Node from 'element-ui/packages/tree/src/model/node'
import LogicQuery from '@/services/LogicEditor/application/query/LogicQuery'
import LogicTreeElementsQuery from '@/services/LogicEditor/application/query/LogicTreeElementsQuery'
import LogicTreeElementByElementGuidQuery from '@/services/LogicEditor/application/query/LogicTreeElementByElementGuidQuery'
import LogicTreeElementsSearchQuery from '@/services/LogicEditor/application/query/LogicTreeElementsSearchQuery'
import Entity from '@/components/ObjectEditor/Models/Entity.js'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'
import { LogicTreeElementDTO } from '@/services/LogicEditor/domain/model/LogicTreeElement'

export default Vue.extend({
  name: 'TreeElementLayout',

  props: {
    createElement: {
      type: Function,
      default: function (elementType, parentNode) {
      }
    },

    updateElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    deleteElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    duplicateElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    close: {
      type: Function,
      default: function () {
      }
    },

    externalEntityGuid: {
      type: String,
      default: null
    }
  },

  inject: ['getQueryBus', 'addMainTab'],

  watch: {
    searchText () {
      if (!this.isSearchText) {
        this.isSearch = false
        this.searchData = []
      }
    }
  },

  computed: {
    treeProp () {
      /*
        ref="tree"
        node-key="guid"
        :key="isSearch ? 'searchTree' : 'defaultTree'"
        :lazy="!isSearch"
        :data="searchData"
        :draggable="!isSearch"
        :props="treeProps"
        :load="loadNode"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
       */
      return {
        ref: 'tree', // Vue ID компонента
        nodeKey: 'guid', // Основной уникальный ключ узла
        key: this.isSearch ? 'searchTree' : 'defaultTree', // Меняем ключ, что бы перерендерить компонент с новыми свойствами
        lazy: !this.isSearch, // Для поиска отключаем ленивую загрузку
        data: this.isSearch ? this.searchData : [], // Для поиска передаём найденные данные в дерево
        draggable: !this.isSearch, // Блокируем drag & drop если сейчас активен поиск
        props: this.treeProps,
        expandOnClickNode: false,
        load: this.loadNode,
        allowDrop: this.allowDrop,
        allowDrag: this.allowDrag,
        defaultExpandAll: this.isSearch
      }
    },

    isLogic () {
      return this.selectedNode !== null && typeof this.selectedNode.data.element_type === 'undefined'
    },

    isSearchText () {
      return !!this.searchText
    },

    isSearchLoading () {
      if (this.isSearch) {
        return this.$store.getters['LogicTreeElement/isLogicTreeElementLoading']
      }

      return false
    }
  },

  data () {
    return {
      searchText: null,
      isSearch: false,
      searchData: [],

      selectedNode: null,

      isEdit: false,

      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },

      icons: {
        group: 'el-icon-folder',
        formula: 'bs-icon-column-formula',
        row_formula: 'bs-icon-row-formula',
        state: 'bs-icon-state',
        listener: 'bs-icon-listener',
        command: 'bs-icon-command',
        constraint: 'bs-icon-constraint',
        view_constraint: 'bs-icon-view-field',
        disable_constraint: 'bs-icon-blocked-field',
        approval: 'bs-icon-approval-stages',
        related_object: 'bs-icon-related-object',
        procedure: 'el-icon-document-checked'
      },

      allowDuplicateElementTypes: [
        'formula', 'row_formula', 'state',
        'listener', 'command', 'constraint',
        'view_constraint', 'disable_constraint', 'approval',
        'related_object', 'procedure'
      ],

      elementTypes: [
        'group', 'formula', 'row_formula', 'state',
        'listener', 'command', 'constraint',
        'view_constraint', 'disable_constraint', 'approval',
        'related_object', 'procedure'
      ]
    }
  },

  methods: {
    allowDrop (draggingNode, dropNode, type) {
      if (dropNode.level === 0) {
        return false
      }

      if (typeof draggingNode.data.element_type !== 'undefined' && draggingNode.data.logic_id !== dropNode.data.logic_id) {
        return false
      }

      if (type === 'inner') {
        // inner drop
        if (draggingNode.data.id === dropNode.data.id || dropNode.data.element_type !== 'group') {
          return false
        }

        if (this.preventParentNodeFromInheritingChildNode(dropNode).indexOf(draggingNode.data.id) !== -1) {
          return false
        }

        if (dropNode.data.element_type === 'group') {
          return draggingNode.data.parent_id !== dropNode.data.id
        }
      } else {
        // before, after drop
        if (typeof draggingNode.data.element_type === 'undefined') {
          return false
        }
      }

      return true
    },

    allowDrag (draggingNode) {
      return typeof draggingNode.data.element_type !== 'undefined'
    },

    // Супер мега функция... Запрещает родительскому узлу перемещаться в дочерний узел, на любой уровень вложенности
    preventParentNodeFromInheritingChildNode (node) {
      let nodeKeys = []
      if (node !== null && typeof node.data !== 'undefined') {
        nodeKeys.push(node.data.parent_id)
        nodeKeys.push(...this.preventParentNodeFromInheritingChildNode(node.parent))
      }
      return nodeKeys
    },

    async nodeDrop (draggingNode, dropNode, dropType, ev) {
      let model = {}
      console.log(dropNode.data, draggingNode.data, dropType)
      if (dropType !== 'inner') {
        model = {
          target_id: draggingNode.data.parent_id === dropNode.data.parent_id ? draggingNode.data.parent_id : dropNode.data.parent_id,
          row_order: this.mediumRowOrder(draggingNode, dropNode)
        }
      } else {
        model = {
          target_id: dropNode ? dropNode.data.id : draggingNode.data.parent_id,
          row_order: draggingNode.data.row_order
        }
      }

      await this.$http.post(`${this.$config.api}/v2/logiceditor/logic_tree_elements/${draggingNode.data.id}/draggable`, model)
    },

    mediumRowOrder (draggingNode, node) {
      // children into parent
      const a = node.parent.childNodes
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          // console.log('совпадение', a[i].data.name, a[i].data.id)
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 330))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((100 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }

      return rowOrder
    },

    async openRegistry (data) {
      this.addMainTab({
        name: this.$t('main.system.object_editor'),
        componentType: 'ObjectEditor',
        payload: {
          entityId: data.entity_id,
          expanded: this.getExpanded(
            await new Entity({ id: data.entity_id }).expanded().$first()
          )
        }
      })
    },

    getExpanded (object) {
      let array = []
      if (object && object.parent) {
        array.push(object.parent.id)
        array.push(...this.getExpanded(object.parent))
      }
      return array
    },

    getLabel (node, data): string {
      const icon = `<span class="node-label__icon ${this.getTreeIcon(node)}"></span>`

      if (this.isSearch) {
        const searchClass = data.is_search
          ? ' is-search'
          : ''

        if (typeof data.element_type !== 'undefined') {
          if (data.element_type === 'group') {
            return `${icon}<span class="node-label__name${searchClass}">${node.label}</span><span class="node-label__info">(Реестр: ${data.entity_name} - ${data.entity_id})</span>`
          } else {
            return `${icon}<span class="node-label__name${searchClass}">${node.label}</span><span class="node-label__info">(id: ${data.element_id}) (Реестр: ${data.entity_name} - ${data.entity_id})</span>`
          }
        } else {
          return `${icon}<span class="node-label__name${searchClass}">${node.label}</span><span class="node-label__info">(БЛ: ${data.id}, Реестр: ${data.entity_id})</span>`
        }
      }

      if (typeof data.element_type !== 'undefined') {
        if (data.element_type === 'group') {
          return `${icon}<span class="node-label__name">${node.label}</span>`
        } else {
          return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.element_id})</span>`
        }
      } else {
        return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(БЛ: ${data.id}, Реестр: ${data.entity_id})</span>`
      }
    },

    getTreeIcon (node): string|[string]|null {
      // Если свойство element_type доступно, значит это элемент из logic_tree_elements
      if (node.data.element_type) {
        if (node.data.element_type === 'group') {
          return !node.expanded
            ? 'el-icon-folder'
            : 'el-icon-folder-opened'
        } else {
          if (this.icons[node.data.element_type]) {
            return [this.icons[node.data.element_type]]
          }
        }
      } else {
        // Если свойство element_type отсутствует, то значит текущий объект - logic
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      }

      return null
    },

    getTree () {
      return this.$refs.tree
    },

    closeEditor (): void {
      this.isEdit = false
      this.selectedNode = null
      this.close()
    },

    selected (node): void {
      if (this.selectedNode === node) {
        this.closeEditor()
        return
      }

      this.closeEditor()
      this.edit(node, node.data)
    },

    addNode (data, parentNode): void {
      if (parentNode) {
        this.$refs.tree.append(data, parentNode)
      } else {
        const node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: data
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    async getNodeByElementGuid (elementGuid): Promise<Node> {
      const treeElement = await this.getQueryBus().execute(
        new LogicTreeElementByElementGuidQuery(elementGuid)
      )

      return this.$refs.tree.getNode(treeElement.guid)
    },

    updateNode (node): void {
      this.selectedNode = node
    },

    add (elementType = null, parentNode = null): void {
      this.closeEditor()

      this.$nextTick(() => {
        this.selectedNode = parentNode
        this.createElement(elementType, parentNode)
      })
    },

    edit (node, data): void {
      if (this.isEdit) {
        return
      }

      this.selectedNode = node
      this.isEdit = true

      this.updateElement(node, data)
    },

    remove (node, data): void {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteElement(node, data)
        this.$refs.tree.remove(node)
      })
    },

    async search (): Promise<void> {
      if (!this.searchText.length) {
        return
      }

      this.isSearch = true
      this.searchData = await this.searchElements()
    },

    async searchElements (): Promise<LogicTreeElementDTO[]> {
      const expressions = []
      const elementId = parseInt(this.searchText)

      if (!isNaN(elementId)) {
        expressions.push({
          eq: {
            element_id: elementId
          }
        })

        expressions.push({
          eq: {
            entity_id: elementId
          }
        })
      }

      expressions.push({
        like: {
          name: `%${this.searchText}%`
        }
      })

      expressions.push({
        like: {
          element_guid: `%${this.searchText}%`
        }
      })

      expressions.push({
        like: {
          entity_name: `%${this.searchText}%`
        }
      })

      return this.getQueryBus().execute(
        new LogicTreeElementsSearchQuery({
          where: {
            or: expressions
          },
          order: 'element_type:desc,name:asc'
        })
      )
    },

    async loadNode (node, resolve): Promise<void> {
      if (node.level === 0) {
        // Logic (root)
        resolve(await this.loadElements())
      } else if (node.level === 1) {
        // TreeElement (root by logicId)
        resolve(await this.loadElements(node.data.id))
      } else {
        // TreeElement (children by parentId)
        resolve(await this.loadElements(node.data.logic_id, node.data.id))
      }

      if (this.externalEntityGuid) {
        this.$refs.tree.store.setDefaultExpandedKeys([this.externalEntityGuid])
      }
    },

    async loadElements (logicId = null, parentId = null): Promise<LogicDTO[] | LogicTreeElementDTO[]> {
      let elements = []

      if (!logicId && !parentId) {
        elements = await this.getQueryBus().execute(new LogicQuery({ order: 'name:asc' }))
      } else if (!parentId && logicId) {
        elements = await this.getQueryBus().execute(new LogicTreeElementsQuery({ is_null: 'parent_id', logic_id: logicId, order: 'element_type:desc,name:asc' }))
      } else {
        elements = await this.getQueryBus().execute(new LogicTreeElementsQuery({ parent_id: parentId, order: 'element_type:desc,name:asc' }))
      }

      return elements
    }
  }
})
