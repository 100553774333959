import { Nullable } from '@/core/domain/type/types'

export default class ConnectionUpdateCommand {
    private id: number;
    private guid: string;
    private name: string;
    private description: Nullable<string>;
    private type: string;
    private properties: Array<string>;

    constructor (
      id: number,
      guid: string,
      name: string,
      description: Nullable<string>,
      type: string,
      properties: Array<string>
    ) {
      this.id
      this.guid = guid
      this.name = name
      this.description = description
      this.type = type
      this.properties = properties
    }

    getClassName (): string {
      return 'ConnectionUpdateCommand'
    }

    getId(): number {
      return this.id
    }

    getGuid(): string {
      return this.guid
    }

    getName(): string {
      return this.name
    }

    getDescription(): Nullable<string> {
      return this.description
    }

    getType(): string {
      return this.type
    }

    getProperties(): Array<string> {
      return this.properties
    }
}
