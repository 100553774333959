import ExtractorCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorCommandRepositoryInterface'
import store from '@/store'
import { CreateExtractor, UpdateExtractor, DeleteExtractor } from '@/services/EtlEditor/infrastructure/store/modules/Extractor/action-types'
import { ExtractorDTO } from '@/services/EtlEditor/domain/model/Extractor'

export default class ExtractorCommandRepository implements ExtractorCommandRepositoryInterface {
  insert (item: ExtractorDTO): Promise<void> {
    return store.dispatch(new CreateExtractor(item))
  }

  update (item: ExtractorDTO): Promise<void> {
    return store.dispatch(new UpdateExtractor(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteExtractor(guid))
  }
}
