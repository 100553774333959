import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeAddChildElementCommand from '@/services/MapEditor/application/command/ProjectTreeAddChildElementCommand'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export default class ProjectTreeAddChildElementHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeAddChildElementCommand): Promise<void>
    {
        let item: ProjectTreeElementDTO = {
            id: command.getChildId(),
            parent_id: command.getParentId()
        };
        return this.repo.updateParent(item);
    }
}