import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LoaderQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderQueryRepositoryInterface'
import LoaderTypesQuery from '@/services/EtlEditor/application/query/LoaderTypesQuery'

export default class LoaderTypesHandler implements QueryHandlerInterface {
  private repository: LoaderQueryRepositoryInterface;

  constructor (repository: LoaderQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: LoaderTypesQuery): Promise<any> {
    return this.repository.getTypes()
  }
}
