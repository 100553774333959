import { Nullable } from '@/core/domain/type/types'

export default class SettingUpdateCommand
{
    private id: number;
    private name: string;
    private value: Nullable<string>;
    private description: Nullable<string>;

    constructor(id: number, name: string, value: Nullable<string>, description: Nullable<string>)
    {
        this.id = id;
        this.name = name;
        this.value = value;
        this.description = description;
    }

    getClassName(): string
    {
        return 'SettingUpdateCommand';
    }

    getId(): number
    {
        return this.id;
    }

    getName(): string
    {
        return this.name;
    }

    getValue(): Nullable<string>
    {
        return this.value;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

}