
import Vue from 'vue'
import DateInput from './components/DateInput.vue'
import DatetimeInput from './components/DatetimeInput.vue'
import FlagInput from './components/FlagInput.vue'
import FloatInput from './components/FloatInput.vue'
import NumberInput from './components/NumberInput.vue'
import StringInput from './components/StringInput.vue'
import TextInput from './components/TextInput.vue'
import TimeInput from './components/TimeInput.vue'
import XrefInput from './components/XrefInput.vue'
import XrefMultiInput from './components/XrefMultiInput.vue'

export default Vue.extend({
  name: 'Constant',

  props: {
    value: [Array, Number, String, Boolean],

    fieldType: String,

    placeholder: {
      type: String,
      default: ''
    },

    xrefList: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    DateInput,
    DatetimeInput,
    FlagInput,
    FloatInput,
    NumberInput,
    StringInput,
    TextInput,
    TimeInput,
    XrefInput,
    XrefMultiInput
  },

  computed: {
    componentId () {
      return typeof this.mappedFieldType[this.fieldType] !== 'undefined' ? this.mappedFieldType[this.fieldType] : null
    }
  },

  data () {
    return {
      mappedFieldType: {
        date_field: 'DateInput',
        datetime_field: 'DatetimeInput',
        boolean_field: 'FlagInput',
        float_field: 'FloatInput',
        integer_field: 'NumberInput',
        string_field: 'StringInput',
        text_field: 'TextInput',
        time_field: 'TimeInput',
        xref_field: 'XrefInput',
        xref_multi_field: 'XrefMultiInput'
      }
    }
  }
})
