import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ApprovalCreateCommand from '@/services/LogicEditor/application/command/ApprovalCreateCommand'
import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'

export default class ApprovalCreateHandler implements CommandHandlerInterface {
  private repository: ApprovalCommandRepositoryInterface

  constructor (repository: ApprovalCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ApprovalCreateCommand): Promise<void> {
    return this.repository.insert(command.getDTO())
  }
}
