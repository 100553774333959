import ListenerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ListenerCommandRepositoryInterface'
import store from '@/store'
import { CreateListener, UpdateListener, DeleteListener } from '@/services/LogicEditor/infrastructure/store/modules/Listener/action-types'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'

export default class ListenerCommandRepository implements ListenerCommandRepositoryInterface {
  insert (item: ListenerDTO): Promise<void> {
    return store.dispatch(new CreateListener(item))
  }

  update (item: ListenerDTO): Promise<void> {
    return store.dispatch(new UpdateListener(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteListener(guid))
  }
}
