<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.select_dashbord">
    <el-select v-model="localProperties[0].value" filterable>
      <el-option
        v-for="item in dashboards"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'dashboard',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'dashboard_id', 'type': 'varchar', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || {},
      dashboards: []
    }
  },
  async mounted () {
    let data = await this.$http.get(`${this.$config.api}/interfaceeditor/dashboards?fields=id,name`)
    this.dashboards = data.data
  }
}
</script>

<style scoped>
</style>
