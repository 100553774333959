
import PropertySet from '@/services/MapEditor/infrastructure/components/PropertySet/index.vue'
import SourceTypePropertiesQuery from '@/services/MapEditor/application/query/SourceTypePropertiesQuery'
import SourceProjectsQuery from '@/services/MapEditor/application/query/SourceProjectsQuery'

export default {
  name: "SourceInformationPanel",
  components: {
    PropertySet
  },
  inject: ['getEventBus', 'getQueryBus'],
  props: {
    source: Object, 
    labelPosition: {
      type: String,
      default: 'left'
    },
    showProjects: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      properties: [],
      projects: [],
      dto: {
        properties: []
      }
    }
  },
  watch: {
    source: {
      immediate: true, 
      handler () {
        this.updateSourceProperties();
      }
    }
  },
  methods: {
    propertiesMounted(properties) {
      if (this.properties.length && this.$refs.propertySet == properties) {
        properties.setValues(this.source.getProperties());
      }
    },
    sourceUpdated(source) {
      if (this.source == source) {
        this.updateSourceProperties();
      }
    },
    async updateSourceProperties() {
      this.properties = [];
      this.properties = await this.getQueryBus().execute(
        new SourceTypePropertiesQuery(this.source.getType())
      );
    }
  },
  async mounted() {
    this.getEventBus().$on('propertiesMounted', this.propertiesMounted);
    this.getEventBus().$on('sourceUpdated', this.sourceUpdated);
    if (this.showProjects) {
      this.loading = true;
      await this.getQueryBus().execute(
        new SourceProjectsQuery(this.source.getGuid())
      ).then(data => {
        this.loading = false;
        this.projects = data;
      });
    }
  }
}
