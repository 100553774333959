
import Vue from 'vue'
import GroupForm from '@/services/NotificationEditor/infrastructure/components/forms/GroupForm.vue'
import NotificationForm from '@/services/NotificationEditor/infrastructure/components/forms/NotificationForm.vue'
import SourceByIdAndTypeQuery from '@/services/NotificationEditor/application/query/SourceByIdAndTypeQuery'

export default Vue.extend({
  name: 'EditorElementLayout',

  components: {
    GroupForm,
    NotificationForm
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    elementType: {
      type: String,
      required: true
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeElementLayout', 'getEditorNode'],

  computed: {
    isNew () {
      if (typeof this.value.guid === 'undefined') {
        return true
      }

      return this.value.guid === null
    },

    notification () {
      return this.$store.getters['Notification/getLocation']
    },

    group () {
      return this.$store.getters['NotificationGroup/getLocation']
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    notification (location: string) {
      if (location !== null && this.isNew) {
        const id = parseInt(location.replace('/notifications/', ''))

        this.addElementToTree(id, 'notification')
      }
    },

    group (location: string) {
      if (location !== null && this.isNew) {
        const id = parseInt(location.replace('/groups/', ''))

        this.addElementToTree(id, 'group')
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    closeEditor (): void {
      this.getTreeElementLayout().closeEditor()
    },

    addElementToTree (id: number, type: string): void {
      this.getQueryBus()
        .execute(new SourceByIdAndTypeQuery(id, type))
        .then((response) => {
          if (
            typeof response.code !== 'undefined' && ['invalid_guid', 'entity_not_found', 'notification_view_not_found'].includes(response.code)
          ) {
            return
          }

          this.getTreeElementLayout().addNode(response, this.getEditorNode())
        })
    },

    submit (): void {
      this.$refs.form.submit(async () => {
        if (this.isNew === false) {
          let node = await this.getTreeElementLayout().getNodeByElementGuid(this.value.guid)
          if (node) {
            node.data.name = this.value.name

            this.getTreeElementLayout().updateNode(node)
          }
        }
      })
    }
  }
})
