import { Nullable } from '@/core/domain/type/types'

export default class CoordinateSystemCreateCommand
{
    private srid: Nullable<number>;
    private name: string;
    private proj4text: string;
    private projectId: number;
    private epsgType: number;

    constructor(srid: Nullable<number>, name: string, proj4text: string, epsgType: number, projectId: number)
    {
        this.srid = srid;
        this.name = name;
        this.proj4text = proj4text;
        this.epsgType = epsgType;
        this.projectId = projectId;
    }

    getClassName(): string
    {
        return 'CoordinateSystemCreateCommand';
    }

    getSrid(): Nullable<number>
    {
        return this.srid;
    }

    getName(): string
    {
        return this.name;
    }

    getProj4text(): string
    {
        return this.proj4text;
    }

    getEpsgType(): number
    {
        return this.epsgType;
    }

    getProjectId(): number
    {
        return this.projectId;
    }
}