import store from '@/store'
import TaskRegistryEntityQueryRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityQueryRepositoryInterface'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'
import {
  GetTaskRegistryEntities,
  GetTaskRegistryEntityByProperty
} from '@/services/TaskEditor/infrastructure/store/modules/TaskRegistryEntity/action-types'

export default class TaskRegistryEntityQueryRepository implements TaskRegistryEntityQueryRepositoryInterface {
  get (): Promise<Array<TaskRegistryEntity>> {
    return store.dispatch(new GetTaskRegistryEntities())
  }

  getByProperty (property: string): Promise<TaskRegistryEntity> {
    return store.dispatch(new GetTaskRegistryEntityByProperty(property))
  }
}
