
import Vue from 'vue'
import TreeElementLayout from '@/services/NotificationEditor/infrastructure/components/TreeElementLayout.vue'
import EditorElementLayout from '@/services/NotificationEditor/infrastructure/components/EditorElementLayout.vue'
import MainMixin from '@/services/NotificationEditor/infrastructure/mixins/MainMixin'
import Node from 'element-ui/packages/tree/src/model/node'
import SourceDTO from "@/services/NotificationEditor/domain/model/SourceDTO";

export default Vue.extend({
  name: 'NotificationEditor',

  mixins: [MainMixin],

  props: {
    externalNotificationGuid: {
      type: String,
      default: null
    }
  },

  components: {
    TreeElementLayout,
    EditorElementLayout
  },

  data () {
    return {
      editor: {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    }
  },

  provide () {
    return {
      getTreeElementLayout: this.getTreeElementLayout,
      getEditorNode: this.getEditorNode
    }
  },

  methods: {
    closeEditor () {
      this.editor = {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    },

    createElement (elementType: string, parentNode: Node = null) {
      this.editor.form = this.form[elementType]
      this.editor.title = this.$t('notify_editor.types.' + elementType)
      this.editor.node = parentNode
      this.editor.elementType = elementType
      this.editor.model = this.defaultModel[elementType].create(
        parentNode
          ? parentNode.data.id
          : null
      )
    },

    async updateElement (node: Node, data: SourceDTO) {
      this.editor.model = await this.queryBus.execute(new this.queryByGuid[data.type](data.id))
      this.editor.form = this.form[data.type]
      this.editor.title = `${data.name} (id: ${data.id})`
      this.editor.node = node
      this.editor.elementType = data.type
    },

    async deleteElement (node: Node, data: SourceDTO) {
      await this.getCommandBus().execute(
        new this.deleteCommands[data.type](data.id)
      )
    },

    getEditorNode () {
      return this.editor.node
    },

    getTreeElementLayout () {
      return this.$refs.treeElement
    }
  }
})
