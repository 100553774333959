<template>
  <div class="select-panel" ref="layer_select_panel">
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createLayer"></el-button>
      <el-button icon="el-icon-edit" size="mini" circle  @click="editLayer"></el-button>
      <el-button icon="el-icon-delete" size="mini" circle  @click="deleteLayer"></el-button>
      <el-checkbox v-if="!hideAll" v-model="allLayers" :label="$locale.map_editor.layer_panel.all_layers"></el-checkbox>
      <el-input
        :placeholder="$locale.map_editor.shared.search_by_name"
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchByName">
      </el-input>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="layers"
          stripe
          border
          ref="layers_table"
          row-key="tree_element_id"
          current-row-key="tree_element_id"
          highlight-current-row
          @current-change="changeLayer"
        >
        <el-table-column
          :label="$locale.map_editor.entities.layer"
        > 
          <el-table-column
            prop="id"
            :label="$locale.main.fields.id"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="name"
            :label="$locale.main.fields.name"
            width="180"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          :label="$locale.map_editor.entities.project"
         >
          <el-table-column
            prop="project_id"
            :label="$locale.main.fields.id"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="project_name"
            :label="$locale.main.fields.name"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="user_fullname"
            :label="$locale.main.fields.user_name"
            width="180"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          :label="$locale.map_editor.entities.tree"
         >
         <el-table-column
            prop="tree_element_id"
            :label="$locale.main.fields.id"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="is_deleted"
            :label="$locale.main.fields.status"
            width="120"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_deleted" :type="$locale.map_editor.layer_panel.status_tags.deleted">
                <span>{{ $locale.map_editor.layer_panel.statuses.deleted }}</span>
              </el-tag>
              <el-tag v-else :type="$locale.map_editor.layer_panel.status_tags.active">
                <span>{{ $locale.map_editor.layer_panel.statuses.active }}</span>
              </el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="layer-pagination"
            :total="layersCount"
            :page-size="layersPageSize"
            :layout="allLayers ? 'total, prev, pager, next' : 'total'"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>     
  </div>
</template>

<script type="ts">
import LayersQuery from '@/services/MapEditor/application/query/LayersQuery'
import ProjectLayersQuery from '@/services/MapEditor/application/query/ProjectLayersQuery'
import Layer from '@/services/MapEditor/domain/model/Layer/Layer'
import ProjectLayerDeleteCommand from '@/services/MapEditor/application/command/ProjectLayerDeleteCommand'

export default {
  name: 'LayerSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'hideAll', 'projectId', 'projectGuid'],
  data() {
    return {
      loading: false,
      layersPageSize: 0,
      defaultLayersPageSize: 100,
      layersCurrentPage: 0,
      layersPageLimit: 100,
      layersCount: 0,
      layers: [],
      layer: null,
      layerDto: null,
      searchName: null,
      allLayers: false,
      informationPanelHeight: 0
    }
  },
  computed: {
    layerCreated() {
      return this.$store.getters['Layer/getLocation'];
    }
  },
  watch: {
    allLayers (val) {
      this.layer = null;
      this.$refs.layers_table.setCurrentRow(null);
      this.loadLayers();
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.layer_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.layer_select_panel.clientHeight - 36;
      }
    },
    changeLayer(layerDto) {
      this.layer = Layer.create(layerDto);     
      this.layerDto = layerDto;
    },
    submit(callback) {
      this.getEventBus().$emit('selectLayer', this.layer, this.layerDto.tree_element_id, this.parentFormId, this.projectId);
      if (typeof callback == 'function') {
        callback();
      }
    },
    createLayer() {
      this.getEventBus().$emit('createForm', 'layer', {'parentFormId': this.formId, 'layerCreatedCallback': this.refreshLayer});
    },
    editLayer() {
      if (this.layer == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.getEventBus().$emit('createForm', 'layer_edit', {'parentFormId': this.formId, 'layer': this.layer, 'layerUpdatedCallback': this.refreshLayer, 'layerTreeElementDto': this.layerDto});
      }
    },
    async refreshLayer(layer) {
      await this.loadLayers();
      this.selectLayer(layer);
    },
    deleteLayer() {
      if (this.layer == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ProjectLayerDeleteCommand(
              this.layerDto.id,
              this.layerDto.project_guid
            )
          ).then((response) => {
            this.layer = null;
            this.layerDto = null;
            this.$refs.layers_table.setCurrentRow(null);
            this.loadLayers();          
          });
        }).catch((error) => { console.log(error); })
      }
    },
    selectLayer(layer) {
      let rows = this.layers.filter(el => {
        return el.guid == layer.getGuid()
      });
      if (rows.length) {
        this.$refs.layers_table.setCurrentRow(rows[0]);
      }
    },
    loadLayers(callback) {
      this.loadProjectLayers(callback);    
    },
    async loadProjectLayers(callback) {
      let payload = {};
      if (this.allLayers) {
        payload["limit"] = this.layersPageLimit;
        payload["offset"] = this.layersCurrentPage;
      } else {
        payload["project_id"] = this.projectId;
      }
      await this.getQueryBus().execute(
        new LayersQuery(payload)
      ).then(data => {
        if (this.allLayers) {
          this.layersPageSize = this.layersPageLimit;
        } else {
          this.layersPageSize = data.length;
        }
        this.layersCount = data.length;
        this.layers = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async searchByName() {
      let payload = {};
      if (this.searchName != null && this.searchName != "") {
        payload = {
          "where": {
            "and": [
              {
                "like": { "name": `%${this.searchName}%`}
              }
            ]
          }
        };
        if (!this.allLayers) {
          payload["where"]["and"].push(
            {
              "eq": { project_id: this.projectId }
            }
          );
        } else {
          payload["limit"] = this.layersPageLimit;
          payload["offset"] = this.layersCurrentPage;
        }
      } else if (!this.allLayers) {
        payload = { project_id: this.projectId };
      }
      await this.getQueryBus().execute(
        new LayersQuery(payload)
      ).then(data => {
        if (this.allLayers) {
          this.layersPageSize = this.layersPageLimit;
        } else {
          this.layersPageSize = data.length;
        }
        this.layersCount = data.length;
        this.layers = data;
      });
    }
  },
  mounted() {
    this.loadLayers(() => {
      this.resizePanel();
    });   
  }
}
</script>