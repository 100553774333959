<template>
  <div :id="`stimulsoft_designer_${componentId}`" :key="componentId"></div>
</template>

<script>
import Vue from 'vue'
import StiHelper from './StiHelper'

export default Vue.extend({
  name: 'StimulsoftReport',

  props: {
    filename: {
      type: String,
      default () {
        return 'SimpleList.mrt'
      }
    },

    reportId: Number,

    sourceFilters: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      componentId: this.generateGuid()
    }
  },

  async mounted () {
    const options = new window.Stimulsoft.Designer.StiDesignerOptions()
    options.appearance.fullScreenMode = true
    options.toolbar.showSendEmailButton = true
    options.appearance.allowChangeWindowTitle = false

    const designer = new window.Stimulsoft.Designer.StiDesigner(options, 'StiDesigner_' + this.componentId, false)

    window.Stimulsoft.Helper = new StiHelper(`${this.$config.api}/stimulsoftreports/handler.php`, 300, {
      sourceFilters: this.sourceFilters
    })

    designer.onBeginProcessData = function (event, callback) {
      window.Stimulsoft.Helper.process(arguments[0], arguments[1])
    }

    designer.onSaveReport = function (args, callback) {
      args.preventDefault = true
      window.Stimulsoft.Helper.process(args, callback)
    }

    window.StiOptions.Export.Excel.AllowExportDateTime = true

    const report = new window.Stimulsoft.Report.StiReport()
    report.loadFile(`${this.$config.api}/reports/${this.filename}`)

    window.stiLastReport = report

    const databases = report.getDictionary().databases

    if (!databases.getByName('Акцент')) {
      report.getDictionary().databases.add(
        new window.Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase('Акцент', 'Акцент', 'Строка для подключения к БД определена сервером поумолчанию.')
      )
    }

    const response = await this.$http.get(`${this.$config.api}/reporteditor/reports/sources/${this.reportId}`)
    const sourceCollection = report.dataSources
    if (Array.isArray(response.data) && response.data.length > 0) {
      response.data.forEach((item) => {
        if (!sourceCollection.getByName(item.name)) {
          const source = new window.Stimulsoft.Report.Dictionary.StiPostgreSQLSource('Акцент', item.name, item.alias, item.query, true, false)
          source.dictionary = report.getDictionary()
          sourceCollection.add(source)
        }
      })
    }

    designer.report = report
    designer.renderHtml(`stimulsoft_designer_${this.componentId}`)
  }
})
</script>
