import ProjectCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectCommandRepositoryInterface'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import store from '@/store'
import { AddProject, UpdateProject, DeleteProject } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectCommandRepository implements ProjectCommandRepositoryInterface
{
    insert(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new AddProject(item));
    }

    update(item: ProjectDTO): Promise<void>
    {
        return store.dispatch(new UpdateProject(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteProject(guid));
    }
}
