import { render, staticRenderFns } from "./XsdParser.vue?vue&type=template&id=6c63d67a&scoped=true&"
import script from "./XsdParser.vue?vue&type=script&lang=js&"
export * from "./XsdParser.vue?vue&type=script&lang=js&"
import style0 from "./XsdParser.vue?vue&type=style&index=0&id=6c63d67a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c63d67a",
  null
  
)

export default component.exports