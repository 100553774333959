

export default {

  name: 'DSSReturnFrom',

  data () {
    return {
      loading: true
    }
  },

  mounted () {
    window.localStorage.getItem('dss_session_init')

    let url = new URL(window.location.href)
    let access_token = url.searchParams.get('access_token')
    let code = url.searchParams.get('code')
    let expires = url.searchParams.get('expires_in')
    let type = url.searchParams.get('token_type')

    if (url.searchParams.get('clear')) {
      window.localStorage.setItem('dss_token', '')
      window.localStorage.setItem('dss_token_expires', '')
      window.localStorage.setItem('dss_code', '')
      console.log('Clear DSS properties in local storage')
      return
    }

    if (access_token) {
      // console.log(Buffer.from(access_token, 'base64').toString('binary'))
      let expiresTime = new Date()
      expiresTime.setSeconds(expiresTime.getSeconds() + (Number(expires)))
      console.log(expiresTime.toUTCString())

      window.localStorage.setItem('dss_token', access_token)
      window.localStorage.setItem('dss_token_expires', expiresTime.toUTCString())
    }

    if (code) {
      window.localStorage.setItem('dss_code', code)
    }
    if (window.location.host !== 'localhost') {
      setTimeout(() => window.close(), 30)
    }
  }
}

