import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import System from '@/components/System'
import LoginPage from '@/components/LoginPage'
import OAuthPage from '@/components/LoginPage/oauth'
import EsiaPage from '@/components/LoginPage/esia'
import FastEsia from '@/components/LoginPage/FastEsia'
import DSSReturnFrom from '@/components/LoginPage/DSSReturnFrom'
import FastLogin from '@/components/LoginPage/FastLogin.vue'
import RegistryCard from '@/components/RegistryCard/index.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['Authorization/isAuthenticated']) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['Authorization/isAuthenticated']) {
    next()
    return
  }
  const redirectUrl = window.location.pathname
  if (redirectUrl) {
    localStorage.setItem('redirect_url', redirectUrl)
  }
  next('/login')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: System,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/login_esia',
      name: 'FastEsia',
      component: FastEsia,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/dashboard/:openDashboardId/*',
      name: 'Dashboard',
      component: System,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/dashboard/:openDashboardId',
      name: 'Dashboard',
      component: System,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/oauth/:providerId',
      name: 'OAuthPage',
      component: OAuthPage,
      props: true
    },
    {
      path: '/esia',
      name: 'EsiaPage',
      component: EsiaPage,
      props: true
    },
    {
      path: '/dss',
      name: 'DSSReturnFrom',
      component: DSSReturnFrom,
      props: true
    },
    {
      path: '/card/:registryId/:cardId',
      name: 'RegistryCard',
      component: RegistryCard,
      props: true
    },
    {
      path: '/fast_login',
      name: 'FastLogin',
      component: FastLogin,
      props: true
    }
  ]
})
