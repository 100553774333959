<template>
    <editor-layout class="etl_editor_v2">
      <template v-slot:header>
        {{ $locale.main.system.etl_editor }}
      </template>

      <template v-slot:tree.side>
        <tree-element-layout
          ref="treeElement"
          :create-element="createElement"
          :update-element="updateElement"
          :delete-element="deleteElement"
          :close="closeEditor">
        </tree-element-layout>
      </template>

      <template v-slot:editor.side>
        <editor-element-layout
          ref="editorElement"
          v-if="editor.form !== null"
          v-model="editor.model"
          :title="editor.title"
          :form="editor.form"
          :key="editor.id"
          :element-type="editor.elementType">
        </editor-element-layout>
      </template>
    </editor-layout>
</template>

<script type="ts">
import TreeElementLayout from '@/services/EtlEditor/infrastructure/components/TreeElementLayout.vue'
import EditorElementLayout from '@/services/EtlEditor/infrastructure/components/EditorElementLayout.vue'
import mixins from '@/services/EtlEditor/mixins'

export default {
  name: 'EtlEditor',

  mixins: [mixins],

  components: {
    TreeElementLayout,
    EditorElementLayout
  },

  data () {
    return {
      editor: {
        id: null,
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      },

      form: {
        extractor: 'ExtractorForm',
        group: 'GroupForm',
        loader: 'LoaderForm',
        task: 'TaskForm',
        template: 'TemplateForm',
        transformer: 'TransformerForm'
      }
    }
  },

  provide () {
    return {
      getTreeElementLayout: this.getTreeElementLayout,
      getEditorNode: this.getEditorNode,
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  methods: {
    closeEditor () {
      this.editor = {
        id: null,
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    },

    createElement (elementType, parentNode) {
      this.editor.id = this.generateGuid()
      this.editor.form = this.form[elementType]
      this.editor.title = this.$locale.etl_editor_v2.headlines.create[elementType]
      this.editor.node = parentNode
      this.editor.elementType = elementType
      this.editor.model = this.defaultModel[elementType].create()
      this.editor.model.parent_tree_element_id = parentNode ? parentNode.data.id : null
    },

    async updateElement (node, data) {
      this.editor.id = this.generateGuid()
      this.editor.model = await this.queryBus.execute(new this.queryByGuid[data.element_type](data.element_guid))
      this.editor.form = this.form[data.element_type]
      this.editor.title = this.$locale.etl_editor_v2.headlines.update[data.element_type]
      this.editor.node = node
      this.editor.elementType = data.element_type
    },

    async deleteElement (node, data) {
      await this.getCommandBus().execute(
        new this.deleteCommands[data.element_type](data.element_guid)
      )
    },

    getEditorNode () {
      return this.editor.node
    },

    getTreeElementLayout () {
      return this.$refs.treeElement
    },

    getEventBus () {
      return this.eventBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>

<style lang="scss">
@import './EtlEditor.scss';
</style>
