import { Nullable } from '@/core/domain/type/types'
import CommandInterface from '@/core/application/command/CommandInterface'

export default class ProjectSourceDeleteCommand implements CommandInterface
{
    private id: number;
    private projectGuid: Nullable<string>;

    constructor(id: number, projectGuid: Nullable<string>)
    {
        this.id = id;
        this.projectGuid = projectGuid;
    }

    getId(): number
    {
        return this.id;
    }

    getProjectGuid(): Nullable<string>
    {
        return this.projectGuid;
    }

    getClassName(): string
    {
        return 'ProjectSourceDeleteCommand';
    }  
}