import RasterCommandRepositoryInterface from '@/services/RasterLibrary/domain/repository/RasterCommandRepositoryInterface'
import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'
import store from '@/store'
import { AddRaster, UpdateRaster, DeleteRaster } from '@/services/RasterLibrary/infrastructure/store/modules/Raster/action-types'

export default class RasterCommandRepository implements RasterCommandRepositoryInterface
{
    insert(item: FormData): Promise<void>
    {
        return store.dispatch(new AddRaster(item));
    }

    update(item: RasterDTO): Promise<void>
    {
        return store.dispatch(new UpdateRaster(item));
    }

    delete(guid: string, params: unknown): Promise<void>
    {
        return store.dispatch(new DeleteRaster(guid, params));
    }
}