<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
      <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
    </el-form-item>
    <el-form-item :label="$locale.etl_editor.form.fields.alias" prop="alias">
      <el-input v-model="value.alias" :placeholder="$locale.etl_editor.form.fields.alias" size="mini"></el-input>
    </el-form-item>

    <el-form-item v-if="showType" :label="$locale.etl_editor.form.fields.type" prop="extractor_type_id">
      <el-select v-model="value.extractor_type_id" :placeholder="$locale.etl_editor.form.fields.type">
        <el-option
          v-for="item in extractorTypes"
          :key="item.id"
          :label="$locale.etl_editor.menu[item.name]"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>

    <slot></slot>
  </el-form>
</template>

<script>
import ExtractorType from '../../Models/ExtractorType'

export default {
  name: 'ExtractorCreate',

  props: {
    value: {
      type: Object,
      default: null
    },
    showType: {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      extractorTypes: [],
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        extractor_type_id: [
          { required: true, message: 'Please select extractor type', trigger: 'change' }
        ]
      }
    }
  },

  async mounted () {
    if (this.showType) {
      this.extractorTypes = await new ExtractorType().get()
    }
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(true)
        }
      })
    }
  }
}
</script>

<style type="text/css">

</style>
