
// Центр уведомлений
import Cards from '@/components/Registry/Cards.vue'
import { cloneObject, getCardId } from '@/helpers'
import mixin from '@/components/System/Notification/mixins/index'
import mixins from 'vue-typed-mixins'
import { MessageApi } from './api/MessageApi.js'
import { mapGetters, mapState } from 'vuex'

export default mixins(mixin).extend({
  name: 'NotificationCenter',

  components: {
    Cards
  },

  computed: {
    ...mapGetters(['message']),

    ...mapState('Notify', ['numberUnreadMessages']),

    showCard () {
      return this.openedCards.length > 0
    },

    disabledApplyButton () {
      return this.filterModel.author_id === null && this.filterModel.create_date === null
    },

    disabledClearButton () {
      return this.filterModel.author_id !== null || this.filterModel.create_date !== null
    },

    baseParams () {
      const where = { and: [] }

      if (this.filterModel.type === 'new') {
        where.and.push({
          is_null: 'read_date'
        })
      }

      if (typeof this.filterModel.author_id === 'number' && this.filterModel.author_id >= 0) {
        where.and.push({
          // eq: { 'm.author_id': this.filterModel.author_id }
          eq: { author_id: this.filterModel.author_id }
        })
      }

      if (this.filterModel.create_date) {
        where.and.push({
          and: [{
            // gte: { 'm.create_date': this.filterModel.create_date[0] },
            // lte: { 'm.create_date': this.filterModel.create_date[1] }
            gte: { create_date: this.filterModel.create_date[0] },
            lte: { create_date: this.filterModel.create_date[1] }
          }]
        })
      }

      if (this.filterModel.search) {
        where.and.push({
          or: [
            // { like: { 'u.name': `%${this.filterModel.search}%` } },
            // { like: { 'u.midname': `%${this.filterModel.search}%` } },
            // { like: { 'u.surname': `%${this.filterModel.search}%` } },
            // { like: { 'm.title': `%${this.filterModel.search}%` } },
            // { like: { 'm.content': `%${this.filterModel.search}%` } },
            { like: { 'title': `%${this.filterModel.search}%` } },
            { like: { 'content': `%${this.filterModel.search}%` } }
          ]
        })
      }

      return where
    },

    loadParams () {
      return {
        offset: this.tableModel.page,
        limit: this.tableModel.limit,
        order: 'create_date:desc',
        where: this.baseParams
      }
    }
  },

  watch: {
    message: {
      handler: function (message) {
        // if (message) {
        //   // Запросить список уведомлений, учитывая ид уведомления из сокета и текущую настройку фильтров
        //   // Полученный список добавить вверх локального списка уведомлений
        //   this.onRefreshTable(false)
        // }

        this.onSoketMessage(message)
      }
    }
  },

  data () {
    return {
      api: new MessageApi(),
      tableModel: {
        data: [],
        total: 0,
        limit: 50,
        page: 0
      },
      filterModel: {
        author_id: null,
        create_date: null,
        search: '',
        type: 'all',

        // Интерактив
        badge: false,
        visible: false
      },
      loading: false,
      openedCards: [],
      authors: [],
      pickerOptions: {
        shortcuts: [{
          text: 'Прошлая неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Прошлый месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Последние 3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },

  inject: {
    addMainTab: {
      default: () => {
      }
    }
  },

  async mounted () {
    this.loading = true

    this.authors = await this.api.getAuthors()

    await this.onLoadMessages()

    if (this.$attrs.registryId) {
      this.openedCards.push({
        id: this.$attrs.cardId,
        registryId: this.$attrs.registryId,
        recordId: this.$attrs.recordId,
        name: '',
        initialData: {}
      })
    }
  },

  provide () {
    return {
      openRegistryCard: this.onOpenRegistryCard,
      cancelChanges: this.onCancelChanges
    }
  },

  methods: {
    onOpenRegistryCard (data) {
      const {
        registryId,
        cardId,
        cardName,
        recordId = null,
        initialData = {},
        registry = null
      } = data

      if (!cardId || !registryId) {
        this.$notify.error(this.$t('main.message.not_saved'))

        return false
      }

      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false
      })
    },

    onCancelChanges () {
      if (this.openedCards.length === 1) {
        this.openedCards = []
      } else {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
      }
    },

    async openCard (message) {
      let dataRegistryByRecord = await this.getDataRegistryByRecord({
        registryId: message.notification.object_id,
        recordId: message.record_id
      })

      if (dataRegistryByRecord.is_deleted) {
        this.$message.error('Запись удалена из системы')
        return
      }

      // Открыть карточку внешнего реестра
      if (message.notification.interaction_type === 'open_external_card') {
        return this.openCardOuter(message, dataRegistryByRecord)
      }

      this.loading = true

      try {
        const currentCardId = message.notification?.current_card_id || null
        const card: any = currentCardId
          ? { id: currentCardId }
          : await getCardId(this, {
            registryId: message.notification.object_id,
            recordId: message.record_id
          })

        this.openedCards.push({
          id: card?.id || null,
          name: card?.name || 'Новая карточка',
          registryId: message.notification.object_id,
          recordId: message.record_id,
          initialData: {}
          // registry: registry,
          // readonly: registry ? (registry.readonly || false) : false
        })

        await this.onReadMessage(message)
      } catch (error) {
        console.log({ error })
        this.$message.error('Вызникла непредвиденная ошибка')
      } finally {
        this.loading = false
      }
    },

    // Открыть карточку внешнего реестра
    async openCardOuter (message, dataRegistryByRecord) {
      try {
        this.loading = true

        const { openCard, readOnly, cardId, recordXref } = await this.validationData(message, dataRegistryByRecord)
        if (!openCard) {
          this.$message.error('Неудалось открыть карточку')
          this.loading = false
          return
        }

        // получить id карточки внешнего реестра
        const card: any = cardId
          ? { id: cardId }
          : await getCardId(this, {
            registryId: openCard.external_object_id,
            recordId: recordXref
          })

        this.openedCards.push({
          id: card?.id,
          registryId: openCard.external_object_id,
          recordId: recordXref,
          name: '',
          initialData: {},
          readonly: readOnly
        })

        await this.onReadMessage(message)
      } catch (error) {
        console.log({ error })
        this.$message.error('Вызникла непредвиденная ошибка')
      } finally {
        this.loading = false
      }
    },

    async onSoketMessage (message) {
      if (!message) {
        return
      }

      if (this.tableModel.data.some(msg => msg.id === message.id)) {
        return
      }

      if (this.tableModel.page > 0) {
        return
      }

      this.tableModel.total = await this.api.getNumberMessages(this.baseParams)

      const params = cloneObject(this.loadParams)
      params.where.and.push({
        in: { id: message.id }
      })

      const messages = await this.api.getMessages(params)

      for (const msg of messages) {
        this.tableModel.data.unshift(msg)
      }
    },

    onRefreshTable (clearFilters = true) {
      if (clearFilters) {
        this.filterModel.type = 'all'
        this.filterModel.search = ''
        this.filterModel.author_id = null
        this.filterModel.create_date = null
        this.filterModel.badge = false
      }

      this.onLoadMessages()
    },

    onApplyFilters () {
      this.filterModel.badge = true
      // this.filterModel.visible = false

      this.onLoadMessages()
    },

    onClearFilters () {
      this.filterModel.author_id = null
      this.filterModel.create_date = null
      this.filterModel.badge = false
      this.filterModel.visible = false

      this.onLoadMessages()
    },

    // прочитать все сообщения
    async onReadAllMessages () {
      await this.$store.dispatch('Notify/readAllMessages')

      this.onRefreshTable()
    },

    async onReadMessage (row) {
      this.$refs.notifyTable.toggleRowExpansion(row)

      if (row.read_date) {
        return
      }

      for (const message of this.tableModel.data) {
        if (message.id === row.id) {
          message.read_date = new Date().toISOString()
        }
      }

      await this.$store.dispatch('Notify/readMessage', row)
    },

    tableRowClassName ({ row }) {
      if (row.read_date) {
        return 'read-row'
      } else {
        return 'noRead-row'
      }
    },

    onChangePagination (val) {
      this.tableModel.page = (val - 1) * this.tableModel.limit

      this.onLoadMessages()
    },

    async onLoadMessages () {
      this.loading = true

      try {
        this.tableModel.total = await this.api.getNumberMessages(this.baseParams)
        this.tableModel.data = await this.api.getMessages(this.loadParams)

        this.$nextTick(() => {
          this.$refs.notifyTable && this.$refs.notifyTable.doLayout()
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
})
