import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import MigrationRepositoryInterface from '@/services/SyncService/domain/repository/MigrationRepositoryInterface'
import { GetMigrations, CountMigrations } from '@/services/SyncService/infrastructure/store/modules/Migration/action-types'

export default class MigrationRepository extends AbstractQueryRepository implements MigrationRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetMigrations(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountMigrations(payload))
  }
}
