import ApprovalStageCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalStageCommandRepositoryInterface'
import store from '@/store'
import { CreateApprovalStage, UpdateApprovalStage, DeleteApprovalStage } from '@/services/LogicEditor/infrastructure/store/modules/ApprovalStage/action-types'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export default class ApprovalStageCommandRepository implements ApprovalStageCommandRepositoryInterface {
  insert (item: ApprovalStageDTO): Promise<void> {
    return store.dispatch(new CreateApprovalStage(item))
  }

  update (item: ApprovalStageDTO): Promise<void> {
    return store.dispatch(new UpdateApprovalStage(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteApprovalStage(guid))
  }
}
