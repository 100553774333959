import { render, staticRenderFns } from "./ColorPreview.vue?vue&type=template&id=e7de8ee0&scoped=true&"
import script from "./ColorPreview.vue?vue&type=script&lang=ts&"
export * from "./ColorPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7de8ee0",
  null
  
)

export default component.exports