import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CommandUpdateCommand from '@/services/LogicEditor/application/command/CommandUpdateCommand'
import CommandCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandCommandRepositoryInterface'

export default class CommandUpdateHandler implements CommandHandlerInterface {
    private repository: CommandCommandRepositoryInterface;

    constructor (repository: CommandCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: CommandUpdateCommand): Promise<void> {
      return this.repository.update(command.getDTO())
    }
}
