import store from '@/store'
import TaskRegistryEntityCommandRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityCommandRepositoryInterface'
import { UpdateTaskRegistryEntities }
  from '@/services/TaskEditor/infrastructure/store/modules/TaskRegistryEntity/action-types'

export default class TaskRegistryEntityCommandRepository implements TaskRegistryEntityCommandRepositoryInterface {
  update (payload: object): Promise<void> {
    return store.dispatch(new UpdateTaskRegistryEntities(payload))
  }
}
