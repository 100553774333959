<template>
  <div class="loader_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.etl_editor_v2.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.alias" prop="alias">
        <el-input v-model="model.alias" :placeholder="$locale.etl_editor_v2.labels.alias" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.type" prop="loader_type_id">
        <el-select v-model="model.loader_type_id" :placeholder="$locale.etl_editor_v2.labels.type" @change="changeType" :disabled="!isNew">
          <el-option
            v-for="item in loaderTypes"
            :key="item.id"
            :label="$locale.etl_editor_v2.types[item.name]"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <properties-form v-if="isLoadedProperties" v-model="model.properties" :properties="loaderTypeProperties"></properties-form>
    </el-form>
  </div>
</template>

<script>
import LoaderByGuidQuery from '@/services/EtlEditor/application/query/LoaderByGuidQuery'
import LoaderTypePropertiesQuery from '@/services/EtlEditor/application/query/LoaderTypePropertiesQuery'
import LoaderTypesQuery from '@/services/EtlEditor/application/query/LoaderTypesQuery'
import Loader from '@/services/EtlEditor/domain/model/Loader'
import LoaderCreateCommand from '@/services/EtlEditor/application/command/LoaderCreateCommand'
import LoaderUpdateCommand from '@/services/EtlEditor/application/command/LoaderUpdateCommand'

import PropertiesForm from '../properties/PropertiesForm'

export default {
  name: 'LoaderForm',

  props: {
    value: Object
  },

  inject: ['getQueryBus', 'getCommandBus'],

  components: {
    PropertiesForm
  },

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    loaderCreated () {
      return this.$store.getters['Loader/getLoaderLocation']
    },

    isLoadedProperties () {
      return this.loaderTypeProperties.length > 0
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    loaderCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new LoaderByGuidQuery(
            location.replace('/loaders/', '')
          )
        ).then((response) => {
          if (response.guid) {
            this.$emit('input', response)
          }
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: this.$locale.etl_editor_v2.rules.name, trigger: 'blur' }
        ],
        loader_type_id: [
          { required: true, message: this.$locale.etl_editor_v2.rules.type, trigger: 'change' }
        ]
      },

      isLoaded: false,

      loaderTypes: [],
      loaderTypeProperties: []
    }
  },

  mounted () {
    if (!this.loaderTypes.length) {
      this.getQueryBus().execute(new LoaderTypesQuery()).then((response) => {
        if (response.length) {
          this.loaderTypes.push(...response)
        }
      })
    }

    if (!this.loaderTypeProperties.length && this.model.loader_type_id) {
      this.getQueryBus().execute(new LoaderTypePropertiesQuery(this.model.loader_type_id)).then((response) => {
        if (response.length) {
          this.loaderTypeProperties.push(...response)
        }
      })
    }

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    changeType (value) {
      this.loaderTypeProperties = []
      this.getQueryBus().execute(new LoaderTypePropertiesQuery(value)).then((response) => {
        this.model.properties = []

        if (response.length) {
          response.forEach(property => {
            this.model.properties.push({
              id: property.id,
              type: property.type,
              value: this.getDefValue(property)
            })
          })

          this.loaderTypeProperties.push(...response)
        }
      })
    },

    getDefValue (prop) {
      if (['boolean', 'integer', 'array', 'object'].indexOf(prop.primitive_type) !== -1 && prop.default_value !== null) {
        return JSON.parse(prop.default_value)
      } else {
        return prop.default_value
      }
    },

    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let model = Loader.create(this.value)

      if (this.isNew) {
        return new LoaderCreateCommand(
          model.getName(),
          model.getLoaderTypeId(),
          model.getAlias(),
          model.getProperties(),
          model.getParentTreeElementId()
        )
      }

      return new LoaderUpdateCommand(
        model.getGuid(),
        model.getName(),
        model.getLoaderTypeId(),
        model.getAlias(),
        model.getProperties()
      )
    }
  }
}
</script>

<style scoped></style>
