<template>
  <div :class="{ 'table_mapping': true, 'full_screen': isFullScreen }">
    <el-table
      :data="mapping.data"
      :height="isFullScreen ? this.window.height - 41 : 394"
      border
    >
      <el-table-column
        :label="$t('etl_editor.form.table.source_object')"
        header-align="center"
      >
        <el-table-column
          prop="source_field_id"
          :label="$t('etl_editor.form.table.field')"
          width="150"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-select
              v-if="rowEditor === scope.row.id"
              v-model="scope.row.source_field_id"
              :placeholder="$t('etl_editor.form.table.field')"
              size="mini"
              filterable
              clearable
              @clear="scope.row.source_field_id = null"
            >
              <el-option v-for="item in selectSourceField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_field_id) }">
              {{ getObjectName(selectSourceField, scope.row.source_field_id) }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="source_column"
          :label="$t('etl_editor.form.table.column')"
          width="100"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="rowEditor === scope.row.id"
              size="mini"
              v-model="scope.row.source_column"
              :placeholder="$t('etl_editor.form.table.column')"
              clearable
              @clear="scope.row.source_column = null"
            ></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_column) }">
              {{ scope.row.source_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="source_alias"
          :label="$t('etl_editor.form.table.alias')"
          width="100"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="rowEditor === scope.row.id"
              size="mini"
              v-model="scope.row.source_alias"
              :placeholder="$t('etl_editor.form.table.alias')"
              clearable
              @clear="scope.row.source_alias = null"
            ></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.source_alias) }">
              {{ scope.row.source_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column
        :label="$t('etl_editor.form.table.target_object')"
        header-align="center"
      >
        <el-table-column
          prop="target_field_id"
          :label="$t('etl_editor.form.table.field')"
          width="150"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-select
              v-if="rowEditor === scope.row.id"
              v-model="scope.row.target_field_id"
              :placeholder="$t('etl_editor.form.table.field')"
              size="mini"
              @change="selectTargetFieldId($event, scope.$index)"
              filterable
              clearable
              @clear="scope.row.target_field_id = null"
            >
              <el-option v-for="item in selectTargetField" :key="item.id" :label="`${item.name} (attr_${item.id}_)`" :value="item.id"></el-option>
            </el-select>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.target_field_id === null }">
              {{ getObjectName(selectTargetField, scope.row.target_field_id) }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="target_column"
          :label="$t('etl_editor.form.table.column')"
          width="100"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="rowEditor === scope.row.id"
              size="mini"
              v-model="scope.row.target_column"
              :placeholder="$t('etl_editor.form.table.column')"
              clearable
              @clear="scope.row.target_column = null"
            ></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_column) }">
              {{ scope.row.target_column || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="target_alias"
          :label="$t('etl_editor.form.table.alias')"
          width="100"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="rowEditor === scope.row.id"
              size="mini"
              v-model="scope.row.target_alias"
              :placeholder="$t('etl_editor.form.table.alias')"
              clearable
              @clear="scope.row.target_alias = null"
            ></el-input>

            <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.target_alias) }">
              {{ scope.row.target_alias || '(нет данных)' }}
            </p>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column
        prop="is_key"
        :label="$t('etl_editor.form.table.is_key')"
        width="100"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_key" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column
        prop="is_required"
        :label="$t('etl_editor.form.table.is_required')"
        width="115"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_required" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column
        prop="is_load_xref_table_values"
        :label="$t('etl_editor.form.table.is_load_xref_table_values')"
        width="110"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-checkbox :disabled="rowEditor !== scope.row.id" v-model="scope.row.is_load_xref_table_values" label="Да"></el-checkbox>
        </template>
      </el-table-column>

      <el-table-column
        prop="xref_field_id"
        :label="$t('etl_editor.form.table.xref_field_id')"
        width="125"
        header-align="center"
      >
        <template slot-scope="scope">
          <registry-select-tree
            v-model="scope.row.xref_field_id"
            v-if="rowEditor === scope.row.id && xrefObjectId !== null"
            value-as="number"
            type="field"
            size="mini"
            :placeholder="$t('etl_editor.form.table.xref_field_id')"
            :parent-id="xrefObjectId">
          </registry-select-tree>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.xref_field_id) }">
            {{ scope.row.xref_field_id || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="xref_condition"
        :label="$t('etl_editor.form.table.xref_condition')"
        width="120"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.xref_condition"
            :placeholder="$t('etl_editor.form.table.xref_condition')"
            clearable
            @clear="scope.row.xref_condition = null"
          ></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.xref_condition) }">
            {{ scope.row.xref_condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="loader_id"
        :label="$t('etl_editor.form.table.loader_id')"
        width="200"
        header-align="center"
      >
        <template slot-scope="scope">
<!--          <el-select
            v-if="rowEditor === scope.row.id"
            v-model="scope.row.loader_id"
            :placeholder="$t('etl_editor.form.table.loader_id')"
            size="mini"
            filterable
            clearable
            @clear="scope.row.loader_id = null"
          >
            <el-option v-for="item in loaders" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>-->

          <dynamic-select
            v-if="rowEditor === scope.row.id"
            v-model="scope.row.loader_id"
            :placeholder="$t('etl_editor.form.table.loader_id')"
            :options="loaders"
            @update-options="$emit('update:loaders', $event)"
            type="loader"
          ></dynamic-select>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.loader_id === null }">
            {{ getObjectName(loaders, scope.row.loader_id) }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="value"
        :label="$t('etl_editor.form.table.value')"
        width="120"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.value"
            :placeholder="$t('etl_editor.form.table.value')"
            clearable
            @clear="scope.row.value = null"
          ></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.value) }">
            {{ scope.row.value || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="row_order"
        :label="$t('etl_editor.form.table.row_order')"
        width="120"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-input-number
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.row_order"
            :placeholder="$t('etl_editor.form.table.row_order')"
            controls-position="right"
            :min="0"
            :max="99999999"
          ></el-input-number>

          <p v-if="rowEditor !== scope.row.id">
            {{ scope.row.row_order || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="condition"
        :label="$t('etl_editor.form.table.condition')"
        width="120"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-if="rowEditor === scope.row.id"
            size="mini"
            v-model="scope.row.condition"
            :placeholder="$t('etl_editor.form.table.condition')"
            clearable
            @clear="scope.row.condition = null"
          ></el-input>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': empty(scope.row.condition) }">
            {{ scope.row.condition || '(нет данных)' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="transformer_id"
        :label="$t('etl_editor.form.table.transformer_id')"
        width="200"
        header-align="center"
      >
        <template slot-scope="scope">
<!--          <el-select
            v-if="rowEditor === scope.row.id"
            v-model="scope.row.transformer_id"
            :placeholder="$t('etl_editor.form.table.transformer_id')"
            size="mini"
            filterable
            clearable
            @clear="scope.row.transformer_id = null"
          >
            <el-option v-for="item in transformers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>-->

          <dynamic-select
            v-if="rowEditor === scope.row.id"
            v-model="scope.row.transformer_id"
            :placeholder="$t('etl_editor.form.table.transformer_id')"
            :options="transformers"
            @update-options="$emit('update:transformers', $event)"
            type="transformer"
          ></dynamic-select>

          <p v-if="rowEditor !== scope.row.id" :class="{ 'no-data': scope.row.transformer_id === null }">
            {{ getObjectName(transformers, scope.row.transformer_id) }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        width="115"
        class="actions"
        header-align="center"
      >
        <template slot="header">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('etl_editor.tooltip.add')" placement="top">
            <el-button icon="el-icon-circle-plus-outline" type="success" size="mini" @click.stop="addMapping()" circle></el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('etl_editor.tooltip.full_screen')" placement="top">
            <el-button
              icon="el-icon-full-screen"
              :type="isFullScreen ? 'info' : 'default'"
              size="mini"
              circle
              @click.stop="fullScreen()"
            ></el-button>
          </el-tooltip>
        </template>

        <template slot-scope="scope">
          <el-tooltip
            :open-delay="300"
            class="item"
            effect="dark"
            :content="rowEditor === scope.row.id ? $t('etl_editor.tooltip.save') : $t('etl_editor.tooltip.edit')"
            placement="top"
          >
            <el-button
              @click.native.prevent="editMapping(scope.$index)"
              :type="rowEditor === scope.row.id ? 'primary' : 'default'"
              circle
              size="mini"
            >
              <span :class="rowEditor === scope.row.id ? 'el-icon-success' : 'el-icon-edit'"></span>
            </el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('etl_editor.tooltip.delete')" placement="top">
            <el-button
              @click.native.prevent="deleteMapping(scope.$index)"
              type="danger"
              circle
              size="mini"
            >
              <span class="el-icon-delete-solid"></span>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @current-change="tablePageChange"
      :page-size="pagination.limit"
      layout="total, prev, pager, next"
      style="background-color: #fff;"
      :total="mapping.count">
    </el-pagination>
  </div>
</template>

<script>
import Mapping from '../../Models/Mapping'
import RegistrySelectTree from '../../../Common/RegistrySelectTree'
import DynamicSelect from '@/components/EtlEditor/render/DynamicSelect'

export default {
  name: 'TableMapping',

  components: {
    RegistrySelectTree,
    DynamicSelect
  },

  props: {
    taskId: Number,

    loaders: {
      type: Array,
      default () {
        return []
      }
    },

    transformers: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      selectSourceField: [],
      selectTargetField: [],

      rowEditor: 0,

      mapping: {
        count: 0,
        data: []
      },
      pagination: {
        current: 0,
        limit: 30
      },

      xrefObjectId: null,

      isFullScreen: false,

      window: {
        width: 0,
        height: 0
      }
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted () {
    this.load()
  },

  methods: {
    selectTargetFieldId (value, index) {
      this.xrefObjectId = null
      if (value !== null) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${value}`)
          .then((response) => {
            if (response.data !== null) {
              this.xrefObjectId = response.data.xref_object_id
            }
          })
      }
    },

    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },

    empty (value) {
      return value === null || value === ''
    },

    async load () {
      this.$http
        .get(`${this.$config.api}/etleditor/task_objects/${this.taskId}`)
        .then((response) => {
          this.getObjectList(response.data)
        })

      this.rowEditor = 0

      let count = await Mapping.params({ '*': { func: 'count' }, task_id: this.taskId }).get()
      this.mapping.count = count.length ? count[0].count : 0
      this.mapping.data = await Mapping.params({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit }).orderBy('row_order:asc', 'id:asc').get()
    },

    async editMapping (index) {
      if (!this.mapping.data[index].id) {
        this.mapping.data[index] = await this.mapping.data[index].save()

        console.log('this.mapping.data[index]', this.mapping.data[index])

        this.rowEditor = 0
      }

      if (this.rowEditor === this.mapping.data[index].id) {
        if (this.mapping.data[index].isEdit()) {
          this.mapping.data[index] = await this.mapping.data[index].save()
        }

        this.rowEditor = 0
      } else {
        this.rowEditor = this.mapping.data[index].id

        if (this.mapping.data[index].target_field_id !== null) {
          this.selectTargetFieldId(this.mapping.data[index].target_field_id, index)
        }
      }
    },

    saveAllEditMapping () {
      this.mapping.data.forEach(async (item) => {
        if (item.isEdit()) {
          await item.save()
        }
      })
    },

    addMapping () {
      this.mapping.data.push(new Mapping({
        id: 0,
        task_id: this.taskId,
        source_field_id: null,
        source_column: null,
        source_alias: null,
        target_field_id: null,
        target_column: null,
        target_alias: null,
        is_key: false,
        is_required: false,
        is_load_xref_table_values: false,
        xref_field_id: null,
        xref_condition: null,
        loader_id: null,
        value: null,
        row_order: 0,
        condition: null
      }))
    },

    async deleteMapping (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.ok'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(async () => {
        if (this.mapping.data[index].id) {
          await this.mapping.data[index].delete()
          this.mapping.count--
          this.mapping.data.splice(index, 1)
        } else {
          this.mapping.data.splice(index, 1)
        }
      })
    },

    getObjectList (data) {
      console.log('getObjectList', data)

      this.selectSourceField = []
      if (data.extractor_type_id === 'object_table' && data.extractor_object_id !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.extractor_object_id}&is_field=true`)
          .then((response) => {
            this.selectSourceField.push(...response.data.data)
          })
      }

      this.selectTargetField = []
      if (data.loader_type_id === 'object_table' && data.loader_object_id !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${data.loader_object_id}&is_field=true`)
          .then((response) => {
            this.selectTargetField.push(...response.data.data)
          })
      }
    },

    tablePageChange (val) {
      this.mapping.data.forEach(item => {
        if (item.isEdit()) {
          this.$confirm('Сохранить изменения?', this.$t('main.message_title.warning'), {
            confirmButtonText: this.$t('main.button.ok'),
            cancelButtonText: this.$t('main.button.no'),
            type: 'warning'
          }).then(async () => {
            await item.save()

            this.tpc(val)
          }).catch(response => {
            this.tpc(val)
          })
        } else {
          this.tpc(val)
        }
      })
    },

    getObjectName (arrayObjects, searchValue) {
      const object = arrayObjects.find(obj => obj.id === searchValue)

      if (typeof object !== 'undefined') {
        return `${object.name} (id: ${object.id})`
      }

      return '(нет данных)'
    },

    async tpc (val) {
      val--
      this.pagination.current = (val * this.pagination.limit)

      let count = await Mapping.params({ '*': { func: 'count' }, task_id: this.taskId }).get()
      this.mapping.count = count.length ? count[0].count : 0
      this.mapping.data = await Mapping.params({ task_id: this.taskId, offset: this.pagination.current, limit: this.pagination.limit }).orderBy('row_order:asc', 'id:asc').get()
    },

    fullScreen () {
      this.isFullScreen = !this.isFullScreen
    }
  }
}
</script>

<style lang="scss">
.table_mapping {

  &.full_screen {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
  }

  .el-table .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}
</style>
