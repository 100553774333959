import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import FeatureCommandRepositoryInterface from '@/services/MapEditor/domain/repository/FeatureCommandRepositoryInterface'
import FeatureUpdateCommand from '@/services/MapEditor/application/command/FeatureUpdateCommand'

export default class FeatureUpdateHandler implements CommandHandlerInterface
{
    private repo: FeatureCommandRepositoryInterface;

    constructor(repo: FeatureCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: FeatureUpdateCommand): Promise<void>
    {
        let item = { data: command.getData() };
        return this.repo.update(item);
    }
}