import CommandInterface from '@/core/application/command/CommandInterface'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'

export default class ApprovalCreateCommand implements CommandInterface {
  constructor (private readonly dto: ApprovalDTO) {}

  getClassName (): string {
    return 'ApprovalCreateCommand'
  }

  getDTO (): ApprovalDTO {
    return this.dto
  }
}
