import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProcedureCreateCommand from '@/services/LogicEditor/application/command/ProcedureCreateCommand'
import ProcedureCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ProcedureCommandRepositoryInterface'

export default class ProcedureCreateHandler implements CommandHandlerInterface {
  private repository: ProcedureCommandRepositoryInterface

  constructor (repository: ProcedureCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ProcedureCreateCommand): Promise<void> {
    return this.repository.insert(command.getDTO())
  }
}
