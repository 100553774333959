var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c('el-form',{ref:"request_form",attrs:{"size":"mini","model":_vm.localModel,"rules":_vm.rules}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),_c('el-form-item',{staticClass:"form_element",attrs:{"prop":"name"}},[_c('span',[_vm._v(_vm._s(_vm.$t('api_service.request.name')))]),_c('el-input',{model:{value:(_vm.localModel.name),callback:function ($$v) {_vm.$set(_vm.localModel, "name", $$v)},expression:"localModel.name"}})],1),(_vm.requestType === 'project')?_c('el-form-item',{staticClass:"form_element"},[_c('span',[_vm._v(_vm._s(_vm.$t('api_service.request.connections')))]),_c('editor-select',{ref:"connections_select",attrs:{"value":_vm.localModel.connections,"options":{
          multiple: true,
          clearable: true,
          options: _vm.connections
        }},on:{"change":function($event){return _vm.$set(_vm.localModel ,'connections', $event)}}})],1):_vm._e(),(_vm.requestType === 'request')?_c('div',[_c('el-form-item',{staticClass:"form_element",attrs:{"prop":"request_type"}},[_c('span',[_vm._v(_vm._s(_vm.$t('api_service.request.type')))]),_c('editor-select',{ref:"types_select",attrs:{"value":_vm.localModel.request_type,"options":{
          multiple: false,
          clearable: true,
          options: _vm.types
        }},on:{"change":function($event){return _vm.$set(_vm.localModel ,'request_type', $event)}}})],1),_c('el-form-item',{staticClass:"form_element",attrs:{"prop":"protocol_type"}},[_c('span',[_vm._v(_vm._s(_vm.$t('api_service.request.protocol')))]),_c('editor-select',{ref:"protocol_select",attrs:{"value":_vm.localModel.protocol_type,"options":{
          multiple: false,
          clearable: true,
          options: _vm.protocols
        }},on:{"change":function($event){return _vm.$set(_vm.localModel ,'protocol_type', $event)}}})],1),_c('el-form-item',{staticClass:"form_element",attrs:{"prop":"method_type_id"}},[_c('span',[_vm._v(_vm._s(_vm.$t('api_service.request.method')))]),_c('editor-select',{ref:"method_select",attrs:{"value":_vm.localModel.method_type_id,"options":{
          multiple: false,
          clearable: true,
          options: _vm.methods
        }},on:{"change":function($event){return _vm.$set(_vm.localModel ,'method_type_id', $event)}}})],1)],1):_vm._e(),_c('el-form-item',{staticClass:"save_button"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.validate}},[_vm._v(" Добавить ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }