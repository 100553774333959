
import Vue from 'vue'

export interface IKeyValue {
  key: string;
  value: string;
  type?: string;
}

export default Vue.extend({
  name: 'KeyValueForm',

  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },

    prop: String,

    label: String,

    types: {
      type: Array,
      default () {
        return []
      }
    },

    defaultType: {
      type: String,
      default: ''
    },

    showType: Boolean,

    keyAutocomplete: Boolean,
    keyList: {
      type: Array,
      default () {
        return []
      }
    },

    valueAutocomplete: Boolean,
    valueList: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value: IKeyValue[]) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = value
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value: IKeyValue[]) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      },
      deep: true
    }
  },

  data () {
    return {
      localValue: this.value
    }
  },

  methods: {
    addParameter () {
      this.localValue.push({
        key: '',
        value: '',
        type: this.defaultType
      })
    },

    removeParameter (index: number) {
      this.localValue.splice(index, 1)
    },

    keySearch (queryString, cb) {
      const results = queryString
        ? this.keyList.filter((key) => key.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
        : this.keyList

      cb(
        results.map((result) => ({
          value: result
        }))
      )
    },

    valueSearch (queryString, cb) {
      const results = queryString
        ? this.valueList.filter((value) => value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
        : this.valueList

      cb(
        results.map((result) => ({
          value: result
        }))
      )
    }
  }
})
