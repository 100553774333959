import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetTransformers, GetTransformerByGuid, GetTransformerTypes, GetTransformerTypeProperties } from '@/services/EtlEditor/infrastructure/store/modules/Transformer/action-types'
import TransformerQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerQueryRepositoryInterface'

export default class TransformerQueryRepository extends AbstractQueryRepository implements TransformerQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTransformers(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetTransformerByGuid(guid))
  }

  getTypes (): Promise<any> {
    return store.dispatch(new GetTransformerTypes())
  }

  getTypeProperties (type: string): Promise<any> {
    return store.dispatch(new GetTransformerTypeProperties(type))
  }
}
