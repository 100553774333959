export default class FeatureUpdateCommand
{
    private data: string;

    constructor(
        data: string
    ) {
        this.data = data;
    }

    getClassName(): string
    {
        return 'FeatureUpdateCommand';
    }

    getData(): string
    {
        return this.data;
    }
}