<template>
  <el-tree
    ref="tree"
    :data="dataList"
    :props="props"
    class="source-tree"
    node-key="id"
    @node-click="select"
    default-expand-all
    :expand-on-click-node="false"
  >
    <span slot-scope="{ node, data }" :class="{ 'custom-tree-node': true, 'selected-node': selected !== null && selected.id === data.id }">
      <span>
        {{ `${node.label} (${$t('report_editor.object_types.' + data.type)})` }}
      </span>

      <span>
        <el-button v-if="childAppend && data.type !== 'xml'" size="mini" type="text" @click.stop="_append(data)">
          {{ $t('main.button.add') }}
        </el-button>

        <el-button class="text_danger" size="mini" type="text" @click.stop="_remove(node, data)">
          {{ $t('main.button.delete') }}
        </el-button>
      </span>
    </span>
  </el-tree>
</template>

<script>
export default {
  name: 'SourceTree',

  props: {
    sources: {
      type: Array,
      default () {
        return []
      }
    },

    append: {
      type: Function,
      default () {
        return function (parentId = null) {
        }
      }
    },

    edit: {
      type: Function,
      default () {
        return function () {
        }
      }
    },

    remove: {
      type: Function,
      default () {
        return function (sourceId, sourceIndex) {
        }
      }
    },

    childAppend: {
      type: Boolean,
      default () {
        return true
      }
    }
  },

  data () {
    return {
      dataList: [],
      selected: null,
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'is_leaf'
      }
    }
  },

  mounted () {
    this.dataList = this._buildTree(this.sources)
  },

  methods: {
    _append (data) {
      this.append(data.id)
    },

    _remove (node, data) {
      if (this.selected && this.selected.id === data.id) {
        this.selected = null
      }

      this.remove(data.id, data.originIndex)

      this.$refs.tree.remove(data.id)
    },

    select (data) {
      this.selected = data
      this.edit(data)
    },

    getTree () {
      return this.$refs.tree
    },

    _buildTree (elements, parentId = null) {
      let data = []
      elements.forEach((el, index) => {
        if (el.parent_id === parentId) {
          let children = this._buildTree(elements, el.id)
          data.push({
            id: el.id,
            type: el.report_source_type_id,
            name: el.properties.name,
            originIndex: index,
            children: this._buildTree(elements, el.id),
            is_leaf: !children.length
          })
        }
      })
      return data
    }
  }
}
</script>
