import ProjectGroupCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectGroupCommandRepositoryInterface'
import { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'
import store from '@/store'
import { UpdateProjectGroup } from '@/services/MapEditor/infrastructure/store/modules/ProjectGroup/action-types'

export default class ProjectGroupCommandRepository implements ProjectGroupCommandRepositoryInterface
{
    update(item: ProjectGroupDTO): Promise<void>
    {
        return store.dispatch(new UpdateProjectGroup(item));
    }
}
