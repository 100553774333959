
import { PropType } from 'vue'
import InputShadowItem from './InputShadowItem.vue'
import InputMixin from '@/services/CssEditor/infrastructure/components/InputMixin'
import mixins from 'vue-typed-mixins'

export default mixins(InputMixin).extend({
  name: 'InputShadow',

  components: {
    InputShadowItem
  },

  props: {
    value: String as PropType<string>
  },

  computed: {
    nativeValue (): string {
      if (this.isVarValue) {
        return this.localValue
      }

      return this.localValue.join(', ')
    },

    isVarValue () {
      return !!this.variables[this.localValue]
    },

    allTemplates (): string[] {
      return this.templates.map(t => t.value)
    },

    templates () {
      return [
        { label: 'Custom', value: 'custom' },
        ...this.variableList.filter(v => v.value !== this.currentVariable)
      ]
    }
  },

  watch: {
    value () {
      if (this.value !== this.nativeValue) {
        this.parseValue()
      }
    },

    nativeValue () {
      if (this.allTemplates.includes(this.nativeValue)) {
        this.templateValue = this.nativeValue
      }

      if (this.nativeValue !== this.value) {
        this.$emit('change', [this.nativeValue, this.value])
        this.$emit('input', this.nativeValue)
      }
    },

    templateValue () {
      if (this.templateValue !== 'custom') {
        if (typeof this.variables[this.templateValue] !== 'undefined' && !this.isVarValue) {
          this.localOldValue = this.localValue
        }

        this.localValue = this.templateValue
      } else {
        if (this.isVarValue) {
          this.localValue = this.localOldValue
        }
      }
    }
  },

  data () {
    return {
      localValue: [],
      localOldValue: [],

      templateValue: 'custom'
    }
  },

  mounted () {
    this.parseValue()
  },

  methods: {
    getNativeValue (value: string|string[]) {
      if (typeof value === 'string' && typeof this.variables[value] !== 'undefined') {
        return value
      }

      return Array.isArray(value)
        ? value.join(', ')
        : null
    },

    parseValue () {
      const value = this.value || null

      if (typeof value === 'string' && typeof this.variables[this.value] !== 'undefined') {
        return this.value
      }

      this.localValue = (String(value) || '')
        .replace(/,(?![^\(]*\))/g, ';')
        .split(';')
        .map(v => v.trim())
    },

    remove (index) {
      this.localValue.splice(index, 1)
    }
  }
})
