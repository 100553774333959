
import ServicesQuery from '@/services/ReleaseEditor/application/query/ServicesQuery'


export default {
  name: 'ServicesPanel',
  data () {
    return {
      loading: false,
      services: [],
      service: null,
      serviceDto: null,
      serviceDtoPrev: null,
      isDuplicate: false,
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    isServiceLoading: (state) => state
  },
  computed: {
    isServiceLoading() {
      return this.$store.getters['Service/isLoading'];
    }
  },
  methods: {
    async loadServices(callback) {
      await this.getQueryBus().execute(
        new ServicesQuery({})
      ).then(data => {
        this.services = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  },
  mounted() {
    this.loadServices();
  }
}
