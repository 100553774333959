import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LogicUpdateCommand implements CommandInterface {
  private guid: string
  private description: Nullable<string>
  private alias: Nullable<string>

  constructor (
    guid: string,
    description: Nullable<string>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.description = description
    this.alias = alias
  }

  getClassName (): string {
    return 'LogicUpdateCommand'
  }

  getGuid (): string {
    return this.guid
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
