import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SnapshotRepositoryInterface from '../../../domain/repository/SnapshotRepositoryInterface'
import SnapshotsSchemaFilterValuesQuery from '@/services/SyncService/application/query/SnapshotsSchemaFilterValuesQuery'

export default class SnapshotsSchemaFilterValuesHandler implements QueryHandlerInterface {
  private repo: SnapshotRepositoryInterface

  constructor (repo: SnapshotRepositoryInterface) {
    this.repo = repo
  }

  execute (query: SnapshotsSchemaFilterValuesQuery): Promise<any> {
    return this.repo.getSchemaFilter()
  }
}
