
import Vue from 'vue'
import InputNumber from '../InputNumber.vue'
import utils from '@/services/CssEditor/infrastructure/components/utils'

export interface ILocalValue {
  offsetX: number;
  offsetY: number;
  blur: number;
  spread: number;
  color: string;
}

export default Vue.extend({
  name: 'InputShadowItem',

  components: {
    InputNumber
  },

  props: {
    value: String,

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'small'
    },

    index: {
      type: Number,
      default: 0
    }
  },

  computed: {
    nativeValue (): string {
      let { color = 'transparent', offsetX = 0, offsetY = 0, spread = 0, blur = 0 } = this.localValue

      offsetX = offsetX !== 0 ? offsetX += this.sizeClass.offsetX : 0
      offsetY = offsetY !== 0 ? offsetY += this.sizeClass.offsetY : 0

      blur = blur < -1 ? 0 : blur
      blur = blur === 0 ? 0 : blur += this.sizeClass.blur

      spread = spread === 0 ? 0 : spread += this.sizeClass.spread

      return `${offsetX} ${offsetY} ${blur} ${spread} ${color}`
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (value !== this.nativeValue) {
          this.parseNativeValue()
        }
      }
    },

    localValue: {
      handler: function (value) {
        this.$emit('input', this.nativeValue)
      },
      deep: true
    },

    sizeClass: {
      handler: function () {
        this.$emit('input', this.nativeValue)
      },
      deep: true
    }
  },

  data () {
    return {
      localValue: {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        spread: 0,
        color: '#000000'
      },

      sizeClass: {
        offsetX: 'px',
        offsetY: 'px',
        blur: 'px',
        spread: 'px'
      },
      sizeClasses: utils.sizeClasses
    }
  },

  mounted () {
    this.parseNativeValue()
  },

  methods: {
    parseNativeValue () {
      // Извлекаем значения по переменным
      let [offsetX = '0', offsetY = '0', blur = '0', spread = '0', ...c] = this.value.split(' ')

      // Извлекаем ед. измерения для значений
      this.sizeClass.offsetX = utils.getSizeClass(offsetX)
      this.sizeClass.offsetY = utils.getSizeClass(offsetY)
      this.sizeClass.blur = utils.getSizeClass(blur)
      this.sizeClass.spread = utils.getSizeClass(spread)

      // Преобразуем типы данных
      offsetX = parseInt(offsetX)
      offsetY = parseInt(offsetY)
      blur = parseInt(blur)
      spread = parseInt(spread)

      // Формируем модель данных
      this.localValue = {
        offsetX: (isNaN(offsetX) ? 0 : offsetX) as number,
        offsetY: (isNaN(offsetY) ? 0 : offsetY) as number,
        blur: (isNaN(blur) ? 0 : blur) as number,
        spread: (isNaN(spread) ? 0 : spread) as number,
        color: c.join(' ')
      }
    }
  }
})
