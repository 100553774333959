import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StateOperationTypesQuery from '@/services/LogicEditor/application/query/StateOperationTypesQuery'
import StateQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/StateQueryRepositoryInterface'

export default class StateOperationTypesHandler implements QueryHandlerInterface {
  private repository: StateQueryRepositoryInterface

  constructor (repository: StateQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: StateOperationTypesQuery): Promise<any> {
    return this.repository.getStateOperationTypes()
  }
}
