import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Object,
      required: true
    },

    logicId: {
      type: Number
    },

    objectId: {
      type: Number
    }
  }
})
