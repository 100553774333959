import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RuleGroupUpdateCommand from '@/services/TaskEditor/application/command/RuleGroupUpdateCommand'
import RuleGroupCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleGroupCommandRepositoryInterface'

export default class RuleGroupUpdateHandler implements CommandHandlerInterface {
    private repository: RuleGroupCommandRepositoryInterface;

    constructor (repository: RuleGroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RuleGroupUpdateCommand): Promise<void> {
      return this.repository.update(command.getModel())
    }
}
