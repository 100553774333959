import CommandInterface from '@/core/application/command/CommandInterface'
import { CssClassDTO } from '@/services/CssEditor/domain/model/CssClass'

export default class CssClassUpdateCommand implements CommandInterface {
  private dto: CssClassDTO;

  constructor (
    dto: CssClassDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'CssClassUpdateCommand'
  }

  getDto (): CssClassDTO {
    return this.dto
  }
}
