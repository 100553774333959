<template>
  <el-form @submit.native.prevent class="downloadTemplate" label-position="top" label-width="100px">
    <div style="position: absolute;top: 66px; right: 60px;">
      <el-checkbox style="margin-right: 10px" v-model="isNewDesigner" v-show="!activeTemplate">Новый дизайнер</el-checkbox>
      <el-button @click="cancel" size="small">{{ $locale.main.button.cancel }}</el-button>
      <el-button type="primary" size="small" :disabled="disabledButton" @click="addDashboard">{{ $locale.main.button.save }}</el-button>
    </div>
    <div style="display: flex; margin-top: 20px;">
      <el-form-item class="required" style="margin-bottom: 5px; flex: 1; margin-right: 30px;">
        <el-input ref="inputName" v-model="form.value"  :placeholder="$locale.dashboard_editor.template.new_templates" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 5px; flex: 1; margin-right: 30px;">
        <el-input ref="inputName" v-model="form.alias"  :placeholder="$t('main.fields.alias')" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item style="flex: 1;">
        <el-input
          :placeholder="$locale.dashboard_editor.template.search"
          suffix-icon="el-icon-search"
          v-model.trim="form.search">
        </el-input>
      </el-form-item>
    </div>
    <div class="tag custom_scrollbar">
      <template>
        <el-tag
          v-show="tag.length"
          v-for="tag in tags" :key="tag"
          :type="activeTags.includes(tag) ? 'warning' : 'info'"
          style="cursor:pointer; margin: 5px"
          @click="selectTag(tag)">
          {{tag}}
        </el-tag>
      </template>
    </div>
    <div class="preview custom_scrollbar" style="height: calc(70vh - 70px); overflow-x:hidden;">
      <div class="image_box preview_box">
        <div style="position: relative;" @click="activeTemplate = null">
          <el-image
            class="templateImage default_image"
            :class="{ imageActive: activeTemplate === null }"
            fit="scale-down">
            <div slot="error" class="image-slot">
              <i class="el-icon-plus"></i>
            </div>
          </el-image>
          <div class="description_box custom_scrollbar">
            <div class="name">{{ $locale.dashboard_editor.template.new }}</div>
            <div class="description">{{ $locale.dashboard_editor.template.empty }}</div>
          </div>
        </div>
         <el-image v-show="loading" class="templateImage" v-loading="loading">
          <div slot="error" class="image-slot"></div>
        </el-image>
        <template v-if="templates.length > 1">
          <div style="position: relative;" v-for="template in foundTemplate" :key="template.id">
            <!-- <el-image
              class="templateImage"
              :class="{ imageActive: template.id === activeTemplate }"
              :src="template.thumbnail"
              @click="selectTemplate(template.id)"
              fit="scale-down">
            </el-image> -->
            <div
              class="templateImage"
              :style="{backgroundImage:`url(${template.thumbnail})`}"
              style="background-size: contain; background-repeat: no-repeat;"
              :class="{ imageActive: template.id === activeTemplate }"
              @click="selectTemplate(template.id)"
            >
            </div>

            <div class="description_box custom_scrollbar" @click="selectTemplate(template.id)">
              <div class="name">{{template.name}}</div>
              <div class="description">{{template.description}}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </el-form>
</template>

<script>
import Templates from './Models/Templates'

export default {
  props: {
    templateWindow: {
      type: Boolean,
      default: false
    },
    templateModel: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      templates: [
        { id: '', tags: '', name: '' }
      ],
      tags: [],
      activeTemplate: null,
      activeTags: [],
      loading: false,
      isNewDesigner: false,
      form: {
        value: null,
        search: ''

      }
    }
  },
  computed: {
    disabledButton () {
      if (this.form.value) {
        return false
      }
      return true
    },
    // фильтр по тегам шаблонов
    foundTags () {
      const tags = this.activeTags.map(item => item.trim())
      this.templates.map(item => {
        item.tag = item.tags.split(',').map(tag => tag.trim())
        return item
      })
      return this.templates.filter(item => item.tag.some(name => tags.includes(name)))
    },
    // фильтр по имени шаблонов
    foundTemplate () {
      const searchName = this.form.search.toLowerCase()
      if (this.foundTags.length) {
        return this.foundTags.filter(item => item.name.toLowerCase().includes(searchName))
      }
      return this.templates.filter(item => item.name.toLowerCase().includes(searchName))
    }
  },
  watch: {
    // при открытии компонента запрос к серверу
    async templateWindow (newValue) {
      if (newValue) {
        this.loading = true
        let templates = await Templates.$get()
        this.templates = templates
        // уникальные теги
        this.tags = this.templates.reduce((acc, template) => {
          acc.push(...template.tags.split(','))
          return acc
        }, [])
        this.tags = [...new Set(this.tags)]
        this.loading = false
      } else {
        this.clearForm()
        this.loading = false
      }
    }
  },
  methods: {
    selectTemplate (templateId) {
      if (this.activeTemplate === templateId) {
        this.activeTemplate = null
        return
      }
      this.activeTemplate = templateId
    },
    selectTag (tag) {
      if (this.activeTags.includes(tag)) {
        this.activeTags = this.activeTags.filter(item => item !== tag)
        return
      }
      this.activeTags.push(tag)
    },
    clearForm () {
      this.form.value = this.activeTemplate = null
      this.form.search = ''
    },
    cancel () {
      this.clearForm()
      this.$emit('update:templateWindow', false)
    },
    addDashboard () {
      if (this.activeTemplate) {
        let activeTemplate = this.templates.find(item => this.activeTemplate === item.id)
        this.$emit('add-dashboard',
          {
            type: this.templateModel.type,
            parent: this.templateModel.parent,
            name: this.form.value,
            structure: activeTemplate.structure
          }
        )
      } else {
        this.$emit('add-dashboard', {
          type: this.templateModel.type,
          parent: this.templateModel.parent,
          name: this.form.value,
          isNewDesigner: this.isNewDesigner
        })
      }
      this.$emit('update:templateWindow', false)
      this.clearForm()
    }
  }
}
</script>

<style scoped >
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .downloadTemplate {
    padding: 20px;
    padding-top: 0;
  }
  .downloadTemplate  /deep/ .el-form-item__label {
    padding-bottom: 0;
  }
  .downloadTemplate .required  /deep/ .el-form-item__label {
    position: relative;
  }
  .downloadTemplate .required /deep/ .el-form-item__label::after {
    content: '* ';
    color: #ff4949;
    font-weight: bold;
    position: absolute;
    top: -8px;
  }
  .downloadTemplate .tag {
    margin-bottom: 20px;
    margin-top: 12px;
    display: flex;
    min-height: 33px;
    flex-wrap: wrap;
    max-height: 85px;
    overflow-y: auto;
  }
  .downloadTemplate .preview {
    background: #D2D6DA;
    padding-left: 10px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
  }
  .downloadTemplate .default_image {
    background: #E5F5FF;
    display: flex;
    align-items: start;
    justify-content: center;
    font-size: 35px;
    width: 100%;
    padding-top: 110px;
  }
  .downloadTemplate .image_box {
    position: relative;
    margin: 5px;
    display: flex;
    /* flex: 1 0 auto; */
    flex-wrap: wrap;
  }
  .downloadTemplate .templateImage {
    width: 430px;
    height: 320px;
    margin: 15px;
    margin-top: 15px;
    transition: .5s;
    cursor: pointer;
    /* filter: grayscale(100%); */
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 8px;
    border: 1px solid #4FA9F3;
    box-sizing: border-box;
    background: #fff;
  }
  .downloadTemplate .description_box {
    position: absolute;
    display: block;
    width: 93%;
    box-sizing: border-box;
    height: 82px;
    overflow-y: auto;
    background: #fff;
    bottom: 46px;
    margin-left: 16px;
    bottom: 5px;
    margin-bottom: 15px;
    border-radius: 0 0 8px 8px;
    padding: 16px;
    padding-top: 12px;
    transition: .5s;
    cursor: pointer;
    box-shadow: 0px -20px 20px 0px rgb(34 60 80 / 20%);
}
  .downloadTemplate .name {
    color: #2D3943;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .downloadTemplate .description {
    color: #788591;
    font-size: 13px;
  }
  .downloadTemplate .templateImage:hover{
    transform: scale(1.02);
    box-shadow: 0px -1px 10px 2px rgba(241, 207, 9, 1);
    /* filter: grayscale(0); */
  }
  .downloadTemplate .templateImage:hover +.description_box {
    width: 434px;
    margin-left: 13px;
    bottom: 4px;
    overflow-y: auto;
  }
  .downloadTemplate .imageActive +.description_box {
    width: 434px;
    margin-left: 13px;
    bottom: 4px;
    /* box-shadow: 0px -1px 10px 2px rgba(231, 11, 213, 0.712); */
  }
  .downloadTemplate .imageActive {
    transform: scale(1.02);
    box-shadow: 0px -1px 10px 2px rgba(241, 206, 9, 1);
    /* filter: grayscale(0); */
  }
  .downloadTemplate .footer {
    position: sticky;
    bottom: 0px;
    left: 20px;
    /* background: #D2D6DA; */
    z-index: 10;
    text-align: right;
    display: block;
    width: 97%;
    height: 46px;
    padding-bottom: 10px;
  }
</style>
