<template>
  <el-row>
    <el-col :span="12">
      <registry-select-tree
        v-model="localValue.registryId"
        type="registry"
        value-as="number"
      ></registry-select-tree>
    </el-col>
    <el-col :span="12">
      <el-select
        v-model="localValue.cards"
        multiple
        size="mini"
      >
        <el-option v-for="(item, index) in cards" :key="index" :label="item.name" :value="item.id">
          <span style="float: left">{{ item.name }} (id: {{ item.id }})</span>
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import Card from '@/components/ObjectEditor/Models/Card'

export default {
  name: 'ConstraintRow',
  components: { RegistrySelectTree },
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    localValue (value) {
      this.$emit('input', value)
    },
    'localValue.registryId': {
      handler () {
        this.localValue.cards = []
        this.loadCards()
      }
    }
  },
  data () {
    return {
      localValue: this.value || {},
      cards: []
    }
  },
  mounted () {
    this.loadCards()
  },
  methods: {
    async loadCards () {
      if (this.localValue?.registryId) {
        this.cards = await new Card().params({ entity_id: this.localValue.registryId, fields: ['id', 'name'] }).$get()
      } else {
        this.cards = []
      }
    }
  }
}
</script>

<style scoped>

</style>
