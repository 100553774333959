import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TransformerCreateCommand implements CommandInterface {
  private name: string
  private transformerTypeId: string
  private alias: Nullable<string>
  private properties: Array<object>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    transformerTypeId: string,
    alias: Nullable<string>,
    properties: Array<object>,
    parentTreeElementId: number
  ) {
    this.name = name
    this.transformerTypeId = transformerTypeId
    this.alias = alias
    this.properties = properties
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'TransformerCreateCommand'
  }

  getName (): string {
    return this.name
  }

  getTransformerTypeId (): string {
    return this.transformerTypeId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getProperties (): Array<object> {
    return this.properties
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
