import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SchemaRepositoryInterface from '@/services/ApiService/domain/repository/SchemaRepositoryInterface'
import UpdateSchemaCommand from '@/services/ApiService/application/command/UpdateSchemaCommand'

export default class UpdateSchemaHandler implements CommandHandlerInterface {
  private repository: SchemaRepositoryInterface;

  constructor (repository: SchemaRepositoryInterface) {
    this.repository = repository
  }

  execute (command: UpdateSchemaCommand): Promise<void> {
    return this.repository.updateSchema(command.getDto())
  }
}
