
import TopicCreateCommand from '@/services/Databus/application/command/TopicCreateCommand'
import TopicUpdateCommand from '@/services/Databus/application/command/TopicUpdateCommand'
import TopicDeleteCommand from '@/services/Databus/application/command/TopicDeleteCommand'
import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
import TopicsCountQuery from '@/services/Databus/application/query/TopicsCountQuery'
import Topic, { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export default {
  name: 'TopicPanel',
  data () {
    return {
      loading: false,
      topicsPageSize: 0,
      defaultTopicsPageSize: 100,
      topicsCurrentPage: 0,
      topicsPageLimit: 100,
      topicsCount: 0,
      allTopicsCount: 0,
      topics: [],
      topic: null,
      topicDto: null,
      topicDtoPrev: null,
      topicDtoParams: [],
      topicIsRouted: false,
      useSmevFtp: false,
      topicSnIsAny: false,
      topicSnWithFiles: false,
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateTopicWindowVisible: false,
      topicTypes: [{
        id: "package",
        name: this.$locale.databus.topic_panel.package
      },{
        id: "smev_request",
        name: this.$locale.databus.topic_panel.smev_request
      },{
        id: "smev_incoming_request",
        name: this.$locale.databus.topic_panel.smev_incoming_request
      },{
        id: "smev_outgoing_response",
        name: this.$locale.databus.topic_panel.smev_outgoing_response
      },{
        id: "gis_gkh_request",
        name: this.$locale.databus.topic_panel.gis_gkh_request
      },{
          id: "sn_request",
          name: this.$locale.databus.topic_panel.sn_request
      }],
      createTopicRules: {
        code: {
          required: true,
          trigger: 'change',
          validator: (rules, value, callback) => {
            if (value) {
              if (!/^[a-zA-Z_0-9]+[a-zA-Z_0-9]+$/.test(value)) {
                callback(new Error(this.$locale.databus.topic_panel.invalid_topic_code_format))
              } 
            } else {
              callback(new Error(this.$locale.main.message.required_field))
            }
          }
        },
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      topicsFilters: {
        type: null
      },
      fakeSmevSwitcher: [{
        id: "rest",
        name: 'REST'
      },{
        id: "soap",
        name: 'SOAP'
      }],
      fakeSmevValue: 'soap'
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    topicCreated: function (location) {
      this.loadTopics(() => {
        this.closeCreateTopicWindow();
      });
    },
    isTopicLoading: (state) => state,
    getSaveTopicError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.databus.topic_panel.duplicate_topic_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isTopicLoading() {
      return this.$store.getters['Topic/isLoading'];     
    },
    topicCreated() {
      return this.$store.getters['Topic/getLocation'];
    },
    getSaveTopicError() {
      return this.$store.getters['Topic/getError']; 
    }
  },
  methods: {
    loadTopics(callback) {
      if (this.allTopicsCount == 0) {
        this.countAndLoadTopics(callback);
      }
      else {
        this.loadAllTopics(callback);
      }
    },
    async countAndLoadTopics(callback) {
      await this.getQueryBus().execute(
        new TopicsCountQuery(this.prepareUrlQueryParams(true))
      ).then(data => {
        this.topicsCount = data[0].count;      
        this.loadAllTopics(callback);
      });
    },
    async loadAllTopics(callback) {
      await this.getQueryBus().execute(
        new TopicsQuery(this.prepareUrlQueryParams())
      ).then(data => {
        this.topicsPageSize = this.topicsPageLimit;
        this.topics = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createTopic() {
      this.topicDtoPrev = this.topicDto;
      this.topicDto = new TopicDTO({});
      this.isCreateTopicWindowVisible = true;
    },
    updateTopic() {
      if (this.topic !== null) {
        if (this.topicDtoPrev != null) {
          this.topicDto = this.topicDtoPrev;
          this.topicDtoPrev = null;
          this.changeTopicType();
        }
        this.isCreateTopicWindowVisible = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveTopic() {
      let topic = Topic.create(this.topicDto);
      if (this.topicDtoParams.length == 0) {
        topic.setParams(this.topicDtoParams);
      }
      else {
        topic.setParams([this.topicDtoParams]);
      }
      if (topic.getId() == null) {
        this.getCommandBus().execute(
          new TopicCreateCommand(
            topic.getCode(),
            topic.getName(),
            topic.getDescription(),
            topic.getType(),
            topic.getParams()
          )
        );
      }
      else {
        this.getCommandBus().execute(
            new TopicUpdateCommand(
              topic.getGuid(),
              topic.getCode(),
              topic.getName(),
              topic.getDescription(),
              topic.getType(),
              topic.getParams()
            )
          ).then(() => {
            if (this.isDuplicate) {
              this.isDuplicate = false;
            } else {
              this.closeCreateTopicWindow();
            }
          });
      }
    },
    closeCreateTopicWindow() {
      //this.topicDto = null;
      this.isCreateTopicWindowVisible = false;
    },
    changeTopic(topicDto) {
      if (topicDto !== null) {
        this.topic = Topic.create(topicDto);
        this.topicDto = topicDto;
      }
      else {
        this.topic = null;
        this.topicDto = null;
      }
      this.changeTopicType();
    },
    deleteTopic() {
      if (this.topic == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
      else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new TopicDeleteCommand(
              this.topic.getGuid()
            )
          ).then((response) => {
            this.topic = null;
            this.$refs.topics_table.setCurrentRow(null);
            this.loadTopics();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    handleTopicsPageChange(val) {
      val--;
      this.topicsCurrentPage = (val * this.topicsPageLimit);
      this.loadTopics();
    },
    changeTopicType() {
      this.topicDtoParams = [];
      if (this.topicDto.type == 'package') {
        this.topicDtoParams = {
          "to_import" : {"name": this.$locale.databus.topic_panel.to_import, "value": "0"}
        };
        if (this.topicDto.params != null) {
          this.topicDtoParams.to_import.value = this.topicDto.params[0].to_import.value;
          (this.topicDtoParams.to_import.value == "1") ? this.topicIsRouted = true : this.topicIsRouted = false;
        }
      }
      if (this.topicDto.type == 'smev_request' || this.topicDto.type == 'smev_incoming_request' || this.topicDto.type == 'smev_outgoing_response') {
        this.topicDtoParams = {
          "use_smev_ftp" : {"name": this.$locale.databus.topic_panel.use_smev_ftp, "value": "0"}
        };
        if (this.topicDto.params != null) {
          this.topicDtoParams.use_smev_ftp.value = this.topicDto.params[0].use_smev_ftp.value;
          (this.topicDtoParams.use_smev_ftp.value == "1") ? this.useSmevFtp = true : this.useSmevFtp = false;
        }
      }
      if (this.topicDto.type == 'gis_gkh_request') {
        this.topicDtoParams = {
          "pid" : {"name": this.$locale.databus.topic_panel.gis_gkh_pid, "value": ""},
          "url" : {"name": this.$locale.databus.topic_panel.gis_gkh_url, "value": ""},
          "action" : {"name": this.$locale.databus.topic_panel.gis_gkh_action, "value": ""},
          "store" : {"name": this.$locale.databus.topic_panel.gis_gkh_store, "value": ""},
        };
        if (this.topicDto.params != null) {
          this.topicDtoParams.pid.value = this.topicDto.params[0].pid.value;
          this.topicDtoParams.url.value = this.topicDto.params[0].url.value;
          this.topicDtoParams.action.value = this.topicDto.params[0].action.value;
          this.topicDtoParams.store.value = this.topicDto.params[0].store.value;
        }
      }
      if (this.topicDto.type == 'sn_request') {
        this.topicDtoParams = {
          "sender" : {"name": this.$locale.databus.topic_panel.sn_sender, "value": ""},
          "reciever" : {"name": this.$locale.databus.topic_panel.sn_reciever, "value": ""},
          "schema_name": {"name": this.$locale.databus.topic_panel.sn_schema, "value": ""},
          "is_any" : {"name": this.$locale.databus.topic_panel.sn_is_any, "value": "0"},
          "with_files" : {"name": this.$locale.databus.topic_panel.sn_with_files, "value": "0"},
        };
        if (this.topicDto.params != null) {
          this.topicDtoParams.sender.value = this.topicDto.params[0].sender.value;
          this.topicDtoParams.reciever.value = this.topicDto.params[0].reciever.value;
          this.topicDtoParams.schema_name.value = this.topicDto.params[0].schema_name.value;
          if (this.topicDto.params[0].is_any.value == "1") {
            this.topicSnIsAny = true;
            this.topicDtoParams.is_any.value = "1";
          }
          else {
            this.topicSnIsAny = false;
            this.topicDtoParams.is_any.value = "0";
          }
          if (this.topicDto.params[0].with_files.value == "1") {
            this.topicSnWithFiles = true;
            this.topicDtoParams.with_files.value = "1";
          }
          else {
            this.topicSnWithFiles = false;
            this.topicDtoParams.with_files.value = "0";
          }
        }
      }
    },
    changeRoutedStatus() {
      if (this.topicDtoParams.to_import.value == "0") {
        this.topicDtoParams.to_import.value = "1";
      }
      else {
        this.topicDtoParams.to_import.value = "0";
      }
    },
    changeUseSmevFTP() {
      if (this.topicDtoParams.use_smev_ftp.value == "0") {
        this.topicDtoParams.use_smev_ftp.value = "1";
      }
      else {
        this.topicDtoParams.use_smev_ftp.value = "0";
      }
    },
    changeSnDocType() {
      if (this.topicDtoParams.is_any.value == "0") {
        this.topicDtoParams.is_any.value = "1";
      }
      else {
        this.topicDtoParams.is_any.value = "0";
      }
    },
    changeSnWithFiles() {
      if (this.topicDtoParams.with_files.value == "0") {
        this.topicDtoParams.with_files.value = "1";
      }
      else {
        this.topicDtoParams.with_files.value = "0";
      }
    },
    applyFilters(type, value) {
      if (type == 'type') {
        this.topicsFilters.role = value;
        if (typeof this.topicsFilters.type == 'undefined' || this.topicsFilters.type == "") {
          this.topicsFilters.type = null
        }
      }
      this.loadTopics();
    },
    prepareUrlQueryParams(forCount = false) {
      let params = {};
      if (!forCount) {
        params['limit'] = this.topicsPageLimit;
        params['offset'] = this.topicsCurrentPage;
      }
      if (this.topicsFilters.type != null) {
        params['type'] = this.topicsFilters.type;
      }
      return params;
    },
    dblClickChangeTopic(selectedTopic) {
      this.topic = Topic.create(selectedTopic)
      this.topicDto = selectedTopic
      this.updateTopic()
    }
  },
  mounted() {
    this.loadTopics();
  }
}
