import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil'
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil'
export default class CustomContextPad {
  eventBus
  constructor (contextPad, eventBus) {
    this.eventBus = eventBus
    contextPad.registerProvider(this)
  }

  getContextPadEntries (element) {
    let available = [
      'lane-divide-three',
      'lane-divide-two',
      'lane-insert-above',
      'lane-insert-below',
      'append.text-annotation',
      'connect',
      'delete'
    ]
    const eventBus = this.eventBus

    return function (entries) {
      let result = {}
      const businessObject = getBusinessObject(element)
      if (businessObject.accentType) {
        if (isAny(element, ['bpmn:ServiceTask', 'bpmn:StartEvent', 'bpmn:UserTask']) && element.type !== 'label') {
          result['accent.associate'] = {
            group: 'accent',
            className: 'bpmn-icon-service-task',
            title: 'Связать с сущностью',
            action: {
              click: (event, element) => {
                eventBus.fire('accent.associate', element)
              }
            }
          }
        }
        if (is(element, 'bpmn:StartEvent') && businessObject.accentGuid) {
          result['accent.build_approval'] = {
            group: 'accent',
            className: 'bpmn-icon-task',
            title: 'Собрать маршрут',
            action: {
              click: (event, element) => {
                eventBus.fire('accent.build_approval', element)
              }
            }
          }
        }
        if (is(element, ['bpmn:Task']) && businessObject.accentType === 'approval_stage') {
          available = [
            'append.text-annotation',
            'delete'
          ]
        }
        // console.log(businessObject)
        if (is(element, ['bpmn:BoundaryEvent']) && businessObject.attachedToRef?.accentType === 'approval_stage') {
          available = [
            'append.text-annotation',
            'connect'
          ]
        }
      }

      available.forEach((item) => {
        if (item in entries) {
          result[item] = entries[item]
        }
      })

      return result
    }
  }
}

CustomContextPad.$inject = [
  'contextPad',
  'eventBus'
]
