import { Nullable } from '@/core/domain/type/types'

export interface IGroup {
  id?: number;
  guid?: string;
  name: string;
  parent_id: Nullable<number>;
}

export default class GroupDTO {
  public id?: number;
  public guid?: string;
  public name: string;
  public parent_id: Nullable<number>;

  constructor (data: IGroup) {
    this.id = data?.id
    this.guid = data?.guid
    this.name = data.name
    this.parent_id = data.parent_id
  }

  static create (parentId: Nullable<number> = null): GroupDTO {
    return new GroupDTO({
      name: null,
      parent_id: parentId
    })
  }
}
