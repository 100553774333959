import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SourceCommandRepositoryInterface from '../../../domain/repository/SourceCommandRepositoryInterface'
import ProjectSourceDeleteCommand from '@/services/MapEditor/application/command/ProjectSourceDeleteCommand'

export default class ProjectSourceDeleteHandler implements CommandHandlerInterface
{
    private repo: SourceCommandRepositoryInterface;

    constructor(repo: SourceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectSourceDeleteCommand): Promise<any>
    {
        return this.repo.deleteFromProject(command.getId(), command.getProjectGuid());
    }
}