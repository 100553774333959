
import Vue from 'vue'
import Node from 'element-ui/packages/tree/src/model/node'
import RuleTreeElementsQuery from '@/services/TaskEditor/application/query/RuleTreeElementsQuery'
import RuleTreeElementByElementGuidQuery from '@/services/TaskEditor/application/query/RuleTreeElementByElementGuidQuery'

export default Vue.extend({
  name: 'TreeElementLayout',

  props: {
    createElement: {
      type: Function,
      default: function (elementType, parentNode) {
      }
    },

    updateElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    duplicateElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    deleteElement: {
      type: Function,
      default: function (node, data) {
      }
    },

    cancel: {
      type: Function,
      default: function cancel () {
      }
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],

  computed: {
    isLoading () {
      return this.$store.getters['RuleTreeElement/isRuleTreeElementLoading']
    }
  },

  data () {
    return {
      selectedNode: null,

      isEdit: false,

      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },

      elementTypes: ['rule', 'group']
    }
  },

  mounted () {
  },

  methods: {
    allowDrop (draggingNode, dropNode, type) {
      if (type === 'inner') {
        if (draggingNode.data.id === dropNode.data.id || dropNode.data.element_type !== 'group') {
          return false
        }

        if (this.preventParentNodeFromInheritingChildNode(dropNode).indexOf(draggingNode.data.id) !== -1) {
          return false
        }

        if (dropNode.data.element_type === 'group') {
          return draggingNode.data.parent_id !== dropNode.data.id
        }
      } else {
        if (dropNode.level === 1) {
          return true
        }
      }

      return true
    },

    allowDrag (draggingNode) {
      return true
    },

    // Супер мега функция... Запрещает родительскому узлу перемещаться в дочерний узел, на любой уровень вложенности
    preventParentNodeFromInheritingChildNode (node) {
      let nodeKeys = []
      if (node !== null && typeof node.data !== 'undefined') {
        nodeKeys.push(node.data.parent_id)
        nodeKeys.push(...this.preventParentNodeFromInheritingChildNode(node.parent))
      }
      return nodeKeys
    },

    nodeDrop (draggingNode, dropNode, dropType, ev) {
      let model = {}
      if (dropType !== 'inner') {
        model = {
          target_id: draggingNode.data.parent_id,
          row_order: this.mediumRowOrder(draggingNode, dropNode)
        }
      } else {
        model = {
          target_id: dropNode ? dropNode.data.id : null,
          row_order: draggingNode.data.row_order
        }
      }

      this.$http.post(`${this.$config.api}/taskeditor/rule_tree_elements/${draggingNode.data.id}/draggable`, model)
    },

    mediumRowOrder (draggingNode, node) {
      // children into parent
      const a = node.parent.childNodes
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          // console.log('совпадение', a[i].data.name, a[i].data.id)
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 330))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((100 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }

      return rowOrder
    },

    getTreeIcon (node) {
      if (!node.expanded) {
        return { 'el-icon-folder': node.data.element_type === 'group' }
      }

      return { 'el-icon-folder-opened': node.data.element_type === 'group' }
    },

    getLabel (node, data) {
      if (data.element_type === 'group') {
        const iconClass = !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'

        return `<span class="node-label__icon ${iconClass}"></span><span class="node-label__name">${node.label}</span>`
      } else {
        return `<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.rule_id})</span>`
      }
    },

    getTree () {
      return this.$refs.tree
    },

    cancelEditor () {
      this.isEdit = false
      this.selectedNode = null
      this.cancel()
    },

    duplicate (node, data) {
      this.duplicateElement(node, data)
    },

    cleanSelected (node) {
      this.selectedNode = node
    },

    selected (node) {
      if (this.selectedNode === node) {
        this.cancelEditor()
        return
      }

      this.cancelEditor()
      this.edit(node, node.data)
    },

    addNode (data, parentNode) {
      if (parentNode) {
        this.$refs.tree.append(data, parentNode)
      } else {
        let node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: data
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    async getNodeByElementGuid (elementGuid) {
      let treeElement = await this.getQueryBus().execute(
        new RuleTreeElementByElementGuidQuery(elementGuid)
      )

      return this.$refs.tree.getNode(treeElement.guid)
    },

    getNode (data) {
      return this.$refs.tree.getNode(data)
    },

    updateNode (node) {
      this.selectedNode = node
    },

    add (elementType = null, parentNode = null) {
      this.selectedNode = parentNode
      this.createElement(elementType, parentNode)
    },

    edit (node, data) {
      if (this.isEdit) {
        return
      }

      this.selectedNode = node
      this.isEdit = true

      this.updateElement(node, data)
    },

    remove (node: Node, data: object) {
      this.$confirm(this.$t('etl_editor.form.messages.delete'), this.$t('etl_editor.form.messages.warningTitle'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteElement(node, data)
        this.$refs.tree.remove(node)
        this.cancelEditor()
      })
    },

    async loadNode (node, resolve) {
      resolve(await this.loadElements(node.level === 0 ? null : node.data.id))
    },

    async loadElements (parentId = null) {
      let elements = []

      if (parentId) {
        elements = await this.getQueryBus().execute(new RuleTreeElementsQuery({ parent_id: parentId }))
      } else {
        elements = await this.getQueryBus().execute(new RuleTreeElementsQuery({}))
      }

      return elements
    }
  }
})
