/* eslint-disable new-cap */
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'

type CashDataEntry = { id: number, guids: string[] };
type typeSource = 'extended_object' | 'variable' | 'query'
interface ICashData {
  [source: string]: CashDataEntry[];
}

interface ISource {
  entityId: number
  type: typeSource
  queryParameters?: Object[]
  valueField: {id: number, name: string}
}
interface IProperties {
  action: unknown
  cashData: Object
  filters: Object[]
  hiddenCondition: unknown
  isHiddenFromTab: boolean
  isReadonly: unknown
  margin: string
  source: ISource
}
interface IComponents {
  [guid: string]: {
    group: string
    guid: string
    initialType: string
    isSelected: boolean
    name: string
    properties: Partial<IProperties>
    rendered: boolean
    type: Function
  }
}

export async function createCashDataLabel (components: IComponents) {
  let cashData: ICashData = {}
  for (let guid in components) {
    if (components.hasOwnProperty(guid)) {
      if (components[guid].name !== 'basic/a-label') {
        continue
      }

      const properties = components[guid].properties
      const type = properties.source?.type
      const filters = properties.filters || []
      const sourceId = properties.source?.entityId || 0
      const queryParameters = properties.source?.queryParameters || []
      if (!sourceId || !type) continue
      if (properties?.cashData) {
        properties.cashData = {}
      }
      // кеширования без фильтров и без queryParameters
      if (['extended_object', 'query'].includes(type) && filters.length === 0 && queryParameters.length === 0) {
        createCash(cashData, type, sourceId, guid)
      }
    }
  }
  // console.warn('результирующий объект', cashData)
  if (Object.keys(cashData).length > 0) {
    await insertCashDataInPropertiesComponents(components, cashData)
  }
}

// добавить в properties cashData
export async function insertCashDataInPropertiesComponents (components: IComponents, cashData: ICashData) {
  const payload = {}

  const requestTypes = {
    extended_object: APIDataSource.ExtendedObjectQuery,
    query: APIDataSource.QueryQuery
  }
  for (const [source, entries] of Object.entries(cashData)) {
    for (const entry of entries) {
      const { id, guids } = entry

      // Получение типа запроса на основе источника данных
      const requestType = requestTypes[source]

      if (!requestType || !id) continue

      const response = await APIClient.shared.request(new requestType(id, payload))

      // Обновление компонентов с полученными данными
      guids.forEach(guid => {
        const component = components[guid]
        if (component) {
          component.properties.cashData = response[0]
        }
      })
    }
  }
}

function createCash (cashData: ICashData, type: string, id: number, guid: string) {
  const existingEntry = cashData[type]

  if (!existingEntry) {
    cashData[type] = []
  }
  const existingEntryIndex = cashData[type].findIndex(entry => entry.id === id)
  if (existingEntryIndex !== -1) {
    // Если запись уже есть, добавляем новый guid к существующей записи
    cashData[type][existingEntryIndex].guids.push(guid)
  } else {
    // Иначе, создаем новую запись
    cashData[type].push({ id, guids: [guid] })
  }
}
