import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import ProjectTreeElementProjectPutCommand from '@/services/MapEditor/application/command/ProjectTreeElementProjectPutCommand'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'

export default class ProjectTreeElementProjectPutHandler implements CommandHandlerInterface
{
    private repo: ProjectTreeElementCommandRepositoryInterface;

    constructor(repo: ProjectTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectTreeElementProjectPutCommand): Promise<void>
    {
        let item: ProjectTreeElementDTO = {
            project_id: command.getProjectId(),
            parent_id: command.getParentId()
        };
        return this.repo.putProject(item);
    }
}