<template>
  <div class="main">
    <el-alert
      v-for="(error, index) in errors"
      :key="index"
      type="error"
      :title="$locale.login.errors.types[error.type]"
      :description="error.text"
      show-icon
      :closable="false">
    </el-alert>
    <template v-if="organisations.length > 0">
      <div class="title">
        {{ $locale.login.esia_title }}
      </div>
      <el-scrollbar class="esia_scrollbar" wrap-style="overflow-x:hidden;">
        <div class="organisations">
          <div class="organisation" @click="loginByState(user.state)">
            <div class="title">{{ user.shortName }}</div>
            <div class="ogrn">Физ. лицо</div>
          </div>
          <div v-for="(item, index) in organisations" :key="index" class="organisation" @click="loginAsOrganisation(item.oid)">
            <div class="title">{{ item.shortName }}</div>
            <div class="ogrn">ОГРН: {{ item.ogrn }}</div>
          </div>
        </div>
      </el-scrollbar>
    </template>
  </div>
</template>

<script>
export default {
  // props: {
  //   state: {
  //     type: String,
  //     default: null
  //   }
  // },
  name: 'EsiaPage',
  data () {
    return {
      code: this.$route.query.code,
      state: this.$route.query.state,
      ESIAError: this.$route.query.error_description,
      organisations: [],
      user: {}
    }
  },
  computed: {
    errors () {
      let errors = []
      if (!this.state) {
        errors.push({ type: 'system', text: this.$locale.login.errors.oauth_provider_id_required })
      }
      if (!this.code) {
        errors.push({ type: 'system', text: this.$locale.login.errors.oauth_code_required })
      }

      if (this.ESIAError) {
        errors.push({ type: 'esia', text: this.ESIAError })
      }

      return errors
    }
  },
  mounted () {
    if (!this.error) {
      this.login()
    }
  },
  methods: {
    loginAsOrganisation (organisationId) {
      this.$store.dispatch(`Authorization/EsiaLogin`, {
        organisationId: organisationId
      })
    },
    async loginByState (state, issetData) {
      let data = {}
      if (issetData) {
        data = issetData
      } else {
        let response = await this.$http.get(`${this.$config.api}/esiaauthentication/auth/state/${state}`)
        data = response.data
      }
      if (data.status === 'identified') {
        localStorage.setItem('ESIA_LOGGED', '1')
        await this.$store.dispatch(`Authorization/login`, data.uuid)
      }
      if (data.status === 'pdp_agreement_need') {
        await this.$http.post(`${this.$config.api}/esiaauthentication/auth/processdata`, { state: state })
        await this.loginByState(state)
        return
      }
      this.$router.push('/')
    },
    async login () {
      if (this.errors.length > 0) {
        return
      }
      try {
        let { data } = await this.$http.get(`${this.$config.api}/esiaauthentication/auth/getroles?code=${this.code}&state=${this.state}`)
        if (data.elements) {
          data.elements.forEach((item) => {
            if (item.type === 'PERSON') {
              this.user = Object.assign({ state: data.newstate }, item)
            } else {
              this.organisations.push(item)
            }
          })
        }
        if (this.organisations.length === 0) {
          await this.loginByState(data.newstate, data.status ? data : undefined)
        }
      } catch (error) {
        this.$router.push(' ')
      }
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  margin-top: 5%;
}
.main > .title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}
.organisation {
  padding: 20px;
  box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}
.organisation > .title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.esia_scrollbar {
  word-wrap: break-word;
  display: flex;
  height: 70%;
  width: 30%;
}

@media (max-width: 530px) {
  .esia_scrollbar {
    width: 80%;
  }
}
</style>
