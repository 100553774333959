import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class GroupDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  alias: Nullable<string>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, alias, parent_tree_element_id }: { id?: Nullable<number>, guid?: Nullable<string>, name: Nullable<string>, alias: Nullable<string>, parent_tree_element_id?: Nullable<number> }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.alias = alias
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): GroupDTO {
    return new GroupDTO({
      name: null,
      alias: null,
      parent_tree_element_id: null
    })
  }
}

export default class Group extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    alias?: Nullable<string>,
    parentTreeElementId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: GroupDTO): Group {
    return new Group(
      dto.name,
      dto.id,
      dto.guid,
      dto.alias,
      dto.parent_tree_element_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setAlias (parentId: Nullable<string>): void {
    this.alias = parentId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setParentTreeElementId (parentTreeElementId: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getParentTreeElementId (): number {
    return this.parentTreeElementId
  }
}
