import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import TelegramChatQueryRepositoryInterface
  from '@/services/NotificationEditor/domain/repository/TelegramChatQueryRepositoryInterface'
import TelegramChatDTO from '@/services/NotificationEditor/domain/model/TelegramChatDTO'
import { GetTelegramChat } from '@/services/NotificationEditor/infrastructure/store/modules/TelegramChat/action-types'

export default class TelegramChatQueryRepository extends AbstractQueryRepository implements TelegramChatQueryRepositoryInterface {
  get (payload: string[][]): Promise<TelegramChatDTO[]> {
    return store.dispatch(new GetTelegramChat(payload))
  }
}
