<template>
  <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="model" size="mini">
    <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
      <el-input v-model="model.name"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
      <el-input v-model="model.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.description')" prop="alias">
      <el-input v-model="model.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'UpdateGroup',

  props: {
    value: Object,

    rules: Object
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
}
</script>
