import CommandInterface from '@/core/application/command/CommandInterface'

export default class NotificationDeleteCommand implements CommandInterface {
  private id: number

  constructor (id: number) {
    this.id = id
  }

  getClassName (): string {
    return 'NotificationDeleteCommand'
  }

  getId (): number {
    return this.id
  }
}
