import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ExtractorTypeDTO {
  id?: Nullable<string>
  name?: Nullable<string>

  constructor ({ id, name }) {
    this.id = id
    this.name = name
  }
}

export class ExtractorTypePropertyDTO {
  id?: Nullable<string>
  name?: Nullable<string>
  type?: Nullable<string>
  default_value?: Nullable<string>
  primitive_type?: Nullable<string>

  constructor ({ id, name, type, default_value, primitive_type }) {
    this.id = id
    this.name = name
    this.type = type
    this.default_value = default_value
    this.primitive_type = primitive_type
  }
}

export class ExtractorDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  extractor_type_id: Nullable<string>
  alias: Nullable<string>
  properties: Nullable<Array<object>>
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, extractor_type_id, alias, properties, parent_tree_element_id }: { id?: Nullable<number>, guid?: Nullable<string>, name: Nullable<string>, extractor_type_id: Nullable<string>, alias: Nullable<string>, properties: Nullable<Array<object>>, parent_tree_element_id?: Nullable<number> }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.extractor_type_id = extractor_type_id
    this.alias = alias
    this.properties = properties
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): ExtractorDTO {
    return new ExtractorDTO({
      name: null,
      extractor_type_id: null,
      alias: null,
      properties: [],
      parent_tree_element_id: null
    })
  }
}

export default class Extractor extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private extractorTypeId: string
  private alias: Nullable<string>
  private properties: Array<object>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    extractorTypeId: string,
    properties: Array<object>,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    alias?: Nullable<string>,
    parentTreeElementId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.extractorTypeId = extractorTypeId
    this.alias = alias
    this.properties = properties
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: ExtractorDTO): Extractor {
    return new Extractor(
      dto.name,
      dto.extractor_type_id,
      dto.properties,
      dto.id,
      dto.guid,
      dto.alias,
      dto.parent_tree_element_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setExtractorTypeId (extractorTypeId: string): void {
    this.extractorTypeId = extractorTypeId
  }

  getExtractorTypeId (): string {
    return this.extractorTypeId
  }

  setAlias (parentId: Nullable<string>): void {
    this.alias = parentId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setProperties (properties: Array<object>): void {
    this.properties = properties
  }

  getProperties (): Array<object> {
    return this.properties
  }

  setParentTreeElementId (parentTreeElementId: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getParentTreeElementId (): number {
    return this.parentTreeElementId
  }
}
