import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'
import LogicUpdateCommand from '@/services/LogicEditor/application/command/LogicUpdateCommand'
import LogicCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicCommandRepositoryInterface'

export default class LogicUpdateHandler implements CommandHandlerInterface {
    private repository: LogicCommandRepositoryInterface;

    constructor (repository: LogicCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicUpdateCommand): Promise<void> {
      let item: LogicDTO = {
        guid: command.getGuid(),
        alias: command.getAlias(),
        description: command.getDescription()
      }

      return this.repository.update(item)
    }
}
