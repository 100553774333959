import Vue from 'vue'
import { getCardId } from '../../../../helpers'

export default Vue.extend({
  data () {
    return {
      cardLoading: false
    }
  },

  methods: {
    // проверить данные, если верны, то вернуть их
    async validationData (message, dataRegistryByRecord) {
      this.cardLoading = true

      const openCard = message.notification
      const readOnly = openCard.card_operation_type === 'read'
      const cardId = openCard.external_card_id
      // id записи на которую ссылается ссылка
      const recordXref = dataRegistryByRecord[`attr_${openCard.current_field_id}_id`]
      if (!recordXref) {
        console.warn(`Ссылка не обнаружена attr_${openCard.current_field_id}_id`)
        this.cardLoading = false
        return true
      }

      return {
        openCard,
        readOnly,
        cardId,
        recordXref
      }
    },

    // данные реестра по id записи
    getDataRegistryByRecord ({ registryId, recordId }) {
      return this.$http
        .get(`${this.$config.api}/registryservice/registry/${registryId}/data/${recordId}`)
        .then(response => response.data)
    },

    async onOpenOuterCard (message, dataRegistryByRecord, isCenter) {
      const { openCard, readOnly, cardId, recordXref } = await this.validationData(message, dataRegistryByRecord)
      if (!openCard) {
        return
      }

      const card = cardId
        ? { id: cardId, name: 'Новая вкладка' }
        : await getCardId(this, {
          registryId: openCard.external_object_id,
          recordId: recordXref
        })

      this.addMainTab({
        name: card.name || 'Новая вкладка',
        componentType: 'CardsWrapper',
        payload: {
          openCard: {
            registryId: openCard.external_object_id,
            recordId: recordXref,
            cardId: card?.id || null,
            initialData: {},
            readOnly
          },
          showBackBreadcrumb: false,
          showCloseCardBtn: true,
          isOpenInNewtab: true,
          showBreadcrumbEmpty: false
        }
      })
    },

    async onOpenCard (message) {
      if (!message.notification.interaction_type) {
        return
      }

      if (!message.read_date) {
        await this.readMessage(message)
      }

      const dataRegistryByRecord = await this.getDataRegistryByRecord({
        registryId: message.notification.object_id,
        recordId: message.record_id
      })

      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }

      // Открыть карточку внешнего реестра
      if (message.notification.interaction_type === 'open_external_card') {
        return this.onOpenOuterCard(message, dataRegistryByRecord)
      }

      try {
        const currentCardId = message.notification.current_card_id || null

        const card = currentCardId
          ? { id: currentCardId, name: 'Новая карточка' }
          : await getCardId(this, {
            registryId: message.notification.object_id,
            recordId: message.record_id
          })

        this.addMainTab({
          name: card.name || 'Новая вкладка',
          componentType: 'CardsWrapper',
          payload: {
            openCard: {
              registryId: message.notification.object_id,
              recordId: message.record_id,
              cardId: card.id || null,
              initialData: {}
            },
            showBackBreadcrumb: false,
            showCloseCardBtn: true,
            isOpenInNewtab: true,
            showBreadcrumbEmpty: false
          }
        })
      } catch (error) {
      } finally {
        this.visible = false
      }
    },

    getAvatarLink (avatar) {
      if (avatar) {
        return this.$config.api + '/files/' + avatar.filename
      } else {
        return 'https://placehold.co/60'
      }
    }
  }
})
