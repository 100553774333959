<template>
  <el-form size="mini" :model="value">
    <el-form-item :label="$t('report_editor.form.name')" >
      <el-input v-model="value.name"></el-input>
    </el-form-item>

    <el-form-item :label="$t('report_editor.form.description')" >
      <el-input v-model="value.description" type="textarea" :row="2"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'GroupForm',

  props: ['value', 'activeNode'],

  data () {
    return {
      localValue: this.value,
      localNode: this.activeNode
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  methods: {
    async save () {
      this.localValue = await this.localValue.save()

      this.activeNode.data.name = this.localValue.name
    }
  }
}
</script>
