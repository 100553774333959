import { render, staticRenderFns } from "./BpmnEventForm.vue?vue&type=template&id=bd54b180&scoped=true&"
import script from "./BpmnEventForm.vue?vue&type=script&lang=ts&"
export * from "./BpmnEventForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd54b180",
  null
  
)

export default component.exports