<template>
  <el-form-item :label="label">
    <el-select v-model="localModel"
               clearable
               @clear="$emit('input', null)"
               @change="$emit('input', localModel)">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.label"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>

  export default {
    name: 'address_type',
    props: ['value', 'entity-id', 'entity-type-id', 'properties', 'label'],
    data () {
      return {
        localModel: this.value,
        options: [
          { id: 'administrative', label: 'Административное' },
          { id: 'municipal', label: 'Муниципальное' },
        ]
      }
    }
  }
</script>

<style scoped>

</style>
