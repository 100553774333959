
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import RequestPanel from '@/services/ApiService/infrastructure/components/RequestPanel/index.vue'
import RequestsHandler from './application/handler/query/RequestsHandler'
import RequestRepository from './infrastructure/domain/repository/RequestRepository'
import CreateRequestHandler from '@/services/ApiService/application/handler/command/CreateRequestHandler'
import DeleteRequestHandler from './application/handler/command/DeleteRequestHandler'
import ViewPanel from '@/services/ApiService/infrastructure/components/ViewPanel/index.vue'
import RequestSchemeEntitiesHandler from './application/handler/query/RequestSchemeEntitiesHandler'
import UpdateRequestEntityHandler from './application/handler/command/UpdateRequestEntityHandler'
import RequestParametersHandler from './application/handler/query/RequestParametersHandler'
import CreateRequestParameterHandler from './application/handler/command/CreateRequestParameterHandler'
import DeleteRequestParameterHandler from './application/handler/command/DeleteRequestParameterHandler'
import UpdateRequestParameterHandler from './application/handler/command/UpdateRequestParameterHandler'
import SchemaPanel from '@/services/ApiService/infrastructure/components/SchemaPanel/index.vue'
import SchemasHandler from './application/handler/query/SchemasHandler'
import SchemaRepository from './infrastructure/domain/repository/SchemaRepository'
import CreateSchemaHandler from './application/handler/command/CreateSchemaHandler'
import DeleteSchemaHandler from './application/handler/command/DeleteSchemaHandler'
import UpdateSchemaHandler from './application/handler/command/UpdateSchemaHandler'
import ExportStructureHandler from './application/handler/query/ExportStructureHandler'
import ImportStructureHandler from './application/handler/command/ImportStructureCommand'

export default {
  name: 'ApiService',
  components: {
    RequestPanel,
    ViewPanel,
    SchemaPanel
  },
  data () {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'CreateRequestCommand': new CreateRequestHandler(
            new RequestRepository()
          ),
          'DeleteRequestCommand': new DeleteRequestHandler(
            new RequestRepository()
          ),
          'UpdateRequestEntityCommand': new UpdateRequestEntityHandler(
            new RequestRepository()
          ),
          'CreateRequestParameterCommand': new CreateRequestParameterHandler(
            new RequestRepository()
          ),
          'DeleteRequestParameterCommand': new DeleteRequestParameterHandler(
            new RequestRepository()
          ),
          'UpdateRequestParameterCommand': new UpdateRequestParameterHandler(
            new RequestRepository()
          ),
          'CreateSchemaCommand': new CreateSchemaHandler(
            new SchemaRepository()
          ),
          'DeleteSchemaCommand': new DeleteSchemaHandler(
            new SchemaRepository()
          ),
          'UpdateSchemaCommand': new UpdateSchemaHandler(
            new SchemaRepository()
          ),
          'ImportStructureCommand': new ImportStructureHandler(
            new RequestRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'RequestsQuery': new RequestsHandler(
            new RequestRepository()
          ),
          'RequestSchemeEntitiesQuery': new RequestSchemeEntitiesHandler(
            new RequestRepository()
          ),
          'RequestParametersQuery': new RequestParametersHandler(
            new RequestRepository()
          ),
          'SchemasQuery': new SchemasHandler(
            new SchemaRepository()
          ),
          'ExportStructureQuery': new ExportStructureHandler(
            new RequestRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    }
  },
  mounted () {
  }
}
