import FormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/FormulaCommandRepositoryInterface'
import store from '@/store'
import { CreateFormula, UpdateFormula, DeleteFormula } from '@/services/LogicEditor/infrastructure/store/modules/Formula/action-types'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'

export default class FormulaCommandRepository implements FormulaCommandRepositoryInterface {
  insert (item: FormulaDTO): Promise<void> {
    return store.dispatch(new CreateFormula(item))
  }

  update (item: FormulaDTO): Promise<void> {
    return store.dispatch(new UpdateFormula(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteFormula(guid))
  }
}
