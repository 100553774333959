import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TaskDTO } from '@/services/EtlEditor/domain/model/Task'
import TaskCreateCommand from '@/services/EtlEditor/application/command/TaskCreateCommand'
import TaskCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskCommandRepositoryInterface'

export default class TaskCreateHandler implements CommandHandlerInterface {
    private repository: TaskCommandRepositoryInterface;

    constructor (repository: TaskCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TaskCreateCommand): Promise<void> {
      let item: TaskDTO = {
        name: command.getName(),
        cron_time: command.getCronTime(),
        is_cron_task: command.getIsCronTask(),
        is_visible_in_registry: command.getIsVisibleInRegistry(),
        code: command.getCode(),
        extractor_id: command.getExtractorId(),
        loader_id: command.getLoaderId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
