<template>
  <el-dialog
    :title="$locale.login.two_factor_auth"
    :visible.sync="opened"
    width="30%">
    <template v-if="smsAuthData.phone_is_set">
      <div style="text-align: center;margin-bottom: 20px;word-break: break-word;">
        {{ $locale.login.sms_code_sent }}
        <div style="font-weight: bold; font-size: 20px;margin-top: 10px">{{ smsAuthData.phone }}</div>
      </div>
      <el-input v-model="smsCode"></el-input>
    </template>
    <el-alert style="margin-top: 10px" v-show="error" :title="error" type="error" :closable="false" show-icon></el-alert>
    <div slot="footer" style="text-align: center">
      <el-button v-loading="loading" type="primary" @click="checkSMSCode">{{ $locale.main.button.confirm }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SMSAuthentication',
  props: {
    smsAuthData: {
      type: Object
    }
  },
  watch: {
    opened (value) {
      if (!value) {
        this.$emit('close')
      }
    }
  },
  data () {
    return {
      error: null,
      loading: false,
      smsCode: null,
      opened: true
    }
  },
  methods: {
    checkSMSCode () {
      this.error = null
      this.loading = true
      this.$http.post(`${this.$config.api}/defaultauthentication/auth/sms_check`, {
        guid: this.smsAuthData.guid,
        code: this.smsCode
      }).then((response) => {
        this.$store.dispatch(`Authorization/login`, response.data.uuid)
          .then(() => {
            this.$router.push('/')
          })
          .catch(() => {
            this.error = 'Ошибка авторизации, пожалуйста, обновите страницу и попробуйте снова'
          })
          .finally(() => {
            this.loading = false
          })
      }).catch((error) => {
        this.loading = false
        if (error.response.status === 404) {
          this.error = 'Неверный код'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
