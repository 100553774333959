import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'
import StateUpdateCommand from '@/services/LogicEditor/application/command/StateUpdateCommand'
import StateCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/StateCommandRepositoryInterface'

export default class StateUpdateHandler implements CommandHandlerInterface {
    private repository: StateCommandRepositoryInterface;

    constructor (repository: StateCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: StateUpdateCommand): Promise<void> {
      let item: StateDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        is_complex: command.getIsComplex(),
        properties: command.getProperties(),
        state_fields: command.getStateFields()
      }

      return this.repository.update(item)
    }
}
