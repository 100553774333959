import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class TaskObjectDTO {
  task_id: Nullable<number>
  task_name: Nullable<string>
  task_code: Nullable<string>
  is_visible_in_registry: Nullable<boolean>
  extractor_type_id: Nullable<string>
  extractor_object_id: Nullable<number>
  loader_type_id: Nullable<string>
  loader_object_id: Nullable<number>
  extractor_properties: Nullable<Array<object>>
  loader_properties: Nullable<Array<object>>
}

export class TaskDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  cron_time: Nullable<object>
  is_cron_task: Nullable<boolean>
  code: Nullable<string>
  extractor_id: Nullable<number>
  loader_id: Nullable<number>
  is_visible_in_registry: Nullable<boolean>
  parent_tree_element_id?: Nullable<number>

  constructor (
    { id, guid, name, cron_time, is_cron_task, code, extractor_id, loader_id, is_visible_in_registry, parent_tree_element_id }
    :
      {
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name: Nullable<string>,
        cron_time: Nullable<object>,
        is_cron_task: Nullable<boolean>,
        code: Nullable<string>,
        extractor_id: Nullable<number>,
        loader_id: Nullable<number>,
        is_visible_in_registry: Nullable<boolean>,
        parent_tree_element_id?: Nullable<number>
      }
  ) {
    this.id = id
    this.guid = guid
    this.name = name
    this.cron_time = cron_time
    this.is_cron_task = is_cron_task
    this.code = code
    this.extractor_id = extractor_id
    this.loader_id = loader_id
    this.is_visible_in_registry = is_visible_in_registry
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): TaskDTO {
    return new TaskDTO({
      name: null,
      cron_time: null,
      is_cron_task: false,
      code: null,
      extractor_id: null,
      loader_id: null,
      is_visible_in_registry: false,
      parent_tree_element_id: null
    })
  }
}

export default class Task extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private cronTime: Nullable<object>
  private isCronTask: boolean
  private code: Nullable<string>
  private extractorId: number
  private loaderId: number
  private isVisibleInRegistry: boolean
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    isCronTask: boolean,
    extractorId: number,
    loaderId: number,
    isVisibleInRegistry: boolean,
    id?: Nullable<number>,
    guid?: Nullable<string>,
    cronTime?: Nullable<object>,
    code?: Nullable<string>,
    parentTreeElementId?: Nullable<number>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.isCronTask = isCronTask
    this.extractorId = extractorId
    this.loaderId = loaderId
    this.isVisibleInRegistry = isVisibleInRegistry
    this.cronTime = cronTime
    this.code = code
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: TaskDTO): Task {
    return new Task(
      dto.name,
      dto.is_cron_task,
      dto.extractor_id,
      dto.loader_id,
      dto.is_visible_in_registry,
      dto.id,
      dto.guid,
      dto.cron_time,
      dto.code,
      dto.parent_tree_element_id
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setCronTime (cronTime: Nullable<object>): void {
    this.cronTime = cronTime
  }

  getCronTime (): Nullable<object> {
    return this.cronTime
  }

  setIsCronTask (isCronTask: boolean): void {
    this.isCronTask = isCronTask
  }

  getIsCronTask (): boolean {
    return this.isCronTask
  }

  setCode (code: Nullable<string>): void {
    this.code = code
  }

  getCode (): Nullable<string> {
    return this.code
  }

  setExtractorId (extractorId: number): void {
    this.extractorId = extractorId
  }

  getExtractorId (): number {
    return this.extractorId
  }

  setLoaderId (loaderId: number): void {
    this.loaderId = loaderId
  }

  getLoaderId (): number {
    return this.loaderId
  }

  setIsVisibleInRegistry (isVisibleInRegistry: boolean): void {
    this.isVisibleInRegistry = isVisibleInRegistry
  }

  getIsVisibleInRegistry (): boolean {
    return this.isVisibleInRegistry
  }

  setParentTreeElementId (parentTreeElementId: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
