import ConstraintCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintCommandRepositoryInterface'
import store from '@/store'
import { CreateConstraint, UpdateConstraint, DeleteConstraint } from '@/services/LogicEditor/infrastructure/store/modules/Constraint/action-types'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'

export default class ConstraintCommandRepository implements ConstraintCommandRepositoryInterface {
  insert (item: ConstraintDTO): Promise<void> {
    return store.dispatch(new CreateConstraint(item))
  }

  update (item: ConstraintDTO): Promise<void> {
    return store.dispatch(new UpdateConstraint(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteConstraint(guid))
  }
}
