import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RowFormulaDeleteCommand from '@/services/LogicEditor/application/command/RowFormulaDeleteCommand'
import RowFormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RowFormulaCommandRepositoryInterface'

export default class RowFormulaDeleteHandler implements CommandHandlerInterface {
    private repository: RowFormulaCommandRepositoryInterface;

    constructor (repository: RowFormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RowFormulaDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
