import MappingCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/MappingCommandRepositoryInterface'
import store from '@/store'
import { CreateMapping, UpdateMapping, DeleteMapping } from '@/services/EtlEditor/infrastructure/store/modules/Mapping/action-types'
import { MappingDTO } from '@/services/EtlEditor/domain/model/Mapping'

export default class MappingCommandRepository implements MappingCommandRepositoryInterface {
  insert (item: MappingDTO): Promise<void> {
    return store.dispatch(new CreateMapping(item))
  }

  update (item: MappingDTO): Promise<void> {
    return store.dispatch(new UpdateMapping(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteMapping(guid))
  }
}
