import ExtractorQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ExtractorTypePropertiesQuery from '@/services/EtlEditor/application/query/ExtractorTypePropertiesQuery'

export default class ExtractorTypePropertiesHandler implements QueryHandlerInterface {
  private repository: ExtractorQueryRepositoryInterface

  constructor (repository: ExtractorQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: ExtractorTypePropertiesQuery): Promise<any> {
    return this.repository.getTypeProperties(query.getType())
  }
}
