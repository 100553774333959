import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ConnectionCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/ConnectionCommandRepositoryInterface'
import ConnectionUpdateCommand from '@/services/AccessEditor/application/command/ConnectionUpdateCommand'
import { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'

export default class ConnectionUpdateHandler implements CommandHandlerInterface {
    private repo: ConnectionCommandRepositoryInterface;

    constructor (repo: ConnectionCommandRepositoryInterface) {
      this.repo = repo
    }

    execute (command: ConnectionUpdateCommand): Promise<void> {
      let item: ConnectionDTO = {
        id: command.getId(),
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        connection_type_id: command.getType(),
        properties: command.getProperties()
      }
      return this.repo.update(item)
    }
}
