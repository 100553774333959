import CommandInterface from '@/core/application/command/CommandInterface'
import Rule from '@/services/TaskEditor/domain/model/Rule'

export default class RuleCreateCommand implements CommandInterface {
  private model: Rule;

  constructor (model: Rule) {
    this.model = model
  }

  getClassName (): string {
    return 'RuleCreateCommand'
  }

  getModel (): Rule {
    return this.model
  }
}
