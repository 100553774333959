import CommandInterface from '@/core/application/command/CommandInterface'
import RuleGroup from '@/services/TaskEditor/domain/model/RuleGroup'

export default class RuleGroupCreateCommand implements CommandInterface {
  private model: RuleGroup;

  constructor (model: RuleGroup) {
    this.model = model
  }

  getClassName (): string {
    return 'RuleGroupCreateCommand'
  }

  getModel (): RuleGroup {
    return this.model
  }
}
