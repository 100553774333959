import EventRepositoryInterface from '@/services/ActivityService/domain/repository/EventRepositoryInterface'
import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'

export default class EventsExportHandler implements QueryHandlerInterface {
  private repo: EventRepositoryInterface

  constructor (repository: EventRepositoryInterface) {
    this.repo = repository
  }

  execute (query: AbstractEntitiesQuery): Promise<any> {
    return this.repo.eventsExport(query.getPayload())
  }
}
