import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ExtractorQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorQueryRepositoryInterface'
import ExtractorTypesQuery from '@/services/EtlEditor/application/query/ExtractorTypesQuery'

export default class ExtractorTypesHandler implements QueryHandlerInterface {
  private repository: ExtractorQueryRepositoryInterface;

  constructor (repository: ExtractorQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: ExtractorTypesQuery): Promise<any> {
    return this.repository.getTypes()
  }
}
