import QueryInterface from '@/core/application/query/QueryInterface'

export default class TaskObjectByTaskIdQuery implements QueryInterface {
  private taskId: number;

  constructor (taskId: number) {
    this.taskId = taskId
  }

  getClassName (): string {
    return 'TaskObjectByTaskIdQuery'
  }

  getTaskId (): number {
    return this.taskId
  }
}
