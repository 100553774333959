<template>
  <card class="element_editor_layout" :rounded="false" :bordered="false" type="grey">
    <template v-slot:header.title>
      {{ title }}
    </template>

    <el-scrollbar>
      <el-main>
        <component
          ref="form"
          v-bind:value="value"
          v-on:input="$emit('input', $event)"
          :is="form">
        </component>
      </el-main>
    </el-scrollbar>

    <template v-slot:footer>
      <el-button type="danger" icon="el-icon-close" size="small" @click="closeEditor()">
        {{ $locale.main.button.close }}
      </el-button>

      <el-button type="primary" icon="el-icon-success" size="small" @click="submit()">
        {{ $locale.main.button.save }}
      </el-button>
    </template>
  </card>
</template>

<script type="ts">
import ExtractorForm from './forms/ExtractorForm.vue'
import GroupForm from './forms/GroupForm.vue'
import LoaderForm from './forms/LoaderForm.vue'
import TaskForm from './forms/TaskForm.vue'
import TemplateForm from './forms/TemplateForm.vue'
import TransformerForm from './forms/TransformerForm.vue'

import TreeElementByElementGuidQuery from '@/services/EtlEditor/application/query/TreeElementByElementGuidQuery'

export default {
  name: 'EditorElementLayout',

  components: {
    ExtractorForm,
    GroupForm,
    LoaderForm,
    TaskForm,
    TemplateForm,
    TransformerForm
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    elementType: {
      type: String,
      required: true
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeElementLayout', 'getEditorNode'],

  computed: {
    isNew () {
      return typeof this.value.guid === 'undefined' || this.value.guid === null
    },

    extractorCreated () {
      return this.$store.getters['Extractor/getExtractorLocation']
    },

    groupCreated () {
      return this.$store.getters['Group/getGroupLocation']
    },

    loaderCreated () {
      return this.$store.getters['Loader/getLoaderLocation']
    },

    taskCreated () {
      return this.$store.getters['Task/getTaskLocation']
    },

    templateCreated () {
      return this.$store.getters['Template/getTemplateLocation']
    },

    transformerCreated () {
      return this.$store.getters['Transformer/getTransformerLocation']
    }
  },

  watch: {
    extractorCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/extractors/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    },

    groupCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/groups/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    },

    loaderCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/loaders/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    },

    taskCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/tasks/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    },

    templateCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/templates/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    },

    transformerCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new TreeElementByElementGuidQuery(
            location.replace('/transformers/', '')
          )
        ).then(response => {
          if (response.guid) {
            this.addElementToTree(response)
          }
        })
      }
    }
  },

  methods: {
    closeEditor () {
      this.getTreeElementLayout().closeEditor()
    },

    async addElementToTree (response) {
      this.getTreeElementLayout().addNode(
        response, this.getEditorNode()
      )
    },

    submit () {
      this.$refs.form.submit(async () => {
        if (this.isNew === false) {
          let node = await this.getTreeElementLayout().getNodeByElementGuid(this.value.guid)
          if (node) {
            node.data.name = this.value.name
            this.getTreeElementLayout().updateNode(node)
          }
        }
      })
    }
  }
}
</script>

<style scoped></style>
