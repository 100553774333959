
import Vue from 'vue'
import TableMapping from '../../render/mapping/TableMapping.vue'
import TreeMapping from '../../render/mapping/TreeMapping.vue'
import ExtraTask from '@/components/EtlEditor/render/ExtraTask.vue'
import DynamicSelect from '@/components/EtlEditor/render/DynamicSelect.vue'
import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'TaskUpdate',

  props: {
    value: {
      type: Object,
      default: null
    },

    hideMapping: {
      type: Boolean,
      default: false
    },

    hideExtraTasks: {
      type: Boolean,
      default: false
    }
  },

  components: {
    TableMapping,
    TreeMapping,
    ExtraTask,
    DynamicSelect,
    draggable
  },

  computed: {
    /**
     * Определение типа экстрактора, от которого зависит компонент мэппинга
     */
    extractorType (): string|null {
      const extractor = this.extractorById(this.localValue.extractor_id)

      return extractor ? extractor.extractor_type_id : null
    },

    /**
     * Условие показа/скрытия мэппинга
     */
    showMapping (): boolean {
      return this.mappingComponent !== null && this.mappingKey !== null && this.hideMapping !== true
    },

    /**
     * Условие показа/скрытия формы дополнительных задач
     */
    showExtraTasks () {
      console.log('showExtraTasks', this.hideExtraTasks, this.localValue.is_extra_task, this.localValue.has_extra_tasks, !this.hideExtraTasks && !this.localValue.is_extra_task && this.localValue.has_extra_tasks)

      return !this.hideExtraTasks && !this.localValue.is_extra_task && this.localValue.has_extra_tasks
    },

    /**
     * Список задач, которые ещё не были прикреплены к основной задаче
     */
    extraTasks () {
      const selected = this.localValue.extra_tasks.map((extraTask) => {
        return extraTask.extra_task_id
      })

      return this.tasks.filter((task) => {
        return !selected.includes(task.id) && task.has_extra_tasks !== true
      })
    },

    /**
     * Заблокировать свойство для дполнительных задач, или если скрыта эта фукция в текущей форме
     */
    disableHasExtraTasks () {
      return this.localValue.is_extra_task || this.hideExtraTasks
    }
  },

  data () {
    return {
      localValue: this.value,

      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        loader_id: [
          { required: true, message: 'Please select Loader Type', trigger: 'change' }
        ],
        extractor_id: [
          { required: true, message: 'Please select Extractor Type', trigger: 'change' }
        ]
      },

      list: {
        loader: [],
        extractor: [],
        transformer: []
      },

      mappingComponent: null,

      taskSelected: null,

      tasks: [],

      mappingKey: null
    }
  },

  watch: {
    localValue: {
      handler: function () {
        this.$emit('input', this.localValue)
      },
      deep: true
    },

    'localValue.code': function (val) {
      if (val === '') {
        this.localValue.code = null
      }
    },

    value: {
      handler: function () {
        this.localValue = this.value
      },
      deep: true
    },

    mappingComponent () {
      console.log('mappingComponent', this.mappingComponent)
    }
  },

  mounted () {
    this.loadTasks()

    this.$http
      .get(`${this.$config.api}/etleditor/extractors?fields=id,name,extractor_type_id&order=name:asc`)
      .then((response) => {
        this.list.extractor = response.data

        this.setMappingComponent(this.localValue.extractor_id)
      })

    this.$http
      .get(`${this.$config.api}/etleditor/loaders?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.loader = response.data
      })

    this.$http
      .get(`${this.$config.api}/etleditor/transformers?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.transformer = response.data
      })
  },

  methods: {
    loadTasks () {
      if (this.showExtraTasks) {
        const currentTasks = this.localValue.extra_tasks || []

        const params = {
          spec: 'not_in',
          id: [
            ...currentTasks.map((task) => {
              return task.extra_task_id
            }),

            this.value.id
          ],
          has_extra_tasks: false
        }

        this.$http
          .get(`${this.$config.api}/etleditor/tasks`, {
            params
          })
          .then((response) => {
            this.tasks = response.data
          })
      }
    },

    onHasExtraTask () {
      this.loadTasks()
    },

    onDraggable () {
      this.localValue.extra_tasks.forEach((extraTask, index) => {
        extraTask.row_order = (index + 1) * 1000
      })
    },

    removeExtraTask (index) {
      this.localValue.extra_tasks.splice(index, 1)
    },

    attachExtraTask () {
      if (!this.taskSelected) {
        return
      }

      const task = this.extraTasks.find(t => t.id === this.taskSelected)
      if (!task) {
        return
      }

      this.localValue.extra_tasks.push({
        task_id: this.localValue.id,
        extra_task_id: task.id,
        row_order: (this.localValue.extra_tasks.length + 1) * 1000,
        extra_task: task
      })

      this.taskSelected = null
    },

    submit (save: Function) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(false)

          this.$refs.mapping.saveAllEditMapping()
        }
      })
    },

    extractorById (value) {
      let extractor = this.list.extractor.find(e => e.id === value)

      return extractor || null
    },

    codeChange (value) {
      if (value === '') {
        this.localValue.code = null
      }
    },

    selectExtractor (value) {
      this.setMappingComponent(value)
    },

    setMappingComponent (value) {
      let extractor = this.extractorById(value)

      if (extractor) {
        this.mappingComponent = ['xml', 'json_tree', 'geometry_archive'].indexOf(extractor.extractor_type_id) !== -1 ? TreeMapping : TableMapping
        this.mappingKey = this.generateGuid()
      } else {
        this.mappingComponent = null
      }
    }
  }
})
