
import mixins from 'vue-typed-mixins'
import FormulaByGuidQuery from '@/services/LogicEditor/application/query/FormulaByGuidQuery'
import Formula from '@/services/LogicEditor/domain/model/Formula'
import FormulaCreateCommand from '@/services/LogicEditor/application/command/FormulaCreateCommand'
import FormulaUpdateCommand from '@/services/LogicEditor/application/command/FormulaUpdateCommand'

import FormulaCalculator from '@/core/infrastructure/components/FormulaCalculator/index.vue'
import FormulaStatement from '@/core/infrastructure/components/FormulaStatement.vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'FormulaForm',

  components: {
    FormulaCalculator,
    FormulaStatement,
    RegistrySelectTree
  },

  props: {
    disabledEntityId: {
      type: Boolean,
      default: false
    }
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Formula/isFormulaLoading']
    },

    formulaCreated () {
      return this.$store.getters['Formula/getFormulaLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        entity_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.field'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    formulaCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new FormulaByGuidQuery(
            location.replace('/formulas/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    },

    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let formula = Formula.create(this.value)

      if (this.isNew) {
        return new FormulaCreateCommand(
          formula.getName(),
          formula.getDescription(),
          formula.getFormulaTypeId(),
          formula.getIsDsl(),
          formula.getIsStored(),
          formula.getIsBasic(),
          formula.getRoleId(),
          formula.getEntityId(),
          formula.getSqlStatements(),
          formula.getDslStatement(),
          formula.getLogicId(),
          formula.getAlias(),
          formula.getParentTreeElementId()
        )
      }

      return new FormulaUpdateCommand(
        formula.getGuid(),
        formula.getName(),
        formula.getDescription(),
        formula.getFormulaTypeId(),
        formula.getIsDsl(),
        formula.getIsStored(),
        formula.getIsBasic(),
        formula.getRoleId(),
        formula.getEntityId(),
        formula.getSqlStatements(),
        formula.getDslStatement(),
        formula.getAlias()
      )
    }
  }
})
