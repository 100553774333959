<template>
  <div class="widget_panel">
    <el-col :span="24" class="widget_list">
      <el-input
        size="mini"
        class="search"
        prefix-icon="el-icon-search"
        clearable
        v-model="searchText"
        ref="search"
        placeholder="Найти компонент"></el-input>
      <div class="basic_components" v-show="active === 'basic'">
        <el-scrollbar :style="{height:'100%'}" wrap-style="overflow-x:hidden;" ref="scroll">
          <div v-for="(group, index) in filteredBasicWidgets" :key="index">
            <div class="group_name">{{ group.name }}</div>
            <div class="group_components">
              <div v-for="(component, index) in group.components"
                   :key="index"
                   class="component_container"
                   @click="addWidget(component, $event)"
              >
                <div class="component_icon" :style="`background: no-repeat url(/icon/components/${component.component.split('/')[0]}.svg) center / contain`"></div>
                <div class="component_name">{{component.name}}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-col>
  </div>
</template>

<script>
import Widgets from '../../../domain/model/Widgets.json'
export default {
  name: 'WidgetList',
  inject: ['getEventBus'],
  props: {
    type: String,
    isShown: Boolean,
    registryId: Number,
    activeBlock: Object
  },
  data () {
    return {
      active: 'basic',
      basicWidgets: Widgets,
      searchText: null
    }
  },
  computed: {
    filteredBasicWidgets () {
      let answer = []
      Object.keys(this.basicWidgets.groups).forEach((group) => {
        let object = {
          name: this.basicWidgets.groups[group],
          components: this.basicWidgets.components.filter(cmp => cmp.group === group)
            .filter(cmp => (this.searchText || '').trim().length === 0 ||
              cmp.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
        }
        if (object.components.length > 0) {
          answer.push(object)
        }
      })

      return answer
    }
  },
  methods: {
    addWidget (widget, event) {
      this.getEventBus().$emit('add', {
        properties: Object.assign({}, widget.properties, { name: widget.name }),
        bpmn_properties: widget.bpmn_properties,
        component: widget.component,
        event: event
      })
      this.getEventBus().$emit('closePopover')
    }
  },
  watch: {
    active () {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    isShown (value) {
      if (value && this.$refs.search) {
        this.$refs.search.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../main.scss';

.widget_panel {
  width: 800px;
  padding: 5px;
  & .widget_list {
    padding-left: 10px;
  }
}
.widget_panel.template {
  transition: width 0.5s;
  width: 1200px;
}
.basic_components {
  height: 300px;
}
.widget_list {
  & .search {
    width: 100%;
    margin-bottom: 10px;
  }
  & .group_name {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
  }
  & .group_components {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 20px 0;
    & .component_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 85px;
      padding: 3px;
      &:hover {
        background: $hover_block_color;
        cursor: pointer;
      }
    }
    & .component_icon {
      width: 65px;
      height: 65px;
    }
    & .component_name {
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
  }
}

.widget_type {
  font-size: 14px;
  padding: 10px 20px;

  &:hover {
    background: $hover_block_color;
    cursor: pointer;
    color: $active_text_color;
  }
  &.active {
    background: $active_block_color;
    color: $active_text_color;
  }
}
</style>
