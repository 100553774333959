import RegionDTO from '@/services/GarAddressService/domain/model/Region'
import RegionCommandRepositoryInterface
  from '@/services/GarAddressService/domain/repository/RegionCommandRepositoryInterface'
import {APIClient} from "@/core/infrastructure/api/APIClient";
import {RegionAPI} from "@/services/GarAddressService/infrastructure/api/RegionAPI";

export default class RegionCommandRepository implements RegionCommandRepositoryInterface {
  update (item: RegionDTO): Promise<void> {
    return APIClient.shared.request(new RegionAPI.UpdateRegion(item))
  }
}
