
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RoleProfileRightDTO {
    role_guid?: Nullable<string>;
    user_field_id?: Nullable<string>;
    is_readable?: Nullable<boolean>;
    is_editable?: Nullable<boolean>;

    constructor ({ role_guid, user_field_id, is_readable, is_editable }: {role_guid?: Nullable<string>, user_field_id?: Nullable<string>, is_readable?: Nullable<boolean>, is_editable?: Nullable<boolean>}) {
      this.role_guid = role_guid
      this.user_field_id = user_field_id
      this.is_readable = is_readable
      this.is_editable = is_editable
    }
}

export default class RoleProfileRight extends AbstractBaseEntity {
    private roleGuid: Nullable<string>;
    private userFieldId: Nullable<string>;
    private isReadable: Nullable<boolean>;
    private isEditable: Nullable<boolean>;

    constructor (
      roleGuid: Nullable<string>,
      userFieldId: Nullable<string>,
      isReadable: Nullable<boolean> = false,
      isEditable: Nullable<boolean> = false
    ) {
      super()
      this.roleGuid = roleGuid
      this.userFieldId = userFieldId
      this.isReadable = isReadable
      this.isEditable = isEditable
    }

    static create (dto: RoleProfileRightDTO): RoleProfileRight {
      return new RoleProfileRight(
        dto.role_guid,
        dto.user_field_id,
        dto.is_readable,
        dto.is_editable
      )
    }

    setRoleGuid (roleGuid: string): void {
      this.roleGuid = roleGuid
    }

    getRoleGuid (): string {
      return this.roleGuid
    }

    setUserFieldId (userFieldId : string): void {
      this.userFieldId = userFieldId
    }

    getUserFieldId (): string {
      return this.userFieldId
    }

    setIsReadable (isReadable: boolean): void {
      this.isReadable = isReadable
    }

    getIsReadable (): boolean {
      return this.isReadable
    }

    setIsEditable (isEditable: boolean): void {
      this.isEditable = isEditable
    }

    getIsEditable (): boolean {
      return this.isEditable
    }
}
