
import { PropType } from 'vue'

export default {
  name: 'event',
  props: {
    event: Object as PropType<Event>
  },
  filters: {
    empty (value) {
      if (value === null || value === '') {
        return 'Пусто'
      }
      return value
    }
  }
}
