import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetExtractors, GetExtractorByGuid, GetExtractorTypes, GetExtractorTypeProperties } from '@/services/EtlEditor/infrastructure/store/modules/Extractor/action-types'
import ExtractorQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorQueryRepositoryInterface'

export default class ExtractorQueryRepository extends AbstractQueryRepository implements ExtractorQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetExtractors(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetExtractorByGuid(guid))
  }

  getTypes (): Promise<any> {
    return store.dispatch(new GetExtractorTypes())
  }

  getTypeProperties (type: string): Promise<any> {
    return store.dispatch(new GetExtractorTypeProperties(type))
  }
}
