import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ApprovalStageCreateCommand from '@/services/LogicEditor/application/command/ApprovalStageCreateCommand'
import ApprovalStageCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalStageCommandRepositoryInterface'

export default class ApprovalStageCreateHandler implements CommandHandlerInterface {
    private repository: ApprovalStageCommandRepositoryInterface;

    constructor (repository: ApprovalStageCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ApprovalStageCreateCommand): Promise<void> {
      return this.repository.insert(command.getDTO())
    }
}
