<template>
  <div class="xml_editor">
    <div class="tool-box">
      <div class="tool-box__item">
        <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.button.save')" placement="top">
          <el-button
            size="small"
            @click="save"
            type="success"
            icon="el-icon-success"
          >
            {{ $t('main.button.save') }}
          </el-button>
        </el-tooltip>
      </div>

      <div class="tool-box__item">
        <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('xml_editor.tooltips.tab_size')" placement="top">
          <el-select
            v-model="options.tabSize"
            placeholder="Tab Size"
            size="small"
          >
            <el-option
              v-for="item in [{ value: 2, label: 'Tab Size 2' }, { value: 4, label: 'Tab Size 4' }]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-tooltip>
      </div>

      <div class="tool-box__item">
        <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('xml_editor.tooltips.code_color_scheme')" placement="top">
          <el-select
            v-model="options.theme"
            placeholder="Code theme"
            size="small"
          >
            <el-option
              v-for="item in codemirrorThemes"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-tooltip>
      </div>

      <div class="tool-box__item">
        <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('xml_editor.tooltips.open_documentation_site')" placement="top">
          <el-button type="default" size="small" @click="twigDoc">Twig Doc</el-button>
        </el-tooltip>
      </div>
    </div>

    <codemirror class="code_box" v-model="code" :options="options"></codemirror>

    <el-button
      class="cd-button"
      type="primary"
      :style="{ right: !collapsed ? '0px' : '400px' }"
      :icon="!collapsed ? 'el-icon-caret-left' : 'el-icon-caret-right'"
      @click="collapsed = !collapsed">
    </el-button>

    <div class="cd-panel" v-bind:class="{ 'is-visible': collapsed }">
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <div class="filter-input">
            <el-input
              placeholder="Искать..."
              v-model="filterText"
              size="mini"
            ></el-input>
          </div>

          <el-scrollbar class="tree_scroll">
            <el-tree
              ref="tree"
              :data="fields"
              :props="{ children: 'children', label: 'name' }"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              default-expand-all
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-popover
                  placement="bottom"
                  title="Информация"
                  width="500"
                  trigger="click"
                >
                  <div class="info-var">
                    <p><b>Переменная:</b> <span class="copy-var" v-clipboard:copy="data.id" @click.stop="() => {}">{{ data.id }}</span></p>
                    <p><b>Тип:</b> {{ getTypeName(data.type) }}</p>
                    <p><b>Подтип:</b> {{ getTypeName(data.subType) }}</p>
                    <p><b>Описание:</b> {{ data.description || '(пусто)' }}</p>

                    <span>
                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="getCopyIf(data)"
                      >
                        if ... else ...
                      </el-button>

                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="getCopyFor(data)"
                      >
                        for ... else ...
                      </el-button>

                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="data.name"
                      >
                        Имя переменной
                      </el-button>
                    </span>
                  </div>

                  <span slot="reference" class="node-label">
                    <span class="node-label__name">
                      {{ node.label }}
                    </span>
                    <span class="node-label__info">
                      <template v-if="data.subType">
                        (тип: {{ getTypeName(data.type) }}, подтип: {{ getTypeName(data.subType) }})
                      </template>
                      <template v-else>
                        (тип: {{ getTypeName(data.type) }})
                      </template>
                    </span>
                  </span>
                </el-popover>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Report from '@/components/ReportEditor/Models/Reports'

// codemirror theme css
import 'codemirror/theme/lesser-dark.css'
import 'codemirror/theme/juejin.css'
import 'codemirror/theme/lucario.css'
import 'codemirror/theme/mbo.css'
import 'codemirror/theme/xq-light.css'

export default {
  name: 'XmlEditor',

  props: {
    reportId: Number
  },

  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  data () {
    return {
      report: null,
      code: null,
      freezeCode: null,
      options: {
        tabSize: 4,
        mode: 'application/xml',
        theme: 'default',
        styleActiveLine: true,
        styleSelectedText: true,
        lineNumbers: true,
        line: false,
        autoCloseTags: true,
        extraKeys: {
          'Ctrl-S': this.save
        }
      },
      fields: [],
      collapsed: false,
      filterText: '',
      codemirrorThemes: [
        'default',
        'base16-light',
        'lesser-dark',
        'juejin',
        'lucario',
        'mbo',
        'xq-light'
      ]
    }
  },

  async mounted () {
    await this.$http
      .get(`${this.$config.api}/reporteditor/report_source_columns/${this.reportId}`)
      .then(response => {
        this.fields.push(...response.data)
      })

    this.report = await Report.find(this.reportId)
    this.code = this.report.content
    this.freezeCode = this.report.content
  },

  methods: {
    async save () {
      if (this.report) {
        this.report.content = this.code
        this.freezeCode = this.code
        await this.report.save()
      }
    },

    isEditXml () {
      return this.freezeCode !== this.code
    },

    collapsePanel () {
      this.collapsed = !this.collapsed
    },

    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.indexOf(value) !== -1
    },

    getCopyIf (data) {
      return `{% if ${data.id} %}\n\n` +
        `  {{ ${data.id} }}\n\n` +
        `{% else %}\n\n` +
        `  Нет данных\n\n` +
        `{% endif %}`
    },

    getCopyFor (data) {
      return `{% for item in ${data.id} %}\n\n` +
        `  {{ item }}\n\n` +
        `{% else %}\n\n` +
        `  Нет данных\n\n` +
        `{% endfor %}`
    },

    twigDoc () {
      const a = document.createElement('a')
      a.href = 'https://twig.symfony.com/doc/'
      a.target = '_blank'
      a.click()
      a.remove()
    },

    getTypeName (type) {
      switch (type) {
        case 'object':
          return 'объект'
        case 'array':
          return 'массив'
        case 'date':
          return 'дата'
        case 'decimal':
        case 'number':
          return 'число'
        case 'boolean':
          return 'логический'
        case 'string':
          return 'текст'
        default:
          return 'любой'
      }
    }
  }
}
</script>

<style lang="scss">
.xml_editor {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .tool-box {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e4e7ed;

    .el-select {
      width: 120px;
    }

    &__item {
      margin-right: 15px;
    }
  }

  .code_box {
    height: calc(100vh - 95px);

    .CodeMirror {
      width: 100%;
      height: 100%;
    }

    .cm-s-base16-light.CodeMirror {
      background: #ffffff;
      color: #202020;
    }

    .CodeMirror-gutters {
      z-index: 2;
    }

    .cm-s-base16-light .CodeMirror-gutters {
      border-right: 1px solid #e4e7ed;
    }
  }

  .filter-input {
    padding: 5px;
  }

  .el-tree-node>.el-tree-node__children {
    border-left: 2px solid #4FA9F3;
    margin-left: 5px;
  }

  .node-label {
    cursor: pointer;
  }

  .node-label:hover {
    color: #4FA9F3;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .cd-button {
    position: absolute;
    top: calc(50% - 35px);
    border-radius: 5px 0 0 5px;
    height: 70px;
    width: 10px;
    -webkit-transition: top 1s ease-out 0.5s;
    transition: top 1s ease-out 0.5s;
    padding: 10px;
    text-align: center;
  }

  .cd-panel {
    visibility: hidden;
    transition: visibility 0s 0.6s;
  }

  .cd-panel.is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
  }

  .cd-panel-container {
    position: absolute;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1000;

    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0.3s;

    transform: translate3d(100%, 0, 0);

    background-color: #fff;
  }

  .is-visible .cd-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }

  .tree_scroll {
    height: calc(100vh - 80px);
    width: 100%;
  }
}
</style>
