import RelatedObjectCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RelatedObjectCommandRepositoryInterface'
import store from '@/store'
import { CreateRelatedObject, UpdateRelatedObject, DeleteRelatedObject } from '@/services/LogicEditor/infrastructure/store/modules/RelatedObject/action-types'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'

export default class RelatedObjectCommandRepository implements RelatedObjectCommandRepositoryInterface {
  insert (item: RelatedObjectDTO): Promise<void> {
    return store.dispatch(new CreateRelatedObject(item))
  }

  update (item: RelatedObjectDTO): Promise<void> {
    return store.dispatch(new UpdateRelatedObject(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteRelatedObject(guid))
  }
}
