<template>
  <el-form ref="argumentForm" :model="model" :rules="rules" label-position="top" size="mini">
    <template v-if="type === 'column'">
      <el-form-item :label="$t('main.calculator.labels.xrefField')" prop="xref_field_id">
        <el-select
          v-model="model.xref_field_id"
          :placeholder="$t('main.calculator.labels.xrefField')"
          value-key="id"
          filterable
          @change="changeXrefField"
        >
          <el-option
            v-for="item in xrefFields"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('main.calculator.labels.external_arguments')" prop="field_id">
        <tree-select
          v-model="model.field_id"
          :data="fields"
          :props="treeProps"
          :placeholder="$t('main.calculator.labels.external_arguments')"
          :render-content="renderTree"
          :disabled="!fields.length"
          @change="changeExternalArgument"
        ></tree-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="add()">{{ $t('main.button.add') }}</el-button>
      </el-form-item>
    </template>

    <template v-else>
      <el-form-item :label="$t('main.calculator.labels.relation')" prop="xref_field_id">
        <el-select
          v-model="model.xref_field_id"
          :placeholder="$t('main.calculator.labels.relation')"
          value-key="id"
          filterable
          @change="changeXrefField">
          <el-option
            v-for="item in xrefFields"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="fields.length > 0" :label="$t('main.calculator.labels.relation.field')" prop="field_id">
        <tree-select
          v-model="model.field_id"
          :data="fields"
          :props="treeProps"
          :placeholder="$t('main.calculator.labels.field')"
          :render-content="renderTree"
          @change="changeExternalArgument"
        ></tree-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="add()">{{ $t('main.button.add') }}</el-button>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import TreeSelect from '@/components/Common/TreeSelect'

export default {
  name: 'ArgumentExternalTableForm',

  components: {
    TreeSelect
  },

  props: {
    value: Object,

    fields: {
      type: Array,
      default () {
        return []
      }
    },

    xrefFields: {
      type: Array,
      default () {
        return []
      }
    },

    addArgument: {
      type: Function,
      default () {
        return function () {
        }
      }
    },

    changeArgument: {
      type: Function,
      default () {
        return function (value) {
        }
      }
    },

    changeXrefField: {
      type: Function,
      default () {
        return function (value) {
        }
      }
    },

    type: {
      type: String,
      default: 'column',
      validate (value) {
        return ['column', 'row'].includes(value)
      }
    }
  },

  data () {
    return {
      model: this.value,

      treeProps: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children'
      },

      rules: {
        field_id: [{ required: true, message: 'Select argument', trigger: 'change' }],
        xref_field_id: [{ required: true, message: 'Select xref field', trigger: 'change' }]
      }
    }
  },

  methods: {
    add () {
      this.$refs.argumentForm.validate((valid) => {
        if (valid) {
          this.addArgument()
        } else {
          return false
        }
      })
    },

    changeExternalArgument (value) {
      this.changeArgument(value)
    },

    renderTree (h, { node, data, store }) {
      const attr = `attr_${data.id}_`

      return h('span', {
        class: {
          'custom-tree-node': true
        }
      }, [
        h('span', node.label),
        h(
          'span',
          {
            class: 'el-tree-node__attribute'
          },
          attr
        )
      ])
    }
  }
}
</script>

<style scoped>

</style>
