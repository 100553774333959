import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'
import TemplateCreateCommand from '@/services/EtlEditor/application/command/TemplateCreateCommand'
import TemplateCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TemplateCommandRepositoryInterface'

export default class TemplateCreateHandler implements CommandHandlerInterface {
    private repository: TemplateCommandRepositoryInterface;

    constructor (repository: TemplateCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TemplateCreateCommand): Promise<void> {
      let item: TemplateDTO = {
        name: command.getName(),
        alias: command.getAlias(),
        file_id: command.getFileId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
