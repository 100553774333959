<template>
  <div class="task_form">
    <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
      <el-form-item :label="$t('etl_editor.form.fields.name')" prop="name">
        <el-input v-model="localValue.name" :placeholder="$t('etl_editor.form.fields.name')" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.extractor')" prop="extractor_id">
        <dynamic-select
          v-model="localValue.extractor_id"
          :placeholder="$t('etl_editor.form.fields.extractor')"
          :options.sync="list.extractor"
          type="extractor"
        ></dynamic-select>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.loader')" prop="loader_id">
        <dynamic-select
          v-model="localValue.loader_id"
          :placeholder="$t('etl_editor.form.fields.loader')"
          :options.sync="list.loader"
          type="loader"
        ></dynamic-select>
      </el-form-item>

      <el-form-item :label="$t('etl_editor.form.fields.code')" prop="code">
        <el-input v-model="localValue.code" :placeholder="$t('etl_editor.form.fields.code')"></el-input>
      </el-form-item>

      <slot></slot>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue'
import DynamicSelect from '../DynamicSelect'

export default Vue.extend({
  name: 'TaskCreate',

  components: {
    DynamicSelect
  },

  props: {
    value: {
      type: Object,
      default: null
    },
    title: String
  },

  watch: {
    value () {
      this.localValue = this.value
    },

    localValue () {
      this.$emit('input', this.localValue)
    }
  },

  data () {
    return {
      localValue: this.value,

      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ],
        loader_id: [
          { required: true, message: 'Please select loader', trigger: 'change' }
        ],
        extractor_id: [
          { required: true, message: 'Please select extractor', trigger: 'change' }
        ]
      },

      list: {
        loader: [],
        extractor: []
      }
    }
  },

  mounted () {
    this.$http
      .get(`${this.$config.api}/etleditor/extractors?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.extractor = response.data
      })

    this.$http
      .get(`${this.$config.api}/etleditor/loaders?fields=id,name&order=name:asc`)
      .then((response) => {
        this.list.loader = response.data
      })
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(true)
        }
      })
    }
  }
})
</script>
