import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RasterCommandRepositoryInterface from '@/services/RasterLibrary/domain/repository/RasterCommandRepositoryInterface'
import RasterUpdateCommand from '@/services/RasterLibrary/application/command/RasterUpdateCommand'
import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'

export default class RasterUpdateHandler implements CommandHandlerInterface
{
    private repo: RasterCommandRepositoryInterface;

    constructor(repo: RasterCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RasterUpdateCommand): Promise<void>
    {
        let item: RasterDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            description: command.getDescription()
        };
        return this.repo.update(item);
    }
}