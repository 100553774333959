import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'
import { StateFieldDTO } from '@/services/LogicEditor/domain/model/State'

export default class StateCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private isComplex: boolean
  private properties: Nullable<object>
  private stateFields: Array<StateFieldDTO>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    isComplex: boolean,
    stateFields: Array<StateFieldDTO>,
    logicId: number,
    properties: Nullable<object>,
    alias: Nullable<string>,
    parentTreeElementId: Nullable<number>
  ) {
    this.name = name
    this.description = description
    this.isComplex = isComplex
    this.properties = properties
    this.stateFields = stateFields
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'StateCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getIsComplex (): boolean {
    return this.isComplex
  }

  getProperties (): Nullable<object> {
    return this.properties
  }

  getStateFields (): Array<StateFieldDTO> {
    return this.stateFields
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
