import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ApprovalUpdateCommand from '@/services/LogicEditor/application/command/ApprovalUpdateCommand'
import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'

export default class ApprovalUpdateHandler implements CommandHandlerInterface {
  private repository: ApprovalCommandRepositoryInterface

  constructor (repository: ApprovalCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ApprovalUpdateCommand): Promise<void> {
    return this.repository.update(command.getDTO())
  }
}
