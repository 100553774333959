<template>
  <div class="panel">
    <el-tooltip content="Свойства" placement="bottom" effect="light" :open-delay="500">
      <popover-button
        :icon="icons.settings"
        :options="fileOptions"
      >
        <div class="options">
          <div
            v-for="(option, index) in fileOptions"
            class="list_item"
            :key="index"
            @click="getEventBus().$emit('closePopover');getEventBus().$emit(option.event, $event)"
          >
            {{ option.name }}
          </div>
        </div>
      </popover-button>
    </el-tooltip>
    <el-tooltip content="Описание" placement="bottom" effect="light" :open-delay="500">
      <simple-button
        :icon="icons.notes"
        @click="getEventBus().$emit('openNotes')"
      ></simple-button>
    </el-tooltip>
    <div style="margin-left: auto; display: flex; flex-direction: row; align-items: center;">
      <deployment-block
        style="margin-right: 20px"
        :process-id="processId"
      />
      <div class="button" @click="getEventBus().$emit('save')">Сохранить</div>
    </div>
  </div>
</template>

<script>
import { faCog, faPlus, faStickyNote } from '@fortawesome/free-solid-svg-icons'
import PopoverButton from './PopoverButton.vue'
import SimpleButton from '@/services/BPMNEditor/infrastructure/components/Toolbar/SimpleButton.vue'
import DeploymentBlock from '@/services/BPMNEditor/infrastructure/components/Toolbar/DeploymentBlock.vue'

const FILE_OPTIONS = [
  { name: 'Импортировать', event: 'import' },
  { name: 'Экспортировать', event: 'export' }
]

export default {
  name: 'Toolbar',
  inject: ['getEventBus'],
  props: {
    processId: Number
  },
  components: {
    DeploymentBlock,
    SimpleButton,
    PopoverButton
  },
  data () {
    return {
      fileOptions: FILE_OPTIONS,
      icons: {
        settings: faCog,
        plus: faPlus,
        notes: faStickyNote
      }
    }
  }
}
</script>
