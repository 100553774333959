import { Nullable } from '@/core/domain/type/types'

export default class ConnectionCreateCommand {
    private name: string;
    private description: Nullable<string>;
    private type: string;
    private properties: Array<string>;

    constructor (
      name: string,
      description: Nullable<string>,
      type: string,
      properties: Array<string>
    ) {
      this.name = name
      this.description = description
      this.type = type
      this.properties = properties
    }

    getClassName(): string {
      return 'ConnectionCreateCommand'
    }

    getName(): string {
      return this.name
    }

    getDescription(): Nullable<string> {
      return this.description
    }

    getType(): string {
      return this.type
    }

    getProperties(): Array<string> {
      return this.properties
    }
}
