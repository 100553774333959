
import { PropType } from 'vue'
import InputMixin from '@/services/CssEditor/infrastructure/components/InputMixin'
import mixins from 'vue-typed-mixins'

export default mixins(InputMixin).extend({
  name: 'InputColor',

  props: {
    value: String as PropType<string>
  },

  computed: {
    localValue: {
      get: function (): string {
        return this.value
      },

      set: function (value: string): void {
        if (this.allTemplates.includes(value)) {
          this.templateValue = value
        }

        this.$emit('change', [value.trim(), this.value])
        this.$emit('input', value.trim())
      }
    },

    colorValue: {
      get: function (): string {
        if (this.localValue === 'transparent' || this.isVarValue) {
          return null
        }

        return this.localValue
      },

      set: function (value: string): void {
        if (value === null || value === '') {
          value = 'transparent'
        }

        this.localValue = value
      }
    },

    isVarValue () {
      return !!this.variables[this.localValue]
    },

    allTemplates () {
      const result = []

      for (const group of this.templates) {
        for (const item of group.children) {
          result.push(item.value)
        }
      }

      return result
    }
  },

  watch: {
    templateValue () {
      if (this.templateValue !== 'custom') {
        if (typeof this.variables[this.templateValue] !== 'undefined' && !this.isVarValue) {
          this.localOldValue = this.localValue
        }

        this.localValue = this.templateValue
      } else {
        if (this.isVarValue) {
          this.localValue = this.localOldValue
        }
      }
    }
  },

  data () {
    return {
      localOldValue: 'transparent',
      templateValue: 'custom',

      templates: [
        {
          label: 'Templates',
          children: [
            { label: 'Custom', value: 'custom' },
            { label: 'White', value: 'rgba(255, 255, 255, 1)' },
            { label: 'Black', value: 'rgba(0, 0, 0, 1)' },
            { label: 'Transparent', value: 'rgba(0, 0, 0, 0.0)' },
            { label: 'Red', value: 'rgba(255, 0, 0, 1)' },
            { label: 'Blue', value: 'rgba(0, 0, 255, 1)' },
            { label: 'Green', value: 'rgba(0, 255, 0, 1)' },
            { label: 'Yellow', value: 'rgba(255, 255, 0, 1)' }
          ]
        },
        {
          label: 'Variables',
          // children: [
          //   { label: 'Color White', value: '$--color-white' },
          //   { label: 'Color Black', value: '$--color-black' },
          //   { label: 'Color Primary', value: '$--color-primary' },
          //   { label: 'Color Success', value: '$--color-success' },
          //   { label: 'Color Warning', value: '$--color-warning' },
          //   { label: 'Color Danger', value: '$--color-danger' }
          // ]
          children: this.variableList.filter(v => v.value !== this.currentVariable)
        }
      ]
    }
  }
})
