<template>
  <el-form-item :label="label" :label-width="titleWidth">
    <tree-select
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      node-key="id"
      clearable
      multiple
      :data="data"
      :props="treeProps"
      :placeholder="$locale.main.placeholder.select">
    </tree-select>
  </el-form-item>
</template>

<script>
import TreeSelect from '@/components/Common/TreeSelect'

export default {
  name: 'prop-field-multi',

  props: ['value', 'size', 'label', 'titleWidth', 'registryId'],

  components: {
    TreeSelect
  },

  watch: {
    registryId () {
      this.getObjects()

      this.$emit('input', null)
    }
  },

  data () {
    return {
      data: [],
      treeProps: {
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id === 'field_group'
        },
        isLeaf: 'leaf'
      }
    }
  },

  mounted () {
    this.getObjects()
  },

  methods: {
    getObjects () {
      if (this.registryId !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.registryId}&show_children=true`)
          .then(response => { this.data = response.data.data })
          .catch(error => console.log(error))
      }
    }
  }
}
</script>

<style type="text/css">
  .tree-xref.el-tree {
    height: 200px;
    overflow-y: scroll;
    border: 1px solid #dcdfe6;
  }
</style>
