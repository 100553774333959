import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ConnectionPropertiesQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/ConnectionPropertiesQueryRepositoryInterface'
import store from '@/store'
import { GetConnectionProperties } from '@/services/AccessEditor/infrastructure/store/modules/Connection/action-types'

export default class ConnectionPropertiesQueryRepository extends AbstractQueryRepository implements ConnectionPropertiesQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetConnectionProperties(payload))
  }
}
