import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ExtractorCreateCommand implements CommandInterface {
  private name: string
  private extractorTypeId: string
  private alias: Nullable<string>
  private properties: Array<object>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    extractorTypeId: string,
    alias: Nullable<string>,
    properties: Array<object>,
    parentTreeElementId: number
  ) {
    this.name = name
    this.extractorTypeId = extractorTypeId
    this.alias = alias
    this.properties = properties
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'ExtractorCreateCommand'
  }

  getName (): string {
    return this.name
  }

  getExtractorTypeId (): string {
    return this.extractorTypeId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getProperties (): Array<object> {
    return this.properties
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
