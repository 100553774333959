<template>
  <MainLayout>
    <template slot="header">
      <div class="flex flex-row justify-between">
        <div class="text-2xl font-bold">{{ title || 'BPMN Gateway' }}</div>
        <el-button type="text" icon="el-icon-refresh-right" circle @click="update"></el-button>
      </div>
      <template v-if="currentProcess">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="mt-2">
          <el-breadcrumb-item>
            <a @click="currentProcess = null">{{ $t('main.button.back') }}</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ currentProcess.id_ }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </template>
    </template>
    <template slot="content">
      <ProcessInstancesTable
        v-show="!currentProcess"
        ref="instancesTable"
        :process-key="processKey"
        :additional-variables="additionalVariables"
        @select="onSelectProcess"
      />
      <ProcessInstanceInfo
        ref="instanceInfo"
        :process="currentProcess"
        v-if="currentProcess"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/services/BpmnGateway/infrastructure/layouts/MainLayout.vue'
import ProcessInstancesTable from '@/services/BpmnGateway/infrastructure/components/ProcessInstancesTable.vue'
import ProcessInstanceInfo from '@/services/BpmnGateway/infrastructure/components/ProcessInstanceInfo.vue'

export default {
  name: 'BpmnGateway',
  components: { ProcessInstanceInfo, ProcessInstancesTable, MainLayout },
  props: {
    title: String,
    processKey: String,
    additionalVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentProcess: null
    }
  },
  methods: {
    update () {
      const ref = this.currentProcess ? 'instanceInfo' : 'instancesTable'
      console.log(ref)
      console.log(this.$refs)
      this.$refs[ref]?.update()
    },
    onSelectProcess (process) {
      this.currentProcess = process
    }
  }
}
</script>

<style scoped lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
