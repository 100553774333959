<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
      <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
    </el-form-item>

    <el-form-item :label="$locale.etl_editor.form.fields.alias" prop="alias">
      <el-input v-model="value.alias" :placeholder="$locale.etl_editor.form.fields.alias" size="mini"></el-input>
    </el-form-item>

    <el-scrollbar v-if="inModal" class="form_scrollbar_modal">
      <div class="modal_form">
        <component-form v-model="value.properties" :properties="typeProperties"></component-form>
      </div>
    </el-scrollbar>
    <el-scrollbar v-else class="form_scrollbar">
      <component-form v-model="value.properties" :properties="typeProperties"></component-form>
    </el-scrollbar>

    <slot></slot>
  </el-form>
</template>

<script>
import ComponentForm from './ComponentForm'
import LoaderType from '../../Models/LoaderType'

export default {
  name: 'LoaderUpdate',

  props: {
    value: {
      type: Object,
      default: null
    },
    inModal: {
      type: Boolean,
      default () {
        return false
      }
    }
  },

  components: {
    ComponentForm
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' }
        ]
      },
      typeProperties: []
    }
  },

  async mounted () {
    this.typeProperties = await new LoaderType({ id: this.value.loader_type_id }).properties().get()
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save(false)
        }
      })
    }
  }
}
</script>

<style type="text/css">

</style>
