
import Vue from 'vue'
import ThemeCard from '@/services/CssEditor/infrastructure/components/ThemeCard.vue'
import ThemeForm from '@/services/CssEditor/infrastructure/components/form/ThemeForm.vue'
import ThemesQuery from '@/services/CssEditor/application/query/ThemesQuery'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'
import ThemeCreateCommand from '@/services/CssEditor/application/command/ThemeCreateCommand'
import ThemeByGuidQuery from '@/services/CssEditor/application/query/ThemeByGuidQuery'
import ThemeDeleteCommand from '@/services/CssEditor/application/command/ThemeDeleteCommand'
import ThemeUpdateCommand from '@/services/CssEditor/application/command/ThemeUpdateCommand'

export default Vue.extend({
  name: 'ThemeGrid',

  components: {
    ThemeCard,
    ThemeForm
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],

  computed: {
    isRenameCard () {
      return this.renameCard !== null
    },

    isEditCard () {
      return this.editCard !== null
    },

    isLoading () {
      return this.$store.getters['Theme/isThemeLoading']
    },

    themeCreated () {
      return this.$store.getters['Theme/getThemeLocation']
    }
  },

  watch: {
    visibleRename () {
      if (!this.visibleRename) {
        this.renameCard = null
      }
    },

    renameCard () {
      if (this.renameCard !== null) {
        this.visibleRename = true
      }
    },

    themeCreated (location) {
      if (location !== null) {
        this.handleCardPush(location.replace('/themes/', ''))
      }
    }
  },

  data () {
    return {
      visibleRename: false,

      renameCard: null,

      editCard: null,

      cards: [],

      uploadList: []
    }
  },

  async mounted () {
    await this.handleLoad()
  },

  methods: {
    handleUploadChange (file, fileList) {
      console.log('handleUploadChange', file)

      this.uploadList = [file]

      if (file.raw.type !== 'application/json') {
        this.uploadList = []
        this.$message.error(this.$t('css_editor.messages.select_json_file'))
        return false
      }

      const reader = new FileReader()
      reader.onload = () => {
        const theme = new ThemeDTO(
          JSON.parse(
            reader.result as string
          )
        )

        this.getCommandBus().execute(
          new ThemeCreateCommand(theme.asNew(file.name))
        )
      }
      reader.onerror = () => {
        this.$message.error(this.$t('css_editor.messages.read_json_error'))

        console.error(reader.error)
      }
      reader.readAsText(file.raw, 'UTF-8')

      return true
    },

    async handleLoad () {
      this.cards = await this.getQueryBus().execute(
        new ThemesQuery()
      )
    },

    async handleCardPush (guid: string) {
      const theme = await this.getQueryBus().execute(
        new ThemeByGuidQuery(guid)
      )

      this.cards.push(theme)
    },

    handleCreate () {
      this.getCommandBus().execute(
        new ThemeCreateCommand(
          ThemeDTO.create('Theme ' + (this.cards.length + 1))
        )
      ).then(() => {
        this.handleBuildCss()
      })
    },

    async handleBuildCss (item?: ThemeDTO) {
      if (item) {
        await this.$http
          .post(`${this.$config.api}/nodeutils/build_themes/${item.id}`, null, {
            hideNotification: true
          })
          .then(() => {
            const themeLink = item.isDefault()
              ? `${this.$config.api}/files/csseditor/themes/default.min.css`
              : `${this.$config.api}/files/csseditor/themes/theme-${item.id}.min.css`

            localStorage.setItem('theme-link', themeLink)

            const themeTag = document.getElementById('themeCss')
            const customThemeLink = localStorage.getItem('theme-link')

            if (themeTag && customThemeLink) {
              themeTag.setAttribute('href', `${customThemeLink}?v=${Math.random()}`)
            }
          })
          .catch((error) => {
            const data = error?.response?.data || null

            if (data && data.code === 'compiler_error') {
              this.$alert(data.message, this.$t('main.message.error'), {
                type: 'error',
                dangerouslyUseHTMLString: true
              })
            }
          })
      } else {
        await this.$http
          .post(`${this.$config.api}/nodeutils/build_themes`, null, {
            hideNotification: true
          })
          .catch((error) => {
            const data = error?.response?.data || null

            if (data && data.code === 'compiler_error') {
              this.$alert(data.message, this.$t('main.message.error'), {
                type: 'error',
                dangerouslyUseHTMLString: true
              })
            }
          })
      }
    },

    handleEdit (item: ThemeDTO) {
      this.editCard = item
    },

    handleDuplicate (item: ThemeDTO) {
      this.getCommandBus().execute(
        new ThemeCreateCommand(item.duplicate())
      ).then(() => {
        this.handleBuildCss(item)
      })
    },

    handleReset (item: ThemeDTO) {
      item.resetVariables()

      this.getCommandBus().execute(
        new ThemeUpdateCommand(item)
      ).then(() => {
        this.handleBuildCss(item)
      })
    },

    handleClose () {
      this.editCard = null
      this.renameCard = null
      this.visibleRename = false
    },

    async handleSave () {
      const dto = this.editCard !== null
        ? this.editCard
        : this.renameCard

      if (!dto) {
        return
      }

      await this.getCommandBus().execute(
        new ThemeUpdateCommand(dto)
      ).then(() => {
        this.handleBuildCss(dto)
      })

      if (this.editCard) {
        // if (typeof this.editCard.guid !== 'undefined') {
        //   this.editCard = await this.getQueryBus().execute(
        //     new ThemeByGuidQuery(this.editCard.guid)
        //   )
        // }

        return
      }

      this.renameCard = null
      this.visibleRename = false
    },

    handleRename (item: ThemeDTO) {
      this.renameCard = item
    },

    handleDownload (item: ThemeDTO) {
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(item)], { type: 'application/json' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${item.name}.json`)
      document.body.appendChild(link)
      link.click()
    },

    handleDelete (item: ThemeDTO) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        if (item.isDefault()) {
          this.$message.error(this.$t('It is forbidden to delete the default theme!'))

          return
        }

        this
          .getCommandBus()
          .execute(
            new ThemeDeleteCommand(item.guid)
          )
          .then(() => {
            const index = this.cards.findIndex(i => i.id === item.id)

            if (index !== -1) {
              this.cards.splice(index, 1)
            }
          })
      })
    }
  }
})
