import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProjectCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectCommandRepositoryInterface'
import ProjectCreateCommand from '@/services/MapEditor/application/command/ProjectCreateCommand'
import { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'

export default class ProjectCreateHandler implements CommandHandlerInterface
{
    private repo: ProjectCommandRepositoryInterface;

    constructor(repo: ProjectCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectCreateCommand): Promise<void>
    {
        let item: ProjectDTO = {
            name: command.getName(),
            description: command.getDescription()
        };
        return this.repo.insert(item);
    }
}