import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TaskDTO } from '@/services/EtlEditor/domain/model/Task'
import TaskUpdateCommand from '@/services/EtlEditor/application/command/TaskUpdateCommand'
import TaskCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskCommandRepositoryInterface'

export default class TaskUpdateHandler implements CommandHandlerInterface {
    private repository: TaskCommandRepositoryInterface;

    constructor (repository: TaskCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TaskUpdateCommand): Promise<void> {
      let item: TaskDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        cron_time: command.getCronTime(),
        is_cron_task: command.getIsCronTask(),
        is_visible_in_registry: command.getIsVisibleInRegistry(),
        code: command.getCode(),
        extractor_id: command.getExtractorId(),
        loader_id: command.getLoaderId()
      }

      return this.repository.update(item)
    }
}
