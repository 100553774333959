import TopicRightCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/TopicRightCommandRepositoryInterface'
import { TopicRightDTO } from '@/services/AccessEditor/domain/model/Right/TopicRight'
import store from '@/store'
import { UpdateTopicRight } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class TopicRightCommandRepository implements TopicRightCommandRepositoryInterface {
  update (item: TopicRightDTO): Promise<void> {
    return store.dispatch(new UpdateTopicRight(item))
  }
}
