import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ConnectionQueryRepositoryInterface from '../../../domain/repository/ConnectionQueryRepositoryInterface'
import ConnectionsCountQuery from '../../query/ConnectionsCountQuery'

export default class ConnectionsCountHandler implements QueryHandlerInterface {
    private repo: ConnectionQueryRepositoryInterface;

    constructor (repo: ConnectionQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: ConnectionsCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
