import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SchemaRepositoryInterface from '@/services/ApiService/domain/repository/SchemaRepositoryInterface'
import DeleteSchemaCommand from '@/services/ApiService/application/command/DeleteSchemaCommand'

export default class DeleteSchemaHandler implements CommandHandlerInterface {
  private repository: SchemaRepositoryInterface;

  constructor (repository: SchemaRepositoryInterface) {
    this.repository = repository
  }

  execute (command: DeleteSchemaCommand): Promise<void> {
    return this.repository.deleteSchema(command.getGuid())
  }
}
