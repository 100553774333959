import CommandInterface from '@/core/application/command/CommandInterface'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export default class CreateRequestParameterCommand implements CommandInterface {
  private dto: RequestParameter;
  private requestGuid: string;

  constructor (
    dto: RequestParameter,
    requestGuid: string
  ) {
    this.dto = dto
    this.requestGuid = requestGuid
  }

  getClassName (): string {
    return 'CreateRequestParameterCommand'
  }

  getDto (): RequestParameter {
    return this.dto
  }

  getRequestGuid (): string {
    return this.requestGuid
  }
}
