import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ConstraintCreateCommand from '@/services/LogicEditor/application/command/ConstraintCreateCommand'
import ConstraintCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintCommandRepositoryInterface'

export default class ConstraintCreateHandler implements CommandHandlerInterface {
    private repository: ConstraintCommandRepositoryInterface;

    constructor (repository: ConstraintCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ConstraintCreateCommand): Promise<void> {
      return this.repository.insert(command.getDTO())
    }
}
