<template>
  <div class="xsd_import_dialog">
    <el-dialog
      :title="$locale.etl_editor.tooltip.import"
      :visible.sync="model.show"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      width="30%"
    >
      <span>
        <el-form label-position="top">
          <el-form-item :label="$locale.etl_editor.xsd_import.index_file" v-if="model.selected">
            <el-tree
              ref="tree"
              show-checkbox
              @check-change="checkNode"
              check-strictly
              :data="model.data"
              node-key="id"
              :props="{ children: 'children', label: 'name' }">
            </el-tree>
          </el-form-item>
          <el-form-item :label="$locale.etl_editor.xsd_import.file">
            <el-upload
              id="xsd-upload"
              name="zipfile"
              :headers="headers"
              :action="`${this.$config.api}/xsd2jsonconverter/xsdservice/zip_files`"
              list-type="text"
              :auto-upload="true"
              :multiple="false"
              :file-list="model.fileList"
              :on-change="change"
              :on-success="success"
              :limit="1">
              <el-button size="small" type="primary" class="el-icon-upload">
                {{ $locale.etl_editor.xsd_import.select_file }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $locale.etl_editor.xsd_import.file_info }}
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="importCancel">
          {{ $locale.main.button.cancel }}
        </el-button>
        <el-button size="small" type="primary" @click="submit()" :disabled="model.checkedNode === null" v-loading.fullscreen.lock="model.loading">
          {{ $locale.main.button.import }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'XsdImportDialog',

  props: {
    value: Object
  },

  computed: {
    headers () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    change (file, fileList) {
      if (['application/x-zip-compressed', 'application/zip'].indexOf(file.raw.type) === -1) {
        this.model.fileList = []
        this.$message.error(this.$locale.etl_editor.xsd_import.zip_file)
        return false
      }

      this.model.fileList = fileList
    },

    success (response, file, fileList) {
      this.model.selected = true
      this.model.guid = response.guid
      this.model.data = response.file_list
    },

    importCancel () {
      this.model = {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      }
    },

    submit () {
      if (this.model.checkedNode !== null) {
        this.$http
          .post(
            `${this.$config.api}/xsd2jsonconverter/xsdservice/zip_parse`,
            {
              guid: this.model.guid,
              filename: this.model.checkedNode.name
            }
          )
          .then((response) => {
            this.model.loading = true
            this.$http
              .post(`${this.$config.api}/etleditor/mappings/${this.taskId}/xsd_import`, { element: response.data.json })
              .then((childResponse) => {
                this.model = {
                  show: false,
                  selected: false,
                  data: [],
                  guid: null,
                  fileList: [],
                  checkedNode: null,
                  fileType: '',
                  loading: false
                }
              })
              .finally(() => {
                this.model.loading = false
              })
          })
      }
    },

    checkNode (node, checked) {
      if (checked) {
        this.model.checkedNode = node
        this.$refs.tree.setCheckedKeys([node.id])
      }
    }
  }
}
</script>

<style scoped></style>
