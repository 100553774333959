import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ApprovalDeleteCommand from '@/services/LogicEditor/application/command/ApprovalDeleteCommand'
import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'

export default class ApprovalDeleteHandler implements CommandHandlerInterface {
    private repository: ApprovalCommandRepositoryInterface;

    constructor (repository: ApprovalCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ApprovalDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
