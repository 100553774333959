import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import NotificationCreateCommand from '@/services/NotificationEditor/application/command/NotificationCreateCommand'
import NotificationCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/NotificationCommandRepositoryInterface'

export default class NotificationCreateHandler implements CommandHandlerInterface {
  private repository: NotificationCommandRepositoryInterface;

  constructor (repository: NotificationCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: NotificationCreateCommand): Promise<void> {
    return this.repository.insert(command.getDto())
  }
}
