import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import LayerTreeElementQueryRepositoryInterface from '@/services/MapEditor/domain/repository/LayerTreeElementQueryRepositoryInterface'
import store from '@/store'
import { GetLayerTreeElementByGuid, GetLayerTreeElements } from '@/services/MapEditor/infrastructure/store/modules/LayerTreeElement/action-types'

export default class LayerTreeElementQueryRepository extends AbstractQueryRepository implements LayerTreeElementQueryRepositoryInterface
{
    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetLayerTreeElementByGuid(guid));
    }

    getChildNodes(parentId: Nullable<number>, projectId?: Nullable<number>): Promise<any>
    {
        return store.dispatch(new GetLayerTreeElements(parentId, projectId));
    }
}