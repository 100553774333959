/**
  *  проверка на пустоту
  *  @param n - Любая переменная
  *  @example
  *  isEmpty(null);         // true
  *  isEmpty(undefined);    // true
  *  isEmpty('');           // true
  *  isEmpty(' ');          // true
  *  isEmpty({});           // true
  *  isEmpty([]);           // true
  *  isEmpty('[]');         // true
  *  isEmpty(0);            // false
  *  isEmpty('hello');      // false
  *  isEmpty({a: 1});       // false
  *  isEmpty([1, 2, 3]);    // false
*/
export const isEmpty = (value: any): boolean => {
  if (value == null) { // Проверяет null и undefined
    return true
  }

  if (typeof value === 'string') {
    const trimmedValue = value.trim()
    return trimmedValue.length === 0 || trimmedValue === '[]'
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  return false
}
/**
 проверка на число
 @param n - Любая переменная
*/
export const isNumeric = (n: any): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}
/**
 * Является ли переменная объектом
*/
export function isObject (obj:any): boolean {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
/**
 * Является ли переменная массивом
*/
export function isArray (arr:any): boolean {
  return Object.prototype.toString.call(arr) === '[object Array]'
}
/**
 * Переменные одинакового типа
*/
export function isSameType (item1:any, item2:any): boolean {
  return Object.prototype.toString.call(item1) === Object.prototype.toString.call(item2)
}
/**
 * Глубокое сравнение любых данных
 * @example
 * deepCompare(null, {}) --> false;
 * deepCompare([1, 2, 3], [1, 2, 3] --> true;
 * deepCompare({a: 1, c: null, b: 2}, {a: 1, b: 2, c: null}) --> true
 */
export function deepCompare (obj1:any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true
  }

  if (
    (!isObject(obj1) && !isArray(obj1)) ||
    (!isObject(obj2) && !isArray(obj2))
  ) {
    return false
  }

  if (
    !isSameType(obj1, obj2) ||
    Object.keys(obj1).length !== Object.keys(obj2).length
  ) {
    return false
  }

  for (let key of Object.keys(obj1)) {
    if (!obj2.hasOwnProperty(key)) {
      return false
    }

    if (!deepCompare(obj1[key], obj2[key])) {
      return false
    }
  }
  return true
};
