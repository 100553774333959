import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import RuleGroupQueryRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleGroupQueryRepositoryInterface'
import { GetRuleGroupByGuid } from '@/services/TaskEditor/infrastructure/store/modules/RuleGroup/action-types'

export default class RuleGroupQueryRepository extends AbstractQueryRepository implements RuleGroupQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetRuleGroupByGuid(guid))
  }
}
