import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TransformerUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private transformerTypeId: string
  private alias: Nullable<string>
  private properties: Array<object>

  constructor (
    guid: string,
    name: string,
    transformerTypeId: string,
    alias: Nullable<string>,
    properties: Array<object>
  ) {
    this.guid = guid
    this.name = name
    this.transformerTypeId = transformerTypeId
    this.alias = alias
    this.properties = properties
  }

  getClassName (): string {
    return 'TransformerUpdateCommand'
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getTransformerTypeId (): string {
    return this.transformerTypeId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getProperties (): Array<object> {
    return this.properties
  }
}
