import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import MenuQueryRepositoryInterface from '../../../domain/repository/MenuQueryRepositoryInterface'
import MenuQuery from '../../query/MenuQuery'

export default class MenuHandler implements QueryHandlerInterface {
    private repo: MenuQueryRepositoryInterface;

    constructor (repo: MenuQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: MenuQuery): Promise<any> {
      return this.repo.getRootMenu()
    }
}
