import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace GarAPI {

  export class Update implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = '/garaddressservice/gar/update'
    }
  }
}
