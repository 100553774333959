import ReleaseCommandRepositoryInterface from '@/services/ReleaseEditor/domain/repository/ReleaseCommandRepositoryInterface'
import { ReleaseDTO } from '@/services/ReleaseEditor/domain/model/Release/Release'
import store from '@/store'
import { AddRelease, UpdateRelease, DeleteRelease } from '@/services/ReleaseEditor/infrastructure/store/modules/Release/action-types'

export default class ReleaseCommandRepository implements ReleaseCommandRepositoryInterface
{
    insert(item: ReleaseDTO): Promise<void>
    {
        return store.dispatch(new AddRelease(item));
    }

    update(item: ReleaseDTO): Promise<void>
    {
        return store.dispatch(new UpdateRelease(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteRelease(guid));
    }
}
