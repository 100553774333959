import { Nullable } from '@/core/domain/type/types'

export default class LayerTreeElementLayerPutCommand
{
    private layerId: number;
    private projectId: number;
    private treeElementId: number;
    private parentId: Nullable<number>;

    constructor(
        treeElementId: number,
        layerId: number,
        projectId: number,        
        parentId: Nullable<number>
    ) {
        this.layerId = layerId;
        this.projectId = projectId;
        this.treeElementId = treeElementId;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'LayerTreeElementLayerPutCommand';
    }

    getLayerId(): number
    {
        return this.layerId;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    getTreeElementId(): number
    {
        return this.treeElementId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}