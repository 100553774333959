
import Vue, { PropType } from 'vue'
import CalculatedParameters from '@/services/LogicEditor/infrastructure/components/elements/CalculatedParameters.vue'
import KeyValueForm from '@/services/LogicEditor/infrastructure/components/elements/KeyValueForm.vue'
import {
  createApiKey,
  createBearerToken,
  createFormData,
  createOAuth2, createRaw, createTask, createTemplate,
  IRestApiEventProperties
} from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/RestApiEventForm/classes'
import Report from '@/services/LogicEditor/infrastructure/components/elements/Report.vue'
import FieldSelect from '@/services/LogicEditor/infrastructure/components/elements/FieldSelect.vue'

export default Vue.extend({
  name: 'RestApiEventForm',

  components: {
    Report,
    FieldSelect,
    CalculatedParameters,
    KeyValueForm
  },

  props: {
    value: Object as PropType<IRestApiEventProperties>,

    objectId: Number
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = value
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue)
        }
      },
      deep: true
    }
  },

  computed: {
    variables () {
      return this.localValue.api_variables.map((variable) => `{{ ${variable.name} }}`)
    }
  },

  data () {
    return {
      localValue: this.value,

      rules: {
        api_method: [
          { required: true, message: 'Please choose the method of executing the request', trigger: 'blur' }
        ],
        api_url: [
          { required: true, message: 'Please enter the link to request the API service', trigger: 'blur' }
        ]
      },

      apiMethods: [
        { value: 'get', label: 'GET' },
        { value: 'post', label: 'POST' },
        { value: 'put', label: 'PUT' },
        { value: 'patch', label: 'PATCH' },
        { value: 'delete', label: 'DELETE' }
      ],

      authTypes: [
        { value: null, label: 'None' },
        { value: 'bearer_token', label: 'Bearer Token' },
        { value: 'api_key', label: 'API Key' }
        // { value: 'oauth2', label: 'OAuth2' }
      ],

      apiKeyTypes: [
        { value: 'header', label: 'Header' },
        { value: 'query_params', label: 'Query Params' }
      ],

      responseTypes: [
        { value: null, label: 'None' },
        { value: 'task', label: 'Task' }
      ],

      bodyTypes: [
        { value: null, label: 'None' },
        { value: 'form_data', label: 'Form Data' },
        { value: 'raw', label: 'Raw' },
        { value: 'template', label: 'Template' }
      ],

      formatTypes: [
        { value: 'xml', label: 'XML' },
        { value: 'json', label: 'JSON' }
      ],

      templateSourceTypes: [
        { value: 'report_editor', label: 'Редактор отчётов' },
        { value: 'file_field', label: 'Файловое поле' }
      ],

      formDataTypes: [
        { value: 'string', label: 'String' },
        { value: 'file', label: 'File' }
      ],

      headers: [
        'Accept',
        'Accept-Charset',
        'Accept-Datetime',
        'Accept-Encoding',
        'Accept-Language',
        'Access-Control-Request-Method',
        'Access-Control-Request-Headers',
        'Authorization',
        'Cache-Control',
        'Connection',
        'Content-ID',
        'Content-Length',
        'Content-Range',
        'Content-Type',
        'Content-Transfer-Encoding',
        'Content-Encoding',
        'Cookie',
        'Expect',
        'Forwarded',
        'From',
        'Host',
        'If-Match',
        'If-Modified-Since',
        'If-None-Match',
        'If-Range',
        'If-Match',
        'If-None-Match',
        'If-Unmodified-Since',
        'Max-Forwards',
        'Origin',
        'Pragma',
        'Prefer',
        'Proxy-Authorization',
        'Location',
        'Range',
        'Referer',
        'TE',
        'Trailer',
        'Transfer-Encoding',
        'User-Agent',
        'Upgrade',
        'Via',
        'Warning'
      ],

      tasks: []
    }
  },

  async mounted () {
    this.tasks = await this.$http
      .get(`${this.$config.api}/etleditor/tasks?fields=id,name&order=id:desc`)
      .then((response) => {
        return response.data
      })
      .catch(() => [])
  },

  methods: {
    changeBodyType () {
      if (this.localValue.api_body.type === 'form_data') {
        this.localValue.api_body.properties = createFormData()
      } else if (this.localValue.api_body.type === 'raw') {
        this.localValue.api_body.properties = createRaw()
      } else if (this.localValue.api_body.type === 'template') {
        this.localValue.api_body.properties = createTemplate()
      } else {
        this.localValue.api_body.properties = null
      }
    },

    changeAuthType () {
      if (this.localValue.api_auth.type === 'oauth2') {
        this.localValue.api_auth.properties = createOAuth2()
      } else if (this.localValue.api_auth.type === 'bearer_token') {
        this.localValue.api_auth.properties = createBearerToken()
      } else if (this.localValue.api_auth.type === 'api_key') {
        this.localValue.api_auth.properties = createApiKey()
      } else {
        this.localValue.api_auth.properties = null
      }
    },

    changeResponseType () {
      if (this.localValue.api_response.type === 'task') {
        this.localValue.api_response.properties = createTask()
      } else {
        this.localValue.api_response.properties = null
      }
    },

    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    }
  }
})
