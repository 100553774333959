import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'

export class CoordinateSystemDTO
{
    srid?: Nullable<number>;
    auth_srid?: Nullable<number>;
    auth_name?: Nullable<string>;
    srtext?: Nullable<string> = '';
    proj4text?: Nullable<string>;
    project_id?: number;
    epsg_type?: Nullable<number> = 1;
    user_id?: number;

    constructor({ 
        srid, 
        auth_srid, 
        auth_name, 
        srtext = '', 
        proj4text = '', 
        project_id, 
        epsg_type,
        user_id
    }: {
        srid?: Nullable<number>, 
        auth_srid?: Nullable<number>, 
        auth_name?: Nullable<string>, 
        srtext?: Nullable<string>, 
        proj4text?: Nullable<string>, 
        project_id?: number, 
        epsg_type?: Nullable<number>
        user_id?: number
    }) {
        this.srid = srid;
        this.auth_srid = auth_srid;
        this.auth_name = auth_name;
        this.srtext = srtext;
        this.proj4text = proj4text;
        this.project_id = project_id;
        this.epsg_type = epsg_type;
        this.user_id = user_id;
    }
}

export default class CoordinateSystem extends AbstractBaseEntity
{
    private srid: number;
    private authSrid?: Nullable<number>;
    private authName: string;
    private srtext?: Nullable<string>;
    private proj4text: string;
    private projectId?: Nullable<number>;
    private epsgType?: Nullable<number>;
    private userId?: Nullable<number>;

    constructor(
        srid: number,
        authSrid: Nullable<number>,
        authName: string,
        srtext: Nullable<string>,
        proj4text: string,
        epsgType?: Nullable<number>,
        projectId?: Nullable<number>,       
        userId?: Nullable<number>      
    ) {
        super();
        this.srid = srid;
        this.authSrid = srid;
        this.authName = authName;
        this.srtext = srtext;
        this.proj4text = proj4text;        
        this.epsgType = epsgType;
        this.projectId = projectId;
        this.userId = userId;
    }

    static create(dto: CoordinateSystemDTO): CoordinateSystem
    {
        return new CoordinateSystem(
            dto.srid,
            dto.auth_srid,
            dto.auth_name,
            dto.srtext,
            dto.proj4text,
            dto.epsg_type,
            dto.project_id,
            dto.user_id              
        );
    }

    setSrid(srid: number): void
    {
        this.srid = srid;
    }

    getSrid(): number
    {
        return this.srid;
    }

    setName(name: string): void
    {
        if (name && name.length > 255) {
            throw new NameTooLong();
        }
        this.authName = name;
    }

    getName(): string
    {
        return this.authName;
    }

    setProj4text(proj4text: string): void
    {
        this.proj4text = proj4text;
    }

    getProj4text(): string
    {
        return this.proj4text;
    }

    setEpsgType(epsgType: number): void
    {
        this.epsgType = epsgType;
    }

    getEpsgType(): number
    {
        return this.epsgType;
    }
}