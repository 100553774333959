import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetFormulaByGuid } from '@/services/LogicEditor/infrastructure/store/modules/Formula/action-types'
import FormulaQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/FormulaQueryRepositoryInterface'

export default class FormulaQueryRepository extends AbstractQueryRepository implements FormulaQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetFormulaByGuid(guid))
  }
}
