
import Vue from 'vue'
import LogicGroupByGuidQuery from '@/services/LogicEditor/application/query/LogicGroupByGuidQuery'
import LogicGroup from '@/services/LogicEditor/domain/model/LogicGroup'
import LogicGroupCreateCommand from '@/services/LogicEditor/application/command/LogicGroupCreateCommand'
import LogicGroupUpdateCommand from '@/services/LogicEditor/application/command/LogicGroupUpdateCommand'

export default Vue.extend({
  name: 'LogicGroupForm',

  props: {
    value: Object
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['LogicGroup/isLogicGroupLoading']
    },

    logicGroupCreated () {
      return this.$store.getters['LogicGroup/getLogicGroupLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    logicGroupCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new LogicGroupByGuidQuery(
            location.replace('/logic_groups/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let logicGroup = LogicGroup.create(this.value)

      if (this.isNew) {
        return new LogicGroupCreateCommand(
          logicGroup.getName(),
          logicGroup.getDescription(),
          logicGroup.getLogicId(),
          logicGroup.getAlias(),
          logicGroup.getParentTreeElementId()
        )
      }

      return new LogicGroupUpdateCommand(
        logicGroup.getGuid(),
        logicGroup.getName(),
        logicGroup.getDescription(),
        logicGroup.getAlias()
      )
    }
  }
})
