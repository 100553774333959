import MigrationRepository from '@/services/SyncService/infrastructure/domain/repository/MigrationRepository'
import MigrationsCountQuery from '@/services/SyncService/application/query/MigrationsCountQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'

export default class MigrationsCountHandler implements QueryHandlerInterface {
  private repo: MigrationRepository;

  constructor (repo: MigrationRepository) {
    this.repo = repo
  }

  execute (query: MigrationsCountQuery): Promise<any> {
    return this.repo.getCount(query.getPayload())
  }
}
