import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import UserQueryRepositoryInterface from '../../../domain/repository/UserQueryRepositoryInterface'
import UsersCountQuery from '../../query/UsersCountQuery'

export default class UsersCountHandler implements QueryHandlerInterface {
    private repo: UserQueryRepositoryInterface;

    constructor (repo: UserQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: UsersCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
