
import { PropType } from 'vue'
import InputNumber from '@/services/CssEditor/infrastructure/components/InputNumber.vue'
import utils from '@/services/CssEditor/infrastructure/components/utils'
import InputMixin from '@/services/CssEditor/infrastructure/components/InputMixin'
import mixins from 'vue-typed-mixins'

export type ScssVariable = string

export interface ILocalValue {
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
  lock: boolean;
}

export default mixins(InputMixin).extend({
  name: 'InputBorderRadius',

  components: {
    InputNumber
  },

  props: {
    value: String as PropType<string>
  },

  computed: {
    lockIcon (): string {
      return !this.isVarValue && this.localValue.lock
        ? 'el-icon-lock'
        : 'el-icon-unlock'
    },

    nativeValue (): string | ScssVariable {
      if (this.isVarValue) {
        return this.localValue
      }

      if (this.localValue.lock) {
        return this.localValue.topLeft + this.sizeClass.topLeft
      }

      return `${this.localValue.topLeft}${this.sizeClass.topLeft} ` +
        `${this.localValue.topRight}${this.sizeClass.topRight} ` +
        `${this.localValue.bottomRight}${this.sizeClass.bottomRight} ` +
        `${this.localValue.bottomLeft}${this.sizeClass.bottomLeft}`
    },

    isVarValue () {
      return !!this.variables[this.localValue]
    },

    allTemplates () {
      const result = []

      for (const group of this.templates) {
        for (const item of group.children) {
          result.push(item.value)
        }
      }

      return result
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (value !== this.nativeValue) {
          this.localValue = this.parseValue(value)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (this.allTemplates.includes(value)) {
          this.templateValue = value
        }

        if (JSON.stringify(value) !== JSON.stringify(this.parseValue(this.value))) {
          this.$emit('change', [this.nativeValue, this.value])
          this.$emit('input', this.nativeValue)
        }
      },
      deep: true
    },

    sizeClass: {
      handler: function () {
        this.$emit('input', this.nativeValue)
      },
      deep: true
    },

    templateValue () {
      if (this.templateValue !== 'custom') {
        if (typeof this.variables[this.templateValue] !== 'undefined' && !this.isVarValue) {
          this.localOldValue = this.localValue
        }

        this.localValue = this.templateValue
      } else {
        if (this.isVarValue) {
          this.localValue = this.localOldValue
        }
      }
    }
  },

  data () {
    return {
      localValue: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
        lock: true
      },

      localOldValue: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
        lock: true
      },

      sizeClass: {
        topLeft: 'px',
        topRight: 'px',
        bottomRight: 'px',
        bottomLeft: 'px'
      },

      sizeClasses: utils.sizeClasses,

      templateValue: 'custom',

      templates: [
        {
          label: 'Templates',
          children: [
            { label: 'Custom', value: 'custom' }
          ]
        },

        {
          label: 'Variables',
          // children: [
          //   { label: 'Border Radius Base', value: '$--border-radius-base' },
          //   { label: 'Border Radius Small', value: '$--border-radius-small' },
          //   { label: 'Border Radius Circle', value: '$--border-radius-circle' },
          //   { label: 'Border Radius Zero', value: '$--border-radius-zero' }
          // ]
          children: this.variableList.filter(v => v.value !== this.currentVariable)
        }
      ]
    }
  },

  mounted () {
    this.localValue = this.parseValue(this.value)
  },

  methods: {
    parseValue (value: string): ILocalValue|ScssVariable {
      if (typeof this.variables[value] !== 'undefined') {
        return value
      }

      const val: string[] = value.trim().split(' ')

      if (val.length === 1) {
        this.sizeClass.topLeft = this.sizeClass.topRight = this.sizeClass.bottomRight = this.sizeClass.bottomLeft = utils.getSizeClass(val[0])

        return {
          topLeft: parseInt(val[0]),
          topRight: parseInt(val[0]),
          bottomRight: parseInt(val[0]),
          bottomLeft: parseInt(val[0]),
          lock: true
        }
      }

      const [topLeft = '0', topRight = '0', bottomRight = '0', bottomLeft = '0'] = val

      // Извлекаем ед. измерения для значений
      this.sizeClass.topLeft = utils.getSizeClass(topLeft)
      this.sizeClass.topRight = utils.getSizeClass(topRight)
      this.sizeClass.bottomRight = utils.getSizeClass(bottomRight)
      this.sizeClass.bottomLeft = utils.getSizeClass(bottomLeft)

      return {
        topLeft: parseInt(topLeft),
        topRight: parseInt(topRight),
        bottomRight: parseInt(bottomRight),
        bottomLeft: parseInt(bottomLeft),
        lock: false
      }
    },

    lock () {
      this.localValue.lock = !this.localValue.lock

      this.localValue.topRight = this.localValue.bottomRight = this.localValue.bottomLeft = this.localValue.topLeft
      this.sizeClass.topRight = this.sizeClass.bottomRight = this.sizeClass.bottomLeft = this.sizeClass.topLeft
    }
  }
})
