import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ConnectionCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/ConnectionCommandRepositoryInterface'
import ConnectionCreateCommand from '@/services/AccessEditor/application/command/ConnectionCreateCommand'
import { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'

export default class ConnectionCreateHandler implements CommandHandlerInterface {
    private repo: ConnectionCommandRepositoryInterface;

    constructor (repo: ConnectionCommandRepositoryInterface) {
      this.repo = repo
    }

    execute (command: ConnectionCreateCommand): Promise<void> {
      let item: ConnectionDTO = {
        name: command.getName(),
        description: command.getDescription(),
        connection_type_id: command.getType(),
        properties: command.getProperties()
      }
      return this.repo.insert(item)
    }
}
