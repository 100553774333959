import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import SettingsQueryRepositoryInterface from '@/services/Databus/domain/repository/SettingsQueryRepositoryInterface'
import store from '@/store'
import { GetSettings, CountSettings } from '@/services/Databus/infrastructure/store/modules/Setting/action-types'

export default class SettingsQueryRepository extends AbstractQueryRepository implements SettingsQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetSettings(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountSettings(payload));
    }

}