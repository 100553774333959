<template>
  <el-container direction="vertical" class="address-service">
    <div class="header-container">
      <div class="title">{{ $locale.address_service.title }}</div>
    </div>
    <div class="main-container">
      <el-tabs v-model="activeTab" style="width: 100%;" class="tabs">
        <el-tab-pane style="height: 100%;" :lazy="true" :label="$locale.gar_address_service.tabs.regions" name="regions">
          <regions></regions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-container>
</template>

<script>
  import QueryBus from '@/core/application/query/service/QueryBus'
  import CommandBus from '@/core/application/command/service/CommandBus'
  import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
  import RegionsHandler from '@/services/GarAddressService/application/handler/query/RegionsHandler'
  import RegionQueryRepository from '@/services/GarAddressService/infrastructure/domain/RegionQueryRepository'

  import Regions from '@/services/GarAddressService/infrastructure/components/Regions'
  import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
  import RegionUpdateHandler from '@/services/GarAddressService/application/handler/command/RegionUpdateHandler'
  import RegionCommandRepository from '@/services/GarAddressService/infrastructure/domain/RegionCommandRepository'
  import GarTablesHandler from '@/services/GarAddressService/application/handler/query/GarTablesHandler'
  import GarTableQueryRepository from '@/services/GarAddressService/infrastructure/domain/GarTableQueryRepository'
  import GarUpdateHandler from './application/handler/command/GarUpdateHandler'
  import GarCommandRepository from './infrastructure/domain/GarCommandRepository'

  export default {
    name: 'GarAddressService',
    components: {Regions},
    data: function () {
      return {
        activeTab: 'regions',
        queryBus: new QueryBus(
          new QueryBusHandlerLocator({
            'RegionsQuery': new RegionsHandler(
              new RegionQueryRepository()
            ),
            'GarTablesQuery': new GarTablesHandler(
              new GarTableQueryRepository()
            )},
          ),
        ),
        commandBus: new CommandBus(
          new CommandBusHandlerLocator({
            'RegionUpdateCommand': new RegionUpdateHandler(
              new RegionCommandRepository()
            ),
            'GarUpdateCommand': new GarUpdateHandler(
              new GarCommandRepository()
            )
          })
        )
      }
    },
    provide () {
      return {
        getQueryBus: this.getQueryBus,
        getCommandBus: this.getCommandBus
      }
    },
    methods: {
      getQueryBus () {
        return this.queryBus
      },
      getCommandBus () {
        return this.commandBus
      }
    }
  }
</script>

<style scoped src="./GarAddressService.css">

</style>
