<template>
    <el-form-item :label="label" :label-width="titleWidth">
        <el-input-number v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="label" :size="size" :step="1" controls-position="right"></el-input-number>
    </el-form-item>
</template>

<script>
export default {
  name: 'prop-coordinate-system',

  props: ['value', 'size', 'label', 'titleWidth']
}
</script>

<style type="text/css">

</style>
