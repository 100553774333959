import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SourceQueryRepositoryInterface from '../../../domain/repository/SourceQueryRepositoryInterface'
import SourcesCountQuery from '../../query/SourcesCountQuery'

export default class SourcesCountHandler implements QueryHandlerInterface
{
    private repo: SourceQueryRepositoryInterface;

    constructor(repo: SourceQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SourcesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}