import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ListenerDeleteCommand from '@/services/LogicEditor/application/command/ListenerDeleteCommand'
import ListenerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ListenerCommandRepositoryInterface'

export default class ListenerDeleteHandler implements CommandHandlerInterface {
    private repository: ListenerCommandRepositoryInterface;

    constructor (repository: ListenerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ListenerDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
