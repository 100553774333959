import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RuleTreeElementByElementGuidQuery from '@/services/TaskEditor/application/query/RuleTreeElementByElementGuidQuery'
import RuleTreeElementQueryRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleTreeElementQueryRepositoryInterface'

export default class RuleTreeElementByElementGuidHandler implements QueryHandlerInterface {
  private repository: RuleTreeElementQueryRepositoryInterface

  constructor (repository: RuleTreeElementQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: RuleTreeElementByElementGuidQuery): Promise<any> {
    return this.repository.getByElementGuid(query.getElementGuid())
  }
}
