
import ElForm from 'element-ui/packages/form/src/form.vue'
import ElFormItem from 'element-ui/packages/form/src/form-item.vue'
import ElInput from 'element-ui/packages/input/src/input.vue'
import ElTabs from 'element-ui/packages/tabs/src/tabs.vue'
import ElTabPane from 'element-ui/packages/tabs/src/tab-pane.vue'
import ElDropdown from 'element-ui/packages/dropdown/src/dropdown.vue'
import ElDropdownMenu from 'element-ui/packages/dropdown/src/dropdown-menu.vue'
import ElDropdownItem from 'element-ui/packages/dropdown/src/dropdown-item.vue'
import ElButtonGroup from 'element-ui/packages/button/src/button-group.vue'
import ElButton from 'element-ui/packages/button/src/button.vue'
import ElRow from 'element-ui/packages/row/src/row.js'
import ElCol from 'element-ui/packages/col/src/col.js'
import SelectOptions from '@/core/infrastructure/components/SelectOptions.vue'
import Treeselect from '@bingosoftnn/vue-treeselect'
import Layer, { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'
import LayerTreeElement from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'
import LayerType from '@/services/MapEditor/domain/model/Layer/LayerType'
import Source from '@/services/MapEditor/domain/model/Source/Source'
import SourceType from '@/services/MapEditor/domain/model/Source/SourceType'
import SourceInformationPanel from '@/services/MapEditor/infrastructure/components/SourceInformationPanel/index.vue'
import ProjectCoordinateSystemsQuery from '@/services/MapEditor/application/query/ProjectCoordinateSystemsQuery'
import LayerTreeElementByGuidQuery from '@/services/MapEditor/application/query/LayerTreeElementByGuidQuery'
import StylesQuery from '@/services/MapEditor/application/query/StylesQuery'
import LayerCreateCommand from '@/services/MapEditor/application/command/LayerCreateCommand'
import LayerUpdateCommand from '@/services/MapEditor/application/command/LayerUpdateCommand'
import LayerIcon from '@/core/infrastructure/components/LayerIcon.vue'

export default {
  name: 'LayerForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'layer', 'parentNodeId', 'parentPanelId', 'layerTreeElementDto'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  data() {
    return {
      dto: null,
      source: null,
      inputValue: null,
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        source_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        srs_handling_type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        declared_coordinate_system_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      srsHandlingOptions: [{
        id: 'forced_declared',
        name: this.$locale.map_editor.layer_form.forced_declared
      },{
        id: 'reproject',
        name: this.$locale.map_editor.layer_form.reproject
      },{
        id: 'keep_native',
        name: this.$locale.map_editor.layer_form.keep_native
      }]
    }
  },
  watch: {
    source: {
      handler: function(source) {
        if (this.source) {
          let sourceType = source.getType();
          if (sourceType == SourceType.REGISTRY && this.dto.type != LayerType.VECTOR) {
            this.dto.type = LayerType.VECTOR;
          }
          if (sourceType == SourceType.XYZ || sourceType == SourceType.TILEWMS || sourceType == SourceType.TILEARCGISREST) {
            this.dto.type = LayerType.RASTER;
          }
        }
      }
    },
    dto: {
      handler: function (dto) {
        if (this.layerTreeElementDto != null && this.layerTreeElementDto.name != dto.name) {
          this.layerTreeElementDto.name = dto.name;
        }
      },
      deep: true
    },
    layerTreeElementCreated: function (location) {
      this.getQueryBus().execute(
        new LayerTreeElementByGuidQuery(
          location.replace('/layer_tree_elements/', '')
        )
      ).then((elementDto) => {
        let element = LayerTreeElement.create(elementDto);
        this.getEventBus().$emit('layerTreeElementCreated', element, elementDto, this.projectId, this.parentNodeId, this.parentPanelId);    
      });
    } 
  },
  computed: {
    layerTreeElementCreated() {
      return this.$store.getters['Layer/getLocation'];
    }
  },
  methods: {
    createTabs() {
      return this.$createElement(ElTabs, {
        props: {
          tabPosition: 'left'
        }
      }, [
        this.createInformationTab(),
        this.createSourceTab(),
        this.createStyleTab()
      ]);
    },
    createInformationTab() {
      let me = this;
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.information
        }
      }, [
        this.createForm()
      ]);
    },
    createForm() {
      return this.$createElement(ElForm, {
        props: {
          model: this.dto,
          rules: this.formRules
        },
        class: {
          "layer-form-inner-body": true,
          "custom_scrollbar": true
        },
        style: {
          "overflow-y": "auto"
        },
        ref: this.formId
      }, this.createFormItems());
    },
    createFormItems() {
      //let me = this;
      return [
        this.$createElement(ElFormItem, {
          props: {
            prop: 'name',
            label: this.$locale.main.fields.name
          }
        },[
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              value: this.dto.name
            },
            on: {
              input: (value) => {
                this.dto.name = value;
              }
            }
          },[])
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'description',
            label: this.$locale.main.fields.description
          }
        }, [
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              type: 'textarea',
              value: this.dto.description
            },
            on: {
              input: (value) => {
                this.dto.description = value;
              }
            }
          })
        ]),
        this.$createElement('fieldset', {},[
          this.$createElement('legend', {}, [
            this.$createElement('div', {
              class: {
               'el-form-item__label': true
              },
              style: {
                "width": "100%",
                "text-align": "left"
              }
            }, [
              this.$locale.map_editor.entities.coordinate_system
            ])
          ]), ...this.createCoordinateSystemFields() 
        ]),
        this.$createElement(ElFormItem, {
          props: {
            prop: 'source_id',
            label: this.$locale.map_editor.layer_form.source
          },
          class: {
            "hidden-input": true
          }
        }, [
          this.$createElement(ElInput, {
            props: {
              autocomplete: 'off',
              value: this.dto.source_id
            },
            on: {
              input: (value) => {
                this.dto.source_id = value;
              }
            }
          })
        ])
      ]
    },
    createCoordinateSystemFields() {
      let fields = [];
      let declaredCs = this.$createElement(ElRow, {
        },[
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
              props: {
                label: this.$locale.map_editor.layer_form.declared_cs,
                prop: 'declared_coordinate_system_id'
              }
            },[
              this.$createElement(Treeselect, {
                props: {
                  placeholder: this.$locale.main.fields.select,
                  noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                  loadingText: this.$locale.main.fields.loading_text,
                  noResultsText: this.$locale.main.fields.no_results_text,
                  normalizer: (node) => {return {id: node.srid, label: `${node.auth_name} (${node.srid})`}},
                  searchable: true,
                  cacheOptions: true,
                  appendToBody: true,
                  options: this.dto.declared_coordinate_system,
                  value: this.dto.declared_coordinate_system_id
                },
                on: {
                  open: async () => {
                    this.dto.declared_coordinate_system = await this.getQueryBus().execute(
                      new ProjectCoordinateSystemsQuery(this.projectGuid, null)
                    );
                  },
                  input: (id) => {
                    if (typeof id == "undefined") {
                      this.dto.declared_coordinate_system_id = null;
                    } else {
                      this.dto.declared_coordinate_system_id = id;
                    }
                  }
                }
              })
            ])            
          ]),
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
            },[
              this.$createElement(SelectOptions, {
                props: {
                  addRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system', {'parentFormId': this.formId, 'field': 'declaredCoordinateSystem'});
                  },
                  selectRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system_select', {'parentFormId': this.formId, 'field': 'declaredCoordinateSystem'});
                  }
                }
              })
            ])
          ])
      ]);
      let nativeCs = this.$createElement(ElRow, {
        },[
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
              props: {
                label: this.$locale.map_editor.layer_form.native_cs,
                prop: 'native_coordinate_system_id',
                disabled: true
              }
            },[
              this.$createElement(Treeselect, {
                props: {
                  disabled: false,
                  placeholder: this.$locale.main.fields.select,
                  noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                  loadingText: this.$locale.main.fields.loading_text,
                  noResultsText: this.$locale.main.fields.no_results_text,
                  normalizer: (node) => {return {id: node.srid, label: `${node.auth_name} (${node.srid})`}},
                  searchable: true,
                  cacheOptions: true,
                  appendToBody: true,
                  options: this.dto.native_coordinate_system,
                  value: this.dto.native_coordinate_system_id
                },
                on: {
                  open: async () => {
                    this.dto.native_coordinate_system = await this.getQueryBus().execute(
                      new ProjectCoordinateSystemsQuery(this.projectGuid, null)
                    );
                  },
                  input: (id) => {
                    if (typeof id == "undefined") {
                      this.dto.native_coordinate_system_id = null;
                    } else {
                      this.dto.native_coordinate_system_id = id;
                    }
                  }
                }
              })
            ])            
          ]),
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
          },[
            this.$createElement(ElFormItem, {
            },[
              this.$createElement(SelectOptions, {
                props: {
                  addRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system', {'parentFormId': this.formId, 'field': 'nativeCoordinateSystem'});
                  },
                  selectRecord: () => {
                    this.getEventBus().$emit('createForm', 'coordinate_system_select', {'parentFormId': this.formId, 'field': 'nativeCoordinateSystem'});
                  }
                }
              })
            ])
          ])
      ]);
      let srsHandling = this.$createElement(ElCol, {
          props: {
            span: 12
          }
        },[
          this.$createElement(ElFormItem, {
            props: {
              label: this.$locale.map_editor.layer_form.srs_handling_cs,
              prop: 'srs_handling_type'
            }
          },[
            this.$createElement(Treeselect, {
              props: {
                placeholder: this.$locale.main.fields.select,
                noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                loadingText: this.$locale.main.fields.loading_text,
                noResultsText: this.$locale.main.fields.no_results_text,
                normalizer: (node) => {return {id: node.id, label: node.name}},
                searchable: false,
                cacheOptions: true,
                appendToBody: true,
                options: this.srsHandlingOptions,
                value: this.dto.srs_handling_type,
                clearable: false,
                deleteRemoves: false,
                backspaceRemoves: false
              },
              on: {
                input: (id) => {
                  this.dto.srs_handling_type = id;
                }
              }
            })
          ])            
      ]);
      return [nativeCs, declaredCs, srsHandling];
    },
    createSourceTab() {
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.source
        }
      }, [
        this.$createElement("div", {
          class: {
            "layer-source-options": true,
            "custom_scrollbar": true
          },
          style: {
            "overflow-y": "auto"
          }
        }, [
          ...this.createSourceOptions(),
          this.createSourceInformationPanel()         
        ])
      ]);
    },
    createSourceOptions() {
      if (typeof this.layer !== 'undefined') {
        return [];
      }
      let options = [
        this.$createElement(ElDropdown, {
          props: {
            placement: "bottom-start",
            trigger: "click"
          },
          on: {
            command: (type) => {
              if (type == 'add_new') {
                this.addSource()
              } else if (type == 'select_existing') {
                this.selectSource()
              }
            }
          }
        },[
          this.$createElement(ElButton, {
            props: {
              type: "info",
              plain: true,
              icon: "el-icon-plus"
            }
          },[]),
          this.$createElement(ElDropdownMenu, {            
          },[
            this.$createElement(ElDropdownItem, {  
              props: {
                command: "add_new"
              }
            },[this.$locale.main.button.add_new]),
            this.$createElement(ElDropdownItem, {  
              props: {
                command: "select_existing"
              }
            },[this.$locale.main.button.select_existing])
          ])
        ]),
        this.$createElement(ElButton, {
            props: {
              type: "info",
              plain: true,
              icon: "el-icon-edit",
              disabled: !this.source
            },
            style: {
              "margin-left": "8px"
            },
            on: {
              click: () => {
                this.editSource()
              }
            }
        },[])
      ];

      return options;
    },
    createStyleTab() {
      return this.$createElement(ElTabPane, {
        props: {
          label: this.$locale.map_editor.layer_form.style
        }
      }, [
        ...this.createStyleOptions()
      ]);
    },
    createStyleOptions() {
      let pointStyle = [], linestringStyle = [], polygonStyle = [], labelStyle = [];
      if (this.dto.point_style_id != null) {
        for (let i in this.dto.point_style) {
          if (this.dto.point_style[i].id == this.dto.point_style_id) {
            pointStyle = this.dto.point_style[i].properties;
          }
        }
      }
      if (this.dto.linestring_style_id != null) {
        for (let i in this.dto.linestring_style) {
          if (this.dto.linestring_style[i].id == this.dto.linestring_style_id) {
            linestringStyle = this.dto.linestring_style[i].properties;
          }
        }
      }
      if (this.dto.polygon_style_id != null) {
        for (let i in this.dto.polygon_style) {
          if (this.dto.polygon_style[i].id == this.dto.polygon_style_id) {
            polygonStyle = this.dto.polygon_style[i].properties;
          }
        }
      }
      if (this.dto.label_style_id != null) {
        for (let i in this.dto.label_style) {
          if (this.dto.label_style[i].id == this.dto.label_style_id) {
            labelStyle = this.dto.label_style[i].properties;
          }
        }
      }
      return [
        this.$createElement('div', {
          class: {
            "el-tabs__header": true,
            "is-left": true
          }
        },[
          this.$createElement('div', {
            class: {
              "is-left": true
            }
          },[
            this.$createElement('div', {
              class: {
                "el-tabs__nav-scroll": true
              }
            },[
              this.$createElement('div', {
                class: {
                  "style-preview": true,
                  "selected": this.dto.icon_style_type_id == "point"
                },
                style: {
                  "margin-top": "15px"
                },
                on: {
                  click: () => {
                    if (this.dto.point_style_id != null) {
                      this.dto.icon_style_type_id = "point";
                    }
                  }
                }
              },[
                this.$createElement(LayerIcon, {
                  props: {
                    "type": "point",
                    "properties": pointStyle
                  }
                },[
                  
                ])
              ]),
              this.$createElement('div', {
                class: {
                  "style-preview": true,
                  "selected": this.dto.icon_style_type_id == "linestring"
                },
                style: {
                  "margin-top": "6px"
                },
                on: {
                  click: () => {
                    if (this.dto.linestring_style_id != null) {
                      this.dto.icon_style_type_id = "linestring";
                    }
                  }
                }
              },[
                this.$createElement(LayerIcon, {
                  props: {
                    "type": "linestring",
                    "properties": linestringStyle
                  }
                },[
                  
                ])
              ]),
              this.$createElement('div', {
                class: {
                  "style-preview": true,
                  "selected": this.dto.icon_style_type_id == "polygon"
                },
                style: {
                  "margin-top": "6px"
                },
                on: {
                  click: () => {
                    if (this.dto.polygon_style_id != null) {
                      this.dto.icon_style_type_id = "polygon";
                    }
                  }
                }
              },[
                this.$createElement(LayerIcon, {
                  props: {
                    "type": "polygon",
                    "properties": polygonStyle
                  }
                },[
                  
                ])
              ]),
              ,
              this.$createElement('div', {
                class: {
                  "style-preview": true,
                  "selected": this.dto.icon_style_type_id == "label"
                },
                style: {
                  "margin-top": "6px",
                  "display": "none"
                },
                on: {
                  click: () => {
                    if (this.dto.label_style_id != null) {
                      this.dto.icon_style_type_id = "label";
                    }
                  }
                }
              },[
                this.$createElement(LayerIcon, {
                  props: {
                    "type": "label",
                    "properties": labelStyle
                  }
                },[
                  
                ])
              ])
            ])
          ])
        ]),
        this.$createElement('div', {
          class: {
            "el-tabs__content": true
          }
        },[
          this.$createElement('div', {
            class: {
              "el-tab-pane": true
            },
            props: {
              role: "tabpanel",
              id: "pane-0",
              "aria-labelledby": "tab-0"
            }
          },[
            this.createStyleOption('point'),
            this.createStyleOption('linestring'),
            this.createStyleOption('polygon'),
            this.createStyleOption('label')
          ])
        ])
      ];
    },
    createStyleOption(styleOption) {
      let typeKey = styleOption + "_style_id";
      let optionsKey = styleOption + "_style";
      return this.$createElement(ElRow, {
        },[
          this.$createElement(ElForm, {}, [
          this.$createElement(ElCol, {
            props: {
              span: 12
            }
            },[
              this.$createElement(ElFormItem, {
                props: {
                  label: this.$locale.map_editor.geometry_types[styleOption],
                }
              },[
                this.$createElement(Treeselect, {
                  props: {
                    placeholder: this.$locale.main.fields.select,
                    noOptionsText: this.isEntityLoading ? this.$locale.main.fields.loading_text : this.$locale.main.fields.no_options_text,
                    loadingText: this.$locale.main.fields.loading_text,
                    noResultsText: this.$locale.main.fields.no_results_text,
                    normalizer: (node) => {return {id: node.id, label: node.name}},
                    searchable: false,
                    cacheOptions: false,
                    appendToBody: true,
                    options: this.dto[optionsKey],
                    value: this.dto[typeKey]                    
                  },
                  on: {
                    open: async () => {
                      this.dto[optionsKey] = await this.getQueryBus().execute(
                        new StylesQuery({"style_type_id": styleOption})
                      );
                    },
                    input: (id) => {
                      if (typeof id == "undefined") {
                        if (this.dto.icon_style_type_id == styleOption) {
                          this.dto.icon_style_type_id = null;
                        }
                        this.dto[typeKey] = null;
                      } else {
                        this.dto[typeKey] = id;
                      }
                    }
                  }
                })
              ])            
            ]
          ),
          this.$createElement(ElCol, {
            props:  {
              span: 12
            }
            },[
            this.$createElement(ElFormItem, {
              },[
                this.$createElement(SelectOptions, {
                  props: {
                    addRecord: () => {
                      this.getEventBus().$emit('createForm', styleOption, {'parentFormId': this.formId, 'field': styleOption, 'type': styleOption});
                    },
                    selectRecord: () => {
                      this.getEventBus().$emit('createForm', 'style_select', {'parentFormId': this.formId, 'field': styleOption, 'type': styleOption});
                    }
                  }
                })
              ]
            )
            ]
          )
        ])
      ]);
    },
    addSource() {
      this.getEventBus().$emit('createForm', 'source', {'parentFormId': this.formId});
    },
    editSource() {
      this.getEventBus().$emit('createForm', 'source_edit', {'parentFormId': this.formId, 'source': this.source});
    },
    selectSource() {
      this.getEventBus().$emit('createForm', 'source_select', {'parentFormId': this.formId});
    },
    sourceCreated(source, formId) {
      this.source = null;
      if (this.formId == formId) {
        this.source = source;
        this.dto.source_id = source.getId();
      }
    },
    coordinateSystemCreated(coordinateSystem, formId, field) {
      if (this.formId == formId) {
        let value = coordinateSystem.getSrid();
        let item = [{
          "srid": value,
          "auth_name": coordinateSystem.getName()
        }];        
        if (field == 'nativeCoordinateSystem') {
          this.dto.native_coordinate_system = item;
          this.dto.native_coordinate_system_id = value;
        } else if (field == 'declaredCoordinateSystem') {
          this.dto.declared_coordinate_system = item;
          this.dto.declared_coordinate_system_id = value;
        }
      }
    },
    styleCreated(style, formId, type) {
      if (this.formId == formId) {
        let typeKey = type + "_style_id";
        let optionsKey = type + "_style";
        this.dto[optionsKey] = [{
          "id": style.getId(),
          "name": style.getName(),
          "value": style.getProperties()
        }];
        this.dto[typeKey] = style.getId();
      }
    },
    createSourceInformationPanel() {
      if (this.source) {
        return this.$createElement(SourceInformationPanel, {
          props: {
            source: this.source
          }
        });
      }
    },
    setReactiveProperties() {
      this.$set(this.dto, 'name', this.dto.name || "");
      this.$set(this.dto, 'description', this.dto.description || "");
      this.$set(this.dto, 'source_id', this.dto.source_id || null);
      this.$set(this.dto, 'srs_handling_type', this.dto.srs_handling_type);
      this.$set(this.dto, 'native_coordinate_system_id', this.dto.native_coordinate_system_id || null);
      this.$set(this.dto, 'declared_coordinate_system_id', this.dto.declared_coordinate_system_id || null);
      this.$set(this.dto, 'native_coordinate_system', this.dto.native_coordinate_system || []);
      this.$set(this.dto, 'declared_coordinate_system', this.dto.declared_coordinate_system || []);
      this.$set(this.dto, 'point_style_id', this.dto.point_style_id || null);
      this.$set(this.dto, 'icon_style_type_id', this.dto.icon_style_type_id || null);
      this.$set(this.dto, 'linestring_style_id', this.dto.linestring_style_id || null);
      this.$set(this.dto, 'polygon_style_id', this.dto.polygon_style_id || null);
      this.$set(this.dto, 'point_style', this.dto.point_style || []);
      this.$set(this.dto, 'linestring_style', this.dto.linestring_style || []);
      this.$set(this.dto, 'polygon_style', this.dto.polygon_style || []);
    },
    submit(callback) {
      let form = this.$refs[this.formId];
      if (!this.dto.name || this.dto.name == "") {
        this.$message({
          message: this.$locale.map_editor.messages.name_required,
          type: 'warning'
        });
        return;
      }
      if (!this.dto.source_id) {
        this.$message({
          message: this.$locale.map_editor.messages.source_required,
          type: 'warning'
        });
        return;
      }
      if (!this.styleIsValid()) {
        this.$message({
          message: this.$locale.map_editor.messages.style_required,
          type: 'warning'
        });
        return;
      }
      if (!this.dto.declared_coordinate_system_id) {
        this.$message({
          message: this.$locale.map_editor.messages.coordinate_system_required,
          type: 'warning'
        });
        return;
      }
      form.validate((valid, invalidFields) => {
        if (valid) {
          let layer = Layer.create(this.dto);
          if (typeof this.source !== 'undefined' && typeof layer.getId() !== "undefined") {
            this.getCommandBus().execute(
              new LayerUpdateCommand(
                layer.getGuid(),
                layer.getName(),
                layer.getDescription(),
                layer.getSourceId(),
                layer.getLayerType(),
                layer.getSrsHandling(),
                layer.getDeclaredCoordinateSystemId(),
                layer.getNativeCoordinateSystemId(),
                layer.getPointStyleId(),
                layer.getLinestringStyleId(),
                layer.getPolygonStyleId(),
                layer.getLabelStyleId(),
                layer.getIconStyleTypeId(),
                layer.getConstraintFilter(),
                layer.getIsClustered(),
                layer.getNativeBboxXmin(),
                layer.getNativeBboxYmin(),
                layer.getNativeBboxXmax(),
                layer.getNativeBboxYmax(),
                layer.getLatlonBboxXmin(),
                layer.getLatlonBboxYmin(),
                layer.getLatlonBboxXmax(),
                layer.getLatlonBboxYmax(),
                layer.getProperties(),
                this.projectId,
                this.parentNodeId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback();
              }
              if (typeof this.layerUpdatedCallback == 'function') {
                this.layerUpdatedCallback(this.layer);
              }
              this.getEventBus().$emit('layerUpdated', this.layer, this.projectId, this.parentNodeId, this.parentPanelId);
            });
          } else {
            this.getCommandBus().execute(
              new LayerCreateCommand(                
                layer.getName(),
                layer.getDescription(),
                layer.getSourceId(),
                layer.getLayerType(),
                layer.getSrsHandling(),
                layer.getDeclaredCoordinateSystemId(),
                layer.getNativeCoordinateSystemId(),
                layer.getPointStyleId(),
                layer.getLinestringStyleId(),
                layer.getPolygonStyleId(),
                layer.getLabelStyleId(),
                layer.getIconStyleTypeId(),
                layer.getConstraintFilter(),
                layer.getIsClustered(),
                layer.getNativeBboxXmin(),
                layer.getNativeBboxYmin(),
                layer.getNativeBboxXmax(),
                layer.getNativeBboxYmax(),
                layer.getLatlonBboxXmin(),
                layer.getLatlonBboxYmin(),
                layer.getLatlonBboxXmax(),
                layer.getLatlonBboxYmax(),
                layer.getProperties(),
                this.projectId,
                this.parentNodeId
              )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }             
            });
          }
        }
      });
    },
    styleIsValid() {
      let styleIsSet = this.dto.point_style_id != null || this.dto.linestring_style_id != null || this.dto.polygon_style_id != null;
      if (this.source.getType() == SourceType.REGISTRY && !styleIsSet) {
        return false;
      }
      return true;
    }
  },
  render (createElement) {
    return createElement("div", {
      class: {
          "layer-form": true
      }
    }, [this.dto ? this.createTabs() : null]);
  },
  mounted() {    
    if (typeof this.layer !== 'undefined') {
      this.dto = new LayerDTO({
        id: this.layer.getId(),
        guid: this.layer.getGuid(),
        name: this.layer.getName(),
        description: this.layer.getDescription(),
        source_id: this.layer.getSourceId(),
        type: this.layer.getType(),
        projection_type: this.layer.getProjectionType(),
        srs_handling_type: this.layer.getSrsHandling(),
        declared_coordinate_system_id: this.layer.getDeclaredCoordinateSystemId(),
        native_coordinate_system_id: this.layer.getNativeCoordinateSystemId(),
        declared_coordinate_system: this.layer.getDeclaredCoordinateSystem(),
        native_coordinate_system: this.layer.getNativeCoordinateSystem(),
        point_style_id: this.layer.getPointStyleId(),
        linestring_style_id: this.layer.getLinestringStyleId(),
        polygon_style_id: this.layer.getPolygonStyleId(),
        label_style_id: this.layer.getLabelStyleId(),
        point_style: this.layer.getPointStyle(),
        linestring_style: this.layer.getLinestringStyle(),
        polygon_style: this.layer.getPolygonStyle(),
        label_style: this.layer.getLabelStyle(),
        icon_style_type_id: this.layer.getIconStyleTypeId(),
        constraint_filter: this.layer.getConstraintFilter(),
        is_clustered: this.layer.getIsClustered(),
        native_bbox_xmin: this.layer.getNativeBboxXmin(),
        native_bbox_ymin: this.layer.getNativeBboxYmin(),
        native_bbox_xmax: this.layer.getNativeBboxXmax(),
        native_bbox_ymax: this.layer.getNativeBboxYmax(),
        latlon_bbox_xmin: this.layer.getLatlonBboxXmin(),
        latlon_bbox_ymin: this.layer.getLatlonBboxYmin(),
        latlon_bbox_xmax: this.layer.getLatlonBboxXmax(),
        latlon_bbox_ymax: this.layer.getLatlonBboxYmax(),
        properties: this.layer.getProperties(),
        project_id: this.projectId,
        parent_id: this.parentNodeId
      });
      this.source = this.layer.getSource();
    } else {
      this.dto = new LayerDTO({project_id: this.projectId, parent_id: this.parentNodeId});
    }
    this.setReactiveProperties();
    this.getEventBus().$on('sourceCreated', this.sourceCreated);
    this.getEventBus().$on('sourceSelected', this.sourceCreated);
    this.getEventBus().$on('coordinateSystemCreated', this.coordinateSystemCreated);
    this.getEventBus().$on('coordinateSystemSelected', this.coordinateSystemCreated);
    this.getEventBus().$on('styleCreated', this.styleCreated);
    this.getEventBus().$on('styleSelected', this.styleCreated);
  }
}
