<template>
  <el-form-item>
    <el-checkbox v-model="isActive">
      {{ label }}
    </el-checkbox>
    <el-tree
      v-if="isActive"
      v-loading="loading"
      style="height: 200px; overflow-y:scroll; width: 100%"
      ref="tree"
      :props="props"
      :data="treeData"
      show-checkbox
      check-strictly
      node-key="id"
      @check-change="handleCheckChange"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }} (id: {{data.id}}, <b>{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}</b>)</span>
      </span>
    </el-tree>
  </el-form-item>
</template>

<script>
export default {
  name: 'card_field',
  props: ['value', 'label', 'entity-id'],
  data () {
    return {
      isActive: false,
      loading: false,
      treeData: [],
      props: {
        isLeaf: 'leaf',
        label: 'name'
      }
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.loadTree()
      } else {
        this.$emit('input', null)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.isActive = true
    }
  },
  methods: {
    handleCheckChange (node, checked) {
      if (checked) {
        this.$refs.tree.setCheckedKeys([node.id])
        this.$emit('input', parseInt(node.id))
      } else {
        this.$emit('input', null)
      }
    },
    async loadTree () {
      this.loading = true
      const registryId = this.entityId
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
        .finally(() => {
          this.loading = false
        })
      this.treeData = data.data.data
      if (this.value) {
        this.$refs.tree.setCheckedKeys([this.value])
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
