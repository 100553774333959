import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ThemeUpdateCommand from '@/services/CssEditor/application/command/ThemeUpdateCommand'
import ThemeCommandRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeCommandRepositoryInterface'

export default class ThemeUpdateCommandHandler implements CommandHandlerInterface {
  private repository: ThemeCommandRepositoryInterface;

  constructor (repository: ThemeCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ThemeUpdateCommand): Promise<void> {
    return this.repository.update(command.getDto())
  }
}
