import QueryInterface from '@/core/application/query/QueryInterface'

export default class RegistryFieldsQuery implements QueryInterface {
  private readonly registryId: number
  constructor (registryId: number) {
    this.registryId = registryId
  }

  getRegistryId (): number {
    return this.registryId
  }

  getClassName (): string {
    return 'RegistryFieldsQuery'
  }
}
