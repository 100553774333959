import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerGroupCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerGroupCommandRepositoryInterface'
import LayerGroupCreateCommand from '@/services/MapEditor/application/command/LayerGroupCreateCommand'
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export default class LayerGroupCreateHandler implements CommandHandlerInterface
{
    private repo: LayerGroupCommandRepositoryInterface;

    constructor(repo: LayerGroupCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerGroupCreateCommand): Promise<void>
    {
        let item: LayerGroupDTO = {
            name: command.getName(),
            project_id: command.getProjectId(),
            parent_id: command.getParentId()
        };
        return this.repo.insert(item);
    }
}