import QueryInterface from '@/core/application/query/QueryInterface'

export default class ApprovalStagesByApprovalIdQuery implements QueryInterface {
  private approvalId: number
  private payload: string[][] | object

  constructor (approvalId: number, payload: string[][] | object) {
    this.approvalId = approvalId
    this.payload = payload
  }

  getClassName (): string {
    return 'ApprovalStagesByApprovalIdQuery'
  }

  getApprovalId (): number {
    return this.approvalId
  }

  getPayload (): string[][] | object {
    return this.payload
  }
}
