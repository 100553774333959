<template>
  <el-dialog width="70%" title="Лог импорта" :visible.sync="show" :before-close="close" :border="true" :close-on-click-modal="false">
    <el-table :height="500" :data="grid.data">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div v-if="scope.row.has_errors && scope.row.detail.length > 0">
            <div v-for="(item, index) in scope.row.detail" class="detail-box" :key="index">
              <p><strong>{{ $locale.etl_editor.log.detail.row_number }}</strong>: {{ item.row_number || $locale.etl_editor.log.not_data }}</p>
              <p><strong>{{ $locale.etl_editor.log.detail.incorrect_fields }}</strong>: {{ item.incorrect_fields || $locale.etl_editor.log.not_data }}</p>
              <p><strong>{{ $locale.etl_editor.log.detail.required_fields }}</strong>: {{ item.required_fields || $locale.etl_editor.log.not_data }}</p>
              <p><strong>{{ $locale.etl_editor.log.detail.exception }}</strong>: {{ item.exception || $locale.etl_editor.log.not_data }}</p>
            </div>
          </div>
          <div v-else>
            <div class="detail-box">
              <p>{{ $locale.etl_editor.log.detail.not_errors }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="has_errors" :label="$locale.etl_editor.log.table.has_errors" width="110">
        <template slot-scope="scope">
          {{ scope.row.has_errors ? $locale.etl_editor.log.table.error_yes : $locale.etl_editor.log.table.error_no }}
        </template>
      </el-table-column>
      <el-table-column property="task" :label="$locale.etl_editor.log.table.task" width="300"></el-table-column>
      <el-table-column property="user" :label="$locale.etl_editor.log.table.user" width="300"></el-table-column>
      <el-table-column property="load_date" :label="$locale.etl_editor.log.table.load_date" width="200"></el-table-column>
      <el-table-column property="file_name" :label="$locale.etl_editor.log.table.file_name" width="200"></el-table-column>
      <el-table-column property="inserted" :label="$locale.etl_editor.log.table.inserted" width="100"></el-table-column>
      <el-table-column property="updated" :label="$locale.etl_editor.log.table.updated" width="100"></el-table-column>
      <el-table-column property="not_loaded" :label="$locale.etl_editor.log.table.not_loaded" width="120"></el-table-column>
    </el-table>
    <el-pagination
      @current-change="pageChange"
      :page-size="grid.pagination.limit"
      layout="total, prev, pager, next"
      style="background-color: #fff;"
      :total="grid.count">
    </el-pagination>
  </el-dialog>
</template>

<script>
export default {
  name: 'ImportLogDialog',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: function () {
      }
    }
  },

  data () {
    return {
      currentTaskId: null,
      grid: {
        count: 0,
        data: [],
        pagination: {
          current: 0,
          limit: 30
        }
      },
      tabs: []
    }
  },

  methods: {
    load (taskId) {
      this.currentTaskId = taskId

      this.$http.get(`${this.$config.api}/etleditor/import/log?*[func]=count&task_id=${taskId}`).then((response) => {
        this.grid.count = response.data.length ? response.data[0].count : 0
      })
      this.$http
        .get(`${this.$config.api}/etleditor/import/log?task_id=${taskId}&offset=${this.grid.pagination.current}&limit=${this.grid.pagination.limit}&order=load_date:desc`)
        .then((response) => {
          this.grid.data = []
          this.grid.data.push(...response.data)

          this.grid.data.forEach((item) => {
            item.detail = []
            if (item.has_errors) {
              this.$http
                .get(`${this.$config.api}/etleditor/import/log/rows/${item.id}`)
                .then((res) => {
                  item.detail = res.data
                })
            }
          })
        })
    },

    pageChange (val) {
      val--
      this.grid.pagination.current = (val * this.grid.pagination.limit)

      this.load(this.currentTaskId)
    }
  }
}
</script>

<style type="text/css">
  .detail-box {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(235, 238, 245);
  }

  .detail-box p {
    margin: 5px 0;
  }
</style>
