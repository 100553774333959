import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TaskRegistryEntityQueryRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityQueryRepositoryInterface'
import TaskRegistryEntityByPropertyQuery
  from '@/services/TaskEditor/application/query/TaskRegistryEntityByPropertyQuery'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export default class TaskRegistryEntityByPropertyHandler implements QueryHandlerInterface {
  private repository: TaskRegistryEntityQueryRepositoryInterface;

  constructor (repository: TaskRegistryEntityQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TaskRegistryEntityByPropertyQuery): Promise<TaskRegistryEntity> {
    return this.repository.getByProperty(query.getProperty())
  }
}
