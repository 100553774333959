import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LogicGroupCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId: Nullable<number>
  ) {
    this.name = name
    this.description = description
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'LogicGroupCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
