
import Vue from 'vue'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'

import ReleasesPanel from '@/services/ReleaseEditor/infrastructure/components/ReleasesPanel/index.vue'
import ServicesPanel from '@/services/ReleaseEditor/infrastructure/components/ServicesPanel/index.vue'
import DeploymentsPanel from '@/services/ReleaseEditor/infrastructure/components/DeploymentsPanel/index.vue'

import ReleasesHandler from '@/services/ReleaseEditor/application/handler/query/ReleasesHandler'
import ReleasesCountHandler from '@/services/ReleaseEditor/application/handler/query/ReleasesCountHandler'
import ReleaseCreateHandler from '@/services/ReleaseEditor/application/handler/command/ReleaseCreateHandler'
import ReleaseUpdateHandler from '@/services/ReleaseEditor/application/handler/command/ReleaseUpdateHandler'
import ReleaseDeleteHandler from '@/services/ReleaseEditor/application/handler/command/ReleaseDeleteHandler'

import ServicesHandler from '@/services/ReleaseEditor/application/handler/query/ServicesHandler'

import BindServicesHandler from '@/services/ReleaseEditor/application/handler/query/BindServicesHandler'
import BindServiceCreateHandler from '@/services/ReleaseEditor/application/handler/command/BindServiceCreateHandler'
import BindServiceDeleteHandler from '@/services/ReleaseEditor/application/handler/command/BindServiceDeleteHandler'

import DeploymentsHandler from '@/services/ReleaseEditor/application/handler/query/DeploymentsHandler'

import ReleaseQueryRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/ReleaseQueryRepository'
import ReleaseCommandRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/ReleaseCommandRepository'
import ServiceQueryRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/ServiceQueryRepository'
import BindServiceQueryRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/BindServiceQueryRepository'
import BindServiceCommandRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/BindServiceCommandRepository'
import DeploymentQueryRepository from '@/services/ReleaseEditor/infrastructure/domain/repository/DeploymentQueryRepository'



export default {
  name: 'ReleaseEditor',
  props: {
    hideCompletePanel: {
      type: Boolean,
      default: false
    },
    hideToolsContainer: {
      type: Boolean,
      default: false
    },
    hideDeploymentsPanel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ReleasesPanel, ServicesPanel, DeploymentsPanel
  },
  data() {
    return {
      activeEntity: 'releases',
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'ReleaseCreateCommand': new ReleaseCreateHandler(
            new ReleaseCommandRepository()
          ),
          'ReleaseUpdateCommand': new ReleaseUpdateHandler(
            new ReleaseCommandRepository()
          ),
          'ReleaseDeleteCommand': new ReleaseDeleteHandler(
            new ReleaseCommandRepository()
          ),
          'BindServiceCreateCommand': new BindServiceCreateHandler(
            new BindServiceCommandRepository()
          ),
          'BindServiceDeleteCommand': new BindServiceDeleteHandler(
            new BindServiceCommandRepository()
          ),
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ReleasesQuery': new ReleasesHandler(
            new ReleaseQueryRepository()
          ),
          'ReleasesCountQuery': new ReleasesCountHandler(
            new ReleaseQueryRepository()
          ),
          'ServicesQuery': new ServicesHandler(
            new ServiceQueryRepository()
          ),
          'BindServicesQuery': new BindServicesHandler(
            new BindServiceQueryRepository()
          ),
          'DeploymentsQuery': new DeploymentsHandler(
            new DeploymentQueryRepository()
          )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    }
  },
  mounted() {

  }
}
