import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import CreateRequestParameterCommand from '@/services/ApiService/application/command/CreateRequestParameterCommand'

export default class CreateRequestParameterHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CreateRequestParameterCommand): Promise<void> {
    return this.repository.createRequestParameter(command.getDto(), command.getRequestGuid())
  }
}
