import LoaderQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LoaderTypePropertiesQuery from '@/services/EtlEditor/application/query/LoaderTypePropertiesQuery'

export default class LoaderTypePropertiesHandler implements QueryHandlerInterface {
  private repository: LoaderQueryRepositoryInterface

  constructor (repository: LoaderQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: LoaderTypePropertiesQuery): Promise<any> {
    return this.repository.getTypeProperties(query.getType())
  }
}
