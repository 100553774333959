import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'
import ReleaseType from './ReleaseType'

export class ReleaseDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    version?: Nullable<string>;
    type?: Nullable<string>;
    start_date?: Nullable<string>;
    complete_date?: Nullable<string>;
    is_complete?: Nullable<boolean>;

    constructor({ id, guid, name, description, version, type, start_date, complete_date, is_complete}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>, description?: Nullable<string>, version?: Nullable<string>, type?: Nullable<string>, start_date?: Nullable<string>, complete_date?: Nullable<string>, is_complete?: Nullable<boolean>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.version = version;
        this.type = type;
        this.start_date = start_date;
        this.complete_date = complete_date;
        this.is_complete = is_complete;
    }
}

export default class Release extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: Nullable<string>;
    private version: Nullable<string>;
    private type: ReleaseType;
    private start_date: string;
    private complete_date: Nullable<string>;
    private is_complete: boolean;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: Nullable<string>,
        version: Nullable<string>,
        type: ReleaseType,
        start_date: string,
        complete_date: Nullable<string>,
        is_complete: boolean
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.version = version;
        this.type = type;
        this.start_date = start_date;
        this.complete_date = complete_date;
        this.is_complete = is_complete;
    }

    static create(dto: ReleaseDTO): Release
    {
        return new Release(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.version,
            ReleaseType[dto.type.toUpperCase()],
            dto.start_date,
            dto.complete_date,
            dto.is_complete
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setDescription(description: string): void
    {
        this.description = description;
    }

    getDescription(): string
    {
        return this.description;
    }

    setVersion(version: Nullable<string>): void
    {
        this.version = version;
    }

    getVersion(): Nullable<string>
    {
        return this.version;
    }

    setType(type: ReleaseType): void
    {
        this.type = type;
    }

    getType(): ReleaseType
    {
        return this.type;
    }

    setStartDate(start_date: string): void
    {
        this.start_date = start_date;
    }

    getStartDate(): string
    {
        return this.start_date;
    }

    setCompleteDate(complete_date: Nullable<string>): void
    {
        this.complete_date = complete_date;
    }

    getCompleteDate(): Nullable<string>
    {
        return this.complete_date;
    }

    setIsComplete(is_complete: boolean): void
    {
        this.is_complete = is_complete;
    }

    getIsComplete(): boolean
    {
        return this.is_complete;
    }

}
