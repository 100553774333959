import TemplateCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TemplateCommandRepositoryInterface'
import store from '@/store'
import { CreateTemplate, UpdateTemplate, DeleteTemplate } from '@/services/EtlEditor/infrastructure/store/modules/Template/action-types'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'

export default class TemplateCommandRepository implements TemplateCommandRepositoryInterface {
  insert (item: TemplateDTO): Promise<void> {
    return store.dispatch(new CreateTemplate(item))
  }

  update (item: TemplateDTO): Promise<void> {
    return store.dispatch(new UpdateTemplate(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteTemplate(guid))
  }
}
