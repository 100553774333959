
import Vue from 'vue'

export default Vue.extend({
  name: 'ColorPreview',

  props: {
    variables: {
      type: Object,
      default () {
        return {
        }
      }
    }
  },

  computed: {
    colorPrimary () {
      return {
        style: {
          'background-color': this.variables['$--color-primary'] || '#409EFF'
        },
        value: this.variables['$--color-primary'] || '#409EFF'
      }
    },

    colorSuccess () {
      return {
        style: {
          'background-color': this.variables['$--color-success'] || '#67C23A'
        },
        value: this.variables['$--color-success'] || '#67C23A'
      }
    },

    colorWarning () {
      return {
        style: {
          'background-color': this.variables['$--color-warning'] || '#E6A23C'
        },
        value: this.variables['$--color-warning'] || '#E6A23C'
      }
    },

    colorDanger () {
      return {
        style: {
          'background-color': this.variables['$--color-danger'] || '#F56C6C'
        },
        value: this.variables['$--color-danger'] || '#F56C6C'
      }
    },

    colorInfo () {
      return {
        style: {
          'background-color': this.variables['$--color-info'] || '#909399'
        },
        value: this.variables['$--color-info'] || '#909399'
      }
    },

    textPrimary () {
      const value = this.variables['$--color-text-primary'] || '#303133'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    textRegular () {
      const value = this.variables['$--color-text-regular'] || '#606266'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    textSecondary () {
      const value = this.variables['$--color-text-secondary'] || '#909399'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    textPlaceholder () {
      const value = this.variables['$--color-text-placeholder'] || '#C0C4CC'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    borderBase () {
      const value = this.variables['$--border-color-base'] || '#DCDFE6'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    borderLight () {
      const value = this.variables['$--border-color-light'] || '#E4E7ED'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    borderLighter () {
      const value = this.variables['$--border-color-lighter'] || '#EBEEF5'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    borderExtraLight () {
      const value = this.variables['$--border-color-extra-light'] || '#F2F6FC'

      return {
        style: {
          'background-color': value
        },
        value: value
      }
    },

    bgColor () {
      const value = this.variables['$--background-color-base'] || 'F5F7FA'

      return {
        style: value !== 'transparent'
          ? {
            'background-color': value,
            'color': '#000000'
          }
          : {
            'color': '#000000'
          },
        value: value,
        classes: {
          'bg-transparent': value === 'transparent'
        }
      }
    },

    bgColorB () {
      const value = this.variables['$--color-black'] || '#000000'

      return {
        style: value !== 'transparent'
          ? {
            'background-color': value,
            'color': '#ffffff'
          }
          : {
            'color': '#ffffff'
          },
        value: value,
        classes: {
          'bg-transparent': value === 'transparent'
        }
      }
    },

    bgColorW () {
      const value = this.variables['$--color-white'] || '#FFFFFF'

      return {
        style: value !== 'transparent'
          ? {
            'background-color': value,
            'color': '#000000'
          }
          : {
            'color': '#000000'
          },
        value: value,
        classes: {
          'bg-transparent': value === 'transparent'
        }
      }
    }
  }
})
