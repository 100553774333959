<template>
  <codemirror :value="localValue" :options="options"></codemirror>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ScssVariableViewer',

  props: {
    value: Object
  },

  computed: {
    localValue () {
      const value = this.value || {}

      let result = ''

      for (const key of Object.keys(value)) {
        result += key + ': ' + value[key] + ';\n'
      }

      return result
    }
  },

  data () {
    return {
      options: {
        tabSize: 2,
        mode: 'text/x-scss',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true,
        readonly: true
      }
    }
  }
})
</script>
