import CommandInterface from '@/core/application/command/CommandInterface'

export default class GroupDeleteCommand implements CommandInterface {
  private id: number

  constructor (id: number) {
    this.id = id
  }

  getClassName (): string {
    return 'GroupDeleteCommand'
  }

  getId (): number {
    return this.id
  }
}
