import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ExportStructureQuery from '@/services/ApiService/application/query/ExportStructureQuery'

export default class ExportStructureHandler implements QueryHandlerInterface {
  private repo: RequestRepositoryInterface
  constructor (repo: RequestRepositoryInterface) {
    this.repo = repo
  }

  execute (query: ExportStructureQuery): Promise<any> {
    return this.repo.exportStructure(query.getId())
  }
}
