import ConstraintQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import QueryInterface from '@/core/application/query/QueryInterface'

export default class ConstraintTypesHandler implements QueryHandlerInterface {
  private repository: ConstraintQueryRepositoryInterface

  constructor (repository: ConstraintQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: QueryInterface): Promise<any> {
    return this.repository.getConstraintTypes()
  }
}
