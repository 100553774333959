import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import LayerQueryRepositoryInterface from '@/services/MapEditor/domain/repository/LayerQueryRepositoryInterface'
import store from '@/store'
import { GetLayerByGuid, GetLayers } from '@/services/MapEditor/infrastructure/store/modules/Layer/action-types'
import { GetProjectLayers } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class LayerQueryRepository extends AbstractQueryRepository implements LayerQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetLayers(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetLayerByGuid(guid));
    }

    getByJoinTable(projectGuid: string): Promise<any>
    {
        return store.dispatch(new GetProjectLayers(projectGuid));
    }
}