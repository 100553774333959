import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SnapshotRepositoryInterface from '../../../domain/repository/SnapshotRepositoryInterface'
import SnapshotsTablesFilterValuesQuery from '@/services/SyncService/application/query/SnapshotsTablesFilterValuesQuery'

export default class SnapshotsTablesFilterValuesHandler implements QueryHandlerInterface {
  private repo: SnapshotRepositoryInterface

  constructor (repo: SnapshotRepositoryInterface) {
    this.repo = repo
  }

  execute (query: SnapshotsTablesFilterValuesQuery): Promise<any> {
    return this.repo.getTablesFilter(query.getPayload())
  }
}
