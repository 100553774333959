import ThemeCommandRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeCommandRepositoryInterface'
import store from '@/store'
import { CreateTheme, UpdateTheme, DeleteTheme } from '@/services/CssEditor/infrastructure/store/modules/Theme/action-types'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'

export default class ThemeCommandRepository implements ThemeCommandRepositoryInterface {
  insert (item: ThemeDTO): Promise<void> {
    return store.dispatch(new CreateTheme(item))
  }

  update (item: ThemeDTO): Promise<void> {
    return store.dispatch(new UpdateTheme(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteTheme(guid))
  }
}
