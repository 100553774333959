import QueryInterface from '@/core/application/query/QueryInterface'

export default class CommandEventTypePropertiesQuery implements QueryInterface {
  private type: string

  constructor (type: string) {
    this.type = type
  }

  getClassName (): string {
    return 'CommandEventTypePropertiesQuery'
  }

  getType (): string {
    return this.type
  }
}
