import CommandInterface from '@/core/application/command/CommandInterface'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export default class ApprovalStageCreateCommand implements CommandInterface {
  constructor (private readonly dto: ApprovalStageDTO) {}

  getClassName (): string {
    return 'ApprovalStageCreateCommand'
  }

  getDTO (): ApprovalStageDTO {
    return this.dto
  }
}
