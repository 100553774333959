import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import GeoJsonQueryRepositoryInterface from '../../../domain/repository/GeoJsonQueryRepositoryInterface'
import LayerGeoJsonQuery from '../../query/LayerGeoJsonQuery'

export default class LayerGeoJsonHandler implements QueryHandlerInterface
{
    private repo: GeoJsonQueryRepositoryInterface;

    constructor(repo: GeoJsonQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: LayerGeoJsonQuery): Promise<any>
    {
        return this.repo.getLayerGeoJson(query.getLayerId(), query.getFilter());
    }
}