import ProjectTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectTreeElementCommandRepositoryInterface'
import { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'
import store from '@/store'
import { AddProjectTreeProjectElement, AddProjectTreeGroupElement, DeleteProjectTreeElement, PutProjectTreeProjectElement, UpdateParent } from '@/services/MapEditor/infrastructure/store/modules/ProjectTreeElement/action-types'

export default class ProjectTreeElementCommandRepository implements ProjectTreeElementCommandRepositoryInterface
{
    insertProject(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeProjectElement(item));
    }

    insertGroup(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new AddProjectTreeGroupElement(item));
    }

    putProject(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new PutProjectTreeProjectElement(item));
    }

    updateParent(item: ProjectTreeElementDTO): Promise<void>
    {
        return store.dispatch(new UpdateParent(item));
    }

    delete(guid: string): Promise<void>
    {
        return store.dispatch(new DeleteProjectTreeElement(guid));
    }
}
