<template>
  <div style="height: 100%;">
    <el-form ref="request_form" size="mini" :model="localModel" :rules="rules">
      <span class="label">{{ label }}</span>
      <el-form-item class="form_element" prop="name">
        <span>{{ $t('api_service.request.name') }}</span>
        <el-input v-model="localModel.name"></el-input>
      </el-form-item>
      <el-form-item class="form_element" v-if="requestType === 'project'">
        <span>{{ $t('api_service.request.connections') }}</span>
        <editor-select
          ref="connections_select"
          :value="localModel.connections"
          @change="$set(localModel ,'connections', $event)"
          :options="{
            multiple: true,
            clearable: true,
            options: connections
          }"
        ></editor-select>
      </el-form-item>
      <div v-if="requestType === 'request'">
        <el-form-item class="form_element" prop="request_type">
          <span>{{ $t('api_service.request.type') }}</span>
          <editor-select
            ref="types_select"
            :value="localModel.request_type"
            @change="$set(localModel ,'request_type', $event)"
            :options="{
            multiple: false,
            clearable: true,
            options: types
          }"
          ></editor-select>
        </el-form-item>
        <el-form-item class="form_element" prop="protocol_type">
          <span>{{ $t('api_service.request.protocol') }}</span>
          <editor-select
            ref="protocol_select"
            :value="localModel.protocol_type"
            @change="$set(localModel ,'protocol_type', $event)"
            :options="{
            multiple: false,
            clearable: true,
            options: protocols
          }"
          ></editor-select>
        </el-form-item>
        <el-form-item class="form_element" prop="method_type_id">
          <span>{{ $t('api_service.request.method') }}</span>
          <editor-select
            ref="method_select"
            :value="localModel.method_type_id"
            @change="$set(localModel ,'method_type_id', $event)"
            :options="{
            multiple: false,
            clearable: true,
            options: methods
          }"
          ></editor-select>
        </el-form-item>
      </div>
      <el-form-item class="save_button">
        <el-button @click="validate" type="success">
          Добавить
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select.vue'
import CreateRequestCommand from '@/services/ApiService/application/command/CreateRequestCommand'
import { RequestTreeDTO } from '@/services/ApiService/domain/model/Request/RequestTree'

export default {
  name: 'RequestForm',
  components: {
    EditorSelect
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: {
    label: String,
    requestType: String,
    parent: Object
  },
  data () {
    return {
      localModel: {
        name: null,
        element_type: this.requestType,
        connections: null,
        request_type: '',
        protocol_type: '',
        method_type_id: '',
        parent_id: this.parent?.id,
        row_order: 0
      },
      connections: [
      ],
      types: [
        { id: 'in', name: this.$t('api_service.request.request_type.in') },
        { id: 'out', name: this.$t('api_service.request.request_type.out') }
      ],
      protocols: [
        { id: 'rest', name: 'REST' }
      ],
      methods: [
        { id: 'get', name: 'GET' },
        { id: 'post', name: 'POST' },
        { id: 'put', name: 'PUT' },
        { id: 'delete', name: 'DELETE' }
      ],
      rules: {
        name: [
          { required: true, message: this.$t('api_service.request.validation_rules.name'), trigger: 'blur' }
        ],
        request_type: [
          { required: true, message: this.$t('api_service.request.validation_rules.type'), trigger: 'blur' }
        ],
        protocol_type: [
          { required: true, message: this.$t('api_service.request.validation_rules.protocol'), trigger: 'blur' }
        ],
        method_type_id: [
          { required: true, message: this.$t('api_service.request.validation_rules.method'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async validate () {
      this.$refs.request_form.validate(async (valid) => {
        if (!valid) {
          return false
        }

        await this.getCommandBus().execute(new CreateRequestCommand(
          new RequestTreeDTO(
            this.$refs.request_form.model
          )
        ))
        this.getEventBus().$emit('refresh', this.parent)
        this.getEventBus().$emit('showWindow', false)
      })
    }
  }
}
</script>

<style scoped>
  .label {
    font-family: Roboto, Sans-Serif;
    font-size: medium;
    font-weight: bold;
    margin: 10px 10px 0 10px;
    display: inline-block;
  }

  .form_element {
    width: 200px;
    margin: 10px 0 10px 10px;
  }
</style>
