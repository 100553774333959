import AbstractEntitiesQueryHandler from '@/core/application/query/AbstractEntitiesQueryHandler'
import LogsRepositoryInterface from '@/services/LogService/domain/repository/LogsRepositoryInterface'
import LogsByResourceQuery from '@/services/LogService/application/query/LogsByResourceQuery'

export default class LogsByResourceHandler extends AbstractEntitiesQueryHandler {
  private repository: LogsRepositoryInterface

  constructor (repo: LogsRepositoryInterface) {
    super(repo)
    this.repository = repo
  }

  execute (query: LogsByResourceQuery): Promise<any> {
    return this.repository.getLogsByResource(query.getPayload(), query.getResource())
  }
}
