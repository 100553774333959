import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class RowFormulaUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private objectId: number
  private aliasFieldId: number
  private targetAliasValue: string
  private value: string
  private ast: string
  private preparedStatement: string
  private hasTargetRows: boolean
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    objectId: number,
    aliasFieldId: number,
    targetAliasValue: string,
    value: string,
    ast: string,
    preparedStatement: string,
    hasTargetRows: boolean,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.objectId = objectId
    this.aliasFieldId = aliasFieldId
    this.targetAliasValue = targetAliasValue
    this.ast = ast
    this.preparedStatement = preparedStatement
    this.hasTargetRows = hasTargetRows
    this.alias = alias
  }

  getClassName (): string {
    return 'RowFormulaUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getObjectId (): number {
    return this.objectId
  }

  getAliasFieldId (): number {
    return this.aliasFieldId
  }

  getTargetAliasValue (): string {
    return this.targetAliasValue
  }

  getValue (): string {
    return this.value
  }

  getAst (): string {
    return this.ast
  }

  getPreparedStatement (): string {
    return this.preparedStatement
  }

  getHasTargetRows (): boolean {
    return this.hasTargetRows
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
