<template>
  <div class="flex flex-col h-full">
    <!--    <div>
          <process-tree-elements
            value-key="element_guid"
            :placeholder="$t('logic_editor_v2.labels.process_key')"/>
        </div>-->
    <el-table
      v-loading="loading"
      highlight-current-row
      @row-click="clickRow"
      height="100%"
      class="registry"
      :row-style="{
        'cursor': 'pointer'
      }"
      :data="data">
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :formatter="column.formatter"
      >
        <template slot-scope="scope">
          <template v-if="['start_time_','end_time_'].includes(column.prop) && scope.row[column.prop]">
            <i class="el-icon-time"></i>
            <span style="margin-left: 5px">{{ $moment(scope.row[column.prop]).format('DD.MM.YYYY HH:mm:ss') }}</span>
          </template>
          <template v-else-if="column.prop === 'state_' && scope.row[column.prop]">
            <span :style="
              stateFieldMapper[scope.row[column.prop]]?.color ?
              `color: ${stateFieldMapper[scope.row[column.prop]].color}` : null
            ">
              <i v-if="stateFieldMapper[scope.row[column.prop]]?.icon"
                 :class="stateFieldMapper[scope.row[column.prop]].icon"></i>
              {{ stateFieldMapper[scope.row[column.prop]]?.text || scope.row[column.prop] }}
            </span>
          </template>
          <template v-else-if="column.prop === 'variables' && scope.row[column.prop]">
            <div v-for="(variable, index) in additionalVariables" :key="index">
              <b>{{ variable.name }}</b>:
              <span>
                {{ scope.row[column.prop]?.find(_ => _.name_ === variable.value) | variableValue }}
              </span>
            </div>
          </template>
          <template v-else>
            <span>{{ scope.row[column.prop] }}</span>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="meta.page"
      :page-size="meta.pageSize"
      layout="total, prev, pager, next"
      :total="meta.total">
    </el-pagination>
  </div>
</template>
<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MonitoringAPI } from '@/services/BpmnGateway/infrastructure/api/MonitoringAPI'
import ProcessTreeElements from '@/core/infrastructure/components/ProcessTreeElements.vue'
import Template from '@/components/EtlEditor/Properties/Template.vue'
import VariablesMixin from '@/services/BpmnGateway/infrastructure/mixins/VariablesMixin'

export default {
  name: 'ProcessInstancesTable',
  components: { Template, ProcessTreeElements },
  mixins: [VariablesMixin],
  props: {
    processKey: String,
    additionalVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      data: [],
      meta: {
        page: 1,
        total: 0,
        pageSize: 50
      },
      stateFieldMapper: {
        ACTIVE: {
          text: 'Активен',
          icon: 'el-icon-caret-right',
          color: '#409EFF'
        },
        INTERNALLY_TERMINATED: {
          text: 'Ошибка',
          icon: 'el-icon-circle-close',
          color: '#F56C6C'
        },
        COMPLETED: {
          text: 'Завершен',
          icon: 'el-icon-circle-check',
          color: '#67C23A'
        }
      },
      columns: [
        {
          label: 'ID',
          prop: 'id_',
          width: 100
        },
        {
          label: 'Время запуска',
          prop: 'start_time_',
          width: 180
        },
        {
          label: 'Время завершения',
          prop: 'end_time_',
          width: 180
        },
        {
          label: 'Статус',
          prop: 'state_',
          width: 150
        },
        {
          label: 'Идентификатор схемы',
          prop: 'proc_def_id_'
        }
      ]
    }
  },
  watch: {
    'meta.page': function () {
      this.loadData()
    }
  },
  mounted () {
    if (this.additionalVariables?.length > 0) {
      this.columns.push({
        label: 'Переменные',
        prop: 'variables'
      })
    }
    this.loadData()
  },
  methods: {
    clickRow (row) {
      this.$emit('select', row)
    },
    update () {
      this.loadData()
    },
    async loadData () {
      try {
        this.loading = true
        await Promise.all([
          APIClient.shared.request(new MonitoringAPI.GetProcessInstances(
            this.meta.pageSize, (this.meta.page - 1) * this.meta.pageSize, this.processKey,
            this.additionalVariables?.map(_ => _.value) || []
          ))
            .then((data) => {
              this.data = data
            }),
          APIClient.shared.request(new MonitoringAPI.GetProcessInstancesCount(this.processKey)).then((data) => {
            this.meta.total = data[0]?.count || 0
          })
        ])
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
