<template>
  <div style="height: 100%" v-loading="loading">
    <el-alert type="error" v-if="error">{{ error }}</el-alert>
  </div>
</template>

<script>
export default {
  name: 'FastLogin',
  data () {
    return {
      loading: true,
      uuid: this.$route.query.uuid,
      error: null
    }
  },
  mounted () {
    if (this.uuid) {
      this.$store.dispatch(`Authorization/login`, this.uuid).then(() => {
        this.$router.push('/')
      }).catch(() => {
        this.error = 'Ошибка авторизации'
        this.loading = false
      })
    } else {
      this.error = 'Не передан uuid'
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
