import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import CreateRequestCommand from '@/services/ApiService/application/command/CreateRequestCommand'

export default class CreateRequestHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CreateRequestCommand): Promise<void> {
    return this.repository.createRequest(command.getDto())
  }
}
