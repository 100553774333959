<template>
  <div>
    <el-tooltip class="item" effect="dark" content="Система координат" placement="top">
      <coordinate-system
        id="coordinate-system"
        :isDisabled="false"
        :value="srid"
        :options="srids"
        size="mini"
        @change-cs="sridChange">
      </coordinate-system>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" content="Добавить систему координат" placement="">
      <el-button plain size="mini" icon="el-icon-plus" @click="coordinateSystemSelect"/>
    </el-tooltip>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
import CoordinateSystemsByUserIdQuery from '@/services/MapEditor/application/query/CoordinateSystemsByUserIdQuery'
import CoordinateSystemBySridQuery from '@/services/MapEditor/application/query/CoordinateSystemBySridQuery'
import MapManager from '@bingo_soft/mapmanager'

export default {
  name: 'CoordinateSystemCombobox',
  props: ['value'],
  inject: ['getEventBus', 'getQueryBus'],
  components: {
    CoordinateSystem
  },
  data() {
    return {
      srids: [],
      srid: this.value
    }
  },
  methods: {
    sridChange(newValue) {
      this.getQueryBus().execute(
        new CoordinateSystemBySridQuery(newValue)
      ).then(data => {
        if (Array.isArray(data)) {
          this.addCoordinateSystem(data[0].auth_name, data[0].auth_srid, data[0].proj4text);
          this.getEventBus().$emit('coordinateSystemChanged', newValue);
        }
      });
    },
    loadCoordinateSystems() {
      const userId = this.$store.getters['Authorization/userId'];
      if (!userId) {
          return;
      }
      this.getQueryBus().execute(
        new CoordinateSystemsByUserIdQuery(userId)
      ).then(data => {
        if (Array.isArray(data)) {
          data.forEach(el => {
            if (el.auth_srid) {
              this.addCoordinateSystem(el.auth_name, el.auth_srid, el.proj4text);
            }
          });
        }
      });
    },
    addCoordinateSystem(authName, authSrid, proj4text) {
      const existing = this.srids.find((el) => { return el.srid == authSrid; });
      if (!existing) {
        let code = 'EPSG:' + authSrid;
        MapManager.addProjection(code, proj4text);
        if (authName != 'EPSG') {
          code = '(' + code + ') ' + authName;
        } else {
          const regExp = new RegExp('(?<=\\+title=)(.*?)(?=\\+)')
          let r = proj4text.match(regExp);
          if (r) {
            code = r[0] + ' (' + code + ')';
          }
        } 
        this.srids.push({
          label: code,
          value: authSrid,
          srid: authSrid,
          auth_name: authName,
          auth_srid: authSrid,
          proj4text: proj4text
        });
      }
    },
    coordinateSystemSelect() {
      this.getEventBus().$emit('selectCoordinateSystem');
    },
    coordinateSystemSelected(coordinateSystem) {
      // TODO: add SRS to user at the backend
      this.addCoordinateSystem(coordinateSystem.getName(), coordinateSystem.getSrid(), coordinateSystem.getProj4text());
      //this.srid = coordinateSystem.getSrid(); // TODO: не работает
    }
  },
  mounted() {
    this.loadCoordinateSystems();
    this.getEventBus().$on('addCoordinateSystem', this.addCoordinateSystem);
    this.getEventBus().$on('CoordinateSystemComboboxCoordinateSystemSelected', this.coordinateSystemSelected);
  }
}
</script>


<style scoped>
    div {
        display: inline-block;
    }
    #coordinate-system {
      width: 250px;
    }
    input {
        height: 35px;
    }
    .el-input {
        width: 100px;
    }
    .el-select {
        width: 200px;
        margin-right: 10px;
    }
</style>
