<template>
  <div style="height: 100%;">
    <el-form ref="schema_form" size="mini" :model="localModel" :rules="rules">
      <span class="label">{{ label }}</span>
      <el-form-item class="form_element" prop="name">
        <span>{{ $t('api_service.schema.name') }}</span>
        <el-input v-model="localModel.name"></el-input>
      </el-form-item>
      <el-form-item class="form_element" prop="type">
        <span>{{ $t('api_service.schema.type') }}</span>
        <el-input v-model="localModel.entity_type_id" disabled></el-input>
      </el-form-item>
      <el-form-item class="save_button">
        <el-button @click="validate" type="success">
          {{ $t('api_service.schema.add') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select.vue'
import CreateSchemaCommand from '@/services/ApiService/application/command/CreateSchemaCommand'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export default {
  name: 'SchemaForm',
  components: {
    EditorSelect
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: {
    label: String,
    schemaEntityType: String,
    parent: Object
  },
  computed: {
    isSchema () {
      return this.parent.entity_type_id === 'group'
    }
  },
  data () {
    return {
      localModel: {
        name: null,
        entity_type_id: this.schemaEntityType,
        parent_id: this.parent?.id
      },
      rules: {
        name: [
          { required: true, message: this.$t('api_service.request.validation_rules.name'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async validate () {
      this.$refs.schema_form.validate(async (valid) => {
        if (!valid) {
          return false
        }

        Object.assign(this.$refs.schema_form.model, { is_schema: this.isSchema })

        await this.getCommandBus().execute(new CreateSchemaCommand(
          new SchemaTreeDTO(
            this.$refs.schema_form.model
          )
        ))
        this.getEventBus().$emit('refresh_schema', this.parent)
        this.getEventBus().$emit('show_schema_window', false)
      })
    }
  }
}
</script>

<style scoped>
  .label {
    font-family: Roboto, Sans-Serif;
    font-size: medium;
    font-weight: bold;
    display: inline-block;
    margin: 10px 10px 0 10px;
  }

  .form_element {
    width: 200px;
    margin: 10px 0 10px 10px;
  }
</style>
