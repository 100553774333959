<template>
  <div class="select-editor">
    <div class="input-group">
      <el-select v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="placeholder" filterable size="mini">
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <div class="input-group__append">
        <el-button type="success" icon="el-icon-plus" size="mini" @click="add()"></el-button>
        <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit()" :disabled="value === null"></el-button>
      </div>
    </div>

    <el-dialog
      custom-class="select-editor__dialog"
      destroy-on-close
      width="60%"
      :close-on-click-modal="false"
      :title="editor.title"
      :visible.sync="editor.show"
      :before-close="dialogClose">
      <component
        ref="form"
        v-model="editor.model"
        :is="editor.form">
      </component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editorClose()" size="small">{{ $locale.main.button.close }}</el-button>
        <el-button type="primary" @click="save()" size="small">{{ $locale.main.button.save }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ExtractorForm from './forms/ExtractorForm'
import LoaderForm from './forms/LoaderForm'

import { ExtractorDTO } from '../../domain/model/Extractor'
import { LoaderDTO } from '../../domain/model/Loader'
import LoaderByGuidQuery from '../../application/query/LoaderByGuidQuery'
import ExtractorByGuidQuery from '../../application/query/ExtractorByGuidQuery'

export default {
  name: 'SelectEditor',

  props: {
    value: Number,

    options: {
      type: Array,
      default () {
        return []
      }
    },

    elementType: {
      type: String,
      validate: (value) => {
        return [
          'extractor',
          'loader'
        ].indexOf(value) !== -1
      }
    },

    placeholder: {
      type: String
    },

    parentTreeElementId: Number
  },

  components: {
    ExtractorForm,
    LoaderForm
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    extractorCreated () {
      return this.$store.getters['Extractor/getExtractorLocation']
    },

    loaderCreated () {
      return this.$store.getters['Loader/getLoaderLocation']
    }
  },

  watch: {
    extractorCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ExtractorByGuidQuery(
            location.replace('/extractors/', '')
          )
        ).then(response => {
          if (response.guid) {
            let options = this.options
            options.push(response)
            this.$emit('update:options', options)
            this.$emit('input', response.id)
          }
        })
      }
    },

    loaderCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new LoaderByGuidQuery(
            location.replace('/loaders/', '')
          )
        ).then(response => {
          if (response.guid) {
            let options = this.options
            options.push(response)
            this.$emit('update:options', options)
            this.$emit('input', response.id)
          }
        })
      }
    }
  },

  data () {
    return {
      editor: {
        model: null,
        form: null,
        title: null,
        show: false
      },

      defaultModel: {
        extractor: ExtractorDTO,
        loader: LoaderDTO
      },

      form: {
        extractor: 'ExtractorForm',
        loader: 'LoaderForm'
      }
    }
  },

  methods: {
    add () {
      this.editor.form = this.form[this.elementType]
      this.editor.title = this.$locale.etl_editor_v2.headlines.create[this.elementType]
      this.editor.model = this.defaultModel[this.elementType].create()
      this.editor.model.parent_tree_element_id = this.parentTreeElementId
      this.editor.show = true
    },

    edit () {
      this.editor.form = this.form[this.elementType]
      this.editor.title = this.$locale.etl_editor_v2.headlines.update[this.elementType]
      this.editor.model = this.options.find(model => model.id === this.value)
      this.editor.show = true
    },

    save () {
      this.$refs.form.submit(() => {
        let options = this.options
        let editIndex = options.findIndex(opt => opt.id === this.value)
        options[editIndex].name = this.editor.model.name
        this.$emit('update:options', options)

        this.editor = {
          model: null,
          form: null,
          title: null,
          show: false
        }
      })
    },

    dialogClose (done) {
      this.editor = {
        model: null,
        form: null,
        title: null,
        show: false
      }
    }
  }
}
</script>

<style scoped></style>
