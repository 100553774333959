import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetRowFormulaByGuid } from '@/services/LogicEditor/infrastructure/store/modules/RowFormula/action-types'
import RowFormulaQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/RowFormulaQueryRepositoryInterface'

export default class RowFormulaQueryRepository extends AbstractQueryRepository implements RowFormulaQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetRowFormulaByGuid(guid))
  }
}
