import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ActivityRepositoryInterface from '@/services/ActivityService/domain/repository/ActivityRepositoryInterface'
import store from '@/store'
import { GetActivities } from '@/services/ActivityService/infrastructure/store/modules/Activity/action-types'

export default class ActivityRepository extends AbstractQueryRepository implements ActivityRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetActivities(payload))
  }
}
