import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import GroupCreateCommand from '@/services/CssEditor/application/command/GroupCreateCommand'
import GroupCommandRepositoryInterface from '@/services/CssEditor/domain/repository/GroupCommandRepositoryInterface'

export default class GroupCreateCommandHandler implements CommandHandlerInterface {
  private repository: GroupCommandRepositoryInterface;

  constructor (repository: GroupCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: GroupCreateCommand): Promise<void> {
    return this.repository.insert(command.getDto())
  }
}
