import { Nullable } from '@/core/domain/type/types'

export default class ReleaseCreateCommand
{
    private name: string;
    private description: Nullable<string>;
    private version: Nullable<string>;
    private type: string;
    private start_date: string;
    private complete_date: Nullable<string>;
    private is_complete: boolean;

    constructor(name: string, description: Nullable<string>, version: Nullable<string>, type: string, start_date: string, complete_date: Nullable<string>, is_complete: boolean)
    {
        this.name = name;
        this.description = description;
        this.version = version;
        this.type = type;
        this.start_date = start_date;
        this.complete_date = complete_date;
        this.is_complete = is_complete;
    }

    getClassName(): string
    {
        return 'ReleaseCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getVersion(): Nullable<string>
    {
        return this.version;
    }

    getType(): string
    {
        return this.type;
    }

    getStartDate(): string
    {
        return this.start_date;
    }

    getCompleteDate(): Nullable<string>
    {
        return this.complete_date;
    }

    getIsComplete(): boolean
    {
        return this.is_complete;
    }
}