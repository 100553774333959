import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RasterDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name: string;
    description: string;
    file_path?: string;
    create_date?: any;
    format?: string;
    scale?: string;
    coordinate_system?: string;
    width?: number;
    height?: number;
    extent_x0?: number;
    extent_y0?: number;
    extent_x1?: number;
    extent_y1?: number;
    data_type?: string;
    compression_method?: string;
    metadata?: any;
    pixel_size?: number;
    is_valid?: boolean;
    checking_result?: string;
    edit_date?: any;
    
    constructor({ 
        id,
        guid,
        name,
        description,
        file_path,
        create_date,
        format,
        scale,
        coordinate_system,
        width,
        height,
        extent_x0,
        extent_y0,
        extent_x1,
        extent_y1,
        data_type,
        compression_method,
        metadata,
        pixel_size,
        is_valid,
        checking_result,
        edit_date
    }) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.file_path = file_path;
        this.create_date = create_date;
        this.format = format;
        this.scale = scale;
        this.coordinate_system = coordinate_system;
        this.width = width;
        this.height = height;
        this.extent_x0 = extent_x0;
        this.extent_y0 = extent_y0;
        this.extent_x1 = extent_x1;
        this.extent_y1 = extent_y1;
        this.data_type = data_type;
        this.compression_method = compression_method;
        this.metadata = metadata;
        this.pixel_size = pixel_size;
        this.is_valid = is_valid;
        this.checking_result = checking_result;
        this.edit_date = edit_date;
    }
}


export default class Raster extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;
    private description: string;
    private file_path?: string;
    private create_date?: any;
    private format?: string;
    private scale?: string;
    private coordinate_system?: string;
    private width?: number;
    private height?: number;
    private extent_x0?: number;
    private extent_y0?: number;
    private extent_x1?: number;
    private extent_y1?: number;
    private data_type?: string;
    private compression_method?: string;
    private metadata?: any;
    private pixel_size?: number;
    private is_valid?: boolean;
    private checking_result?: string;
    private edit_date?: any;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string,
        description: string,
        file_path: string,
        create_date?: any,
        format?: string,
        scale?: string,
        coordinate_system?: string,
        width?: number,
        height?: number,
        extent_x0?: number,
        extent_y0?: number,
        extent_x1?: number,
        extent_y1?: number,
        data_type?: string,
        compression_method?: string,
        metadata?: any,
        pixel_size?: number,
        is_valid?: boolean,
        checking_result?: string,
        edit_date?: any
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.file_path = file_path;
        this.create_date = create_date;
        this.format = format;
        this.scale = scale;
        this.coordinate_system = coordinate_system;
        this.width = width;
        this.height = height;
        this.extent_x0 = extent_x0;
        this.extent_y0 = extent_y0;
        this.extent_x1 = extent_x1;
        this.extent_y1 = extent_y1;
        this.data_type = data_type;
        this.compression_method = compression_method;
        this.metadata = metadata;
        this.pixel_size = pixel_size;
        this.is_valid = is_valid;
        this.checking_result = checking_result;
        this.edit_date = edit_date;
    }

    static create(dto: RasterDTO): Raster
    {
        return new Raster(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.file_path,
            dto.create_date,
            dto.format,
            dto.scale,
            dto.coordinate_system,
            dto.width,
            dto.height,
            dto.extent_x0,
            dto.extent_y0,
            dto.extent_x1,
            dto.extent_y1,
            dto.data_type,
            dto.compression_method,
            dto.metadata,
            dto.pixel_size,
            dto.is_valid,
            dto.checking_result,
            dto.edit_date
        );
    }

    getAllProperties(): any
    {
        return {
            id: this.id,
            guid: this.guid,
            name: this.name,
            description: this.description,
            file_path: this.file_path,
            create_date: this.create_date,
            format: this.format,
            scale: this.scale,
            coordinate_system: this.coordinate_system,
            width: this.width,
            height: this.height,
            extent_x0: this.extent_x0,
            extent_y0: this.extent_y0,
            extent_x1: this.extent_x1,
            extent_y1: this.extent_y1,
            data_type: this.data_type,
            compression_method: this.compression_method,
            metadata: this.metadata,
            pixel_size: this.pixel_size,
            is_valid: this.is_valid,
            checking_result: this.checking_result,
            edit_date: this.edit_date
        };
    }

    getId(): number {
        return this.id;
    }

    getGuid(): string {
        return this.guid;
    }

    getName(): string {
        return this.name;
    }

    getDescription(): string {
        return this.description;
    }

    getFilePath(): string {
        return this.file_path;
    }

    getCreateDate(): any {
        return this.create_date;
    }

    getFormat(): string {
        return this.format;
    }

    getScale(): string {
        return this.scale;
    }

    getCoordinateSystem(): string {
        return this.coordinate_system;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    getExtentX0(): number {
        return this.extent_x0;
    }

    getExtentY0(): number {
        return this.extent_y0;
    }

    getExtentX1(): number {
        return this.extent_x1;
    }

    getExtentY1(): number {
        return this.extent_y1;
    }

    getDataType(): string {
        return this.data_type;
    }

    getCompressionMethod(): string {
        return this.compression_method;
    }

    getMetadata(): any {
        return this.metadata;
    }

    getPixelSize(): number {
        return this.pixel_size;
    }

    getIsValid(): boolean {
        return this.is_valid;
    }

    getCheckingResult(): string {
        return this.checking_result;
    }

    getEditDate(): any {
        return this.edit_date;
    }

}
