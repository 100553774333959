import ApprovalStage from '@/services/BPMNEditorApprovals/domain/model/ApprovalStage'
import BPMNService from '@/services/BPMNEditorApprovals/infrastructure/service/BPMNService'
import ApprovalStageBPMNBuilder from '@/services/BPMNEditorApprovals/domain/service/ApprovalStageBPMNBuilder'

export default class ApprovalBPMNBuilder {
  private approvalStages: ApprovalStage[]

  public constructor (approvalStages: ApprovalStage[]) {
    this.approvalStages = approvalStages
  }

  public build (BPMNService: BPMNService, position: { X, Y }): { elements, connections, lastPosition } {
    let result = []
    let connections = []
    let lastX = position.X
    let lastY = position.Y
    // строим все этапы
    const approvalStagesMap = new Map(this.approvalStages.map((item, index) => {
      const builder = new ApprovalStageBPMNBuilder(item)
      let _result = builder.build(BPMNService, { X: lastX + 200, Y: position.Y })
      result.push(..._result.elements)
      connections.push(..._result.connections)
      item.element = _result.elements[0]
      item.lastCancelElement = _result.elements[1]
      item.lastElement = _result.elements.pop()
      lastX = _result.lastPosition.X
      lastY = _result.lastPosition.Y

      return [item.id, item]
    }))
    // идем по всем этапам и строим связи блокирующих этапов
    this.approvalStages.forEach((item) => {
      const currentStage = approvalStagesMap.get(item.id)
      item.blockedStages.forEach((stageId) => {
        const blockingStage = approvalStagesMap.get(stageId)
        connections.push({
          from: blockingStage.lastElement,
          to: currentStage.element
        })
      })
    })

    // строим этапы возврата
    this.approvalStages.forEach((item) => {
      if (item.returnStageGuid) {
        const returnStage = this.approvalStages.find((_) => _.guid === item.returnStageGuid)
        if (returnStage) {
          const returnStageElement = approvalStagesMap.get(returnStage.id)
          connections.push({
            from: item.lastCancelElement,
            to: returnStageElement.element
          })
        }
      }
    })

    return {
      elements: result,
      connections: connections,
      lastPosition: {
        X: lastX,
        Y: lastY
      }
    }
  }
}
