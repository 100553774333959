<template>
  <div class="page-placement">
    <div class="place-row">
      <div class="place" :class="{ 'is-active': value === 'leftTop' }" @click="onClick('leftTop')"></div>

      <div class="place" :class="{ 'is-active': value === 'centerTop' }" @click="onClick('centerTop')"></div>

      <div class="place" :class="{ 'is-active': value === 'rightTop' }" @click="onClick('rightTop')"></div>
    </div>

    <div class="place-row">
      <div class="place" :class="{ 'is-active': value === 'leftCenter' }" @click="onClick('leftCenter')"></div>

      <div class="place" :class="{ 'is-active': value === 'center' }" @click="onClick('center')"></div>

      <div class="place" :class="{ 'is-active': value === 'rightCenter' }" @click="onClick('rightCenter')"></div>
    </div>

    <div class="place-row">
      <div class="place" :class="{ 'is-active': value === 'leftBottom' }" @click="onClick('leftBottom')"></div>

      <div class="place" :class="{ 'is-active': value === 'centerBottom' }" @click="onClick('centerBottom')"></div>

      <div class="place" :class="{ 'is-active': value === 'rightBottom' }" @click="onClick('rightBottom')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePlacement',

  props: ['value'],

  methods: {
    onClick (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-placement {
  border: 1px solid #C0C4CC;
  //padding: 5px;
  border-radius: 3px;
  max-width: 240px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .place-row {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 25px;
    //
    //&:last-child {
    //  margin: 0;
    //}
  }

  .place {
    cursor: pointer;
    border: 1px solid #C0C4CC;
    max-width: 50px;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    margin: 10px;

    &:hover {
      border: 1px dashed #C0C4CC;
    }

    &.is-active {
      background: #C0C4CC;
    }
  }
}
</style>
