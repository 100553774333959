<template>
  <div class="task_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.etl_editor_v2.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.extractor" prop="extractor_id">
        <select-editor
          v-model="model.extractor_id"
          v-bind:options="extractors"
          v-on:update:options="extractors = $event"
          :placeholder="$locale.etl_editor_v2.labels.extractor"
          :parent-tree-element-id="model.parent_tree_element_id"
          element-type="extractor">
        </select-editor>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.loader" prop="loader_id">
        <select-editor
          v-model="model.loader_id"
          v-bind:options="loaders"
          v-on:update:options="loaders = $event"
          :placeholder="$locale.etl_editor_v2.labels.loader"
          :parent-tree-element-id="model.parent_tree_element_id"
          element-type="loader">
        </select-editor>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.code" prop="code">
        <el-input v-model="model.code" :placeholder="$locale.etl_editor_v2.labels.code"></el-input>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="model.is_visible_in_registry">
          {{ $locale.etl_editor_v2.labels.is_visible_in_registry }}
        </el-checkbox>
      </el-form-item>

      <!--<el-form-item v-if="mapping.type !== null">
        &lt;!&ndash;<el-button type="primary" size="mini" @click="mapping.visible = !mapping.visible">
          {{ $locale.etl_editor_v2.labels.mapping }}
        </el-button>&ndash;&gt;

        <component
          v-show="mapping.type !== null"
          ref="mapping"
          :is="mapping.type"
          :task-id="model.id"
          :loaders="loaders"
          :transformers="[]"
          :extractor-type="mapping.extractorType"
          :key="generateGuid()">
        </component>
      </el-form-item>-->

      <component
        v-show="mapping.type !== null"
        ref="mapping"
        :is="mapping.type"
        :task-id="model.id"
        :loaders="loaders"
        :transformers="[]"
        :extractor-type="mapping.extractorType"
        :key="generateGuid()">
      </component>
    </el-form>

    <!--<el-dialog
      :title="$locale.etl_editor_v2.labels.mapping"
      :close-on-click-modal="false"
      width="70%"
      :visible.sync="mapping.visible"
      :destroy-on-close="false"
      :before-close="mapClose">
      <component
        v-show="mapping.type !== null"
        ref="mapping"
        :is="mapping.type"
        :task-id="model.id"
        :loaders="loaders"
        :transformers="[]"
        :extractor-type="mapping.extractorType"
        :key="generateGuid()">
      </component>
    </el-dialog>-->
  </div>
</template>

<script>
import ExtractorsQuery from '@/services/EtlEditor/application/query/ExtractorsQuery'
import LoadersQuery from '@/services/EtlEditor/application/query/LoadersQuery'
import TaskByGuidQuery from '@/services/EtlEditor/application/query/TaskByGuidQuery'
import Task from '@/services/EtlEditor/domain/model/Task'
import TaskCreateCommand from '@/services/EtlEditor/application/command/TaskCreateCommand'
import TaskUpdateCommand from '@/services/EtlEditor/application/command/TaskUpdateCommand'

import SelectEditor from '../SelectEditor'
import TreeMapping from '../mappings/Tree/index'
import TableMapping from '../mappings/Table/index'

export default {
  name: 'TaskForm',

  props: {
    value: Object
  },

  components: {
    SelectEditor,
    TreeMapping,
    TableMapping
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    taskCreated () {
      return this.$store.getters['Task/getTaskLocation']
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    'model.extractor_id': function (value) {
      if (!this.isNew) {
        let extractor = this.extractors.find(ex => ex.id === value)

        if (extractor) {
          this.mapping.type = ['json_tree', 'xml'].indexOf(extractor.extractor_type_id) !== -1 ? 'TreeMapping' : 'TableMapping'
          this.mapping.extractorType = extractor.extractor_type_id
        } else {
          this.mapping.type = null
          this.mapping.extractorType = null
        }
      }
    },

    taskCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new TaskByGuidQuery(
            location.replace('/tasks/', '')
          )
        ).then((response) => {
          if (response.guid) {
            this.$emit('input', response)
          }
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: this.$locale.etl_editor_v2.rules.name, trigger: 'blur' }
        ],
        extractor_id: [
          { required: true, message: this.$locale.etl_editor_v2.rules.extractor, trigger: 'change' }
        ],
        loader_id: [
          { required: true, message: this.$locale.etl_editor_v2.rules.loader, trigger: 'change' }
        ]
      },

      mapping: {
        type: null,
        extractorType: null,
        visible: false
      },

      extractors: [],
      loaders: [],

      isLoaded: false
    }
  },

  async mounted () {
    if (!this.extractors.length) {
      this.extractors = await this.getQueryBus().execute(new ExtractorsQuery())
    }

    if (!this.loaders.length) {
      this.loaders = await this.getQueryBus().execute(new LoadersQuery())
    }

    if (!this.isNew) {
      let extractor = this.extractors.find(ex => ex.id === this.model.extractor_id)

      if (extractor) {
        this.mapping.type = ['json_tree', 'xml'].indexOf(extractor.extractor_type_id) !== -1 ? 'TreeMapping' : 'TableMapping'
        this.mapping.extractorType = extractor.extractor_type_id
      } else {
        this.mapping.type = null
        this.mapping.extractorType = null
      }
    }

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    mapClose (done) {
      this.mapping.visible = false
    },

    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let model = Task.create(this.value)

      if (this.isNew) {
        return new TaskCreateCommand(
          model.getName(),
          model.getIsCronTask(),
          model.getExtractorId(),
          model.getLoaderId(),
          model.getIsVisibleInRegistry(),
          model.getCronTime(),
          model.getCode(),
          model.getParentTreeElementId()
        )
      }

      return new TaskUpdateCommand(
        model.getGuid(),
        model.getName(),
        model.getIsCronTask(),
        model.getExtractorId(),
        model.getLoaderId(),
        model.getIsVisibleInRegistry(),
        model.getCronTime(),
        model.getCode()
      )
    }
  }
}
</script>

<style scoped></style>
