
  import EventsCountQuery from '@/services/Databus/application/query/EventsCountQuery'
  import EventsTreeQuery from '@/services/Databus/application/query/EventsTreeQuery'
  import EventType from '@/services/Databus/domain/model/Event/EventType'
  import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
  import EventsQuery from '@/services/Databus/application/query/EventsQuery'

  export default {
    name: 'EventPanel',
    data () {
      return {
        loading: false,
        eventsPageSize: 0,
        defaultEventsPageSize: 100,
        eventsCurrentPage: 0,
        eventsPageLimit: 100,
        eventsCount: 0,
        allEventsCount: 0,
        events: [],
        event: null,
        informationPanelHeight: 0,
        eventsTree: null,
        isEventTreeWindowVisible: false,
        eventsFilters: {
          topic: null,
          type: null,
          period: null,
          recordId: ''
        },
        eventTypes: this.getEventsList(),
        topicList: []
      }
    },
    inject: ['getEventBus', 'getQueryBus', 'getDownloadPackage'],
    watch: {
      isEventTreeLoading: (state) => state
    },
    computed: {
      isEventTreeLoading() {
        return this.$store.getters['DatabusEvent/isLoading'];
      }
    },
    methods: {
      loadEvents() {
        if (this.allEventsCount == 0) {
          this.countAndLoadEvents();
        } else {
          this.loadAllEvents();
        }
      },
      async countAndLoadEvents() {
        await this.getQueryBus().execute(
          new EventsCountQuery(this.prepareUrlQueryParams(true))
        ).then(data => {
          this.eventsCount = data[0]['count'];
          this.loadAllEvents();
        });
      },
      async loadAllEvents() {
        await this.getQueryBus().execute(
          new EventsQuery(this.prepareUrlQueryParams())
        ).then(data => {
          this.eventsPageSize = this.eventsPageLimit;
          this.events = data;
        });
      },
      handleEventsPageChange(val) {
        val--;
        this.eventsCurrentPage = (val * this.eventsPageLimit);
        this.loadEvents();
      },
      eventTableRowClassName({row, rowIndex}) {
        if (
          row.event_type_id == EventType.PACKAGE_READ_FAILURE ||
          row.event_type_id == EventType.PACKAGE_WRITE_FAILURE
        ) {
          return 'error-row';
        }
        if (rowIndex % 2 !== 0) {
          return 'el-table__row--striped'
        }
        return '';
      },
      parseMessage(topic, properties) {
        let res = [];
        properties.forEach((property) => {
          if (property.id == "message") {
            let message = property.value;
            if (typeof message == 'object' && message != null) {
              for (let key in message) {
                res.push(key + ': ' + message[key])
              }
            }
          }
        });
        return res.join("<br>");
      },
      async buildEventTree(row) {
        await this.getQueryBus().execute(
          new EventsTreeQuery(row.guid)
        ).then(data => {
          this.eventsTree = data;
        });
        this.isEventTreeWindowVisible = true;
      },
      closeEventTreeWindow() {
        this.isEventTreeWindowVisible = false;
      },
      applyFilters(type, value) {
        if (type == 'type') {
          this.eventsFilters.type = value;
          if (typeof this.eventsFilters.type == 'undefined' || this.eventsFilters.type == "") {
            this.eventsFilters.type = null
          }
        }
        if (type == 'topic') {
          this.eventsFilters.topic = value;
          if (typeof this.eventsFilters.topic == 'undefined' || this.eventsFilters.topic == "") {
            this.eventsFilters.topic = null
          }
        }
        if (type == 'period') {
          this.eventsFilters.period = value;
          if (typeof this.eventsFilters.period == 'undefined') {
            this.eventsFilters.period = null
          }
        }
        if (type === 'recordId') {
          this.eventsFilters.recordId = value
          if (typeof this.eventsFilters.recordId === 'undefined') {
            this.eventsFilters.recordId = ''
          }
        }
        this.loadEvents();
      },
      prepareUrlQueryParams(forCount = false) {
        let params = {};
        if (!forCount) {
          params['limit'] = this.eventsPageLimit;
          params['offset'] = this.eventsCurrentPage;
        }
        if (this.eventsFilters.type != null) {
          params['event_type_id'] = this.eventsFilters.type;
        }
        if (this.eventsFilters.topic != null) {
          params['topic_id'] = this.eventsFilters.topic;
        }
        if (this.eventsFilters.period != null) {
          params['period_from'] = encodeURI(this.eventsFilters.period[0]);
          params['period_to'] = encodeURI(this.eventsFilters.period[1]);
        }
        if (this.eventsFilters.recordId !== '') {
          params['package_record_id'] = this.eventsFilters.recordId
        }
        return params;
      },
      async loadTopicList() {
        await this.getQueryBus().execute(
          new TopicsQuery()
        ).then(data => {
          this.topicList = data;
        });
      },
      getEventsList() {
        return [
          {
            "id": "package_read_success",
            "name": this.$locale.databus.event_panel.event_types.package_read_success
          },
          {
            "id": "package_read_failure",
            "name": this.$locale.databus.event_panel.event_types.package_read_failure
          },
          {
            "id": "package_write_success",
            "name": this.$locale.databus.event_panel.event_types.package_write_success
          },
          {
            "id": "package_write_failure",
            "name": this.$locale.databus.event_panel.event_types.package_write_failure
          },
          {
            "id": "package_import_success",
            "name": this.$locale.databus.event_panel.event_types.package_import_success
          },
          {
            "id": "package_import_error",
            "name": this.$locale.databus.event_panel.event_types.package_import_error
          },
          {
            "id": "package_response_routing_success",
            "name": this.$locale.databus.event_panel.event_types.package_response_routing_success
          },
          {
            "id": "package_response_routing_error",
            "name": this.$locale.databus.event_panel.event_types.package_response_routing_error
          },
          {
            "id": "smev_request",
            "name": this.$locale.databus.event_panel.event_types.smev_request
          },
          {
            "id": "smev_response",
            "name": this.$locale.databus.event_panel.event_types.smev_response
          },
          {
            "id": "smev_reject",
            "name": this.$locale.databus.event_panel.event_types.smev_reject
          },
          {
            "id": "smev_error",
            "name": this.$locale.databus.event_panel.event_types.smev_error
          },
          {
            "id": "smev_status",
            "name": this.$locale.databus.event_panel.event_types.smev_status
          },
          {
            "id": "smev_response_routing_success",
            "name": this.$locale.databus.event_panel.event_types.smev_response_routing_success
          },
          {
            "id": "smev_response_routing_error",
            "name": this.$locale.databus.event_panel.event_types.smev_response_routing_error
          },
          {
            "id": "smev_response_routing",
            "name": this.$locale.databus.event_panel.event_types.smev_response_routing
          },
          {
            "id": "smev_incoming_request",
            "name": this.$locale.databus.event_panel.event_types.smev_incoming_request
          },
          {
            "id": "smev_outgoing_response",
            "name": this.$locale.databus.event_panel.event_types.smev_outgoing_response
          },
          {
            "id": "gis_gkh_request",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_request
          },
          {
            "id": "gis_gkh_response",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_response
          },
          {
            "id": "gis_gkh_reject",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_reject
          },
          {
            "id": "gis_gkh_error",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_error
          },
          {
            "id": "gis_gkh_status",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_status
          },
          {
            "id": "gis_gkh_response_routing_success",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_response_routing_success
          },
          {
            "id": "gis_gkh_response_routing_error",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_response_routing_error
          },
          {
            "id": "gis_gkh_response_routing",
            "name": this.$locale.databus.event_panel.event_types.gis_gkh_response_routing
          },
          {
            "id": "sn_request",
            "name": this.$locale.databus.event_panel.event_types.sn_request
          },
          {
            "id": "sn_response",
            "name": this.$locale.databus.event_panel.event_types.sn_response
          },
          {
            "id": "sn_reject",
            "name": this.$locale.databus.event_panel.event_types.sn_reject
          },
          {
            "id": "sn_error",
            "name": this.$locale.databus.event_panel.event_types.sn_error
          },
          {
            "id": "sn_status",
            "name": this.$locale.databus.event_panel.event_types.sn_status
          },
          {
            "id": "sn_response_routing_success",
            "name": this.$locale.databus.event_panel.event_types.sn_response_routing_success
          },
          {
            "id": "sn_response_routing_error",
            "name": this.$locale.databus.event_panel.event_types.sn_response_routing_error
          },
          {
            "id": "sn_response_routing",
            "name": this.$locale.databus.event_panel.event_types.sn_response_routing
          }
        ];
      }
    },
    mounted () {
      this.loadTopicList();
      this.loadEvents();
    }
  }
