import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProcedureDeleteCommand from '@/services/LogicEditor/application/command/ProcedureDeleteCommand'
import ProcedureCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ProcedureCommandRepositoryInterface'

export default class ProcedureDeleteHandler implements CommandHandlerInterface {
    private repository: ProcedureCommandRepositoryInterface;

    constructor (repository: ProcedureCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ProcedureDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
