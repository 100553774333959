import { unserialize } from '../utils/Serializer.js'

export default {
  filters: {
    variableValue: ({
      double_,
      long_,
      text_,
      text2_,
      bytes_,
      var_type_
    } = {}) => {
      switch (var_type_) {
        case 'integer':
          return long_ || double_
        case 'string':
          return text_ || text2_
        case 'serializable':
          try {
            return unserialize(bytes_) || bytes_
          } catch (e) {
            return bytes_
          }
        default:
          return null
      }
    }
  }
}
