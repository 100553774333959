import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import NotificationDeleteCommand from '@/services/NotificationEditor/application/command/NotificationDeleteCommand'
import NotificationCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/NotificationCommandRepositoryInterface'

export default class NotificationDeleteHandler implements CommandHandlerInterface {
  private repository: NotificationCommandRepositoryInterface;

  constructor (repository: NotificationCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: NotificationDeleteCommand): Promise<void> {
    return this.repository.delete(command.getId())
  }
}
