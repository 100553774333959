import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RasterQueryRepositoryInterface from '../../../domain/repository/RasterQueryRepositoryInterface'
import RastersCountQuery from '../../query/RastersCountQuery'

export default class RastersCountHandler implements QueryHandlerInterface
{
    private repo: RasterQueryRepositoryInterface;

    constructor(repo: RasterQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: RastersCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}