<template>
  <draggable class="draggable" v-model="model" group="people" @update="update">
    <div v-for="(layer, index) in layers" :key="index" class="row">
      <span style="flex: 1;min-width: 0;">
        <LayerIcon v-if="layer.getProperties()['icon_style_type_id'] != null" :type="layer.getProperties()['icon_style_type_id']" :properties="layer.getProperties()['style_properties'][`${layer.getProperties()['icon_style_type_id']}_style`]"/>
        <span class="label">{{ layer.getProperties()['name'] }}</span>
      </span>
      <span class="index">{{ index + 1}}</span>
    </div>
  </draggable>
</template>

<script type="ts">

import draggable from 'vuedraggable'
import LayerIcon from '@/core/infrastructure/components/LayerIcon.vue'

export default {
  name: 'LayersZindexPanel',
  props: ['layers'],
  components: {
    draggable, LayerIcon
  },
  data() {
    return { 
      model: []
    }
  },
  methods: {
    update() {
      this.model.forEach((item, index) => {
        item.setZIndex(100 + index);
      });
    }
  },
  mounted () {
    this.model = this.layers;
  }
}
</script>

<style scoped>
.draggable {
  /* font-size: 13px; */
  margin-top: 10px;
}
.row {
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;
}
/* .checkbox {
  margin-right: 8px;
} */
.row:hover {
  background-color: rgb(245 247 250);
}
.label {
  color: #2D3239;
  white-space: nowrap;
  width: calc(100% - 40px);
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
/* .dot {
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
  vertical-align: text-top;
} */
.index {
  position: absolute;
  right: 10px;
  color: #c0c4cc;
}
</style>
