import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SettingsQueryRepositoryInterface from '../../../domain/repository/SettingsQueryRepositoryInterface'
import SettingsCountQuery from '../../query/SettingsCountQuery'

export default class SettingsCountHandler implements QueryHandlerInterface
{
    private repo: SettingsQueryRepositoryInterface;

    constructor(repo: SettingsQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: SettingsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}