import store from '@/store'
import NotificationCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/NotificationCommandRepositoryInterface'
import { CreateNotification, UpdateNotification, DeleteNotification } from '@/services/NotificationEditor/infrastructure/store/modules/Notification/action-types'
import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'

export default class NotificationCommandRepository implements NotificationCommandRepositoryInterface {
  insert (item: NotificationDTO): Promise<void> {
    return store.dispatch(new CreateNotification(item))
  }

  update (item: NotificationDTO): Promise<void> {
    return store.dispatch(new UpdateNotification(item))
  }

  delete (id: number): Promise<void> {
    return store.dispatch(new DeleteNotification(id))
  }
}
