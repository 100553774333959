import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import FormulaDeleteCommand from '@/services/LogicEditor/application/command/FormulaDeleteCommand'
import FormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/FormulaCommandRepositoryInterface'

export default class FormulaDeleteHandler implements CommandHandlerInterface {
    private repository: FormulaCommandRepositoryInterface;

    constructor (repository: FormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: FormulaDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
