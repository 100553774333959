import CommandQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import CommandEventTypePropertiesQuery from '@/services/LogicEditor/application/query/CommandEventTypePropertiesQuery'

export default class CommandEventTypePropertiesHandler implements QueryHandlerInterface {
  private repository: CommandQueryRepositoryInterface

  constructor (repository: CommandQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: CommandEventTypePropertiesQuery): Promise<any> {
    return this.repository.getEventTypeProperties(query.getType())
  }
}
