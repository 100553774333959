<template>
  <div class="properties_form">
    <component
      v-for="item in properties"
      v-model="value[value.findIndex(prop => prop.id === item.id)].value"
      :key="item.id"
      size="mini"
      title-width="200px"
      :label="$locale.etl_editor_v2.labels.properties[item.name]"
      :registry-id="objectId"
      :is="isComponent(item.type)">
    </component>
  </div>
</template>

<script>
import PropBoolean from './prop-boolean'
import PropCoordinateSystem from './prop-coordinate-system'
import PropField from './prop-field'
import PropFieldMulti from './prop-field-multi'
import PropInteger from './prop-integer'
import PropObject from './prop-object'
import PropQuery from './prop-query'
import PropTemplate from './prop-template'
import PropTopic from './prop-topic'
import PropVarchar from './prop-varchar'

export default {
  name: 'PropertiesForm',

  props: {
    value: Array,
    properties: Array
  },

  components: {
    PropBoolean,
    PropFieldMulti,
    PropInteger,
    PropObject,
    PropVarchar,
    PropTemplate,
    PropTopic,
    PropField,
    PropCoordinateSystem,
    PropQuery
  },

  computed: {
    objectId () {
      let index = this.value.findIndex(prop => prop.id === 'object_id')

      if (index !== -1) {
        return this.value[index].value
      }

      return null
    }
  },

  methods: {
    isComponent (type) {
      let componentName = `Prop${this.toCamelCase(type)}`

      return componentName in this.$options.components ? componentName : null
    },

    toCamelCase (s) {
      return s.replace(/^([a-z]{1})/g, ($1) => {
        return $1.toUpperCase()
      }).replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
  }
}
</script>

<style scoped></style>
