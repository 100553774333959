import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  GetGroups,
  GetGroupByGuid
} from '@/services/CssEditor/infrastructure/store/modules/Group/action-types'
import GroupQueryRepositoryInterface from '@/services/CssEditor/domain/repository/GroupQueryRepositoryInterface'

export default class GroupQueryRepository extends AbstractQueryRepository implements GroupQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetGroups(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetGroupByGuid(guid))
  }
}
