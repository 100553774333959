import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import TopicRightQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/TopicRightQueryRepositoryInterface'
import store from '@/store'
import { GetTopicRights, CountTopicRights } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class TopicRightQueryRepository extends AbstractQueryRepository implements TopicRightQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTopicRights(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new CountTopicRights(payload))
  }
}
