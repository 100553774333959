import CommandInterface from '@/core/application/command/CommandInterface'
import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'

export default class NotificationUpdateCommand implements CommandInterface {
  private dto: NotificationDTO;

  constructor (
    dto: NotificationDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'NotificationUpdateCommand'
  }

  getDto (): NotificationDTO {
    return this.dto
  }
}
