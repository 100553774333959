import Vue, { PropType } from 'vue'

export interface IVariableOption {
  label: string;
  value: string;
}

export interface IVariables {
  [p: string]: any
}

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    placeholder: {
      type: String as PropType<string>,
      default: ''
    },

    size: {
      type: String as PropType<string>,
      default: 'small'
    },

    variables: {
      type: Object as PropType<IVariables>,
      default () {
        return {}
      }
    },

    variableList: {
      type: Array as PropType<IVariableOption[]>,
      default () {
        return []
      }
    },

    currentVariable: {
      type: String as PropType<string>,
      default () {
        return '$--color-primary'
      }
    }
  }
})
