<template>
  <div class="attribute_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.mapping.labels[`name_${model.element_type_id}`]" prop="source_column">
        <el-input v-model="model.source_column" :placeholder="$locale.etl_editor_v2.mapping.labels[`name_${model.element_type_id}`]" @input="maskedElement"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.alias">
        <el-input v-model="model.source_alias" :placeholder="$locale.etl_editor_v2.mapping.labels.alias"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.description">
        <el-input v-model="model.description" :placeholder="$locale.etl_editor_v2.mapping.labels.description"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.field">
        <el-select v-model="model.target_field_id" :placeholder="$locale.etl_editor_v2.mapping.labels.field" clearable @change="selectTargetFieldId">
          <el-option
            v-for="item in targetFields"
            :key="item.id"
            :label="`${item.name} (attr_${item.id}_)`"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="model.is_key">
          {{ $locale.etl_editor_v2.mapping.labels.is_key }}
        </el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="model.is_required">
          {{ $locale.etl_editor_v2.mapping.labels.is_required }}
        </el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox v-model="model.is_load_xref_table_values">
          {{ $locale.etl_editor_v2.mapping.labels.is_load_xref_table_values }}
        </el-checkbox>
      </el-form-item>

      <template v-if="xrefObjectId !== null">
        <el-form-item :label="$locale.etl_editor_v2.mapping.labels.xref_field_id">
          <registry-select-tree
            v-model="model.xref_field_id"
            value-as="number"
            type="field"
            clearable
            :placeholder="$locale.etl_editor_v2.mapping.labels.xref_field_id"
            :parent-id="xrefObjectId">
          </registry-select-tree>
        </el-form-item>

        <el-form-item :label="$locale.etl_editor_v2.mapping.labels.xref_condition">
          <el-input
            v-model="model.xref_condition"
            :placeholder="$locale.etl_editor_v2.mapping.labels.xref_condition">
          </el-input>
        </el-form-item>
      </template>

      <el-form-item :label="$locale.etl_editor_v2.mapping.labels.transformer_id">
        <el-select v-model="model.transformer_id" :placeholder="$locale.etl_editor_v2.mapping.labels.transformer_id" size="mini" filterable clearable>
          <el-option v-for="item in transformers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'AttributeForm',

  props: {
    value: Object
  },

  components: {
    RegistrySelectTree
  },

  inject: ['getTargetFields', 'getTransformers'],

  watch: {
    value () {
      this.model = this.value
    }
  },

  data () {
    return {
      model: this.model,
      isLoaded: false,
      xrefObjectId: null,
      targetFields: [],
      transformers: [],
      rules: {
        source_column: [{ required: true, message: this.$locale.etl_editor_v2.rules.required, trigger: 'blur' }]
      }
    }
  },

  mounted () {
    this.targetFields = this.getTargetFields()
    this.transformers = this.getTransformers()

    if (this.model.target_field_id !== null) {
      this.$http
        .get(`${this.$config.api}/registryservice/xref/${this.model.target_field_id}`)
        .then((response) => {
          if (response.data !== null) {
            this.xrefObjectId = response.data.xref_object_id
          }
        })
    }

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    selectTargetFieldId (value) {
      this.xrefObjectId = this.model.xref_field_id = null
      if (value !== null) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${value}`)
          .then((response) => {
            if (response.data !== null) {
              this.xrefObjectId = response.data.xref_object_id
            }
          })
      }
    },

    maskedElement (value) {
      value = value.replace(/(\s)+/g, '-').replace(/[^_:.\-0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.model.source_column = value
    },

    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          callback()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped></style>
