<template>
  <div v-loading="loading">
    <el-button type="text" icon="el-icon-refresh-right" circle @click="loadData"></el-button>
    <b>Обновлено:</b>
    <template v-if="deployments.length > 0">
      {{ $moment(new Date(deployments[0].deploy_time_)).format('DD.MM.YYYY HH:mm:ss') }}
    </template>
    <template v-else>
      Нет данных
    </template>
  </div>
</template>
<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MonitoringAPI } from '@/services/BpmnGateway/infrastructure/api/MonitoringAPI'

export default {
  name: 'DeploymentBlock',
  props: {
    processId: Number
  },
  data () {
    return {
      deployments: [],
      loading: false
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.loading = true
      this.deployments = await APIClient.shared.request(new MonitoringAPI.GetDeployments(this.processId))
      this.loading = false
    }
  }
}
</script>
<style scoped>

</style>
