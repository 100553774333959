<template>
  <ul class="v-context">
    <li :class="{ 'v-context__sub': item[prop.children] && item[prop.children].length }" v-for="item in items" :key="item[prop.key]">
      <a href="#" @click.prevent="menuItemClick(item[prop.key])">{{ item[prop.label] }}</a>
      <context-child v-if="item[prop.children] && item[prop.children].length" :items="item[prop.children]" :menu-item-click="menuItemClick" :prop="prop"></context-child>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ContextChild',

  props: {
    items: Array,

    prop: {
      type: Object,
      default () {
        return {
          key: 'key',
          label: 'label',
          children: 'children'
        }
      }
    },

    menuItemClick: {
      type: Function,
      default () {
        return function (itemId) {
        }
      }
    }
  }
}
</script>

<style scoped></style>
