export default class StrokeStyleCreateCommand
{
    private name: string;
    private description: string;
    private pattern: Array<string>;

    constructor(
        name: string,
        description: string,
        pattern: Array<string>
    ) {
        this.name = name;
        this.description = description;
        this.pattern = pattern;
    }

    getClassName(): string
    {
        return 'StrokeStyleCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): string
    {
        return this.description;
    }

    getPattern(): Array<string>
    {
        return this.pattern;
    }
}