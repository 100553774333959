import { Nullable } from '@/core/domain/type/types'
import CommandInterface from '@/core/application/command/CommandInterface'

export default class CoordinateSystemDeleteCommand implements CommandInterface
{
    private srid: number;
    private projectGuid: Nullable<string>;

    constructor(srid: number, projectGuid: Nullable<string>)
    {
        this.srid = srid;
        this.projectGuid = projectGuid;
    }

    getSrid(): number
    {
        return this.srid;
    }

    getProjectGuid(): Nullable<string>
    {
        return this.projectGuid;
    }

    getClassName(): string
    {
        return 'CoordinateSystemDeleteCommand';
    }  
}