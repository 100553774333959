import RowFormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RowFormulaCommandRepositoryInterface'
import store from '@/store'
import { CreateRowFormula, UpdateRowFormula, DeleteRowFormula } from '@/services/LogicEditor/infrastructure/store/modules/RowFormula/action-types'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'

export default class RowFormulaCommandRepository implements RowFormulaCommandRepositoryInterface {
  insert (item: RowFormulaDTO): Promise<void> {
    return store.dispatch(new CreateRowFormula(item))
  }

  update (item: RowFormulaDTO): Promise<void> {
    return store.dispatch(new UpdateRowFormula(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteRowFormula(guid))
  }
}
