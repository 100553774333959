import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TreeElementByElementGuidQuery from '@/services/EtlEditor/application/query/TreeElementByElementGuidQuery'
import TreeElementQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TreeElementQueryRepositoryInterface'

export default class TreeElementByElementGuidHandler implements QueryHandlerInterface {
  private repository: TreeElementQueryRepositoryInterface

  constructor (repository: TreeElementQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TreeElementByElementGuidQuery): Promise<any> {
    return this.repository.getByElementGuid(query.getElementGuid())
  }
}
