<template>
  <el-form-item :label="label" :label-width="titleWidth">
    <registry-select-tree
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      value-as="number"
      type="registry" />
  </el-form-item>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'prop-object',

  props: ['value', 'size', 'label', 'titleWidth'],

  components: {
    RegistrySelectTree
  }
}
</script>

<style type="text/css">
.tree-xref.el-tree {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #dcdfe6;
}
</style>
