import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import RegionDTO from '@/services/GarAddressService/domain/model/Region'

export namespace RegionAPI {

  export class GetRegions implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    params: string[][]
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/garaddressservice/regions'
      this.params = payload
    }
  }

  export class UpdateRegion implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.PUT;
    parse = (data: AxiosResponse) => data.data
    constructor (public params: RegionDTO) {
      this.path = `/garaddressservice/regions/${params.code}`
    }
  }
}
