import TaskQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TaskObjectByTaskIdQuery from '@/services/EtlEditor/application/query/TaskObjectByTaskIdQuery'

export default class TaskObjectByTaskIdHandler implements QueryHandlerInterface {
  private repository: TaskQueryRepositoryInterface;

  constructor (repository: TaskQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TaskObjectByTaskIdQuery): Promise<any> {
    return this.repository.getTaskObjectByTaskId(query.getTaskId())
  }
}
