<template>
  <div class="raster-job-panel custom_scrollbar">
    <div class="close"><i class="el-icon-close close-button" @click="close"></i></div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{$locale.raster_library.jobs}}</span>
      </div>
    </el-card> 
  </div>
</template>

<script type="ts">

//import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {
  name: 'RasterJobPanel',
  //props: ['connection'],
  inject: ['getEventBus'],
  data() {
    return {
      conn: null
    }
  },
  methods: {
    buildConnection() {
      //this.conn = this.connection;
      if (this.conn != null) {
        return;
      }
      //проверить при повторном открытии окна!!
      this.conn = new HubConnectionBuilder()
      .withUrl('/rastersstorageservice/rasterhub')
      .configureLogging(LogLevel.Information)
      .build();

      this.conn.onclose(async () => {
        await this.startConnection();
      });

      this.conn.on("ReceiveMessage", (message) => {
        console.log("message: ", message);
      });

      this.getEventBus().$emit('setHubConnection', this.conn);
    },
    async startConnection() {
      try {
        await this.conn.start();
        console.log("SignalR Connected.");
      } catch (err) {
        console.log(err);
        setTimeout(this.startConnection, 5000);
      }
    },
    close() {
      this.conn.close();
      this.getEventBus().$emit('showRasterJobPanel', false);
    },
  },
  mounted() {
    this.buildConnection();
    this.startConnection();
  }
}
</script>