export default class PointStyleImageCreateCommand
{
    private name: string;
    private image: File;

    constructor(
        name: string,
        image: File
    ) {
        this.name = name;
        this.image = image;
    }

    getClassName(): string
    {
        return 'PointStyleImageCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getImage(): File
    {
        return this.image;
    }
}