import CommandQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import QueryInterface from '@/core/application/query/QueryInterface'

export default class CommandEventTypesHandler implements QueryHandlerInterface {
  private repository: CommandQueryRepositoryInterface;

  constructor (repository: CommandQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: QueryInterface): Promise<any> {
    return this.repository.getEventTypes()
  }
}
