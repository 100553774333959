import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'
import LogicGroupUpdateCommand from '@/services/LogicEditor/application/command/LogicGroupUpdateCommand'
import LogicGroupCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicGroupCommandRepositoryInterface'

export default class LogicGroupUpdateHandler implements CommandHandlerInterface {
    private repository: LogicGroupCommandRepositoryInterface;

    constructor (repository: LogicGroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicGroupUpdateCommand): Promise<void> {
      let item: LogicGroupDTO = {
        guid: command.getGuid(),
        description: command.getDescription(),
        name: command.getName(),
        alias: command.getAlias()
      }

      return this.repository.update(item)
    }
}
