<template>
  <el-select v-bind:value="value" v-on:input="$emit('input', $event)" :placeholder="placeholder">
    <el-option
      v-for="item in types"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'Condition',

  props: {
    value: String,

    placeholder: {
      type: String,
      default: 'Condition'
    }
  },

  data () {
    return {
      types: [
        {
          id: 'empty',
          name: 'Пусто'
        },
        {
          id: 'not_empty',
          name: 'Не пусто'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
