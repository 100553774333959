
  import { VueContext } from 'vue-context'
  import { Loading } from 'element-ui'
  import MapManager from '@bingo_soft/mapmanager'
  import ElCollapsibleTabs from '@/core/infrastructure/components/CollapsibleTabs.vue'
  import EditorTree from '@/core/infrastructure/components/EditorTree.vue'
  import MapPanel from '@/services/MapEditor/infrastructure/components/MapPanel/index.vue'
  import FormSet from '@/services/MapEditor/infrastructure/components/FormSet/index.vue'
  import LinkToRegistryForm from '@/services/MapEditor/infrastructure/components/LinkToRegistryForm/index.vue'
  import ImportForm from '@/services/MapEditor/infrastructure/components/ImportForm/index.vue'
  import ExportForm from '@/services/MapEditor/infrastructure/components/ExportForm/index.vue'
  import LayerGroupCreateCommand from '@/services/MapEditor/application/command/LayerGroupCreateCommand'
  import LayerGroupUpdateCommand from '@/services/MapEditor/application/command/LayerGroupUpdateCommand'
  import LayerTreeElementsQuery from '@/services/MapEditor/application/query/LayerTreeElementsQuery'
  import LayerTreeElementByGuidQuery from '@/services/MapEditor/application/query/LayerTreeElementByGuidQuery'
  import LayerByGuidQuery from '@/services/MapEditor/application/query/LayerByGuidQuery'
  import LayersQuery from '@/services/MapEditor/application/query/LayersQuery'
  import CoordinateSystemBySridQuery from '@/services/MapEditor/application/query/CoordinateSystemBySridQuery'
  import LayerTreeElementDeleteCommand from '@/services/MapEditor/application/command/LayerTreeElementDeleteCommand'
  import LayerTreeElementLayerPutCommand from '@/services/MapEditor/application/command/LayerTreeElementLayerPutCommand'
  import LayerTreeAddChildElementCommand from '@/services/MapEditor/application/command/LayerTreeAddChildElementCommand'
  import LayerTreeElement, { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'
  import Layer from '@/services/MapEditor/domain/model/Layer/Layer'
  import LayerGroup, { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'
  import LayerTreeElementType from '@/services/MapEditor/domain/model/Layer/LayerTreeElementType'
  import LayerIcon from '@/core/infrastructure/components/LayerIcon.vue'
  import LayersZindexPanel from '@/services/MapEditor/infrastructure/components/LayersZindexPanel/index.vue'
  import FeatureInformationPanel from '@/services/MapEditor/infrastructure/components/FeatureInformationPanel/index.vue'
  
  export default {
    name: 'LayerPanel',
    components: {
      ElCollapsibleTabs,
      MapPanel,
      LayersZindexPanel,
      FormSet,
      EditorTree,
      VueContext,
      LayerIcon,
      LinkToRegistryForm,
      ImportForm,
      ExportForm,
      FeatureInformationPanel
    },
    props: ['mapId', 'mapInstance'],
    data () {
      return {
        allowNodeDrag: true,
        mapMounted: false,
        showSelectProject: false,
        selectProjectItems: [],
        loadResolve: null,
        layerFormSetItems: [{
          type: "layer",
          title: this.$locale.map_editor.layer_form.add_layer_op
        }],
        linkToRegistryId: null,
        layersForImportForm: [],
        layersForExportForm: [],
        activeTabName: null,
        groupRules: {
          name: {
            required: true,
            message: this.$locale.map_editor.errors.name_required,
            trigger: 'change'
          }
        },
        layerPanelCollapsed: true,
        activeProjectTreeElement: null,
        //activeProjectLayersTab: null,
        //projectTreeElements: [],
        layerPanelDeafultWidth: null,
        layerSelectedForEdit: null,
        layerDtoSelectedFromContextMenu: null,
        showLayerForm: false,
        layerTreeNodeProps: {
          isLeaf: 'is_leaf',
          label: 'name',
          children: 'child'
        },
        elementBuilder: LayerTreeElement,
        selectedLayerTreeElement: {},
        selectedLayerTreeElementDto: {},
        isCreateGroupVisible: false,
        parentNodeId: {},
        loadedLayers: {},
        allLayers: [],
        opacityValue: 100,
        dirtyLayers: [],
        selectedFeatures: [],
      }
    },  
    inject: ['getEventBus', 'getQueryBus', 'getCommandBus'/* , 'getProjectTabsContainer' */],
    provide() {
      return {
        getLayerSelectedForEdit: this.getLayerSelectedForEdit
      }
    },
    watch: {   
      isLayerGroupLoading: (state) => state,
      layerGroupCreated: function (location) {
        let me = this;
        this.getQueryBus().execute(
          new LayerTreeElementByGuidQuery(
            location.replace('/layer_tree_elements/', '')
          )
        ).then(function(elementDto){
          me.isCreateGroupVisible = false;
          me.$refs.layerTree.addEditorElement(elementDto);
          /*for (let id in me.$refs.layerTree) {
            if (me.$refs.layerTree[id].id == me.activeProjectTreeElement.getProjectId()) {
              me.$refs.layerTree[id].addEditorElement(elementDto);
              break;
            }
          }*/ 
        });
      },
      layerTreeElementCreatedEvent: function (location) {
        let me = this;
        this.getQueryBus().execute(
          new LayerTreeElementByGuidQuery(
            location.replace('/layer_tree_elements/', '')
          )
        ).then(function(elementDto){
          me.$refs.layerTree.addEditorElement(elementDto);
          /*for (let id in me.$refs.layerTree) {
            if (me.$refs.layerTree[id].id == elementDto.project_id) {
              me.$refs.layerTree[id].addEditorElement(elementDto);
              break;
            }
          } */
        });
      }
    },
    computed: {
      console: () => console,
      isLayerGroupLoading() {
        return this.$store.getters['LayerGroup/isLoading'];     
      },
      layerGroupCreated() {
        return this.$store.getters['LayerGroup/getLocation'];
      },
      layerTreeElementCreatedEvent() {
        return this.$store.getters['LayerTreeElement/getElementLocation'];
      }
    },
    methods: {
      debug (message) {
        console.log(message)
      },
      collapsePanel() {
        if (this.layerPanelDeafultWidth == null) {
          this.layerPanelDeafultWidth = this.$refs.layerPanel.$el.offsetWidth;
        }
        this.layerPanelCollapsed = !this.layerPanelCollapsed;
        this.$refs.layerPanel.$el.style.width = this.layerPanelCollapsed ? "0px" : (this.layerPanelDeafultWidth + "px");
        this.getEventBus().$emit('updateMapSize');
      },
      showProjectLayerPanel (projectTreeElement) {
        this.activeProjectTreeElement = null;
        MapManager.setActiveLayer(this.mapInstance, null);
        MapManager.setNormalInteraction(this.mapInstance);
        this.$refs.mapPanel.setMapCoordinatesInteraction();
        this.$nextTick(() => {      
          this.activeProjectTreeElement = projectTreeElement;
        });
      },
      removeProjectLayerPanel(project) {
        this.activeProjectTreeElement = null;
        MapManager.setActiveLayer(this.mapInstance, null);
        MapManager.setNormalInteraction(this.mapInstance);
      },
      addLayerTreeElement(command) {
        if (this.activeProjectTreeElement != null) {
        let projectId = this.activeProjectTreeElement.getProjectId();
          if (command == 'add_new_layer') {
            this.showLayerForm = false; 
            this.$nextTick(() => {
              this.layerFormSetItems = [{
                type: "layer",
                title: this.$locale.map_editor.layer_form.add_layer_op,
                action: this.$locale.map_editor.layer_form.save_layer_action
              }];
              this.showLayerForm = true; 
              this.getEventBus().$emit('layerFormVisible');
            });           
          } else if (command == 'add_group') {
            this.isCreateGroupVisible = true;
            this.$set(this.selectedLayerTreeElementDto, projectId, new LayerTreeElementDTO({project_id: projectId}));
            this.$set(this.selectedLayerTreeElementDto[projectId], 'name', null);
          } else if (command == 'put_existing_layer') {
            this.showLayerForm = false;
            this.$nextTick(() => {
              this.layerFormSetItems = [{
                type: "listof_layers",
                title: this.$locale.map_editor.entities.layers,
                action: this.$locale.map_editor.layer_form.select_layer_action,
                hide_submit: false,
                hide_all_layers: false,
                projectId: this.projectId
              }];
              this.showLayerForm = true;
            });
            this.getEventBus().$emit('selectLayersFromLayerTree');      
          }
        }
      },
      editLayerTreeElement() {
        if (this.activeProjectTreeElement != null) {
          let projectId = this.activeProjectTreeElement.getProjectId();
          if (!this.selectedLayerTreeElement.hasOwnProperty(projectId) || this.selectedLayerTreeElement[projectId] == null) {
            this.$message({
              message: this.$locale.main.message.select_record,
              type: 'warning'
            });
          } else {
            let elementType = this.selectedLayerTreeElement[projectId].getElementType();
            if (elementType == LayerTreeElementType.LAYER) {
              let layer = this.selectedLayerTreeElement[projectId].getLayer();
              this.getQueryBus().execute(
                new LayersQuery({"guid": layer.getGuid(), "project_id": projectId})
              ).then((data) => {
                let layer = Layer.create(data[0]);
                this.showLayerForm = false; 
                this.$nextTick(() => {
                  this.layerFormSetItems = [{
                    type: "layer",
                    title: this.$locale.map_editor.layer_form.edit_layer_op,
                    action: this.$locale.map_editor.layer_form.save_layer_action,
                    params: {
                      layer: layer,
                      layerTreeElementDto: this.selectedLayerTreeElementDto[projectId]
                    }
                  }];
                  this.showLayerForm = true; 
                  this.getEventBus().$emit('layerFormVisible');
                });
              });
            } else {
              let layerGroup = this.selectedLayerTreeElement[projectId].getGroup();
              this.layerGroupDto = new LayerGroupDTO({
                id: layerGroup.getId(),
                guid: layerGroup.getGuid(),
                name: layerGroup.getName()
              });
              this.isCreateGroupVisible = true;
            }
          }
        }
      },
      removeLayerTreeElement() {
        if (this.activeProjectTreeElement != null) {
          let projectId = this.activeProjectTreeElement.getProjectId();
          if (!this.selectedLayerTreeElement.hasOwnProperty(projectId) || this.selectedLayerTreeElement[projectId] == null) {
            this.$message({
              message: this.$locale.main.message.select_record,
              type: 'warning'
            });
          } else {
            this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
              confirmButtonText: this.$locale.main.button.delete,
              cancelButtonText: this.$locale.main.button.cancel,
              type: 'warning'
            }).then(async () => {
              let payload = {
                type: 'tree'
              };
              this.getCommandBus().execute(
                new LayerTreeElementDeleteCommand(
                  this.selectedLayerTreeElement[projectId].getGuid(),
                  payload
                )
              ).then((response) => {
                this.getEventBus().$emit('removeLayerTreeElement', this.selectedLayerTreeElement[projectId]);
                this.$refs.layerTree.remove(this.selectedLayerTreeElementDto[projectId]);               
                this.selectedLayerTreeElement[projectId] = null;
                this.selectedLayerTreeElementDto[projectId] = null;            
              });
            }).catch((error) => { console.log(error); })
          }
        }
      },
      getLayerSelectedForEdit() {
        return this.layerSelectedForEdit;
      },
      selectSourcesFromContextMenu() {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "listof_sources",
            title: this.$locale.map_editor.entities.sources,
            hide_submit: true,
            hide_all_sources: true
          }];
          this.showLayerForm = true;
        });      
      },
      selectProject(parentId) {
        this.showSelectProject = false;
        this.$nextTick(() => {        
          this.selectProjectItems = [{
            type: "project_select",
            title: this.$locale.map_editor.entities.projects,
            hide_submit: false,
            hide_all_projects: false,
            action: this.$locale.map_editor.layer_form.select_project_action
          }];
          this.showSelectProject = true;
        });     
      },
      selectLayersFromContextMenu() {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "listof_layers",
            title: this.$locale.map_editor.entities.layers,
            hide_submit: true,
            hide_all_layers: true
          }];
          this.showLayerForm = true;
        });      
      },
      selectCoordinateSystemsFromContextMenu() {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "listof_coordinate_systems",
            title: this.$locale.map_editor.entities.coordinate_systems,
            hide_submit: true,
            hide_all_coordinate_systems: false
          }];
          this.showLayerForm = true;
        });      
      },
      selectCoordinateSystem() {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "coordinate_system_select",
            title: this.$locale.map_editor.entities.coordinate_systems,
            hide_submit: false,
            hide_all_coordinate_systems: false,
            action: this.$locale.map_editor.layer_form.select_coordinate_system_action
          }];
          this.showLayerForm = true;
        });    
      },
      coordinateSystemTextEditorForm(type, map, layer, feature) {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "geometry_text_editor_form",
            title: this.$locale.map_editor.layer_panel.geometry_text_editor,
            action: this.$locale.map_editor.layer_form.save,
            params: {
              type: type,
              map: map,
              layer: layer,
              feature: feature
            }
          }];
          this.showLayerForm = true;
        });
      },
      vertexEditPanel(type, map, layer, feature, srsId, srsIds) {
        this.showLayerForm = false;
        this.$nextTick(() => {
          this.layerFormSetItems = [{
            type: "vertex_edit_panel",
            title: this.$locale.map_editor.layer_panel.vertex_edit_panel,
            action: this.$locale.map_editor.layer_form.save,
            params: {
              type: type,
              map: map,
              layer: layer,
              feature: feature,
              srsId: srsId,
              srsIds: srsIds
            }
          }];
          this.showLayerForm = true;
        });
      },
      linkToRegistryForm(registryId) {
        this.$refs.linkToRegistryForm.hideForm();
        this.$nextTick(() => {
          this.linkToRegistryId = parseInt(registryId);
          this.$refs.linkToRegistryForm.showForm();
        });
      },
      importForm() {
        this.$refs.importForm.hideForm();
        this.$nextTick(() => {
          this.layersForImportForm = [{"id":1495,"id_obj":97442,"point_hash":"Point_1_1_0_0_#000000_100","line_hash":"LineString_1_1_0_0_1_0_#000000_100","polygon_hash":"Polygon_1_1_0_0_1_0_1_0_0_#000000_100_#FFFFFF_100","name":"Первомайск. ЛЭП","srs":"standard_4326"},{"id":1197,"id_obj":0,"point_hash":"Point_1_1_0_0_#000000_100","line_hash":"LineString_1_1_0_0_1_0_#000000_100","polygon_hash":"Polygon_1_1_0_0_1_0_1_0_0_#000000_100_#FFFFFF_100","name":"Границы г.Бор (КПТ)","srs":"proj_2"},{"id":2438,"id_obj":92898,"point_hash":"Point_2_1_0_0_#333333_100","line_hash":"LineString_2_1_0_0_1_0_#333333_100","polygon_hash":"Polygon_2_1_0_0_1_0_1_0_0_#333333_100_#FFFF99_100","name":"Зона планируемого размещения объекта г.о.г Саров","srs":"proj_2"},{"id":3277,"id_obj":94519,"point_hash":"Point_1_1_0_0_#000000_100","line_hash":"LineString_1_1_0_0_1_0_#000000_100","polygon_hash":"Polygon_1_1_0_0_1_0_1_0_0_#000000_100_#FFFFFF_100","name":"ПЗЗ Кудеяровский сельсовет","srs":"proj_2"}];  
          this.$refs.importForm.showForm();
        });
      },
      exportForm() {
        this.$refs.exportForm.hideForm();
        this.$nextTick(() => {
          this.layersForExportForm = [];
          this.$refs.exportForm.showForm();
        });
      },
      async loadLayerTree(node, resolve) {
        this.loadResolve = resolve;
        this.getQueryBus().execute(
          new LayerTreeElementsQuery(
            ( node == null || node.level === 0 ) ? null : node.data.id,
            this.activeProjectTreeElement.getProjectId()
          )
        ).then((elements) => {
          resolve(elements);
        });
      },
      selectLayerTreeNode(elementDto) {
        this.$set(this.selectedLayerTreeElementDto, elementDto.project_id, elementDto);
        this.$set(this.selectedLayerTreeElement, elementDto.project_id, LayerTreeElement.create(elementDto));      
        if (this.selectedLayerTreeElement[elementDto.project_id].getElementType() == LayerTreeElementType.GROUP) {
          this.parentNodeId[elementDto.project_id] = this.selectedLayerTreeElement[elementDto.project_id].getId();
        } else {
          this.parentNodeId[elementDto.project_id] = this.selectedLayerTreeElement[elementDto.project_id].getParentId();
          let layerId = elementDto.layer_id;
          if (this.loadedLayers.hasOwnProperty(layerId)) {
            MapManager.setActiveLayer(this.mapInstance, this.loadedLayers[layerId]);
          }
        }
      },
      checkLayerTreeNode(dto, checked) {
        let selectedElement = LayerTreeElement.create(dto);
        let elementType = selectedElement.getElementType();
        if (elementType == LayerTreeElementType.LAYER) {
          if (checked) {
            this.checkLayerNode(dto);
          } else {
            this.uncheckLayerNode(dto);
          }
        } else {
          if (checked) {
            this.checkGroupNode(dto);
          } else {
            this.uncheckGroupNode(dto);
          }
        }
      },
      async checkLayerNode(dto) {
          if (this.loadedLayers.hasOwnProperty(dto.layer_id)) {
            MapManager.addLayer(this.mapInstance, this.loadedLayers[dto.layer_id]);
          } else {
            let loader;
            if (dto.layer_type_id != 'raster') {
              loader = Loading.service({
                text: this.$locale.main.loading_text,
                target: this.$refs.layerPanel.$el
              });
            }
            try {  
              let styleObject = this.buildStyleFromObject(dto.style_properties);
              let opts = {
                "srs_handling": {
                  native_coordinate_system_id: dto.native_coordinate_system_id,
                  declared_coordinate_system_id: dto.declared_coordinate_system_id,
                  srs_handling_type: dto.srs_handling_type
                },
                "request": {
                  method: 'post',
                  base_url: `${this.$config.api}/mapeditor/geojson/layer/${dto.layer_id}`,              
                  headers: null,
                  data: null
                },
                "style": styleObject,
                "load_callback": (layer) => { console.log("load_callback");
                  MapManager.fitLayer(this.mapInstance, layer);
                  this.loadedLayers[dto.layer_id] = layer;  
                  this.allLayers.push(layer);
                  if (this.selectedLayerTreeElementDto.hasOwnProperty(dto.project_id) && this.selectedLayerTreeElementDto[dto.project_id] != null && dto.layer_id == this.selectedLayerTreeElementDto[dto.project_id].layer_id) {
                    MapManager.setActiveLayer(this.mapInstance, layer);
                  }
                  if (loader) {
                    loader.close();
                  }
                },
                "properties": {
                  id: dto.id,
                  layer_id: dto.layer_id,
                  name: dto.name,
                  style: styleObject,
                  style_properties: dto.style_properties,
                  icon_style_type_id: dto.icon_style_type_id,
                  geometry_object_id: dto.geometry_object_id,
                  geometry_field: dto.geometry_field
                }
              };
              //// попросить Артёма сунуть это в layer_tree_elements_view 
              let sourceType = dto.layer_type_id;
              if (dto.layer_type_id == 'raster') {
                  const data = await this.getQueryBus().execute(
                      new LayersQuery({"guid": dto.element_guid, "project_id": this.activeProjectTreeElement.getProjectId()})
                  );
                  sourceType = data[0].source.source_type_id;
                  const properties = data[0].source.properties;
                  let property = properties.find(el => el.name == 'url');   
                  opts['request']['base_url'] = property ? property.value : '';
                  property = properties.find(el => el.name == 'params');   
                  opts['request']['params'] = property ? property.value : '';
              }
              ////
              let layer = MapManager.createLayer(
                sourceType,
                opts
              );
              // add layer coordinate system to combobox below the map
              this.addCoordinateSystem(dto);
              MapManager.addLayer(this.mapInstance, layer);
              MapManager.setOpacity(layer, this.opacityValue);
              /* this.loadedLayers[dto.layer_id] = layer;  
              this.allLayers.push(layer);
              if (this.selectedLayerTreeElementDto.hasOwnProperty(dto.project_id) && this.selectedLayerTreeElementDto[dto.project_id] != null && dto.layer_id == this.selectedLayerTreeElementDto[dto.project_id].layer_id) {
                  MapManager.setActiveLayer(this.mapInstance, layer);
              } */
            } catch (err) {
              loader.close();
              console.log(err);
              this.$message({
                message: this.$locale.main.message.loading_error,
                type: 'warning'
              });
            }          
          }
      },
      addCoordinateSystem(properties) {
        const srid = properties.srs_handling_type == 'keep_native' ? properties.native_coordinate_system_id : properties.declared_coordinate_system_id;
        this.getQueryBus().execute(
            new CoordinateSystemBySridQuery(srid)
        ).then((data) => {
            if (data && data.length) {
              this.getEventBus().$emit('addCoordinateSystem', data[0].auth_name, data[0].auth_srid, data[0].proj4text);        
            }
        });
      },
      buildStyleFromObject(properties) {
        let styles = {}, keys = ["point_style", "linestring_style", "polygon_style"];
        for (let i in keys) {
          if (properties[keys[i]].length) {
            let style = properties[keys[i]].reduce((obj, item) => {
              obj[item.name] = item.value;
              if (item.name == "icon_file" && item.value.hasOwnProperty("guid")) {
                obj["image_path"] = `${this.$config.api}/files/mapeditor/images/${item.value.guid}.${item.value.extension}`;
              }
              return obj;
            }, {});
            styles[keys[i].replace("_style", "")] = style;
          } else {
            styles[keys[i].replace("_style", "")] = {};
          }
        }
        return styles;
      },
      uncheckLayerNode(dto) {
        let layerId = dto.layer_id;
        if (this.loadedLayers.hasOwnProperty(layerId)) {
          MapManager.removeLayer(this.mapInstance, this.loadedLayers[layerId]);
        }
      },
      checkGroupNode(dto) {
  
      },
      uncheckGroupNode(dto) {
  
      },
      saveGroupTreeElement() {
        let form = this.$refs.layerGroupForm;
        let projectId = this.activeProjectTreeElement.getProjectId();
        form.validate((valid, invalidFields) => {
          if (valid) {
            let layerGroupElement = LayerTreeElement.create(this.selectedLayerTreeElementDto[projectId]);
            if (typeof layerGroupElement.getId() !== "undefined") {
              this.getCommandBus().execute(
                new LayerGroupUpdateCommand(
                  layerGroupElement.getElementGuid(),
                  layerGroupElement.getName(),
                  projectId
                )
              ).then((response) => { 
                this.isCreateGroupVisible = false;
              });
            } else {
              let parentId = this.parentNodeId.hasOwnProperty(projectId) && this.parentNodeId[projectId] ? this.parentNodeId[projectId] : null;
              this.getCommandBus().execute(
                new LayerGroupCreateCommand(                
                  layerGroupElement.getName(),
                  projectId,
                  parentId
                )
              ).then((response) => { });
            }
          }
        });
      },
      layerTreeElementCreated(element, elementDto, projectId, parentNodeId, parentPanelId) {
        let parent = parentNodeId != null ? this.$refs.layerTree.getNode(parentNodeId) : null;
        this.$refs.layerTree.addEditorNode(elementDto, parent);
      },
      allowDrop(draggingNode, dropNode, type) {
        if (type == "inner") return !dropNode.data.is_leaf;
        return true;
      },
      allowDrag() {
        return this.allowNodeDrag;
      },
      nodeDrop (dragNode, dropNode, type) {
        this.getCommandBus().execute(
          new LayerTreeAddChildElementCommand(
            dropNode.data.id,
            dragNode.data.id
          )
        ).then((response) => {                      
        });
      },
      opacityChange() {      
        this.allowNodeDrag = true;
      },
      opacityInput(data) {
        this.allowNodeDrag = false;
        if (this.loadedLayers.hasOwnProperty(data.layer_id)) {
          MapManager.setOpacity(this.loadedLayers[data.layer_id], this.opacityValue);
        }
      },
      layersContextMenu(event, data) {
        let selectedElement = LayerTreeElement.create(data);
        let elementType = selectedElement.getElementType();
        let layerId = selectedElement.getId();
        this.layerDtoSelectedFromContextMenu = data;
        this.$refs.layersContextMenu.open(event, data);
      },
      fitLayer() {
        if (this.layerDtoSelectedFromContextMenu == null || !this.loadedLayers.hasOwnProperty(this.layerDtoSelectedFromContextMenu.layer_id)) {
          this.$message({
            message: this.$locale.map_editor.layer_form.layer_not_loaded,
            type: 'warning'
          });
        } else if (this.loadedLayers.hasOwnProperty(this.layerDtoSelectedFromContextMenu.layer_id)) {
          const layer = this.loadedLayers[this.layerDtoSelectedFromContextMenu.layer_id];
          if (layer) {
              MapManager.fitLayer(this.mapInstance, layer);
          }
        }
      },
      selectLayer(layer, treeElementId, formId, projectId) {
        const command = new LayerTreeElementLayerPutCommand(
          treeElementId,
          layer.getId(),
          projectId,          
          null
        );
        this.getCommandBus().execute(
          command
        )
        .then((response) => { 
          console.log("response", response);
        })
        .catch((reason) => {
          console.log("error", reason);
        });
      },
      mapSourceChanged(mapInstance) {
        const dirtyLayers = MapManager.getDirtyLayers(mapInstance);
        const tree = this.$refs.layerTree;
        for (let i = this.dirtyLayers.length-1; i >= 0; i--) {
          if (typeof tree !== "undefined") {
            const node = tree.getNode(this.dirtyLayers[i]);
            if (node) {
              this.$set(node.data, "is_dirty", false);
            }
            this.dirtyLayers.splice(i, 1);
          }
        }
        dirtyLayers.forEach(layer => {
          const props = layer.getProperties();
          if (Object.prototype.hasOwnProperty.call(props, "id") && typeof tree !== "undefined") {
            const node = tree.getNode(props["id"]);
            if (node) {
              this.$set(node.data, "is_dirty", true);
            }
            this.dirtyLayers.push(props["id"]);
          }
        });
      },
      showFeatureAttributes(features) {
        this.selectedFeatures = features;
        this.activeTabName = "featureProperties";
      }
    },
    mounted () {
      //this.fixProjectTabsPosition();
      this.collapsePanel();
      this.getEventBus().$on('showProjectLayerPanel', this.showProjectLayerPanel);
      this.getEventBus().$on('removeProjectLayerPanel', this.removeProjectLayerPanel);
      this.getEventBus().$on('hideLayerForm', () => { this.showLayerForm = false });
      this.getEventBus().$on('hideProjectForm', () => { this.showSelectProject = false });    
      this.getEventBus().$on('selectSourcesFromContextMenu', this.selectSourcesFromContextMenu);
      this.getEventBus().$on('selectLayersFromContextMenu', this.selectLayersFromContextMenu);
      this.getEventBus().$on('selectCoordinateSystemsFromContextMenu', this.selectCoordinateSystemsFromContextMenu);
      this.getEventBus().$on('selectCoordinateSystem', this.selectCoordinateSystem);
      this.getEventBus().$on('vertexEditPanel', this.vertexEditPanel);
      this.getEventBus().$on('selectProject', this.selectProject);
      this.getEventBus().$on('layerTreeElementCreated', this.layerTreeElementCreated);    
      this.getEventBus().$on('selectLayer', this.selectLayer);
      this.getEventBus().$on('mapSourceChanged', this.mapSourceChanged);
      this.getEventBus().$on('linkToRegistryForm', this.linkToRegistryForm);
      this.getEventBus().$on('importForm', this.importForm);
      this.getEventBus().$on('exportForm', this.exportForm);
      this.getEventBus().$on('showFeatureAttributes', this.showFeatureAttributes);
      this.mapMounted = true;
    }
  }
  