export default class GatewayParser {
  /**
   * Находит связи переданного элемента, чтобы найти все нужные связанные элементы.
   * По умолчанию - если натыкается на элемент, отличный от того, что нужно найти, то выходит (исключение - шлюз)
   * @param gateway - элемент с которого начинаем поиск
   * @param elementType - тип элемента, который нужно найти
   * @param type - тип связи (входящая, исходящая)
   * @param deep
   * @param includeAttachers
   */
  public static find (gateway, elementType, type = 'outgoing', deep = false, includeAttachers = false): any[] {
    let result = []
    let parsedIds = []
    const _parse = (routes) => {
      let result = []
      routes.forEach((route) => {
        const component = type === 'outgoing' ? route.target : route.source
        if (parsedIds.includes(component.id)) {
          return
        }
        parsedIds.push(component.id)
        if (component.type === elementType && component.businessObject.accentType) {
          result.push(component)
          if (includeAttachers) {
            component.attachers.forEach((attached) => {
              if (attached.businessObject.accentType === 'cancelGateway' || attached.businessObject.accentType === 'approveGateway') {
                if (attached[type]) {
                  result.push(..._parse(attached[type]))
                }
              }
            })
          }
        } else if (component.type === 'bpmn:ParallelGateway' || deep) {
          result.push(..._parse(component[type]))
        } else if (component.type === 'bpmn:BoundaryEvent' && component.host.type === elementType) {
          result.push(component.host)
        }
      })

      return result
    }
    if (gateway[type]) {
      result = _parse(gateway[type])
    }

    return result.filter((value, index, self) => self.indexOf(value) === index)
  }

  public static findAndStop (gateway, elementType, type = 'outgoing') {
    return this.find(gateway, elementType, type)
  }

  public static findDeepWithAttachers (gateway, elementType, type = 'outgoing') {
    return this.find(
      gateway,
      elementType,
      type,
      true,
      true
    )
  }

  public static findDeep (gateway, elementType, type = 'outgoing') {
    return this.find(
      gateway,
      elementType,
      type,
      true
    )
  }
}
