import LoaderCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderCommandRepositoryInterface'
import store from '@/store'
import { CreateLoader, UpdateLoader, DeleteLoader } from '@/services/EtlEditor/infrastructure/store/modules/Loader/action-types'
import { LoaderDTO } from '@/services/EtlEditor/domain/model/Loader'

export default class LoaderCommandRepository implements LoaderCommandRepositoryInterface {
  insert (item: LoaderDTO): Promise<void> {
    return store.dispatch(new CreateLoader(item))
  }

  update (item: LoaderDTO): Promise<void> {
    return store.dispatch(new UpdateLoader(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteLoader(guid))
  }
}
