<template>
  <div>
    <span class="value" v-if="!isActive" @click="isActive = true">{{ value }}</span>
    <el-input size="mini"
              :value="value"
              @input="_onInput"
              @blur="isActive = false"
              @change="isActive = false"
              v-else
              class="editor"
              ref="editor"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'ClickInput',
  props: {
    value: Number
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    _onInput (value) {
      this.$emit('input', parseInt(value))
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.editor) {
            this.$refs.editor.focus()
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.value {
  font-size: 13px;
  width: calc(100% - 15px);

  &:hover {
    color: #606266;
    cursor: pointer;
    border-bottom: 1px dashed #606266;
  }
}
::v-deep .editor.el-input--mini .el-input__inner {
  height: 16px;
  line-height: 16px;
  border: none;
  font-size: 13px;
  padding: 0;
}
</style>
