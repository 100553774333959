import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import FormulaCreateCommand from '@/services/LogicEditor/application/command/FormulaCreateCommand'
import FormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/FormulaCommandRepositoryInterface'

export default class FormulaCreateHandler implements CommandHandlerInterface {
    private repository: FormulaCommandRepositoryInterface;

    constructor (repository: FormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: FormulaCreateCommand): Promise<void> {
      let item: FormulaDTO = {
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        formula_type_id: command.getFormulaTypeId(),
        sql_statements: command.getSqlStatements(),
        dsl_statement: command.getDslStatement(),
        entity_id: command.getEntityId(),
        role_id: command.getRoleId(),
        is_dsl: command.getIsDsl(),
        is_stored: command.getIsStored(),
        is_basic: command.getIsBasic(),
        logic_id: command.getLogicId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
