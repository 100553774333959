import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ExtractorDTO } from '@/services/EtlEditor/domain/model/Extractor'
import ExtractorCreateCommand from '@/services/EtlEditor/application/command/ExtractorCreateCommand'
import ExtractorCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorCommandRepositoryInterface'

export default class ExtractorCreateHandler implements CommandHandlerInterface {
    private repository: ExtractorCommandRepositoryInterface;

    constructor (repository: ExtractorCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ExtractorCreateCommand): Promise<void> {
      let item: ExtractorDTO = {
        name: command.getName(),
        extractor_type_id: command.getExtractorTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
