import SettingCommandRepositoryInterface from '@/services/Databus/domain/repository/SettingCommandRepositoryInterface'
import { SettingDTO } from '@/services/Databus/domain/model/Setting/Setting'
import store from '@/store'
import { UpdateSetting } from '@/services/Databus/infrastructure/store/modules/Setting/action-types'

export default class SettingCommandRepository implements SettingCommandRepositoryInterface
{

    update(item: SettingDTO): Promise<void>
    {
        return store.dispatch(new UpdateSetting(item));
    }

}
