<template>
  <ResizablePanel class="setting-panel" v-if="properties.block" :key="properties.block.guid" active="left" :default-width="320" name="right">
    <div class="header">
      <el-tooltip content="Настройки блока" placement="top" effect="light" :open-delay="500">
        <div
          :class="{ icon_button: true, active: active === SETTING_TYPES.BLOCK }"
          @click="active = SETTING_TYPES.BLOCK"
        >
          <font-awesome-icon :icon="icons.blockSettings"></font-awesome-icon>
        </div>
      </el-tooltip>

      <el-tooltip content="Настройки компонента" placement="top" effect="light" :open-delay="500" v-show="properties.component">
        <div
          :class="{ icon_button: true, active: active === SETTING_TYPES.COMPONENT }"
          @click="active = SETTING_TYPES.COMPONENT"
        >
          <font-awesome-icon :icon="icons.componentSettings"></font-awesome-icon>
        </div>
      </el-tooltip>

      <el-tooltip style="margin-left: auto; color:#E94435" content="Удалить" placement="top" effect="light" :open-delay="500">
        <div
          :class="{ icon_button: true }"
          @click="deleteBlock"
        >
          <font-awesome-icon :icon="icons.delete"></font-awesome-icon>
        </div>
      </el-tooltip>
    </div>

    <div class="settings custom_scrollbar">
      <BlockSettings
        v-if="active === SETTING_TYPES.BLOCK"
        :block.sync="properties.block"
        :mainParentBlock="mainParentBlock"
        :tab-settings-service="tabSettingsService"
        :block-settings="blockSettings"
        @remove-tab="$emit('remove-tab', $event)"
      ></BlockSettings>

      <ComponentSettings
        v-show="active === SETTING_TYPES.COMPONENT"
        v-if="properties.component"
        :properties="properties.componentProperties || []"
        :component-name="properties.component.initialType.replace('/index', '')"
        :registry-fields="registryFields"
        @change="updateComponentProperty($event.name, $event.value)"
        @open-modal-window-by-configurator="$emit('open-modal-window-by-configurator', $event)"
      ></ComponentSettings>

      <el-alert
        v-else-if="active === SETTING_TYPES.COMPONENT && Object.keys(properties.component || {}).length === 0"
        type="info"
        :closable="false"
        :title="$t('main.message.loading')"
      ></el-alert>
    </div>
  </ResizablePanel>

  <ResizablePanel active="left" :default-width="320" name="right" v-else>
    <div class="placeholder">Выберите блок для настройки</div>
  </ResizablePanel>
</template>

<script type="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSquare, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import BlockSettings from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/BlockSettings.vue'
import ComponentSettings from '@/core/infrastructure/components/ComponentSettings.vue'
import ResizablePanel from '@/core/infrastructure/components/ResizablePanel.vue'

const SETTING_TYPES = {
  BLOCK: 'block',
  COMPONENT: 'component'
}
export default {
  name: 'settings-panel',
  components: {
    ResizablePanel,
    ComponentSettings,
    BlockSettings,
    FontAwesomeIcon
  },
  props: {
    properties: {
      type: Object
    },
    mainParentBlock: {
      type: Object
    },
    tabSettingsService: {
      type: Object
    },
    registryFields: {
      type: Array
    },
    blockSettings: Object
  },
  inject: ['getEventBus'],
  data () {
    return {
      active: SETTING_TYPES.BLOCK,
      SETTING_TYPES: SETTING_TYPES,
      icons: {
        blockSettings: faSquare,
        componentSettings: faTools,
        delete: faTrashAlt
      }
    }
  },
  methods: {
    updateComponentProperty (property, value) {
      // console.log('Принял CHANGE updateComponentProperty settingsPanel:', property, value)
      if (Object.keys(this.properties.component.properties).length === 0) {
        this.$set(this.properties.component, 'properties', {})
      }
      this.$set(this.properties.component.properties, property, value)
    },
    deleteBlock () {
      this.getEventBus().$emit('remove')
    }
  },
  watch: {
    properties (value) {
      if (value.component) {
        this.active = SETTING_TYPES.COMPONENT
      } else {
        this.active = SETTING_TYPES.BLOCK
      }
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  padding: 2px;
  background: #F9FAFA;
}
.settings {
  height: calc(100% - 37px);
  overflow: auto;
}
.placeholder {
  display: flex;
  align-items: center;
  color: #949FA8;
  height: 100%;
  justify-content: center;
}
</style>
<style scoped src="../../../main.scss" lang="scss"></style>
