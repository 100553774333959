import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerTreeElementCommandRepositoryInterface'
import LayerTreeElementLayerPutCommand from '@/services/MapEditor/application/command/LayerTreeElementLayerPutCommand'
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'

export default class LayerTreeElementLayerPutHandler implements CommandHandlerInterface
{
    private repo: LayerTreeElementCommandRepositoryInterface;

    constructor(repo: LayerTreeElementCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerTreeElementLayerPutCommand): Promise<void>
    {
        let item: LayerTreeElementDTO = {
            id: command.getTreeElementId(),
            layer_id: command.getLayerId(),
            project_id: command.getProjectId(),
            parent_id: command.getParentId()
        };
        return this.repo.putLayer(item);
    }
}