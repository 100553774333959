<template>
  <div class="select-panel" ref="coordinate_system_select_panel">
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createCoordinateSystem"></el-button>
      <el-button icon="el-icon-edit" size="mini" circle  @click="editCoordinateSystem"></el-button>
      <el-button icon="el-icon-delete" size="mini" circle  @click="deleteCoordinateSystem"></el-button>
      <el-checkbox v-if="!hideAll" v-model="all" :label="$locale.map_editor.coordinate_system_panel.all_coordinate_systems"></el-checkbox>
      <el-input
        :placeholder="$locale.map_editor.coordinate_system_panel.search_by_code"
        prefix-icon="el-icon-search"
        v-model="searchSrid"
        @keyup.enter.native="searchBySrid">
      </el-input>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%" v-bind:class="{ 'mini': coordinateSystem !== null }">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="coordinateSystems"
          stripe
          border
          ref="coordinate_systems_table"
          row-key="srid"
          current-row-key="srid"
          highlight-current-row
          @current-change="changeCoordinateSystem"
        >
        <el-table-column
          prop="srid"
          :label="$locale.map_editor.coordinate_system_panel.code"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="auth_name"
          :label="$locale.main.label_input.name"
          width="400"
        ></el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="coordinate-system-pagination"
            :page-size="pageSize"
            :layout="all ? 'total, prev, pager, next' : 'total'"
            :total="count"
            @current-change="handlePageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
      <el-col v-if="coordinateSystem !== null" v-bind:class="{ 'mini': coordinateSystem !== null }">
        <CoordinateSystemInformationPanel label-position="top" :style="{
          'max-height': `${informationPanelHeight}px`,
          'overflow-y': 'auto'
        }" class="information custom_scrollbar" :coordinateSystem="coordinateSystem" />
      </el-col>
    </el-row>     
  </div>
</template>

<script type="ts">
import ProjectCoordinateSystemsQuery from '@/services/MapEditor/application/query/ProjectCoordinateSystemsQuery'
import CoordinateSystemsQuery from '@/services/MapEditor/application/query/CoordinateSystemsQuery'
import CoordinateSystemsCountQuery from '@/services/MapEditor/application/query/CoordinateSystemsCountQuery'
import CoordinateSystemDeleteCommand from '@/services/MapEditor/application/command/CoordinateSystemDeleteCommand'
import CoordinateSystem from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'
import CoordinateSystemInformationPanel from '@/services/MapEditor/infrastructure/components/CoordinateSystemInformationPanel/index.vue'

export default {
  name: 'CoordinateSystemSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'projectGuid', 'hideAll', 'field'],
  components: {
    CoordinateSystemInformationPanel
  },
  data() {
    return {
      loading: false,
      pageSize: 0,
      defaultPageSize: 100,
      currentPage: 0,
      pageLimit: 100,
      count: 0,
      allCount: 0,
      all: false,
      coordinateSystems: [],
      coordinateSystem: null,
      informationPanelHeight: 0,
      searchSrid: null
    }
  },
  computed: {
    coordinateSystemCreated() {
      return this.$store.getters['CoordinateSystem/getLocation'];
    }
  },
  watch: {
    all (val) {
      this.coordinateSystem = null;
      this.$refs.coordinate_systems_table.setCurrentRow(null);
      if (val) {        
        this.pageSize = this.defaultPageSize;
      }
      this.loadCoordinateSystems();
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.coordinate_system_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.coordinate_system_select_panel.clientHeight - 36;
      }
    },
    changeCoordinateSystem(coordinateSystemDto) {
      this.coordinateSystem = CoordinateSystem.create(coordinateSystemDto);
    },
    submit(callback) {
      if (!this.coordinateSystem) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
        return;
      }
      this.getEventBus().$emit('coordinateSystemSelected', this.coordinateSystem, this.parentFormId, this.field);
      this.getEventBus().$emit('CoordinateSystemComboboxCoordinateSystemSelected', this.coordinateSystem);
      if (typeof callback == 'function') {
        callback();
      }
    },
    createCoordinateSystem() {
      this.getEventBus().$emit('createForm', 'coordinate_system', {'parentFormId': this.formId, 'coordinateSystemCreatedCallback': this.refreshCoordinateSystem});
    },
    editCoordinateSystem() {
      if (this.coordinateSystem == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.getEventBus().$emit('createForm', 'coordinate_system_edit', {'parentFormId': this.formId, 'coordinateSystem': this.coordinateSystem, 'coordinateSystemUpdatedCallback': this.refreshCoordinateSystem});
      }
    },
    async refreshCoordinateSystem(coordinateSystem) {
      await this.loadCoordinateSystems(() => {
        this.selectCoordinateSystem(coordinateSystem);
      });      
    },
    deleteCoordinateSystem() {
      if (this.coordinateSystem == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new CoordinateSystemDeleteCommand(
              this.coordinateSystem.getSrid(),
              this.all ? null : this.projectGuid
            )
          ).then((response) => {
            this.coordinateSystem = null;
            this.$refs.coordinate_systems_table.setCurrentRow(null);
            this.loadCoordinateSystems();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    selectCoordinateSystem(coordinateSystem) {
      let rows = this.coordinateSystems.filter(el => {
        return el.srid == coordinateSystem.getSrid()
      });
      if (rows.length) {
        this.$refs.coordinate_systems_table.setCurrentRow(rows[0]);
      }
    },
    handlePageChange(val) {
      val--;
      this.currentPage = (val * this.pageLimit);
      this.loadCoordinateSystems();
    },
    loadCoordinateSystems(callback) {
      if (!this.all) {
        this.loadProjectCoordinateSystems(callback);        
      } else {
        if (this.allCount == 0) {
          this.countAndLoadCoordinateSystems(callback);
        } else {
          this.loadall(callback);
        }      
      }
    },
    async loadProjectCoordinateSystems(callback) {
      await this.getQueryBus().execute(
        new ProjectCoordinateSystemsQuery(this.projectGuid)
      ).then(data => {
        this.pageSize = data.length;
        this.count = data.length;
        this.coordinateSystems = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async countAndLoadCoordinateSystems(callback) {
      await this.getQueryBus().execute(
        new CoordinateSystemsCountQuery()
      ).then(data => {
        this.count = data[0].count;      
        this.loadall(callback);
      });
    },
    async loadall(callback) {
      let payload = {
        limit: this.pageLimit,
        offset: this.currentPage
      };

      await this.getQueryBus().execute(
        new CoordinateSystemsQuery(payload)
      ).then(data => {
        this.pageSize = this.pageLimit;
        this.coordinateSystems = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async searchBySrid() {
      let payload = {};
      let query;

      if (this.searchSrid && this.searchSrid.length > 0) {
        payload['srid'] = this.searchSrid;        
      }
      if (this.all) {
        payload["limit"] = this.pageLimit;
        payload["offset"] = this.currentPage;
        query = new CoordinateSystemsQuery(payload);
      } else {
        query = new ProjectCoordinateSystemsQuery(this.projectGuid, payload);
      }

      await this.getQueryBus().execute(query).then(data => {
        this.pageSize = this.pageLimit;
        this.coordinateSystems = data;
      });
    }
  },
  mounted() {
    this.loadCoordinateSystems(() => {
      this.resizePanel();
    });   
  }
}
</script>