import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'
import RelatedObjectUpdateCommand from '@/services/LogicEditor/application/command/RelatedObjectUpdateCommand'
import RelatedObjectCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RelatedObjectCommandRepositoryInterface'

export default class RelatedObjectUpdateHandler implements CommandHandlerInterface {
    private repository: RelatedObjectCommandRepositoryInterface;

    constructor (repository: RelatedObjectCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RelatedObjectUpdateCommand): Promise<void> {
      let item: RelatedObjectDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        object_id: command.getObjectId(),
        alias_field_id: command.getAliasFieldId(),
        sql_statements: command.getSqlStatements(),
        filters: command.getFilters()
      }

      return this.repository.update(item)
    }
}
