
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default Vue.extend({
  name: 'PopoverButton',

  inject: ['getEventBus'],

  components: {
    FontAwesomeIcon
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    icon: {},

    title: String,

    mini: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  mounted () {
    this.getEventBus().$on('closePopover', this.close)
  },

  beforeDestroy () {
    this.getEventBus().$off('closePopover', this.close)
  },

  methods: {
    close () {
      if (this.isActive) {
        this.isActive = false
      }
    }
  }
})
