import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import MappingQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/MappingQueryRepositoryInterface'
import MappingElementTypesQuery from '@/services/EtlEditor/application/query/MappingElementTypesQuery'

export default class MappingElementTypesHandler implements QueryHandlerInterface {
  private repository: MappingQueryRepositoryInterface;

  constructor (repository: MappingQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: MappingElementTypesQuery): Promise<any> {
    return this.repository.getElementTypes()
  }
}
