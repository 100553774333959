import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  CreateSchema,
  DeleteSchema,
  GetSchemas,
  UpdateSchema
} from '@/services/ApiService/infrastructure/store/modules/Schema/action-types'
import SchemaRepositoryInterface from '@/services/ApiService/domain/repository/SchemaRepositoryInterface'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export default class SchemaRepository extends AbstractQueryRepository implements SchemaRepositoryInterface {
  getSchemas (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetSchemas(payload))
  }
  createSchema (payload: object): Promise<any> {
    return store.dispatch(new CreateSchema(payload))
  }
  deleteSchema (guid: string): Promise<any> {
    return store.dispatch(new DeleteSchema(guid))
  }
  updateSchema (payload: SchemaTreeDTO): Promise<any> {
    return store.dispatch(new UpdateSchema(payload))
  }
}
