import LayerTreeElementType from './LayerTreeElementType'
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class LayerGroupDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: string;
    project_id?: number;
    parent_id?: Nullable<number>;

    constructor({ id, guid, name, project_id, parent_id}: {id?: Nullable<number>, guid?: Nullable<string>, name?: string, project_id?: number, parent_id?: Nullable<number>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.project_id = project_id;
        this.parent_id = parent_id;
    }
}

export default class LayerGroup extends AbstractBaseEntity
{
    private id: Nullable<number>;
    private guid: Nullable<string>;
    private name: string;
    private projectId: number;
    private parentId: Nullable<number>;

    constructor(id: Nullable<number>, guid: Nullable<string>, name: string, projectId: number, parentId: Nullable<number>)
    {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.projectId = projectId;
        this.parentId = parentId;
        this.assertInvariants();
    }

    static create(dto: LayerGroupDTO): LayerGroup
    {
        return new LayerGroup(dto.id, dto.guid, dto.name, dto.project_id, dto.parent_id);
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    setProjectId(projectId: number): void
    {
        this.projectId = projectId;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    setParentId(parentId: Nullable<number>): void
    {
        this.parentId = parentId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    getType(): LayerTreeElementType
    {
        return LayerTreeElementType.GROUP;
    }
}