import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'

export default class CountByResourceQuery extends AbstractEntitiesQuery {
  private resource: string

  constructor (payload: string[][] | object, resource: string) {
    super(payload)
    this.resource = resource
  }

  getClassName (): string {
    return 'CountByResourceQuery'
  }

  getResource (): string {
    return this.resource
  }
}
