import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer'

import {
  append as svgAppend,
  classes as svgClasses,
  attr as svgAttr,
  create as svgCreate,
  remove as svgRemove,
  innerSVG
} from 'tiny-svg'

// eslint-disable-next-line import/no-webpack-loader-syntax
const UserTaskIcon = require('!!raw-loader!./icons/user-task.svg')
// eslint-disable-next-line import/no-webpack-loader-syntax
const ServiceTaskIcon = require('!!raw-loader!./icons/service-task.svg')
// eslint-disable-next-line import/no-webpack-loader-syntax
const ParallelGateway = require('!!raw-loader!./icons/parallel-gateway.svg')
// eslint-disable-next-line import/no-webpack-loader-syntax
const ExclusiveGateway = require('!!raw-loader!./icons/exclusive-gateway.svg')
// eslint-disable-next-line import/no-webpack-loader-syntax
const InclusiveGateway = require('!!raw-loader!./icons/inclusive-gateway.svg')

const HIGH_PRIORITY = 1500

export default class AccentRenderer extends BaseRenderer {
  constructor (eventBus, bpmnRenderer, textRenderer) {
    super(eventBus, HIGH_PRIORITY)
    this.textRenderer = textRenderer
    this.bpmnRenderer = bpmnRenderer
  }

  canRender (element) {
    // isAny(element, ['bpmn:ServiceTask', 'bpmn:StartEvent', 'bpmn:Task']) &&
    return !element.labelTarget
  }

  getIcon (type, element) {
    switch (type) {
      case 'UserTask':
        return UserTaskIcon
      case 'ServiceTask':
        return ServiceTaskIcon
      case 'ParallelGateway':
        return {
          icon: ParallelGateway,
          x: element.width / 2 - 9,
          y: element.height / 2 - 9
        }
      case 'ExclusiveGateway':
        return {
          icon: ExclusiveGateway,
          x: element.width / 2 - 9,
          y: element.height / 2 - 9
        }
      case 'InclusiveGateway':
        return {
          icon: InclusiveGateway,
          x: element.width / 2 - 9,
          y: element.height / 2 - 9
        }
      default:
        return null
    }
  }

  getFill (type) {
    switch (type) {
      case 'UserTask':
        return '#FFF2E9'
      case 'ServiceTask':
        return '#EEF3FE'
      case 'StartEvent':
        return '#8598FF'
      case 'EndEvent':
        return '#FF974D'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
      case 'InclusiveGateway':
        return '#FFFFFF'
    }

    switch (type) {
      case 'EndEvent':
        return '#f0a8ab'
      case 'StartEvent':
        return '#e5fd95'
      case 'ServiceTask':
      case 'Task':
      case 'ManualTask':
      case 'UserTask':
      case 'SubProcess':
      case 'BoundaryEvent':
        return '#ecefff'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
        return '#fffec9'
      case 'IntermediateThrowEvent':
      case 'IntermediateCatchEvent':
        return '#fffbe1'
      case 'DataObject':
      case 'DataStoreReference':
        return '#f0f0f0'
      default:
        return null
    }
  }

  getStroke (type) {
    switch (type) {
      case 'UserTask':
        return '#FFC49B'
      case 'ServiceTask':
        return '#AEC7F9'
      case 'SequenceFlow':
        return '#8C8C8C'
      case 'StartEvent':
        return '#8598FF'
      case 'EndEvent':
        return '#FF974D'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
      case 'InclusiveGateway':
        return '#737BF3'
    }

    switch (type) {
      case 'EndEvent':
        return '#a73e3f'
      case 'StartEvent':
        return '#68951d'
      case 'ServiceTask':
      case 'Task':
      case 'BoundaryEvent':
      case 'SubProcess':
      case 'ManualTask':
      case 'UserTask':
        return '#1c5372'
      case 'ParallelGateway':
      case 'ExclusiveGateway':
        return '#acaa5c'
      case 'IntermediateThrowEvent':
      case 'IntermediateCatchEvent':
        return '#999771'
      case 'DataObject':
      case 'DataStoreReference':
        return '#9d9d9d'
      default:
        return null
    }
  }

  getSizes (type) {
    switch (type) {
      case 'UserTask':
      case 'ServiceTask':
        return {
          width: 160,
          height: 80
        }
      default:
        return null
    }
  }

  drawConnection (parentNode, element) {
    const connection = this.bpmnRenderer.drawConnection(parentNode, element)
    const stroke = this.getStroke(element.type.replace('bpmn:', ''))
    if (stroke) {
      connection.style.stroke = stroke
      let eleId = parentNode.childNodes[0].style['marker-end'].split('"')[1]

      let marker = document.querySelector(eleId)

      marker.childNodes[0].style.fill = stroke

      marker.childNodes[0].style.stroke = stroke

      parentNode.childNodes[0].style.stroke = stroke
    }

    return connection
  }

  drawShape (parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element)
    const elementType = element.type.replace('bpmn:', '')
    const fill = this.getFill(elementType)
    if (fill) {
      shape.style.fill = fill
    }
    const stroke = this.getStroke(elementType)
    if (stroke) {
      shape.style.stroke = stroke
      shape.setAttribute('rx', 4)
      shape.setAttribute('ry', 4)
    }

    const icon = this.getIcon(elementType, element)

    if (icon) {
      let iconLink = typeof icon === 'object' ? icon.icon : icon
      let oldIcons = parentNode.querySelectorAll('path')
      oldIcons.forEach((i) => parentNode.removeChild(i))
      oldIcons = parentNode.querySelectorAll('circle')
      oldIcons.forEach((i) => parentNode.removeChild(i))
      let svgIcon = svgCreate('svg')
      innerSVG(svgIcon, iconLink)
      svgAttr(svgIcon, {
        // href: iconLink,
        fill: stroke,
        stroke: stroke,
        width: icon.width || '20px',
        height: icon.height || '20px',
        x: icon.x || 0,
        y: icon.y || 0
      })
      svgClasses(svgIcon).add('djs-label')
      svgClasses(svgIcon).add('djs-icon')
      svgAppend(parentNode, svgIcon)
    }

    const text = parentNode.querySelector('text.djs-label')
    if (text) {
      text.style.fontFamily = 'Roboto'
      text.style.fontWeight = '500'
      text.style.fontSize = '14px'
    }

    const sizes = this.getSizes(elementType)
    if (sizes) {
      element.height = sizes.height || element.height
      element.width = sizes.width || element.width
    }
    shape.style.width = element.width
    shape.style.height = element.height
    shape.style.borderRadius = '4px'

    return shape
  }
}

AccentRenderer.$inject = ['eventBus', 'bpmnRenderer', 'textRenderer']
