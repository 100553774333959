<template>
  <div class="main">
    <toolbar></toolbar>
    <div :id="`viewer_${guid}`" class="viewer"></div>
    <modal-window
      show-close-icon
      :active.sync="showAssociateWindow"
      v-if="showAssociateWindow"
      :title="activeAssociationModel.accentGuid ? $t(`process_editor.properties_types.${activeAssociationModel.type}`) : $t(`process_editor.associate_types.${activeAssociationModel.type}`)"
    >
      <associate-with
        :type="activeAssociationModel.type"
        :accent-guid="activeAssociationModel.accentGuid"
        :properties="activeAssociationModel.properties"
        @associate="associateElement"
      ></associate-with>
    </modal-window>
  </div>
</template>

<script>
import Modeler from 'bpmn-js/lib/Modeler'
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import PaletteModule from './infrastructure/modules/PalleteProvider'
import ContextMenu from './infrastructure/modules/ContextMenu'
import Toolbar from './infrastructure/components/Toolbar'
import BPMNService from '@/services/BPMNEditorApprovals/infrastructure/service/BPMNService'
import AccentExtension from './domain/model/AccentExtension.json'
import CustomRenderer from './infrastructure/modules/CustomRenderer'
import Vue from 'vue'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import AssociateWith from '@/services/BPMNEditorApprovals/infrastructure/components/AssociateWith'

const XML = `<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2" targetNamespace="http://bpmn.io/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="9.0.3">
  <process id="Process_1" isExecutable="false" />
  <bpmndi:BPMNDiagram id="BpmnDiagram_1">
    <bpmndi:BPMNPlane id="BpmnPlane_1" bpmnElement="Process_1" />
  </bpmndi:BPMNDiagram>
</definitions>
`
const BMPNAccentMapper = {
  'bpmn:ServiceTask': 'command',
  'bpmn:StartEvent': 'approval',
  'bpmn:UserTask': 'approval_stage'
}
export default {
  name: 'BPMNEditorApprovals',
  components: { AssociateWith, ModalWindow, Toolbar },
  props: {
    schema: String,
    saveCallback: Function
  },
  provide () {
    return {
      getEventBus: this.getEventBus
    }
  },
  data () {
    return {
      modeler: undefined,
      guid: this.generateGuid(),
      BPMNService: undefined,
      eventBus: new Vue(),
      showAssociateWindow: false,
      activeAssociationModel: {}
    }
  },
  mounted () {
    this.modeler = new Modeler({
      container: `#viewer_${this.guid}`,
      moddleExtensions: {
        cmd: AccentExtension
      },
      additionalModules: [
        CustomRenderer,
        PaletteModule,
        ContextMenu
      ]
    })
    this.modeler.importXML(this.schema).catch((error) => {
      console.log(error)
      if ((this.schema || '').length > 0) {
        this.$message.warning('Некорректная схема, заменена на пустую')
      }
      this.modeler.importXML(XML)
    }).finally(() => {
      this.modeler.get('canvas').zoom('fit-viewport')
    })
    this.BPMNService = new BPMNService(this.modeler)
    this.registerEvents()
  },
  beforeDestroy () {
    this.eventBus.$destroy()
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    save () {
      const me = this
      if (typeof this.saveCallback === 'function') {
        this.modeler.saveXML({ format: true }).then((xml) => {
          me.saveCallback(xml)
        })
      }
    },
    registerEvents () {
      this.eventBus.$on('export', () => this.BPMNService.export())
      this.eventBus.$on('import', () => this.BPMNService.import())
      this.eventBus.$on('save', this.save)
      this.modeler.get('eventBus').on('accent.add', (event, data) => this.BPMNService.add(data))
      this.modeler.get('eventBus').on('accent.associate', this.openAssociateWindow)
      this.modeler.get('eventBus').on('accent.build_approval', (event, element) => {
        this.saveApproval(this.BPMNService.buildApproval(element))
      })
    },
    openAssociateWindow (event, element) {
      const type = BMPNAccentMapper[element.type]
      if (!type) {
        console.warn('Не найден тип ассоциации')
        return false
      }
      const elementProperties = element.businessObject

      this.activeAssociationModel = {
        type: type,
        accentGuid: elementProperties.accentGuid,
        element: element
      }

      if (type === 'command') {
        this.activeAssociationModel.properties = {
          event_id: elementProperties.accentSubType
        }
      }
      this.showAssociateWindow = true
    },
    saveApproval (data) {
      this.$http.post(`${this.$config.api}/v2/logiceditor/approvals/${data.guid}/bpmn`, { stages: data.stages })
        .then(() => {
          this.$message.success('Маршрут успешно сохранен')
        })
        .catch(() => {
          this.$message.error('Ошибка сборки маршрута')
        })
    },
    async associateElement (model) {
      this.showAssociateWindow = false
      const props = {
        accentGuid: model.guid
      }
      if (model.name) {
        props.name = model.name
      }
      if (model.event_id) {
        props.accentSubType = model.event_id
      }
      this.modeler.get('modeling').updateProperties(this.activeAssociationModel.element, props)
      if (this.activeAssociationModel.type === 'approval_stage') {
        this.activeAssociationModel.element.attachers.forEach((item) => {
          if (item.businessObject?.accentType === 'cancelGateway') {
            this.modeler.get('modeling').updateProperties(item, {
              name: model.cancel_text
            })
          } else if (item.businessObject?.accentType === 'approveGateway') {
            this.modeler.get('modeling').updateProperties(item, {
              name: model.approve_text
            })
          }
        })
      }
      if (this.activeAssociationModel.type === 'approval' && model.import) {
        // собираем все команды
        let commandsToGet = []
        model.approval_stages.forEach((item) => {
          commandsToGet.push(...item.approve_commands || [])
          commandsToGet.push(...item.cancel_commands || [])
          commandsToGet.push(...item.close_commands || [])
        })
        commandsToGet = commandsToGet.filter((v, i, a) => a.indexOf(v) === i)
        let commandMap = new Map()
        if (commandsToGet.length > 0) {
          const { data } = await this.$http.get(`${this.$config.api}/v2/logiceditor/commands?in[id]=${commandsToGet.join(',')}`)
          commandMap = new Map(data.map(item => [item.id, item]))
        }
        this.BPMNService.importApproval(this.activeAssociationModel.element, model, commandMap)
      }
      this.activeAssociationModel = {}
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;

  & .viewer {
    height: calc(100% - 41px);
  }
}
</style>
