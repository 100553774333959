import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RuleDeleteCommand from '@/services/TaskEditor/application/command/RuleDeleteCommand'
import RuleCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleCommandRepositoryInterface'

export default class RuleDeleteHandler implements CommandHandlerInterface {
    private repository: RuleCommandRepositoryInterface;

    constructor (repository: RuleCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RuleDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
