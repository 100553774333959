<template>
  <el-dialog
    :title="title"
    :visible.sync="isShow"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      class="create-query-form"
      ref="form"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="model"
      size="mini"
      @submit.native.prevent="save"
    >
      <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
        <el-input v-model="model.name" :placeholder="$t('bi_editor.form.label.name')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
        <el-input v-model="model.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.description')" prop="alias">
        <el-input v-model="model.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.sql_statement')" prop="sql_statement">
        <codemirror v-model="model.sql_statement" :options="options"></codemirror>
      </el-form-item>

      <el-form-item>
        <el-checkbox :label="$t('bi_editor.form.label.is_materialized')" v-model="model.is_materialized"></el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox :label="$t('bi_editor.form.label.has_view')" v-model="model.has_view"></el-checkbox>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">
        {{ $t('bi_editor.form.btn_cancel') }}
      </el-button>

      <el-button type="primary" @click="save" size="small">
        {{ $t('bi_editor.form.btn_save') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'CreateQuery',

  props: {
    title: String,

    value: Object,

    rules: Object,

    isShow: Boolean,

    beforeClose: {
      type: Function,
      default () {
        return function (isNewEntity) {
        }
      }
    },

    submit: {
      type: Function,
      default () {
        return async function (isNewEntity) {
        }
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value,

      options: {
        tabSize: 4,
        mode: 'text/x-pgsql',
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        collapseIdentical: true,
        highlightDifferences: false
      }
    }
  },

  methods: {
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submit(true)
        } else {
          return false
        }
      })
    },

    close (isNewEntity = true) {
      this.beforeClose(isNewEntity)
    }
  }
}
</script>

<style>
.create-query-form .vue-codemirror .CodeMirror {
  width: 100%;
  height: 200px;
}
</style>
