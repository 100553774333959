import ThemeQueryRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeQueryRepositoryInterface'
import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'

export default class ThemesQueryHandler implements QueryHandlerInterface {
  private repo: ThemeQueryRepositoryInterface;

  constructor (repository: ThemeQueryRepositoryInterface) {
    this.repo = repository
  }

  execute (query: AbstractEntitiesQuery): Promise<ThemeDTO[]> {
    return this.repo.get(query.getPayload())
  }
}
