import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class MappingUpdateCommand implements CommandInterface {
  private guid: string
  private sourceFieldId: Nullable<number>
  private sourceColumn: Nullable<string>
  private sourceAlias: Nullable<string>
  private targetFieldId: Nullable<number>
  private targetColumn: Nullable<string>
  private targetAlias: Nullable<string>
  private isKey: boolean
  private isRequired: boolean
  private isLoadXrefTableValues: boolean
  private xrefFieldId: Nullable<number>
  private xrefCondition: Nullable<string>
  private loaderId: Nullable<number>
  private transformerId: Nullable<number>
  private rowOrder: Nullable<number>
  private value: Nullable<string>
  private condition: Nullable<string>
  private elementTypeId: Nullable<string>
  private description: Nullable<string>

  constructor (
    guid: string,
    sourceFieldId: Nullable<number>,
    sourceColumn: Nullable<string>,
    sourceAlias: Nullable<string>,
    targetFieldId: Nullable<number>,
    targetColumn: Nullable<string>,
    targetAlias: Nullable<string>,
    isKey: boolean,
    isRequired: boolean,
    isLoadXrefTableValues: boolean,
    xrefFieldId: Nullable<number>,
    xrefCondition: Nullable<string>,
    loaderId: Nullable<number>,
    transformerId: Nullable<number>,
    rowOrder: Nullable<number>,
    value: Nullable<string>,
    condition: Nullable<string>,
    elementTypeId: Nullable<string>,
    description: Nullable<string>
  ) {
    this.guid = guid
    this.sourceFieldId = sourceFieldId
    this.sourceColumn = sourceColumn
    this.sourceAlias = sourceAlias
    this.targetFieldId = targetFieldId
    this.targetAlias = targetAlias
    this.isKey = isKey
    this.isRequired = isRequired
    this.isLoadXrefTableValues = isLoadXrefTableValues
    this.xrefFieldId = xrefFieldId
    this.xrefCondition = xrefCondition
    this.loaderId = loaderId
    this.transformerId = transformerId
    this.rowOrder = rowOrder
    this.value = value
    this.condition = condition
    this.elementTypeId = elementTypeId
    this.description = description
  }

  getClassName (): string {
    return 'MappingUpdateCommand'
  }

  getGuid (): string {
    return this.guid
  }

  getSourceFieldId (): Nullable<number> {
    return this.sourceFieldId
  }

  getSourceColumn (): Nullable<string> {
    return this.sourceColumn
  }

  getSourceAlias (): Nullable<string> {
    return this.sourceAlias
  }

  getTargetFieldId (): Nullable<number> {
    return this.targetFieldId
  }

  getTargetColumn (): Nullable<string> {
    return this.targetColumn
  }

  getTargetAlias (): Nullable<string> {
    return this.targetAlias
  }

  getIsKey (): boolean {
    return this.isKey
  }

  getIsRequired (): boolean {
    return this.isRequired
  }

  getIsLoadXrefTableValues (): boolean {
    return this.isLoadXrefTableValues
  }

  getXrefFieldId (): Nullable<number> {
    return this.xrefFieldId
  }

  getXrefCondition (): Nullable<string> {
    return this.xrefCondition
  }

  getLoaderId (): Nullable<number> {
    return this.loaderId
  }

  getTransformerId (): Nullable<number> {
    return this.transformerId
  }

  getRowOrder (): Nullable<number> {
    return this.rowOrder
  }

  getValue (): Nullable<string> {
    return this.value
  }

  getCondition (): Nullable<string> {
    return this.condition
  }

  getElementTypeId (): Nullable<string> {
    return this.elementTypeId
  }

  getDescription (): Nullable<string> {
    return this.description
  }
}
