<template>
  <div v-loading="loading">
    <select-tree
      class="property"
      :value="value"
      @input="$emit('input', $event)"
      type-field="report_type"
      :clearable="false"
      :standard-tree="true"
      :data-table="reports"
      :append-to-body="false"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import Report from '@/components/Models/reporteditor/Report'

export default Vue.extend({
  name: 'ReportSelect',

  components: {
    SelectTree
  },

  props: ['value'],

  data () {
    return {
      reports: [],
      loading: false
    }
  },

  methods: {
    createReportsTree (data) {
      data = data.map((record) => {
        record.labelType = this.$t('report_editor.types.' + record.report_type)
        record.leaf = !record.has_children

        return record
      })

      const hashTable = Object.create(null)

      for (const record of data) {
        hashTable[record.id] = { ...record, children: [] }
      }

      const dataTree = []

      for (const record of data) {
        if (record.parent_id) {
          hashTable[record.parent_id].children.push(hashTable[record.id])
        } else {
          dataTree.push(hashTable[record.id])
        }
      }

      return dataTree
    }
  },

  async mounted () {
    this.loading = true

    const reports = await Report.params({ 'order': 'report_type:asc,row_order:asc,name:desc' }).get()
    this.reports = this.createReportsTree(reports)

    this.loading = false
  }
})
</script>

<style scoped>

</style>
