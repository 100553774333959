
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { XrefAPI } from '@/services/RegistryTable/infrastructure/api/XrefAPI'
import { APIDataSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/api/DataSource'

export default {
  template: `<div class="ag-filter-wrapper ag-focus-managed">
  <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">

    <div class="ag-set-filter">
      <!--AG-INPUT-TEXT-FIELD-->
      <div class="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
        <div class="ag-input-field-label ag-label ag-hidden ag-text-field-label"></div>
        <div class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
          <input v-model="search" class="ag-input-field-input ag-text-field-input" type="text" placeholder="Поиск...">
        </div>
      </div>
      <div v-show="!loading && options.length === 0" class="ag-filter-no-matches ag-hidden">Не найдено</div>
      <div class="ag-set-filter-list" role="presentation">
      <div class="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed" role="listbox" ref="eContainer1">
        <div v-show="loading" class="ag-filter-loading">Загрузка...</div>
          <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
          <div class="ag-virtual-list-container ag-filter-virtual-list-container" ref="eContainer">
            <div style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div v-show="options.length !== 1" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                   @click="selectAllOptions"
              >
                <div class="ag-input-field-label ag-label ag-checkbox-label">
                  (Выбрать все)
                </div>
                <div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{
                      'ag-checked': isAllOptionsChecked,
                      'ag-indeterminate': isIndeterminateCheckbox
                    }"
                >
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
                </div>
              </div>
            </div>
            <div v-for="(item, index) in listFoundItems" :key="index" style="padding: 3px 0px;" class="ag-set-filter-item">
              <!--AG-CHECKBOX-->
              <div @click="item.checked = !item.checked"
                   class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                <div ref="eLabel" class="ag-input-field-label ag-label ag-checkbox-label">
                  {{ item.name  }}
                </div>
                <div ref="eWrapper"
                     class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                     :class="{'ag-checked': item.checked}"
                     role="presentation">
                  <input ref="eInput" class="ag-input-field-input ag-checkbox-input"
                         type="checkbox">
                </div>
              </div>
            </div>
          </div>
          <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="ag-filter-apply-panel">
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="apply">ОК</button>
    <button type="button" class="ag-standard-button ag-filter-apply-panel-button" @click="reset">Сбросить</button>
  </div>
  </div>`,
  name: 'xrefFilter',
  data () {
    return {
      options: [],
      valueGetter: null,
      loading: false,
      error: null,
      offset: 0,
      attributeId: null,
      filterDebounce: null,
      state: [],
      isFirstApply: true,
      search: '',
      dataSourceService: null,
      filterVisibleOnly: false,
      entityType: '',
      entityId: 0
    }
  },
  mounted () {
    let grid = this.$parent.$parent
    this.dataSourceService = grid.dataSourceService
    this.filterVisibleOnly = this.params.colDef.filterVisibleOnly
    this.entityType = this.dataSourceService.getType()
    this.entityId = this.entityType === 'registry' ? this.dataSourceService.registryId : this.dataSourceService.entityId
    let me = this
    grid.gridOptions.onFilterOpened = function () {
      if (me.filterVisibleOnly) me.loadOptions({ attributeId: me.attributeId })
    }
    if (this.params.colDef.field && /attr_[0-9]+_/i.test(this.params.colDef.field)) {
      this.attributeId = this.params.colDef.field.replace(/[^0-9]/g, '')
      this.loadOptions({ attributeId: this.attributeId, isFirst: true })
    }
  },
  computed: {
    isAllOptionsChecked () {
      return this.listFoundItems.filter((item) => !item.checked).length === 0
    },
    isIndeterminateCheckbox () {
      return !this.isAllOptionsChecked && this.options.filter((item) => item.checked).length > 0
    },
    listFoundItems () {
      return this.options.filter((item) => this.search.length
        ? ((item.name || '').toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || (item.name || '') !== 'is_null')
        : true)
    }
  },
  destroyed () {
    if (this.$refs['eContainer1']) {
      this.$refs['eContainer1'].removeEventListener('scroll', this.handleScroll)
    }
  },
  watch: {
    search: {
      handler (value) {
        this.loading = true
        if (this.filterDebounce) {
          clearTimeout(this.filterDebounce)
        }
        this.filterDebounce = setTimeout(() => {
          if (this.filterVisibleOnly) {
            if (value) {
              this.options = this.options.filter(el => {
                return el.name.toString().toLowerCase().trim().includes(value.toString().toLowerCase().trim())
              })
            } else {
              this.loadOptions({ attributeId: this.attributeId })
            }
          } else {
            if (value) {
              this.loadOptions({ attributeId: this.attributeId, search: value })
            } else {
              this.options = this.state
            }
          }
          this.loading = false
        }, 700)
      }
    }
  },
  methods: {
    searchItem () {
      if (this.search) {
        this.loadOptions({ attributeId: this.attributeId, search: this.search })
      }
    },
    reset () {
      this.options = this.state

      if (this.options.length === 1) {
        this.options[0].checked = false
      } else {
        this.options.forEach((item) => { item.checked = false })
      }
      this.search = ''
      this.params.filterChangedCallback()
      if (this.filterVisibleOnly) this.loadOptions({ attributeId: this.attributeId })
    },
    async apply () {
      if (!this.isAllOptionsChecked && !this.isIndeterminateCheckbox && this.options.length !== 1) {
        return
      }

      if (this.search.length) {
        // выделить найденые чекбоксы
        let searchById = this.listFoundItems.filter((item) => item.checked).map(item => item.id)
        this.options.forEach(item => {
          item.checked = searchById.includes(item.id)
        })
      }

      this.params.filterChangedCallback()
    },
    handleScroll () {
      if (this.search || !this.isFirstApply || this.filterVisibleOnly) {
        return
      }

      let container = this.$refs['eContainer1']
      const magicNumber = 5
      const hasReachedEnd = container.scrollHeight - container.scrollTop <= (container.clientHeight + magicNumber)
      if (hasReachedEnd) {
        this.offset += 100
        this.loadOptions({ attributeId: this.attributeId })
      }
    },
    async selectAllOptions () {
      let flagToSet = false
      if (this.options.some((item) => !item.checked)) {
        flagToSet = true
      }
      this.options.forEach((item) => { item.checked = flagToSet })

      if (this.isFirstApply && !this.search && this.options.length >= 100) {
        this.loading = true
        this.isFirstApply = false
        this.offset += 100
        let params = { offset: this.offset }
        let result = await APIClient.shared.request(new XrefAPI.GetData(
          parseInt(this.attributeId),
          Object.entries(params)
        ))
        result.forEach(item => { item.checked = true })
        this.state.push(...result)
        this.options = this.state
        this.loading = false
      }
    },
    async loadOptions ({ attributeId, search = '', isFirst = false }) {
      this.loading = true
      let params = {}
      let options = []
      let payload = {}
      const requestTypes = {
        extended_object: APIDataSource.ExtendedObjectQuery,
        query: APIDataSource.QueryQuery,
        registry: APIDataSource.UniqueXrefQuery
      }
      if (!search) {
        params = { limit: 100, offset: this.offset }
      } else {
        params = { search: search, limit: 50 }
      }
      const Request = requestTypes[this.dataSourceService.getType()]
      if (!Request || !this.entityId) {
        return false
      }
      if (this.filterVisibleOnly) {
        const lastPayload = this.dataSourceService.getLastPayload()
        payload = {
          'fields': [this.params.colDef.field],
          'group': this.params.colDef.field
        }
        if (lastPayload.where) {
          payload = Object.assign(payload, {
            where: lastPayload.where
          })
        }
        options = await APIClient.shared.request(new Request(
          parseInt(this.entityId),
          payload
        ))
        let uniqueValues = []
        options = options.map(el => {
          uniqueValues = [...uniqueValues, ...JSON.parse(el[`attr_${attributeId}_`])]
          return JSON.parse(el[`attr_${attributeId}_`])
        })
        options = uniqueValues.filter(el => { return !!el })
      } else {
        options = await APIClient.shared.request(new XrefAPI.GetData(
          parseInt(attributeId),
          Object.entries(params)
        ))
      }
      let newData = options.map((item) => {
        item.checked = false
        return item
      })
      if (search || this.filterVisibleOnly) {
        this.options = [{ id: 'is_null', name: 'Пусто', checked: false }, ...newData]
      } else {
        this.options = [...this.options, ...newData]
        // закешируем результат запроса
        this.state.push(...newData)
      }
      if (isFirst) {
        if (this.options[0].id !== 'is_null') {
          this.state = [{ id: 'is_null', name: 'Пусто', checked: false }, ...this.options]
        } else {
          this.state = [...this.options]
        }
        this.options = this.state

        this.$nextTick(() => {
          this.$refs['eContainer1'].addEventListener('scroll', this.handleScroll)
        })
      }
      this.loading = false
    },
    isFilterActive () {
      if (this.options.length === 1) {
        return this.options.some((item) => item.checked)
      }
      return (this.isAllOptionsChecked || this.isIndeterminateCheckbox)
    },
    getModel () {
      return { filterType: 'xref', type: this.isFilterActive() ? 'active' : 'non-active', value: this.options.filter((item) => item.checked).map((item) => item.id) }
    },

    setModel (model) {
      if (!model) {
        this.reset()
      }
      // не понял почему внизу предупреждение

      console.warn('setModel method in XrefFilter is disabled', model)
      /* if (model && Array.isArray(model)) {
        this.options.forEach((item) => {
          if (model.includes(item.id)) {
            item.checked = true
          }
        })
      } */
    }
  }
}
