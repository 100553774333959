import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TopicRightQueryRepositoryInterface from '../../../domain/repository/TopicRightQueryRepositoryInterface'
import TopicRightsCountQuery from '../../query/TopicRightsCountQuery'

export default class TopicRightsCountHandler implements QueryHandlerInterface {
    private repo: TopicRightQueryRepositoryInterface;

    constructor (repo: TopicRightQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (query: TopicRightsCountQuery): Promise<any> {
      return this.repo.getCount(query.getPayload())
    }
}
