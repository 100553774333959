export default class UsersUploadFromFileCommand {
    private roleId: number;
    private isLoginDynamic: boolean;
    private uploadFile: File;

    constructor (
      roleId: number,
      isLoginDynamic: boolean,
      uploadFile: File
    ) {
      this.roleId = roleId
      this.isLoginDynamic = isLoginDynamic
      this.uploadFile = uploadFile
    }

    getClassName (): string {
      return 'UsersUploadFromFileCommand'
    }

    getRoleId (): number {
      return this.roleId
    }

    getIsLoginDynamic (): boolean {
      return this.isLoginDynamic
    }

    getUploadFile (): File {
      return this.uploadFile
    }
}
