
import Treeselect from '@bingosoftnn/vue-treeselect'
import Connection, { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'
import ConnectionsQuery from '@/services/AccessEditor/application/query/ConnectionsQuery'
import ConnectionsCountQuery from '@/services/AccessEditor/application/query/ConnectionsCountQuery'
import ConnectionPropertiesQuery from '@/services/AccessEditor/application/query/ConnectionPropertiesQuery'
import ConnectionCreateCommand from '@/services/AccessEditor/application/command/ConnectionCreateCommand'
import ConnectionUpdateCommand from '@/services/AccessEditor/application/command/ConnectionUpdateCommand'
import ConnectionDeleteCommand from '@/services/AccessEditor/application/command/ConnectionDeleteCommand'


export default {
  name: 'ConnectionPanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      connectionsPageSize: 0,
      defaultConnectionsPageSize: 100,
      connectionsCurrentPage: 0,
      connectionsPageLimit: 100,
      connectionsCount: 0,
      allConnectionsCount: 0,
      connections: [],
      connection: null,
      connectionDto: null,
      connectionDtoPrev: null,
      connectionDtoParams: [],
      connectionIsRouted: false,
      isDuplicate: false,
      isCoping: false,
      informationPanelHeight: 0,
      isCreateConnectionWindowVisible: false,
      connectionTypes: [
        {
          id: "oauth2",
          name: "oauth2"
        }
      ],
      createConnectionRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        connection_type_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    isConnectionLoading: (state) => state,
    getSaveConnectionError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.access_editor.connection_panel.duplicate_connection_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isConnectionLoading() {
      return this.$store.getters['Connection/isLoading'];
    },
    getSaveConnectionError() {
      return this.$store.getters['Connection/getError'];
    }
  },
  methods: {
    loadConnections(callback) {
      if (this.allConnectionsCount == 0) {
        this.countAndLoadConnections(callback);
      }
      else {
        this.loadAllConnections(callback);
      }
    },
    async countAndLoadConnections(callback) {
      await this.getQueryBus().execute(
        new ConnectionsCountQuery()
      ).then(data => {
        this.connectionsCount = data[0].count;
        this.loadAllConnections(callback);
      });
    },
    async loadAllConnections(callback) {
      await this.getQueryBus().execute(
        new ConnectionsQuery({
          limit: this.connectionsPageLimit,
          offset: this.connectionsCurrentPage
        })
      ).then(data => {
        this.connectionsPageSize = this.connectionsPageLimit;
        this.connections = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    async loadConnectionProperties() {
      await this.getQueryBus().execute(
        new ConnectionPropertiesQuery({
          type: this.connectionDto.connection_type_id
        })
      ).then(data => {
        this.connectionDto.properties = []
        if (data instanceof Array) {
          for (let i = 0; i < data.length; i++) {
            let value_type = 'varchar'
            if (data[i].value === false || data[i].value === true) {
              value_type = 'boolean'
            }
            this.connectionDto.properties.push({
              id: data[i].connection_property_id,
              type: value_type,
              value: data[i].default_value
            })
          }
        }
        else {
          console.log('properties is not array!')
        }
      });
    },
    changeConnection(connectionDto) {
      if (connectionDto !== null) {
        this.connection = Connection.create(connectionDto);
        this.connectionDto = connectionDto;
      } else {
        this.connection = null;
        this.connectionDto = null;
      }
    },
    createConnection() {
      this.connectionDtoPrev = this.connectionDto;
      this.connectionDto = new ConnectionDTO({});
      this.isCreateConnectionWindowVisible = true;
    },
    updateConnection() {
      if (this.connection !== null) {
        if (this.connectionDtoPrev != null) {
          this.connectionDto = this.connectionDtoPrev;
          this.connectionDtoPrev = null;
        }
        this.isCreateConnectionWindowVisible = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveConnection() {
      let connection = Connection.create(this.connectionDto);
      if (this.isCoping) {
        connection.setId(null)
        connection.setGuid(null)
      }
      if (connection.getId() == null) {
        this.getCommandBus().execute(
          new ConnectionCreateCommand(
            connection.getName(),
            connection.getDescription(),
            connection.getType(),
            connection.getProperties()
          )
        ).then(() => {
          this.loadConnections();
          this.closeCreateConnectionWindow();
        });
      }
      else {
        this.getCommandBus().execute(
            new ConnectionUpdateCommand(
              connection.getId(),
              connection.getGuid(),
              connection.getName(),
              connection.getDescription(),
              connection.getType(),
              connection.getProperties()
            )
          ).then(() => {
            if (this.isDuplicate) {
              this.isDuplicate = false;
            }
            else {
              this.loadConnections();
              this.closeCreateConnectionWindow();
            }
          });
      }
    },
    deleteConnection() {
      if (this.connection == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
      else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ConnectionDeleteCommand(
              this.connection.getGuid()
            )
          ).then((response) => {
            this.connection = null;
            this.$refs.connections_table.setCurrentRow(null);
            this.loadConnections();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    copyConnection() {
      if (this.connection !== null) {
        this.isCoping = true;
        this.isCreateConnectionWindowVisible = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    closeCreateConnectionWindow() {
      this.isCoping = false;
      this.isCreateConnectionWindowVisible = false;
    },
    handleConnectionsPageChange(val) {
      val--;
      this.connectionsCurrentPage = (val * this.connectionsPageLimit);
      this.loadConnections();
    },
    getTypesList() {
      return this.connectionTypes
    },
    changeConnectionType() {
      this.loadConnectionProperties()
    },
  },
  mounted() {
    this.loadConnections();
  }
}
