import Vue from 'vue'

import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import ExtractorDeleteCommand from '@/services/EtlEditor/application/command/ExtractorDeleteCommand'
import GroupDeleteCommand from '@/services/EtlEditor/application/command/GroupDeleteCommand'
import LoaderDeleteCommand from '@/services/EtlEditor/application/command/LoaderDeleteCommand'
import TemplateDeleteCommand from '@/services/EtlEditor/application/command/TemplateDeleteCommand'
import TaskDeleteCommand from '@/services/EtlEditor/application/command/TaskDeleteCommand'
import TransformerDeleteCommand from '@/services/EtlEditor/application/command/TransformerDeleteCommand'

import ExtractorCreateHandler from '@/services/EtlEditor/application/handler/command/ExtractorCreateHandler'
import ExtractorDeleteHandler from '@/services/EtlEditor/application/handler/command/ExtractorDeleteHandler'
import ExtractorUpdateHandler from '@/services/EtlEditor/application/handler/command/ExtractorUpdateHandler'
import GroupCreateHandler from '@/services/EtlEditor/application/handler/command/GroupCreateHandler'
import GroupDeleteHandler from '@/services/EtlEditor/application/handler/command/GroupDeleteHandler'
import GroupUpdateHandler from '@/services/EtlEditor/application/handler/command/GroupUpdateHandler'
import LoaderCreateHandler from '@/services/EtlEditor/application/handler/command/LoaderCreateHandler'
import LoaderDeleteHandler from '@/services/EtlEditor/application/handler/command/LoaderDeleteHandler'
import LoaderUpdateHandler from '@/services/EtlEditor/application/handler/command/LoaderUpdateHandler'
import MappingUpdateHandler from '@/services/EtlEditor/application/handler/command/MappingUpdateHandler'
import MappingCreateHandler from '@/services/EtlEditor/application/handler/command/MappingCreateHandler'
import MappingDeleteHandler from '@/services/EtlEditor/application/handler/command/MappingDeleteHandler'
import TaskCreateHandler from '@/services/EtlEditor/application/handler/command/TaskCreateHandler'
import TaskDeleteHandler from '@/services/EtlEditor/application/handler/command/TaskDeleteHandler'
import TaskUpdateHandler from '@/services/EtlEditor/application/handler/command/TaskUpdateHandler'
import TemplateCreateHandler from '@/services/EtlEditor/application/handler/command/TemplateCreateHandler'
import TemplateDeleteHandler from '@/services/EtlEditor/application/handler/command/TemplateDeleteHandler'
import TemplateUpdateHandler from '@/services/EtlEditor/application/handler/command/TemplateUpdateHandler'
import TransformerCreateHandler from '@/services/EtlEditor/application/handler/command/TransformerCreateHandler'
import TransformerDeleteHandler from '@/services/EtlEditor/application/handler/command/TransformerDeleteHandler'
import TransformerUpdateHandler from '@/services/EtlEditor/application/handler/command/TransformerUpdateHandler'

import ExtractorByGuidHandler from '@/services/EtlEditor/application/handler/query/ExtractorByGuidHandler'
import ExtractorsHandler from '@/services/EtlEditor/application/handler/query/ExtractorsHandler'
import ExtractorTypePropertiesHandler from '@/services/EtlEditor/application/handler/query/ExtractorTypePropertiesHandler'
import ExtractorTypesHandler from '@/services/EtlEditor/application/handler/query/ExtractorTypesHandler'
import GroupByGuidHandler from '@/services/EtlEditor/application/handler/query/GroupByGuidHandler'
import LoaderByGuidHandler from '@/services/EtlEditor/application/handler/query/LoaderByGuidHandler'
import LoadersHandler from '@/services/EtlEditor/application/handler/query/LoadersHandler'
import LoaderTypePropertiesHandler from '@/services/EtlEditor/application/handler/query/LoaderTypePropertiesHandler'
import LoaderTypesHandler from '@/services/EtlEditor/application/handler/query/LoaderTypesHandler'
import MappingByGuidHandler from '@/services/EtlEditor/application/handler/query/MappingByGuidHandler'
import MappingElementTypesHandler from '@/services/EtlEditor/application/handler/query/MappingElementTypesHandler'
import MappingsHandler from '@/services/EtlEditor/application/handler/query/MappingsHandler'
import TaskByGuidHandler from '@/services/EtlEditor/application/handler/query/TaskByGuidHandler'
import TaskObjectByTaskIdHandler from '@/services/EtlEditor/application/handler/query/TaskObjectByTaskIdHandler'
import TaskObjectsHandler from '@/services/EtlEditor/application/handler/query/TaskObjectsHandler'
import TemplateByGuidHandler from '@/services/EtlEditor/application/handler/query/TemplateByGuidHandler'
import TransformerTypePropertiesHandler from '@/services/EtlEditor/application/handler/query/TransformerTypePropertiesHandler'
import TransformerByGuidHandler from '@/services/EtlEditor/application/handler/query/TransformerByGuidHandler'
import TransformerTypesHandler from '@/services/EtlEditor/application/handler/query/TransformerTypesHandler'
import TreeElementByElementGuidHandler from '@/services/EtlEditor/application/handler/query/TreeElementByElementGuidHandler'
import TreeElementByGuidHandler from '@/services/EtlEditor/application/handler/query/TreeElementByGuidHandler'
import TreeElementsHandler from '@/services/EtlEditor/application/handler/query/TreeElementsHandler'

import ExtractorByGuidQuery from '@/services/EtlEditor/application/query/ExtractorByGuidQuery'
import GroupByGuidQuery from '@/services/EtlEditor/application/query/GroupByGuidQuery'
import LoaderByGuidQuery from '@/services/EtlEditor/application/query/LoaderByGuidQuery'
import TaskByGuidQuery from '@/services/EtlEditor/application/query/TaskByGuidQuery'
import TemplateByGuidQuery from '@/services/EtlEditor/application/query/TemplateByGuidQuery'
import TransformerByGuidQuery from '@/services/EtlEditor/application/query/TransformerByGuidQuery'

import ExtractorCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/ExtractorCommandRepository'
import GroupCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/GroupCommandRepository'
import LoaderCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/LoaderCommandRepository'
import MappingCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/MappingCommandRepository'
import TaskCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/TaskCommandRepository'
import TemplateCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/TemplateCommandRepository'
import TransformerCommandRepository from '@/services/EtlEditor/infrastructure/domain/repository/TransformerCommandRepository'

import ExtractorQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/ExtractorQueryRepository'
import GroupQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/GroupQueryRepository'
import LoaderQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/LoaderQueryRepository'
import MappingQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/MappingQueryRepository'
import TaskQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/TaskQueryRepository'
import TemplateQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/TemplateQueryRepository'
import TransformerQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/TransformerQueryRepository'
import TreeElementQueryRepository from '@/services/EtlEditor/infrastructure/domain/repository/TreeElementQueryRepository'

import { ExtractorDTO } from '@/services/EtlEditor/domain/model/Extractor'
import { GroupDTO } from '@/services/EtlEditor/domain/model/Group'
import { LoaderDTO } from '@/services/EtlEditor/domain/model/Loader'
import { TaskDTO } from '@/services/EtlEditor/domain/model/Task'
import { TemplateDTO } from '@/services/EtlEditor/domain/model/Template'
import { TransformerDTO } from '@/services/EtlEditor/domain/model/Transformer'

export default Vue.extend({
  data () {
    return {
      defaultModel: {
        extractor: ExtractorDTO,
        group: GroupDTO,
        loader: LoaderDTO,
        task: TaskDTO,
        template: TemplateDTO,
        transformer: TransformerDTO
      },

      queryByGuid: {
        extractor: ExtractorByGuidQuery,
        group: GroupByGuidQuery,
        loader: LoaderByGuidQuery,
        task: TaskByGuidQuery,
        template: TemplateByGuidQuery,
        transformer: TransformerByGuidQuery
      },

      deleteCommands: {
        extractor: ExtractorDeleteCommand,
        group: GroupDeleteCommand,
        loader: LoaderDeleteCommand,
        task: TaskDeleteCommand,
        template: TemplateDeleteCommand,
        transformer: TransformerDeleteCommand
      },

      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'ExtractorCreateCommand': new ExtractorCreateHandler(
            new ExtractorCommandRepository()
          ),
          'ExtractorDeleteCommand': new ExtractorDeleteHandler(
            new ExtractorCommandRepository()
          ),
          'ExtractorUpdateCommand': new ExtractorUpdateHandler(
            new ExtractorCommandRepository()
          ),
          'GroupCreateCommand': new GroupCreateHandler(
            new GroupCommandRepository()
          ),
          'GroupDeleteCommand': new GroupDeleteHandler(
            new GroupCommandRepository()
          ),
          'GroupUpdateCommand': new GroupUpdateHandler(
            new GroupCommandRepository()
          ),
          'LoaderCreateCommand': new LoaderCreateHandler(
            new LoaderCommandRepository()
          ),
          'LoaderDeleteCommand': new LoaderDeleteHandler(
            new LoaderCommandRepository()
          ),
          'LoaderUpdateCommand': new LoaderUpdateHandler(
            new LoaderCommandRepository()
          ),
          'MappingCreateCommand': new MappingCreateHandler(
            new MappingCommandRepository()
          ),
          'MappingDeleteCommand': new MappingDeleteHandler(
            new MappingCommandRepository()
          ),
          'MappingUpdateCommand': new MappingUpdateHandler(
            new MappingCommandRepository()
          ),
          'TaskCreateCommand': new TaskCreateHandler(
            new TaskCommandRepository()
          ),
          'TaskDeleteCommand': new TaskDeleteHandler(
            new TaskCommandRepository()
          ),
          'TaskUpdateCommand': new TaskUpdateHandler(
            new TaskCommandRepository()
          ),
          'TemplateCreateCommand': new TemplateCreateHandler(
            new TemplateCommandRepository()
          ),
          'TemplateDeleteCommand': new TemplateDeleteHandler(
            new TemplateCommandRepository()
          ),
          'TemplateUpdateCommand': new TemplateUpdateHandler(
            new TemplateCommandRepository()
          ),
          'TransformerCreateCommand': new TransformerCreateHandler(
            new TransformerCommandRepository()
          ),
          'TransformerDeleteCommand': new TransformerDeleteHandler(
            new TransformerCommandRepository()
          ),
          'TransformerUpdateCommand': new TransformerUpdateHandler(
            new TransformerCommandRepository()
          )
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ExtractorByGuidQuery': new ExtractorByGuidHandler(
            new ExtractorQueryRepository()
          ),
          'ExtractorsQuery': new ExtractorsHandler(
            new ExtractorQueryRepository()
          ),
          'ExtractorTypePropertiesQuery': new ExtractorTypePropertiesHandler(
            new ExtractorQueryRepository()
          ),
          'ExtractorTypesQuery': new ExtractorTypesHandler(
            new ExtractorQueryRepository()
          ),
          'GroupByGuidQuery': new GroupByGuidHandler(
            new GroupQueryRepository()
          ),
          'LoaderByGuidQuery': new LoaderByGuidHandler(
            new LoaderQueryRepository()
          ),
          'LoadersQuery': new LoadersHandler(
            new LoaderQueryRepository()
          ),
          'LoaderTypePropertiesQuery': new LoaderTypePropertiesHandler(
            new LoaderQueryRepository()
          ),
          'LoaderTypesQuery': new LoaderTypesHandler(
            new LoaderQueryRepository()
          ),
          'MappingByGuidQuery': new MappingByGuidHandler(
            new MappingQueryRepository()
          ),
          'MappingElementTypesQuery': new MappingElementTypesHandler(
            new MappingQueryRepository()
          ),
          'MappingsQuery': new MappingsHandler(
            new MappingQueryRepository()
          ),
          'TaskByGuidQuery': new TaskByGuidHandler(
            new TaskQueryRepository()
          ),
          'TaskObjectByTaskIdQuery': new TaskObjectByTaskIdHandler(
            new TaskQueryRepository()
          ),
          'TaskObjectsQuery': new TaskObjectsHandler(
            new TaskQueryRepository()
          ),
          'TemplateByGuidQuery': new TemplateByGuidHandler(
            new TemplateQueryRepository()
          ),
          'TransformerByGuidQuery': new TransformerByGuidHandler(
            new TransformerQueryRepository()
          ),
          'TransformerTypePropertiesQuery': new TransformerTypePropertiesHandler(
            new TransformerQueryRepository()
          ),
          'TransformerTypesQuery': new TransformerTypesHandler(
            new TransformerQueryRepository()
          ),
          'TreeElementByElementGuidQuery': new TreeElementByElementGuidHandler(
            new TreeElementQueryRepository()
          ),
          'TreeElementByGuidQuery': new TreeElementByGuidHandler(
            new TreeElementQueryRepository()
          ),
          'TreeElementsQuery': new TreeElementsHandler(
            new TreeElementQueryRepository()
          )
        })
      )
    }
  }
})
