
import Vue from 'vue'
import RuleByGuidQuery from '@/services/TaskEditor/application/query/RuleByGuidQuery'
import RuleFieldTypesQuery from '@/services/TaskEditor/application/query/RuleFieldTypesQuery'
import RuleCreateCommand from '@/services/TaskEditor/application/command/RuleCreateCommand'
import RuleUpdateCommand from '@/services/TaskEditor/application/command/RuleUpdateCommand'

import RuleFieldForm from '@/services/TaskEditor/infrastructure/components/forms/Rule/RuleFieldForm.vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import LogicTreeElements from '@/core/infrastructure/components/LogicTreeElements.vue'

export default Vue.extend({
  name: 'RuleForm',

  components: {
    RuleFieldForm,
    RegistrySelectTree,
    LogicTreeElements
  },

  props: {
    value: Object,

    taskRegistryId: {
      type: Number,
      default: null
    }
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Rule/isRuleLoading']
    },

    ruleCreated () {
      return this.$store.getters['Rule/getRuleLocation']
    },

    key () {
      return {
        ruleStates: this.generateGuid() + '__' + this.model.object_id,
        ruleTargetRegistryStates: this.generateGuid() + '__' + this.model.object_id
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('change', value)
      },
      deep: true
    },

    ruleCreated (location) {
      if (location) {
        this.getQueryBus().execute(
          new RuleByGuidQuery(
            location.replace('/rules/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Please input field "Name"', trigger: 'blur' }
        ],
        object_id: [
          { required: true, message: 'Please select "Target object"', trigger: 'change' }
        ],
        related_record_field_id: [
          { required: true, message: 'Please select "Related record field"', trigger: 'change' }
        ]
      },

      mappedProp: {
        field: 'field_id',
        type: 'rule_field_type_id'
      },

      ruleFieldTypes: [],

      model: this.value,

      matchTypeList: [
        {
          id: 'all',
          name: 'all'
        },
        {
          id: 'any',
          name: 'any'
        }
      ],

      fields: []
    }
  },

  async mounted () {
    if (!this.ruleFieldTypes.length) {
      this.ruleFieldTypes = await this.getQueryBus().execute(new RuleFieldTypesQuery())
    }

    if (!this.fields.length) {
      this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.taskRegistryId}&show_children=true`)
        .then((response) => {
          this.fields = response.data.data
        })
    }
  },

  methods: {
    addRuleField () {
      this.model.rule_fields.push({
        field_id: null,
        rule_field_type_id: null,
        users_field_id: null,
        target_table_field_id: null,
        properties: {
          name: null,
          value: null
        }
      })
    },

    objectChange (newValue, oldValue) {
      this.model.rule_states = []
      this.model.rule_target_registry_states = []

      this.key = {
        ruleStates: this.generateGuid(),
        ruleTargetRegistryStates: this.generateGuid()
      }
    },

    removeRuleField (index) {
      this.model.rule_fields.splice(index, 1)
    },

    fieldValid () {
      if (this.$refs.ruleFieldForm) {
        let cntx = 0

        this.$refs.ruleFieldForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.ruleFieldForm.length
      }

      return true
    },

    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.form.validate((valid) => {
        if (valid && this.fieldValid()) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      if (this.isNew) {
        return new RuleCreateCommand(this.model)
      }

      return new RuleUpdateCommand(this.model)
    }
  }
})
