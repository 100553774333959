import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LogicDeleteCommand from '@/services/LogicEditor/application/command/LogicDeleteCommand'
import LogicCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicCommandRepositoryInterface'

export default class LogicDeleteHandler implements CommandHandlerInterface {
    private repository: LogicCommandRepositoryInterface;

    constructor (repository: LogicCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
