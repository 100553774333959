import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ServiceQueryRepositoryInterface from '@/services/ReleaseEditor/domain/repository/ServiceQueryRepositoryInterface'
import store from '@/store'
import { GetServices } from '@/services/ReleaseEditor/infrastructure/store/modules/Service/action-types'

export default class ServiceQueryRepository extends AbstractQueryRepository implements ServiceQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetServices(payload));
    }
}