
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile } from '@fortawesome/free-solid-svg-icons'

import LogModal from '@/services/LogService/infrastructure/components/LogModal/index.vue'
import MessageBox from 'element-ui/packages/message-box/src/main.vue'

import LogsByResourceQuery from '@/services/LogService/application/query/LogsByResourceQuery'
import LogsByResourceAndDateQuery from '@/services/LogService/application/query/LogsByResourceAndDateQuery'
import CountByResourceQuery from '@/services/LogService/application/query/CountByResourceQuery'

library.add(faFile)

export default {
  name: 'LogTable',
  props: {
    resource: String,
    name: String
  },
  components: {
    FontAwesomeIcon
  },
  inject: ['getEventBus', 'getQueryBus'],
  data () {
    return {
      datePick: null,
      loading: false,
      logs: [],
      pageSize: 25,
      pageTotal: 0,
      limit: 0,
      offset: 0,
      currentPage: 1
    }
  },
  methods: {
    async loadLogs (limit: number, offset: number) {
      this.loading = true

      try {
        this.logs = await this.getQueryBus().execute(
          new LogsByResourceQuery(
            {
              limit,
              offset
            },
            this.resource
          )
        )

        this.loading = false
      } catch (_err) {
        console.error(_err)
      }
    },
    async loadLogsByDate (limit: number, offset: number, date: string) {
      this.loading = true

      try {
        this.logs = await this.getQueryBus().execute(
          new LogsByResourceAndDateQuery(
            {
              limit,
              offset,
              date
            },
            this.resource
          )
        )

        this.loading = false
      } catch (_err) {
        console.error(_err)
      }
    },
    async getCount (date: string | null) {
      let payload = {}

      if (date !== null) {
        // @ts-ignore
        payload.date = date
      }

      try {
        const result = await this.getQueryBus().execute(
          new CountByResourceQuery(payload, this.resource)
        )

        this.pageTotal = result.count
      } catch (_err) {
        console.error(_err)
      }
    },
    pageChange (value) {
      switch (value) {
        case 1:
          this.offset = 0
          this.limit = this.pageSize
          break
        default:
          this.limit = this.pageSize * value
          this.offset = this.limit - this.pageSize
          break
      }

      this.currentPage = value

      if (this.datePick !== null) {
        this.loadLogsByDate(this.limit, this.offset, this.datePick.toLocaleDateString())
        return
      }

      this.loadLogs(this.limit, this.offset)
    },
    async pickDate (value) {
      this.datePick = value
      this.limit = this.pageSize
      this.offset = 0
      this.currentPage = 1

      if (value === null) {
        this.getCount(value)
        this.loadLogs(this.limit, this.offset)
        return
      }

      this.getCount(value.toLocaleDateString())
      this.loadLogsByDate(this.limit, this.offset, value.toLocaleDateString())
    },
    refreshTable () {
      if (this.datePick !== null) {
        this.getCount(this.datePick.toLocaleDateString())
        this.loadLogsByDate(this.limit, this.offset, this.datePick.toLocaleDateString())
        return
      }

      this.getCount(null)
      this.loadLogs(this.limit, this.offset)
    },
    openModalLog (index) {
      const h = this.$createElement
      this.$msgbox({
        title: '',
        customClass: 'wrapper-log-service-msgbox',
        showCancelButton: false,
        showConfirmButton: false,
        message: h('p', { key: Math.random() }, [
          h(LogModal, { props: { log: this.logs[index] } })
        ])
      })
    }
  },
  mounted () {
    this.limit = this.pageSize
    this.getCount(null)
    this.loadLogs(this.limit, this.offset)
  }
}
