
export interface IRequestParameter {
  alias: string;
  description?: string;
  guid: string;
  id: number;
  is_required: boolean;
  nullable: boolean;
  properties: object;
  request_id: number;
  type: string;
}

export class RequestParameter {
  alias: string;
  description?: string;
  guid: string;
  id: number;
  is_required: boolean;
  nullable: boolean;
  properties: object;
  request_id: number;
  type: string;

  constructor (data: IRequestParameter) {
    this.alias = data.alias
    this.description = data.description
    this.guid = data.guid
    this.id = data.id
    this.is_required = data.is_required
    this.nullable = data.nullable
    this.properties = data.properties
    this.request_id = data.request_id
    this.type = data.type
  }

  static create () {
    return new RequestParameter({
      alias: 'unnamed_param',
      guid: null,
      id: null,
      is_required: false,
      nullable: false,
      properties: {},
      request_id: null,
      type: 'path'
    })
  }
}
