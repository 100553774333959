<template>
  <div class="source-form">
    <el-form ref="form" :model="value" :rules="formRules" size="mini" label-position="top">
      <el-row :gutter="10">
        <el-col :span="showAliasField ? (showXmlField ? 6 : 8) : 12">
          <el-form-item :label="$t('report_editor.form.source_type')" prop="report_source_type_id">
            <el-select
              v-model="localValue.report_source_type_id"
              :placeholder="$t('report_editor.form.source_type')"
              @change="sourceTypeChange"
            >
              <el-option
                v-for="item in localReportSourceTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="showAliasField ? (showXmlField ? 6 : 8) : 12">
          <el-form-item :label="$t('report_editor.form.source')" prop="properties.id">
            <template v-if="localValue.report_source_type_id === 'extended_object'">
              <el-select
                v-model="localValue.properties.id"
                :placeholder="$t('report_editor.object_types.extended_object')"
                @change="selectedExtendedObject"
              >
                <el-option
                  v-for="item in extendedObjectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>

            <template v-if="localValue.report_source_type_id === 'query'">
              <el-select
                v-model="localValue.properties.id"
                :placeholder="$t('report_editor.object_types.query')"
                @change="selectedQuery"
              >
                <el-option
                  v-for="item in queryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>

            <template v-if="localValue.report_source_type_id === 'registry'">
              <registry-select-tree
                type="registry"
                v-model="localValue.properties"
                :placeholder="$t('report_editor.object_types.registry')"
              ></registry-select-tree>
            </template>

            <template v-if="localValue.report_source_type_id === 'xml'">
              <el-input v-model="localValue.properties.name" :placeholder="$t('report_editor.form.name')"></el-input>
            </template>
          </el-form-item>
        </el-col>

        <el-col v-if="showAliasField" :span="showXmlField ? 6 : 8">
          <el-form-item
            :label="$t('report_editor.form.alias')"
            prop="properties.alias"
          >
            <el-input
              :placeholder="$t('report_editor.form.alias')"
              v-model="localValue.properties.alias"
              @input="aliasChange">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col v-if="showXmlField" :span="showXmlField ? 6 : 8">
          <el-form-item
            :label="$t('report_editor.form.xsd_schema')"
            prop="properties.xsd"
          >
            <el-button
              type="default"
              style="width: 100%;"
              @click="dialogVisible = true"
            >
              Импорт XSD
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item v-if="localValue.parent_id !== null" prop="properties.relation">
        <template slot="label">
          {{ $t('report_editor.form.relations') }}

          <el-tooltip class="item" effect="dark" :content="$t('report_editor.relation.add')" placement="top">
            <el-button
              type="success"
              icon="el-icon-plus"
              circle
              class="el-button--plus"
              @click="addRelation()"
            ></el-button>
          </el-tooltip>
        </template>

        <el-form-item v-for="(item, index) in localValue.properties.relation" :key="index" class="multi-form">
          <relation-form
            ref="relationFrom"
            v-model="localValue.properties.relation[index]"
            :index="index"
            :from-field-list="fields.from"
            :to-field-list="fields.to"
            :remove="removeRelation"
          ></relation-form>
        </el-form-item>
      </el-form-item>

      <el-form-item>
        <template slot="label">
          {{ $t('report_editor.form.filters') }}

          <el-tooltip class="item" effect="dark" :content="$t('report_editor.filter.add')" placement="top">
            <el-button
              type="success"
              icon="el-icon-plus"
              circle
              class="el-button--plus"
              @click="addFilter()"
            ></el-button>
          </el-tooltip>
        </template>

        <el-form-item v-for="(filter, index) in localValue.report_filters" :key="filter.id" class="multi-form">
          <filter-form
            ref="filterForm"
            v-model="localValue.report_filters[index]"
            :index="index"
            :field-list="fieldList"
            :filter-type-list="filterTypeList"
            :source-type="localValue.report_source_type_id"
            :remove="removeFilter"
          ></filter-form>
        </el-form-item>
      </el-form-item>
    </el-form>

    <el-dialog
      title="Импорт XSD"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closeImport"
    >
      <span>
        <xsd-upload
          ref="xsdUpload"
          v-model="zipXsdData"
          @on-remove="zipXsdData = null"
        ></xsd-upload>

        <xsd-parser
          v-if="zipXsdData"
          v-model="localValue.properties.xsd"
          :data="zipXsdData"
        ></xsd-parser>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false; zipXsdData = null;">
          {{ $t('main.button.ok') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import FilterForm from './FilterForm'
import RelationForm from './RelationForm'
import ExtendedObjectField from '../../BIEditor/Models/ExtendedObjectField'
import XsdUpload from '@/components/ReportEditor/components/typeComponents/XsdUpload'
import XsdParser from '@/components/ReportEditor/components/typeComponents/XsdParser'

export default {
  name: 'SourceForm',

  components: {
    RegistrySelectTree,
    FilterForm,
    RelationForm,
    XsdUpload,
    XsdParser
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    sources: {
      type: Array,
      default () {
        return []
      }
    },

    reportType: {
      type: String,
      default () {
        return 'report'
      }
    },

    reportSourceTypes: {
      type: Array,
      default () {
        return []
      }
    },

    extendedObjectList: {
      type: Array,
      default () {
        return []
      }
    },

    queryList: {
      type: Array,
      default () {
        return []
      }
    },

    filterTypeList: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(this.localValue) !== JSON.stringify(value)) {
          this.localValue = value
        }
      },
      deep: true
    },

    'value.properties.id': {
      handler: function (value, oldValue) {
        if (value.properties.id !== oldValue.properties.id) {
          value.properties.relation = []
        }
      }
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(this.value) !== JSON.stringify(value)) {
          this.$emit('input', value)
        }
      },
      deep: true
    }
  },

  computed: {
    localReportSourceTypes () {
      if (['document', 'xml', 'json'].includes(this.reportType) && this.localValue.parent_id === null) {
        return this.reportSourceTypes
      }

      return this.reportSourceTypes.filter(el => el.id !== 'xml')
    },

    showXmlField () {
      return this.localValue.report_source_type_id === 'xml'
    },

    showAliasField () {
      return ['document', 'xml', 'report', 'json'].includes(this.reportType)
    },

    formRules () {
      const rules = {
        report_source_type_id: [
          {
            required: true,
            message: this.$t('report_editor.errors.report_source_type_id'),
            trigger: 'change'
          }
        ],
        'properties.alias': [
          {
            required: true,
            message: this.$t('report_editor.errors.properties_alias'),
            trigger: 'blur'
          }
        ]
      }

      if (this.localValue.report_source_type_id !== 'xml') {
        rules['properties.id'] = [
          {
            required: true,
            message: this.$t('report_editor.errors.properties_id'),
            trigger: 'change'
          }
        ]

        rules['properties.relation'] = [
          {
            required: true,
            message: 'Укажите связи для источников',
            trigger: 'change'
          }
        ]
      }

      return rules
    }
  },

  data () {
    return {
      localValue: this.value,
      fields: {
        from: [],
        to: []
      },
      fieldList: [],
      dialogVisible: false,
      zipXsdData: null
    }
  },

  async mounted () {
    if (this.value.report_source_type_id === 'extended_object') {
      const response = await new ExtendedObjectField().params({ extended_object_id: this.value.properties.id }).$get()

      response.forEach(item => {
        this.fieldList.push({
          id: item.name,
          name: item.name,
          entity_type_id: item.entity_type_id
        })
      })
    } else if (this.value.report_source_type_id === 'query') {
      await this.$http
        .get(`${this.$config.api}/bieditor/queries/fields/${this.value.properties.id}`)
        .then((response) => {
          response.data.forEach(item => {
            this.fieldList.push({
              id: item.name,
              name: item.name,
              entity_type_id: null
            })
          })
        })
    } else if (this.value.report_source_type_id === 'registry') {
      this.fieldList.push({
        id: 'id',
        name: 'id',
        entity_type_id: null
      })

      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.value.properties.id}&is_field=true`)
        .then((response) => {
          response.data.data.forEach(item => {
            const attr = `attr_${item.id}_`
            this.fieldList.push({
              id: attr,
              name: `${item.name} (${attr})`,
              entity_type_id: item.entity_type_id
            })
          })
        })
    } else {
      this.fieldList.push({
        id: 'id',
        name: 'id',
        entity_type_id: null
      })
    }

    if (this.value.parent_id !== null) {
      await this.loadFields(this.value.parent_id, 'from')
      await this.loadFields(this.value.id, 'to')
    }
  },

  methods: {
    closeImport () {
      this.zipXsdData = null
      this.dialogVisible = false
      this.$refs.xsdUpload.clearFiles()
    },

    isValid () {
      let validForm = true
      this.$refs.form.validate((valid) => {
        validForm = valid
      })

      if (this.$refs.filterForm) {
        const filtersValid = []
        this.$refs.filterForm.forEach((form) => {
          filtersValid.push(form.isValid())
        })
        validForm = filtersValid.includes(false) !== true
      }

      if (this.$refs.relationFrom) {
        const relationsValid = []
        this.$refs.relationFrom.forEach((form) => {
          relationsValid.push(form.isValid())
        })
        validForm = relationsValid.includes(false) !== true
      }

      return validForm
    },

    selectedExtendedObject (value) {
      let extendedObject = this.extendedObjectList.find(item => value === item.id)

      this.localValue.properties.name = extendedObject.name

      if (!this.localValue.properties.alias) {
        this.localValue.properties.alias = this.aliasReplace(extendedObject.name)
      }
    },

    selectedQuery (value) {
      let query = this.queryList.find(item => value === item.id)

      this.localValue.properties.name = query.name

      if (!this.localValue.properties.alias) {
        this.localValue.properties.alias = this.aliasReplace(query.name)
      }
    },

    sourceTypeChange () {
      let relation = this.localValue.properties.relation

      this.localValue.properties = {
        id: null,
        name: null,
        alias: null,
        xsd: null,
        structure: null
      }

      if (this.localValue.parent_id && relation) {
        this.localValue.properties.relation = relation
      }
    },

    addFilter () {
      this.localValue.report_filters.push({
        report_source_id: this.localValue.id,
        report_filter_type_id: null,
        operation_type_id: null,
        properties: {
          field: null,
          type: null,
          value: null
        }
      })

      this.$emit('input', this.localValue)
    },

    addRelation () {
      if (!this.localValue.properties.relation) {
        this.localValue.properties.relation = []
      }

      this.localValue.properties.relation.push({
        from: null,
        to: null
      })

      this.$emit('input', this.localValue)
    },

    removeRelation (index) {
      this.localValue.properties.relation.splice(index, 1)

      this.$emit('input', this.localValue)
    },

    removeFilter (index) {
      this.localValue.report_filters.splice(index, 1)

      this.$emit('input', this.localValue)
    },

    aliasReplace (alias) {
      return alias
        .replace(/(\s)+/g, '_')
        .replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    async loadFields (value, listId = 'from') {
      let source = this.sources.find(item => item.id === value)
      let response = []
      if (source.report_source_type_id === 'extended_object') {
        response = await new ExtendedObjectField().params({ extended_object_id: source.properties.id }).$get()
      } else if (source.report_source_type_id === 'query') {
        await this.$http.get(`${this.$config.api}/bieditor/queries/fields/${source.properties.id}`).then((res) => {
          response.push(...res.data)
        })
      }
      response.forEach((item) => {
        this.fields[listId].push({
          id: item.name,
          label: item.name,
          disabled: false
        })
      })
    },

    aliasChange (value) {
      this.localValue.properties.alias = value = this.aliasReplace(value)
    }
  }
}
</script>
