<template>
  <div>
    <el-divider v-if="value.isVectorTile"></el-divider>
    <el-row>
      <el-col :span="24">
        <span style="margin-right: 10px">слой VectorTile</span>
        <el-checkbox v-model="value.isVectorTile"></el-checkbox>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="value.isVectorTile" style="display: flex;">
        <div style="margin-right: 10px">время жизни кэша VectorTile</div>
        <el-input-number v-model="value.vectorTileCacheTTL" controls-position="right" size="mini" style="width: 120px;"></el-input-number>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="value.isVectorTile" style="display: flex;">
        <div style="margin-right: 10px">extent</div>
        <el-input-number v-model="value.vectorTileExtent" controls-position="right" size="mini" style="width: 120px;"></el-input-number>
        <div style="display: flex; align-items: center; padding: 4px;">
          <el-tooltip content="Размер тайла в целочисленных координатах (по умолчанию 4096)" placement="right" effect="light">
            <i class="el-icon-warning-outline" style="font-size: 1.35em"></i>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="value.isVectorTile">
        <span style="margin-right: 10px">использовать WebGL</span>
        <el-checkbox v-model="value.isWebGLVectorTile"></el-checkbox>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="value.isVectorTile">
        <span style="margin-right: 10px">Разрешить импорт</span>
        <el-checkbox v-model="value.enableImport"></el-checkbox>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="value.enableImport">
        <span style="margin-right: 10px">Показывать только геометрию текущей записи</span>
        <el-checkbox v-model="value.addRecordFilter"></el-checkbox>
      </el-col>
    </el-row>
    <el-divider v-if="value.isVectorTile"></el-divider>
    <el-form-item label="Реестр">
      <registry-select-tree
        v-model="value.entityId"
        type="registry"
        value-as="number">
      </registry-select-tree>
    </el-form-item>
    <el-form-item label="Взять путь из поля источника">
      <el-checkbox
        v-model="value.enableExternalSourceFromField"></el-checkbox>
    </el-form-item>
    <el-form-item
      v-if="value.enableExternalSourceFromField"
      label="Тип источника в поле">
      <el-select
        v-model="value.externalSourceField.type">
        <el-option
          key="WMS"
          label="WMS"
          value="WMS"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="Поле - источник"
      v-if="value.enableExternalSourceFromField"
    >
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.externalSourceField.field"/>
    </el-form-item>
    <el-form-item
      v-if="!value.enableExternalSourceFromField"
      label="Геометрия">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.geometryField"/>
    </el-form-item>
    <el-form-item label="Метки значений">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.marksField"/>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Способ обработки">
      <el-select
        v-model="value.srsHandlingType">
        <el-option
          v-for="item in $locale.interface_editor.component_editor.map_layers.srs_handling_types"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
export default {
  components: {
    SelectTree,
    RegistrySelectTree,
    CoordinateSystem
  },
  name: 'Registry',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          entityId: null,
          geometryField: null,
          marksField: null
        }
      }
    }
  },
  data () {
    return {
      fields: []
    }
  },
  mounted () {
    this.loadFields(this.value.entityId)
  },
  watch: {
    'value.entityId': {
      handler (value) {
        this.loadFields(value)
        this.value.geometryField = null
        this.value.marksField = null
      }
    }
  },
  methods: {
    async loadFields (registryId) {
      if (!registryId) {
        return false
      }
      this.fields = await Registry.params({
        parent_id: registryId,
        show_children: true,
        properties: { is_visible_in_grid: true } }).$get()
    }
  }
}
</script>

<style scoped>

</style>
