
import LogPanel from '@/services/Databus/infrastructure/components/UtilitiesPanel/LogPanel/index.vue'
import WorkerQueuePanel from '@/services/Databus/infrastructure/components/UtilitiesPanel/WorkerQueuePanel/index.vue'

export default {
  name: 'utilitiesPanel',
  components: {
    LogPanel,
    WorkerQueuePanel
  },
  data () {
    return {
      activeUtility: "logs"
    }
  },
  inject: ['getQueryBus', 'getCommandBus']
}
