<template>
  <div class="select-panel" ref="style_select_panel">
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createStyle"></el-button>
      <el-button icon="el-icon-edit" size="mini" circle  @click="editStyle"></el-button>
      <el-button icon="el-icon-delete" size="mini" circle  @click="deleteStyle"></el-button>
      <treeselect
        :placeholder="$locale.main.fields.select"
        :normalizer="(node) => {return {id: node.id, label: node.name}}"
        :options="styleOptions"
        :clear-value-text="$locale.main.message.clear"
        :loading-text="$locale.main.message.loading"
        :disable-immediate-search="false"
        :async="false"
        :cache-options="false"
        :append-to-body="true"
        :clearable="false"
        v-model="styleOption"
        :disabled="field !== null"
        class="toolbar-options"
    ></treeselect> 
      <el-checkbox v-if="!hideAll" v-model="allStyles" :label="$locale.map_editor.style_panel.all_styles"></el-checkbox>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="styles"
          stripe
          border
          ref="styles_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeStyle"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="180"
        ></el-table-column>                
        <el-table-column
          prop="style_type_id"
          :label="$locale.main.fields.type"
          width="180"
        >
          <template slot-scope="scope">
            {{ $locale.map_editor.geometry_types[scope.row.style_type_id] }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$locale.main.fields.icon"
          width="80"
        >
          <template slot-scope="scope">
            <span><LayerIcon :type="scope.row.style_type_id" :properties="scope.row.properties"/></span>
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="style-pagination"
            :page-size="stylesPageSize"
            :layout="allStyles ? 'total, prev, pager, next' : 'total'"
            :total="stylesCount"
            @current-change="handleStylesPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
      <!--<el-col v-if="style !== null" v-bind:class="{ 'mini': style !== null }">
        Image
      </el-col>-->
    </el-row>     
  </div>
</template>

<script type="ts">
import Treeselect from '@bingosoftnn/vue-treeselect'
import ProjectStylesQuery from '@/services/MapEditor/application/query/ProjectStylesQuery'
import StylesQuery from '@/services/MapEditor/application/query/StylesQuery'
import StylesCountQuery from '@/services/MapEditor/application/query/StylesCountQuery'
import Style from '@/services/MapEditor/domain/model/Style/Style'
import StyleDeleteCommand from '@/services/MapEditor/application/command/StyleDeleteCommand'
import LayerIcon from '@/core/infrastructure/components/LayerIcon.vue'

export default {
  name: 'StyleSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'projectGuid', 'hideAll', 'field'],
  components: {
    Treeselect,
    LayerIcon
  },
  data() {
    return {
      loading: false,
      stylesPageSize: 0,
      defaultStylesPageSize: 100,
      stylesCurrentPage: 0,
      stylesPageLimit: 100,
      stylesCount: 0,
      allStylesCount: 0,
      allStyles: false,
      styles: [],
      style_: null,
      informationPanelHeight: 0,
      styleOptions: [{
        id: 'all',
        name: this.$locale.map_editor.geometry_types.all
      },{
        id: 'point',
        name: this.$locale.map_editor.geometry_types.point
      },{
        id: 'linestring',
        name: this.$locale.map_editor.geometry_types.linestring
      },{
        id: 'polygon',
        name: this.$locale.map_editor.geometry_types.polygon
      },{
        id: 'label',
        name: this.$locale.map_editor.geometry_types.label
      }],
      styleOption: this.field
    }
  },
  computed: {
    styleCreated() {
      return this.$store.getters['Style/getLocation'];
    }
  },
  watch: {
    allStyles (val) {
      this.style_ = null;
      this.$refs.styles_table.setCurrentRow(null);
      if (val) {        
        this.stylesPageSize = this.defaultStylesPageSize;
      }
      this.loadStyles();
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.style_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.style_select_panel.clientHeight - 36;
      }
    },
    changeStyle(styleDto) {
      this.style_ = Style.create(styleDto);
    },
    submit(callback) {
      if (this.style_.getType() == this.field) {
        this.getEventBus().$emit('styleSelected', this.style_, this.parentFormId, this.styleOption);
        if (typeof callback == 'function') {
          callback();
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages[`select_${this.field}_type`],
          type: 'warning'
        });
      }
    },
    createStyle() {
      this.getEventBus().$emit('createForm', this.styleOption, {'parentFormId': this.formId, 'styleCreatedCallback': this.refreshStyle, 'type': this.styleOption});
    },
    editStyle() {
      if (this.style_ == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {     
        this.getEventBus().$emit('createForm', `${this.style_.getType()}_edit`, {'parentFormId': this.formId, 'style_': this.style_, 'styleUpdatedCallback': this.refreshStyle, 'type': this.style_.getType()});
      }
    },
    async refreshStyle(style) {
      await this.loadStyles();
      this.selectStyle(style);
    },
    deleteStyle() {
      if (this.style_ == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          
          this.getCommandBus().execute(
            new StyleDeleteCommand(
              this.style_.getGuid()
            )
          ).then((response) => {
            this.style_ = null;
            this.$refs.styles_table.setCurrentRow(null);
            this.loadStyles();
          });

        }).catch((error) => { console.log(error); })
      }
    },
    selectStyle(style) {
      let rows = this.styles.filter(el => {
        return el.guid == style.getGuid()
      });
      if (rows.length) {
        this.$refs.styles_table.setCurrentRow(rows[0]);
      }
    },
    handleStylesPageChange(val) {
      val--;
      this.stylesCurrentPage = (val * this.stylesPageLimit);
      this.loadStyles();
    },
    loadStyles(callback) {
      if (!this.allStyles) {
        this.loadProjectStyles(callback);        
      } else {
        if (this.allStylesCount == 0) {
          this.countAndLoadStyles(callback);
        } else {
          this.loadAllStyles(callback);
        }      
      }
    },
    async loadProjectStyles(callback) {
      let payload = {}
      if (this.styleOption) {
        payload['style_type_id'] = this.styleOption;
      }
      await this.getQueryBus().execute(
        new ProjectStylesQuery(this.projectGuid, payload)
      ).then(data => {
        this.stylesPageSize = data.length;
        this.stylesCount = data.length;
        this.styles = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async countAndLoadStyles(callback) {
      await this.getQueryBus().execute(
        new StylesCountQuery({
        })
      ).then(data => {
        this.stylesCount = data[0].count;      
        this.loadAllStyles(callback);
      });
    },
    async loadAllStyles(callback) {
      await this.getQueryBus().execute(
        new StylesQuery({
          limit: this.stylesPageLimit,
          offset: this.stylesCurrentPage
        })
      ).then(data => {
        this.stylesPageSize = this.stylesPageLimit;
        this.styles = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    }
  },
  mounted() {
    this.loadStyles(() => {
      this.resizePanel();
    });   
  }
}
</script>