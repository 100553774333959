<template>
  <div style="height: 100%">
    <div class="schema-viewer" v-loading="schemaLoading">
      <schema-viewer
        v-if="schema"
        :schema="schema"
        :actions="actions"
      />
    </div>
    <el-tabs class="tab-panel" v-model="activeTab">
      <el-tab-pane :label="$t('bpmngatewayservice.history')" name="history" class="tab-item">
        <HistoryTable
          ref="historyTable"
          :process-id="process.id_"
          @loaded="history = $event"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('bpmngatewayservice.timers')" name="timers" class="tab-item">
        <TimersTable
          ref="timersTable"
          :process-id="process.id_"
        />
      </el-tab-pane>
      <el-tab-pane :label="$t('bpmngatewayservice.variables')" name="variables" class="tab-item">
        <VariablesTable
          ref="variablesTable"
          :process-id="process.id_"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TimersTable from '@/services/BpmnGateway/infrastructure/components/TimersTable.vue'
import HistoryTable from '@/services/BpmnGateway/infrastructure/components/HistoryTable.vue'
import VariablesTable from '@/services/BpmnGateway/infrastructure/components/VariablesTable.vue'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MonitoringAPI } from '@/services/BpmnGateway/infrastructure/api/MonitoringAPI'
import SchemaViewer from '@/services/BpmnGateway/infrastructure/components/SchemaViewer.vue'

export default {
  name: 'ProcessInstanceInfo',
  components: { SchemaViewer, VariablesTable, HistoryTable, TimersTable },
  props: {
    process: Object
  },
  data () {
    return {
      activeTab: 'history',
      schema: null,
      history: [],
      schemaLoading: false
    }
  },
  mounted () {
    this.loadSchema()
  },
  computed: {
    actions () {
      return this.history.map((item) => {
        let status = null
        if (item.start_time_) {
          status = 'started'
        }
        if (item.end_time_) {
          status = 'completed'
        }
        return {
          id: item.act_id_,
          status
        }
      })
    }
  },
  methods: {
    async loadSchema () {
      const schemaName = this.process.proc_def_id_
      this.schemaLoading = true
      if (schemaName) {
        const { schema } = await APIClient.shared.request(new MonitoringAPI.GetSchema(
          schemaName
        ))
        this.schema = schema
      }
      this.schemaLoading = false
    },
    update () {
      this.$refs['historyTable']?.loadData()
      this.$refs['timersTable']?.loadData()
      this.$refs['variablesTable']?.loadData()
      this.loadSchema()
    }
  }
}
</script>

<style scoped>
.schema-viewer {
  height: 60%;
}

.tab-panel {
  height: 40%;
  display: flex;
  flex-direction: column;
}

.tab-item {
  height: 100%;
}
</style>
