<template>
  <div class="document_editor">
    <iframe :src="url" width="100%" height="99%" style="border: none" title=""></iframe>

    <el-button
      class="cd-button"
      type="primary"
      :style="{ right: !collapsed ? '0px' : '400px' }"
      :icon="!collapsed ? 'el-icon-caret-left' : 'el-icon-caret-right'"
      @click="collapsed = !collapsed">
    </el-button>

    <div class="cd-panel" v-bind:class="{ 'is-visible': collapsed }">
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <div class="filter-input">
            <el-input
              placeholder="Искать..."
              v-model="filterText"
              size="mini"
            ></el-input>
          </div>

          <el-scrollbar class="tree_scroll">
            <el-tree
              ref="tree"
              :data="fields"
              :props="{ children: 'children', label: 'name' }"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              default-expand-all
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-popover
                  placement="bottom"
                  title="Информация"
                  width="500"
                  trigger="click"
                >
                  <div class="info-var">
                    <p><b>Переменная:</b> <span class="copy-var" v-clipboard:copy="data.id" @click.stop="() => {}">{{ data.id }}</span></p>
                    <p><b>Тип:</b> {{ getTypeName(data.type) }}</p>
                    <p><b>Подтип:</b> {{ getTypeName(data.subType) }}</p>
                    <p><b>Описание:</b> {{ data.description || '(пусто)' }}</p>

                    <span>
                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="getCopyIf(data)"
                      >
                        if ... else ...
                      </el-button>

                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="getCopyFor(data)"
                      >
                        for ... else ...
                      </el-button>

                      <el-button
                        type="default"
                        size="mini"
                        v-clipboard:copy="data.name"
                      >
                        Имя переменной
                      </el-button>

                      <el-button
                        type="default"
                        size="mini"
                        @click="twigDoc"
                      >
                        Twig Doc
                      </el-button>
                    </span>
                  </div>

                  <span slot="reference" class="node-label">
                    <span class="node-label__name">
                      {{ node.label }}
                    </span>
                    <span class="node-label__info">
                      <template v-if="data.subType">
                        (тип: {{ getTypeName(data.type) }}, подтип: {{ getTypeName(data.subType) }})
                      </template>
                      <template v-else>
                        (тип: {{ getTypeName(data.type) }})
                      </template>
                    </span>
                  </span>
                </el-popover>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentEditor',

  props: {
    reportId: Number,
    filename: String
  },

  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  computed: {
    url () {
      let filePath = `file:///data/reports/${this.filename}`

      return `${this.$config.api}/documenteditor?file_path=${filePath}`
    }
  },

  data () {
    return {
      fields: [],
      collapsed: false,
      filterText: ''
    }
  },

  async mounted () {
    await this.$http.get(`${this.$config.api}/reporteditor/report_source_columns/${this.reportId}`).then(response => {
      this.fields.push(...response.data)
    })
  },

  methods: {
    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.indexOf(value) !== -1
    },

    getCopyIf (data) {
      return `{% if ${data.id} %}\n\n` +
        `{{ ${data.id} }}\n\n` +
        `{% else %}\n\n` +
        `Нет данных\n\n` +
        `{% endif %}`
    },

    getCopyFor (data) {
      return `{% for item in ${data.id} %}\n\n` +
        `{{ item }}\n\n` +
        `{% else %}\n\n` +
        `Нет данных\n\n` +
        `{% endfor %}`
    },

    twigDoc () {
      const a = document.createElement('a')
      a.href = 'https://twig.symfony.com/doc/'
      a.target = '_blank'
      a.click()
      a.remove()
    },

    getTypeName (type) {
      switch (type) {
        case 'object':
          return 'объект'
        case 'array':
          return 'массив'
        case 'date':
          return 'дата'
        case 'decimal':
        case 'number':
          return 'число'
        case 'boolean':
          return 'логический'
        case 'string':
          return 'текст'
        default:
          return 'любой'
      }
    }
  }
}
</script>

<style lang="scss">
.info-var {
  p {
    margin: 7px 0;
  }
}

.copy-var {
  color: #409EFF;
  cursor: pointer;

  &:hover {
    color: #66b1ff;
    text-decoration: underline;
  }
}

.document_editor {
  height: 99%;
  width: 100%;

  .filter-input {
    padding: 5px;
  }

  .el-tree-node>.el-tree-node__children {
    border-left: 2px solid #4FA9F3;
    margin-left: 5px;
  }

  .node-label {
    cursor: pointer;
  }

  .node-label:hover {
    color: #4FA9F3;
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .cd-button {
    position: absolute;
    top: calc(50% - 35px);
    border-radius: 5px 0 0 5px;
    height: 70px;
    width: 10px;
    -webkit-transition: top 1s ease-out 0.5s;
    transition: top 1s ease-out 0.5s;
    padding: 10px;
    text-align: center;
  }

  .cd-panel {
    visibility: hidden;
    transition: visibility 0s 0.6s;
  }

  .cd-panel.is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
  }

  .cd-panel-container {
    position: absolute;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;

    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0.3s;

    transform: translate3d(100%, 0, 0);

    background-color: #fff;
  }

  .is-visible .cd-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
  }

  .tree_scroll {
    height: calc(100vh - 80px);
    width: 100%;
  }
}
</style>
