import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ReleaseQueryRepositoryInterface from '../../../domain/repository/ReleaseQueryRepositoryInterface'
import ReleasesCountQuery from '../../query/ReleasesCountQuery'

export default class ReleasesCountHandler implements QueryHandlerInterface
{
    private repo: ReleaseQueryRepositoryInterface;

    constructor(repo: ReleaseQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: ReleasesCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}