import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetConstraintByGuid, GetConstraintTypes } from '@/services/LogicEditor/infrastructure/store/modules/Constraint/action-types'
import ConstraintQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintQueryRepositoryInterface'

export default class ConstraintQueryRepository extends AbstractQueryRepository implements ConstraintQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetConstraintByGuid(guid))
  }

  getConstraintTypes (): Promise<any> {
    return store.dispatch(new GetConstraintTypes())
  }
}
