import RuleGroupCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleGroupCommandRepositoryInterface'
import store from '@/store'
import { CreateRuleGroup, UpdateRuleGroup, DeleteRuleGroup } from '@/services/TaskEditor/infrastructure/store/modules/RuleGroup/action-types'
import RuleGroup from '@/services/TaskEditor/domain/model/RuleGroup'

export default class RuleGroupCommandRepository implements RuleGroupCommandRepositoryInterface {
  insert (item: RuleGroup): Promise<void> {
    return store.dispatch(new CreateRuleGroup(item))
  }

  update (item: RuleGroup): Promise<void> {
    return store.dispatch(new UpdateRuleGroup(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteRuleGroup(guid))
  }
}
