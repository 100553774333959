import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import GeoJsonQueryRepositoryInterface from '@/services/MapEditor/domain/repository/GeoJsonQueryRepositoryInterface'
import store from '@/store'
import { GetLayerGeoJson } from '@/services/MapEditor/infrastructure/store/modules/GeoJson/action-types'

export default class GeoJsonQueryRepository extends AbstractQueryRepository implements GeoJsonQueryRepositoryInterface
{
    getLayerGeoJson(layerId: number, filter: string[][] | object | null): Promise<any>
    {
        return store.dispatch(new GetLayerGeoJson(layerId, filter));
    }
}