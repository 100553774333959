import QueryInterface from '@/core/application/query/QueryInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LogicTreeElementsTreeQuery implements QueryInterface {
  public elementType: string
  public logicId?: Nullable<number>

  constructor (elementType: string, logicId?: Nullable<number>) {
    this.elementType = elementType
    this.logicId = logicId
  }

  getClassName (): string {
    return 'LogicTreeElementsTreeQuery'
  }

  getElementType (): string {
    return this.elementType
  }

  getLogicId (): Nullable<number> {
    return this.logicId
  }
}
