import { Nullable } from '@/core/domain/type/types'

export default class LayerGroupUpdateCommand
{
    private guid: string;
    private name: string;
    private parentId: Nullable<number>;

    constructor(
        guid: string,
        name: string,
        parentId: Nullable<number>
    ) {
        this.guid = guid;
        this.name = name;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'LayerGroupUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}