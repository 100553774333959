import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetRuleByGuid, GetRuleFieldTypes } from '@/services/TaskEditor/infrastructure/store/modules/Rule/action-types'
import RuleQueryRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleQueryRepositoryInterface'

export default class RuleQueryRepository extends AbstractQueryRepository implements RuleQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetRuleByGuid(guid))
  }

  getRuleFieldTypes (): Promise<any> {
    return store.dispatch(new GetRuleFieldTypes())
  }
}
