import RoleProfileRightCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleProfileRightCommandRepositoryInterface'
import { RoleProfileRightDTO } from '@/services/AccessEditor/domain/model/Right/RoleProfileRight'
import store from '@/store'
import { UpdateRoleProfileRight } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class RoleProfileRightCommandRepository implements RoleProfileRightCommandRepositoryInterface {
  update (item: RoleProfileRightDTO): Promise<void> {
    return store.dispatch(new UpdateRoleProfileRight(item))
  }
}
