import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'
import ListenerCreateCommand from '@/services/LogicEditor/application/command/ListenerCreateCommand'
import ListenerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ListenerCommandRepositoryInterface'

export default class ListenerCreateHandler implements CommandHandlerInterface {
    private repository: ListenerCommandRepositoryInterface;

    constructor (repository: ListenerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ListenerCreateCommand): Promise<void> {
      let item: ListenerDTO = {
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        event_id: command.getEventId(),
        listener_cards: command.getListenerCards(),
        listener_commands: command.getListenerCommands(),
        logic_id: command.getLogicId(),
        parent_tree_element_id: command.getParentTreeElementId(),
        run_after_transaction: command.getRunAfterTransaction()
      }

      return this.repository.insert(item)
    }
}
