import ApprovalCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalCommandRepositoryInterface'
import store from '@/store'
import { CreateApproval, UpdateApproval, DeleteApproval } from '@/services/LogicEditor/infrastructure/store/modules/Approval/action-types'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'

export default class ApprovalCommandRepository implements ApprovalCommandRepositoryInterface {
  insert (item: ApprovalDTO): Promise<void> {
    return store.dispatch(new CreateApproval(item))
  }

  update (item: ApprovalDTO): Promise<void> {
    return store.dispatch(new UpdateApproval(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteApproval(guid))
  }
}
