import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetListenerByGuid } from '@/services/LogicEditor/infrastructure/store/modules/Listener/action-types'
import ListenerQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ListenerQueryRepositoryInterface'

export default class ListenerQueryRepository extends AbstractQueryRepository implements ListenerQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetListenerByGuid(guid))
  }
}
