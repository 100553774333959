import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ReleaseCommandRepositoryInterface from '@/services/ReleaseEditor/domain/repository/ReleaseCommandRepositoryInterface'
import ReleaseCreateCommand from '@/services/ReleaseEditor/application/command/ReleaseCreateCommand'
import { ReleaseDTO } from '@/services/ReleaseEditor/domain/model/Release/Release'

export default class ReleaseCreateHandler implements CommandHandlerInterface
{
    private repo: ReleaseCommandRepositoryInterface;

    constructor(repo: ReleaseCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ReleaseCreateCommand): Promise<void>
    {
        let item: ReleaseDTO = {
            name: command.getName(),
            description: command.getDescription(),
            version: command.getVersion(),
            type: command.getType(),
            start_date: command.getStartDate(),
            complete_date: command.getCompleteDate(),
            is_complete: command.getIsComplete()
        };
        return this.repo.insert(item);
    }
}