<template>
  <div class="dashboard_admin">
    <div class="menu-container">
      <div class="main-content">
        <div class="top-bar">
          <div class="left-section">
            <div class="icon_system_settings"></div>
            <div class="system-settings">Настройка системы</div>
          </div>
          <div class="right-section">
            <!--            <div class="search_panel">-->
            <!--              <el-input class="custom-el-input" type="text" placeholder="Поиск" :size="'mini'"/>-->
            <!--            </div>-->
            <div class="language-selector" :style="{ width: selectWidth + 'px' }">
              <el-select
                v-model="$i18n.locale"
                @change="onChangeLang"
                :size="'mini'"
                ref="dynamicSelect"
              >
                <el-option
                  v-for="(lang, i) in langs"
                  :key="`Lang${i}`"
                  :value="lang.value"
                  :label="lang.label"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="header">
            <div class="logo_header"></div>
          </div>
          <!-- <el-scrollbar class="container" wrap-style="overflow-x:hidden;"> -->
          <div class="container">
            <div class="menu_fw">
              <div class="icon icon_data"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({
                  name: $t('dashboard_admin.object_editor'),
                  componentType: 'ObjectEditor',
                })
              "
                >
                  {{ $t('dashboard_admin.object_editor') }}
                </div>
                <div class="menu_text">
                  {{ $t('dashboard_admin.object_editor_des') }}
                </div>
              </div>
            </div>
            <!-- columns -->
            <div class="menu">
              <div class="icon icon_interface"></div>
              <div>
                <div class="menu_header non_underline">{{ $t('dashboard_admin.menu_editor') }}</div>
                <div class="menu_text_columns">
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.menu_editor_tab'),
                      componentType: 'MenuEditor',
                    })
                  "
                >{{ $t('dashboard_admin.menu_editor_tab') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.menu_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.dash_editor'),
                      componentType: 'DashboardEditor',
                    })
                  "
                >{{ $t('dashboard_admin.dash_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.dash_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.css_editor'),
                      componentType: 'CssEditor',
                    })
                  "
                >{{ $t('dashboard_admin.css_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.css_editor_des') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- columns -->
            <div class="menu_fw">
              <div class="icon icon_acess"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({
                  name: $t('dashboard_admin.access_editor'),
                  componentType: 'AccessEditor_v2',
                })
              "
                >
                  {{ $t('dashboard_admin.access_editor') }}
                </div>
                <div class="menu_text">
                  {{ $t('dashboard_admin.access_editor_des') }}
                </div>
              </div>
            </div>
            <!-- columns -->
            <div class="menu">
              <div class="icon icon_engineering"></div>
              <div>
                <div class="menu_header non_underline">{{ $t('dashboard_admin.logic_editor_tab') }}</div>
                <div class="menu_text_columns">
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.logic_editor'),
                      componentType: 'LogicEditor_v2',
                    })
                  "
                >{{ $t('dashboard_admin.logic_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.logic_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.task_editor'),
                      componentType: 'TaskEditor',
                    })
                  "
                >{{ $t('dashboard_admin.task_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.task_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.notification_editor'),
                      componentType: 'NotificationEditor',
                    })
                  "
                >{{ $t('dashboard_admin.notification_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.notification_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.process_editor'),
                      componentType: 'ProcessEditor',
                    })
                  "
                >{{ $t('dashboard_admin.process_editor') }}</span
                >
                    <!--                <div class="menu_text">-->
                    <!--                  {{$t('dashboard_admin.process_editor_des')}}-->
                    <!--                </div>-->
                  </div>
                </div>
              </div>
            </div>
            <!-- columns -->
            <div class="menu">
              <div class="icon icon_source"></div>
              <div>
                <div class="menu_header non_underline">
                  {{ $t('dashboard_admin.bl_editor') }}
                </div>
                <div class="menu_text_columns">
                  <div class="menu_column_fw_source">
                <span class="column_header"
                      @click=
                        "addMainTab({
                        name: $t('dashboard_admin.bl_editor'),
                        componentType: 'BIEditor',
                      })">
                  {{ $t('dashboard_admin.bi_editor') }}
                </span>
                    <div class="menu_text">
                      {{ $t('dashboard_admin.bl_editor_des') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="menu_fw">
              <div class="icon icon_document"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({
                  name: $t('dashboard_admin.report_editor'),
                  componentType: 'ReportEditor',
                })
              "
                >
                  {{ $t('dashboard_admin.report_editor') }}
                </div>
                <div class="menu_text">
                  {{ $t('dashboard_admin.report_editor_des') }}
                </div>
              </div>
            </div>
            <div class="menu_fw">
              <div class="icon icon_map"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({ name: $t('dashboard_admin.map_editor'), componentType: 'MapEditor' })
              "
                >
                  {{ $t('dashboard_admin.map_editor') }}
                </div>
                <div class="menu_text">
                  {{ $t('dashboard_admin.map_editor_des') }}
                </div>
              </div>
            </div>
            <!-- columns -->
            <div class="menu">
              <div class="icon icon_integration"></div>
              <div>
                <div class="menu_header non_underline">{{ $t('dashboard_admin.integration') }}</div>
                <div class="menu_text_columns">
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.etl_editor'),
                      componentType: 'EtlEditor',
                    })
                  "
                >{{ $t('dashboard_admin.etl_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.etl_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.address_editor_gar'),
                      componentType: 'GarAddressService',
                    })
                  "
                >{{ $t('dashboard_admin.address_editor') }}</span
                >
                    <div class="menu_text">
                      {{ $t('dashboard_admin.address_editor_des') }}
                    </div>
                  </div>
                  <div class="menu_column">
                <span class="column_header"
                      @click="
                    addMainTab({
                      name: $t('dashboard_admin.api_service'),
                      componentType: 'ApiService',
                    })">
                  {{ $t('dashboard_admin.api_service') }}
                </span>
                    -
                    <div class="menu_text">
                      {{ $t('dashboard_admin.api_service_des') }}
                    </div>
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.data_editor'),
                      componentType: 'Databus',
                    })
                  "
                >{{ $t('dashboard_admin.data_editor') }}</span
                >
                    <!--                <div class="menu_text"></div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="menu_fw">
              <div class="icon icon_sync"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({
                  name: $t('dashboard_admin.sync_editor'),
                  componentType: 'SyncService',
                })
              "
                >
                  {{ $t('dashboard_admin.sync_editor') }}
                </div>
                <div class="menu_text">
                  {{ $t('dashboard_admin.sync_editor_des') }}
                </div>
              </div>
            </div>
            <div class="menu_fw">
              <div class="icon icon_logs"></div>
              <div>
                <div
                  class="menu_header"
                  @click="
                addMainTab({
                  name: $t('dashboard_admin.activity_editor'),
                  componentType: 'ActivityService',
                })
              "
                >
                  {{ $t('dashboard_admin.activity_editor') }}
                </div>
                <div class="menu_text">{{ $t('dashboard_admin.activity_editor_des') }}</div>
              </div>
            </div>
            <div class="menu_fw">
              <div class="icon icon_settings"></div>
              <div>
                <div class="menu_header">{{ $t('dashboard_admin.system_editor') }}</div>
                <div class="menu_text">{{ $t('dashboard_admin.system_editor_dev') }}</div>
              </div>
            </div>
            <div class="menu">
              <div class="icon icon_monitoring"></div>
              <div>
                <div class="menu_header non_underline">
                  {{ $t('dashboard_admin.monitoring') }}
                </div>
                <div class="menu_text_columns">
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.nginx'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/A4_qnTv7z/web?orgId=1&refresh=10s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.nginx') }}</span
                >
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.vm'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/e0ESDrZ7z/hardware?orgId=1&refresh=5s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.vm') }}</span
                >
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.postgresql'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/-QLZ8gB7z/postgresql?orgId=1&refresh=5s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.postgresql') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.kafka'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/HD8pcdM7k/kafka?orgId=1&refresh=1m`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.kafka') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.redis'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/DiEBNrW7z/redis?orgId=1&refresh=30s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.redis') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.airflow'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/s4WiSDMnk/airflow-cluster?orgId=1&refresh=5s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.airflow') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.dags'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/XCPzIDM7z/airflow-dag?orgId=1&refresh=5s`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.dags') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.log_service'),
                      componentType: 'LogService',
                    })
                  "
                >{{ $t('dashboard_admin.log_service') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center" v-show="$config.project === 'GjiMurmansk'">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.sys_acces'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/Zjv30F04z/hardware-copy?orgId=1`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.sys_acces') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="menu">
              <div class="icon icon_api"></div>
              <div style="width: 100%">
                <div class="menu_header non_underline">
                  {{ $t('dashboard_admin.tech_document') }}
                </div>
                <div class="menu_text_columns">
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.api'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/swagger/index.html`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.api') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="
                    addMainTab({
                      name: $t('dashboard_admin.plagin'),
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/plugins/index.xhtml`,
                      },
                    })
                  "
                >{{ $t('dashboard_admin.plugin') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                  <div class="menu_column_center">
                <span
                  class="column_header_center"
                  @click="addMainTab({
                    name: $t('dashboard_admin.service'),
                    componentType: 'release-editor',
                    payload: {
                      hideToolsContainer: true,
                      hideDeploymentsPanel: true,
                      hideCompletePanel: true
                      }
                  })"
                >{{ $t('dashboard_admin.service') }}</span>
                    <!--                <div class="menu_text"></div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </el-scrollbar> -->
        </div>
      </div>
      <!--      <div class="background-union"></div>-->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'dashboard_admin',
  inject: ['addMainTab'],
  data () {
    return {
      selectWidth: 110
    }
  },
  methods: {
    onChangeLang (event) {
      const selectedValue = this.$i18n.locale
      this.getSelectWidth(selectedValue)

      let lang = event
      import(`../../locale/${lang}.json`).then((locale) => {
        Vue.prototype.$locale = locale
      })
      if (selectedValue !== 'id') {
        this.selectWidth = 110
      }
    },
    getSelectWidth (selectedValue) {
      const textWidth = this.$refs.dynamicSelect.$refs.reference.$el.offsetWidth
      if (selectedValue === 'id') {
        this.selectWidth = textWidth + 60
      }
    }
  },
  mounted () {
    this.getSelectWidth(this.$i18n.locale)
  },
  computed: {
    langs () {
      return [
        {
          value: 'ru',
          label: this.$t(`dashboard_admin.ru`)
        }, {
          value: 'en',
          label: this.$t(`dashboard_admin.en`)
        }, {
          value: 'id',
          label: this.$t(`dashboard_admin.id`)
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Light';
  src: url('resources/fonts/Geologica-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SemiBold';
  src: url('resources/fonts/Geologica-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@import "./style/_dashboardAdminVariables";

.dashboard_admin {
  background-color: $main_background;
  // height: 100%;
  box-sizing: border-box;
  position: relative;

  .menu-container {
    padding-bottom: 16px; /* Настройте отступ внизу вместо margin-bottom */
    background-color: $background_containter; /* Примените цвет фона */
    background-size: cover; /* Растягиваем фоновое изображение на всю область */
    background-position: center; /* Выравниваем фоновое изображение по центру */
  }

  .background-union {
    position: fixed;
    bottom: 0;
    right: 12px;
    width: 96%;
    height: 37%;
    z-index: 1;
    background: url("./resources/icons/background_union.svg") no-repeat;
    background-size: cover;
  }

  .main-content {
    position: relative;
    z-index: 2;
  }

  .top-bar {
    background-color: #444FEF;
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .left-section {
    color: white;
    display: flex;
    align-items: center;
  }

  .right-section {
    display: flex;
    align-items: center;
    color: white;
  }

  .system-settings {
    font-family: 'SemiBold';
    font-size: 24px;
    line-height: 28px;
  }

  .language-selector {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .el-input__inner {
    background: #444FEF;
    color: #FFFFFF;
    font-family: 'Light';
    border-radius: 12px;
    height: 36px;
    font-size: 15px;
    line-height: 20px;
    border: none;
    //opacity: 75%;
  }

  .custom-el-select .el-input__inner {
    background: #444FEF;
    color: #FFFFFF;
    width: 110px;
  }

  .search_panel {
    margin-right: 10px;
    min-width: 300px;
  }

  .icon_system_settings {
    background: url("./resources/icons/system_settings.svg") no-repeat;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-left: 12px;
  }

  & .wrapper {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 1025px;
    margin: auto;
  }

  & .header {
    background-color: $background_header;
    height: 100px;
    display: flex;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    // position: sticky;
    // top: 0;
    background-size: cover;
  }

  //& .name_header {
  //  color: #000000;
  //  font-size: 36px;
  //  line-height: 36px;
  //  display: inline-block;
  //}
  & .logo_header {
    background: url("./resources/icons/logo_accent.svg") no-repeat;
    height: 50px;
    width: 250px;
  }

  & .container {
    & .menu {
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #0000001A;
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;
      //margin-left: 50px;
      //margin-right: 50px;
      padding: 25px 25px 25px 0;
    }

    & .menu_fw {
      border-radius: 15px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #0000001A;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      //margin-left: 50px;
      //margin-right: 50px;
      padding: 25px 25px 25px 0;
    }

    & .menu:last-child {
      margin-bottom: 16px;
    }

    & .menu_text_columns {
      display: flex;
      flex-wrap: wrap;

      & .menu_column {
        font-family: 'Light';
        flex-basis: 40%;
        border-radius: 17px;
        background-color: #ECECF1;
        padding: 24px;
        margin: 12px 12px 0 0;
        max-width: 232px;
        min-width: 130px;
      }

      & .menu_column_fw {
        font-family: 'Light';
        flex-basis: 100%;
        //padding-left: 15px;
        //margin-bottom: 20px;
        border-radius: 15px;
        background-color: #ECECF1;
        padding: 24px;
        margin: 12px 0 0 12px;
      }

      & .menu_column_fw_source {
        font-family: 'Light';
        flex-basis: 100%;
        border-radius: 15px;
        background-color: #ECECF1;
        padding: 24px;
        margin: 12px 12px 0 0;
      }

      & .menu_column_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: 'Light';
        flex-basis: 40%;
        border-radius: 17px;
        background-color: #ECECF1;
        padding: 24px;
        margin: 12px 12px 0 0;
        max-width: 232px;
        min-width: 130px;
      }

      & .column_header {
        font-family: 'SemiBold';
        color: $menu_column_color;
        font-size: $menu_column_fontSize;
        line-height: $menu_column_lineHeight;
        display: inline-block;
        position: relative;
        margin-bottom: 8px;
      }

      & .column_header_center {
        font-family: 'SemiBold';
        color: $menu_column_color;
        font-size: $menu_column_fontSize;
        line-height: $menu_column_lineHeight;
        display: inline-block;
        position: relative;
      }

      & .column_header_center:before {
        content: none;
        font-size: 33px;
        position: absolute;
        left: -14px;
        top: -8px;
      }

      & .column_header_center:hover {
        cursor: pointer;
        color: #FF7D20;
      }

      & .column_header:before {
        content: none;
        font-size: 33px;
        position: absolute;
        left: -14px;
        top: -8px;
      }

      & .column_header:hover {
        cursor: pointer;
        color: #FF7D20;
      }
    }

    & .icon {
      margin-left: 6px;
      height: 84px;
      margin-right: 34px;
      min-width: 84px;
      transform: translateY(-8px);

      &.icon_data {
        background: url("./resources/icons/data.svg") no-repeat;
      }

      &.icon_interface {
        background: url("./resources/icons/interface.svg") no-repeat;
      }

      &.icon_acess {
        background: url("./resources/icons/acess.svg") no-repeat;
      }

      &.icon_source {
        background: url("resources/icons/source.svg") no-repeat;
      }

      &.icon_document {
        background: url("resources/icons/document.svg") no-repeat;
      }

      &.icon_map {
        background: url("./resources/icons/map.svg") no-repeat;
      }

      &.icon_engineering {
        background: url("./resources/icons/engineering.svg") no-repeat;
      }

      &.icon_integration {
        background: url("resources/icons/integration.svg") no-repeat;
      }

      &.icon_sync {
        background: url("./resources/icons/sync.svg") no-repeat;
      }

      &.icon_logs {
        background: url("./resources/icons/logs.svg") no-repeat;
      }

      &.icon_settings {
        background: url("./resources/icons/settings.svg") no-repeat;
      }

      &.icon_monitoring {
        background: url("./resources/icons/monitoring.svg") no-repeat;
      }

      &.icon_api {
        background: url("./resources/icons/api.svg") no-repeat;
      }
    }
  }

  & .menu_header {
    display: inline-block;
    color: $menu_header_color;
    font-size: $menu_header_fontSize;
    line-height: $menu_header_lineHeight;
    margin-bottom: 8px;
    font-family: 'SemiBold';
  }

  & .menu_header:hover {
    cursor: pointer;
    color: #FF7D20;
  }

  & .menu_text {
    color: $menu_text_color;
    font-size: $menu_text_fontSize;
    line-height: $menu_text_lineHeight;
    font-family: 'Light';
  }

  & .non_underline {
    color: black !important;
    text-decoration: none !important;
    cursor: default !important;
  }

  & .locale-changer {
    width: 150px;
    position: absolute;
    top: 100px;
    right: 35px;
    color: $menu_column_color;
  }

  & .locale-changer_title {
    display: inline-block;
    margin-bottom: 8px;
  }
}
</style>
