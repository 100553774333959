
import Vue from 'vue'
import { faCog, faPlus, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare, faEye, faEyeSlash, faObjectGroup, faWindowRestore } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PopoverButton from '@/services/InterfaceEditor/infrastructure/components/Toolbar/PopoverButton.vue'
import WidgetList from '@/services/InterfaceEditor/infrastructure/components/Toolbar/WidgetList.vue'
import ElementSelector from '@/services/InterfaceEditor/infrastructure/components/Toolbar/ElementSelector.vue'

export default Vue.extend({
  name: 'Toolbar',

  components: {
    WidgetList,
    PopoverButton,
    FontAwesomeIcon,
    ElementSelector
  },
  inject: ['getEventBus'],

  props: {
    type: String,
    registryId: Number,
    activeBlock: Object,
    blocks: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => ({})
    },
    currentViewer: {
      type: Object
    },
    status: {
      type: Object,
      default: () => {
        return {
          title: undefined,
          subtitle: undefined
        }
      }
    },
    conditions: {
      type: Array,
      default: () => []
    },
    windows: {
      type: Array,
      default: () => []
    },
    blockConditions: {
      type: Boolean,
      default: false
    },
    blockWindows: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      preview: false,
      showingHidden: false,
      icons: {
        addBlock: faPlusSquare,
        settings: faCog,
        plus: faPlus,
        showHidden: faEye,
        hideHidden: faEyeSlash,
        designer: faObjectGroup,
        windowRestore: faWindowRestore,
        arrows: faExpandArrowsAlt
      },
      isShowElementSelectorPopover: false,
      activeElementName: ''
    }
  },

  computed: {
    fileOptions () {
      return [
        { name: this.$t(`interface_editor.save`), event: 'save' },
        { name: this.$t(`interface_editor.setting`), event: 'settings' },
        { name: this.$t(`interface_editor.import`), event: 'import' },
        { name: this.$t(`interface_editor.export`), event: 'export' },
        { name: this.$t(`interface_editor.card_source_settings`), event: 'card-source-settings' },
        { name: this.$t(`interface_editor.save_as_template`), event: 'openTemplateDialog' },
        { name: this.$t(`interface_editor.url_setup`), event: 'routing' },
        { name: this.$t(`interface_editor.manage_listeners`), event: 'openListenersDialog' }
      ]
    }
  },

  methods: {
  }
})
