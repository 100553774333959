import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetGroupByGuid } from '@/services/EtlEditor/infrastructure/store/modules/Group/action-types'
import GroupQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/GroupQueryRepositoryInterface'

export default class GroupQueryRepository extends AbstractQueryRepository implements GroupQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetGroupByGuid(guid))
  }
}
