import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class RelatedObjectUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private objectId: number
  private aliasFieldId: number
  private sqlStatements: object
  private filters: Array<object>
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    objectId: number,
    aliasFieldId: number,
    sqlStatements: object,
    filters: Array<object>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.objectId = objectId
    this.aliasFieldId = aliasFieldId
    this.sqlStatements = sqlStatements
    this.filters = filters
    this.alias = alias
  }

  getClassName (): string {
    return 'RelatedObjectUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getObjectId (): number {
    return this.objectId
  }

  getAliasFieldId (): number {
    return this.aliasFieldId
  }

  getSqlStatements (): object {
    return this.sqlStatements
  }

  getFilters (): Array<object> {
    return this.filters
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
