import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import ImportStructureCommand from '@/services/ApiService/application/command/ImportStructureCommand'

export default class ImportStructureHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ImportStructureCommand): Promise<void> {
    return this.repository.importStructure(command.getPayload())
  }
}
