import CommandHandlerInterface from "@/core/application/command/CommandHandlerInterface";
import GarCommandRepositoryInterface from "@/services/GarAddressService/domain/repository/GarCommandRepository";

export default class RegionUpdateHandler implements CommandHandlerInterface {
  private repository: GarCommandRepositoryInterface;

  constructor (repository: GarCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (): Promise<void> {
    return this.repository.update()
  }
}
