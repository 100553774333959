import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetApprovalStagesByApprovalId, GetApprovalByGuid } from '@/services/LogicEditor/infrastructure/store/modules/Approval/action-types'
import ApprovalQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalQueryRepositoryInterface'

export default class ApprovalQueryRepository extends AbstractQueryRepository implements ApprovalQueryRepositoryInterface {
  getApprovalStagesByApprovalId (approvalId: number, payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetApprovalStagesByApprovalId(approvalId, payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetApprovalByGuid(guid))
  }
}
