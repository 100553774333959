import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class GroupUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.alias = alias
  }

  getClassName (): string {
    return 'GroupUpdateCommand'
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
