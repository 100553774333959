

export default {
  name: 'logPanel',
  data () {
    return {
      loading: false,
      logsPageSize: 0,
      logsCount: 0,
      allLogsCount: 0,
      logs: [],
      log: null
    }
  },
  inject: ['getQueryBus', 'getCommandBus'],
  methods: {
    async loadLogs() {
      let data = await this.$http.get(`${this.$config.api}/databusservice/utilities/logs`);
      data = data.data;
      this.logs = data;
      this.logsPageSize = data.length;
      this.logsCount = data.length;
    },
    async downloadLog(filename) {
      let response = await this.$http.get(`${this.$config.api}/databusservice/utilities/logs/${filename}`);
      if (response.data.status == 'ok') {
        const url = window.URL.createObjectURL(new Blob([response.data.content]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
      else {
        this.$message({
          message: this.$locale.databus.log_panel.log + " " + filename + " " + this.$locale.databus.log_panel.not_found,
          type: 'warning'
        });
      }
    }
  },
  mounted () {
    this.loadLogs();
  }
}
