export const operatorsToTypes = {
  string_field: [
    'eq', 'neq', 'in', 'not_in', 'is_null', 'is_not_null', 'like', 'not_like'
  ],
  text_field: [
    'eq', 'neq', 'is_null', 'is_not_null', 'like', 'not_like'
  ],
  integer_field: [
    'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
  ],
  float_field: [
    'eq', 'neq', 'in', 'not_in', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
  ],
  date_field: [
    'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
  ],
  time_field: [
    'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
  ],
  datetime_field: [
    'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'is_null', 'is_not_null'
  ],
  boolean_field: [
    'eq', 'neq', 'is_null', 'is_not_null'
  ],
  formula_field: [
    'eq', 'neq', 'in', 'not_in', 'is_null', 'is_not_null', 'like', 'not_like', 'gt', 'lt', 'gte'
  ]
}
