<template>
  <div class="flex flex-col h-full">
    {{ 123 | variableValue('integer') }}
    <el-table
      v-loading="loading"
      highlight-current-row
      @current-change="selectRow"
      height="100%"
      class="registry"
      :row-style="{
        'cursor': 'pointer'
      }"
      :data="data">
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :formatter="column.formatter"
      >
        <template slot-scope="scope">
          <template v-if="['create_time_'].includes(column.prop) && scope.row[column.prop]">
            <i class="el-icon-time"></i>
            <span style="margin-left: 5px">{{ $moment(scope.row[column.prop]).format('DD.MM.YYYY HH:mm:ss') }}</span>
          </template>
          <template v-else-if="column.prop === 'value'">
            <span>{{ scope.row | variableValue }}</span>
          </template>
          <template v-else>
            <span>{{ scope.row[column.prop] }}</span>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="meta.page"
      :page-size="meta.pageSize"
      layout="total, prev, pager, next"
      :total="meta.total">
    </el-pagination>
  </div>
</template>
<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { MonitoringAPI } from '@/services/BpmnGateway/infrastructure/api/MonitoringAPI'
import VariablesMixin from '@/services/BpmnGateway/infrastructure/mixins/VariablesMixin.js'

export default {
  name: 'VariablesTable',
  mixins: [VariablesMixin],
  data () {
    return {
      loading: true,
      data: [],
      meta: {
        page: 1,
        total: 0,
        pageSize: 50
      },
      columns: [
        {
          label: 'ID',
          prop: 'id_',
          width: 100
        },
        {
          label: 'Время создание',
          prop: 'create_time_',
          width: 180
        },
        {
          label: 'Тип',
          prop: 'var_type_',
          width: 150
        },
        {
          label: 'Название',
          prop: 'name_',
          width: 300
        },
        {
          label: 'Значение',
          prop: 'value',
          width: 180
        }
      ]
    }
  },
  props: {
    processId: String
  },
  watch: {
    'meta.page': function () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    selectRow (row) {
      this.$emit('select', row)
    },
    async loadData () {
      try {
        this.loading = true
        await Promise.all([
          APIClient.shared.request(new MonitoringAPI.GetProcessVariables(this.processId, this.meta.pageSize, (this.meta.page - 1) * this.meta.pageSize))
            .then((data) => {
              this.data = data
            }),
          APIClient.shared.request(new MonitoringAPI.GetProcessVariablesCount(this.processId)).then((data) => {
            this.meta.total = data[0]?.count || 0
          })
        ])
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
