import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LoaderDTO } from '@/services/EtlEditor/domain/model/Loader'
import LoaderUpdateCommand from '@/services/EtlEditor/application/command/LoaderUpdateCommand'
import LoaderCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderCommandRepositoryInterface'

export default class LoaderUpdateHandler implements CommandHandlerInterface {
    private repository: LoaderCommandRepositoryInterface;

    constructor (repository: LoaderCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LoaderUpdateCommand): Promise<void> {
      let item: LoaderDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        loader_type_id: command.getLoaderTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties()
      }

      return this.repository.update(item)
    }
}
