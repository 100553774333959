import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CssClassUpdateCommand from '@/services/CssEditor/application/command/CssClassUpdateCommand'
import CssClassCommandRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassCommandRepositoryInterface'

export default class CssClassUpdateCommandHandler implements CommandHandlerInterface {
  private repository: CssClassCommandRepositoryInterface;

  constructor (repository: CssClassCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CssClassUpdateCommand): Promise<void> {
    return this.repository.update(command.getDto())
  }
}
