export type TApiStateValueType = 'constant' | 'parameter' | 'field';

export type ParameterTypes = 'current_table_field' | 'external_table_field' | 'current_user' | 'current_datetime' | 'constant' | 'parameter' | 'user_table' | 'sql' | string

export type ParameterValue = {
  field_id?: number;
  registry_id?: number;
  xref_field_id?: number;
  constant?: string|null;
  parameter?: string|null;
  sql?: string|null;
  is_array?: boolean;
} | null;

export interface IParameter {
  name: string;
  type: ParameterTypes;
  value: ParameterValue;
  is_correlation_key: boolean;
}

export interface IKeyValue {
  key: string;
  value: string;
  type?: string;
}

export interface IApiAuthOAuth2 {
  header_prefix: string;
  token_name: string;
  grant_type: string;
  callback_url: string;
  auth_url: string;
  access_token_url: string;
  client_id: string;
  client_secret: string;
  scope: string;
  state: string;
  client_authorization: string;
  code_challenge_method: string;
  code_verifier: string;
  username: string;
  password: string;
  refresh_token_url: string;
  auth_request: string;
  token_request: string;
  refresh_request: string;
}

export interface IApiAuthBearerToken {
  token: string;
}

export interface IApiAuthKey {
  key: string;
  value: string;
  add_to: 'header' | 'query_params';
}

export interface IApiAuth {
  type: 'oauth2' | 'api_key' | 'bearer_token' | null;
  properties: IApiAuthBearerToken | IApiAuthKey | IApiAuthOAuth2 | null;
}

export interface IApiBodyRaw {
  content: string;
}

export interface IApiBodyTemplate {
  template_id: string | null;
  format: 'xml' | 'json';
  template_source_type: 'report_editor' | 'file_field';
  template_file_field_id: number | null;
  // и прочие параметры для интеграции с редактором отчётов
}

export interface IApiBodyFormData {
  fields: IKeyValue[];
}

export interface IApiBody {
  type: 'form_data' | 'raw' | 'template' | null;
  properties: IApiBodyFormData | IApiBodyRaw | IApiBodyTemplate | null;
}

export interface IApiResponseTask {
  task_id: number | null;
  extension: string;
}

export interface IApiResponse {
  type: 'task' | null;
  properties: IApiResponseTask | null;
}

export interface IRestApiEventProperties {
  api_method: string;
  api_url: string;
  api_params: IKeyValue[];
  api_headers: IKeyValue[];
  api_auth: IApiAuth;
  api_body: IApiBody;
  api_response: IApiResponse;
  api_variables: IParameter[];
}

export const createOAuth2 = function (): IApiAuthOAuth2 {
  return {
    header_prefix: '',
    token_name: '',
    grant_type: '',
    callback_url: '',
    auth_url: '',
    access_token_url: '',
    client_id: '',
    client_secret: '',
    scope: '',
    state: '',
    client_authorization: '',
    code_challenge_method: '',
    code_verifier: '',
    username: '',
    password: '',
    refresh_token_url: '',
    auth_request: '',
    token_request: '',
    refresh_request: ''
  }
}

export const createBearerToken = function (): IApiAuthBearerToken {
  return {
    token: ''
  }
}

export const createApiKey = function (): IApiAuthKey {
  return {
    key: '',
    value: '',
    add_to: 'header'
  }
}

export const createTask = function (): IApiResponseTask {
  return {
    task_id: null,
    extension: 'json'
  }
}

export const createFormData = function (): IApiBodyFormData {
  return {
    fields: []
  }
}

export const createTemplate = function (): IApiBodyTemplate {
  return {
    template_id: null,
    format: 'json',
    template_source_type: 'report_editor',
    template_file_field_id: null
  }
}

export const createRaw = function (): IApiBodyRaw {
  return {
    content: ''
  }
}
