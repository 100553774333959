import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  GetCommands,
  GetCommandByGuid,
  GetCommandEventTypeProperties,
  GetCommandEventTypes
} from '@/services/LogicEditor/infrastructure/store/modules/Command/action-types'
import CommandQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandQueryRepositoryInterface'
import { CommandEventTypeDTO, CommandEventTypePropertyDTO } from '@/services/LogicEditor/domain/model/Command'

export default class CommandQueryRepository extends AbstractQueryRepository implements CommandQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetCommands(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetCommandByGuid(guid))
  }

  getEventTypes (): Promise<CommandEventTypeDTO> {
    return store.dispatch(new GetCommandEventTypes())
  }

  getEventTypeProperties (type: string): Promise<CommandEventTypePropertyDTO> {
    return store.dispatch(new GetCommandEventTypeProperties(type))
  }
}
