<template>
  <div class="component-preview is-button">
    <h4>Button</h4>

    <el-row>
      <el-button>Default</el-button>
      <el-button type="primary">Primary</el-button>
      <el-button type="success">Success</el-button>
      <el-button type="info">Info</el-button>
      <el-button type="warning">Warning</el-button>
      <el-button type="danger">Danger</el-button>
    </el-row>

    <el-row>
      <el-button plain>Plain</el-button>
      <el-button type="primary" plain>Primary</el-button>
      <el-button type="success" plain>Success</el-button>
      <el-button type="info" plain>Info</el-button>
      <el-button type="warning" plain>Warning</el-button>
      <el-button type="danger" plain>Danger</el-button>
    </el-row>

    <el-row>
      <el-button round>Round</el-button>
      <el-button type="primary" round>Primary</el-button>
      <el-button type="success" round>Success</el-button>
      <el-button type="info" round>Info</el-button>
      <el-button type="warning" round>Warning</el-button>
      <el-button type="danger" round>Danger</el-button>
    </el-row>

    <el-row>
      <el-button icon="el-icon-search" circle></el-button>
      <el-button type="primary" icon="el-icon-edit" circle></el-button>
      <el-button type="success" icon="el-icon-check" circle></el-button>
      <el-button type="info" icon="el-icon-message" circle></el-button>
      <el-button type="warning" icon="el-icon-star-off" circle></el-button>
      <el-button type="danger" icon="el-icon-delete" circle></el-button>
    </el-row>

    <el-row>
      <el-button disabled>Default (Disabled)</el-button>
      <el-button type="primary" disabled>Primary (Disabled)</el-button>
      <el-button type="success" disabled>Success (Disabled)</el-button>
      <el-button type="info" disabled>Info</el-button>
      <el-button type="warning" disabled>Warning (Disabled)</el-button>
      <el-button type="danger" disabled>Danger (Disabled)</el-button>
    </el-row>

    <el-row>
      <el-button>Default</el-button>
      <el-button size="medium">Medium</el-button>
      <el-button size="small">Small</el-button>
      <el-button size="mini">Mini</el-button>
    </el-row>

    <el-row>
      <el-button type="text">Text Button</el-button>
      <el-button type="text" disabled>Text Button (Disabled)</el-button>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonPreview'
})
</script>

<style lang="css">
.component-preview.is-button .el-row {
  margin-bottom: 20px;
}
</style>
