import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import CoordinateSystemQueryRepositoryInterface from '@/services/MapEditor/domain/repository/CoordinateSystemQueryRepositoryInterface'
import store from '@/store'
import { GetCoordinateSystems, CountCoordinateSystems, GetCoordinateSystemBySrid, GetCoordinateSystemsByUserId } from '@/services/MapEditor/infrastructure/store/modules/CoordinateSystem/action-types'
import { GetProjectCoordinateSystems } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class CoordinateSystemQueryRepository extends AbstractQueryRepository implements CoordinateSystemQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetCoordinateSystems(payload));
    }

    getCount(payload: string[][]): Promise<any>
    {
        return store.dispatch(new CountCoordinateSystems(payload));
    }

    getByJoinTable(projectGuid: string, payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetProjectCoordinateSystems(projectGuid, payload));
    }

    getBySrid(srid: number): Promise<any>
    {
        return store.dispatch(new GetCoordinateSystemBySrid(srid));
    }

    getByUserId(userId: number): Promise<any>
    {
        return store.dispatch(new GetCoordinateSystemsByUserId(userId));
    }
}