import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  GetThemes,
  GetThemeByGuid
} from '@/services/CssEditor/infrastructure/store/modules/Theme/action-types'
import ThemeQueryRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeQueryRepositoryInterface'

export default class ThemeQueryRepository extends AbstractQueryRepository implements ThemeQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetThemes(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetThemeByGuid(guid))
  }
}
