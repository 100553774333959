import TaskCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskCommandRepositoryInterface'
import store from '@/store'
import { CreateTask, UpdateTask, DeleteTask } from '@/services/EtlEditor/infrastructure/store/modules/Task/action-types'
import { TaskDTO } from '@/services/EtlEditor/domain/model/Task'

export default class TaskCommandRepository implements TaskCommandRepositoryInterface {
  insert (item: TaskDTO): Promise<void> {
    return store.dispatch(new CreateTask(item))
  }

  update (item: TaskDTO): Promise<void> {
    return store.dispatch(new UpdateTask(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteTask(guid))
  }
}
