import QueryInterface from './QueryInterface'
import { Nullable } from '@/core/domain/type/types'

export default abstract class AbstractByParentGuidEntitiesQuery implements QueryInterface
{
    private parentGuid: string;

    private payload: string[][] | object | null;

    constructor(parentGuid: string, payload: string[][] | object | null)
    {
        this.parentGuid = parentGuid;
        this.payload = payload;
    }

    getParentGuid(): string
    {
        return this.parentGuid;
    }

    getPayload(): string[][] | object | null
    {
        return this.payload;
    }

    abstract getClassName(): string;
}