import ProcedureCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ProcedureCommandRepositoryInterface'
import store from '@/store'
import { CreateProcedure, UpdateProcedure, DeleteProcedure } from '@/services/LogicEditor/infrastructure/store/modules/Procedure/action-types'
import { ProcedureDTO } from '@/services/LogicEditor/domain/model/Procedure'

export default class ProcedureCommandRepository implements ProcedureCommandRepositoryInterface {
  insert (item: ProcedureDTO): Promise<void> {
    return store.dispatch(new CreateProcedure(item))
  }

  update (item: ProcedureDTO): Promise<void> {
    return store.dispatch(new UpdateProcedure(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteProcedure(guid))
  }
}
