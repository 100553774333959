<template>
  <div class="component-preview">
    <h4>Input</h4>

    <div>
      <el-input v-model="input" placeholder="Please input"></el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input
        placeholder="Pick a date"
        v-model="input"
      >
        <i slot="suffix" class="el-input__icon el-icon-date"></i>
      </el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input
        placeholder="Type something"
        v-model="input"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input placeholder="Please input" v-model="input">
        <template slot="prepend">
          <el-select v-model="model" placeholder="Protocol" clearable filterable style="width: 150px">
            <el-option
              v-for="item in ['http', 'https']"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </template>
      </el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input placeholder="Please input" v-model="input" size="medium">
        <template slot="append">
          <span class="el-icon-search"></span>
        </template>
      </el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input placeholder="Please input" v-model="input" size="small" disabled>
        <template slot="prepend">
          <span>www.</span>
        </template>
      </el-input>
    </div>

    <div style="margin-top: 20px">
      <el-input placeholder="Please input" v-model="input" size="mini" disabled>
        <template slot="append">
          <el-button type="primary" icon="el-icon-search"></el-button>
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InputPreview',

  data () {
    return {
      input: '',
      model: null
    }
  }
})
</script>
