import { render, staticRenderFns } from "./ElementForm.vue?vue&type=template&id=57da6068&scoped=true&"
import script from "./ElementForm.vue?vue&type=script&lang=js&"
export * from "./ElementForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57da6068",
  null
  
)

export default component.exports