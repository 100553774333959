import { Nullable } from '@/core/domain/type/types'

export default class ProjectCreateCommand
{
    private name: string;
    private description: Nullable<string>;

    constructor(name: string, description: Nullable<string>)
    {
        this.name = name;
        this.description = description;
    }

    getClassName(): string
    {
        return 'ProjectCreateCommand';
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): string
    {
        return this.description;
    }
}