import { Nullable } from '@/core/domain/type/types'

export default class ProjectTreeElementProjectPutCommand
{
    private projectId: number;
    private parentId: Nullable<number>;

    constructor(projectId: number, parentId: Nullable<number>)
    {
        this.projectId = projectId;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'ProjectTreeElementProjectPutCommand';
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}