import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import BindServiceCommandRepositoryInterface from '@/services/ReleaseEditor/domain/repository/BindServiceCommandRepositoryInterface'
import BindServiceCreateCommand from '@/services/ReleaseEditor/application/command/BindServiceCreateCommand'
import { BindServiceDTO } from '@/services/ReleaseEditor/domain/model/BindService/BindService'

export default class BindServiceCreateHandler implements CommandHandlerInterface
{
    private repo: BindServiceCommandRepositoryInterface;

    constructor(repo: BindServiceCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: BindServiceCreateCommand): Promise<void>
    {
        let item: BindServiceDTO = {
            release_guid: command.getReleaseGuid(),
            release_id: command.getReleaseId(),
            service_id: command.getServiceId(),
            service_version: command.getServiceVersion(),
            service_release_date: command.getServiceReleaseDate()
        };
        return this.repo.insert(item);
    }
}