import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ProjectQueryRepositoryInterface from '../../../domain/repository/ProjectQueryRepositoryInterface'
import ProjectsCountQuery from '../../query/ProjectsCountQuery'

export default class ProjectsCountHandler implements QueryHandlerInterface
{
    private repo: ProjectQueryRepositoryInterface;

    constructor(repo: ProjectQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: ProjectsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}