<template>
  <div class="window" :style="{zIndex: zIndex}">
    <div class="overlay">
      <div class="content" :style="{width: this.onFullScreen.width, height: this.onFullScreen.height}">
        <div class="header">Мастер экспорта</div>
        <el-button class="continue_button" @click="onFullScreenChange($event)" size="small">
          <font-awesome-icon :icon="onFullScreen.state ? 'window-minimize' : 'window-maximize'"></font-awesome-icon>
        </el-button>
        <el-button class="continue_button" @click="closeWindow()" type="danger" icon="el-icon-error" size="small">Закрыть</el-button>
        <el-button class="continue_button" @click="activateStep('next')" v-show="currentTab !== '3'" type="success" icon="el-icon-right" size="small">Вперед</el-button>
        <el-button class="continue_button" @click="activateStep('back')" v-show="currentTab !== '1'" type="success" icon="el-icon-back" size="small">Назад</el-button>
        <el-popover v-if="currentTab === '1'" trigger="click">
          <el-form>
            <el-form-item>
              <span class="property_label">Действия</span>
              <editor-select
                ref="actions_select"
                :value="filterOptions.actions.value"
                @change="$set(filterOptions.actions ,'value', $event)"
                :options="{
                multiple: true,
                clearable: true,
                options: filterOptions.actions.data
              }"
              ></editor-select>
            </el-form-item>
            <el-form-item>
              <span class="property_label">Сервисы</span>
              <editor-select
                ref="schema_select"
                :value="filterOptions.schema.value"
                @change="$set(filterOptions.schema ,'value', $event); getFilters($event)"
                :options="{
                multiple: true,
                clearable: true,
                options: filterOptions.schema.data
              }"
              ></editor-select>
            </el-form-item>
            <el-form-item>
              <span class="property_label">Таблицы</span>
              <editor-select
                ref="tables_select"
                :value="filterOptions.tables.value"
                @change="$set(filterOptions.tables ,'value', $event)"
                :options="{
                multiple: true,
                clearable: true,
                options: filterOptions.tables.data
              }"
              ></editor-select>
            </el-form-item>
            <el-button class="filter_button_apply" @click="filterSnapshots">Применить</el-button>
            <el-button class="filter_button_cancel" @click="clearFilters">
              <font-awesome-icon icon="trash"></font-awesome-icon>
            </el-button>
          </el-form>
          <el-button class="filter_button" slot="reference" @click="1" size="small">
            <font-awesome-icon icon="filter"></font-awesome-icon>
          </el-button>
        </el-popover>
        <el-button class="continue_button" @click="loadSnapshots" size="small" icon="el-icon-refresh-left"></el-button>
        <el-tabs style="height: 100%" v-model="currentTab">
              <el-tab-pane
                :label="$locale.syncservice.export_master.transactions_journal" style="height: 100%"
                name="1"
                :disabled="true"
              >
                <el-row class="main-table snapshot-table" height="100%">
                  <el-col style="height: 100%" v-bind:class="{ 'mini': snapshot !== null }">
                    <el-table
                      :indent="0"
                      class="registry custom_scrollbar"
                      height="100%"
                      v-loading="loading"
                      :data="snapshots"
                      border
                      ref="snapshots_table"
                      row-key="guid"
                      current-row-key="guid"
                      highlight-current-row
                    >
                      <el-table-column
                        type="selection"
                        width="55">
                      </el-table-column>
                      <el-table-column
                        :label="$locale.syncservice.snapshot_panel.transaction"
                      >
                        <el-table-column
                          :label="$locale.main.fields.date"
                          width="162"
                        >
                          <template slot-scope="scope">
                            {{ (new Date(parseInt(scope.row.ts_ms.$date.$numberLong))) | moment("DD.MM.Y H:mm:ss")}}
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="offset"
                          :label="$locale.activity_service.activity_panel.offset"
                          width="120"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="tx_id"
                          :label="$locale.syncservice.snapshot_panel.tx_id"
                          width="120"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="op"
                          :label="$locale.syncservice.snapshot_panel.op"
                          width="140"
                        >
                          <template slot-scope="scope">
                            <el-tag
                              :type="$locale.syncservice.snapshot_panel.op_tags[scope.row.op]"
                            >
                              <span>{{ $locale.syncservice.snapshot_panel[scope.row.op] }}</span>
                            </el-tag>
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column
                        :label="$locale.syncservice.snapshot_panel.record"
                      >
                        <el-table-column
                          prop="schema"
                          :label="$locale.syncservice.snapshot_panel.schema"
                          width="200"
                        >
                          <template slot-scope="scope">
                            <el-tag type="info">
                              <span>{{ $locale.main.system[scope.row.schema] }}</span>
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="table"
                          :label="$locale.syncservice.snapshot_panel.table"
                          width="120"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="dump"
                          :label="$locale.syncservice.snapshot_panel.data"
                        >
                          <template slot-scope="scope">
                            <div v-if="scope.row.after" v-html="parseDump(scope.row.after, scope.row.schema, scope.row.table)"></div>
                            <div v-else v-html="parseDump(scope.row.before, scope.row.schema, scope.row.table)"></div>
                          </template>
                        </el-table-column>
                      </el-table-column>
                    </el-table>
                    <el-footer style="height: 32px">
                      <el-pagination
                        class="snapshot-pagination"
                        :page-size="snapshotsPageSize"
                        :layout="'total, prev, pager, next'"
                        :total="snapshotsCount"
                        @current-change="handleSnapshotsPageChange"
                      ></el-pagination>
                    </el-footer>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane
                :label="$locale.syncservice.export_master.files_export" style="height: 100%"
                name="2"
                :disabled="true"
              >
                <el-scrollbar style="height: calc(57vh - 5px);">
                  <el-tree
                    ref="tree"
                    :data="exportFiles"
                    :props="treeProps"
                    node-key="id"
                    show-checkbox
                    :expand-on-click-node="false">
                  </el-tree>
                </el-scrollbar>
              </el-tab-pane>
              <el-tab-pane
                :label="$locale.syncservice.export_master.approve_operation" style="height: 100%"
                name="3"
                :disabled="true"
              >
                <div class="block" style="margin: 5px 0 0 5px">
                  Колличество выбранных транзакций: {{$refs.snapshots_table ? $refs.snapshots_table.selection.length : 0}}
                </div>
                <div class="block" style="margin: 5px 0 0 5px">
                  Колличество выбранных файлов: {{$refs.tree ? $refs.tree.getCheckedKeys(true).length : 0}}
                </div>
                <el-button
                  class="unload_data_button"
                  @click="unloadData"
                  v-show="files > 0 || tx_ids > 0"
                >
                  {{$locale.syncservice.export_master.unload_data}}
                </el-button>
              </el-tab-pane>
            </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWindowMinimize, faFilter, faTrash, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'

import { PopupManager } from 'element-ui/src/utils/popup'
import SnapshotsQuery from '@/services/SyncService/application/query/SnapshotsQuery'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import SnapshotsFilterQuery from '@/services/SyncService/application/query/SnapshotsFilterQuery'
import SnapshotsSchemaFilterValuesQuery from '@/services/SyncService/application/query/SnapshotsSchemaFilterValuesQuery'
import SnapshotsTablesFilterValuesQuery from '@/services/SyncService/application/query/SnapshotsTablesFilterValuesQuery'
library.add(faWindowMinimize, faFilter, faTrash, faWindowMaximize)

export default {
  name: 'ExportWindow',
  inject: ['getEventBus', 'getQueryBus'],
  components: {
    FontAwesomeIcon,
    EditorSelect
  },
  data () {
    return {
      isClickedOnContent: false,
      zIndex: 0,
      snapshotsPageSize: 0,
      defaultSnapshotsPageSize: 100,
      snapshotsCurrentPage: 0,
      snapshotsPageLimit: 100,
      snapshotsCount: 0,
      allSnapshotsCount: 0,
      snapshots: [],
      snapshot: null,
      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },
      exportFiles: [],
      loading: false,
      currentTab: '1',
      disableTabs: {
        second: true,
        third: true
      },
      files: 0,
      tx_ids: 0,
      onFullScreen: {
        state: false,
        width: '70%',
        height: '70%'
      },
      filterOptions: {
        actions: {
          data: [
            { id: 'c', name: 'Добавление' },
            { id: 'u', name: 'Редактирование' },
            { id: 'd', name: 'Удаление' }
          ],
          value: []
        },
        schema: {
          data: [],
          value: []
        },
        tables: {
          data: [],
          value: []
        }
      }
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
    this.loadSnapshots()
    this.loadExportFiles()
    this.getFilters()
  },
  methods: {
    loadSnapshots () {
      this.loading = true
      this.loadAllSnapshots()
    },
    async loadAllSnapshots () {
      await this.getQueryBus().execute(
        new SnapshotsQuery({
          limit: this.snapshotsPageLimit,
          offset: this.snapshotsCurrentPage
        })
      ).then(data => {
        this.snapshotsCount = data.count
        this.snapshotsPageSize = this.snapshotsPageLimit
        this.snapshots = data.data
        this.loading = false
      })
    },
    handleSnapshotsPageChange (val) {
      val--
      this.snapshotsCurrentPage = (val * this.snapshotsPageLimit)
      if (this.filterOptions.tables.value.length > 0 || this.filterOptions.schema.value.length > 0) {
        this.filterSnapshots()
      } else {
        this.loadSnapshots()
      }
    },
    closeWindow () {
      this.$emit('update:active', false)
    },
    parseDump (record, schema, table) {
      let res = []
      if (typeof record.id !== 'undefined') {
        res.push('<strong>id</strong>: ' + record.id)
      }
      if (typeof record.name !== 'undefined') {
        res.push('<strong>' + this.$locale.main.fields.name + '</strong>: ' + record.name)
      }

      if (schema === 'report_editor') {
        res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.report_editor.types[record.report_type])
      }

      if (schema === 'menu_editor') {
        if (table === 'menu') {
          res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.menu_editor.label[record.menu_type_id])

          for (const [key, value] of Object.entries(record)) {
            if (key !== 'name' && this.$locale.menu_editor.label.hasOwnProperty(key)) {
              res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
            }
          }
          let props = JSON.parse(JSON.stringify(record.properties))
          if (props) {
            for (const [key, value] of Object.entries(props)) {
              if (this.$locale.menu_editor.label.hasOwnProperty(key)) {
                res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
              }
            }
          }
        }
      }

      if (schema === 'object_editor') {
        if (table === 'entities') {
          let props = record.properties
          if (props != null) {
            props.forEach(prop => {
              if (this.$locale.object_editor.settings.properties.hasOwnProperty(prop.id)) {
                let val = '<strong>' + this.$locale.object_editor.settings.properties[prop.id] + '</strong>: '
                if (prop.value === true) {
                  val += this.$locale.main.fields.true
                } else if (prop.value === false) {
                  val += this.$locale.main.fields.false
                } else {
                  val += (prop.value ?? '')
                }

                res.push(val)
              }
            })
          }
        }
      }

      return res.join(', ')
    },
    async loadExportFiles () {
      let response = await this.$http({
        url: this.$config.api + '/syncservice/export_files',
        method: 'GET',
        hideNotification: true
      })

      if (response.data.length > 0) {
        this.exportFiles = this.buildTree(response.data)
      }
    },
    buildTree (elements, guid = null) {
      let data = []
      elements.forEach((el, index) => {
        if (el.parent_guid === guid) {
          let children = this.buildTree(elements, el.guid)
          data.push({
            id: el.guid,
            type: el.type,
            name: el.name,
            originIndex: index,
            children: this.buildTree(elements, el.guid),
            is_leaf: !children.length
          })
        }
      })
      return data
    },
    async unloadData () {
      let files = this.$refs.tree.getCheckedKeys(true)
      let txIds = this.$refs.snapshots_table.selection.map((item) => {
        return item.tx_id
      })

      this.closeWindow(false)

      let response = await this.$http({
        url: this.$config.api + '/syncservice/snapshots/export',
        method: 'POST',
        data: { files: files, tx_ids: txIds },
        hideNotification: true
      })

      if (response.status === 200) {
        /* this.$http({
          url: this.$config.api + `/files/${response.data}`,
          method: 'GET',
          hideNotification: true
        }) */
        this.$message({
          message: response.data,
          type: 'success'
        })
      }
    },
    activateStep (action) {
      if (action === 'next') {
        this.currentTab = (parseInt(this.currentTab) + 1).toString()
      } else {
        this.currentTab = (this.currentTab - 1).toString()
      }
      this.files = this.$refs.tree.getCheckedKeys(true).length
      this.tx_ids = this.$refs.snapshots_table.selection.length
    },
    onFullScreenChange (event) {
      this.onFullScreen.state = !this.onFullScreen.state

      if (this.onFullScreen.state) {
        this.onFullScreen.width = '100%'
        this.onFullScreen.height = '100%'
      } else {
        this.onFullScreen.width = '70%'
        this.onFullScreen.height = '70%'
      }
    },
    async getFilters (schema) {
      if (schema) {
        let payload = {
          table_schema: schema
        }
        await this.getQueryBus().execute(
          new SnapshotsTablesFilterValuesQuery(payload)
        ).then(data => {
          this.filterOptions.tables.data = data.map((item) => {
            return { id: item.table_name, name: item.table_name }
          })
        })
      } else {
        await this.getQueryBus().execute(
          new SnapshotsSchemaFilterValuesQuery()
        ).then(data => {
          this.filterOptions.schema.data = data.map((item) => {
            return { id: item.schema_name, name: item.schema_name }
          })
        })
      }
    },
    clearFilters () {
      this.$refs.actions_select.clearValue()
      this.$refs.schema_select.clearValue()
      this.$refs.tables_select.clearValue()
      this.$set(this.filterOptions.actions, 'value', [])
      this.$set(this.filterOptions.schema, 'value', [])
      this.$set(this.filterOptions.tables, 'value', [])

      this.loadSnapshots()
    },
    async filterSnapshots () {
      this.loading = true
      let payload = {
        limit: JSON.stringify(this.snapshotsPageLimit),
        offset: JSON.stringify(this.snapshotsCurrentPage)
      }
      if (this.filterOptions.actions.value.length > 0) {
        Object.assign(payload, {
          op: JSON.stringify(this.filterOptions.actions.value)
        })
      }
      if (this.filterOptions.tables.value.length > 0) {
        Object.assign(payload, {
          table: JSON.stringify(this.filterOptions.tables.value)
        })
      }
      if (this.filterOptions.schema.value.length > 0) {
        Object.assign(payload, {
          schema: JSON.stringify(this.filterOptions.schema.value)
        })
      }
      await this.getQueryBus().execute(
        new SnapshotsFilterQuery(payload)
      ).then(data => {
        this.snapshotsCount = data.count
        this.snapshotsPageSize = this.snapshotsPageLimit
        this.snapshots = data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
</style>
<style scoped src="../../../exportForm.scss" lang="scss"></style>
