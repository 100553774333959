
import Vue from 'vue'
import FilterFieldForm from './FilterFieldForm.vue'
import { FilterFieldDTO } from '@/services/LogicEditor/domain/model/RelatedObject'

export default Vue.extend({
  name: 'FilterForm',

  components: {
    FilterFieldForm
  },

  props: {
    value: Object,
    relatedObjectId: Number,
    currentObjectId: Number
  },

  computed: {
    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!this.filterFieldValid() || !valid) {
          return false
        }

        isValid = true
      })

      return isValid
    },

    filterFieldValid () {
      if (this.$refs.filterFieldForm) {
        let cntx = 0

        this.$refs.filterFieldForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.filterFieldForm.length
      }

      return true
    },

    addFilterField () {
      if (this.relatedObjectId === null) {
        this.$alert(this.$t('logic_editor_v2.messages.select_object'), this.$t('main.message_title.warning'), {
          confirmButtonText: 'OK'
        })

        return
      }

      this.model.filter_fields.push(FilterFieldDTO.create())
    },

    removeFilterField (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.filter_fields.splice(index, 1)
      })
    }
  }
})
