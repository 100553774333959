<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.url">
    <el-input v-model="localProperties[0].value"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'url',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'url', 'type': 'varchar', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || []
    }
  }
}
</script>

<style>
.subMenu .el-form-item__content {
  width: 100%;
}
</style>
