
import Vue from 'vue'

export default Vue.extend({
  name: 'ThemeCard',

  props: {
    data: Object,

    handleEdit: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleRename: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleDuplicate: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleReset: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleAccept: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleDownload: {
      type: Function,
      default () {
        return function (item) {}
      }
    },
    handleDelete: {
      type: Function,
      default () {
        return function (item) {}
      }
    }
  },

  computed: {
    palette () {
      return {
        primary: this.data?.properties['$--color-primary'] || '#000',
        success: this.data?.properties['$--color-success'] || '#000',
        warning: this.data?.properties['$--color-warning'] || '#000',
        textPrimary: this.data?.properties['$--color-text-primary'] || '#000',
        textRegular: this.data?.properties['$--color-text-regular'] || '#000',
        textSecondary: this.data?.properties['$--color-text-secondary'] || '#000'
      }
    }
  },

  methods: {
    _handleEdit () {
      if (this.handleEdit) {
        this.handleEdit(this.data)
      }
    },

    _handleDownload () {
      if (this.handleDownload) {
        this.handleDownload(this.data)
      }
    },

    _handleDropdown (command: string) {
      if (command === 'rename') {
        if (this.handleRename) {
          this.handleRename(this.data)
        }
      } else if (command === 'duplicate') {
        if (this.handleDuplicate) {
          this.handleDuplicate(this.data)
        }
      } else if (command === 'reset') {
        if (this.handleReset) {
          this.handleReset(this.data)
        }
      } else if (command === 'accept') {
        if (this.handleAccept) {
          this.handleAccept(this.data)
        }
      } else if (command === 'delete') {
        if (this.handleDelete) {
          this.handleDelete(this.data)
        }
      }
    }
  }
})
