import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RuleUpdateCommand from '@/services/TaskEditor/application/command/RuleUpdateCommand'
import RuleCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleCommandRepositoryInterface'

export default class RuleUpdateHandler implements CommandHandlerInterface {
    private repository: RuleCommandRepositoryInterface;

    constructor (repository: RuleCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RuleUpdateCommand): Promise<void> {
      return this.repository.update(command.getModel())
    }
}
