import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import FormulaUpdateCommand from '@/services/LogicEditor/application/command/FormulaUpdateCommand'
import FormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/FormulaCommandRepositoryInterface'

export default class FormulaUpdateHandler implements CommandHandlerInterface {
    private repository: FormulaCommandRepositoryInterface;

    constructor (repository: FormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: FormulaUpdateCommand): Promise<void> {
      let item: FormulaDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        formula_type_id: command.getFormulaTypeId(),
        sql_statements: command.getSqlStatements(),
        dsl_statement: command.getDslStatement(),
        entity_id: command.getEntityId(),
        role_id: command.getRoleId(),
        is_dsl: command.getIsDsl(),
        is_stored: command.getIsStored(),
        is_basic: command.getIsBasic()
      }

      return this.repository.update(item)
    }
}
