import TransformerCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerCommandRepositoryInterface'
import store from '@/store'
import { CreateTransformer, UpdateTransformer, DeleteTransformer } from '@/services/EtlEditor/infrastructure/store/modules/Transformer/action-types'
import { TransformerDTO } from '@/services/EtlEditor/domain/model/Transformer'

export default class TransformerCommandRepository implements TransformerCommandRepositoryInterface {
  insert (item: TransformerDTO): Promise<void> {
    return store.dispatch(new CreateTransformer(item))
  }

  update (item: TransformerDTO): Promise<void> {
    return store.dispatch(new UpdateTransformer(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteTransformer(guid))
  }
}
