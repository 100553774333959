import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace RegistryFieldsAPI {

  export class GetByRegistryId implements APIRequest<[]> {
    response: [];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (registryId: number) {
      this.path = `/objecteditor/entities?parent_id=${registryId}&show_children=true`
    }
  }
}
