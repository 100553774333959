import RuleCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleCommandRepositoryInterface'
import store from '@/store'
import { CreateRule, UpdateRule, DeleteRule } from '@/services/TaskEditor/infrastructure/store/modules/Rule/action-types'
import Rule from '@/services/TaskEditor/domain/model/Rule'

export default class RuleCommandRepository implements RuleCommandRepositoryInterface {
  insert (item: Rule): Promise<void> {
    return store.dispatch(new CreateRule(item))
  }

  update (item: Rule): Promise<void> {
    return store.dispatch(new UpdateRule(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteRule(guid))
  }
}
