<template>
  <VueResizable
    :active="resizable.active"
    :width="resizable.width"
    :class="{ 'is-toggle': isToggle }"
    :min-width="minWidth"
    @resize:end="resize"
    class="resizable-panel"
  >
<!--    <button type="button" class="toggle-button" :class="{ 'toggle-button-right': active === 'right' }" @click="toggle">
      <template v-if="active === 'right'">
        <span v-if="isToggle" class="el-icon-caret-left"></span>
        <span v-else class="el-icon-caret-right"></span>
      </template>

      <template v-else>
        <span v-if="isToggle" class="el-icon-caret-right"></span>
        <span v-else class="el-icon-caret-left"></span>
      </template>
    </button>-->

    <slot></slot>
  </VueResizable>
</template>

<script>
import Vue from 'vue'
import VueResizable from 'vue-resizable'

export default Vue.extend({
  name: 'ResizablePanel',

  components: {
    VueResizable
  },

  props: {
    active: {
      type: String,
      default: 'left'
    },

    defaultWidth: {
      type: Number,
      default: 300
    },

    minWidth: {
      type: Number,
      default: 50
    },

    name: String
  },

  data () {
    return {
      isToggle: true,

      resizable: {
        active: [this.active === 'left' ? 'l' : 'r'],
        width: this.defaultWidth
      },

      lastResizable: {
        active: [this.active === 'left' ? 'l' : 'r'],
        width: this.defaultWidth
      }
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    toggle () {
      this.isToggle = !this.isToggle

      if (this.isToggle) {
        // repair
        if (!this.lastResizable.active.length) {
          this.lastResizable = {
            active: [this.active === 'left' ? 'l' : 'r'],
            width: this.defaultWidth
          }
        }

        this.resizable = JSON.parse(JSON.stringify(this.lastResizable))
      } else {
        this.lastResizable = JSON.parse(JSON.stringify(this.resizable))

        this.resizable = {
          active: [],
          width: 0
        }
      }

      this.save()
    },

    resize (event) {
      this.resizable.width = event.width

      this.save()
    },

    save () {
      if (this.name) {
        localStorage.setItem('resizable_panel_' + this.name, JSON.stringify({
          isToggle: this.isToggle,
          lastResizable: this.lastResizable,
          resizable: this.resizable
        }))
      }
    },

    load () {
      if (this.name) {
        const strData = localStorage.getItem('resizable_panel_' + this.name)

        if (strData) {
          const defaultValue = this.getDefaultValue()
          const {
            isToggle = defaultValue.isToggle,
            lastResizable = defaultValue.lastResizable,
            resizable = defaultValue.resizable
          } = JSON.parse(strData)

          this.isToggle = isToggle
          this.lastResizable = lastResizable
          this.resizable = resizable
        }
      } else {
        this.save()
      }
    },

    getDefaultValue () {
      return {
        isToggle: true,

        resizable: {
          active: [this.active === 'left' ? 'l' : 'r'],
          width: this.defaultWidth
        },

        lastResizable: {
          active: [this.active === 'left' ? 'l' : 'r'],
          width: this.defaultWidth
        }
      }
    }
  }
})
</script>

<style lang="scss">
.resizable-panel {
  position: relative;
  left: 0 !important;
  right: 0 !important;
}
</style>
