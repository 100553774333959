import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import DeleteRequestParameterCommand from '@/services/ApiService/application/command/DeleteRequestParameterCommand'

export default class DeleteRequestParameterHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: DeleteRequestParameterCommand): Promise<void> {
    return this.repository.deleteRequestParameter(command.getDto().guid)
  }
}
