import {
  is,
  getBusinessObject
} from 'bpmn-js/lib/util/ModelUtil'

export function isConditionalFlow (element) {
  const businessObject = getBusinessObject(element)
  const sourceBusinessObject = getBusinessObject(element.source)
  console.log(is(sourceBusinessObject, 'bpmn:Activity'), sourceBusinessObject)
  if (!is(element, 'bpmn:SequenceFlow') || !sourceBusinessObject) {
    return false
  }

  return is(sourceBusinessObject, 'bpmn:Activity')
}

export function getLoopCharacteristics (element) {
  const businessObject = getBusinessObject(element)
  return businessObject.get('loopCharacteristics')
}

export function getExtensionProperty (element, property: string) {
  if (Object.keys(element.businessObject?.extensionElements || {}).length) {
    const properties = element.businessObject?.extensionElements.get('values')[0].get('properties')
    const _extension = properties?.find((_) => _.name === property)
    if (_extension) {
      return _extension.value
    }
  }

  return null
}

export function parseDotNotations (obj: object): object {
  let result = {}
  for (let property in obj) {
    property.split('.').reduce(function (r, a, i, array) {
      if (i === array.length - 1) {
        r[a] = obj[property]
      } else {
        r[a] = r[a] || {}
      }
      return r[a]
    }, result)
  }

  return result
}

export function prepareDotNotations (obj: object): any {
  function walk (into, obj, prefix = []) {
    obj && Object.entries(obj).forEach(([key, val]) => {
      if (typeof val === 'object' && !Array.isArray(val)) walk(into, val, [...prefix, key])
      else into[[...prefix, key].join('.')] = val
    })
  }
  const out = {}
  walk(out, obj)
  return out
}
