import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ReleaseQueryRepositoryInterface from '@/services/ReleaseEditor/domain/repository/ReleaseQueryRepositoryInterface'
import store from '@/store'
import { GetReleases, CountReleases, GetReleaseByGuid } from '@/services/ReleaseEditor/infrastructure/store/modules/Release/action-types'

export default class ReleaseQueryRepository extends AbstractQueryRepository implements ReleaseQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetReleases(payload));
    }

    getCount(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new CountReleases(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetReleaseByGuid(guid));
    }
}