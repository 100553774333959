import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TaskRegistryEntitiesQuery from '@/services/TaskEditor/application/query/TaskRegistryEntitiesQuery'
import TaskRegistryEntityQueryRepositoryInterface
  from '@/services/TaskEditor/domain/repository/TaskRegistryEntityQueryRepositoryInterface'
import TaskRegistryEntity from '@/services/TaskEditor/domain/model/TaskRegistryEntity'

export default class TaskRegistryEntitiesHandler implements QueryHandlerInterface {
  private repository: TaskRegistryEntityQueryRepositoryInterface;

  constructor (repository: TaskRegistryEntityQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TaskRegistryEntitiesQuery): Promise<Array<TaskRegistryEntity>> {
    return this.repository.get()
  }
}
