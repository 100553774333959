import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { AxiosResponse } from 'axios'

export namespace AccessEditorAPI {
  export class GetRoles implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (payload?: string[][]) {
      if (payload) {
        this.path = `/v2/accesseditor/roles`
      } else {
        this.path = `/v2/accesseditor/roles?${new URLSearchParams(payload).toString()}`
      }
    }
  }
}
