import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetLogic, GetLogicByGuid } from '@/services/LogicEditor/infrastructure/store/modules/Logic/action-types'
import LogicQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicQueryRepositoryInterface'

export default class LogicQueryRepository extends AbstractQueryRepository implements LogicQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetLogic(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetLogicByGuid(guid))
  }
}
