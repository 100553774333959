
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RegistryRightDTO {
    role_guid?: Nullable<string>;
    entity_id?: Nullable<number>;
    is_addable?: Nullable<boolean>;
    is_readable?: Nullable<boolean>;
    is_editable?: Nullable<boolean>;
    is_deletable?: Nullable<boolean>;

    constructor ({ role_guid, entity_id, is_addable, is_readable, is_editable, is_deletable }: {role_guid?: Nullable<string>, entity_id?: Nullable<number>, is_addable?: Nullable<boolean>, is_readable?: Nullable<boolean>, is_editable?: Nullable<boolean>, is_deletable?: Nullable<boolean>}) {
      this.role_guid = role_guid
      this.entity_id = entity_id
      this.is_addable = is_addable
      this.is_readable = is_readable
      this.is_editable = is_editable
      this.is_deletable = is_deletable
    }
}

export default class RegistryRight extends AbstractBaseEntity {
    private roleGuid: Nullable<string>;
    private entityId: Nullable<number>;
    private isAddable: Nullable<boolean>;
    private isReadable: Nullable<boolean>;
    private isEditable: Nullable<boolean>;
    private isDeletable: Nullable<boolean>;

    constructor (
      roleGuid: Nullable<string>,
      entityId: Nullable<number>,
      isAddable: Nullable<boolean>,
      isReadable: Nullable<boolean>,
      isEditable: Nullable<boolean>,
      isDeletable: Nullable<boolean>
    ) {
      super()
      this.roleGuid = roleGuid
      this.entityId = entityId
      this.isAddable = isAddable
      this.isReadable = isReadable
      this.isEditable = isEditable
      this.isDeletable = isDeletable
    }

    static create (dto: RegistryRightDTO): RegistryRight {
      return new RegistryRight(
        dto.role_guid,
        dto.entity_id,
        dto.is_addable,
        dto.is_readable,
        dto.is_editable,
        dto.is_deletable
      )
    }

    setRoleGuid (roleGuid: string): void {
      this.roleGuid = roleGuid
    }

    getRoleGuid (): string {
      return this.roleGuid
    }

    setEntityId (entityId: number): void {
      this.entityId = entityId
    }

    getEntityId (): number {
      return this.entityId
    }

    setIsAddable (isAddable: Nullable<boolean>): void {
      this.isAddable = isAddable
    }

    getIsAddable (): Nullable<boolean> {
      return this.isAddable
    }

    setIsReadable (isReadable: Nullable<boolean>): void {
      this.isReadable = isReadable
    }

    getIsReadable (): Nullable<boolean> {
      return this.isReadable
    }

    setIsEditable (isEditable: Nullable<boolean>): void {
      this.isEditable = isEditable
    }

    getIsEditable (): Nullable<boolean> {
      return this.isEditable
    }

    setIsDeletable (isDeletable: Nullable<boolean>): void {
      this.isDeletable = isDeletable
    }

    getIsDeletable (): Nullable<boolean> {
      return this.isDeletable
    }
}
