export interface IRequestTreeDTO {
  id?: number;
  guid?: string;
  name: string;
  row_order: number;
  parent_id?: number;
  element_type?: string;
  protocol_type?: string;
  request_type?: string;
  method_type_id?: string;
}

export class RequestTreeDTO {
  id?: number;
  guid?: string;
  name: string;
  row_order: number;
  parent_id?: number;
  element_type?: string;
  protocol_type?: string;
  request_type?: string;
  method_type_id?: string;

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: IRequestTreeDTO) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.row_order = data.row_order
    this.parent_id = data.parent_id
    this.element_type = data.element_type
    this.protocol_type = data.protocol_type
    this.request_type = data.request_type
    this.method_type_id = data.method_type_id
  }

  /**
   * Создать пустую модель
   */
  static create () {
    return new RequestTreeDTO({
      name: '',
      row_order: 0,
      element_type: '',
      protocol_type: '',
      request_type: '',
      method_type_id: ''
    })
  }
}
