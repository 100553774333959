import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RuleFieldTypesQuery from '@/services/TaskEditor/application/query/RuleFieldTypesQuery'
import RuleQueryRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleQueryRepositoryInterface'

export default class RuleFieldTypesHandler implements QueryHandlerInterface {
  private repository: RuleQueryRepositoryInterface

  constructor (repository: RuleQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: RuleFieldTypesQuery): Promise<any> {
    return this.repository.getRuleFieldTypes()
  }
}
