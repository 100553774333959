import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerCommandRepositoryInterface'
import LayerUpdateCommand from '@/services/MapEditor/application/command/LayerUpdateCommand'
import { LayerDTO } from '@/services/MapEditor/domain/model/Layer/Layer'

export default class LayerUpdateHandler implements CommandHandlerInterface
{
    private repo: LayerCommandRepositoryInterface;

    constructor(repo: LayerCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerUpdateCommand): Promise<void>
    {
        let item: LayerDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            description: command.getDescription(),
            source_id: command.getSourceId(),
            type: command.getType(),
            srs_handling_type: command.getSrsHandling(),
            declared_coordinate_system_id: command.getDeclaredCoordinateSystem(),
            native_coordinate_system_id: command.getNativeCoordinateSystem(),
            point_style_id: command.getPointStyleId(),
            linestring_style_id: command.getLinestringStyleId(),
            polygon_style_id: command.getPolygonStyleId(),
            icon_style_type_id: command.getIconStyleTypeId(),
            constraint_filter: command.getConstraintFilter(),
            is_clustered: command.getIsClustered(),
            native_bbox_xmin: command.getNativeBboxXmin(),
            native_bbox_ymin: command.getNativeBboxYmin(),
            native_bbox_xmax: command.getNativeBboxXmax(),
            native_bbox_ymax: command.getNativeBboxYmax(),
            latlon_bbox_xmin: command.getLatlonBboxXmin(),
            latlon_bbox_ymin: command.getLatlonBboxYmin(),
            latlon_bbox_xmax: command.getLatlonBboxXmax(),
            latlon_bbox_ymax: command.getLatlonBboxYmax(),
            properties: command.getProperties(),
            project_id: command.getProjectId(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}