
import Vue from 'vue'
import CalculatedParameters from '@/services/LogicEditor/infrastructure/components/elements/CalculatedParameters.vue'
import UsersSelect from '@/services/LogicEditor/infrastructure/components/elements/UsersSelect.vue'
import ProcessTreeElements from '@/core/infrastructure/components/ProcessTreeElements.vue'

export default Vue.extend({
  name: 'BpmnEventForm',

  props: ['value', 'logicId', 'objectId', 'eventId'],

  components: {
    CalculatedParameters,
    UsersSelect,
    ProcessTreeElements
  },

  watch: {
    'model.parameters': {
      handler: function (value) {
        if (typeof this.model.task_id_param !== 'undefined') {
          const index = value.findIndex(v => v.name === this.model.task_id_param)
          if (index === -1) {
            this.model.task_id_param = null
          }
        }
      },

      deep: true
    }
  },

  computed: {
    parameters () {
      if (!this.model.parameters) {
        return []
      }

      return this.model.parameters.filter(param => !!param.name)
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        task_id_param: [
          { required: true, message: 'Select task id parameter', trigger: 'change' }
        ],
        parameters: [
          { required: true, message: 'Filled parameters', trigger: 'change' }
        ]
      },

      processKeys: [],

      userObjectId: null
    }
  },

  async mounted () {
    if (!this.userObjectId) {
      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true`)
        .then((response) => {
          this.userObjectId = response.data.data[0].id
        })
        .catch(error => console.log(error))
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    }
  }
})
