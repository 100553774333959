import { generateGuid } from '@/helpers'
import Modeler from 'bpmn-js/lib/Modeler'
import {
  is,
  getBusinessObject
} from 'bpmn-js/lib/util/ModelUtil'
import { getExtensionProperty } from '@/services/BPMNEditor/infrastructure/utils/utils'

export default class ElementFactory {
  private static AUTOFILL_EXTENSIONS = {
    'UserTask': ['target_registry_id'],
    'ServiceTask': ['target_registry_id']
  }

  public static build (
    modeler: Modeler,
    {
      type, name, properties, extensions
    }: {
      type: string,
      properties?: object,
      name?: string,
      extensions?: object
    }
  ) {
    const elementFactory = modeler.get('elementFactory')
    const bpmnFactory = modeler.get('bpmnFactory')
    const taskBusinessObject = bpmnFactory.create(`bpmn:${type}`, { id: generateGuid(), name: name })

    const element = elementFactory.createShape({
      type: `bpmn:${type}`,
      businessObject: taskBusinessObject
    })
    if (properties && Object.keys(properties).length > 0) {
      modeler.get('modeling').updateProperties(element, properties)
    }

    const autoFillExtensions = this.AUTOFILL_EXTENSIONS[type]

    if (autoFillExtensions) {
      if (!extensions) {
        extensions = {}
      }
      autoFillExtensions.forEach((extension) => {
        const extensionValue = this.findExtensionOnSchema(modeler, type, extension)
        if (extensionValue) {
          extensions[extension] = extensionValue
        }
      })
    }

    if (extensions && Object.keys(extensions).length > 0) {
      const moddle = modeler.get('moddle')
      const extensionElements = moddle.create('bpmn:ExtensionElements')
      const extensionProperties = moddle.create('extension:Properties')
      Object.keys(extensions).forEach((key) => {
        let property = moddle.create('extension:Property', {
          name: key,
          value: extensions[key]
        })
        extensionProperties.get('properties').push(property)
      })
      extensionElements.get('values').push(extensionProperties)
      modeler.get('modeling').updateProperties(element, { extensionElements })
    }

    return element
  }

  private static findExtensionOnSchema (modeler: Modeler, type: string, extension: string) {
    const elementRegistry = modeler.get('elementRegistry')
    const sameElement = elementRegistry.find((element) => {
      if (is(element, `bpmn:${type}`)) {
        return !!getExtensionProperty(element, extension)
      }

      return false
    })
    if (!sameElement) {
      return null
    }

    return getExtensionProperty(sameElement, extension)
  }
}
