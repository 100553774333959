<template>
  <div class="regions">
    <div class="header">
      <i class="el-icon-refresh-right button" @click="load"></i>
      <el-input
        v-model="search"
        style="width: 20%; float: right"
        :placeholder="$locale.main.fields.search"/>
      <el-button type="text" style="margin-right: 20px;float: right" @click="garUpdate">{{ $locale.gar_address_service.regions.update_from_gar }}</el-button>
    </div>
    <el-table
      :data="regions.filter(item => !search || (item.name.toLowerCase().includes(search.toLowerCase()) || item.code.toLowerCase().includes(search.toLowerCase())))"
      v-loading="isLoading"
      style="width: 100%; border: none; height: 100%"
      class="custom_scrollbar table"
      height="calc(100% - 50px)"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
    >
      <el-table-column width="70" label="">
        <template slot-scope="scope">
          <i
            v-show="!scope.row.edit"
            style="cursor: pointer"
            class="el-icon-edit"
            @click="$set(scope.row, 'edit', true)"
          ></i>
          <i
            v-show="scope.row.edit"
            style="cursor: pointer"
            class="el-icon-check"
            @click="updateRegion(scope.row)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        :label="$locale.gar_address_service.regions.columns.code"
        prop="code"
        width="100"
      >
        <template slot-scope="scope">
          <span style="font-weight: bold">{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        sortable
        width="350"
        :label="$locale.gar_address_service.regions.columns.name"
      ></el-table-column>
      <el-table-column
        prop="is_updatable"
        sortable
        width="120"
        :label="$locale.gar_address_service.regions.columns.is_updatable">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_updatable"
            :disabled="!scope.row.edit"
            active-color="#3DB650">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        :label="$locale.gar_address_service.regions.columns.gar_tables"
        prop="is_updatable"
        sortable
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.gar_tables_values"
            :disabled="!scope.row.edit"
            multiple
          >
            <el-option
              v-for="garTable in scope.row.gar_tables_options"
              :key="garTable.id"
              :value="garTable.id"
              :label="garTable.name"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import RegionsQuery from '@/services/GarAddressService/application/query/RegionsQuery'
  import RegionUpdateCommand from '@/services/GarAddressService/application/command/RegionUpdateCommand'
  import GarTablesQuery from '@/services/GarAddressService/application/query/GarTablesQuery'
  import GarUpdateCommand from '@/services/GarAddressService/application/command/GarUpdateCommand'

  export default {
    name: 'Regions',
    inject: ['getQueryBus', 'getCommandBus'],
    data: function () {
      return {
        isLoading: false,
        regions: [],
        garTables: [],
        search: '',
        cellStyle: {
          fontSize: '15px',
          color: '#2D3239'
        },
        headerCellStyle: {
          color: '#6E757C',
          fontSize: '13px',
          border: 'none',
          background: '#EFF0F0'
        }
      }
    },
    async mounted() {
      await this.loadGarTables()
      this.load()
    },
    methods: {
      garUpdate () {
        this.$confirm(this.$locale.gar_address_service.regions.update_from_gar_confirm_text, this.$locale.main.message.confirm, {
          type: 'warning'
        }).then(() => {
          this.getCommandBus().execute(
            new GarUpdateCommand()
          )
        })
      },
      updateRegion (region) {
        this.isLoading = true
        this.getCommandBus().execute(
          new RegionUpdateCommand(
            region.code,
            region.is_updatable,
            region.name,
            region.gar_tables_values
          )
        ).finally(() => {
          this.$set(region, 'edit', false)
          this.load()
          this.isLoading = false
        })
      },
      async load () {
        this.isLoading = true
        this.getQueryBus().execute(
          new RegionsQuery()
        )
          .then((result) => {
            result.forEach((item) => {
              item.gar_tables = JSON.parse(item.gar_tables)
              item.gar_tables_values = item.gar_tables.map(x => x.id)
              item.gar_tables_options = JSON.parse(JSON.stringify(this.garTables))
              item.gar_tables.forEach((gar_table) => {
                if (gar_table.version_id) {
                  let a = item.gar_tables_options.find((b) => b.id === gar_table.id)
                  if (a) {
                    a.name += ', версия ' + gar_table.version_id
                  }
                }
              })
            })
            this.regions = result
          })
          .finally(() => { this.isLoading = false })
      },
      async loadGarTables () {
        await this.getQueryBus().execute(
          new GarTablesQuery()
        )
          .then((result) => {
            this.garTables = result
          })
      }
    }
  }
</script>

<style scoped src="./Regions.css">

</style>
