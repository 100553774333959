import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import { RequestAPI } from '@/services/ApiService/infrastructure/api/RequestAPI'
import {
  GetRequests,
  CreateRequest,
  DeleteRequest,
  GetRequestSchemeEntities,
  UpdateRequestEntity,
  GetRequestParameters,
  CreateRequestParameter,
  DeleteRequestParameter,
  UpdateRequestParameter,
  GetExportStructure,
  ImportStructure
} from '@/services/ApiService/infrastructure/store/modules/Request/action-types'
import { Request } from '@/services/ApiService/domain/model/Request/Request'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export default class RequestRepository extends AbstractQueryRepository implements RequestRepositoryInterface {
  getRequests (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetRequests(payload))
  }
  createRequest (payload: object): Promise<any> {
    return store.dispatch(new CreateRequest(payload))
  }
  deleteRequest (guid: string): Promise<any> {
    return store.dispatch(new DeleteRequest(guid))
  }
  getRequestSchemeEntities (): Promise<any> {
    return store.dispatch(new GetRequestSchemeEntities())
  }
  updateRequestEntity (payload: Request, entityType: string): Promise<any> {
    return store.dispatch(new UpdateRequestEntity(payload, entityType))
  }
  getRequestParameters (guid: string): Promise<any> {
    return store.dispatch(new GetRequestParameters(guid))
  }
  createRequestParameter (payload: object, requestGuid: string): Promise<any> {
    return store.dispatch(new CreateRequestParameter(payload, requestGuid))
  }
  deleteRequestParameter (guid: string): Promise<any> {
    return store.dispatch(new DeleteRequestParameter(guid))
  }
  updateRequestParameter (payload: RequestParameter): Promise<any> {
    return store.dispatch(new UpdateRequestParameter(payload))
  }
  exportStructure (id: number): Promise<any> {
    return store.dispatch(new GetExportStructure(id))
  }
  importStructure (payload: object): Promise<any> {
    return store.dispatch(new ImportStructure(payload))
  }
}
