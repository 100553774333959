enum TopicType
{
    PACKAGE = 'package',
    SMEV_REQUEST = 'smev_request',
    SMEV_INCOMING_REQUEST = 'smev_incoming_request',
    SMEV_OUTGOING_RESPONSE = 'smev_outgoing_response',
    GIS_GKH_REQUEST = 'gis_gkh_request',
    SN_REQUEST = 'sn_request'
};

export default TopicType;