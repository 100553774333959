<template>
  <div style="height: 100%">
    <template v-if="!accentGuid">
      <div class="header">
        <el-radio-group v-model="associateType" size="mini">
          <el-radio-button label="isset">Существующий</el-radio-button>
          <el-radio-button label="new">Новый</el-radio-button>
        </el-radio-group>
      </div>
      <div class="associate" v-if="associateType==='isset'">
        <el-scrollbar :style="{height:'calc(100% - 44px)'}" wrap-style="overflow-x:hidden;">
          <el-tree
            v-loading="loading"
            ref="tree"
            node-key="guid"
            :data="elements"
            :props="{
              isLeaf: 'is_leaf',
              label: 'name',
              disabled: 'disabled'
            }"
            :accordion="false"
            :expand-on-click-node="false"
            :default-checked-keys="[localValue]"
            size="mini"
            default-expand-all
            check-on-click-node
            check-strictly
            show-checkbox
            @check-change="checkNode"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="node-label" v-html="getLabel(node, data)"></span>
            </span>
          </el-tree>
        </el-scrollbar>
        <el-checkbox class="import_checkbox" v-model="isImportApproval">Импортировать связи</el-checkbox>
        <el-button class="button" size="small" type="primary" :disabled="!localValue" @click="associate">Связать
        </el-button>
      </div>
      <div v-else class="isset">
        <div class="label">Выберите реестр</div>
        <el-select v-model="activeLogic" size="small" value-key="id" filterable>
          <el-option
            v-for="logic in logics"
            :key="logic.id"
            :value="logic"
            :label="`${logic.name} (БЛ: ${logic.id}, Реестр: ${logic.entity_id})`"
          >
            <i class="el-icon-folder"></i> <span class="node-label__name">{{ logic.name }}</span><span
            class="node-label__info"> (БЛ: {{ logic.id }}, Реестр: {{ logic.entity_id }})</span>
          </el-option>
        </el-select>
        <template v-if="activeLogic && type === 'approval_stage'">
          <div class="label" style="margin-top: 5px">Выберите маршрут согласование</div>
          <logic-tree-elements
            :key="activeLogic.id"
            :hide-actions="true"
            v-model="activeApproval"
            :multiple="false"
            element-type="approval"
            :logic-id="activeLogic.id"
            :placeholder="$t('logic_editor_v2.element_types.approval')"
          >
          </logic-tree-elements>
        </template>
        <div class="form" :style="`height:calc(100% - ${type === 'approval_stage' ? '158px' : '106px'})`">
          <el-scrollbar :style="{height:'calc(100%)'}" v-if="activeLogic && ((type === 'approval_stage' && activeApproval) || type !== 'approval_stage')" wrap-style="overflow-x:hidden;">
            <component
              ref="form"
              :key="activeLogic.id"
              :is="form[type]"
              v-model="model"
              :approval-id="activeApproval"
              :logic-id="activeLogic.id"
              :object-id="activeLogic.entity_id"
              :interactive="true"
              :from-bpmn="true"
              @created="created"
            ></component>
          </el-scrollbar>
        </div>
        <el-button class="button" size="small" type="primary" @click="save()" :disabled="!activeLogic">Добавить
        </el-button>
      </div>
    </template>
    <div v-else class="edit">
      <el-scrollbar :style="{padding: '0px 24px', height:'calc(100% - 35px)'}" wrap-style="overflow-x:hidden;">
        <component
          v-if="model.guid"
          :key="model.logic_id"
          ref="form"
          :is="form[type]"
          v-model="model"
          :logic-id="model.logic_id"
          :object-id="model.entity_id || model.object_id"
          :approval-id="model.approval_id"
          :interactive="true"
          :from-bpmn="true"
        ></component>
        <el-alert v-if="error" type="error" :title="error" :closable="false"></el-alert>
      </el-scrollbar>
      <el-button class="button" size="small" type="primary" @click="save(true)">Сохранить
      </el-button>
    </div>
  </div>
</template>

<script>
import LogicTreeElementsTreeQuery from '@/services/LogicEditor/application/query/LogicTreeElementsTreeQuery'
import LogicQuery from '@/services/LogicEditor/application/query/LogicQuery'
import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'
import CommandForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/index.vue'
import ApprovalForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalForm.vue'
import ApprovalStageForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalStageForm'
import MainMixin from '@/services/LogicEditor/infrastructure/mixins/MainMixin'
import { ApprovalStageDTO } from '@/services/LogicEditor/domain/model/ApprovalStage'

export default {
  name: 'AssociateWith',
  mixins: [MainMixin],
  props: {
    type: String,
    accentGuid: String,
    properties: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    CommandForm,
    ApprovalForm,
    ApprovalStageForm
  },
  provide () {
    return {
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  data () {
    return {
      form: {
        approval: 'ApprovalForm',
        command: 'CommandForm',
        approval_stage: 'ApprovalStageForm'
      },
      icons: {
        logic: 'el-icon-folder',
        group: 'el-icon-folder',
        formula: 'bs-icon-column-formula',
        row_formula: 'bs-icon-row-formula',
        state: 'bs-icon-state',
        listener: 'bs-icon-listener',
        command: 'bs-icon-command',
        constraint: 'bs-icon-constraint',
        view_constraint: 'bs-icon-view-field',
        disable_constraint: 'bs-icon-blocked-field',
        approval: 'bs-icon-approval-stages',
        related_object: 'bs-icon-related-object'
      },
      defaultModel: {
        approval: ApprovalDTO,
        command: CommandDTO,
        approval_stage: ApprovalStageDTO
      },
      associateType: 'isset',
      elements: [],
      loading: true,
      localValue: null,
      logics: [],
      activeLogic: null,
      model: {},
      error: null,
      activeApproval: null,
      isImportApproval: false
    }
  },
  async mounted () {
    if (this.accentGuid) {
      this.queryBus.execute(
        new this.queryByGuid[this.type](this.accentGuid)
      ).then((response) => {
        this.model = response
      }).catch(() => {
        this.error = 'Ошибка получения сущности'
      })
    } else {
      this.loadElements()
      this.logics = await this.queryBus.execute(
        new LogicQuery()
      )
      this.model = this.defaultModel[this.type]?.create()
      if (this.properties && Object.keys(this.properties).length > 0) {
        this.model = Object.assign(this.model, this.properties)
      }
      if (this.type === 'approval') {
        this.model.is_enabled = false
      }
    }
  },
  watch: {
    activeLogic: {
      deep: true,
      handler (value) {
        this.model.logic_id = value.id
      }
    }
  },
  methods: {
    created (model) {
      if (model.guid !== 'undefined') {
        this.$emit('associate', model)
      }
    },
    save (isset = false) {
      this.$refs.form.submit(() => {
        if (isset) {
          this.$emit('associate', this.model)
        }
      })
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    },
    async loadElements () {
      this.elements = await this.queryBus.execute(
        new LogicTreeElementsTreeQuery(this.type, null)
      )

      this.loading = false
    },
    checkNode (node, checked) {
      if (checked) {
        this.localValue = node.element_guid
        this.$refs.tree.setCheckedKeys([node.guid])
      } else {
        if (this.localValue === node.element_guid) {
          this.localValue = null
        }
      }
    },
    getLabel (node, data) {
      const iconClass = this.getTreeIcon(node)
      const icon = iconClass ? `<span class="node-label__icon ${iconClass}"></span>` : ''

      if (data.element_type === 'group') {
        return `${icon}<span class="node-label__name">${node.label}</span>`
      } else if (data.element_type === 'logic') {
        return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(БЛ: ${data.id}, Реестр: ${data.entity_id})</span>`
      } else {
        return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.element_id})</span>`
      }
    },
    getTreeIcon (node) {
      if (['group', 'logic'].includes(node.data.element_type)) {
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      } else {
        if (this.icons[node.data.element_type]) {
          return [this.icons[node.data.element_type]]
        }
      }

      return null
    },
    associate () {
      this.queryBus.execute(
        new this.queryByGuid[this.type](this.localValue)
      ).then((response) => {
        this.$emit('associate',
          this.type === 'approval'
            ? Object.assign({}, response, { import: this.isImportApproval })
            : response
        )
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  margin-left: 24px;
}

.associate {
  height: calc(100% - 38px);
  margin-top: 10px;

  & .button {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
  }
  & .import_checkbox {
    margin-left: 15px;
    margin-top: 10px;
  }
}

.isset {
  padding: 0px 24px;
  height: calc(100% - 38px);
  margin-top: 10px;

  & .label {
    margin-bottom: 5px;
    color: #606266;
  }

  & .form {
    height: calc(100% - 106px);
    margin-top: 9px;
    border-top: 1px solid #dddddd;
  }

  & .button {
    float: right;
    margin-top: 5px;
    margin-right: -20px;
  }
}

.edit {
  height: 100%;

  & .button {
    margin-right: 5px;
    float: right;
  }
}
</style>
