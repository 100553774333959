import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetGroupById } from '@/services/NotificationEditor/infrastructure/store/modules/NotificationGroup/action-types'
import GroupQueryRepositoryInterface from '@/services/NotificationEditor/domain/repository/GroupQueryRepositoryInterface'

export default class GroupQueryRepository extends AbstractQueryRepository implements GroupQueryRepositoryInterface {
  getById (id: number): Promise<any> {
    return store.dispatch(new GetGroupById(id))
  }
}
