<template>
  <el-form ref="form" class="relation-form" size="mini" :model="value" :rules="rules" label-position="top">
    <el-row :gutter="10">
      <el-col :span="11">
        <el-form-item prop="from">
          <el-select v-model="localValue.from" :placeholder="$t('report_editor.relation.source_from')">
            <el-option
              v-for="item in fromFieldList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="11">
        <el-form-item prop="to">
          <el-select v-model="localValue.to" :placeholder="$t('report_editor.relation.source_to')">
            <el-option
              v-for="item in toFieldList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-button
          style="margin: 0 10px;"
          circle
          size="mini"
          icon="el-icon-delete"
          type="danger"
          @click="_remove()"
        ></el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'RelationForm',

  props: {
    value: {
      type: Object,
      default () {
        return {
          from: null,
          to: null
        }
      }
    },

    fromFieldList: {
      type: Array,
      default () {
        return []
      }
    },

    toFieldList: {
      type: Array,
      default () {
        return []
      }
    },

    index: Number,

    remove: Function
  },

  watch: {
    value: {
      handler: function () {
        this.localValue = this.value
      },
      deep: true
    },

    localValue: {
      handler: function () {
        this.$emit('input', this.localValue)
      },
      deep: true
    }
  },

  data () {
    return {
      localValue: this.value,
      rules: {
        from: [{ required: true, message: 'Выберите поле родительского источника', trigger: 'change' }],
        to: [{ required: true, message: 'Выберите поле подчинённого источника', trigger: 'change' }]
      }
    }
  },

  methods: {
    isValid () {
      let isValid = true
      this.$refs.form.validate((valid) => {
        isValid = valid
      })
      return isValid
    },

    _remove () {
      this.remove(this.index)
    }
  }
}
</script>
