import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ConstraintUpdateCommand from '@/services/LogicEditor/application/command/ConstraintUpdateCommand'
import ConstraintCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintCommandRepositoryInterface'

export default class ConstraintUpdateHandler implements CommandHandlerInterface {
    private repository: ConstraintCommandRepositoryInterface;

    constructor (repository: ConstraintCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ConstraintUpdateCommand): Promise<void> {
      return this.repository.update(command.getDTO())
    }
}
