import CommandInterface from '@/core/application/command/CommandInterface'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export default class CreateSchemaCommand implements CommandInterface {
  private dto: SchemaTreeDTO;

  constructor (
    dto: SchemaTreeDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'CreateSchemaCommand'
  }

  getDto (): SchemaTreeDTO {
    return this.dto
  }
}
