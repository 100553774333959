<template>
  <div>
      <el-collapse class="layers-accordion">
        <el-collapse-item class="layer-item" v-for="layer in Object.entries(featuresByLayer)" :key="layer[0]">
          <template slot="title">
            <div class="layer-title-accordion">
              {{ layer[1].name }}
            </div>
          </template>
          <el-collapse class="objects-accordion">
            <el-collapse-item v-for="feature in layer[1].features" :key="feature.id" class="object-item">
              <template slot="title">
                <div class="object-title">{{ feature.id }}</div>
              </template>
              <div class="button-container">
                <div class="item-view-mode-selection">
                  <el-tooltip content="приблизить" placement="top">
                    <div
                      class="small-button"
                      @click="fitFeature(layer[0], feature.id)"
                      :style="{ background: 'url(\'/icon/differentIcons/map-zoom-in.svg\') no-repeat 50% 50%' }"></div>
                  </el-tooltip>
                </div>
              </div>
                <div class="object-item-properties">
                  <div class="object-property" v-for="property in Object.entries(feature.properties)" :key="property[0]">
                    <div class="object-property-label">
                      {{ property[0] }}
                    </div>
                    <div class="object-property-value">
                      {{ property[1] }}
                    </div>
                  </div>
                </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
  </div>
</template>

<script>
import MapManager from '@bingo_soft/mapmanager';
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection';
  
  export default {
    name: 'feature-information-panel',
    props: ['map', 'features'],
    data: function () {
      return {
        featuresById: {}
      }
    },
    computed: {
      featuresByLayer() {
        return this.formatFeatures();
      }
    },
    methods: {
      formatFeatures() {
        const featuresByLayer = {};
        this.features.forEach((feature) => {
          const layerProperties = feature.getLayer().properties;
          if (!featuresByLayer[layerProperties.id]) {
            featuresByLayer[layerProperties.id] = {
                'name': layerProperties.name,
                'features': []
            };
          }
          const olFeature = feature.getFeature();
          const olFeatureId = olFeature.getId();
          let featureProperties = olFeature.getProperties();
          // remove all props except for those starting with 'attr_'
          featureProperties = Object.keys(featureProperties).filter(key =>
            key.startsWith('attr_')).reduce((obj, key) =>
            {
                obj[key] = featureProperties[key];
                return obj;
            }, {}
          );
          const featureById = {
              'id': olFeatureId,
              'properties': featureProperties,
              'feature': feature
          };
          featuresByLayer[layerProperties.id]['features'].push(featureById);
          this.featuresById[layerProperties.id + '-' + olFeatureId] = feature;
        });
        console.log(featuresByLayer);
        console.log(this.featuresById);
        return featuresByLayer;
      },
      fitFeature(layerId, featureId) {
        const feature = this.featuresById[layerId + '-' + featureId];
        if (feature) {
            MapManager.fitFeatures(this.map, new FeatureCollection([feature]));
        }
      }
    },
    mounted() {

    }
  }
</script>
<style scoped>
  .el-collapse {
    border: none;
  }
  .el-collapse /deep/ .el-collapse-item__wrap {
    border: none;
    overflow: visible;
  }
  .el-collapse /deep/ .el-collapse-item__arrow {
    left: -26px;
    position: absolute;
  }
  .el-collapse /deep/ .el-collapse-item__header {
    left: 20px;
    position: relative;
    height: 36px;
    line-height: 36px;
    border: none;
    white-space: nowrap;
    font-weight: 400;
  }
  .button-container {
    position: relative;
    top: -30px;
  }
  .button-panel {
    flex-grow: 1;
    align-self: flex-end;
    top: -3px;
    position: relative;
    padding: 10px;
  }
  .layers-accordion /deep/ .el-collapse-item__content {
    border-left-color: #A5AEB6;
    border-left-style: solid;
    border-left-width: 1px;
    padding-bottom: 0;
  }
  .layers-accordion {
    margin: 10px;
  }
  .layer-item {
    margin-left: 10px;
  }
  .layer-title-accordion {
    overflow: hidden;
    width: 99%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .objects-accordion {
    margin-left: 10px;
  }
  .objects-accordion /deep/ .el-collapse-item__content {
    padding-bottom: 5px;
  }
  .object-item /deep/ .el-collapse-item__content {
    border-left-style: hidden;
    padding-bottom: 0;
  }
  .object-title {
    width: calc(100% - 120px);
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .item-view-mode-selection {
    float: right;
    width: max-content;
    height: 24px;
    position: absolute;
    right: -16px;
  }
  .small-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    margin: 0 11px;
  }
  .object-property {
    /* left: 10px; */
    position: relative;
    padding: 10px;
  }
  .object-property:nth-child(even) {
    background-color: #F4F5F6;
  }
  .object-property-label {
    color: #6E7B87;
    height: 16px;
    font-size: 13px;
    line-height: 16px;
  }
  .object-property-value {
    font-size: 13px;
    line-height: 18px;
  }
</style>
