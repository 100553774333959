import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import UpdateRequestEntityCommand from '@/services/ApiService/application/command/UpdateRequestEntityCommand'

export default class UpdateRequestEntityHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: UpdateRequestEntityCommand): Promise<void> {
    return this.repository.updateRequestEntity(command.getDto(), command.getEntityType())
  }
}
