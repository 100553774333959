 <template>
  <registry-table
  v-if="show" 
  :registry-id="registryId"
  :is-show-toolbar="false"
  :row-double-clicked="true"
   @edit-record="editRecord"
  > 
      <div class="bottom-buttons">
        <!-- <el-button 
        type="info" size="small" @click="link">
          {{$locale.map_editor.link_to_registry_form.link_to_registry_button}}
        </el-button> -->
        <el-button @click="show=false">
          {{$locale.main.button.cancel}}
        </el-button>
      </div>
  </registry-table>
</template>

<script type="ts">

import RegistryTable from '@/services/RegistryTable/index.vue'

export default {
  name: 'LinkToRegistryForm',
  props: ['registryId'],
  components: {
    RegistryTable
  },
  inject: ['getEventBus'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    showForm() {
      this.show = true;    
    },
    hideForm() {
      this.show = false;    
    },
    /* link(event) {
      this.getEventBus().$emit('edit-record', event);
    }, */
    editRecord(row) {
      this.getEventBus().$emit('linkToRegistryFormReturn', row.id);
    }
  }
}
</script>

<style scoped>
.button {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: white;
  cursor: pointer;
}
.button svg {
  color: #6E7B87;
  font-size: 16px;
  padding: 14px;
}
.button:hover {
  background-color: #ecf5ff;
}
.button:hover svg {
  color: #409EFF;
}
.button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.button:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.bottom-buttons {
  padding: 5px;  
  border: 1px solid #6E7B87;
  border-top: 0px;
}
</style>