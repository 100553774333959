import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RuleCreateCommand from '@/services/TaskEditor/application/command/RuleCreateCommand'
import RuleCommandRepositoryInterface from '@/services/TaskEditor/domain/repository/RuleCommandRepositoryInterface'

export default class RuleCreateHandler implements CommandHandlerInterface {
    private repository: RuleCommandRepositoryInterface;

    constructor (repository: RuleCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RuleCreateCommand): Promise<void> {
      return this.repository.insert(command.getModel())
    }
}
