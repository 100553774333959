import { Nullable } from '@/core/domain/type/types'

export default class TopicUpdateCommand
{
    private guid: string;
    private code: string;
    private name: string;
    private description: Nullable<string>;
    private type: string;
    private params: Nullable<Array<string>>;

    constructor(guid: string, code: string, name: string, description: Nullable<string>, type: string, params: Nullable<Array<string>>)
    {
        this.guid = guid;
        this.code = code;
        this.name = name;
        this.description = description;
        this.type = type;
        this.params = params;
    }

    getClassName(): string
    {
        return 'TopicUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getCode(): string
    {
        return this.code;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getType(): string
    {
        return this.type;
    }

    getParams(): Nullable<Array<string>>
    {
        return this.params;
    }
}