import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ConditionFieldTypesQuery from '@/services/LogicEditor/application/query/ConditionFieldTypesQuery'
import StateQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/StateQueryRepositoryInterface'

export default class ConditionFieldTypesHandler implements QueryHandlerInterface {
  private repository: StateQueryRepositoryInterface

  constructor (repository: StateQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: ConditionFieldTypesQuery): Promise<any> {
    return this.repository.getConditionFieldTypes()
  }
}
