import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TaskUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private cronTime: Nullable<object>
  private isCronTask: boolean
  private code: Nullable<string>
  private extractorId: number
  private loaderId: number
  private isVisibleInRegistry: boolean

  constructor (
    guid: string,
    name: string,
    isCronTask: boolean,
    extractorId: number,
    loaderId: number,
    isVisibleInRegistry: boolean,
    cronTime: Nullable<object>,
    code: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.isCronTask = isCronTask
    this.extractorId = extractorId
    this.loaderId = loaderId
    this.isVisibleInRegistry = isVisibleInRegistry
    this.cronTime = cronTime
    this.code = code
  }

  getClassName (): string {
    return 'TaskUpdateCommand'
  }

  getName (): string {
    return this.name
  }

  getGuid (): string {
    return this.guid
  }

  getCronTime (): Nullable<object> {
    return this.cronTime
  }

  getIsCronTask (): boolean {
    return this.isCronTask
  }

  getCode (): Nullable<string> {
    return this.code
  }

  getExtractorId (): number {
    return this.extractorId
  }

  getLoaderId (): number {
    return this.loaderId
  }

  getIsVisibleInRegistry (): boolean {
    return this.isVisibleInRegistry
  }
}
