<template>
  <div style="height: 100%">
    <div class="tool_box" style="display: flex">
      <el-button icon="el-icon-refresh-right" size="small" plain @click="refresh(null)">{{ $t('main.button.update') }}</el-button>
      <el-tooltip slot="reference" disabled>
        <el-dropdown placement="bottom-start" trigger="click" @command="addEntity($event)">
          <el-button icon="el-icon-plus" size="small" style="margin-left: 10px;" plain>{{ $t('main.button.add') }}</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="type" v-for="(translate, type) in root_elements" :key="type">{{ translate }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-tooltip>
      <el-upload
        :auto-upload="false"
        :multiple="false"
        :show-file-list="false"
        :on-change="importStructure"
        action=""
        accept="application/json"
        style="margin-left: 10px">
        <el-button icon="el-icon-upload2" size="small" plain>{{ $t('main.button.import') }}</el-button>
      </el-upload>
    </div>
    <el-tree
      ref="request_tree"
      v-loading="loading"
      class="request-tree"
      node-key="guid"
      :data="data"
      :props="tree_props"
      :expand-on-click-node="false"
      :load="loadNode"
      @node-click="handleSelected"
      lazy
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <b>{{ data.name }}</b>
          <span class="request-node-element-type">{{ request_types[data.element_type] }}</span>
          <span> (id - {{ data.id }})</span>
        </span>
        <span v-if="selectedNodeData.id === data.id">
          <el-dropdown
            v-if="selectedNodeData.is_leaf === false"
            trigger="click"
            @command="addEntity($event)"
            style="margin-right: 10px">
            <el-button
              type="text"
              size="small">
              <i class="el-icon-plus">{{ $t('main.button.add') }}</i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="type" v-for="(translate, type) in request_types" :key="type">
                {{ translate }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-if="selectedNodeData.element_type === 'group'" type="text" size="small" style="color:grey" @click="exportEntity(node)" slot="reference">
            <i class="el-icon-download">{{ $t('main.button.export') }}</i>
          </el-button>
          <el-button type="text" size="small" style="color:red" @click="deleteEntity(node)" slot="reference">
            <i class="el-icon-delete el-icon--left">{{ $t('main.button.delete') }}</i>
          </el-button>
        </span>
      </span>
    </el-tree>
    <modal-window :active.sync="showForm" show-close-icon>
      <RequestForm
        :label="request_types[addingForm.type]"
        :requestType="addingForm.type"
        :parent="selectedNodeData"
      />
    </modal-window>
  </div>
</template>
<script>

import RequestsQuery from '@/services/ApiService/application/query/RequestsQuery'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import RequestForm from '@/services/ApiService/infrastructure/components/RequestPanel/RequestForm'
import DeleteRequestCommand from '@/services/ApiService/application/command/DeleteRequestCommand'
import { RequestTreeDTO } from '@/services/ApiService/domain/model/Request/RequestTree'
import ExportStructureQuery from '@/services/ApiService/application/query/ExportStructureQuery'
import ImportStructureCommand from '@/services/ApiService/application/command/ImportStructureCommand'

export default {
  name: 'RequestPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  components: {
    ModalWindow,
    RequestForm
  },
  data () {
    return {
      loading: false,
      tree_props: {
        isLeaf: 'is_leaf',
        label: 'name'
      },
      selectedNodeData: {},
      selectedNode: {},
      root_elements: {
        'group': this.$t('api_service.request.tree_element_type.group')
      },
      request_types: {
        'group': this.$t('api_service.request.tree_element_type.group'),
        'project': this.$t('api_service.request.tree_element_type.project'),
        'request': this.$t('api_service.request.tree_element_type.request')
      },
      showForm: false,
      addingForm: {
        name: null,
        type: null
      },
      data: [],
      showDeleteWindow: false
    }
  },
  async mounted () {
    this.getEventBus().$on('refresh', (node) => {
      this.refresh(node)
    })
    this.getEventBus().$on('showWindow', (value) => {
      this.showForm = value
    })
  },
  methods: {
    async getRequests () {
      return this.getQueryBus().execute(
        new RequestsQuery()
      )
    },
    async loadNode (node, resolve) {
      this.loading = true
      let data = []
      if (node.level === 0) {
        data = await this.getQueryBus().execute(new RequestsQuery())
      } else {
        data = await this.getQueryBus().execute(new RequestsQuery({
          parent_id: node.data.id
        }))
      }
      this.loading = false
      resolve(data)
    },
    async handleSelected (data, node) {
      this.selectedNodeData = data
      this.selectedNode = node
      this.getEventBus().$emit('show_request_form', node)
    },
    addEntity (type) {
      this.addingForm.type = type
      this.showForm = true
    },
    async refresh (node) {
      this.selectedNode = {}
      this.loading = true
      if (node) {
        let nodeGuid = node?.data?.guid ? node?.data?.guid : node.guid
        let currentParentNode = await this.$refs.request_tree.getNode(nodeGuid)
        if (currentParentNode) {
          currentParentNode.loaded = false
          currentParentNode.loadData()
        } else {
          this.data = await this.loadTreeRootLevel()
        }
      } else {
        this.data = await this.loadTreeRootLevel()
      }
      this.loading = false
    },
    async deleteEntity (entity) {
      this.$confirm(this.$t('api_service.dialog.delete_node_message'), this.$t('api_service.dialog.delete_node_title'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(async () => {
        await this.getCommandBus().execute(new DeleteRequestCommand(
          new RequestTreeDTO(entity.data)
        ))
        await this.refresh(entity.parent.data)
      }).catch(() => {})
    },
    async exportEntity (entity) {
      this.$confirm(this.$t('api_service.dialog.export_message'), this.$t('api_service.dialog.export_title'), {
        confirmButtonText: this.$t('main.button.export'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'info'
      }).then(async () => {
        let exportStructure = await this.getQueryBus().execute(new ExportStructureQuery(entity.data.id))

        let content = JSON.stringify(exportStructure.data)

        const link = document.createElement('a')
        const file = new Blob([content], { type: 'text/plain' })
        link.href = URL.createObjectURL(file)
        link.download = 'api_service_export.json'
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(() => {})
    },
    async importStructure (file) {
      let reader = new FileReader()
      reader.readAsText(file.raw)

      let payload = await ((reader) => {
        return new Promise((resolve) => {
          reader.onload = () => {
            resolve(JSON.parse(reader.result))
          }
        })
      })(reader)

      await this.getCommandBus().execute(new ImportStructureCommand(
        payload
      ))
      await this.refresh(null)
    },
    async loadTreeRootLevel () {
      return this.getQueryBus().execute(new RequestsQuery())
    }
  }
}
</script>

<style scoped lang="scss">
  .request-tree {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .request-node-element-type {
    color: #7C838B;
    padding-left: 6px;
  }
</style>
