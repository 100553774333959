import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ProcedureUpdateCommand from '@/services/LogicEditor/application/command/ProcedureUpdateCommand'
import ProcedureCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ProcedureCommandRepositoryInterface'

export default class ProcedureUpdateHandler implements CommandHandlerInterface {
  private repository: ProcedureCommandRepositoryInterface

  constructor (repository: ProcedureCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ProcedureUpdateCommand): Promise<void> {
    return this.repository.update(command.getDTO())
  }
}
