<template>
  <el-form-item :label="label">
    <el-select v-model="localModel"
               clearable
               @clear="$emit('input', null)"
               @change="$emit('input', localModel)">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.level"
      >
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>

  export default {
    name: 'object_level',
    props: ['value', 'entity-id', 'entity-type-id', 'properties', 'label'],
    data () {
      return {
        localModel: this.value,
        options: []
      }
    },
    async mounted () {
      let data = await this.$http.get(`${this.$config.api}/garaddressservice/address_levels`)
      this.options = data.data
    }
  }
</script>

<style scoped>

</style>
