import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetNotificationById } from '@/services/NotificationEditor/infrastructure/store/modules/Notification/action-types'
import NotificationQueryRepositoryInterface from '@/services/NotificationEditor/domain/repository/NotificationQueryRepositoryInterface'

export default class NotificationQueryRepository extends AbstractQueryRepository implements NotificationQueryRepositoryInterface {
  getById (id: number): Promise<any> {
    return store.dispatch(new GetNotificationById(id))
  }
}
