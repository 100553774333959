import CommandInterface from '@/core/application/command/CommandInterface'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'

export default class UpdateRequestParameterCommand implements CommandInterface {
  private dto: RequestParameter;

  constructor (dto: RequestParameter) {
    this.dto = dto
  }

  getClassName (): string {
    return 'UpdateRequestParameterCommand'
  }

  getDto (): RequestParameter {
    return this.dto
  }
}
