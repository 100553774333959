import { Nullable } from '@/core/domain/type/types'

export default class RouteCreateCommand
{
    private topic: string;
    private taskId?: Nullable<number>;
    private fileName?: Nullable<string>;
    private priority?: Nullable<number>;
    private type: string;
    private commands?: Nullable<Array<number>>;
    private properties: object;
    private isBigData: Nullable<boolean>;

    constructor(topic: string, taskId: Nullable<number>, fileName: Nullable<string>, priority: Nullable<number>, type: string, commands: Nullable<Array<number>>, properties: object, isBigData: Nullable<boolean>)
    {
        this.topic = topic;
        this.taskId = taskId;
        this.fileName = fileName;
        this.priority = priority;
        this.type = type;
        this.commands = commands;
        this.properties = properties;
        this.isBigData = isBigData;
    }

    getClassName(): string
    {
        return 'RouteCreateCommand';
    }

    getTopic(): string
    {
        return this.topic;
    }

    getTaskId(): Nullable<number>
    {
        return this.taskId;
    }

    getFileName(): Nullable<string>
    {
        return this.fileName;
    }

    getPriority(): Nullable<number>
    {
        return this.priority;
    }

    getType(): string
    {
        return this.type;
    }

    getCommands(): Nullable<Array<number>>
    {
        return this.commands;
    }

    getProperties(): object
    {
        return this.properties;
    }

    getIsBigData(): Nullable<boolean>
    {
        return this.isBigData;
    }
}