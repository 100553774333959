import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'

export namespace MonitoringAPI {
  export class GetProcessInstances implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => {
      return data?.data?.map((item: any) => {
        const variables = item.variables || '[]'
        if (variables) {
          try {
            item.variables = JSON.parse(variables)
          } catch (e) {}
        }

        return item
      })
    }

    constructor (limit: number = 10, offset: number = 0, process_key: string = null, variables: any[] = []) {
      const payload = {
        limit: limit.toString(),
        offset: offset.toString(),
        order: 'start_time_:desc'
      }
      if (variables.length > 0) {
        payload['variables'] = variables.join(',')
      }
      if (process_key) {
        payload['proc_def_key_'] = `process_${process_key}`
      }
      this.path = `/bpmngatewayservice/processes?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetProcessInstancesCount implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor (process_key: string = null) {
      const payload = {
        '*[func]': 'count'
      }
      if (process_key) {
        payload['proc_def_key_'] = `process_${process_key}`
      }
      this.path = `/bpmngatewayservice/processes?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetSchema implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor (schema_name: string) {
      this.path = `/bpmngatewayservice/schema/${schema_name}`
    }
  }

  export class GetDeployments implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data

    constructor (processId: number) {
      this.path = `/bpmngatewayservice/deployments/${processId}`
    }
  }

  export class GetProcessTimers implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string, limit: number = 10, offset: number = 0) {
      const payload = {
        process_instance_id_: processId,
        limit: limit.toString(),
        offset: offset.toString(),
        order: 'timestamp_:desc'
      }

      this.path = `/bpmngatewayservice/jobs?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetProcessTimersCount implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string) {
      const payload = {
        process_instance_id_: processId
      }
      this.path = `/bpmngatewayservice/jobs?${new URLSearchParams(payload).toString()}&*[func]=count`
    }
  }

  export class GetProcessActivities implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string, limit: number = 10, offset: number = 0) {
      const payload = {
        proc_inst_id_: processId,
        limit: limit.toString(),
        offset: offset.toString(),
        order: 'sequence_counter_:desc'
      }

      this.path = `/bpmngatewayservice/activites?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetProcessActivitiesCount implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string) {
      const payload = {
        proc_inst_id_: processId
      }
      this.path = `/bpmngatewayservice/activites?${new URLSearchParams(payload).toString()}&*[func]=count`
    }
  }

  export class GetProcessVariables implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string, limit: number = 10, offset: number = 0) {
      const payload = {
        proc_inst_id_: processId,
        limit: limit.toString(),
        offset: offset.toString(),
        order: 'create_time_:desc'
      }

      this.path = `/bpmngatewayservice/variables?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetProcessVariablesCount implements APIRequest<any[]> {
    response: any[];
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (processId: string) {
      const payload = {
        proc_inst_id_: processId
      }
      this.path = `/bpmngatewayservice/variables?${new URLSearchParams(payload).toString()}&*[func]=count`
    }
  }
}
