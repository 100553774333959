<template>
  <el-upload
    ref="upload"
    class="image-uploader"
    action="#"
    :auto-upload="false"
    :multiple="false"
    :show-file-list="false"
    :file-list="fileList"
    :on-change="changeImage"
    :on-remove="removeImage"
    :accept="accept"
  >
    <img v-if="showImage" :src="image" class="image" alt="" />

    <i v-else class="el-icon-plus image-uploader-icon"></i>
  </el-upload>
</template>

<script>
export default {
  name: 'ImageUpload',

  props: {
    value: [String, Number],

    type: {
      type: String,
      default: 'base64',
      validate (value) {
        return ['base64', 'registry'].includes(value)
      }
    },

    imageName: {
      type: String,
      default: null
    },

    accept: {
      type: String,
      default: 'image/png, image/jpeg'
    }
  },

  computed: {
    action () {
      const actions = {
        base64: '#',
        registry: `${this.$config.api}/registryservice/files`
      }

      return actions[this.type] || null
    },

    showImage () {
      return !!this.image
    },

    image () {
      return this.type === 'base64'
        ? this.value
        : (this.filename ? `${this.$config.api}/files/${this.filename}` : null)
    }
  },

  data () {
    return {
      model: null,

      fileList: [],

      filename: this.imageName
    }
  },

  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    // numberFiles (file, fileList) {
    //   if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
    //     this.$refs.upload.uploadFiles = []
    //     this.$message.error(this.$locale.access_editor.users_item.error_avatar)
    //     return false
    //   }
    // },

    removeImage () {
      this.$emit('input', null)
    },

    changeImage (file) {
      if (this.type === 'base64') {
        this.toBase64(file)
          .then((response) => {
            this.$emit('input', response)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.uploadFile(file)
          .then((file) => {
            this.$emit('input', file.id)
            this.filename = file.filename
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },

    toBase64 (file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader()
          reader.readAsDataURL(file.raw)
          reader.addEventListener('load', () => {
            return resolve(reader.result)
          }, { once: true })
        } catch (error) {
          reject(error)
        }
      })
    },

    uploadFile (file) {
      return new Promise((resolve, reject) => {
        try {
          const fd = new FormData()
          fd.append('file', file.raw)
          this.$http
            .post(this.$config.api + '/registryservice/files', fd, { hideNotification: true })
            .then((res) => {
              resolve(res.data)
            })
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .image-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      width: 150px;
      height: 150px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background-color: #efefef;

      &:hover {
        border-color: #409EFF;
      }
    }

    .image-uploader-icon {
      font-size: 28px;
      color: #8c939d;
    }

    .image {
      object-fit: contain;
      width: 100%;
      display: block;
    }
  }
</style>
