
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'

library.add(faGripLines)

export default Vue.extend({
  name: 'ExtraTask',

  props: {
    value: Object
  },

  components: {
    FontAwesomeIcon
  }
})
