import store from '@/store'
import GroupCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/GroupCommandRepositoryInterface'
import GroupDTO from '@/services/NotificationEditor/domain/model/GroupDTO'
import {
  CreateGroup,
  DeleteGroup,
  UpdateGroup
} from '@/services/NotificationEditor/infrastructure/store/modules/NotificationGroup/action-types'

export default class GroupCommandRepository implements GroupCommandRepositoryInterface {
  insert (item: GroupDTO): Promise<void> {
    return store.dispatch(new CreateGroup(item))
  }

  update (item: GroupDTO): Promise<void> {
    return store.dispatch(new UpdateGroup(item))
  }

  delete (id: number): Promise<void> {
    return store.dispatch(new DeleteGroup(id))
  }
}
