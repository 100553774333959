
import DeploymentsQuery from '@/services/ReleaseEditor/application/query/DeploymentsQuery'


export default {
  name: 'DeploymentsPanel',
  data () {
    return {
      loading: false,
      deployments: [],
      deployment: null,
      deploymentDto: null,
      deploymentDtoPrev: null,
      isDuplicate: false,
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    isDeploymentLoading: (state) => state
  },
  computed: {
    isDeploymentLoading() {
      return this.$store.getters['Deployment/isLoading'];
    }
  },
  methods: {
    async loadDeployments(callback) {
      await this.getQueryBus().execute(
        new DeploymentsQuery({})
      ).then(data => {
        this.deployments = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  },
  mounted() {
    this.loadDeployments();
  }
}
