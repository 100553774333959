import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TransformerDTO } from '@/services/EtlEditor/domain/model/Transformer'
import TransformerUpdateCommand from '@/services/EtlEditor/application/command/TransformerUpdateCommand'
import TransformerCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerCommandRepositoryInterface'

export default class TransformerUpdateHandler implements CommandHandlerInterface {
    private repository: TransformerCommandRepositoryInterface;

    constructor (repository: TransformerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TransformerUpdateCommand): Promise<void> {
      let item: TransformerDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        transformer_type_id: command.getTransformerTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties()
      }

      return this.repository.update(item)
    }
}
