import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace GarTableAPI {

  export class GetGarTables implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    params: string[][]
    parse = (data: AxiosResponse) => data.data
    constructor (payload: string[][]) {
      this.path = '/garaddressservice/gar_tables'
      this.params = payload
    }
  }
}
