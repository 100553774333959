import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import DeploymentQueryRepositoryInterface from '@/services/ReleaseEditor/domain/repository/DeploymentQueryRepositoryInterface'
import store from '@/store'
import { GetDeployments } from '@/services/ReleaseEditor/infrastructure/store/modules/Deployment/action-types'

export default class DeploymentQueryRepository extends AbstractQueryRepository implements DeploymentQueryRepositoryInterface
{
    get(payload: string[][] | object): Promise<any>
    {
        return store.dispatch(new GetDeployments(payload));
    }
}