import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ConstraintDeleteCommand from '@/services/LogicEditor/application/command/ConstraintDeleteCommand'
import ConstraintCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintCommandRepositoryInterface'

export default class ConstraintDeleteHandler implements CommandHandlerInterface {
    private repository: ConstraintCommandRepositoryInterface;

    constructor (repository: ConstraintCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ConstraintDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
