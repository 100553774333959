import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { GroupDTO } from '@/services/EtlEditor/domain/model/Group'
import GroupCreateCommand from '@/services/EtlEditor/application/command/GroupCreateCommand'
import GroupCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/GroupCommandRepositoryInterface'

export default class GroupCreateHandler implements CommandHandlerInterface {
    private repository: GroupCommandRepositoryInterface;

    constructor (repository: GroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: GroupCreateCommand): Promise<void> {
      let item: GroupDTO = {
        name: command.getName(),
        alias: command.getAlias(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
