import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetLogicTreeElements, GetLogicTreeElementsTree, SearchLogicTreeElements, GetLogicTreeElementByGuid, GetLogicTreeElementByElementGuid } from '@/services/LogicEditor/infrastructure/store/modules/LogicTreeElement/action-types'
import LogicTreeElementQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicTreeElementQueryRepositoryInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LogicTreeElementQueryRepository extends AbstractQueryRepository implements LogicTreeElementQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetLogicTreeElements(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetLogicTreeElementByGuid(guid))
  }

  getByElementGuid (elementGuid: string): Promise<any> {
    return store.dispatch(new GetLogicTreeElementByElementGuid(elementGuid))
  }

  getTree (elementType: string, logicId?: Nullable<number>): Promise<any> {
    let payload: any = { element_type: elementType }

    if (logicId !== null) {
      payload = { element_type: elementType, logic_id: logicId }
    }

    return store.dispatch(new GetLogicTreeElementsTree(payload))
  }

  search (payload: object): Promise<any> {
    return store.dispatch(new SearchLogicTreeElements(payload))
  }
}
