<template>
  <div class="select_ref_field">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-scrollbar class="scroll">
          <el-tree
            ref="tree"
            lazy
            node-key="id"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandedKeys"
            :load="loadNode"
            :props="props">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <b>[{{ $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id }}]</b>
                {{ node.label.length > 32 ? node.label.substring(0, 32) + '...' : node.label }}
                {{node.key? '(id: '+node.key+')' : ''}}
              </span>
              <span v-if="['field_group', 'button_field'].indexOf(data.entity_type_id) === -1">
                <el-button type="text" @click="addField(node)">{{ $locale.main.button.add }}</el-button>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </el-col>
      <el-col :span="12">
        <el-scrollbar class="scroll">
          <el-tree
            ref="rightTree"
            :data="value"
            :props="{ label: 'field_alias' }"
            draggable
            :allow-drag="allowDrag"
            :allow-drop="allowDrop"
            @node-drop="nodeDrop">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <b>[{{ $locale.object_editor.entity_types[node.data.entity_type_id] || node.data.entity_type_id }}]</b>
                {{ node.label.length > 32 ? node.label.substring(0, 32) + '...' : node.label }}{{node.data.field_id? ' (id: ' + node.data.field_id + ')':''}}
              </span>
              <span>
                <el-popover
                  placement="left"
                  width="400"
                  trigger="click">
                  <el-form ref="form" class="select_ref_field__form" :model="data" @submit.native.prevent="() => { return false }" :rules="rules" label-position="top" size="mini">
                    <el-form-item :label="$locale.logic_editor_v2.labels.field_alias" prop="field_alias">
                      <el-input v-model="data.field_alias" :placeholder="$locale.logic_editor_v2.labels.field_alias" @blur="aliasBlur(data)" @focus="aliasFocus(data)"></el-input>
                    </el-form-item>
                  </el-form>
                  <el-button type="text" slot="reference">{{ $locale.main.button.edit }}</el-button>
                </el-popover>
                <el-button class="text_danger" type="text" @click="removeField(node)">{{ $locale.main.button.delete }}</el-button>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Entity from '@/components/ObjectEditor/Models/Entity'

export default {
  name: 'SelectRefField',

  props: ['value', 'objectId'],

  computed: {
    defaultExpandedKeys () {
      let expanded = []

      this.model.forEach(item => {
        expanded.push(item.field_id)

        if (item.xref_field_id) {
          if (expanded.findIndex(value => value === item.xref_field_id) === -1) {
            expanded.push(item.xref_field_id)
          }
        }
      })

      return expanded
    }
  },

  data () {
    let validateAlias = (rule, value, callback) => {
      let model = this.model.find(item => item.name === value)

      if (model) {
        callback(new Error('The alias name must not be repeated'))
      } else {
        callback()
      }
    }

    return {
      model: this.value ? this.value : [],

      props: {
        label: 'name',
        isLeaf: 'leaf'
      },

      rules: {
        field_alias: [
          { required: true, message: this.$locale.logic_editor_v2.rules.alias, trigger: 'change' },
          { validator: validateAlias, trigger: 'change' }
        ]
      },

      fieldEdit: null,

      availableEntityType: [
        'xref_field',
        'xref_outer_field',
        'xref_multi_field'
      ]
    }
  },

  methods: {
    addField (node) {
      let xref_field_id = null
      if (typeof node.parent.data !== 'undefined') {
        xref_field_id = node.parent.data.id
        if (node.parent.data.entity_type_id === 'field_group') {
          if (node.parent.level === 2) {
            xref_field_id = node.parent.parent.data.id
          } else {
            xref_field_id = null
          }
        }
      }
      this.model.push({
        xref_field_id: xref_field_id,
        field_id: node.data.id,
        entity_type_id: node.data.entity_type_id,
        field_alias: node.data.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, ''),
        row_order: (this.model.length + 1) * 1000
      })
    },

    removeField (node) {
      this.$refs.rightTree.remove(node)

      this.model.forEach((item, index) => {
        item.row_order = (index + 1) * 1000
      })
    },

    aliasFocus (field) {
      this.fieldEdit = field.field_alias
    },

    aliasBlur (field) {
      if (field.field_alias === null || field.field_alias === '') {
        field.field_alias = this.fieldEdit
      }
    },

    allowDrop (draggingNode, dropNode, type) {
      return type !== 'inner'
    },

    allowDrag (draggingNode) {
      return true
    },

    nodeDrop (draggingNode, dropNode, dropType, ev) {
      if (dropType !== 'inner') {
        this.model.forEach((item, index) => {
          item.row_order = (index + 1) * 1000
        })
      }
    },

    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities(this.objectId, node))
      } else {
        if (this.availableEntityType.indexOf(node.data.entity_type_id) !== -1) {
          let response = {}
          response = await Entity.$find(node.data.id)
          let propertyFieldId
          response.properties.forEach(item => {
            if (item.id === 'xref') {
              propertyFieldId = item.value !== null ? parseInt(item.value) : null
            }
          })
          if (propertyFieldId !== null) {
            response = await Entity.$find(propertyFieldId)

            resolve(await this.loadEntities(response.object_id, node))
          } else {
            console.warn('Не проставлена ссылка!')
          }
        } else {
          resolve(await this.loadEntities(node.data.id, node))
        }
      }
    },

    async loadEntities (entityId, node) {
      let data = []

      data = await new Entity({ id: entityId }).children().$get()

      data.forEach(entity => {
        if (node.data) {
          entity.key = `${node.data.id}_${entity.id}`
        } else {
          entity.key = `${entity.id}`
        }

        if (this.availableEntityType.indexOf(entity.entity_type_id) !== -1) {
          entity.leaf = false
        }

        entity.properties.forEach(property => {
          if (property.id === 'xref' && property.value === null) {
            entity.leaf = true
          }
        })
      })

      return data
    }
  }
}
</script>

<style lang="scss">
  .select_ref_field {
    border-radius: 4px;

    & .scroll {
      height: 300px !important;
      padding-bottom: 17px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
    }

    & .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    & span+.el-button {
      margin-left: 10px;
    }

    &__form .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
</style>
