<template>
  <el-form-item :label="label">
    <div class="unique_fields">
      <el-button size="small" class="add_condition" @click="addCondition">
        {{$locale.object_editor.unique_fields.add}}
      </el-button>
      <div class="condition" v-for="(condition, index) in localModel" :key="index">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" >
            <el-form-item :label="$locale.object_editor.unique_fields.field">
              <treeselect
                ref="entity_tree"
                style="margin-right: 5px"
                :multiple="true"
                :dataTable="listField"
                :value="condition.fields"
                @input="condition.fields = $event"
              />
            </el-form-item>
          </el-col>
          <span>{{$locale.object_editor.unique_fields.type}}</span>
          <el-col :xs="24" :sm="24" :md="24" :lg="8">
            <el-select v-model="condition.is_forbidden" size="medium">
              <el-option
                v-for="(item, index) in isForbidden"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row type="flex" align="bottom">
          <el-col :span="16">
            <el-form-item :label="$locale.interface_editor.component_editor.editor_control_values.text_user">
              <el-input type="textarea" :rows="2" v-model="condition.message"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-button @click="deleteCondition(index)" size="small" type="danger">
              {{$locale.main.button.delete}}
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-form-item>
</template>

<script>
import Treeselect from '@/core/infrastructure/components/TreeSelect'
import Registry from '@/components/Models/EntityModel'
export default {
  name: 'unique_fields',

  props: ['value', 'entity-id', 'entity-type-id', 'properties', 'label'],

  components: { Treeselect },

  data () {
    return {
      localModel: [],
      listField: [],
      fields: null,
      isForbidden: [
        {
          name: this.$locale.object_editor.unique_fields.forbid,
          value: true
        },
        {
          name: this.$locale.object_editor.unique_fields.notify,
          value: false
        }
      ]
    }
  },

  watch: {
    localModel: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    addCondition () {
      this.localModel.push({
        fields: null,
        is_forbidden: false,
        message: null
      })
    },

    deleteCondition (index) {
      this.localModel.splice(index, 1)
    }
  },

  async mounted () {
    try {
      this.localModel = JSON.parse(this.value)
    } catch (error) {
      this.localModel = this.value
    }
    // список полей реестра ввиде дерева
    let registryTree = await Registry.params({ parent_id: this.entityId, show_children: true }).get()
    this.listField = registryTree.data
  }
}
</script>

<style scoped>
  .unique_fields .add_condition {
    display: block;
    margin-bottom: 10px;
  }

  .unique_fields .condition {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border-color: #ddd;
    border-left: 2px solid #8bc34a;
    padding: 15px;
  }
</style>
