<template >
  <div class="editor_box menu_editor">
    <el-container>
      <el-header height="">
        {{ $locale.menu_editor.main.title }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box" style="justify-content: space-between; display: flex; flex-wrap: wrap;">
              <el-popover
                placement="bottom-left"
                width="260"
                trigger="click"
                v-model="dialogFormVisible">
                <el-form :model="form" size="mini" label-width="90px" labelPosition="right" class="menuEditor">
                  <el-form-item :label="$locale.menu_editor.label.name" class="required">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item :label="$locale.menu_editor.label.description">
                    <el-input type="textarea" :rows="2" v-model="form.description" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item :label="$locale.menu_editor.label.width" class="required">
                    <el-input v-model="form.width" autocomplete="off"></el-input>
                  </el-form-item>
                  <div style="display: flex; justify-content: flex-end;">
                    <el-button size="small" @click="dialogFormVisible = false">
                      {{$locale.menu_editor.button.cancel}}
                    </el-button>
                    <el-button
                      type="primary"
                      size="small"
                      :disabled="disabledBtn"
                      @click="addMenu">
                      {{$locale.menu_editor.button.save}}
                    </el-button>
                  </div>
                </el-form>
                <el-tooltip
                  slot="reference"
                  class="item"
                  effect="dark"
                  :content="$locale.menu_editor.tooltip.add_menu"
                  placement="bottom">
                  <el-button size="small" icon="el-icon-plus">
                    {{ $locale.main.button.add }}
                  </el-button>
                </el-tooltip>
              </el-popover>
            </div>
            <el-scrollbar class="tree_scroll">
              <el-tree
                ref="tree"
                lazy
                draggable
                :props="props"
                :load="loadNode"
                node-key="id"
                :expand-on-click-node="false"
                @node-drop="moveRow"
                :allow-drag="allowDrag"
                :allow-drop="allowDrop">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span :class="{ 'selected-node': editorModel && (editorModel.id === data.id) }" style="width: 100%;" @click="openEditor(data, node)">
                    <span style="font-size: 16px;" :class="getTreeIcon(node)"></span> {{ node.label }}
                  </span>
                  <span>
                    <el-button
                      v-if="data.menu_type_id !== 'title' && data.menu_type_id !== 'separator'"
                      :title="$locale.main.button.add"
                      class="btn_visible"
                      size="mini"
                      icon="el-icon-plus"
                      circle
                      @click="append(data)">
                    </el-button>
                    <el-button
                      v-show="data.id !== 420"
                      :title="$locale.main.button.delete"
                      class="btn_visible"
                      size="mini"
                      type="danger"
                      icon="el-icon-minus"
                      circle
                      @click="remove(node, data)">
                    </el-button>
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <div v-if="editorModel !== null" class="tool_box">
              <div class="tool_header">
                {{ formTitle }}
              </div>
            </div>
            <component
              v-if="editorModel"
              v-model="editorModel"
              :users-table-object-id="usersTableObjectId"
              :key="editorModel.id"
              :save="save"
              :is="typeComponent"
              :activeNode="activeNode">
            </component>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import RootMenuForm from './components/RootMenuForm'
import ChildMenuForm from './components/ChildMenuForm'
import Menu from './Models/Menu'
import Node from 'element-ui/packages/tree/src/model/node'
import Entity from '@/components/ObjectEditor/Models/Entity'

export default {
  name: 'MenuEditor',

  components: {
    RootMenuForm,
    ChildMenuForm
  },

  computed: {
    disabledBtn () {
      return !this.form.name || !this.form.width
    }
  },

  watch: {
    dialogFormVisible: function (val) {
      this.form = {
        name: null,
        description: null,
        width: null
      }
    },

    'form.width': function (newValue) {
      if (typeof newValue === 'string') {
        const result = newValue.replace(/[^0-9]/g, '')
        this.$nextTick(function () {
          this.form.width = result
        })
      }
    }
  },

  data () {
    return {
      activeNode: null,
      typeComponent: null,
      editorModel: null,
      dialogFormVisible: false,
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'child'
      },
      form: {
        name: null,
        properties: []
      },
      formTitle: null,
      usersTableObjectId: null
    }
  },

  async mounted () {
    let usersTable = await new Entity().params({ properties: { is_users_table: true } }).$get()
    this.usersTableObjectId = usersTable[0].id
  },

  methods: {
    getTreeIcon (node) {
      if (!node.isLeaf && !node.expanded) {
        return 'el-icon-folder'
      } else if (!node.isLeaf && node.expanded) {
        return 'el-icon-folder-opened'
      }

      if (node.data.menu_type_id === 'dashboard') {
        return 'el-icon-s-data'
      } else if (node.data.menu_type_id === 'object') {
        return 'el-icon-s-grid'
      } else if (node.data.menu_type_id === 'menu') {
        return 'el-icon-menu'
      } else if (node.data.menu_type_id === 'editor') {
        return 'el-icon-edit-outline'
      } else if (node.data.menu_type_id === 'report') {
        return 'el-icon-document'
      } else if (node.data.menu_type_id === 'url') {
        return 'el-icon-link'
      }

      return 'el-icon-d-arrow-right'
    },

    async save (type = null) {
      const menu = new Menu(this.editorModel)
      menu.properties = this.editorModel.properties

      let description = menu.properties.find(property => property.id === 'description')
      let width = menu.properties.find(property => property.id === 'width')
      let collapse = menu.properties.find(property => property.id === 'is_collapsed')
      let logoSize = menu.properties.find(property => property.id === 'logo_size')
      let miniLogoSize = menu.properties.find(property => property.id === 'mini_logo_size')
      let logo = menu.properties.find(property => property.id === 'logo_id')
      let miniLogo = menu.properties.find(property => property.id === 'mini_logo_id')

      if (description) {
        description.value = menu.description
      } else {
        menu.properties.push({
          'id': 'description',
          'type': 'varchar',
          'value': menu.description
        })
      }

      if (width) {
        width.value = parseInt(menu.width)
      } else {
        menu.properties.push({
          'id': 'width',
          'type': 'integer',
          'value': parseInt(menu.width)
        })
      }

      if (collapse) {
        collapse.value = menu.is_collapsed
      } else {
        menu.properties.push({
          'id': 'is_collapsed',
          'type': 'boolean',
          'value': false
        })
      }

      if (logoSize) {
        logoSize.value = menu.logo_size
      } else {
        menu.properties.push({
          'id': 'logo_size',
          'type': 'varchar',
          'value': menu.logo_size
        })
      }

      if (logo) {
        logo.value = menu.logo_id
      } else {
        menu.properties.push({
          'id': 'logo_id',
          'type': 'image',
          'value': menu.logo_id
        })
      }

      if (miniLogo) {
        miniLogo.value = menu.mini_logo_id
      } else {
        menu.properties.push({
          'id': 'mini_logo_id',
          'type': 'image',
          'value': menu.mini_logo_id
        })
      }

      if (miniLogoSize) {
        miniLogoSize.value = menu.mini_logo_size
      } else {
        menu.properties.push({
          'id': 'mini_logo_size',
          'type': 'varchar',
          'value': menu.mini_logo_size
        })
      }

      let response = await menu.save()
      this.$set(this.$refs.tree.getCurrentNode(), 'name', response.name)
      this.formTitle = response.name
    },

    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities())
      } else {
        resolve(await this.loadEntities(node.data.id))
      }
    },

    async loadEntities (entityId = null) {
      let data = []
      if (!entityId) {
        data = await new Menu().params({ root: true }).$get()
      } else {
        data = await new Menu({ id: entityId }).children().$get()
      }

      return data
    },

    async openEditor (data, node) {
      if (!data) {
        return false
      }
      let menu = await Menu.$find(data.id)
      if (menu) {
        this.activeNode = node
        this.editorModel = menu
        this.formTitle = this.editorModel.name

        if (this.editorModel.properties.find(property => property.id === 'description')) {
          this.$set(this.editorModel, 'description', this.editorModel.properties.find(property => property.id === 'description').value)
        }
        if (this.editorModel.properties.find(property => property.id === 'width')) {
          this.$set(this.editorModel, 'width', this.editorModel.properties.find(property => property.id === 'width').value)
        }
        if (this.editorModel.properties.find(property => property.id === 'is_collapsed')) {
          this.$set(this.editorModel, 'is_collapsed', this.editorModel.properties.find(property => property.id === 'is_collapsed').value)
        }
        if (this.editorModel.properties.find(property => property.id === 'logo_size')) {
          this.$set(this.editorModel, 'logo_size', this.editorModel.properties.find(property => property.id === 'logo_size').value)
        }
        if (this.editorModel.properties.find(property => property.id === 'mini_logo_size')) {
          this.$set(this.editorModel, 'mini_logo_size', this.editorModel.properties.find(property => property.id === 'mini_logo_size').value)
        }

        data.parent_id === null ? (this.typeComponent = RootMenuForm) : (this.typeComponent = ChildMenuForm)
      }
    },

    // добавить меню
    async addMenu () {
      this.dialogFormVisible = false
      const menu = new Menu(this.form)
      menu.menu_type_id = 'menu'
      menu.description = this.form.description || null
      menu.width = +this.form.width
      menu.properties = []
      let response = await menu.save()
      let node = new Node({ parent: this.$refs.root, store: this.$refs.tree.store, data: response })
      node.level = 1
      this.$refs.tree.root.childNodes.push(node)
    },

    // добавить потомков
    async append (data) {
      const menu = new Menu({})
      menu.menu_type_id = 'object'
      menu.name = 'Новый пункт'
      menu.parent_id = data.id
      menu.properties = []
      let response = await menu.save()
      this.$refs.tree.append(response, data.id)
      let node = this.$refs.tree.getNode(data.id)
      node.isLeaf = false
    },

    remove (node, data) {
      if (!data.id) {
        return false
      }
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let menu = new Menu({ id: data.id })
        await menu.delete()
        this.$refs.tree.remove(data.id)
        // this.$refs.tree.remove(node.data.id)
      }).catch((error) => { console.log(error) })
    },

    allowDrop (draggingNode, dropNode, type) {
      // console.log('взятая строка parent_id', draggingNode.data.parent_id)
      // console.log('родитель', dropNode.parent.data.id)
      // console.log('type', type)
      if (draggingNode.data.parent_id === dropNode.parent.data.id) {
        if (dropNode.data.menu_type_id === 'separator' || dropNode.data.menu_type_id === 'title' || dropNode.data.menu_type_id === 'menu') {
          return type !== 'inner'
        } else if (type === 'inner') {
          return false
        }
        // console.log('равны')
        return true
      } else if (type === 'inner') {
        return false
      } else {
        // console.log('не равны')
        return false
      }
    },

    allowDrag (draggingNode) {
      //if (draggingNode.data.menu_type_id !== 'menu') return true
      return true;
    },

    async moveRow (draggingNode, dropNode) {
      let rowOrder = this.mediumRowOrder(draggingNode, dropNode)
      const menu = new Menu({ id: draggingNode.data.id })
      menu.menu_type_id = draggingNode.data.menu_type_id
      menu.row_order = rowOrder
      menu.name = draggingNode.data.name
      let response = await menu.save()
      console.log(response)
    },

    // средние значение row_order
    mediumRowOrder (draggingNode, node) {
      // children into parent
      const a = node.parent.childNodes
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          // console.log('совпадение', a[i].data.name, a[i].data.id)
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 330))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((100 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }

      return rowOrder
    },

    uploadIcon () {
      if (this.$refs.upload) {
        this.$msgbox({
          type: 'success',
          message: 'Иконка загружена'
        })
      }
    }
  }
}
</script>

<style scoped src="./menuEditor.css"></style>
