<template>
  <div class="component_form">
    <template v-for="item in properties">
      <component
        v-model="value[value.findIndex(prop => prop.id === item.id)].value"
        :key="item.id"
        size="mini"
        title-width="200px"
        :title="$t('etl_editor.form.fields.properties.' + item.name)"
        :registry-id="objectId"
        :is="isComponent(item.type)">
      </component>
    </template>
  </div>
</template>

<script>
import TypeBoolean from '../../Properties/Boolean'
import TypeFieldMulti from '../../Properties/FieldMulti'
import TypeInteger from '../../Properties/Integer'
import TypeObject from '../../Properties/Registry'
import TypeVarchar from '../../Properties/Varchar'
import TypeTemplate from '../../Properties/Template'
import TypeTopic from '../../Properties/Topic'
import TypeField from '../../Properties/Field'
import TypeFileField from '../../Properties/FileField'
import TypeQuery from '../../Properties/Query'
import TypeCoordinateSystem from '../../Properties/CoordinateSystem'
import TypeProcedure from '../../Properties/Procedure'

export default {
  name: 'ComponentForm',

  props: {
    value: Array,
    properties: Array
  },

  components: {
    TypeBoolean,
    TypeFieldMulti,
    TypeInteger,
    TypeObject,
    TypeVarchar,
    TypeTemplate,
    TypeTopic,
    TypeField,
    TypeFileField,
    TypeCoordinateSystem,
    TypeQuery,
    TypeProcedure
  },

  computed: {
    objectId () {
      let index = this.value.findIndex(prop => prop.id === 'object_id')

      if (index !== -1) {
        return this.value[index].value
      }

      return null
    }
  },

  data () {
    return {
      registryId: null
    }
  },

  methods: {
    isComponent (type) {
      let componentName = `Type${this.toCamelCase(type)}`

      return componentName in this.$options.components ? componentName : null
    },

    toCamelCase (s) {
      return s.replace(/^([a-z]{1})/g, ($1) => {
        return $1.toUpperCase()
      }).replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    }
  }
}
</script>
