<template>
  <el-dialog
    :title="this.$locale.interface_editor.toolbar.export_template"
    class="templateSql"
    :visible.sync="visibleTemplate"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <el-form :model="form">
      <el-form-item :label="this.$locale.main.label_input.key" class="required" :label-width="formLabelWidth">
        <el-input v-model="form.id" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="this.$locale.main.label_input.name" class="required" :label-width="formLabelWidth">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="this.$locale.main.label_input.description" :label-width="formLabelWidth">
        <el-input v-model="form.description" type='textarea' autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="this.$locale.main.label_input.tag" class="required" :label-width="formLabelWidth">
        <el-tag
          :key="tag"
          v-for="tag in form.dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">{{ $locale.main.label_input.new_tag }}</el-button>
      </el-form-item>
      <el-form-item :label="this.$locale.main.label_input.image" :label-width="formLabelWidth">
        <el-upload
            class="users upload-demo"
            ref="upload"
            action=""
            list-type="picture-card"
            :on-change="changeImage"
            :on-remove="removeImage"
            :file-list="images"
            :auto-upload="false"
        >
        <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">{{ $locale.main.button.cancel }}</el-button>
      <el-button type="primary" :disabled="disabledButton" @click="downloadTemplate">{{ $locale.main.button.export }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // скрыть/показать модальное окно
    visibleTemplate: {
      type: Boolean
    },
    // структура карточки в JSON
    structure: {
      type: String
    }
  },
  data () {
    return {
      images: [],
      dialogFormVisible: false,
      inputVisible: false,
      inputValue: '',
      form: {
        id: null,
        name: null,
        description: null,
        dynamicTags: [],
        image: null

      },
      formLabelWidth: '180px'
    }
  },
  watch: {
    'form.id': {
      handler (newValue, oldValue) {
        // запретить кириллицу
        let reg = /[а-яА-ЯёЁ]/g
        if (newValue && newValue.search(reg) !== -1) {
          this.form.id = newValue.replace(reg, '')
        }
      }
    }
  },
  computed: {
    disabledButton () {
      if (this.form.id && this.form.name && this.form.dynamicTags.length) {
        return false
      }
      return true
    }
  },
  methods: {
    clearForm () {
      this.form.id = this.form.name = this.form.description = null
      this.form.dynamicTags = []
      this.images = []
      this.form.image = null
    },
    cancel () {
      this.clearForm()
      this.$emit('update:visibleTemplate', false)
    },
    downloadTemplate () {
      let description = this.form.description ? `'${this.form.description}'` : null
      let image = this.form.image ? `'${this.form.image}'` : null
      const sql = `INSERT INTO interface_editor.templates (id, name, description, structure, tags, thumbnail)
      VALUES ('${this.form.id}','${this.form.name}',${description},'${this.structure}','${this.form.dynamicTags.toString()}',${image})
      ON CONFLICT(id) DO
      UPDATE SET name = EXCLUDED.name, description = EXCLUDED.description, structure = EXCLUDED.structure, tags = EXCLUDED.tags, thumbnail = EXCLUDED.thumbnail;`

      let a = document.createElement('a')
      let file = new Blob([sql], { type: 'text/plain' })
      a.href = URL.createObjectURL(file)
      a.download = 'interface.sql'
      a.click()
      a.remove()
      this.clearForm()
      this.$emit('update:visibleTemplate', false)
    },
    handleClose (tag) {
      this.form.dynamicTags.splice(this.form.dynamicTags.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      let inputValue = this.inputValue
      if (inputValue) {
        this.form.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    changeImage () {
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
      let file = this.$refs.upload.uploadFiles[0].raw
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        this.form.image = reader.result
      })
    },
    removeImage () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      this.form.image = null
      this.images = []
    }
  }
}
</script>

<style scoped >
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .templateSql .required  /deep/ .el-form-item__label {
    position: relative;
  }
  .templateSql .required /deep/ .el-form-item__label::after {
    content: '* ';
    color: #ff4949;
    font-weight: bold;
    position: absolute;
    right: 8px;
    top: -8px;
  }
</style>
