<template>
  <div class="component-preview">
    <h4>Checkbox</h4>

    <div>
      <el-checkbox-group v-model="checkboxGroupA1">
        <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
      </el-checkbox-group>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="checkboxGroupA2" size="medium">
        <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
      </el-checkbox-group>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="checkboxGroupA3" size="small">
        <el-checkbox-button v-for="city in cities" :label="city" :disabled="city === 'Beijing'" :key="city">{{city}}</el-checkbox-button>
      </el-checkbox-group>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="checkboxGroupA4" size="mini" disabled>
        <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
      </el-checkbox-group>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox v-model="checked1" label="Option1" border></el-checkbox>
      <el-checkbox v-model="checked2" label="Option2" border></el-checkbox>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox v-model="checked3" label="Option1" border size="medium"></el-checkbox>
      <el-checkbox v-model="checked4" label="Option2" border size="medium"></el-checkbox>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="checkboxGroupB1" size="small">
        <el-checkbox label="Option1" border></el-checkbox>
        <el-checkbox label="Option2" border disabled></el-checkbox>
      </el-checkbox-group>
    </div>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="checkboxGroupB2" size="mini" disabled>
        <el-checkbox label="Option1" border></el-checkbox>
        <el-checkbox label="Option2" border></el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'CheckboxPreview',

  data () {
    return {
      checkboxGroupA1: ['Shanghai'],
      checkboxGroupA2: ['Shanghai'],
      checkboxGroupA3: ['Shanghai'],
      checkboxGroupA4: ['Shanghai'],
      cities: ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen'],
      checked1: true,
      checked2: false,
      checked3: false,
      checked4: true,
      checkboxGroupB1: [],
      checkboxGroupB2: []
    }
  }
})
</script>
