import CommandInterface from '@/core/application/command/CommandInterface'
import { ThemeDTO } from '@/services/CssEditor/domain/model/Theme'

export default class ThemeUpdateCommand implements CommandInterface {
  private dto: ThemeDTO;

  constructor (
    dto: ThemeDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'ThemeUpdateCommand'
  }

  getDto (): ThemeDTO {
    return this.dto
  }
}
