
// Всплывашка
import mixin from '@/components/System/Notification/mixins/index'
import mixins from 'vue-typed-mixins'
import { mapActions } from 'vuex'

export default mixins(mixin).extend({
  name: 'MessageNotify',

  props: ['message', 'addMainTab'],

  methods: {
    ...mapActions('Notify', [
      'readMessage'
    ]),

    onClose () {
      this.readMessage(this.message)
      this.$parent.close()
    }
  }
})
