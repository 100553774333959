import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ThemeDeleteCommand from '@/services/CssEditor/application/command/ThemeDeleteCommand'
import ThemeCommandRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeCommandRepositoryInterface'

export default class ThemeDeleteCommandHandler implements CommandHandlerInterface {
  private repository: ThemeCommandRepositoryInterface;

  constructor (repository: ThemeCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ThemeDeleteCommand): Promise<void> {
    return this.repository.delete(command.getGuid())
  }
}
