import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SettingCommandRepositoryInterface from '@/services/Databus/domain/repository/SettingCommandRepositoryInterface'
import SettingUpdateCommand from '@/services/Databus/application/command/SettingUpdateCommand'
import { SettingDTO } from '@/services/Databus/domain/model/Setting/Setting'

export default class SettingUpdateHandler implements CommandHandlerInterface
{
    private repo: SettingCommandRepositoryInterface;

    constructor(repo: SettingCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: SettingUpdateCommand): Promise<void>
    {
        let item: SettingDTO = {
            id: command.getId(),
            name: command.getName(),
            value: command.getValue(),
            description: command.getDescription()
        };
        return this.repo.update(item);
    }
}