
import Vue from 'vue'

export default Vue.extend({
  name: 'ProcedureParameters',

  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },
    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value || []
    }
  },

  mounted () {
    this.model = this.value
  },

  methods: {
    addParameter () {
      this.model.push({
        name: '',
        type: 'constant',
        value: ''
      })
    },

    removeParameter (name: string) {
      const index = this.model.findIndex(v => v.name === name)
      if (index !== -1) {
        this.model.splice(index, 1)
      }
    }
  }
})
