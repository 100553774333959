export default class LayerTreeAddChildElementCommand
{
    private parentId: number;
    private childId: number;

    constructor(
        parentId: number,
        childId: number
    ) {
        this.parentId = parentId;
        this.childId = childId;
    }

    getClassName(): string
    {
        return 'LayerTreeAddChildElementCommand';
    }

    getParentId(): number
    {
        return this.parentId;
    }

    getChildId(): number
    {
        return this.childId;
    }
}