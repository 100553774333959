import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import DeleteRequestCommand from '@/services/ApiService/application/command/DeleteRequestCommand'

export default class DeleteRequestHandler implements CommandHandlerInterface {
  private repository: RequestRepositoryInterface;

  constructor (repository: RequestRepositoryInterface) {
    this.repository = repository
  }

  execute (command: DeleteRequestCommand): Promise<void> {
    return this.repository.deleteRequest(command.getDto().guid)
  }
}
