<template>
  <div class="dynamical-select">
    <div class="input-group">
      <el-select
        v-model="localValue"
        :placeholder="placeholder"
        filterable
        clearable
        @clear="onClear"
        @change="onChange"
        size="mini"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <div class="input-group__append">
        <el-button
          type="success"
          icon="el-icon-plus"
          size="mini"
          class="el-button--square"
          @click="onAdd()"
        ></el-button>

        <el-button
          type="primary"
          icon="el-icon-edit"
          size="mini"
          class="el-button--square"
          @click="onEdit()"
          :disabled="localValue === null"
        ></el-button>
      </div>

      <dialog-form
        v-if="editor.show"
        v-model="editor"
        :save="onSave"
        :cancel="onCancel"
      ></dialog-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loader from '../Models/Loader'
import Extractor from '../Models/Extractor'
import Transformer from '../Models/Transformer'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'DynamicSelect',

  props: {
    value: Number,

    placeholder: String,

    options: {
      type: Array,
      default () {
        return []
      }
    },

    type: {
      type: String,
      default: 'loader',
      validator: function (value) {
        return ['loader', 'transformer', 'extractor'].includes(value)
      }
    }
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value || null,

      editor: {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false
      },

      defaultModel: {
        loader: {
          name: null,
          loader_type_id: null
        },

        extractor: {
          name: null,
          extractor_type_id: null
        },

        transformer: {
          name: null,
          transformer_type_id: null
        }
      },

      models: {
        loader: Loader,
        extractor: Extractor,
        transformer: Transformer
      },

      forms: {
        create: {
          loader: 'LoaderCreate',
          extractor: 'ExtractorCreate',
          transformer: 'TransformerCreate'
        },

        update: {
          loader: 'LoaderUpdate',
          extractor: 'ExtractorUpdate',
          transformer: 'TransformerUpdate'
        }
      }
    }
  },

  methods: {
    onChange (value) {
      this.$emit('input', value)
    },

    onClear () {
      this.localValue = null
      this.$emit('input', null)
    },

    onAdd () {
      this.editor.title = this.$t('etl_editor.form.title.' + `create_${this.type}`)
      this.editor.model = new this.models[this.type](this.defaultModel[this.type])
      this.editor.component = this.forms.create[this.type]

      this.editor.isNew = true
      this.editor.show = true
      this.editor.type = this.type
    },

    async onEdit () {
      if (!this.localValue) {
        return
      }

      this.editor.title = this.$t('etl_editor.form.title.' + `edit_${this.type}`)
      this.editor.model = await this.models[this.type].find(this.localValue)
      this.editor.component = this.forms.update[this.type]

      this.editor.isNew = false
      this.editor.show = true
      this.editor.type = this.type
    },

    onCancel () {
      this.editor = {
        title: null,
        isNew: true,
        model: null,
        component: null,
        show: false,
        type: null
      }
    },

    async onSave (isNew) {
      const response = await this.editor.model.save()

      if (isNew) {
        this.editor.isNew = false
        this.editor.model = await this.models[this.editor.type].find(response.id)
        this.editor.title = this.$t('etl_editor.form.title.' + `edit_${this.editor.type}`)
        this.editor.component = this.forms.update[this.editor.type]
      }

      const options = cloneObject(this.options)
      const option = options.find(o => o.id === response.id)
      if (option) {
        option.name = response.name
      } else {
        options.push({
          id: response.id,
          name: response.name
        })
      }

      this.localValue = response.id

      console.log('options', options, option)

      this.$emit('update-options', options)
      this.$emit('update:options', options)
      this.$emit('input', this.localValue)
    }
  }
})
</script>
