import AbstractEntitiesQueryHandler from '@/core/application/query/AbstractEntitiesQueryHandler'
import LogsRepositoryInterface from '@/services/LogService/domain/repository/LogsRepositoryInterface'
import CountByResourceQuery from '@/services/LogService/application/query/CountByResourceQuery'

export default class CountByResourceHandler extends AbstractEntitiesQueryHandler {
  private repository: LogsRepositoryInterface

  constructor (repo: LogsRepositoryInterface) {
    super(repo)
    this.repository = repo
  }

  execute (query: CountByResourceQuery): Promise<any> {
    return this.repository.getCountByResource(query.getPayload(), query.getResource())
  }
}
