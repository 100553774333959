<template>
  <el-form-item :label="label">
    <el-select v-model="localModel"
               clearable
               @clear="$emit('input', null)"
               @change="$emit('input', localModel)">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.id"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">attr_{{item.id}}_</span>
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import Registry from '@/components/Models/EntityModel'

export default {
  name: 'partition_field',
  props: ['value', 'entity-id', 'entity-type-id', 'properties', 'label'],
  data () {
    return {
      localModel: this.value,
      options: []
    }
  },
  async mounted () {
    let data = await Registry.params({ parent_id: this.entityId, entity_type_id: 'xref_field', show_children: true }).get()
    this.findXrefs(data.data)
  },
  methods: {
    findXrefs (tree) {
      tree.forEach((item) => {
        if (item.entity_type_id === 'xref_field') {
          this.options.push({
            id: item.id,
            name: item.name
          })
        } else if (item.children.length > 0) {
          this.findXrefs(item.children)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
