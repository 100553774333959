import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import RelatedObjectQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/RelatedObjectQueryRepositoryInterface'
import { GetRelatedObjectByGuid } from '@/services/LogicEditor/infrastructure/store/modules/RelatedObject/action-types'

export default class RelatedObjectQueryRepository extends AbstractQueryRepository implements RelatedObjectQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetRelatedObjectByGuid(guid))
  }
}
