<template>
  <el-popover
    placement="bottom-start"
    class="popover"
    trigger="click"
    popper-class="interface_editor_popper"
    :disabled="disabled"
    v-model="isActive"
  >
    <span
      :class="{
      'active': isActive,
      'reference': true,
      'icon_button': true,
      'disabled': disabled,
      'mini': mini
      }"
      slot="reference"
    ><font-awesome-icon :icon="icon" v-if="icon"></font-awesome-icon>
      <span v-if="(title || '').length" class="label" style="margin-left: 5px">{{ title }}</span>
      <i
      :class="{
      'el-icon-caret-top': isActive,
      'el-icon-caret-bottom': !isActive,
      'el-icon--right': true
    }"></i></span>
    <slot :isActive="isActive"></slot>
  </el-popover>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'PopoverButton',
  inject: ['getEventBus'],
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {},
    title: String,
    mini: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  mounted () {
    this.getEventBus().$on('closePopover', this.close)
  },
  beforeDestroy () {
    this.getEventBus().$off('closePopover', this.close)
  },
  methods: {
    close () {
      if (this.isActive) {
        this.isActive = false
      }
    }
  }
}
</script>

<style scoped src="../../../main.scss" lang="scss"></style>
<style lang="scss">
.popover {
  cursor: pointer;
}
.interface_editor_popper {
  margin-top: 15px !important;
  border-radius: 4px !important;
  padding: 0 !important;
}
span.disabled {
  &:hover {
    background: #E9EBED !important;
  }
  cursor: not-allowed !important;
  background: #E9EBED;
}
</style>
