import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    isNew () {
      return typeof this.model.guid !== 'string'
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    model () {
      this.$emit('input', this.model)
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    submit (callback: Function): void {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          this.executeCommand(callback)
        } else {
          return false
        }
      })
    },

    executeCommand (callback: Function): void {
    }
  }
})
