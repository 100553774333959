<template>
  <div style="height: 100%" v-loading="loading">
    <el-alert type="error" v-if="error">{{ error }}</el-alert>
  </div>
</template>

<script>
export default {
  name: 'FastEsia',
  data () {
    return {
      loading: true,
      error: null
    }
  },
  mounted () {
    this.$store.dispatch(`Authorization/EsiaLogin`).catch(() => {
      this.error = this.$locale.login.errors.esia_fast_login
      this.loading = false
    })
  }
}
</script>

<style scoped>

</style>
