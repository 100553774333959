<template>
  <div :class="classes">
    <div v-if="hasHeader" class="card__header">
      <div v-if="hasTitle" class="card__title">
        <slot name="header.title"></slot>
      </div>

      <div v-if="hasToolbar" class="card__toolbar">
        <slot name="header.toolbar"></slot>
      </div>
    </div>

    <div class="card__body">
      <slot></slot>
    </div>

    <div v-if="hasFooter" class="card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    rounded: {
      type: Boolean,
      default: true
    },

    bordered: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  computed: {
    classes () {
      const classes = {
        card: true,
        bordered: this.bordered,
        rounded: this.rounded
      }

      if (['grey', 'primary', 'success'].indexOf(this.type) !== -1) {
        classes['card--' + this.type] = true
      }

      return classes
    },

    hasHeader () {
      return this.hasTitle || this.hasToolbar
    },

    hasTitle () {
      return !!this.$slots['header.title']
    },

    hasToolbar () {
      return !!this.$slots['header.toolbar']
    },

    hasFooter () {
      return !!this.$slots.footer
    }
  }
}
</script>

<style lang="scss">
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    height: 100%;
    border-color: #ebeef5;

    &--grey {
      border-color: #eeeeee;

      .card__header, .card__footer {
        background-color: #eeeeee;
      }
    }

    &--primary {
      border-color: #409EFF;

      .card__header, .card__footer {
        color: #fff;
        background-color: #409EFF;
      }
    }

    &--success {
      border-color: #67C23A;

      .card__header, .card__footer {
        background-color: #67C23A;
        color: #fff;
      }
    }

    &.bordered {
      border: 1px solid #ebeef5;
      height: calc(100% - 2px);
    }

    &.rounded {
      border-radius: 4px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: inherit;
      border-bottom: 1px solid #000;
      border-color: inherit;
      box-sizing: border-box;
      padding: 10px;
    }

    &__title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 7px 0;
      font-size: 1rem;
      font-weight: 500;
      width: 100%;
    }

    &__toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .el-button + .el-button {
        margin-left: 5px;
      }

      .el-button + .el-input {
        margin-left: 5px;
      }

      .el-input {
        width: 100%;
      }

      &-item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__body {
      position: relative;
      background-color: inherit;
      min-height: 1px;
      height: 100%;

      .el-scrollbar {
        height: 100%;
      }

      .el-scrollbar__wrap {
        position: relative;
        overflow-x: hidden;
        height: 100%;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: inherit;
      border-top: 1px solid #000;
      border-color: inherit;
      padding: 10px;
    }
  }
</style>
