import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import StateDeleteCommand from '@/services/LogicEditor/application/command/StateDeleteCommand'
import StateCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/StateCommandRepositoryInterface'

export default class StateDeleteHandler implements CommandHandlerInterface {
    private repository: StateCommandRepositoryInterface;

    constructor (repository: StateCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: StateDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
