
import Vue, { VNode } from 'vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import FormMixin from '@/services/NotificationEditor/infrastructure/mixins/FormMixin'
import icons from '@/components/MenuEditor/components/el-icons.json'
import FormulaForm from '@/services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import NotificationByIdQuery from '@/services/NotificationEditor/application/query/NotificationByIdQuery'
import NotificationDTO from '@/services/NotificationEditor/domain/model/NotificationDTO'
import NotificationCreateCommand from '@/services/NotificationEditor/application/command/NotificationCreateCommand'
import NotificationUpdateCommand from '@/services/NotificationEditor/application/command/NotificationUpdateCommand'
import TagInput from '@/core/infrastructure/components/TagInput.vue'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'
import { LogicEditorAPI } from '@/core/infrastructure/api/modules/LogicEditorAPI'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import TelegramChatsQuery from '@/services/NotificationEditor/application/query/TelegramChatsQuery'

// Уведомляет только тех получателей, которые привязали свой профиль из системы к боту телеграм

export default Vue.extend({
  name: 'NotificationForm',

  mixins: [FormMixin],

  components: {
    RegistrySelectTree,
    TreeSelect,
    FormulaForm,
    TagInput
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isLoading (): boolean {
      return this.$store.getters['Notification/isNotificationLoading'] || this.isOtherLoading
    },

    icons (): object[] {
      return icons.map(el => ({ id: el, name: el }))
    },

    titleFormula (): string {
      const title = this.model.title_formula
      if (title) {
        if (title.is_dsl) {
          return title.dsl_statement
        } else {
          const { select, join, group_by } = title.sql_statements || {}

          return `${select || ''}<br>${join || ''}<br>${group_by || ''}`
        }
      }

      return '<b>Формула для заголовка уведомления</b>'
    },

    contentFormula (): string {
      const content = this.model.content_formula
      if (content) {
        if (content.is_dsl) {
          return content.dsl_statement
        } else {
          const { select, join, group_by } = content.sql_statements || {}

          return `${select || ''}<br>${join || ''}<br>${group_by || ''}`
        }
      }

      return '<b>Формула для содержимого уведомления</b>'
    },

    showFormulaDialog: {
      get: function (): boolean {
        return this.editFormula !== null && this.model.object_id !== null
      },

      set: function (val): void {
        if (val === false) {
          this.editFormula = null
        }
      }
    },

    rules (): object {
      const rules = {
        name: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.name')]), trigger: 'blur' }
        ],
        object_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.field_registry')]), trigger: 'change' }
        ],

        condition_type: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.condition_type')]), trigger: 'change' }
        ],
        condition_match_type: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.match_type')]), trigger: 'change' }
        ],
        notification_condition_states: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.states')]), trigger: 'change' }
        ],

        title_formula_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.title')]), trigger: 'change' }
        ],
        content_formula_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.content')]), trigger: 'change' }
        ],

        author_field_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.author')]), trigger: 'change' }
        ],
        author_state_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.author')]), trigger: 'change' }
        ],
        author_id: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.author')]), trigger: 'change' }
        ],

        notification_recipient_fields: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.send')]), trigger: 'change' }
        ],
        notification_recipient_states: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.send')]), trigger: 'change' }
        ],
        notification_recipients: [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.send')]), trigger: 'change' }
        ],

        card_operation_type: [],
        external_object_id: [],
        current_field_id: []
      }

      if (this.model.interaction_type !== null) {
        rules.card_operation_type = [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.card_operation')]), trigger: 'change' }
        ]
      }

      if (this.model.interaction_type === 'open_external_card') {
        rules.external_object_id = [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.external_object')]), trigger: 'change' }
        ]

        rules.current_field_id = [
          { required: true, message: this.$t('main.errors.required', [this.$t('notify_editor.labels.current_field')]), trigger: 'change' }
        ]
      }

      return rules
    },

    location (): string {
      return this.$store.getters['Notification/getLocation']
    },

    types (): object[] {
      return [
        { id: 'field', name: this.$t('notify_editor.labels.field') },
        { id: 'state', name: this.$t('notify_editor.labels.state') },
        { id: 'user', name: this.$t('notify_editor.labels.user') }
      ]
    },

    periodTypes (): object[] {
      return [
        { id: 'once', name: this.$t('notify_editor.periods.once') },
        { id: 'hourly', name: this.$t('notify_editor.periods.hourly') },
        { id: 'daily', name: this.$t('notify_editor.periods.daily') },
        { id: 'weekly', name: this.$t('notify_editor.periods.weekly') },
        { id: 'monthly', name: this.$t('notify_editor.periods.monthly') }
      ]
    }
  },

  watch: {
    location (value: string): void {
      if (value !== null) {
        const id = parseInt(value.replace('/notifications/', ''))

        this.getQueryBus()
          .execute(new NotificationByIdQuery(id))
          .then((response: NotificationDTO) => {
            this.model = response
          })
      }
    }
  },

  data (): any {
    return {
      conditionTypes: [
        { id: 'state', name: 'По состояниям' }
        // { id: 'command', name: 'Внешней командой' }
      ],
      matchTypes: [
        { id: 'all', name: 'При соблюдении всех условий одновременно' },
        { id: 'any', name: 'При любом условии' }
      ],
      interactionTypes: [
        { id: 'open_card', name: 'Открыть карточку' },
        { id: 'open_external_card', name: 'Открыть карточку внешнего реестра' }
      ],
      operationTypes: [
        { id: 'read', name: 'Чтение' },
        { id: 'edit', name: 'Редактирование' },
        { id: 'create', name: 'Добавление' }
      ],
      emailTypes: [
        { id: 'field', name: 'Поле уведомлений' },
        { id: 'user', name: 'Электронная почта сотрудника' },
        { id: 'constant', name: 'Константа' }
      ],
      telegramTypes: [
        { id: 'field', name: 'Поле реестра' },
        { id: 'constant', name: 'Константа' }
      ],
      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name'
      },
      telegramChats: [],

      users: [],
      userObject: null,
      fields: [],
      externalCards: [],
      currentCards: [],
      isOtherLoading: false,

      editFormula: null,
      editFormulaProp: null,
      editFormulaTitle: 'Создание формулы'
    }
  },

  async mounted (): Promise<void> {
    this.isOtherLoading = true

    // await this.$http
    //   .get(`${this.$config.api}/accesseditor/users?fields=id,name,surname,midname`)
    //   .then((response) => {
    //     this.users = []

    //     response.data.forEach((user) => {
    //       this.users.push({
    //         id: user.id,
    //         name: user.surname + ' ' + user.name + ' ' + user.midname
    //       })
    //     })
    //   })
    try {
      this.users = []
      let response = await APIClient.shared.request(new UserAPI.GetUsers([]))
      response.forEach(item => {
        this.users.push({ id: item.id, name: item.full_name })
      })
    } catch (error) {
      console.log({ error })
    }

    await this.$http
      .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true&show_children=true`)
      .then((response) => {
        this.userObject = response.data.data[0] || null
      })

    if (this.model.object_id !== null) {
      await this.loadFields(this.model.object_id)
    }

    if (this.model.external_object_id !== null) {
      await this.loadExternalCards(this.model.external_object_id)
    }

    if (this.model.object_id !== null) {
      await this.loadCurrentCards(this.model.object_id)
    }

    this.getQueryBus()
      .execute(new TelegramChatsQuery())
      .then((data) => {
        this.telegramChats = data
      })

    this.isOtherLoading = false
  },

  methods: {
    renderContent (h, { node, data, store }): VNode {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    clearTelegramGroup () {
      this.model.telegram_group_type = null
      this.model.telegram_group_value = null
      this.model.telegram_group_field_id = null
    },

    clearTelegram () {
      this.model.telegram_type = null
      this.model.telegram_value = null
      this.model.notification_telegram_fields = []
      this.model.notification_telegram_recipients = []
    },

    clearInteractive () {
      this.model.interaction_type = null
      this.model.card_operation_type = null
      this.model.external_object_id = null
      this.model.external_card_id = null
      this.model.current_field_id = null
      this.model.current_card_id = null
    },

    changeInteractive () {
      if (this.model.interaction_type === 'open_card') {
        this.model.external_object_id = null
        this.model.external_card_id = null
        this.model.current_field_id = null
      } else if (this.model.interaction_type === 'open_external_card') {
        this.model.current_card_id = null
      }
    },

    async selectObjectTrigger (value: number): Promise<void> {
      await this.loadFields(value)
      await this.loadCurrentCards(value)
    },

    async loadFields (value: number): Promise<void> {
      await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${value}&show_children=true`)
        .then((response) => {
          this.fields = response.data.data
        })
        .catch(error => console.error(error))
    },

    async loadExternalCards (value: number): Promise<void> {
      await this.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${value}&fields=id,name,is_default`)
        .then((response) => {
          this.externalCards = response.data
        })
        .catch(error => console.error(error))
    },

    async loadCurrentCards (value: number): Promise<void> {
      await this.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${value}&fields=id,name,is_default`)
        .then((response) => {
          this.currentCards = response.data
        })
        .catch(error => console.error(error))
    },

    async onEditFormula (prop: string): Promise<void> {
      if (!this.model.object_id) {
        return
      }

      if (this.model[prop] !== null) {
        this.editFormula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaById(this.model[prop].id))
        this.editFormulaTitle = 'Редактирование формулы: ' + this.editFormula.name + ' (id: ' + this.editFormula.id + ')'
      } else {
        this.editFormulaTitle = 'Создание формулы'
        this.editFormula = FormulaDTO.create('notification_formula')
        this.editFormula.name = prop === 'title_formula' ? 'Заголовок' : 'Содержание'
        this.editFormula.object_id = this.model.object_id
      }

      this.editFormulaProp = prop
    },

    onSaveFormula (): void {
      if (!this.editFormula) {
        return
      }

      this.$refs.formulaForm.validate(async (valid: boolean) => {
        if (valid) {
          if (this.editFormula.guid) {
            await APIClient.shared.request(new LogicEditorAPI.UpdateFormula(this.editFormula))
            this.editFormula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaByGuid(this.editFormula.guid))
          } else {
            const location = await APIClient.shared.request(new LogicEditorAPI.CreateFormula(this.editFormula))
            const formulaGuid = location.replace('/formulas/', '')
            this.editFormula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaByGuid(formulaGuid))
          }

          this.model[this.editFormulaProp] = this.editFormula
          this.model[this.editFormulaProp + '_id'] = this.model[this.editFormulaProp].id
        } else {
          return false
        }
      })
    },

    executeCommand (callback: Function): void {
      this.getCommandBus()
        .execute(
          this.isNew
            ? new NotificationCreateCommand(this.model)
            : new NotificationUpdateCommand(this.model)
        ).then(() => {
          callback()
        })
    }
  }
})
