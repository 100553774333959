import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import LogicGroupQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicGroupQueryRepositoryInterface'
import { GetLogicGroupByGuid } from '@/services/LogicEditor/infrastructure/store/modules/LogicGroup/action-types'

export default class LogicGroupQueryRepository extends AbstractQueryRepository implements LogicGroupQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetLogicGroupByGuid(guid))
  }
}
