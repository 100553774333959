import CssClassCommandRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassCommandRepositoryInterface'
import store from '@/store'
import { CreateCssClass, UpdateCssClass, DeleteCssClass } from '@/services/CssEditor/infrastructure/store/modules/CssClass/action-types'
import { CssClassDTO } from '@/services/CssEditor/domain/model/CssClass'

export default class CssClassCommandRepository implements CssClassCommandRepositoryInterface {
  insert (item: CssClassDTO): Promise<void> {
    return store.dispatch(new CreateCssClass(item))
  }

  update (item: CssClassDTO): Promise<void> {
    return store.dispatch(new UpdateCssClass(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteCssClass(guid))
  }
}
