<template>
  <div class="role-main-edit-panel role-extend-edit-panel">
    <span class="wrapper">
      <div class="left-side">
        <el-col>
          <div class="main-edit-title">{{$locale.access_editor.titles.extended_settings}}</div>
        </el-col>
        <el-tabs
            v-model="activeEntity"
            :tab-position="tabPosition"
            type="border-card"
            class="main-tabs special-left-tabs-heighter"
        >
          <el-tab-pane
              :label="this.$locale.access_editor.right_object.title"
              name="object_rights"
          >
            <RegistryRightsPanel/>
          </el-tab-pane>
          <el-tab-pane
              :label="this.$locale.access_editor.databus"
              name="databus"
          >
            <DatabusPanel/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </span>
  </div>
</template>

<script>
import RegistryRightsPanel from '@/services/AccessEditor/infrastructure/components/RolePanel/RoleExtendEditPanel/RegistryRightsPanel/index.vue'
import DatabusPanel from '@/services/AccessEditor/infrastructure/components/RolePanel/RoleExtendEditPanel/DatabusPanel/index.vue'

export default {
  name: 'RoleExtendEditPanel',
  components: {
    RegistryRightsPanel,
    DatabusPanel
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      activeEntity: 'object_rights',
      tabPosition: 'left'
    }
  }
}
</script>
