import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ConfigurationElementsExportQuery
  from '@/services/SyncService/application/query/ConfigurationElementsExportQuery'
import ConfigurationElementRepositoryInterface from '@/services/SyncService/domain/repository/ConfigurationElementRepositoryInterface'

export default class ConfigurationElementsExportHandler implements QueryHandlerInterface {
  private repo: ConfigurationElementRepositoryInterface

  constructor (repo: ConfigurationElementRepositoryInterface) {
    this.repo = repo
  }

  execute (query: ConfigurationElementsExportQuery): Promise<any> {
    return this.repo.export(query.getPayload())
  }
}
