import ApprovalQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ApprovalQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ApprovalStagesByApprovalIdQuery from '@/services/LogicEditor/application/query/ApprovalStagesByApprovalIdQuery'

export default class ApprovalStagesByApprovalIdHandler implements QueryHandlerInterface {
  private repository: ApprovalQueryRepositoryInterface

  constructor (repository: ApprovalQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: ApprovalStagesByApprovalIdQuery): Promise<any> {
    return this.repository.getApprovalStagesByApprovalId(query.getApprovalId(), query.getPayload())
  }
}
