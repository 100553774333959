<template>
  <div class="point-style-image-form custom_scrollbar">
    <el-form :rules="formRules" :ref="formId" v-if="dto" class="point-style-image-form-inner-body" :model="dto">
      <el-form-item prop="image">
        <fieldset>
            <legend><span style="color:red">*</span>&nbsp;{{$locale.map_editor.style_form.image}}</legend>
            <el-upload
                class="users upload-demo"
                ref="upload"
                action=""
                list-type="picture-card"
                :on-change="changeImage"
                :on-remove="removeImage"
                :file-list="image"
                :auto-upload="false"
            >
            <i class="el-icon-plus" :title="$locale.map_editor.style_form.dowload_image"></i>
            </el-upload>
        </fieldset>
      </el-form-item> 
      <el-form-item prop="name" :label="$locale.main.fields.name">
        <el-input v-model="dto.name" autocomplete="off"></el-input>        
      </el-form-item>      
    </el-form>
  </div>
</template>

<script type="ts">
import PointStyleImage, { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage'
import PointStyleImageCreateCommand from '@/core/application/command/modules/PointStyleImage/PointStyleImageCreateCommand'
import PointStyleImageByGuidQuery from '@/core/application/query/modules/PointStyleImage/PointStyleImageByGuidQuery'

export default {
  name: 'PointStyleImageForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'pointStyleImage', 'submitCallback'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
  },
  watch: {
    pointStyleImageCreated: function (location) {
        this.getQueryBus().execute(
            new PointStyleImageByGuidQuery(
                location.replace('/point_style_images/', '')
            )
        ).then((pointStyleImageDto) => {
            let pointStyleImage = PointStyleImage.create(pointStyleImageDto);
            if (typeof this.submitCallback == 'function') {
                this.submitCallback(pointStyleImage);
            }
            this.getEventBus().$emit('pointStyleImageCreated', pointStyleImage, this.parentFormId);    
        });
    } 
  },
  data() {
    return {
      dto: null,
      image: [],
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        image: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }     
    }
  },
  computed: {
    pointStyleImageCreated() {
      return this.$store.getters['PointStyleImage/getLocation'];
    }
  },
  methods: {
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
            let pointStyleImage = PointStyleImage.create(this.dto);
            this.getCommandBus().execute(
                new PointStyleImageCreateCommand(
                    pointStyleImage.getName(),
                    pointStyleImage.getImage()
                )
            ).then((response) => {
                if (typeof callback == 'function') {
                    callback(response);
                }              
            });
        }
      });
    },
    changeImage (file) {
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1);        
      }
      this.dto.image = this.$refs.upload.uploadFiles[0].raw;
    },
    removeImage () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = [];
      }
      if (this.dto !== null) {
        this.dto.image = null;
      }
      this.image = [];
    },
  },
  mounted() {
    this.dto = new PointStyleImageDTO({});
  }
}
</script>