import LayerGroupCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerGroupCommandRepositoryInterface'
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'
import store from '@/store'
import { AddLayerGroup, UpdateLayerGroup } from '@/services/MapEditor/infrastructure/store/modules/LayerGroup/action-types'

export default class LayerGroupCommandRepository implements LayerGroupCommandRepositoryInterface
{
    insert(item: LayerGroupDTO): Promise<void>
    {
        return store.dispatch(new AddLayerGroup(item));
    }

    update(item: LayerGroupDTO): Promise<void>
    {
        return store.dispatch(new UpdateLayerGroup(item));
    }
}
