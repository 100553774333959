import Modeler from 'bpmn-js/lib/Modeler'
import ElementFactory from '@/services/BPMNEditor/infrastructure/service/ElementFactory'

export default class BPMNService {
  private readonly modeler: Modeler

  constructor (modeler: Modeler) {
    this.modeler = modeler
  }

  public add (args: {
    type: string,
    event: Event,
    properties?: object,
    name?: string,
    extensions?: object
  }) {
    const shape = ElementFactory.build(this.modeler, args)
    this.modeler.get('create').start(event, shape)
  }
}
