<template>
  <div class="element-selector">
    <el-tree
      :data="elementsSelectorTree"
      :props="{}"
      node-key="guid"
      :default-expand-all="true"
      :expand-on-click-node="false"
    >
      <div
        slot-scope="{ node, data }"
        :style="data.disabled ? 'cursor: not-allowed' : ''"
        :class="{ 'custom-tree__node': true, 'custom-tree__node_selected': activeBlockGuid === data.guid }"
        @mouseenter="event => onHoverElementSelectorNode(node, data, event)"
        @mouseleave="event => onLeaveElementSelectorNode(node, data, event)"
        @click="() => onClickElementSelectorNode(node, data)"
      >
        <span>
          <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
          <span style="margin-left: 0.3em;">
            <b>{{ data.label }}</b> {{ data.secondaryLabel }}
          </span>
        </span>
      </div>
    </el-tree>
  </div>
</template>

<script>
const ELEMENT_LEVELS = {
  CONTAINER: 'container',
  TAB_CONTAINER: 'tabContainer',
  TAB: 'tab',
  COMPONENT: 'component'
}
export default {
  name: 'ElementSelector',

  props: {
    activeBlockGuid: {
      type: String
    },
    blocks: {
      type: Array
    },
    components: {
      type: Object
    },
    currentViewer: {
      type: Object
    },
    hideComponents: {
      type: Boolean,
      default: false
    },
    disableTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeElementName () {
      return this.getElementName(this.activeBlockGuid)
    },
    elementsSelectorTree () {
      const sourceBlocks = this.blocks
      const sourceBlocksCount = sourceBlocks.length

      if (!Array.isArray(sourceBlocks) || sourceBlocksCount === 0) {
        return []
      }

      // Граф обработанных узлов
      const graph = []

      // Итерируемый узел
      let currentNode = null

      // Внутри контейнера вкладок
      let currentTabContainer = null
      // Внутри вкладки
      let currentTab = null
      // Внутри контейнера
      let currentContainer = null

      // Текущий компонент
      let currentComponent = null

      // Стек для итерации дерева
      const stack = []

      // Прямой обход дерева в глубину (NLR) - итеративно
      let blockI = sourceBlocksCount
      let numberContainer = blockI
      while (blockI--) {
        const originalBlock = sourceBlocks[blockI]
        // alias контейнера для label
        numberContainer = blockI
        let type = this.getNodeType(originalBlock)
        if (type === ELEMENT_LEVELS.COMPONENT && this.hideComponents) {
          continue
        }
        const node = {
          guid: originalBlock.guid,
          alias: `${originalBlock?.alias || `Контейнер ${++numberContainer}`}`,
          type: type,
          originalBlock,
          // Дочерние узлы. Имя свойства не children, чтобы не пересекалось с новой структурой
          childrenNodes: originalBlock.children,
          // Уровень вложенности узла
          level: 0,
          disabled: (this.disableTypes || []).includes(type)
        }
        if (originalBlock.tabs) {
          node.tabs = originalBlock.tabs
        }

        // Добавить последний потомок первым, чтобы первый потомок обработался первым
        stack.push(node)
      }

      // Итерация дерева стеком
      while (stack.length > 0) {
        currentNode = stack.pop()

        // Родительские контейнеры
        currentTabContainer = currentNode.parentContainers?.tabContainer
        currentTab = currentNode.parentContainers?.tab
        currentComponent = currentNode.parentContainers?.component
        currentContainer = currentNode.parentContainers?.container

        // Надписи узла
        currentNode.label = this.getNodeLabel(currentNode)
        const secondaryLabel = this.getNodeSecondaryLabel(currentNode)
        if (secondaryLabel) {
          currentNode.secondaryLabel = secondaryLabel
        }

        // Обработать узел по его типу
        switch (currentNode.type) {
          case ELEMENT_LEVELS.TAB_CONTAINER:
            // Контейнер вкладок, итерируется для обработки его вкладок
            const tabContainer = this.handleTabContainer({ currentNode, graph, stack, currentTabContainer, currentTab, currentContainer, isAddTabs: false })

            currentTabContainer = tabContainer
            break
          case ELEMENT_LEVELS.TAB:
            // Вкладка
            currentTab = this.handleTab({ currentNode, graph, currentTabContainer, currentTab, currentContainer })
            break
          case ELEMENT_LEVELS.COMPONENT:
            // Компонент
            currentComponent = this.handleComponent({ currentNode, graph, currentTabContainer, currentTab, currentContainer })
            break
          case ELEMENT_LEVELS.CONTAINER:
            // Контейнер
            currentContainer = this.handleContainer({ currentNode, graph, currentTabContainer, currentTab, currentContainer })
            break
        }

        // Добавить дочерние узлы текущего узла в стек
        const childrenNodes = currentNode.childrenNodes
        if (Array.isArray(childrenNodes) && childrenNodes.length > 0) {
          let i = childrenNodes.length
          let numberContainerChild = i
          while (i--) {
            const originalChildren = childrenNodes[i]
            let alias
            if (originalChildren?.alias) {
              numberContainerChild = ''
              alias = `${originalChildren.alias}${numberContainerChild}`
            } else if (currentContainer?.alias) {
              numberContainerChild = `_${i}`
              alias = `${currentContainer.alias}${numberContainerChild}`
            } else {
              numberContainerChild = `_${i}`
              alias = 'Контейнер'
            }
            let type = this.getNodeType(originalChildren)
            if (type === ELEMENT_LEVELS.COMPONENT && this.hideComponents) {
              continue
            }
            const node = {
              guid: originalChildren.guid,
              alias: alias,
              type: type,
              originalBlock: originalChildren,
              // Дочерние узлы. Имя свойства не children, чтобы не пересекалось с новой структурой
              childrenNodes: originalChildren.children,
              // Уровень вложенности узла
              level: currentNode.level + 1,
              parentContainers: { tabContainer: currentTabContainer, tab: currentTab, container: currentContainer },
              disabled: (this.disableTypes || []).includes(type)
            }
            if (originalChildren.tabs) {
              node.tabs = originalChildren.tabs
            }

            // Добавить последний потомок первым, чтобы первый потомок обработался первым
            stack.push(node)
          }
        }
      }

      return graph
    },

    store () {
      if (!this.currentViewer || !('getStore' in this.currentViewer)) {
        return ''
      }

      return this.currentViewer.getStore()
    }
  },
  methods: {
    getNodeType (currentNode) {
      let type = null

      if (currentNode.tabs) {
        // Контейнер вкладок
        type = ELEMENT_LEVELS.TAB_CONTAINER
      } else if (currentNode.type === ELEMENT_LEVELS.TAB) {
        // Вкладка
        type = ELEMENT_LEVELS.TAB
      } else if (currentNode.guid in this.components) {
        // Компонент
        type = ELEMENT_LEVELS.COMPONENT
      } else {
        // Контейнер
        type = ELEMENT_LEVELS.CONTAINER
      }

      return type
    },

    getNodeLabel (currentNode) {
      let label = ''

      switch (currentNode.type) {
        case ELEMENT_LEVELS.TAB:
          label = currentNode.tabName
          break
        case ELEMENT_LEVELS.COMPONENT:
          const component = this.components[currentNode.guid]

          if (component.properties.label) {
            label += component.properties.label
          }

          if (component.properties.editorAlias) {
            label += component.properties.editorAlias
          }

          if (component.initialType === 'a-label') {
            label = ''
            label += component.properties.editorAlias || component.properties.text || 'Текст'
          }

          if (component.properties.defaultValue) {
            if (label) {
              label += ' '
            }

            label += `[${component.properties.defaultValue}]`
          }

          if (!label) {
            label = component.guid
          }
          break
        case ELEMENT_LEVELS.CONTAINER:
          label = currentNode.alias
          break
        default:
          label = currentNode.alias || currentNode.guid
          break
      }

      return label
    },

    getNodeSecondaryLabel (currentNode) {
      let label = ''

      switch (currentNode.type) {
        case ELEMENT_LEVELS.TAB:
          // label = currentNode.tabName
          break
        case ELEMENT_LEVELS.COMPONENT:
          const component = this.components[currentNode.guid]

          if (component.initialType) {
            label += component.initialType
          }

          if (component.properties.name) {
            if (label) {
              label += ' '
            }

            label += component.properties.name
          }
          break
        default:
          // label = currentNode.alias || currentNode.guid
          break
      }

      return label
    },

    handleTabContainer ({ currentNode, graph, stack, currentTabContainer, currentTab, currentContainer, isAddTabs = false }) {
      const tabContainer = currentNode
      let tabs = []
      if (Array.isArray(tabContainer.tabs?.list)) {
        tabs = tabContainer.tabs.list.map(tab => ({
          // guid вкладки (не контейнера)
          guid: tab.guid,
          tabName: tab.name,
          originalTab: tab,
          type: ELEMENT_LEVELS.TAB,
          // Дочерние узлы от контейнера вкладок
          childrenNodes: tabContainer.childrenNodes.filter(node => node.parentTabGuid === tab.guid),
          // Уровень вложенности узла
          level: tabContainer.level + 1,
          parentContainers: { tabContainer, tab: currentTab, container: currentContainer },
          disabled: (this.disableTypes || []).includes(ELEMENT_LEVELS.TAB)
        })
        )
      }

      // Убрать список дочерних элементов всех табов в контейнере вкладок, после добавления их по вкладкам
      delete tabContainer.childrenNodes

      if (isAddTabs) {
        // Добавить вкладки, без контейнера с вкладками
        this.handleNodes({ nodes: tabs, graph, currentTabContainer: tabContainer, currentTab, currentContainer })
      } else {
        // Добавить контейнер с вкладками
        if (!Array.isArray(tabContainer.children)) {
          tabContainer.children = []
        }
        tabContainer.children.push(...tabs) // Добавить вкладки в контейнер вкладок
        this.handleNode({ node: tabContainer, graph, currentTabContainer, currentTab, currentContainer })
      }

      // Добавить вкладки в стек
      if (Array.isArray(tabs) && tabs.length > 0) {
        let i = tabs.length
        while (i--) {
          // Добавить последний потомок первым, чтобы первый потомок обработался первым
          stack.push(tabs[i])
        }
      }

      return tabContainer
    },

    handleTab ({ currentNode, graph, currentTabContainer, currentTab, currentContainer }) {
      const tab = currentNode
      return tab
    },

    handleComponent ({ currentNode, graph, currentTabContainer, currentTab, currentContainer }) {
      const component = currentNode

      this.handleNode({ node: component, graph, currentTabContainer, currentTab, currentContainer })

      return component
    },

    handleContainer ({ currentNode, graph, currentTabContainer, currentTab, currentContainer }) {
      const container = currentNode

      this.handleNode({ node: container, graph, currentTabContainer, currentTab, currentContainer })

      return container
    },

    handleNode ({ node, graph, currentTabContainer, currentTab, currentContainer }) {
      const deepestContainer = this.getDeepestContainer([currentTab, currentContainer])

      if (deepestContainer) {
        if (!Array.isArray(deepestContainer.children)) {
          deepestContainer.children = []
        }

        deepestContainer.children.push(node)
      } else {
        // Вне контейнеров
        graph.push(node)
      }
    },

    handleNodes ({ nodes, graph, currentTabContainer, currentTab, currentContainer }) {
      nodes.forEach(node => this.handleNode({ node, graph, currentTabContainer, currentTab, currentContainer }))
    },

    getDeepestContainer (containers) {
      const sortedContainers = [...containers].filter(e => e).sort((a, b) => b.level - a.level)
      return sortedContainers[0]
    },

    getTreeIcon (node, data) {
      let cssClass = ''

      switch (data.type) {
        case ELEMENT_LEVELS.TAB_CONTAINER:
          cssClass = 'el-icon-list-alt'
          break
        case ELEMENT_LEVELS.TAB:
          cssClass = (node.expanded) ? 'el-icon-folder-opened' : 'el-icon-folder'
          break
        case ELEMENT_LEVELS.COMPONENT:
          cssClass = 'el-icon-document'
          break
        case ELEMENT_LEVELS.CONTAINER:
          cssClass = ''
          break
      }

      return cssClass
    },

    onHoverElementSelectorNode (node, data, event) {
      const block = this.store.getRefByGuid(data.guid)
      if (block) {
        this.$set(block.block, 'isHighlight', true)
      }
    },

    onLeaveElementSelectorNode (node, data, event) {
      const block = this.store.getRefByGuid(data.guid)
      if (block) {
        this.$set(block.block, 'isHighlight', false)
      }
    },

    onClickElementSelectorNode (node, data) {
      let blockGuid = null

      let tabContainerGuid = null
      let tabGuid = null

      if (data.type === ELEMENT_LEVELS.TAB) {
        tabContainerGuid = data.parentContainers?.tabContainer?.guid
        tabGuid = data.guid

        blockGuid = tabContainerGuid
      } else {
        tabContainerGuid = data.parentContainers?.tabContainer?.guid
        tabGuid = data.parentContainers?.tab?.guid

        blockGuid = data.guid
      }

      if (tabContainerGuid && tabGuid) {
        this.setActiveTab(tabContainerGuid, tabGuid)
      }
      this.setActiveBlock(blockGuid)
    },

    setActiveBlock (guid) {
      this.$emit('set-active-block', guid)
    },

    setActiveTab (tabContainerGuid, tabGuid) {
      this.$emit('set-active-tab', tabContainerGuid, tabGuid, true /* isOpenParentTabs */)
    },

    getElementName (blockGuid) {
      if (!this.store) {
        this.$emit('change-active-element-name', '')
        return ''
      }

      const block = this.store.getByGuid(blockGuid)
      if (block) {
        const nodeLabel = this.getNodeLabel({
          guid: block.guid,
          alias: block.alias,
          type: this.getNodeType(block)
        })

        this.$emit('change-active-element-name', nodeLabel)
        return nodeLabel
      }

      this.$emit('change-active-element-name', '')
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.element-selector {
  width: 40vw;
}

.custom-tree__node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.custom-tree__node {
  width: 100%;
  height: 100%;
}

.custom-tree__node_selected {
  color: #208BE9;
  font-weight: bold;
}
</style>
