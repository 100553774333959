import CommandInterface from '@/core/application/command/CommandInterface'

export default class TaskRegistryEntityUpdateCommand implements CommandInterface {
  public payload: object

  constructor (payload: object) {
    this.payload = payload
  }

  getClassName (): string {
    return 'TaskRegistryEntityUpdateCommand'
  }

  getPayload (): object {
    return this.payload
  }
}
