import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetStateByGuid, GetStateOperationTypes, GetStateOperationTypeEntityTypes, GetStateFieldTypes, GetConditionFieldTypes } from '@/services/LogicEditor/infrastructure/store/modules/State/action-types'
import StateQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/StateQueryRepositoryInterface'

export default class StateQueryRepository extends AbstractQueryRepository implements StateQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetStateByGuid(guid))
  }

  getStateOperationTypes (): Promise<any> {
    return store.dispatch(new GetStateOperationTypes())
  }

  getStateOperationTypeEntityTypes (): Promise<any> {
    return store.dispatch(new GetStateOperationTypeEntityTypes())
  }

  getStateFieldTypes (): Promise<any> {
    return store.dispatch(new GetStateFieldTypes())
  }

  getConditionFieldTypes (): Promise<any> {
    return store.dispatch(new GetConditionFieldTypes())
  }
}
