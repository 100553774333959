<template>
  <div style="height: 100%">
    <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
      <span class="label" style="padding: 5px">{{ $t('api_service.request.request_parameters') }}</span>
      <div class="parameters_toolbar">
        <el-button size="small" @click="createParameter"><i class="el-icon-plus"></i></el-button>
      </div>
      <div class="parameters_tree_container">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <el-tree
            ref="parameters_tree"
            v-loading="loading"
            class="parameters_tree"
            node-key="guid"
            highlight-current
            :data="tree_data"
            :props="tree_props"
            :expand-on-click-node="false"
            @node-click="handleSelected"
          >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>
              <b>{{ data.alias }}</b>
              <span> (id - {{ data.id }})</span>
            </span>
            <span v-if="parameter.id === data.id">
              <el-button type="text" size="small" style="color:red" @click="deleteParameter(data)">
                <i class="el-icon-delete el-icon--left">{{ $t('api_service.request.request_adding_form.delete') }}</i>
              </el-button>
            </span>
          </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <el-divider></el-divider>
      <div v-if="parameter.id" style="padding: 5px 0 0 0"><b>{{ parameter.alias + ` (id - ${parameter.id})` }}</b></div>
      <div class="parameter_main_container" :key="parameter.guid">
        <div class="parameter_container">
          <span class="label" style="margin-bottom: 5px">{{ $t('api_service.request.parameter_passing.property') }}</span>
          <div class="parameter_container_element">
            <span>{{ $t('api_service.request.parameter_passing.alias') }}</span>
            <el-input v-model="parameter.alias"></el-input>
          </div>
          <div class="parameter_container_element">
            <span>{{ $t('api_service.request.parameter_passing.description') }}</span>
            <el-input v-model="parameter.description" type="textarea"></el-input>
          </div>
          <div class="parameter_container_element">
            <span style="margin-right: 5px">{{ $t('api_service.request.parameter_passing.data_type') }}</span>
            <editor-select
              style="width: 100%"
              ref="data_type"
              :value="parameter.type"
              @change="$set(parameter, 'type', $event)"
              :options="{
                    clearable: true,
                    options: data_types
                  }"
            ></editor-select>
          </div>
          <div class="parameter_container_element">
            <span class="span_label">{{ $t('api_service.request.parameter_passing.is_required') }}</span>
            <el-checkbox v-model="parameter.is_required"></el-checkbox>
          </div>
          <div class="parameter_container_element">
            <span class="span_label">{{ $t('api_service.request.parameter_passing.can_be_null') }}</span>
            <el-checkbox v-model="parameter.nullable"></el-checkbox>
          </div>
        </div>
        <div class="parameter_container">
          <span class="label" style="margin-bottom: 5px">{{ $t('api_service.request.parameter_passing.properties.parameter_passing') }}</span>
          <div class="parameter_container_element">
            <span style="margin-right: 5px">{{ $t('api_service.request.parameter_passing.properties.type') }}</span>
            <editor-select
              style="width: 100%"
              ref="property_type"
              :value="property_type.value"
              @change="$set(property_type, 'value', $event)"
              :options="{
                    clearable: true,
                    options: property_type.data
                  }"
            ></editor-select>
          </div>
          <div class="parameter_container_element">
            <span style="margin-right: 5px">{{ $t('api_service.request.parameter_passing.properties.send_as') }}</span>
            <el-input v-model="property_routing.as"></el-input>
          </div>
          <div class="parameter_container_element">
            <span style="margin-right: 5px">{{ $t('api_service.request.parameter_passing.properties.condition') }}</span>
            <editor-select
              style="width: 100%"
              ref="condition"
              :value="property_routing.condition.value"
              @change="$set(property_routing.condition, 'value', $event)"
              :options="{
                    clearable: true,
                    options: property_routing.condition.data
                  }"
            ></editor-select>
          </div>
        </div>
      </div>
      <el-button size="mini" type="success" @click="saveParameter(parameter)" class="save_parameter_button">
        {{ $t('api_service.request.save') }}
      </el-button>
    </el-scrollbar>
  </div>
</template>

<script>
import RequestParametersQuery from '@/services/ApiService/application/query/RequestParametersQuery'
import { RequestParameter } from '@/services/ApiService/domain/model/Request/RequestParameter'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import CreateRequestParameterCommand from '@/services/ApiService/application/command/CreateRequestParameterCommand'
import DeleteRequestParameterCommand from '@/services/ApiService/application/command/DeleteRequestParameterCommand'
import UpdateRequestParameterCommand from '@/services/ApiService/application/command/UpdateRequestParameterCommand'

export default {
  name: 'RequestParametersForm',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  components: {
    EditorSelect
  },
  props: {
    requestGuid: String
  },
  data () {
    return {
      tree_props: {
        isLeaf: 'is_leaf',
        label: 'name'
      },
      loading: false,
      tree_data: [],
      selectedNode: {},
      data_types: [
        { id: 'path', name: this.$t('api_service.request.param_type.path') },
        { id: 'query', name: this.$t('api_service.request.param_type.query') }
      ],
      parameter: {},
      property_type: {
        value: null,
        data: [
          { id: 'string', name: this.$t('api_service.data_type.string') },
          { id: 'int', name: this.$t('api_service.data_type.integer') }
        ]
      },
      property_routing: {
        as: null,
        condition: {
          value: null,
          data: [
            { id: 'is_not_null', name: this.$t('bi_editor.operation_types.is_not_null') },
            { id: 'is_null', name: this.$t('bi_editor.operation_types.is_null') },
            { id: 'in', name: this.$t('bi_editor.operation_types.in') },
            { id: 'not_in', name: this.$t('bi_editor.operation_types.not_in') },
            { id: 'eq', name: this.$t('bi_editor.operation_types.eq') },
            { id: 'neq', name: this.$t('bi_editor.operation_types.neq') },
            { id: 'gt', name: this.$t('bi_editor.operation_types.gt') },
            { id: 'lt', name: this.$t('bi_editor.operation_types.lt') },
            { id: 'gte', name: this.$t('bi_editor.operation_types.gte') },
            { id: 'lte', name: this.$t('bi_editor.operation_types.lte') },
            { id: 'like', name: this.$t('bi_editor.operation_types.like') },
            { id: 'not_like', name: this.$t('bi_editor.operation_types.not_like') }
          ]
        }
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.refreshTree()
    this.loading = false
  },
  methods: {
    async handleSelected (data, node) {
      this.parameter = new RequestParameter(data)

      if (this.parameter.properties?.length > 0) {
        let propertyType = this.parameter.properties.find((item) => item.id === 'type')
        let propertyCondition = this.parameter.properties.find((item) => item.id === 'routing')

        if (propertyType) {
          this.property_type.value = propertyType.value
        }
        if (propertyCondition) {
          this.property_routing.as = propertyCondition.value.as
          this.property_routing.condition.value = propertyCondition.value.condition
        }
      }
    },
    async getRequestParameters () {
      return this.getQueryBus().execute(new RequestParametersQuery(this.requestGuid))
    },
    async createParameter () {
      let parameter = RequestParameter.create()
      let response = await this.getCommandBus().execute(
        new CreateRequestParameterCommand(
          new RequestParameter(parameter),
          this.requestGuid
        )
      )
      await this.refreshTree()
      let newNode = await this.$refs.parameters_tree.getNode(response.guid)
      await this.handleSelected(newNode.data, newNode)
    },
    async refreshTree () {
      this.tree_data = await this.getRequestParameters()
    },
    async deleteParameter (parameter) {
      await this.getCommandBus().execute(
        new DeleteRequestParameterCommand(
          new RequestParameter(parameter)
        )
      )
      await this.refreshTree()
      this.parameter = {}
    },
    setParameterProperties (parameter) {
      parameter.properties = []

      parameter.properties.push({ id: 'type', type: 'string', value: this.property_type.value })
      parameter.properties.push({
        id: 'routing',
        type: 'object',
        value: {
          as: this.property_routing.as,
          condition: this.property_routing.condition.value
        }
      })
    },
    async saveParameter (parameter) {
      this.setParameterProperties(parameter)
      await this.getCommandBus().execute(
        new UpdateRequestParameterCommand(
          new RequestParameter(parameter)
        )
      )
      await this.refreshTree()
    }
  }
}
</script>

<style scoped lang="scss">
.span_label {
  font-family: Roboto, Sans-Serif;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
}
.label {
  font-family: Roboto, Sans-Serif;
  font-size: medium;
  font-weight: bold;
  display: inline-block;
}
.parameters_tree {
  margin-top: 10px;
  height: 100%;
  width: 100%;
}
.parameters_tree_container {
  width: 100%;
  height: 200px;
}
.parameter_main_container {
  display: flex;
  flex-direction: row;
}
.parameter_container {
  width: 300px;
  display: flex;
  margin: 10px 35px 5px 10px;
  flex-direction: column;
}
.parameter_container_element {
  margin-bottom: 10px;
}
.parameters_toolbar {
  width: 100%;
  height: 32px;
  background-color: #f5f5f5;
}
.save_parameter_button {
  margin: 0 10px 10px
}
</style>
