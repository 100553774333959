<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="wrapper-log-modal">
    <div class="log-modal-multi-info">
      <div class="log-modal-info">
        <div class="log-modal-info-title">Тип:</div>
        <div class="log-modal-info-field">
          <div class="log-modal-info-field-data">{{ log.type }}</div>
        </div>
      </div>
      <div class="log-modal-info">
        <div class="log-modal-info-title">IP:</div>
        <div class="log-modal-info-field">
          <div class="log-modal-info-field-data">{{ log.ip }}</div>
        </div>
      </div>
      <div class="log-modal-info">
        <div class="log-modal-info-title">PID:</div>
        <div class="log-modal-info-field">
          <div class="log-modal-info-field-data">{{ log.pid}}</div>
        </div>
      </div>
      <div class="log-modal-info">
        <div class="log-modal-info-title">Дата:</div>
        <div class="log-modal-info-field">
          <div class="log-modal-info-field-data">{{ log.date }}</div>
        </div>
      </div>
    </div>
    <div class="log-modal-textarea-info">
      <div class="log-modal-textarea-title">Описание</div>
      <div class="log-modal-textarea-field">
        <textarea class="log-modal-textarea-field-data" v-model="log.message"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import LogDTO from '@/services/LogService/domain/model/Log/Log'

export default {
  name: 'LogModal',
  props: {
    log: LogDTO
  }
}
</script>
