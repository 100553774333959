<template>
  <el-select
    v-bind:value="model"
    v-on:input="$emit('input', $event)"
    :placeholder="placeholder"
    :collapse-tags="true"
    :filterable="true"
    :multiple="multiple">
    <el-option-group
      v-for="group in groups"
      :key="group.guid"
      :label="group.name">
      <el-option
        v-for="entity in entities.filter(item => item.logic_id === group.id)"
        :key="entity.guid"
        :label="entity.name"
        :value="entity.element_id">
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
export default {
  name: 'LogicGroupedEntities',

  props: {
    value: [Number, Array],

    placeholder: {
      type: String,
      default: 'Select'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    elementType: {
      type: String,
      default: 'state'
    }
  },

  watch: {
    value () {
      this.model = this.value
    }
  },

  data () {
    return {
      model: this.value,

      groups: [],
      entities: []
    }
  },

  mounted () {
    this.$http
      .get(`${this.$config.api}/v2/logiceditor/logic_tree_elements?element_type=${this.elementType}`)
      .then((entities) => {
        if (Array.isArray(entities.data)) {
          this.entities = entities.data

          this.$http
            .get(`${this.$config.api}/v2/logiceditor/logic`)
            .then((groups) => {
              if (Array.isArray(groups.data)) {
                this.groups = []

                groups.data.forEach((group) => {
                  if (this.entities.filter(entity => entity.logic_id === group.id).length > 0) {
                    this.groups.push(group)
                  }
                })
              }
            })
        }
      })
  }
}
</script>

<style scoped>

</style>
