import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetMappings, GetMappingByGuid, GetMappingElementTypes } from '@/services/EtlEditor/infrastructure/store/modules/Mapping/action-types'
import MappingQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/MappingQueryRepositoryInterface'

export default class MappingQueryRepository extends AbstractQueryRepository implements MappingQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetMappings(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetMappingByGuid(guid))
  }

  getElementTypes (): Promise<any> {
    return store.dispatch(new GetMappingElementTypes())
  }
}
