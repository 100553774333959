import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  GetProcedures,
  GetProcedureByGuid
} from '@/services/LogicEditor/infrastructure/store/modules/Procedure/action-types'
import ProcedureQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/ProcedureQueryRepositoryInterface'

export default class ProcedureQueryRepository extends AbstractQueryRepository implements ProcedureQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetProcedures(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetProcedureByGuid(guid))
  }
}
