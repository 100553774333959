import ConnectionCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/ConnectionCommandRepositoryInterface'
import { ConnectionDTO } from '@/services/AccessEditor/domain/model/Connection/Connection'
import store from '@/store'
import { AddConnection, UpdateConnection, DeleteConnection } from '@/services/AccessEditor/infrastructure/store/modules/Connection/action-types'

export default class ConnectionCommandRepository implements ConnectionCommandRepositoryInterface {
  insert (item: ConnectionDTO): Promise<void> {
    return store.dispatch(new AddConnection(item))
  }

  update (item: ConnectionDTO): Promise<void> {
    return store.dispatch(new UpdateConnection(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteConnection(guid))
  }
}
