import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SnapshotRepositoryInterface from '../../../domain/repository/SnapshotRepositoryInterface'
import SnapshotsFilterQuery from '@/services/SyncService/application/query/SnapshotsFilterQuery'

export default class SnapshotsFilterHandler implements QueryHandlerInterface {
  private repo: SnapshotRepositoryInterface

  constructor (repo: SnapshotRepositoryInterface) {
    this.repo = repo
  }

  execute (query: SnapshotsFilterQuery): Promise<any> {
    return this.repo.filter(query.getPayload())
  }
}
