<template>
  <el-select v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="placeholder" size="mini">
    <el-option
      v-for="item in userList"
      :key="item.id"
      :label="item.full_name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
// import User from '../../../AccessEditor/Models/User'
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI.ts'

export default {
  name: 'UsersTable',

  props: ['value', 'placeholder'],

  data () {
    return {
      userList: []
    }
  },

  async mounted () {
    // this.userList = await new User().params({ fields: 'id,name,surname,midname' }).$get()
    try {
      this.userList = await APIClient.shared.request(new UserAPI.GetUsers())
    } catch (error) {
      console.log({ error })
    }
    this.userList.sort((a, b) => {
      if (a.surname > b.surname) {
        return 1
      } else if (a.surname < b.surname) {
        return -1
      }
      return 0
    })
  }
}
</script>
