import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SchemaRepositoryInterface from '@/services/ApiService/domain/repository/SchemaRepositoryInterface'
import CreateSchemaCommand from '@/services/ApiService/application/command/CreateSchemaCommand'

export default class CreateSchemaHandler implements CommandHandlerInterface {
  private repository: SchemaRepositoryInterface;

  constructor (repository: SchemaRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CreateSchemaCommand): Promise<void> {
    return this.repository.createSchema(command.getDto())
  }
}
