<template>
  <div class="menu_form">
    <el-form ref="form" :rules="rules" :model="value" size="mini" class="menuEditor" label-width="100%" label-position="top">
      <el-form-item :label="$locale.menu_editor.label.name" prop="name">
        <el-input v-model="value.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.menu_editor.label.description">
        <el-input v-model="value.description" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="is_collapsed">
        <el-checkbox v-model="value.is_collapsed" :label="$locale.menu_editor.label.collapsed"></el-checkbox>
      </el-form-item>
      <el-form-item :label="$locale.menu_editor.label.width" prop="width">
        <el-input v-model="value.width" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$locale.menu_editor.label.main_logo">
        <el-upload
          :headers="getHeaders()"
          class="upload-demo"
          ref="upload"
          list-type="picture-card"
          :action="$config.api + '/registryservice/files/'"
          :on-change="numberFiles"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :limit="1"
          :http-request="saveUpload"
          :show-file-list="true">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-input v-model="value.logo_size" :placeholder="$locale.interface_editor.settings.card.size_image" prop="logo_size" style="width: 200px;" />
      </el-form-item>
      <el-form-item :label="$locale.menu_editor.label.mini_logo">
        <el-upload
          :headers="getHeaders()"
          class="upload-demo"
          ref="uploadMini"
          list-type="picture-card"
          :action="$config.api + '/registryservice/files/'"
          :on-change="numberFilesMini"
          :before-remove="beforeRemoveMini"
          :on-exceed="handleExceedMini"
          :file-list="fileListMini"
          :on-remove="handleRemoveMini"
          :limit="1"
          :http-request="saveUploadMini"
          :show-file-list="true">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-input v-model="value.mini_logo_size" :placeholder="$locale.interface_editor.settings.card.size_image" prop="mini_logo_size" style="width: 200px;" />
      </el-form-item>

      <div style="text-align: right;">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-success"
          @click="submit()">
          {{ $locale.menu_editor.button.save }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RootMenuForm',

  props: ['value', 'save'],

  watch: {
    'value.width': function (newValue) {
      if (typeof newValue === 'string') {
        this.value.width = newValue.replace(/[^0-9]/g, '')
      }
    }
  },

  data () {
    return {
      form: { },
      fileList: [],
      fileListMini: [],
      rules: {
        name: [{ required: true, message: this.$locale.menu_editor.rules.name, trigger: 'blur' }],
        width: [{ required: true, message: this.$locale.menu_editor.rules.width, trigger: 'blur' }]
      }
    }
  },

  async created () {
    if (this.value.properties && this.value.properties.find(property => property.id === 'logo_id')?.value) {
      try {
        let image = await this.$http.get(`${this.$config.api}/registryservice/files/${this.value.properties.find(property => property.id === 'logo_id').value}`)
        this.fileList.push({
          name: this.getFileName(image.data),
          url: `${this.$config.api}/files/${this.getFilePath(image.data)}`
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (this.value.properties && this.value.properties.find(property => property.id === 'mini_logo_id')?.value) {
      try {
        let image = await this.$http.get(`${this.$config.api}/registryservice/files/${this.value.properties.find(property => property.id === 'mini_logo_id').value}`)
        this.fileListMini.push({
          name: this.getFileName(image.data),
          url: `${this.$config.api}/files/${this.getFilePath(image.data)}`
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.value.properties) {
      this.value.properties = []
    }
  },

  methods: {
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.save()
        }
      })
    },

    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    handleExceed (files, fileList) {
      this.$message.warning(this.$locale.menu_editor.label.warning_logo)
    },

    beforeRemove (file, fileList) {
      return this.$confirm(`${this.$locale.main.message.confirm}`)
    },

    handleRemove (file, fileList) {
      this.value.properties.forEach(item => {
        if (item.id === 'logo_id') item.value = null
      })
    },

    numberFiles (file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png' && file.raw.type !== 'image/svg+xml') {
        this.$refs.upload.uploadFiles = []
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
    },

    handleExceedMini (files, fileList) {
      this.$message.warning(this.$locale.menu_editor.label.warning_logo)
    },

    beforeRemoveMini  (file, fileList) {
      return this.$confirm(`${this.$locale.main.message.confirm}`)
    },

    handleRemoveMini (file, fileList) {
      this.value.properties.logoIdMini = ''
    },

    numberFilesMini  (file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png' && file.raw.type !== 'image/svg+xml') {
        this.$refs.uploadMini.uploadFiles = []
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
    },

    async saveUpload () {
      if (this.$refs.upload.uploadFiles.length) {
        let fileObj = this.$refs.upload.uploadFiles[0].raw
        const fd = new FormData()
        fd.append('file', fileObj)
        let res = await this.$http({
          url: this.$config.api + '/registryservice/files',
          data: fd,
          method: 'POST',
          hideNotification: true
        })
        this.$set(this.value, 'logo_id', res.data.id)
        //this.value.properties.find(property => property.id === 'logo_id').value = res.data.id
      }
    },

    async saveUploadMini () {
      if (this.$refs.uploadMini.uploadFiles.length) {
        let fileObj = this.$refs.uploadMini.uploadFiles[0].raw
        const fd = new FormData()
        fd.append('file', fileObj)
        let res = await this.$http({
          url: this.$config.api + '/registryservice/files',
          data: fd,
          method: 'POST',
          hideNotification: true
        })
        this.$set(this.value, 'mini_logo_id', res.data.id)
        //this.value.properties.find(property => property.id === 'mini_logo_id').value = res.data.id
      }
    }
  }
}
</script>

<style>
  .menuEditor .el-form-item.required label.el-form-item__label:before {
    content: '* ';
    color: #ff4949;
    font-weight: bold;
  }

  .menuEditor .upload-demo {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
</style>
