<template>
  <div class="flex flex-col h-full">
    <div class="px-5 pb-2.5 pt-4 border-b">
      <slot name="header"/>
    </div>
    <div class="flex-1 overflow-y-auto">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainLayout'
}
</script>

<style scoped lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
