<template>
  <div class="raster-folder-tree custom_scrollbar">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{$locale.raster_library.select_file_or_folder}}</span>
      </div>
      <el-tree :data="data" :props="defaultProps" v-loading="loading" @node-click="nodeClick"></el-tree>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{$locale.raster_library.description}}</span>
      </div>
      <el-input v-model="dto.description"></el-input>
      <el-checkbox v-model="dto.isMove">{{$locale.raster_library.move_file_to_storage}}</el-checkbox>
    </el-card>
    <el-footer>
      <el-button @click="submit">{{$locale.main.button.save}}</el-button>
      <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
    </el-footer>
  </div>
</template>

<script type="ts">
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RasterAPI } from '@/services/RasterLibrary/infrastructure/api/RasterAPI';

export default {
  name: 'RasterFolderTree',
  inject: ['getEventBus'],
  data() {
    return {
      data: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      loading: false,
      dto: {
        name: null,
        description: null,
        type: null,
        isMove: false
      }
    }
  },
  
  methods: {
    nodeClick(data) {
      this.dto.name = data.name;
      this.dto.full_name = data.full_name;
      this.dto.type = data.type;
    },
    close() {
      this.getEventBus().$emit('showRasterFolderTree', false);
    },
    async submit() {
        console.log(this.dto)
      if (!this.dto.name) {
        this.$message.error(this.$locale.raster_library.error_file_not_attached);
        return;
      }
      await APIClient.shared.request(new RasterAPI.AddRasterFromFolder(this.dto));
      this.getEventBus().$emit('loadRasters');
      this.close();
    },
    getFolderTree() {
      APIClient.shared.request(new RasterAPI.GetRastersFolderTree())
      .then(data => {
        this.data = data;
        this.loading = false;
      })
      .catch(reason => {
        this.loading = false;    
      });
    },
  },
  mounted() {
    this.getFolderTree();
  }
}
</script>