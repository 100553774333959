<template>
  <div class="log-service-wrapper">
    <div class="log-service-wrapper-event" v-show="show">
      <p>{{ eventMessage }}</p>
    </div>
    <el-tabs class="log-service-tabs-left" tab-position="left" type="border-card" v-show="!show">
      <el-tab-pane
        v-on:mouseover="test"
        v-for="service in services"
        class="log-service-tab-pane"
        :key=service.code
        :label=service.name
        :name=service.code
        lazy
      >
        <LogTable :resource=service.code :name=service.name />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LogTable from '@/services/LogService/infrastructure/components/LogTable/index.vue'
import ResourceQuery from '@/services/LogService/application/query/ResourceQuery'

export default {
  name: 'LogPanel',
  components: {
    LogTable
  },
  inject: ['getEventBus', 'getQueryBus'],
  data () {
    return {
      show: false,
      services: Array,
      eventMessage: ''
    }
  },
  methods: {
    async loadResources () {
      try {
        const result = await this.getQueryBus().execute(
          new ResourceQuery()
        )

        if (result.length === 0) {
          this.show = true
          this.eventMessage = this.$locale.log_service.event.no_resources
          return
        }

        this.services = result
      } catch (_err) {
        this.show = true
        this.eventMessage = this.$locale.log_service.event.error
      }
    },
    test () {
      return 'test'
    }
  },
  mounted () {
    this.loadResources()
  }
}
</script>
