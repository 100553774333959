import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CssClassCreateCommand from '@/services/CssEditor/application/command/CssClassCreateCommand'
import CssClassCommandRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassCommandRepositoryInterface'

export default class CssClassCreateCommandHandler implements CommandHandlerInterface {
  private repository: CssClassCommandRepositoryInterface;

  constructor (repository: CssClassCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CssClassCreateCommand): Promise<void> {
    return this.repository.insert(command.getDto())
  }
}
