import LogicTreeElementQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicTreeElementQueryRepositoryInterface'
import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'

export default class LogicTreeElementsSearchHandler implements QueryHandlerInterface {
  private repository: LogicTreeElementQueryRepositoryInterface;

  constructor (repository: LogicTreeElementQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: AbstractEntitiesQuery): Promise<any> {
    return this.repository.search(query.getPayload())
  }
}
