<template>
  <div class="formula_calculator">
    <div class="tools">
      <div class="tools__container">
        <div v-if="mergedTools.functions" class="tools-item">
          <el-select
            v-model="functionId"
            :placeholder="$t('main.calculator.labels.functions')"
            :filterable="true"
            size="small"
            clearable
            @change="addFunction()"
          >
            <el-option
              v-for="item in functions"
              :key="item.id"
              :label="$t('main.calculator.functions.' + item.name)"
              :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div v-if="mergedTools.operators" class="tools-item">
          <el-select
            v-model="operandId"
            :placeholder="$t('main.calculator.labels.operands')"
            :filterable="true"
            size="small"
            clearable
            @change="addOperand()"
          >
            <el-option
              v-for="item in operands"
              :key="item.id"
              :label="$t('main.calculator.operands.' + item.name)"
              :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div v-if="mergedTools.currentArguments" class="tools-item">
          <el-popover
            ref="currentArgumentPopover"
            placement="top-start"
            width="400"
            trigger="click"
          >
            <argument-current-table-form
              v-model="currentArgument"
              :fields="currentTableFields"
              :type="calculatorType"
              :alias-values="targetAliasValues"
              :add-argument="addCurrentArgument"
              :change-argument="changeCurrentArgument"
            ></argument-current-table-form>
          </el-popover>

          <el-button
            type="primary"
            size="small"
            v-popover:currentArgumentPopover
            v-text="$t('main.calculator.labels.current_arguments')"
          ></el-button>
        </div>

        <div v-if="mergedTools.externalArguments" class="tools-item">
          <el-popover
            v-if="calculatorType === 'column'"
            ref="externalArgumentPopover"
            placement="top-start"
            width="400"
            trigger="click"
          >
            <argument-external-table-form
              v-model="externalArgument"
              :fields="externalTableFields"
              :xref-fields="xrefFields"
              :type="calculatorType"
              :add-argument="addExternalArgument"
              :change-argument="changeExternalArgument"
              :change-xref-field="changeXrefField"
            ></argument-external-table-form>
          </el-popover>

          <el-button
            type="primary"
            size="small"
            v-popover:externalArgumentPopover
            v-text="$t('main.calculator.labels.external_arguments')"
          ></el-button>
        </div>
      </div>

      <div class="tools__container align-right">
        <!--<div class="tools-item">
          <el-tooltip class="item" effect="dark" :content="$t('main.calculator.labels.copy')" placement="top">
            <el-button
              size="small"
              v-clipboard:copy="model"
              v-clipboard:success="onSuccessCopy"
            >
              <font-awesome-icon :icon="icons.clipboard"></font-awesome-icon>
            </el-button>
          </el-tooltip>
        </div>

        <div class="tools-item">
          <el-tooltip class="item" effect="dark" :content="$t('main.calculator.labels.paste')" placement="top">
            <el-button
              size="small"
              @click="pasteCopyContent"
            >
              <font-awesome-icon :icon="icons.clone"></font-awesome-icon>
            </el-button>
          </el-tooltip>
        </div>-->

        <div class="tools-item">
          <el-tooltip class="item" effect="dark" :content="$t('main.calculator.labels.clear')" placement="top">
            <el-button type="danger" icon="el-icon-delete" @click="clear()" size="small"></el-button>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div
      ref="calculatorEditor"
      class="calculator_editor"
      contenteditable="true"
      :style="calculatorStyled"
      :placeholder="$t('main.calculator.labels.calculator')"
      v-on:click="cursorPosition"
      v-on:click.right.prevent="menuOpen('default', $event)"
      v-on:keyup="cursorPosition"
      v-on:input="performMark"
    ></div>

    <context v-if="hasContext" ref="contextMenu" :prop="contextProp" :items="contextItems" :menu-item-click="menuItemClick"></context>
  </div>
</template>

<script>
import TreeSelect from '@/components/Common/TreeSelect'
import ArgumentCurrentTableForm from '@/core/infrastructure/components/FormulaCalculator/ArgumentCurrentTableForm'
import ArgumentExternalTableForm from '@/core/infrastructure/components/FormulaCalculator/ArgumentExternalTableForm'

import markWord from './mark/perfomark'
import Context from './Context'

import { arrayRecursiveFind } from '@/core/infrastructure/components/FormulaCalculator/utils'

import defaultFunctions from './functions'
import defaultOperands from './operands'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClipboard, faClone } from '@fortawesome/free-solid-svg-icons'
library.add(faClipboard, faClone)

const defaultTools = {
  functions: true,
  operators: true,
  currentArguments: true,
  externalArguments: true
}

export default {
  name: 'FormulaCalculator',

  components: {
    Context,
    TreeSelect,
    FontAwesomeIcon,
    ArgumentCurrentTableForm,
    ArgumentExternalTableForm
  },

  props: {
    value: String,

    width: {
      type: Number,
      default: 0
    },

    height: {
      type: Number,
      default: 300
    },

    placeholder: {
      type: String,
      default: 'Calculator'
    },

    functions: {
      type: Array,
      default () {
        return defaultFunctions
      }
    },

    operands: {
      type: Array,
      default () {
        return defaultOperands
      }
    },

    toolSize: {
      type: String,
      default: 'small'
    },

    tools: {
      type: Object,
      default () {
        return defaultTools
      }
    },

    targetAliasValues: Array,

    calculatorType: {
      type: String,
      default: 'column',
      validator: function (value) {
        return ['column', 'row'].indexOf(value) !== -1
      }
    },

    objectId: Number
  },

  watch: {
    value () {
      this.model = this.value

      // if (this.$refs.calculatorEditor) {
      //   this.$refs.calculatorEditor.innerHTML = this.model
      // }
    },

    'externalArgument.related_object_id': {
      handler: function (value) {
        if (value !== null) {
        }
      }
    },

    'externalArgument.alias_field_id': {
      handler: function (value) {
        if (value !== null) {
          this.$http
            .get(`${this.$config.api}/objecteditor/entities/${value}`)
            .then((response) => {
              this.aliasField = response.data.data
            })
        }
      }
    },

    'aliasField': {
      handler: function (value) {
        if (value !== null) {
          this.$http
            .get(`${this.$config.api}/registryservice/xref/`, {
              params: {
                registry_id: this.objectId,
                attribute_id: value.id,
                distinct: true
              }
            })
            .then(response => {
              this.targetAliasValues = []
              if (response.status === 200) {
                this.targetAliasValues = this.parseXrefData(value.entity_type_id, response.data)
              }
            })
        }
      }
    }
  },

  computed: {
    calculatorStyled () {
      let styled = {
        height: `${this.height}px`
      }

      if (this.width !== 0) {
        styled.width = `${this.width}px`
      }

      return styled
    },

    xrefType () {
      if (!this.dslStatement.xrefField || !this.dslStatement.outerField) {
        return null
      }

      if (this.dslStatement.xrefField.entity_type_id === 'xref_multi_field') {
        return 'multi'
      } else if (this.dslStatement.xrefField.entity_type_id === 'xref_field') {
        return 'single'
      } else {
        if (this.dslStatement.outerField.entity_type_id === 'xref_multi_field') {
          return 'multi'
        } else {
          return 'single'
        }
      }
    },

    hasContext () {
      return this.contextItems.length > 0
    }
  },

  data () {
    return {
      model: this.value,

      functionId: null,
      operandId: null,

      menuType: 'default',

      caretPosition: {},

      copyContent: null,

      icons: {
        clone: faClone,
        clipboard: faClipboard
      },

      mergedTools: Object.assign({}, defaultTools, this.tools),

      currentArgument: { field_id: null, target_alias_value: null, filter_field_id: null },
      externalArgument: { related_object_id: null, object_id: null, xref_field_id: null, field_id: null },

      dslStatement: {
        xrefField: null,
        xrefPropertyField: null,
        outerField: null,
        argument: null,

        function: null,
        operand: null
      },

      contextProp: {
        key: 'id',
        label: 'name',
        children: 'children'
      },

      currentTableFields: [],
      externalTableFields: [],
      xrefFields: [],

      treeProps: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children'
      },

      contextItems: []
    }
  },

  mounted () {
    if (this.mergedTools.functions) {
      let menuItem = {
        id: 'function',
        name: this.$t('main.calculator.context.function_paste'),
        children: []
      }
      this.functions.forEach(item => {
        menuItem.children.push({
          id: item.id,
          name: this.$t('main.calculator.functions.' + item.name)
        })
      })
      this.contextItems.push(menuItem)
    }

    if (this.mergedTools.operators) {
      let menuItem = {
        id: 'operator',
        name: this.$t('main.calculator.context.operator_paste'),
        children: []
      }
      this.operands.forEach(item => {
        menuItem.children.push({
          id: item.id,
          name: this.$t('main.calculator.operands.' + item.name)
        })
      })
      this.contextItems.push(menuItem)
    }

    if (this.mergedTools.currentArguments) {
      this
        .$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then(response => {
          this.currentTableFields = response.data.data
        })
    }

    if (this.mergedTools.externalArguments) {
      this
        .$http
        .get(`${this.$config.api}/objecteditor/entities?object_id=${this.objectId}&type=xref_outer_field,xref_field,xref_multi_field`)
        .then(response => {
          this.xrefFields = response.data.data
        })
    }

    this.$nextTick(() => {
      this.$refs.calculatorEditor.innerHTML = this.model
      this.$refs.calculatorEditor.focus()
    })
  },

  methods: {
    paste (event) {
      let pasteData = ''

      if (window.clipboardData && window.clipboardData.getData) {
        pasteData = window.clipboardData.getData('Text');
      } else if (event.clipboardData && event.clipboardData.getData) {
        pasteData = event.clipboardData.getData('text/html');
      }

      this.$refs.calculatorEditor.focus()
      this.$refs.calculatorEditor.append(pasteData)

      event.preventDefault()
    },

    onSuccessCopy () {
      this.$message({
        message: this.$t('main.calculator.hints.successCopy'),
        type: 'success'
      })
    },

    // context menu
    menuOpen (menuType, event) {
      this.menuType = menuType

      if (this.hasContext) {
        this.$refs.contextMenu.open(event)
      }
    },

    menuClose () {
      this.$refs.contextMenu.close()
    },

    menuItemClick (itemId) {
    },

    // current table field
    changeCurrentArgument (value) {
      this.dslStatement.argument = arrayRecursiveFind(this.currentTableFields, value)
    },

    addCurrentArgument () {
      if (this.dslStatement.argument) {
        this.pasteArgCurrentTable()
      }
    },

    // external table field
    changeExternalArgument (value) {
      this.dslStatement.argument = arrayRecursiveFind(this.externalTableFields, value)
    },

    clear () {
      this.$emit('input', null)
    },

    changeXrefField (value) {
      if (value) {
        this.dslStatement.xrefField = value
        let xrefProp = this.dslStatement.xrefField.properties.find(prop => prop.id === 'xref')
        if (xrefProp) {
          this.dslStatement.xrefPropertyField = xrefProp.value
          this
            .$http
            .get(`${this.$config.api}/objecteditor/entities/${xrefProp.value}`)
            .then((res1) => {
              this.dslStatement.outerField = res1.data.data
              this
                .$http
                .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.dslStatement.outerField.object_id}&show_children=true`)
                .then((res2) => {
                  this.externalTableFields = res2.data.data
                })
            })
        }
      }
    },

    addExternalArgument () {
      if (this.dslStatement.argument) {
        this.pasteArgExternalTable()
      }
    },

    addFunction () {
      if (!this.functionId) {
        return
      }

      this.dslStatement.function = this.functions.find(fun => fun.id === this.functionId)

      if (this.dslStatement.function) {
        this.pasteMathFunction()
      }

      this.functionId = null
    },

    addOperand () {
      if (!this.operandId) {
        return
      }

      this.dslStatement.operand = this.operands.find(operand => operand.id === this.operandId)

      if (this.dslStatement.operand) {
        this.pasteMathOperand()
      }

      this.operandId = null
    },

    // inserting chips
    pasteCopyContent (event) {
      const clipboard = (event.clipboardData || window.clipboardData).getData('text')

      if (clipboard) {
        this.$refs.calculatorEditor.focus()
        this.caretPosition = {}

        const selection = window.getSelection()
        if (selection) {
          if (!selection.rangeCount) {
            return
          }
          selection.deleteFromDocument();
          selection.getRangeAt(0).insertNode(document.createTextNode(clipboard))
        }
      }

      const selection = window.getSelection()
      if (selection) {
        if (!selection.rangeCount) {
          return
        }

        const el = document.createElement('div')
        el.innerHTML = html
        let frag = document.createDocumentFragment()
        let node
        let textNode = document.createTextNode('')
        while ((node = el.firstChild)) {
          frag.appendChild(textNode)
          frag.appendChild(node)
          frag.appendChild(textNode)
        }
        if (typeof this.caretPosition.range !== 'undefined') {
          this.caretPosition.range.deleteContents()
          this.caretPosition.range.insertNode(frag)
        } else {
          this.$refs.calculatorEditor.appendChild(frag)
        }
      }
    },

    pasteMathFunction () {
      this.$refs.calculatorEditor.focus()
      this.pasteHtmlAtCaret(`<span class="accent-function" function="${this.dslStatement.function.id}" contenteditable="false">${this.$t('main.calculator.functions.' + this.dslStatement.function.name)}<i class="remove" contenteditable="false"></i></span>`)
      this.caretPosition = {}
    },

    pasteMathOperand () {
      this.$refs.calculatorEditor.focus()
      this.pasteHtmlAtCaret(`<span class="accent-operator" statement="${this.dslStatement.operand.id}" contenteditable="false">${this.$t('main.calculator.operands.' + this.dslStatement.operand.name)}<i class="remove" contenteditable="false"></i></span>`)
      this.caretPosition = {}
    },

    pasteArgCurrentTable () {
      this.$refs.calculatorEditor.focus()
      let argumentLabel = this.dslStatement.argument.name.length > 24 ? this.dslStatement.argument.name.substring(0, 24) + '...' : this.dslStatement.argument.name
      this.pasteHtmlAtCaret(`<span class="accent-argument" field_id="${this.dslStatement.argument.id}" title="${this.dslStatement.argument.name}" field_type="${this.dslStatement.argument.entity_type_id}" contenteditable="false">${argumentLabel}<i class="remove" contenteditable="false"></i></span>`)
      this.caretPosition = {}
    },

    pasteArgExternalTable () {
      this.$refs.calculatorEditor.focus()
      const concatObjects = `${this.dslStatement.xrefField.name}.${this.dslStatement.argument.name}`
      let argumentLabel = concatObjects.length > 24 ? concatObjects.substring(0, 24) + '...' : concatObjects
      this.pasteHtmlAtCaret(`<span class="accent-argument" xref_id="${this.dslStatement.xrefPropertyField}" field_id="${this.dslStatement.argument.id || null}" join_field_id="${this.dslStatement.xrefField.id}" object_id="${this.dslStatement.outerField.object_id}" field_type="${this.dslStatement.argument.entity_type_id || null}" xref_type="${this.xrefType}" title="${this.dslStatement.xrefField.name}.${this.dslStatement.argument.name || ''}" contenteditable="false">${argumentLabel}<i class="remove" contenteditable="false"></i></span>`)
      this.caretPosition = {}
    },

    pasteHtmlAtCaret (html) {
      if (window.getSelection) {
        const el = document.createElement('div')
        el.innerHTML = html
        let frag = document.createDocumentFragment()
        let node
        let textNode = document.createTextNode('')
        while ((node = el.firstChild)) {
          frag.appendChild(textNode)
          frag.appendChild(node)
          frag.appendChild(textNode)
        }
        if (typeof this.caretPosition.range !== 'undefined') {
          this.caretPosition.range.deleteContents()
          this.caretPosition.range.insertNode(frag)
        } else {
          this.$refs.calculatorEditor.appendChild(frag)
        }
      }
    },

    // events for editor
    performMark () {
      markWord(this.$refs.calculatorEditor)

      this.$emit('input', this.$refs.calculatorEditor.innerHTML)
    },

    // blurEditor () {
    //   this.$emit('input', this.$refs.calculatorEditor.innerHTML)
    // },

    cursorPosition (e) {
      this.menuClose()
      if (['accent-function', 'accent-operator', 'accent-argument', 'accent-index'].indexOf(e.target.className) !== -1) {
        return false
      }
      let sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        this.caretPosition.range = sel.getRangeAt(0)
      }
      if (e.target.className === 'remove') {
        e.target.parentElement.remove()
      }
    }
  }
}
</script>

<style lang="scss">
@import "./FormulaCalculator.scss";
</style>
