import CommandInterface from '@/core/application/command/CommandInterface'

export default class LogicGroupDeleteCommand implements CommandInterface {
  private guid: string

  constructor (guid: string) {
    this.guid = guid
  }

  getClassName (): string {
    return 'LogicGroupDeleteCommand'
  }

  getGuid (): string {
    return this.guid
  }
}
