import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetLoaders, GetLoaderByGuid, GetLoaderTypes, GetLoaderTypeProperties } from '@/services/EtlEditor/infrastructure/store/modules/Loader/action-types'
import LoaderQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/LoaderQueryRepositoryInterface'

export default class LoaderQueryRepository extends AbstractQueryRepository implements LoaderQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetLoaders(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetLoaderByGuid(guid))
  }

  getTypes (): Promise<any> {
    return store.dispatch(new GetLoaderTypes())
  }

  getTypeProperties (type: string): Promise<any> {
    return store.dispatch(new GetLoaderTypeProperties(type))
  }
}
