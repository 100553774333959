
import MigrationsQuery from '@/services/SyncService/application/query/MigrationsQuery'
import MigrationsCountQuery from '@/services/SyncService/application/query/MigrationsCountQuery'
import ExportWindow from '@/services/SyncService/infrastructure/components/Forms/ExportWindow.vue'
import ImportWindow from '@/services/SyncService/infrastructure/components/Forms/ImportWindow.vue'
import ExportConfigurationElementsWindow
  from '@/services/SyncService/infrastructure/components/Forms/ExportConfigurationElementsWindow.vue'
import ImportConfigurationElementsWindow
  from '@/services/SyncService/infrastructure/components/Forms/ImportConfigurationElementsWindow.vue'

export default {
  name: 'SnapshotPanel',
  components: {
    ExportConfigurationElementsWindow,
    ImportConfigurationElementsWindow,
    ExportWindow,
    ImportWindow
  },
  data () {
    return {
      loading: false,
      informationPanelHeight: 0,
      migrations: [],
      migration: null,
      migrationsCount: 0,
      migrationsCurrentPage: 0,
      migrationsPageLimit: 100,
      migrationsPageSize: 0,
      allMigrationsCount: 0,
      showImportWindow: false,
      showExportWindow: false,
      showExportConfigurationElementsWindow: false,
      showImportConfigurationElementsWindow: false,
      activeCollapseName: ['error'],
      previousExportedEntities: {}
    }
  },
  inject: ['getEventBus', 'getQueryBus'],
  methods: {
    loadMigrations () {
      this.loading = true
      if (this.allMigrationsCount === 0) {
        this.countAndLoadMigrations()
      } else {
        this.loadAllMigrations()
      }
    },
    async countAndLoadMigrations () {
      await this.getQueryBus().execute(
        new MigrationsCountQuery()
      ).then(data => {
        this.migrationsCount = data[0].count
        this.loadAllMigrations()
      })
    },
    async loadAllMigrations () {
      await this.getQueryBus().execute(
        new MigrationsQuery({
          limit: this.migrationsPageLimit,
          offset: this.migrationsCurrentPage
        })
      ).then(data => {
        this.migrationsPageSize = this.migrationsPageLimit
        this.migrations = data
        this.loading = false
      })
    },
    handleMigrationsPageChange (val) {
      val--
      this.migrationsCurrentPage = (val * this.migrationsPageLimit)
      this.loadMigrations()
    },
    getPreviousExportedEntities (entities) {
      try {
        this.previousExportedEntities = entities
        this.showExportConfigurationElementsWindow = true
      } catch (e) {
        console.log(e)
      }
    },
    clearPreviousExportedEntities () {
      this.previousExportedEntities = {}
    }
  },
  mounted () {
    this.loadMigrations()
  }
}
