import CssClassQueryRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassQueryRepositoryInterface'
import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'

export default class CssClassesSearchQueryHandler implements QueryHandlerInterface {
  private repo: CssClassQueryRepositoryInterface;

  constructor (repository: CssClassQueryRepositoryInterface) {
    this.repo = repository
  }

  execute (query: AbstractEntitiesQuery): Promise<any> {
    return this.repo.search(query.getPayload())
  }
}
