import RegistryFieldsQueryRepositoryInterface
  from '@/services/InterfaceEditor/domain/repository/RegistryFieldsQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RegistryFieldsQuery from '@/services/InterfaceEditor/application/query/RegistryFieldsQuery'

export default class RegistryFieldsHandler implements QueryHandlerInterface {
  private repository: RegistryFieldsQueryRepositoryInterface

  constructor (repository: RegistryFieldsQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: RegistryFieldsQuery): Promise<any> {
    return this.repository.getByRegistryId(query.getRegistryId())
  }
}
