
import Vue from 'vue'
import Report from '@/services/LogicEditor/infrastructure/components/elements/Report.vue'
import Topic from '@/services/LogicEditor/infrastructure/components/elements/Topic.vue'
import FieldSelect from '@/services/LogicEditor/infrastructure/components/elements/FieldSelect.vue'
import PagePlacement from '@/services/LogicEditor/infrastructure/components/elements/PagePlacement.vue'

export default Vue.extend({
  name: 'EmbedImageToPdfEventForm',

  props: ['value', 'objectId'],

  components: {
    Report,
    Topic,
    FieldSelect,
    PagePlacement
  },

  computed: {
    localeMarginX () {
      if (['rightTop', 'rightCenter', 'rightBottom'].includes(this.model.placement)) {
        return this.$t('logic_editor_v2.labels.margin_right')
      }

      return this.$t('logic_editor_v2.labels.margin_left')
    },

    localeMarginY () {
      if (['leftBottom', 'rightBottom', 'centerBottom'].includes(this.model.placement)) {
        return this.$t('logic_editor_v2.labels.margin_bottom')
      }

      return this.$t('logic_editor_v2.labels.margin_top')
    },

    // pageTypes () {
    //   return [
    //     { value: 'first', label: this.$t('logic_editor_v2.pageTypes.first') },
    //     { value: 'last', label: 'Last' },
    //     { value: 'all', label: 'All' },
    //     { value: 'custom', label: 'Custom' }
    //   ]
    // }
  },

  data () {
    return {
      model: this.value,
      rules: {
        template_id: [
          { required: true, message: 'Select template', trigger: 'change' }
        ],
        target_file_field_id: [
          { required: true, message: 'Select file field', trigger: 'change' }
        ],
        file_field_id: [
          { required: true, message: 'Select file field', trigger: 'change' }
        ]
      },
      pageTypes: ['first', 'last', 'all', 'custom'],
      placements: [
        'leftTop', 'centerTop', 'rightTop',
        'leftCenter', 'center', 'rightCenter',
        'leftBottom', 'centerBottom', 'rightBottom'
      ]
    }
  },

  methods: {
    changePlacement (value) {
      if (['centerTop', 'center', 'centerBottom'].includes(value)) {
        this.model.margin_x = 0
      }

      if (['leftCenter', 'center', 'rightCenter'].includes(value)) {
        this.model.margin_y = 0
      }
    },

    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    }
  }
})
