import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import LayerTreeElementType from './LayerTreeElementType'
import Layer from './Layer'
import LayerGroup from './LayerGroup'


export class LayerTreeElementDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    row_order?: Nullable<number>;
    parent_id?: Nullable<number>;
    tree_id?: Nullable<number>;
    project_id?: Nullable<number>;
    layer_id?: Nullable<number>;
    group_id?: Nullable<number>;
    element_type?: Nullable<string>;
    element_guid?: Nullable<string>;
    layer_type_id?: Nullable<string>;

    constructor({ project_id, layer_id, parent_id}: { project_id?: number, layer_id?: Nullable<number>, parent_id?: Nullable<number>}) {
        this.project_id = project_id;
        this.layer_id = layer_id;
        this.parent_id = parent_id;
    }
}

export default class LayerTreeElement extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name?: Nullable<string>;
    private description?: Nullable<string>;
    private rowOrder?: Nullable<number> = 0;
    private parentId?: Nullable<number>;
    private treeId?: Nullable<number>;
    private projectId?: Nullable<number>;
    private groupId?: Nullable<number>;
    private elementType?: Nullable<string>;
    private elementGuid?: Nullable<string>;
    private layerType?: Nullable<string>;
    private layer?: Nullable<Layer>;
    private group?: Nullable<LayerGroup>;

    constructor(
        id?: Nullable<number>,
        guid?: Nullable<string>,
        name?: Nullable<string>,
        description?: Nullable<string>,
        rowOrder?:Nullable<number>,
        parentId?: Nullable<number>,
        treeId?: Nullable<number>,
        projectId?: Nullable<number>,
        groupId?: Nullable<number>,
        elementType?: Nullable<string>,
        layerType?: Nullable<string>,
        elementGuid?: Nullable<string>
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
        this.rowOrder = rowOrder;
        this.parentId = parentId;
        this.treeId = treeId;
        this.projectId = projectId;
        this.groupId = groupId;
        this.elementType = elementType;
        this.elementGuid = elementGuid;
        if (elementType == LayerTreeElementType.LAYER) {
            this.layer = Layer.create({guid: elementGuid, name: name, description: description, type: layerType});
            this.group = null;
        } else if (elementType == LayerTreeElementType.GROUP) {
            this.group = LayerGroup.create({guid: elementGuid, name: name});
            this.layer = null;
        }
    }

    static create(dto: LayerTreeElementDTO): LayerTreeElement
    {
        return new LayerTreeElement(
            dto.id,
            dto.guid,
            dto.name,
            dto.description,
            dto.row_order,
            dto.parent_id,
            dto.tree_id,
            dto.project_id,
            dto.group_id,
            dto.element_type,
            dto.layer_type_id,
            dto.element_guid
        );
    }

    getName(): string
    {
        return this.name;
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    getElementGuid(): string
    {
        return this.elementGuid;
    }

    setRowOrder(rowOrder: number): void
    {
        this.rowOrder = rowOrder;
    }

    getRowOrder(): number
    {
        return this.rowOrder;
    }

    getLayer(): Nullable<Layer>
    {
        return this.layer;
    }

    getProjectId(): Nullable<number>
    {
        return this.projectId;
    }

    getGroupId(): Nullable<number>
    {
        return this.groupId;
    }

    getGroup(): Nullable<LayerGroup>
    {
        return this.group;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }

    getElementType(): string
    {
        return this.elementType;
    }
}