<template>
  <vue-context ref="context">
    <li :class="{ 'v-context__sub': item[prop.children] && item[prop.children].length }" v-for="item in items" :key="item[prop.key]">
      <a href="#" @click.prevent="menuItemClick(item[prop.key])">{{ item[prop.label] }}</a>
      <context-child v-if="item[prop.children] && item[prop.children].length" :items="item[prop.children]" :menu-item-click="menuItemClick" :prop="prop"></context-child>
    </li>
  </vue-context>
</template>

<script>
import VueContext from 'vue-context'
import ContextChild from './ContextChild'
import 'vue-context/src/sass/vue-context.scss'

export default {
  name: 'Context',

  props: {
    items: Array,

    prop: {
      type: Object,
      default () {
        return {
          key: 'key',
          label: 'label',
          children: 'children'
        }
      }
    },

    menuItemClick: {
      type: Function,
      default () {
        return function (itemId) {
        }
      }
    }
  },

  components: {
    VueContext,
    ContextChild
  },

  methods: {
    open (event) {
      this.$refs.context.open(event)
    },

    close () {
      this.$refs.context.close()
    }
  }
}
</script>

<style scoped></style>
