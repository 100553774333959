<template>
  <el-form-item :label="label" :label-width="titleWidth">
    <el-input v-bind:value="value" v-on:input="$emit('input', $event || null)" :placeholder="label" :size="size"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'prop-varchar',

  props: ['value', 'size', 'label', 'titleWidth']
}
</script>

<style type="text/css">

</style>
