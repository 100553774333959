<template>
  <el-form-item :label="title" :label-width="titleWidth">
    <tree-select
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      node-key="id"
      clearable
      :data="data"
      :props="props"
      :render-content="renderTree"
      :placeholder="$t('main.placeholder.select')"
    ></tree-select>
  </el-form-item>
</template>

<script>
import TreeSelect from '../../Common/TreeSelect'

export default {
  name: 'FileField',

  props: ['value', 'size', 'title', 'titleWidth', 'registryId'],

  components: {
    TreeSelect
  },

  watch: {
    registryId () {
      this.loadObjects()

      this.$emit('input', null)
    }
  },

  data () {
    return {
      data: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id !== 'file_field'
        }
      }
    }
  },

  mounted () {
    this.loadObjects()
  },

  methods: {
    loadObjects () {
      if (this.registryId !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.registryId}&show_children=true`)
          .then(response => { this.data = response.data.data })
          .catch(error => console.log(error))
      }
    },

    renderTree (h, { node, data, store }) {
      const attr = `attr_${data.id}_`

      return h('span', {
        class: {
          'custom-tree-node': true
        }
      }, [
        h('span', node.label),
        h(
          'span',
          {
            class: 'el-tree-node__attribute'
          },
          attr
        )
      ])
    }
  }
}
</script>
