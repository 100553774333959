import ApprovalStage from '@/services/BPMNEditorApprovals/domain/model/ApprovalStage'
import BPMNService from '@/services/BPMNEditorApprovals/infrastructure/service/BPMNService'

export default class ApprovalStageJSONAdapter {
  static adapt (json: {
    id, name, guid, approve_commands, cancel_commands, approval_blocking_stages
  }, commandMapper: Map<number, {
    guid, event_id, name
  }>, BPMNService: BPMNService): ApprovalStage {
    const approvalStage = new ApprovalStage(json.name)
    approvalStage.id = json.id
    approvalStage.guid = json.guid

    const [approvalStageElement, approveGateway, cancelGateway] = BPMNService.createElement({
      type: 'ApprovalStage',
      properties: {
        accentGuid: approvalStage.guid,
        accentType: 'approval_stage'
      },
      name: approvalStage.name
    })

    approvalStage.blockedStages = json.approval_blocking_stages
    approvalStage.element = approvalStageElement
    approvalStage.cancelGateway = cancelGateway
    approvalStage.approveGateway = approveGateway

    approvalStage.approveCommandElements = (json.approve_commands || []).map((commandId) =>
      this.buildCommandElement(commandMapper.get(commandId), BPMNService))

    approvalStage.cancelCommandElements = (json.cancel_commands || []).map((commandId) =>
      this.buildCommandElement(commandMapper.get(commandId), BPMNService))

    return approvalStage
  }

  private static buildCommandElement (data: {
    guid, event_id, name
  }, BPMNService: BPMNService) {
    return BPMNService.createElement({
      type: 'ServiceTask',
      properties: {
        accentGuid: data.guid,
        accentType: 'command',
        accentSubType: data.event_id
      },
      name: data.name
    })
  }
}
