import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import {
  ConfigurationElementsExport,
  GetEditorEntities
} from '@/services/SyncService/infrastructure/store/modules/ConfigurationElement/action-types'
import ConfigurationElementRepositoryInterface from '@/services/SyncService/domain/repository/ConfigurationElementRepositoryInterface'

export default class ConfigurationElementRepository extends AbstractQueryRepository implements ConfigurationElementRepositoryInterface {
  getEditorEntities (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetEditorEntities(payload))
  }

  export (payload: string[][] | object): Promise<any> {
    return store.dispatch(new ConfigurationElementsExport(payload))
  }
}
