import { render, staticRenderFns } from "./TaskCreate.vue?vue&type=template&id=7b51c6ef&"
import script from "./TaskCreate.vue?vue&type=script&lang=js&"
export * from "./TaskCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports