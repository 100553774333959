import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import RasterQueryRepositoryInterface from '@/services/RasterLibrary/domain/repository/RasterQueryRepositoryInterface'
import store from '@/store'
import { GetRasters, GetRasterByGuid, CountRasters } from '@/services/RasterLibrary/infrastructure/store/modules/Raster/action-types'

export default class RasterQueryRepository extends AbstractQueryRepository implements RasterQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetRasters(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetRasterByGuid(guid));
    }

    getCount(payload: string[][]): Promise<any>
    {
        return store.dispatch(new CountRasters(payload));
    }
}