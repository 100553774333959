<template>
  <div class="select-panel" ref="project_select_panel">
    <el-dialog
      :visible.sync="isCreateProjectWindowVisible"
      width="30%"
      height="400px"
      ref="createProjectWindow"
      class="create-project-window"
      :modal="false">      
      <span>
        <template v-if="projectDto != null">
          <el-form :rules="createProjectRules" ref="projectForm" :model="projectDto" :disabled="isProjectLoading" size="mini" label-width="100px" labelPosition="right">
            <el-form-item prop="name" :label="$locale.main.fields.name">
              <el-input v-model="projectDto.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="description" type="textarea" :label="$locale.main.fields.description">
              <el-input type="textarea" v-model="projectDto.description" autocomplete="off"></el-input>
            </el-form-item>          
          </el-form>
        </template>
      </span>
      <span slot="footer" class="dialog-footer">
        <span v-loading="true" v-if="isProjectLoading"></span>
        <el-button icon="el-icon-close" :disabled="isProjectLoading" size="small" @click="isCreateProjectWindowVisible = false">{{$locale.main.button.cancel}}</el-button>
        <el-button icon="el-icon-success" :disabled="projectDto == null || isProjectLoading" size="small" @click="saveProject" type="primary">{{$locale.main.button.save}}</el-button>
      </span>
      <div slot="footer" v-if="projectError && projectErrorVisible" class="project-error-message"><a v-clipboard:copy="projectError.message" v-clipboard:success="clipboardCopy" href="#"><i class="el-icon-warning"></i> {{$locale.main.errors[projectError.code]}}</a></div>    
    </el-dialog>
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createProject"></el-button>
      <el-button icon="el-icon-edit" size="mini" circle  @click="editProject"></el-button>
      <el-button icon="el-icon-delete" size="mini" circle  @click="deleteProject"></el-button>
      <el-checkbox v-if="!hideAll" v-model="allProjects" :label="$locale.map_editor.project_panel.all_projects"></el-checkbox>
      <el-input
        :placeholder="$locale.map_editor.shared.search_by_name"
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchByName">
      </el-input>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="projects"
          stripe
          border
          ref="projects_table"
          row-key="rownumber"
          current-row-key="rownumber"
          highlight-current-row
          @current-change="changeProject"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="user_fullname"
          :label="$locale.main.fields.user_name"
          width="180"
        ></el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="project-pagination"
            :page-size="projectsPageSize"
            :layout="allProjects ? 'total, prev, pager, next' : 'total'"
            :total="projectsCount"
            @current-change="handleProjectsPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
    </el-row>     
  </div>
</template>

<script type="ts">
import ProjectsQuery from '@/services/MapEditor/application/query/ProjectsQuery'
import Project, { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import ProjectTreeElement from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'
import ProjectCreateCommand from '@/services/MapEditor/application/command/ProjectCreateCommand'
import ProjectUpdateCommand from '@/services/MapEditor/application/command/ProjectUpdateCommand'
import ProjectDeleteCommand from '@/services/MapEditor/application/command/ProjectDeleteCommand'
import ProjectTreeElementByGuidQuery from '@/services/MapEditor/application/query/ProjectTreeElementByGuidQuery'
import ProjectTreeElementProjectPutCommand from '@/services/MapEditor/application/command/ProjectTreeElementProjectPutCommand'

export default {
  name: 'ProjectSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'hideAll'],
  components: {},
  data() {
    return {
      loading: false,
      projectsPageSize: 0,
      defaultProjectsPageSize: 100,
      projectsCurrentPage: 0,
      projectsPageLimit: 100,
      projectsCount: 0,
      allProjectsCount: 0,
      allProjects: false,
      projects: [],
      project: null,
      projectDto: null,
      informationPanelHeight: 0,
      isCreateProjectWindowVisible: false,
      projectErrorVisible: false,
      searchName: null,
      createProjectRules: {
        element_type: {
          required: true,
          message: this.$locale.map_editor.errors.type_required,
          trigger: 'change'
        },
        name: {
          required: true,
          message: this.$locale.map_editor.errors.name_required,
          trigger: 'change'
        }
      }
    }
  },
  computed: {
    projectCreated() {
      return this.$store.getters['Project/getLocation'];
    },
    isProjectLoading() {
      return this.$store.getters['Project/isLoading'];     
    },
    projectError() {
      return this.$store.getters['Project/getError']; 
    }
  },
  watch: {
    isProjectLoading: (state) => state,
    projectError: function (error) {
      this.projectErrorVisible = true;
      return error;
    },
    allProjects (val) {
      this.project = null;
      this.projectDto = null;
      this.$refs.projects_table.setCurrentRow(null);
      if (val) {        
        this.projectsPageSize = this.defaultProjectsPageSize;
      }
      this.loadProjects();
    },
    projectCreated (location) {
      this.getQueryBus().execute(
        new ProjectTreeElementByGuidQuery(
          location.replace('/project_tree_elements/', '')
        )
      ).then((elementDto) => {
        let element = ProjectTreeElement.create(elementDto);
        this.project = element.getProject();
        this.projectDto = new ProjectDTO({
          id: this.project.getId(),
          guid: this.project.getGuid(),
          name: this.project.getName(),
          description: this.project.getDescription()
        })
        this.refreshProject(this.project);        
      });
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.project_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.project_select_panel.clientHeight - 36;
      }
    },
    async refreshProject(project) {
      await this.loadProjects(() => {
        this.selectProject(project);
        this.isCreateProjectWindowVisible = false;
      });      
    },
    selectProject(project) {
      let rows = this.projects.filter(el => {
        return el.guid == project.getGuid()
      });
      if (rows.length) {
        this.$refs.projects_table.setCurrentRow(rows[0]);
      }
    },
    changeProject(record) {
      if (record != null) {
        this.projectDto = record;
        this.project = Project.create(this.projectDto);
      } else {
        this.projectDto = null;
        this.project = null;
      }      
    },
    submit(callback) {
      if (this.project == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.getCommandBus().execute(
          new ProjectTreeElementProjectPutCommand(
            this.project.getId(),
            null
          )
        ).then((response) => {
          this.getEventBus().$emit('projectSelected', this.project, this.formId);
          if (typeof callback == 'function') {
            callback();
          }
        });
      }
    },
    createProject() {
      this.projectDto = new ProjectDTO({});
      this.projectErrorVisible = false;
      this.isCreateProjectWindowVisible = true;
    },
    editProject() {
      if (this.project == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.projectErrorVisible = false;
        this.isCreateProjectWindowVisible = true;
      }
    },
    deleteProject() {
      if (this.project == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ProjectDeleteCommand(
              this.project.getGuid()
            )
          ).then((response) => {
            this.project = null;
            this.projectDto = null;
            this.$refs.projects_table.setCurrentRow(null);
            this.loadProjects();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    handleProjectsPageChange(val) {
      val--;
      this.projectsCurrentPage = (val * this.projectsPageLimit);
      this.loadProjects();
    },
    loadProjects(callback) {
      if (this.allProjects) {
        this.countAndLoadProjects(callback);
      } else {
        this.loadProjectsData(callback);
      }
    },
    async countAndLoadProjects(callback) {
      await this.getQueryBus().execute(
        new ProjectsQuery({ '*' : { 'func' : 'count' }, all : 1 })
      ).then(data => {
        this.projectsCount = data[0].count;      
        this.loadProjectsData(callback);
      });
    },
    async loadProjectsData(callback) {
      let payload = { all : 0 };
      if (this.allProjects) {
        payload = {
          all: 1,
          limit: this.projectsPageLimit,
          offset: this.projectsCurrentPage
        };
      }
      await this.getQueryBus().execute(
        new ProjectsQuery(payload)
      ).then(data => {
        if (!this.allProjects) {
          this.projectsCount = data.length;
        }
        this.projectsPageSize = this.projectsPageLimit;
        this.projects = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    saveProject() {
      let form = this.$refs.projectForm;
      form.validate((valid, invalidFields) => {
        if (valid) {
          let project = Project.create(this.projectDto);
          if (typeof project.getId() !== "undefined") {
            this.getCommandBus().execute(
              new ProjectUpdateCommand(
                project.getGuid(),
                project.getName(),
                project.getDescription()
              )
            ).then((response) => { 
              this.isCreateProjectWindowVisible = false;
              this.refreshProject(project);
            });
          } else {
            this.getCommandBus().execute(
              new ProjectCreateCommand(                
                project.getName(),
                project.getDescription()
              )
            ).then((response) => { 
              
            });
          }
        }
      });
    },
    async searchByName() {
      let payload = {};
      if (this.allProjects) {
        payload["all"] = 1;
        payload["limit"] = this.projectsPageLimit;
        payload["offset"] = this.projectsCurrentPage;
      } else {
        payload["all"] = 0;
      }
      if (this.searchName != null && this.searchName != "") {
        payload["where"] = {
          "and": [
            {
              "like": { "name": `%${this.searchName}%`}
            }
          ]
        };
      }
      await this.getQueryBus().execute(
        new ProjectsQuery(payload)
      ).then(data => {
        this.projectsCount = data.length;
        this.projects = data;
      });
    }    
  },
  mounted() {
    this.loadProjects(() => {
      this.resizePanel();
    });   
  }
}
</script>