import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'

export default class ExportStructureQuery extends AbstractEntitiesQuery {
  private id: number;

  constructor (id) {
    super()
    this.id = id
  }

  getClassName (): string {
    return 'ExportStructureQuery'
  }

  getId (): number {
    return this.id
  }
}
