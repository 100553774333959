import GroupCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/GroupCommandRepositoryInterface'
import store from '@/store'
import { CreateGroup, UpdateGroup, DeleteGroup } from '@/services/EtlEditor/infrastructure/store/modules/Group/action-types'
import { GroupDTO } from '@/services/EtlEditor/domain/model/Group'

export default class GroupCommandRepository implements GroupCommandRepositoryInterface {
  insert (item: GroupDTO): Promise<void> {
    return store.dispatch(new CreateGroup(item))
  }

  update (item: GroupDTO): Promise<void> {
    return store.dispatch(new UpdateGroup(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteGroup(guid))
  }
}
