<template>
  <registry-select-tree
    style="width: 100%;"
    type="field"
    value-as="number"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
    :parent-id="targetObjectId"
    :placeholder="placeholder">
  </registry-select-tree>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'

export default {
  name: 'TargetTableField',

  props: {
    value: Number,

    targetObjectId: {
      type: Number,
      required: true
    },

    placeholder: {
      type: String,
      default: 'Target Table Field'
    }
  },

  components: {
    RegistrySelectTree
  }
}
</script>

<style scoped>

</style>
