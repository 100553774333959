<template>
  <div class="process_tree_elements el-tree-select">
    <el-select
      v-model="checkedValue"
      ref="select"
      class="el-tree-select-input"
      popper-class="select-option"
      :style="{ width: '100%' }"
      :disabled="_disabled"
      :multiple="multiple"
      :size="size"
      :placeholder="placeholder"
      :popper-append-to-body="false"
      :filterable="false"
      :collapse-tags="collapseTags"
      :loading="loading"
      clearable
      v-popover:popover
      @clear="clear"
      @remove-tag="removeTag"
      @focus="_popoverShowFun"
    >
      <el-option
        v-for="item in options"
        :value="item.value"
        :label="item.label"
        :key="item.value"
      ></el-option>
    </el-select>
    <el-popover
      ref="popover"
      :placement="placement"
      :popper-class="popperClass"
      :width="width"
      v-model="visible"
      trigger="click"
    >
      <el-input v-model="filterText" placeholder="Поиск">
        <template slot="prepend">
          <span class="el-icon-search"></span>
        </template>
      </el-input>

      <el-scrollbar
        tag="div"
        class="is-empty"
        wrap-class="el-select-dropdown__wrap"
        view-class="el-select-dropdown__list"
      >
        <el-tree
          v-if="isParsed"
          ref="tree"
          node-key="guid"
          :data="elements"
          :props="props"
          :accordion="false"
          :size="size"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :default-checked-keys="defaultCheckedKeys"
          default-expand-all
          check-on-click-node
          check-strictly
          show-checkbox
          @check-change="checkNode"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="node-label" :title="node.label" v-html="getLabel(node, data)"></span>
          </span>
        </el-tree>
      </el-scrollbar>
    </el-popover>
  </div>
</template>

<script type="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ProcessTreeElements',

  props: {
    value: {
      type: [Number, Array],
      default () {
        return null
      }
    },

    collapseTags: {
      type: Boolean,
      default: true
    },

    valueKey: {
      type: String,
      default: 'element_id'
    },

    elementType: {
      type: String,
      validate: function (value) {
        return [
          'process'
        ].indexOf(value) !== -1
      },
      default: 'process'
    },

    multiple: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'Select'
    },

    size: {
      type: String,
      default: 'mini'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    placement: {
      type: String,
      default: 'bottom'
    }
  },

  computed: {
    popperClass () {
      return this.disabled ? 'el-tree-select-popper disabled' : 'el-tree-select-popper'
    },

    defaultCheckedKeys () {
      if (this.checkedValue !== null) {
        return this.multiple ? this.checkedValue : [this.checkedValue]
      }
      return []
    },

    _disabled () {
      return this.disabled === true ? this.disabled : this.elements.length === 0
    }
  },

  watch: {
    value: {
      handler: function (newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.localValue)) {
          this.setLocalValue(newValue)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.value)) {
          this.$emit('change', newValue, oldValue)
          this.$emit('input', newValue)
        }
      },
      deep: true
    },

    filterText (value) {
      if (this.$refs.tree) {
        this.$refs.tree.filter(value)
      }
    }
  },

  data () {
    return {
      props: {
        isLeaf: 'is_leaf',
        label: 'name',
        disabled: 'disabled'
      },

      checkedValue: this.multiple ? [] : null,
      localValue: this.multiple ? [] : null,

      elements: [],
      options: [],

      isParsed: false,

      visible: false,
      width: 250,

      filterText: '',

      loading: true
    }
  },

  async mounted () {
    this._updateH()

    this.setLocalValue(this.value)

    await this.loadElements()

    this.loading = false
  },

  methods: {
    // Фильтрация узлов (поиск)
    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name
        .trim()
        .toLowerCase()
        .indexOf(
          value
            .trim()
            .toLowerCase()
        ) !== -1
    },

    // Render функции
    getLabel (node, data) {
      const iconClass = this.getTreeIcon(node)
      const iconTag = iconClass ? `<span class="node-label__icon ${iconClass}"></span>` : ''

      return `${iconTag}<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.element_id})</span>`
    },

    getTreeIcon (node) {
      if (['group'].includes(node.data.element_type)) {
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      }

      return null
    },

    // Загрузка узлов и управление значением (ввод и вывод данных)
    async loadElements () {
      // Загрузка услов с бека
      this.elements = await this.$http.get(`${this.$config.api}/processeditor/tree_elements/tree`)
        .then((response) => {
          return response.data
        }).catch(() => {
          return []
        })

      // Строим список для el-tree
      if (this.elements.length) {
        this.options = this.buildOptions(this.elements)
      }

      // Получить checkedValue по localValue
      if (this.options.length) {
        this.parseValue()
      } else {
        this.isParsed = true
      }
    },

    buildOptions (elements) {
      // Сборка списка элементов для el-select

      const options = []

      for (const element of elements) {
        if (element.element_type === this.elementType) {
          options.push({
            value: element.guid,
            label: element.name,
            [this.valueKey]: element[this.valueKey]
          })
        }

        if (element.children.length) {
          options.push(...this.buildOptions(element.children))
        }
      }

      return options
    },

    checkNode (node, checked) {
      // Заполнение переменной с отмеченными узлами
      if (checked) {
        if (this.multiple) {
          this.checkedValue.push(node.guid)
        } else {
          this.checkedValue = node.guid
        }
      } else {
        if (this.multiple) {
          const index = this.checkedValue.indexOf(node.guid)
          if (index !== -1) {
            this.checkedValue.splice(index, 1)
          }
        } else {
          if (this.checkedValue === node.guid) {
            this.checkedValue = null
          }
        }
      }

      // Точная установка отмеченных узлов
      if (this.checkedValue !== null) {
        if (this.multiple) {
          if (!Array.isArray(this.checkedValue)) {
            this.checkedValue = [this.checkedValue]
          }

          this.$refs.tree.setCheckedKeys(this.checkedValue)
        } else {
          if (Array.isArray(this.checkedValue)) {
            this.checkedValue = this.checkedValue[0]
          }

          this.$refs.tree.setCheckedKeys([this.checkedValue])
        }
      } else {
        this.checkedValue = this.multiple ? [] : null
        this.$refs.tree.setCheckedKeys([])
      }

      // Заполняет localValue идентифакаторами сущностей, по выбранным узлам в el-tree
      if (this.multiple) {
        this.localValue = this.options
          .filter(item => this.checkedValue.includes(item.value))
          .map(item => item[this.valueKey]) // [1, 2, 3]
      } else {
        const option = this.options.find(item => item.value === this.checkedValue)

        this.localValue = option
          ? option[this.valueKey]
          : null
      }
    },

    setLocalValue (value) {
      // Валидирует внешнее значение props.value заполняя data.localValue

      if (this.multiple) {
        if (Array.isArray(value)) {
          this.localValue = value
        } else if (typeof value === 'number') {
          this.localValue = [value]
        } else {
          this.localValue = []
        }
      } else {
        if (Array.isArray(value)) {
          this.localValue = value[0] || null // if undefined then null
        } else {
          this.localValue = value || null // if undefined then null
        }
      }
    },

    parseValue () {
      // Парсит localValue в момент загрузки данных, что бы извлечь узлы которые необходимо выбрать в el-tree

      if (this.multiple) {
        this.checkedValue = this.options
          .filter(item => this.localValue.includes(item[this.valueKey]))
          .map(item => item.value)
      } else {
        const option = this.options.find(item => item[this.valueKey] === this.localValue)

        this.checkedValue = option
          ? option.value
          : null
      }

      this.isParsed = true
    },

    clear () {
      this.$refs.tree.setCheckedKeys([])
      this.$emit('clear')
    },

    removeTag (value) {
      if (this.multiple) {
        const index = this.checkedValue.indexOf(value)

        if (index !== -1) {
          this.checkedValue.splice(index, 1)
        }

        this.$refs.tree.setCheckedKeys(this.localValue)
        this.$emit('remove-tag', value)
      }
    },

    // Стабилизация внешнего вида компонента
    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width

        if (this.width < 250) {
          this.width = 250
        }
      })
    },

    _popoverShowFun () {
      this._updateH()
    }
  }
})
</script>

<style lang="scss" scoped>
  .process_tree_elements {
    .el-tree {
      //display: flex;
    }
  }

  .el-select-dropdown__wrap .el-tree-node > .el-tree-node__children {
    overflow: visible;
  }
</style>
