<template>
  <div style="height: calc(100vh - 56px)">
    <div class="title">{{$locale.access_editor.main.title}}</div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane
        v-for="(tab, index) in items"
        :key="index"
        :lazy="true"
        :label="tab.name"
        class="acces_editor__height"
        :name="index.toString()"
      >
        <component :is="tab.type" :key="index"></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Roles from './components/Roles'
import Users from './components/Users'
import RightsObject from './components/RightsObject'
export default {
  name: 'AccessEditor',
  components: {
    Roles,
    Users,
    RightsObject
  },
  data () {
    return {
      activeName: 'roles',
      items: {
        roles: {
          name: this.$locale.access_editor.roles,
          type: 'Roles'
        },
        users: {
          name: this.$locale.access_editor.users,
          type: 'Users'
        },
        access: {
          name: this.$locale.access_editor.right_object.title,
          type: 'RightsObject'
        }
      }
    }
  }
}
</script>

<style scoped>
.acces_editor__height {
  height: calc(100vh - 170px);
  /* overflow-y: auto */
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  margin-top: 15px;
  height: 28px;
  color: #2C2D35;
}
</style>
