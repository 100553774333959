<template>
  <card class="tree_element_layout" :rounded="false" :bordered="false" type="grey">
    <template v-slot:header.toolbar>
      <el-dropdown
        @command="add($event)"
        trigger="click">
        <el-button
          icon="el-icon-plus"
          size="small">
          {{ $locale.main.button.add }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="type in elementTypes"
            :command="type"
            :key="type">
            {{ $locale.etl_editor_v2.element_types[type] }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>

    <el-scrollbar>
      <el-tree
        ref="tree"
        lazy
        :props="treeProps"
        :load="loadNode"
        node-key="guid"
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span
            :class="{ 'selected-node': selectedNode !== null && selectedNode.data.guid === data.guid }"
            style="width: 100%;"
            @click="selected(node)">
            <span :class="getTreeIcon(node)"></span> {{ getLabel(node, data) }}
          </span>
          <span v-if="selectedNode !== null && selectedNode.data.guid === data.guid">
            <el-dropdown
              v-if="selectedNode.data.element_type === 'group'"
              @command="add($event, node)"
              trigger="click">
              <el-button
                class="text_success"
                type="text"
                icon="el-icon-plus"
                size="mini">
                {{ $locale.main.button.add }}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="type in elementTypes"
                  :command="type"
                  :key="type">
                  {{ $locale.etl_editor_v2.element_types[type] }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="remove(node, data)"
              class="text_danger">
              {{ $locale.main.button.delete }}
            </el-button>
          </span>
        </span>
      </el-tree>
    </el-scrollbar>
  </card>
</template>

<script type="ts">
import Node from 'element-ui/packages/tree/src/model/node'
import TreeElementsQuery from '@/services/EtlEditor/application/query/TreeElementsQuery'
import TreeElementByElementGuidQuery from '@/services/EtlEditor/application/query/TreeElementByElementGuidQuery'

export default {
  name: 'TreeElementLayout',

  props: {
    createElement: {
      type: Function,
      default () {
        return function (elementType, parentNode) {
        }
      }
    },

    updateElement: {
      type: Function,
      default () {
        return function (node, data) {
        }
      }
    },

    deleteElement: {
      type: Function,
      default () {
        return function (node, data) {
        }
      }
    },

    close: {
      type: Function,
      default () {
        return function () {
        }
      }
    }
  },

  inject: ['getQueryBus'],

  data () {
    return {
      selectedNode: null,

      isEdit: false,

      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },

      elementTypes: [
        'extractor', 'group',
        'loader', 'task',
        'template', 'transformer'
      ]
    }
  },

  methods: {
    getLabel (node, data) {
      if (data.element_type === 'group') {
        return node.label
      } else {
        return `[${this.$locale.etl_editor_v2.element_types[data.element_type]}] ${node.label}`
      }
    },

    getTreeIcon (node) {
      if (!node.expanded) {
        return { 'el-icon-folder': node.data.element_type === 'group' }
      } else {
        return { 'el-icon-folder-opened': node.data.element_type === 'group' }
      }
    },

    getTree () {
      return this.$refs.tree
    },

    closeEditor () {
      this.isEdit = false
      this.selectedNode = null
      this.close()
    },

    selected (node) {
      if (this.selectedNode === node) {
        this.closeEditor()
        return
      }

      this.closeEditor()
      this.edit(node, node.data)
    },

    addNode (data, parentNode) {
      if (parentNode) {
        this.$refs.tree.append(data, parentNode)
      } else {
        let node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: data
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    async getNodeByElementGuid (elementGuid) {
      let treeElement = await this.getQueryBus().execute(
        new TreeElementByElementGuidQuery(elementGuid)
      )

      return this.$refs.tree.getNode(treeElement.guid)
    },

    updateNode (node) {
      this.selectedNode = node
    },

    add (elementType = null, parentNode = null) {
      this.selectedNode = parentNode
      this.createElement(elementType, parentNode)
    },

    edit (node, data) {
      if (this.isEdit) {
        return
      }

      this.selectedNode = node
      this.isEdit = true

      this.updateElement(node, data)
    },

    remove (node, data) {
      this.$confirm(this.$locale.main.message.delete, this.$locale.main.message_title.warning, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(() => {
        this.deleteElement(node, data)
        this.$refs.tree.remove(node)
      })
    },

    async loadNode (node, resolve) {
      resolve(await this.loadElements(node.level === 0 ? null : node.data.id))
    },

    async loadElements (parentId = null) {
      return await this.getQueryBus().execute(
        new TreeElementsQuery(
          !parentId ? { is_null: 'parent_id', order: 'name:asc' } : { parent_id: parentId, order: 'name:asc' }
        )
      )
    }
  }
}
</script>

<style type="text/css">
  .el-dropdown+.el-button {
    margin-left: 10px;
  }
</style>
