import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CommandCreateCommand from '@/services/LogicEditor/application/command/CommandCreateCommand'
import CommandCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandCommandRepositoryInterface'

export default class CommandCreateHandler implements CommandHandlerInterface {
    private repository: CommandCommandRepositoryInterface;

    constructor (repository: CommandCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: CommandCreateCommand): Promise<void> {
      return this.repository.insert(command.getDTO())
    }
}
