import SourceQueryRepositoryInterface from '@/services/NotificationEditor/domain/repository/SourceQueryRepositoryInterface'
import QueryHandlerInterface from "@/core/application/query/QueryHandlerInterface";
import SourceByIdAndTypeQuery from "@/services/NotificationEditor/application/query/SourceByIdAndTypeQuery";
import SourceDTO from "@/services/NotificationEditor/domain/model/SourceDTO";

export default class SourceByIdAndTypeHandler implements QueryHandlerInterface {
  private repository: SourceQueryRepositoryInterface;

  constructor (repository: SourceQueryRepositoryInterface) {
    this.repository = repository;
  }

  execute (query: SourceByIdAndTypeQuery): Promise<SourceDTO> {
    return this.repository.getByIdAndType(query.getId(), query.getType());
  }
}
