
import Vue from 'vue'
import CssGrid from './infrastructure/components/CssGrid.vue'
import ThemeGrid from './infrastructure/components/ThemeGrid.vue'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import CssClassesTreeQueryHandler from '@/services/CssEditor/application/handler/query/CssClassesTreeQueryHandler'
import CssClassQueryRepository from '@/services/CssEditor/infrastructure/domain/repository/CssClassQueryRepository'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import CssClassByGuidQueryHandler from '@/services/CssEditor/application/handler/query/CssClassByGuidQueryHandler'
import GroupByGuidQueryHandler from '@/services/CssEditor/application/handler/query/GroupByGuidQueryHandler'
import GroupQueryRepository from '@/services/CssEditor/infrastructure/domain/repository/GroupQueryRepository'
import CssClassesSearchQueryHandler from '@/services/CssEditor/application/handler/query/CssClassesSearchQueryHandler'
import GroupCommandRepository from '@/services/CssEditor/infrastructure/domain/repository/GroupCommandRepository'
import CssClassCommandRepository from '@/services/CssEditor/infrastructure/domain/repository/CssClassCommandRepository'
import CssClassCreateCommandHandler
  from '@/services/CssEditor/application/handler/command/CssClassCreateCommandHandler'
import CssClassUpdateCommandHandler
  from '@/services/CssEditor/application/handler/command/CssClassUpdateCommandHandler'
import CssClassDeleteCommandHandler
  from '@/services/CssEditor/application/handler/command/CssClassDeleteCommandHandler'
import CssClassTreeByGuidQueryHandler
  from '@/services/CssEditor/application/handler/query/CssClassTreeByGuidQueryHandler'
import GroupCreateCommandHandler from '@/services/CssEditor/application/handler/command/GroupCreateCommandHandler'
import GroupUpdateCommandHandler from '@/services/CssEditor/application/handler/command/GroupUpdateCommandHandler'
import GroupDeleteCommandHandler from '@/services/CssEditor/application/handler/command/GroupDeleteCommandHandler'
import ThemesQueryHandler from '@/services/CssEditor/application/handler/query/ThemesQueryHandler'
import ThemeQueryRepository from '@/services/CssEditor/infrastructure/domain/repository/ThemeQueryRepository'
import ThemeByGuidQueryHandler from '@/services/CssEditor/application/handler/query/ThemeByGuidQueryHandler'
import ThemeCreateCommandHandler from '@/services/CssEditor/application/handler/command/ThemeCreateCommandHandler'
import ThemeUpdateCommandHandler from '@/services/CssEditor/application/handler/command/ThemeUpdateCommandHandler'
import ThemeDeleteCommandHandler from '@/services/CssEditor/application/handler/command/ThemeDeleteCommandHandler'
import ThemeCommandRepository from '@/services/CssEditor/infrastructure/domain/repository/ThemeCommandRepository'

export default Vue.extend({
  name: 'CssEditor',

  components: {
    CssGrid,
    ThemeGrid
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus,
      getCommandBus: this.getCommandBus
    }
  },

  data () {
    return {
      activeTab: 'css_classes',

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'CssClassesTreeQuery': new CssClassesTreeQueryHandler(
            new CssClassQueryRepository()
          ),
          'CssClassByGuidQuery': new CssClassByGuidQueryHandler(
            new CssClassQueryRepository()
          ),
          'GroupByGuidQuery': new GroupByGuidQueryHandler(
            new GroupQueryRepository()
          ),
          'CssClassesSearchQuery': new CssClassesSearchQueryHandler(
            new CssClassQueryRepository()
          ),
          'CssClassTreeByGuidQuery': new CssClassTreeByGuidQueryHandler(
            new CssClassQueryRepository()
          ),
          'ThemesQuery': new ThemesQueryHandler(
            new ThemeQueryRepository()
          ),
          'ThemeByGuidQuery': new ThemeByGuidQueryHandler(
            new ThemeQueryRepository()
          )
        })
      ),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'GroupCreateCommand': new GroupCreateCommandHandler(
            new GroupCommandRepository()
          ),
          'GroupUpdateCommand': new GroupUpdateCommandHandler(
            new GroupCommandRepository()
          ),
          'GroupDeleteCommand': new GroupDeleteCommandHandler(
            new GroupCommandRepository()
          ),
          'CssClassCreateCommand': new CssClassCreateCommandHandler(
            new CssClassCommandRepository()
          ),
          'CssClassUpdateCommand': new CssClassUpdateCommandHandler(
            new CssClassCommandRepository()
          ),
          'CssClassDeleteCommand': new CssClassDeleteCommandHandler(
            new CssClassCommandRepository()
          ),
          'ThemeCreateCommand': new ThemeCreateCommandHandler(
            new ThemeCommandRepository()
          ),
          'ThemeUpdateCommand': new ThemeUpdateCommandHandler(
            new ThemeCommandRepository()
          ),
          'ThemeDeleteCommand': new ThemeDeleteCommandHandler(
            new ThemeCommandRepository()
          )
        })
      ),

      eventBus: new Vue()
    }
  },

  methods: {
    getQueryBus () {
      return this.queryBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getEventBus () {
      return this.eventBus
    }
  }
})
