import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import RegionQueryRepositoryInterface from '@/services/GarAddressService/domain/repository/RegionQueryRepositoryInterface'
import {APIClient} from "@/core/infrastructure/api/APIClient";
import {GarTableAPI} from "@/services/GarAddressService/infrastructure/api/GarTableAPI";

export default class GarTableQueryRepository extends AbstractQueryRepository implements RegionQueryRepositoryInterface {
  get (payload: string[][]): Promise<any> {
    return APIClient.shared.request(new GarTableAPI.GetGarTables(payload))
  }
}
