<template>
  <div v-if="show" class="container">
    <el-form :rules="formRules">
      <el-divider content-position="left" class="divider">{{$locale.map_editor.import_form.main_parameters}}</el-divider>
      <el-form-item prop="format" :label="$locale.map_editor.import_form.format" class="form">
        <el-select v-model="formatValue" name="tag" placeholder="Формат">
          <el-option
            v-for="item in formats"
            :key="item.tag"
            :label="item.name"
            :value="item.tag">
          </el-option>
        </el-select>        
      </el-form-item>
      <el-form-item prop="layer" :label="$locale.map_editor.import_form.layer">
        <el-select v-model="layerValue" name="layer_id" placeholder="Целевой слой">
          <el-option
            v-for="item in layers"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>        
      </el-form-item>
      <el-form-item prop="coordinateSystem" :label="$locale.map_editor.import_form.coordinate_system">
        <el-select v-model="coordinateSystemValue" name="source_srs" placeholder="Cистема координат">
          <el-option
            v-for="item in coordinateSystems"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>        
      </el-form-item>
      <el-divider content-position="left" class="divider">{{$locale.map_editor.import_form.additional_parameters}}</el-divider>
      <el-form-item>
        <el-checkbox v-model="primaryAxesRotate" name="is_initial_rotate" class="checkbox">{{$locale.map_editor.import_form.primary_axes_rotate}}</el-checkbox>
        <el-checkbox v-model="axesRotate" name="is_rotate" class="checkbox">{{$locale.map_editor.import_form.axes_rotate}}</el-checkbox>
      </el-form-item>
      <!-- <el-form-item prop="format" :label="$locale.map_editor.import_form.format">
        <el-upload
            :headers="getHeaders()"
            class="upload-demo"
            ref="upload"
            :action="`${this.$config.api}/registryservice/files`"
            :file-list="fileList"
            :auto-upload="false"
            :show-file-list="false"
            :limit="1"
            :multiple="false">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="this.$locale.etl_editor.form.upload_file" placement="top">
              <el-button size="mini"  icon="el-icon-download" type="primary"></el-button>
            </el-tooltip>
        </el-upload>
      </el-form-item> -->
      <el-divider class="divider"></el-divider>
      <el-form-item>
        <el-button @click="importDo">
          {{$locale.main.button.import}}
        </el-button>
        <el-button @click="show=false">
          {{$locale.main.button.cancel}}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="ts">

export default {
  name: 'ImportForm',
  props: ['layers'],
  //inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  data() {
    return {
      show: false,
      formRules: {
        format: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        },
        layer: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        },
        coordinateSystem: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'blur'
        }
      },
      // gonna get it from API
      formats: [{"tag":"MIF","name":"MapInfo MIF","description":"Импорт из MapInfo MIF","plugin_type":"import","can_have_attributes":true,"is_ogr_format":true,"extended_parameters":null},{"tag":"SHP","name":"SHP","description":"Импорт из Shape","plugin_type":"import","can_have_attributes":true,"is_ogr_format":true,"extended_parameters":null},{"tag":"TAB","name":"MapInfo TAB","description":"Импорт из MapInfo TAB","plugin_type":"import","can_have_attributes":true,"is_ogr_format":true,"extended_parameters":null},{"tag":"KML","name":"KML","description":"Импорт в KML","plugin_type":"import","can_have_attributes":false,"is_ogr_format":true,"extended_parameters":null},{"tag":"DXF","name":"DXF","description":"Импорт в DXF","plugin_type":"import","can_have_attributes":false,"is_ogr_format":false,"extended_parameters":[{"name":"is_closed_lines_to_polygones","description":"Преобразовывать замкнутые линии в полигоны","values":null,"type":"checkbox","default":"false","is_enabled":true}]},{"tag":"TXT","name":"TXT","description":"Импорт в TXT","plugin_type":"import","can_have_attributes":false,"is_ogr_format":false,"extended_parameters":[{"name":"is_close_lines_to_polygone","description":"Преобразовывать замкнутые линии в полигоны","values":null,"type":"checkbox","default":"false","is_enabled":true}]},{"tag":"ROSREESTR","name":"XML Росреестр","description":"Импорт из XML росреестра","plugin_type":"import","can_have_attributes":true,"is_ogr_format":false,"extended_parameters":null}],
      formatValue: "MIF",
      coordinateSystems: [{"id":"standard_3857","srid":3857,"name":"WGS84 (метры)","type":1,"coef_xx":0,"coef_xy":0,"coef_yx":0,"coef_yy":0,"step_x":0,"step_y":0,"target_reference":0,"proj4text":null},{"id":"standard_4326","srid":4326,"name":"WGS84","type":1,"coef_xx":0,"coef_xy":0,"coef_yx":0,"coef_yy":0,"step_x":0,"step_y":0,"target_reference":0,"proj4text":null}],
      coordinateSystemValue: null,
      //
      layerValue: null,
      primaryAxesRotate: false,
      axesRotate: false
    }
  },
  methods: {
    showForm() {
      this.show = true;    
    },
    hideForm() {
      this.show = false;    
    },
    setFormDefaultValues() {
      if (this.layers && this.layers.length) {
        this.layerValue = this.layers[0];
      }
    },
    importDo() {
      //this.show = false;  
    }
  },
  mounted() {
    this.setFormDefaultValues();  
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
  }
  .form {
    width: 100%;    
  }
  .divider {
    margin: 30px 0 20px 0;
  }
  .checkbox {
    font-weight: normal;
  }
</style>