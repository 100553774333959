import LogicCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicCommandRepositoryInterface'
import store from '@/store'
import { CreateLogic, UpdateLogic, DeleteLogic } from '@/services/LogicEditor/infrastructure/store/modules/Logic/action-types'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'

export default class LogicCommandRepository implements LogicCommandRepositoryInterface {
  insert (item: LogicDTO): Promise<void> {
    return store.dispatch(new CreateLogic(item))
  }

  update (item: LogicDTO): Promise<void> {
    return store.dispatch(new UpdateLogic(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteLogic(guid))
  }
}
