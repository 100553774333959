<template>
  <div>
    <el-button @click="addVariable" type="text" style="margin-bottom: 10px">{{ $t('main.button.add') }}</el-button>
    <div v-for="(variable, index) in localValue" :key="index" class="variables">
      <el-input v-model="variable.name" :placeholder="$t('main.fields.name')"/>
      <el-input v-model="variable.value" :placeholder="$t('main.fields.value')"/>
      <el-button size="mini" type="text" @click="removeVariable(index)" style="color:red;"><i
        class="el-icon-remove"></i></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringVariables',
  props: {
    value: Array
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue: {
      handler (val) {
        const value = val.filter(_ => _.name && _.value)
        this.$emit('input', value)
      },
      deep: true
    }
  },
  methods: {
    removeVariable (index) {
      this.localValue.splice(index, 1)
    },
    addVariable () {
      this.localValue.push({
        name: '',
        value: ''
      })
    }
  }
}
</script>
<style>
.variables {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
