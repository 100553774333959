import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import LogsRepositoryInterface from '@/services/LogService/domain/repository/LogsRepositoryInterface'
import store from '@/store'
import { GetResources, GetLogsByResource, GetCountByResource, GetLogsByResourceAndDate } from '@/services/LogService/infrastructure/store/modules/Logs/action-types'

export default class LogsRepository extends AbstractQueryRepository implements LogsRepositoryInterface {
  getResources (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetResources(payload))
  }

  getLogsByResource (payload: string[][] | object, resource: string): Promise<any> {
    return store.dispatch(new GetLogsByResource(payload, resource))
  }

  getCountByResource (payload: string[][] | object, resource: string): Promise<any> {
    return store.dispatch(new GetCountByResource(payload, resource))
  }

  getLogsByResourceAndDate (payload: string[][] | object, resource: string): Promise<any> {
    return store.dispatch(new GetLogsByResourceAndDate(payload, resource))
  }
}
