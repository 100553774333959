import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import LogicTreeElementByElementGuidQuery from '@/services/LogicEditor/application/query/LogicTreeElementByElementGuidQuery'
import LogicTreeElementQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicTreeElementQueryRepositoryInterface'

export default class LogicTreeElementByElementGuidHandler implements QueryHandlerInterface {
  private repository: LogicTreeElementQueryRepositoryInterface

  constructor (repository: LogicTreeElementQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: LogicTreeElementByElementGuidQuery): Promise<any> {
    return this.repository.getByElementGuid(query.getElementGuid())
  }
}
