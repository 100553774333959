import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ExtractorDTO } from '@/services/EtlEditor/domain/model/Extractor'
import ExtractorUpdateCommand from '@/services/EtlEditor/application/command/ExtractorUpdateCommand'
import ExtractorCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/ExtractorCommandRepositoryInterface'

export default class ExtractorUpdateHandler implements CommandHandlerInterface {
    private repository: ExtractorCommandRepositoryInterface;

    constructor (repository: ExtractorCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ExtractorUpdateCommand): Promise<void> {
      let item: ExtractorDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        extractor_type_id: command.getExtractorTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties()
      }

      return this.repository.update(item)
    }
}
