<template>
  <div class="linestring-style-pattern-form custom_scrollbar">
    <el-form :rules="formRules" :ref="formId" v-if="dto" class="linestring-style-pattern-form-inner-body" :model="dto">
      <el-form-item prop="name" :label="$locale.main.fields.name">
        <el-input v-model="dto.name" autocomplete="off"></el-input>        
      </el-form-item>
      <el-form-item prop="description" :label="$locale.main.fields.description">
        <el-input v-model="dto.description" type="textarea" autocomplete="off"></el-input>        
      </el-form-item>
      <el-form-item prop="pattern" class="pattern-form-item">
        <div class="pattern-container">
          <div class="pattern-table">
              <el-table
                :indent="0"
                class="custom_scrollbar"
                height="100%"
                :data="steps"
                stripe
                border
                row-key="id"
                current-row-key="id"
                highlight-current-row
                ref="linestring_style_pattern_table"
                @current-change="selectStep"
              >    
              <el-table-column
                prop="dash"
                :label="this.$locale.map_editor.style_form.dash"
                width="152"
              >
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.dash" :min="0" :max="999999" size="small" controls-position="right" @change="editPatternRecord"/>
                </template>
              </el-table-column>
              <el-table-column
                prop="space"
                :label="this.$locale.map_editor.style_form.space"
                width="152"
              >
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.space" :min="0" :max="999999" size="small" controls-position="right" @change="editPatternRecord"/>
                </template>
              </el-table-column>            
              </el-table>
          </div>
          <div class="pattern-controls">
            <div>
              <el-button type="info" circle icon="el-icon-plus" size="mini" plain @click="addDash"/>
            </div>
            <div>
              <el-button type="info" circle icon="el-icon-delete" size="mini" plain @click="deleteDash"/>
            </div>
          </div>
        </div>
      </el-form-item> 
    </el-form>    
  </div>
</template>

<script type="ts">
import StrokeStyle, { StrokeStyleDTO } from '@/core/domain/model/modules/StrokeStyle'
import StrokeStyleCreateCommand from '@/core/application/command/modules/StrokeStyle/StrokeStyleCreateCommand'
import StrokeStyleByGuidQuery from '@/core/application/query/modules/StrokeStyle/StrokeStyleByGuidQuery'

export default {
  name: 'LinestringStylePatternForm',
  props: ['formId', 'parentFormId', 'projectId', 'projectGuid', 'linestringStylePattern', 'submitCallback'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
  },
  watch: {
    linestringStylePatternCreated: function (location) {
      this.getQueryBus().execute(
        new StrokeStyleByGuidQuery(
          location.replace('/stroke_styles/', '')
        )
      ).then((linestringStylePatternDto) => {
        let linestringStylePattern = StrokeStyle.create(linestringStylePatternDto);
        if (typeof this.submitCallback == 'function') {
          this.submitCallback(linestringStylePattern);
        }
        this.getEventBus().$emit('linestringStylePatternCreated', linestringStylePattern, this.parentFormId);    
      });
    },
    steps: function (value) {
      if (this.dto) {
        this.updatePattern(value);        
      }
    }
  },
  data() {
    return {
      dto: null,
      formRules: {
        pattern: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      currentStep: null,
      steps: [],    
    }
  },
  computed: {
    linestringStylePatternCreated() {
      return this.$store.getters['StrokeStyle/getLocation'];
    }
  },
  methods: {
    async submit(callback) {
      let form = this.$refs[this.formId];
      form.validate(valid => {
        if (valid) {
          let linestringStylePattern = StrokeStyle.create(this.dto);       
          this.getCommandBus().execute(
            new StrokeStyleCreateCommand(
              linestringStylePattern.getName(),
              linestringStylePattern.getDescription(),
              linestringStylePattern.getPattern()
            )
            ).then((response) => {
              if (typeof callback == 'function') {
                callback(response);
              }              
          });
        }
      });
    },
    addDash() {
      let maxId = 0;
      for (let i = 0; i < this.steps.length; i += 1) {
        if (maxId < this.steps[i].id) {
          maxId = this.steps[i].id;
        }
      }
      this.steps.push({
        id: maxId + 1,
        dash: 0,
        space: 0
      });
    },
    deleteDash() {
      if (this.currentStep != null) {
        for (let i = 0; i < this.steps.length; i += 1) {
          if (this.steps[i].id == this.currentStep.id) {
            this.steps.splice(i, 1);
            break;
          }
        }
      }
    },
    selectStep(record) {
      this.currentStep = record;
    },
    editPatternRecord() {
      this.updatePattern(this.steps);
    },
    updatePattern(value) {
      if (value.length) {
        let res = [];
        for (let i = 0; i < value.length; i += 1) {
          res.push(value[i].dash);
          res.push(value[i].space);
        }
        this.dto.pattern = res;
      } else {
        this.dto.pattern = null;
      }
  }
  },
  mounted() {
    this.dto = new StrokeStyleDTO({});
  }
}
</script>