import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LogicGroupDeleteCommand from '@/services/LogicEditor/application/command/LogicGroupDeleteCommand'
import LogicGroupCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicGroupCommandRepositoryInterface'

export default class LogicGroupDeleteHandler implements CommandHandlerInterface {
    private repository: LogicGroupCommandRepositoryInterface;

    constructor (repository: LogicGroupCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicGroupDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
