import RegistryFieldsQueryRepositoryInterface
  from '@/services/InterfaceEditor/domain/repository/RegistryFieldsQueryRepositoryInterface'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RegistryFieldsAPI } from '@/services/InterfaceEditor/infrastructure/api/RegistryFieldsAPI'

export default class RegistryFieldsQueryRepository implements RegistryFieldsQueryRepositoryInterface{
  getByRegistryId (registryId: number):Promise<any> {
    return APIClient.shared.request(new RegistryFieldsAPI.GetByRegistryId(registryId))
  }
}
