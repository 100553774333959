<template>
  <div style="height: 100%;overflow-y: hidden;position: relative;">
    <el-form
      ref="request_view_form"
      size="mini"
      :model="request"
      class="request_view_toolbar"
      v-if="show_request_form"
      :key="request.guid"
    >
      <span class="label" style="background-color: #f5f5f5; width: 100%">{{ request_types[request.element_type] }}</span>
      <el-form-item class="form_element" prop="name">
        <span>{{ $t('api_service.request.name') }}</span>
        <el-input v-model="request.element.name"></el-input>
      </el-form-item>
      <div v-if="show_request_properties">
        <div class="main_properties_container">
          <div class="properties_container">
            <el-form-item prop="method_type_id">
              <span style="margin-right: 5px">{{ $t('api_service.request.method') }}</span>
              <el-input v-model="request.element.method_type_id" disabled style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="url">
              <span style="margin-right: 5px">{{ $t('api_service.request.url') }}</span>
              <el-input v-model="request.element.url">
                <template slot="prepend">/api</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="properties_container">
            <el-form-item prop="connection_id">
              <span style="margin-right: 5px">{{ $t('api_service.request.connections') }}</span>
              <editor-select
                ref="connection_select"
                :value="request.element.connection_id"
                @change="$set(request.element ,'connection_id', $event)"
                :options="{
                  clearable: true,
                  options: connections
                }"
              ></editor-select>
            </el-form-item>
            <el-form-item prop="topic_id">
              <span style="margin-right: 5px">{{ $t('api_service.request.topic') }}</span>
              <editor-select
                ref="topic_select"
                :value="request.element.topic_id"
                @change="$set(request.element ,'topic_id', $event)"
                :options="{
                  clearable: true,
                  options: topics
                }"
              ></editor-select>
            </el-form-item>
          </div>
          <div class="properties_container">
            <el-form-item prop="response_source_type" style="margin-bottom: 45px">
              <span style="margin-right: 5px">{{ $t('api_service.request.handler_type') }}</span>
              <editor-select
                ref="handler_type_select"
                :value="handler_type.response_source_type"
                @change="$set(handler_type, 'response_source_type', $event)"
                :options="{
                  clearable: true,
                  options: handler_type.response_source_type_data
                }"
              ></editor-select>
            </el-form-item>
            <el-form-item>
              <editor-select
                ref="handler_value_select"
                :value="handler_value.response_source"
                @change="$set(handler_value, 'response_source', $event)"
                :options="{
                  clearable: true,
                  options: handler_value.response_source_data
                }"
              ></editor-select>
            </el-form-item>
          </div>
          <div class="properties_container">
            <el-form-item prop="scheme_response" style="margin-bottom: 45px">
              <span style="margin-right: 5px">{{ $t('api_service.request.response_scheme') }}</span>
              <editor-select
                ref="scheme_response"
                :value="request_response.response_scheme"
                @change="$set(request_response, 'response_scheme', $event)"
                :options="{
                  clearable: true,
                  options: request_response.response_scheme_data
                }"
              ></editor-select>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="show_request_parameters = !show_request_parameters">
                {{ $t('api_service.request.request_parameters') }}
              </el-button>
            </el-form-item>
          </div>
        </div>
        <modal-window
          :active.sync="show_request_parameters"
          show-close-icon
        >
          <RequestParametersForm :request-guid="request.element.guid"></RequestParametersForm>
        </modal-window>
      </div>
      <div class="main_properties_container">
        <el-form-item class="form_element">
          <el-button size="mini" type="success" @click="saveEntity(request.element_type)">
            {{ $t('api_service.request.save') }}
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-form
      ref="schema_view_form"
      size="mini"
      :model="scheme"
      class="request_view_toolbar"
      v-if="show_schema_form"
      :key="scheme.guid"
    >
      <span class="label" style="background-color: #f5f5f5; width: 100%">{{ schema_entity_types[scheme.entity_type_id] }}</span>
      <el-form-item class="form_element" prop="name">
        <span>{{ $t('api_service.schema.name') }}</span>
        <el-input v-model="scheme.name"></el-input>
      </el-form-item>
      <div v-if="scheme.entity_type_id === 'array'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="array_max_items">
              <span style="margin-right: 5px">{{ $t('api_service.schema.array.max_items') }}</span>
              <el-input v-model="schema_properties.array.max_items" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="array_min_items">
              <span style="margin-right: 5px">{{ $t('api_service.schema.array.min_items') }}</span>
              <el-input v-model="schema_properties.array.min_items" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="array_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.array.nullable') }}</span>
              <el-checkbox v-model="schema_properties.array.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <el-form-item prop="array_unique_items">
              <span style="margin-right: 5px">{{ $t('api_service.schema.array.unique_items') }}</span>
              <el-checkbox v-model="schema_properties.array.unique_items" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
          </div>
          <div class="schema_properties_container">
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.array.all_of') }}</span>
            <editor-select
              ref="array_all_of"
              :value="schema_properties.array.all_of.value"
              @change="$set(schema_properties.array.all_of, 'value', $event)"
              :options="{
                    clearable: true,
                    multiple: true,
                    options: schema_properties.array.all_of.data
                  }"
            ></editor-select>
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.array.any_of') }}</span>
            <editor-select
              ref="array_any_of"
              :value="schema_properties.array.any_of.value"
              @change="$set(schema_properties.array.any_of, 'value', $event)"
              :options="{
                    clearable: true,
                    multiple: true,
                    options: schema_properties.array.any_of.data
                  }"
            ></editor-select>
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.array.one_of') }}</span>
            <editor-select
              ref="array_one_of"
              :value="schema_properties.array.one_of.value"
              @change="$set(schema_properties.array.one_of, 'value', $event)"
              :options="{
                    clearable: true,
                    multiple: true,
                    options: schema_properties.array.one_of.data
                  }"
            ></editor-select>
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.array.items') }}</span>
            <editor-select
              ref="array_items"
              :value="schema_properties.array.items.value"
              @change="$set(schema_properties.array.items, 'value', $event)"
              :options="{
                    clearable: true,
                    options: schema_properties.array.items.data
                  }"
            ></editor-select>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'boolean'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="boolean_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.boolean.nullable') }}</span>
              <el-checkbox v-model="schema_properties.boolean.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'one_of'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.one_of') }}</span>
            <editor-select
              ref="one_of"
              :value="schema_properties.one_of.value"
              @change="$set(schema_properties.one_of, 'value', $event)"
              :options="{
                    clearable: true,
                    multiple: true,
                    options: schema_properties.one_of.data
                  }"
            ></editor-select>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'schema'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.$ref') }}</span>
            <editor-select
              ref="one_of"
              :value="schema_properties.schema.$ref.value"
              @change="$set(schema_properties.schema.$ref, 'value', $event)"
              :options="{
                    clearable: true,
                    options: schema_properties.schema.$ref.data
                  }"
            ></editor-select>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'string'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="string_format">
              <span style="margin-right: 5px">{{ $t('api_service.schema.string.format') }}</span>
              <el-input v-model="schema_properties.string.format" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="string_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.string.nullable') }}</span>
              <el-checkbox v-model="schema_properties.string.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
          </div>
          <div class="schema_properties_container">
            <span style="margin: 5px 5px 5px 5px">{{ $t('api_service.schema.string.enum') }}</span>
            <el-select
              v-model="schema_properties.string.enum.value"
              multiple
              filterable
              allow-create
            >
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'object'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="string_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.object.nullable') }}</span>
              <el-checkbox v-model="schema_properties.object.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <span>{{ $t('api_service.schema.object.required') }}</span>
            <el-select
              v-model="schema_properties.object.required.value"
              multiple
              filterable
              allow-create
            >
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'integer'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="integer_exclusive_maximum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.exclusive_maximum') }}</span>
              <el-checkbox v-model="schema_properties.integer.exclusive_maximum" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <el-form-item prop="integer_exclusive_minimum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.exclusive_minimum') }}</span>
              <el-checkbox v-model="schema_properties.integer.exclusive_minimum" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <el-form-item prop="integer_format">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.format') }}</span>
              <el-input v-model="schema_properties.integer.format" style="margin-right: 5px"></el-input>
            </el-form-item>
          </div>
          <div class="schema_properties_container">
            <el-form-item prop="integer_maximum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.exclusive_maximum') }}</span>
              <el-input v-model="schema_properties.integer.maximum" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="integer_minimum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.minimum') }}</span>
              <el-input v-model="schema_properties.integer.minimum" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="integer_format">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.multiple_of') }}</span>
              <el-input v-model="schema_properties.integer.multiple_of" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="integer_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.integer.nullable') }}</span>
              <el-checkbox v-model="schema_properties.integer.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
          </div>
        </div>
      </div>
      <div v-if="scheme.entity_type_id === 'number'">
        <div class="main_properties_container">
          <div class="schema_properties_container">
            <el-form-item prop="number_exclusive_maximum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.exclusive_maximum') }}</span>
              <el-checkbox v-model="schema_properties.number.exclusive_maximum" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <el-form-item prop="number_exclusive_minimum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.exclusive_minimum') }}</span>
              <el-checkbox v-model="schema_properties.number.exclusive_minimum" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
            <el-form-item prop="number_format">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.format') }}</span>
              <el-input v-model="schema_properties.number.format" style="margin-right: 5px"></el-input>
            </el-form-item>
          </div>
          <div class="schema_properties_container">
            <el-form-item prop="number_maximum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.exclusive_maximum') }}</span>
              <el-input v-model="schema_properties.number.maximum" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="number_minimum">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.minimum') }}</span>
              <el-input v-model="schema_properties.number.minimum" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="number_format">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.multiple_of') }}</span>
              <el-input v-model="schema_properties.number.multiple_of" style="margin-right: 5px"></el-input>
            </el-form-item>
            <el-form-item prop="number_nullable">
              <span style="margin-right: 5px">{{ $t('api_service.schema.number.nullable') }}</span>
              <el-checkbox v-model="schema_properties.number.nullable" style="margin-right: 5px"></el-checkbox>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="main_properties_container">
        <el-form-item class="form_element">
          <el-button size="mini" type="success" @click="saveSchemaEntity(scheme.entity_type_id)">
            {{ $t('api_service.schema.save') }}
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import ExtendedObject from '@/components/BIEditor/Models/ExtendedObject'
import Report from '@/components/ReportEditor/Models/Reports'
import Task from '@/components/EtlEditor/Models/Task'
import Query from '@/components/BIEditor/Models/Query'
import RequestSchemeEntitiesQuery from '@/services/ApiService/application/query/RequestSchemeEntitiesQuery'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PluginAPI } from '@/services/LogicEditor/infrastructure/api/PluginAPI'
import UpdateRequestEntityCommand from '@/services/ApiService/application/command/UpdateRequestEntityCommand'
import { Request } from '@/services/ApiService/domain/model/Request/Request'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import RequestParametersForm from '@/services/ApiService/infrastructure/components/RequestParametersForm'
import UpdateSchemaCommand from '@/services/ApiService/application/command/UpdateSchemaCommand'
import { SchemaTreeDTO } from '@/services/ApiService/domain/model/Schema/SchemaTreeDTO'

export default {
  name: 'ViewPanel',
  components: {
    EditorSelect,
    ModalWindow,
    RequestParametersForm
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    'handler_type.response_source_type': {
      handler (type) {
        this.getHandlerValueDataByHandlerType(type)
      }
    },
    request: {
      handler (value) {
        this.show_request_form = true
        this.show_schema_form = false
        this.show_request_properties = false
      }
    },
    scheme: {
      handler (value) {
        this.show_request_form = false
        this.show_schema_form = true
        this.show_request_properties = false
      }
    }
  },
  data () {
    return {
      request: {},
      scheme: {},
      request_node: {},
      schema_node: {},
      show_request_form: false,
      show_schema_form: false,
      show_request_properties: false, // необходимо для корректного рендера формы для запроса
      request_types: {
        'group': this.$t('api_service.request.tree_element_type.group'),
        'project': this.$t('api_service.request.tree_element_type.project'),
        'request': this.$t('api_service.request.tree_element_type.request')
      },
      connections: [
      ],
      topics: [
      ],
      // request-properties-handler_value
      handler_value: {
        response_source: null,
        response_source_data: []
      },
      // request-properties-handler_type
      handler_type: {
        response_source_type: null,
        response_source_type_data: [
          { id: 'plugin', name: this.$t('api_service.handler_type.plugin') },
          { id: 'query', name: this.$t('api_service.handler_type.query') },
          { id: 'extended_object', name: this.$t('api_service.handler_type.extended_object') },
          { id: 'report', name: this.$t('api_service.handler_type.report') },
          { id: 'etl_editor', name: this.$t('api_service.handler_type.etl_editor') }
        ]
      },
      // request-properties-response
      request_response: {
        response_scheme: null,
        response_scheme_data: []
      },
      show_request_parameters: false,
      schema_entity_types: {
        'group': this.$t('api_service.data_type.group'),
        'string': this.$t('api_service.data_type.string'),
        'number': this.$t('api_service.data_type.number'),
        'integer': this.$t('api_service.data_type.integer'),
        'boolean': this.$t('api_service.data_type.boolean'),
        'array': this.$t('api_service.data_type.array'),
        'object': this.$t('api_service.data_type.object'),
        'schema': this.$t('api_service.data_type.schema'),
        'one_of': this.$t('api_service.data_type.one_of')
      },
      root_schemas: [],
      show_schema_properties: false,
      schema_properties: {
        array: {
          all_of: {
            value: null,
            data: []
          },
          any_of: {
            value: null,
            data: []
          },
          items: {
            value: null,
            data: []
          },
          one_of: {
            value: null,
            data: []
          },
          max_items: null,
          min_items: null,
          nullable: null,
          unique_items: null
        },
        boolean: {
          nullable: null
        },
        one_of: {
          value: null,
          data: []
        },
        schema: {
          $ref: {
            value: null,
            data: null
          }
        },
        string: {
          enum: {
            value: null,
            data: []
          },
          format: null,
          nullable: null
        },
        object: {
          nullable: null,
          required: {
            value: null,
            data: []
          }
        },
        integer: {
          exclusive_maximum: null,
          exclusive_minimum: null,
          format: null,
          maximum: null,
          minimum: null,
          multiple_of: null,
          nullable: null
        },
        number: {
          exclusive_maximum: null,
          exclusive_minimum: null,
          format: null,
          maximum: null,
          minimum: null,
          multiple_of: null,
          nullable: null
        }
      }
    }
  },
  async mounted () {
    await this.prepareDefaultData()
    this.getEventBus().$on('show_request_form', async (request) => {
      this.request_node = request
      await this.prepareRequest(request)
    })
    this.getEventBus().$on('show_schema_form', async (schema) => {
      this.schema_node = schema
      await this.prepareSchema(schema)
    })
  },
  beforeDestroy () {
    this.getEventBus().$off('show_request_form', async (request) => {
      this.request_node = request
      await this.prepareRequest(request)
    })
    this.getEventBus().$off('show_schema_form', async (schema) => {
      this.schema_node = schema
      await this.prepareSchema(schema)
    })
  },
  methods: {
    async prepareRequest (request) {
      // это чтобы форма рендерилась корректно, при нажатии на один и тот же узел
      if (this.request.hasOwnProperty('guid')) {
        if (this.request.element.guid === request.data.element.guid) {
          return
        }
      }
      this.request = request.data
      this.$set(this.handler_value, 'handler_value', null)
      this.$set(this.handler_type, 'response_source_type', null)
      this.$set(this.request_response, 'response_scheme', null)

      if (request.data.element_type === 'request') {
        await this.prepareRequestProperties(request)
      }
    },
    async prepareRequestProperties (request) {
      if (
        this.$refs.handler_type_select ||
        this.$refs.handler_value_select ||
        this.$refs.scheme_response
      ) {
        this.$refs.handler_type_select.clearValue()
        this.$refs.handler_value_select.clearValue()
        this.$refs.scheme_response.clearValue()
      }

      if (!request.data.element.properties) {
        await this.getHandlerValueDataByHandlerType(null)
      } else {
        let handlerType = request.data.element.properties.find((type) => type.id === 'handler_type')
        let handlerValue = request.data.element.properties.find((type) => type.id === 'handler_value')
        let responseScheme = request.data.element.properties.find((type) => type.id === 'response')

        this.handler_type.response_source_type = handlerType?.value
        // парси на бэке проперти корректно, если id прилетает строкой (запрос/расш.таб) - в списке выбор будет кривой
        // а пока такой костыль
        this.handler_value.response_source =
          handlerType.value !== 'plugin' ? parseInt(handlerValue.value) : handlerValue.value
        this.request_response.response_scheme = responseScheme?.value?.$ref

        await this.getHandlerValueDataByHandlerType(handlerType.value)
      }

      this.show_request_properties = true
    },
    async getHandlerValueDataByHandlerType (type) {
      // @todo перевести на нормальные запросы из репозитория
      this.handler_value.response_source_data = []
      let data = []
      switch (type) {
        case 'plugin':
          data = await APIClient.shared.request(new PluginAPI.GetPlugins())
          break
        case 'query':
          data = await new Query().$get()
          break
        case 'extended_object':
          data = await new ExtendedObject().$get()
          break
        case 'report':
          data = await new Report().params({ report_type: 'json' }).$get()
          break
        case 'etl_editor':
          data = await new Task().$get()
          break
      }

      this.handler_value.response_source_data = data
    },
    async saveEntity (type) {
      let request = this.request.element
      let properties = []

      if (this.handler_type.response_source_type) {
        properties.push({
          id: 'handler_type',
          type: 'string',
          value: this.handler_type.response_source_type
        })
      }

      if (this.handler_value.response_source) {
        properties.push({
          id: 'handler_value',
          type: 'string',
          value: this.handler_value.response_source
        })
      }

      if (this.request_response.response_scheme) {
        properties.push({
          id: 'response',
          type: 'object',
          value: {
            $ref: this.request_response.response_scheme
          }
        })
      }

      await this.getCommandBus().execute(
        new UpdateRequestEntityCommand(
          new Request({
            id: request.id,
            guid: request.guid,
            name: request.name,
            url: request.url,
            row_order: request.row_order,
            method_type_id: request.method_type_id,
            protocol_type: request.protocol_type,
            connection_id: request.connection_id,
            topic_id: request.topic_id,
            properties: properties
          }),
          type
        )
      )
      this.getEventBus().$emit('refresh', this.request_node.parent)
    },
    async prepareSchema (schema) {
      this.scheme = schema.data

      await this.prepareSchemaPropertiesByType(schema.data, schema.data.entity_type_id)
    },
    async prepareDefaultData () {
      let allRootSchemas = await this.getQueryBus().execute(new RequestSchemeEntitiesQuery())
      this.request_response.response_scheme_data = allRootSchemas
      this.root_schemas = allRootSchemas
      this.schema_properties.schema.$ref.data = allRootSchemas

      for (const [key, value] of Object.entries(this.schema_entity_types)) {
        let item = { id: key, name: value }
        if (key !== 'group' || key !== 'schema') {
          this.schema_properties.array.items.data.push(item)
        }
        this.schema_properties.array.all_of.data.push(item)
        this.schema_properties.array.any_of.data.push(item)
        this.schema_properties.array.one_of.data.push(item)
        this.schema_properties.one_of.data.push(item)
      }

      allRootSchemas.map(rootSchema => {
        let item = { id: rootSchema.id, name: rootSchema.name }
        this.schema_properties.array.items.data.push(item)
        this.schema_properties.array.all_of.data.push(item)
        this.schema_properties.array.any_of.data.push(item)
        this.schema_properties.array.one_of.data.push(item)
        this.schema_properties.one_of.data.push(item)
      })
    },
    async prepareSchemaPropertiesByType (schema, type) {
      switch (type) {
        case 'array':
          this.schema_properties.array.items.value = schema.properties.find((type) => type.id === 'items').value
          this.schema_properties.array.all_of.value = schema.properties.find((type) => type.id === 'all_of').value
          this.schema_properties.array.any_of.value = schema.properties.find((type) => type.id === 'any_of').value
          this.schema_properties.array.one_of.value = schema.properties.find((type) => type.id === 'one_of').value

          this.schema_properties.array.max_items = schema.properties.find((type) => type.id === 'max_items').value
          this.schema_properties.array.min_items = schema.properties.find((type) => type.id === 'min_items').value
          this.schema_properties.array.nullable = schema.properties.find((type) => type.id === 'nullable').value
          this.schema_properties.array.unique_items = schema.properties.find((type) => type.id === 'unique_items').value
          break
        case 'boolean':
          this.schema_properties.boolean.nullable = schema.properties.find((type) => type.id === 'nullable').value
          break
        case 'one_of':
          this.schema_properties.one_of.value = schema.properties.find((type) => type.id === 'one_of').value
          break
        case 'schema':
          this.schema_properties.schema.$ref.value = schema.properties.find((type) => type.id === '$ref').value
          break
        case 'string':
          let enums = schema.properties.find((type) => type.id === 'enum')
          this.schema_properties.string.enum.value = enums?.value

          if (enums && enums?.length > 0) {
            this.schema_properties.string.enum.data = enums.value.map(item => {
              return {
                id: item, label: item
              }
            })
          }

          this.schema_properties.string.format = schema.properties.find((type) => type.id === 'format')?.value
          this.schema_properties.string.nullable = schema.properties.find((type) => type.id === 'nullable')?.value
          break
        case 'object':
          let required = schema.properties.find((type) => type.id === 'required')
          this.schema_properties.object.required.value = required?.value
          this.schema_properties.object.nullable = schema.properties.find((type) => type.id === 'nullable')?.value
          break
        case 'integer':
          this.schema_properties.integer.exclusive_maximum = schema.properties.find((type) => type.id === 'exclusive_maximum')?.value
          this.schema_properties.integer.exclusive_minimum = schema.properties.find((type) => type.id === 'exclusive_minimum')?.value
          this.schema_properties.integer.format = schema.properties.find((type) => type.id === 'format')?.value
          this.schema_properties.integer.maximum = schema.properties.find((type) => type.id === 'maximum')?.value
          this.schema_properties.integer.minimum = schema.properties.find((type) => type.id === 'minimum')?.value
          this.schema_properties.integer.multiple_of = schema.properties.find((type) => type.id === 'multiple_of')?.value
          this.schema_properties.integer.nullable = schema.properties.find((type) => type.id === 'nullable')?.value

          break
        case 'number':
          this.schema_properties.number.exclusive_maximum = schema.properties.find((type) => type.id === 'exclusive_maximum')?.value
          this.schema_properties.number.exclusive_minimum = schema.properties.find((type) => type.id === 'exclusive_minimum')?.value
          this.schema_properties.number.format = schema.properties.find((type) => type.id === 'format')?.value
          this.schema_properties.number.maximum = schema.properties.find((type) => type.id === 'maximum')?.value
          this.schema_properties.number.minimum = schema.properties.find((type) => type.id === 'minimum')?.value
          this.schema_properties.number.multiple_of = schema.properties.find((type) => type.id === 'multiple_of')?.value
          this.schema_properties.number.nullable = schema.properties.find((type) => type.id === 'nullable')?.value

          break
      }
    },
    async saveSchemaEntity (type) {
      let schema = this.scheme
      let properties = []

      switch (type) {
        case 'array':
          properties.push({
            id: 'items',
            type: 'string',
            value: this.schema_properties.array.items.value
          })
          properties.push({
            id: 'all_of',
            type: 'array',
            value: this.schema_properties.array.all_of.value
          })
          properties.push({
            id: 'any_of',
            type: 'array',
            value: this.schema_properties.array.any_of.value
          })
          properties.push({
            id: 'one_of',
            type: 'array',
            value: this.schema_properties.array.one_of.value
          })
          properties.push({
            id: 'max_items',
            type: 'integer',
            value: this.schema_properties.array.max_items
          })
          properties.push({
            id: 'min_items',
            type: 'integer',
            value: this.schema_properties.array.min_items
          })
          properties.push({
            id: 'nullable',
            type: 'boolean',
            value: this.schema_properties.array.nullable
          })
          properties.push({
            id: 'unique_items',
            type: 'boolean',
            value: this.schema_properties.array.unique_items
          })
          break
        case 'boolean':
          properties.push({
            id: 'nullable',
            type: 'boolean',
            value: this.schema_properties.boolean.nullable
          })
          break
        case 'one_of':
          properties.push({
            id: 'one_of',
            type: 'array',
            value: this.schema_properties.one_of.value
          })
          break
        case 'schema':
          properties.push({
            id: '$ref',
            type: 'integer',
            value: this.schema_properties.schema.$ref.value
          })
          break
        case 'string':
          properties.push({
            id: 'enum',
            type: 'array',
            value: this.schema_properties.string.enum.value
          })
          properties.push({
            id: 'format',
            type: 'string',
            value: this.schema_properties.string.format
          })
          properties.push({
            id: 'nullable',
            type: 'boolean',
            value: this.schema_properties.string.nullable
          })
          break
        case 'object':
          properties.push({
            id: 'required',
            type: 'array',
            value: this.schema_properties.object.required.value
          })
          properties.push({
            id: 'nullable',
            type: 'boolean',
            value: this.schema_properties.object.nullable
          })
          break
        case 'integer':
          properties.push({
            id: 'exclusive_maximum',
            type: 'boolean',
            value: this.schema_properties.integer.exclusive_maximum
          })
          properties.push({
            id: 'exclusive_minimum',
            type: 'boolean',
            value: this.schema_properties.integer.exclusive_minimum
          })
          properties.push({
            id: 'format',
            type: 'string',
            value: this.schema_properties.integer.format
          })
          properties.push({
            id: 'maximum',
            type: 'float',
            value: this.schema_properties.integer.maximum
          })
          properties.push({
            id: 'minimum',
            type: 'float',
            value: this.schema_properties.integer.minimum
          })
          properties.push({
            id: 'multiple_of',
            type: 'float',
            value: this.schema_properties.integer.multiple_of
          })
          properties.push({
            id: 'nullable',
            type: 'float',
            value: this.schema_properties.integer.nullable
          })
          break
        case 'number':
          properties.push({
            id: 'exclusive_maximum',
            type: 'boolean',
            value: this.schema_properties.number.exclusive_maximum
          })
          properties.push({
            id: 'exclusive_minimum',
            type: 'boolean',
            value: this.schema_properties.number.exclusive_minimum
          })
          properties.push({
            id: 'format',
            type: 'string',
            value: this.schema_properties.number.format
          })
          properties.push({
            id: 'maximum',
            type: 'float',
            value: this.schema_properties.number.maximum
          })
          properties.push({
            id: 'minimum',
            type: 'float',
            value: this.schema_properties.number.minimum
          })
          properties.push({
            id: 'multiple_of',
            type: 'float',
            value: this.schema_properties.number.multiple_of
          })
          properties.push({
            id: 'nullable',
            type: 'float',
            value: this.schema_properties.number.nullable
          })
          break
      }

      await this.getCommandBus().execute(
        new UpdateSchemaCommand(
          new SchemaTreeDTO({
            id: schema.id,
            guid: schema.guid,
            name: schema.name,
            row_order: schema.row_order,
            parent_id: schema.parent_id,
            entity_type_id: schema.entity_type_id,
            is_schema: schema.is_schema,
            schema: schema.schema,
            properties: properties
          })
        )
      )
      this.getEventBus().$emit('refresh_schema', this.schema_node.parent)
    }
  }
}
</script>

<style scoped lang="scss">
.request_view_toolbar {
  width: 100%;
  height: 100%;
  background-color: white;
}
.form_element {
  width: calc(100% - 10px);
  padding: 5px;
}

.label {
  font-family: Roboto, Sans-Serif;
  font-size: medium;
  font-weight: bold;
  padding: 5px;
  display: inline-block;
}
.properties_container {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 10px 10px 10px 5px;
}
.main_properties_container {
  display: flex;
  flex-direction: row;
}
.request_save_button {
  margin-left: 5px;
}
.schema_properties_container {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 10px 10px 10px 5px;
}
</style>
