<template>
  <div :id="`viewer_${guid}`" class="bpmn">
  </div>
</template>
<script>
import NavigatedViewer from 'bpmn-js/dist/bpmn-navigated-viewer.production.min'
import CustomRenderer from '@/services/BPMNEditor/infrastructure/modules/CustomRenderer'

export default {
  name: 'SchemaViewer',
  props: {
    schema: String,
    actions: Array
  },
  data () {
    return {
      guid: this.generateGuid(),
      modeler: undefined,
      rendered: false
    }
  },
  beforeDestroy () {
    if (this.modeler) {
      this.modeler.destroy()
    }
  },
  async mounted () {
    this.modeler = new NavigatedViewer({
      container: `#viewer_${this.guid}`,
      additionalModules: [
        CustomRenderer
      ]
    })
    await this.modeler.importXML(this.schema).catch((error) => {
      console.log(error)
      if ((this.schema || '').trim().length === 0) {
        this.$message.warning('Некорректная схема')
      }
    })
    this.modeler.get('canvas').zoom('fit-viewport')
    this.rendered = true
  },
  computed: {
    _actions () {
      return this.rendered ? this.actions : []
    }
  },
  watch: {
    _actions (value) {
      value.forEach(item => {
        if (item.status) {
          this.modeler.get('canvas').addMarker(item.id, 'highlight_' + item.status)
        }
      })
      this.$nextTick(() => {
        const elementRegistry = this.modeler.get('elementRegistry')
        const elements = Object.keys(elementRegistry._elements)
        elements.forEach(key => {
          const element = elementRegistry._elements[key]
          if (!element.gfx?.classList.contains('djs-connection') && element.gfx?.classList.value.indexOf('layer-root') === -1) {
            if (element.gfx?.classList.value.indexOf('highlight_') === -1) {
              this.modeler.get('canvas').addMarker(element.element.id, 'highlight_not_started')
            }
          }
        })
      })
    }
  }
}
</script>
<style scoped>
.bpmn {
  width: 100%;
  height: 100%;
}
</style>
<style>
.highlight_completed:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgba(232, 248, 244, 1) !important;
  stroke: rgba(24, 179, 142, 1) !important;
}
.highlight_completed:not(.djs-connection) .djs-visual .djs-icon {
  fill: rgba(24, 179, 142, 1) !important;
  stroke: rgba(24, 179, 142, 1) !important;
}

.highlight_started:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgba(244, 237, 254, 1) !important;
  stroke: rgba(143, 68, 239, 1) !important;
}
.highlight_started:not(.djs-connection) .djs-visual > .djs-icon {
  fill: rgba(143, 68, 239, 1) !important;
  stroke: rgba(143, 68, 239, 1) !important;
}

.highlight_not_started:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgba(245, 245, 245, 1) !important;
  stroke: rgba(204, 204, 204, 1) !important;
}
.highlight_not_started:not(.djs-connection) .djs-visual > .djs-icon {
  fill: rgba(204, 204, 204, 1) !important;
  stroke: rgba(204, 204, 204, 1) !important;
}
</style>
