<template>
  <div class="log-service">
    <LogPanel />
  </div>
</template>

<script>
import Vue from 'vue'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import LogPanel from '@/services/LogService/infrastructure/components/LogPanel/index.vue'

import ResourceHandler from '@/services/LogService/application/handler/query/ResourceHandler'
import LogsByResourceHandler from '@/services/LogService/application/handler/query/LogsByResourceHandler'
import LogsByResourceAndDateHandler from '@/services/LogService/application/handler/query/LogsByResourceAndDateHandler'
import CountByResourceHandler from '@/services/LogService/application/handler/query/CountByResourceHandler'
import LogsRepository from '@/services/LogService/infrastructure/domain/repository/LogsRepository'

export default {
  name: 'LogService',
  components: {
    LogPanel
  },
  data () {
    return {
      eventBus: new Vue(),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ResourceQuery': new ResourceHandler(
            new LogsRepository()
          ),
          'LogsByResourceQuery': new LogsByResourceHandler(
            new LogsRepository()
          ),
          'CountByResourceQuery': new CountByResourceHandler(
            new LogsRepository()
          ),
          'LogsByResourceAndDateQuery': new LogsByResourceAndDateHandler(
            new LogsRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>

<style lang="scss">
  @import "./LogService";
</style>
