import CommandInterface from '@/core/application/command/CommandInterface'

export default class ImportStructureCommand implements CommandInterface {
  private payload: object;

  constructor (
    payload: object
  ) {
    this.payload = payload
  }

  getClassName (): string {
    return 'ImportStructureCommand'
  }

  getPayload (): object {
    return this.payload
  }
}
