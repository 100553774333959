import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CssClassDeleteCommand from '@/services/CssEditor/application/command/CssClassDeleteCommand'
import CssClassCommandRepositoryInterface from '@/services/CssEditor/domain/repository/CssClassCommandRepositoryInterface'

export default class CssClassDeleteCommandHandler implements CommandHandlerInterface {
  private repository: CssClassCommandRepositoryInterface;

  constructor (repository: CssClassCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: CssClassDeleteCommand): Promise<void> {
    return this.repository.delete(command.getGuid())
  }
}
