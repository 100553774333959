
import { PropType } from 'vue'
import InputNumber from './InputNumber.vue'
import utils from '@/services/CssEditor/infrastructure/components/utils'
import InputMixin from '@/services/CssEditor/infrastructure/components/InputMixin'
import mixins from 'vue-typed-mixins'

export default mixins(InputMixin).extend({
  name: 'InputSize',

  components: {
    InputNumber
  },

  props: {
    value: String as PropType<string>
  },

  computed: {
    nativeValue () {
      if (this.isVarValue) {
        return this.localValue
      }

      return this.localValue + this.sizeClass
    },

    isVarValue () {
      return !!this.variables[this.localValue]
    },

    allTemplates () {
      const result = []

      for (const group of this.templates) {
        for (const item of group.children) {
          result.push(item.value)
        }
      }

      return result
    }
  },

  watch: {
    value () {
      if (this.value !== this.nativeValue) {
        this.parseValue()
      }
    },

    nativeValue () {
      if (this.nativeValue !== this.value) {
        this.$emit('change', [this.nativeValue, this.value])
        this.$emit('input', this.nativeValue)
      }
    },

    localValue () {
      if (this.templateValue === this.nativeValue) {
        return
      }

      if (this.allTemplates.includes(this.nativeValue)) {
        this.templateValue = this.nativeValue
      } else {
        this.templateValue = 'custom'
      }
    },

    sizeClass () {
      if (this.allTemplates.includes(this.nativeValue)) {
        this.templateValue = this.nativeValue
      } else {
        this.templateValue = 'custom'
      }
    },

    templateValue () {
      if (this.templateValue === this.nativeValue) {
        return
      }

      if (this.templateValue !== 'custom') {
        if (typeof this.variables[this.templateValue] !== 'undefined') {
          this.localOldValue = this.localValue
        }

        this.localValue = this.templateValue
      } else {
        if (this.isVarValue && typeof this.variables[this.templateValue] === 'undefined') {
          this.localValue = this.localOldValue
        }
      }
    }
  },

  data () {
    return {
      templateValue: 'custom',
      sizeClass: 'px',

      templates: [
        {
          label: 'Templates',
          children: [
            { label: 'Custom', value: 'custom' },
            { label: '12px', value: '12px' },
            { label: '13px', value: '13px' },
            { label: '14px', value: '14px' },
            { label: '16px', value: '16px' },
            { label: '18px', value: '18px' },
            { label: '20px', value: '20px' },
            { label: '22px', value: '22px' },
            { label: '28px', value: '28px' },
            { label: '36px', value: '36px' },
            { label: '48px', value: '48px' }
          ]
        },

        {
          label: 'Variables',
          // children: [
          //   { label: 'Extra Large', value: '$--font-size-extra-large' },
          //   { label: 'Large', value: '$--font-size-large' },
          //   { label: 'Medium', value: '$--font-size-medium' },
          //   { label: 'Base', value: '$--font-size-base' },
          //   { label: 'Small', value: '$--font-size-small' },
          //   { label: 'Extra Small', value: '$--font-size-extra-small' }
          // ]
          children: this.variableList.filter(v => v.value !== this.currentVariable)
        }
      ],
      sizeClasses: utils.sizeClasses,

      localValue: 0,
      localOldValue: 14
    }
  },

  mounted () {
    this.parseValue()
  },

  methods: {
    parseValue (): void {
      const value = this.variables[this.value] || this.value

      this.localValue = parseInt(value)
      this.localValue = isNaN(this.localValue) ? 0 : this.localValue

      this.sizeClass = utils.getSizeClass(value)
    }
  }
})
