<template>
  <div class="window" :style="{zIndex: zIndex}">
    <div class="overlay" @click="closeWindow($event)">
      <div class="content" @click="closeWindow($event)">
        <div class="header">Настройки</div>
        <el-scrollbar :style="{height:'calc(100% - 29px - 28px - 40px)'}" wrap-style="overflow-x:hidden;" ref="scroll">
          <div class="settings">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="block">
                  <span class="label">{{ $locale.interface_editor.settings.card.background }}</span>
                  <editor-bg-card v-model="value"></editor-bg-card>
                </div>
                <div class="block">
                  <span class="label" style="display: block">
                    {{ $locale.interface_editor.settings.card.background_color }}
                  </span>
                  <div style="display: inline-flex; align-items: center; height: 40px;">
                    <el-color-picker show-alpha v-model="value.backgroundColor"></el-color-picker>
                    <el-input type="text" size="small" v-model="value.backgroundColor"
                              placeholder="rgba(111, 54, 210, 0.5)"></el-input>
                  </div>
                </div>
                <div class="block">
                  <span class="label">
                    {{ $locale.interface_editor.settings.card.CSS }}
                  </span>
                  <!-- <el-input
                    type="textarea"
                    :rows="4"
                    :placeholder="$locale.interface_editor.settings.card.CSS"
                    v-model="value.CSS">
                  </el-input> -->
                  <codemirror v-model="value.CSS" :options="cmOption" />
                </div>
                <div class="block">
                  <span class="label">
                    {{ $locale.interface_editor.settings.card.user_class }}
                  </span>
                  <el-input type="text" size="small" v-model="value.userClass"
                            :placeholder="$locale.interface_editor.settings.card.user_class"></el-input>
                </div>
              </el-col>
              <el-col :span="12" style="padding: 10px">
                  <slot name="settings"></slot>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
        <div class="button" @click.stop="closeWindow($event)">Закрыть</div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorBgCard from '@/components/InterfaceEditor/components/editor/editor-background-card'
import { PopupManager } from 'element-ui/src/utils/popup'

// language
import 'codemirror/mode/css/css.js'
// theme css
import 'codemirror/theme/paraiso-light.css'
// require active-line.js
import 'codemirror/addon/selection/active-line.js'
export default {
  name: 'SettingsWindow',
  props: {
    active: Boolean,
    value: {
      type: Object
    }
  },
  components: {
    EditorBgCard
  },
  data () {
    return {
      isClickedOnContent: false,
      zIndex: 0,
      cmOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/css',
        theme: 'paraiso-light'
      }
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    closeWindow (isClickedOnContent) {
      let isClickBtn = isClickedOnContent.target.classList.contains('button')
      if (isClickBtn) {
        this.$emit('update:active', false)
      }

      // if (isClickedOnContent) {
      //   this.isClickedOnContent = true
      // } else {
      //   if (this.isClickedOnContent) {
      //     this.isClickedOnContent = false
      //   } else {
      //     this.$emit('update:active', false)
      //   }
      // }
    }
  }
}
</script>

<style scoped>
.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
}

.window .overlay {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center
}

.window .content {
  width: 80%;
  height: 80%;
  overflow: hidden;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  margin-top: 15px;
  height: 28px;
  color: #2C2D35;
}

.settings {
  padding: 5px 24px;
}
.settings .block:not(:first-child):not(:last-child) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.settings .block:first-child {
  margin-bottom: 10px;
}
.settings .block:last-child {
  margin-top: 10px;
}

.label {
  color: #7d7d7d;
  padding-bottom: 10px;
  display: block;
}
.button {
  display: inline-block;
  float: right;
  margin-right: 5px;
}
</style>
<style scoped src="../../../main.scss" lang="scss"></style>
