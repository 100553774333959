import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TransformerQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerQueryRepositoryInterface'
import TransformerTypesQuery from '@/services/EtlEditor/application/query/TransformerTypesQuery'

export default class TransformerTypesHandler implements QueryHandlerInterface {
  private repository: TransformerQueryRepositoryInterface;

  constructor (repository: TransformerQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TransformerTypesQuery): Promise<any> {
    return this.repository.getTypes()
  }
}
