<template>
  <el-dialog
    :title="title"
    :visible.sync="isShow"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="model" size="small"
      @submit.native.prevent="save"
    >
      <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
        <el-input v-model="model.name"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.description')" prop="alias">
        <el-input v-model="model.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
        <el-input v-model="model.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('bi_editor.form.label.object_id')" prop="object_id">
        <registry-select-tree v-model="model.object_id" type="registry" value-as="number" />
      </el-form-item>

      <el-form-item>
        <el-checkbox :label="$t('bi_editor.form.label.is_materialized')" v-model="model.is_materialized"></el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox :label="$t('bi_editor.form.label.is_group_by_record')" v-model="model.is_group_by_record"></el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-checkbox :label="$t('bi_editor.form.label.is_based_on_table')" v-model="model.is_based_on_table"></el-checkbox>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">
        {{ $t('bi_editor.form.btn_cancel') }}
      </el-button>

      <el-button type="primary" @click="save" size="small">
        {{ $t('bi_editor.form.btn_save') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RegistrySelectTree from '../../Common/RegistrySelectTree'

export default {
  name: 'CreateExtendedObject',

  components: {
    RegistrySelectTree
  },

  props: {
    title: String,

    value: Object,

    rules: Object,

    isShow: Boolean,

    beforeClose: {
      type: Function,
      default: function (isNewEntity) {
      }
    },

    submit: {
      type: Function,
      default: async function (isNewEntity) {
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.submit(true)
        } else {
          return false
        }
      })
    },

    close (isNewEntity = true) {
      this.beforeClose(isNewEntity)
    }
  }
}
</script>
