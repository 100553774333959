
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class TopicRightDTO {
    role_guid?: Nullable<string>;
    topic_id?: Nullable<number>;
    topic_name?: Nullable<string>;
    is_topic_producer?: Nullable<boolean>;
    is_topic_consumer?: Nullable<boolean>;

    constructor ({ role_guid, topic_id, topic_name, is_topic_producer, is_topic_consumer }: {role_guid?: Nullable<string>, topic_id?: Nullable<number>, topic_name?: Nullable<string>, is_topic_producer?: Nullable<boolean>, is_topic_consumer?: Nullable<boolean>}) {
      this.role_guid = role_guid
      this.topic_id = topic_id
      this.topic_name = topic_name
      this.is_topic_producer = is_topic_producer
      this.is_topic_consumer = is_topic_consumer
    }
}

export default class TopicRight extends AbstractBaseEntity {
    private roleGuid: Nullable<string>;
    private topicId: Nullable<number>;
    private topicName: Nullable<string>;
    private isTopicProducer: Nullable<boolean>;
    private isTopicConsumer: Nullable<boolean>;

    constructor (
      roleGuid: Nullable<string>,
      topicId: Nullable<number>,
      topicName: Nullable<string>,
      isTopicProducer: Nullable<boolean>,
      isTopicConsumer: Nullable<boolean>
    ) {
      super()
      this.roleGuid = roleGuid
      this.topicId = topicId
      this.topicName = topicName
      this.isTopicProducer = isTopicProducer ?? false
      this.isTopicConsumer = isTopicConsumer ?? false
    }

    static create (dto: TopicRightDTO): TopicRight {
      return new TopicRight(
        dto.role_guid,
        dto.topic_id,
        dto.topic_name,
        dto.is_topic_producer,
        dto.is_topic_consumer
      )
    }

    setRoleGuid (roleGuid: string): void {
      this.roleGuid = roleGuid
    }

    getRoleGuid (): string {
      return this.roleGuid
    }

    setTopicId (topicId: number): void {
      this.topicId = topicId
    }

    getTopicId (): number {
      return this.topicId
    }

    setTopicName (topicName : string): void {
      this.topicName = topicName
    }

    getTopicName (): string {
      return this.topicName
    }

    setIsTopicProducer (isTopicProducer: boolean): void {
      this.isTopicProducer = isTopicProducer
    }

    getIsTopicProducer (): boolean {
      return this.isTopicProducer
    }

    setIsTopicConsumer (isTopicConsumer: boolean): void {
      this.isTopicConsumer = isTopicConsumer
    }

    getIsTopicConsumer (): boolean {
      return this.isTopicConsumer
    }
}
