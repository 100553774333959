import RoleCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/RoleCommandRepositoryInterface'
import { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import store from '@/store'
import { AddRole, UpdateRole, DeleteRole } from '@/services/AccessEditor/infrastructure/store/modules/Role/action-types'

export default class RoleCommandRepository implements RoleCommandRepositoryInterface {
  insert (item: RoleDTO): Promise<void> {
    return store.dispatch(new AddRole(item))
  }

  update (item: RoleDTO): Promise<void> {
    return store.dispatch(new UpdateRole(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteRole(guid))
  }
}
