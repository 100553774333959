import CommandInterface from '@/core/application/command/CommandInterface'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'

export default class ConstraintUpdateCommand implements CommandInterface {
  constructor (private readonly dto: ConstraintDTO) {}

  getClassName (): string {
    return 'ConstraintUpdateCommand'
  }

  getDTO (): ConstraintDTO {
    return this.dto
  }
}
