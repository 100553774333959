import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import RegionQueryRepositoryInterface from '@/services/GarAddressService/domain/repository/RegionQueryRepositoryInterface'
import RegionsQuery from '@/services/GarAddressService/application/query/RegionsQuery'

export default class RegionsHandler implements QueryHandlerInterface {
  private repository: RegionQueryRepositoryInterface;

  constructor (repository: RegionQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: RegionsQuery): Promise<any> {
    return this.repository.get(query.getPayload())
  }
}
