<template>
  <div>
    <el-dialog
      :title="$locale.main.system.about_system"
      :visible.sync="dialogVisible"
      width="50%"
      top='5vh'
      :before-close="closeDialog"
    >
      <el-table
        :data="services"
        v-loading="loading"
        class="custom_scrollbar"
        height="calc(70vh - 20px)"
        style="width: 100%">
        <el-table-column
          prop="title"
          :label="$locale.main.system.service">
        </el-table-column>
        <el-table-column
          prop="release"
          :label="$locale.main.system.release">
        </el-table-column>
        <el-table-column
          prop="release_date"
          :label="$locale.main.system.release_date">
        </el-table-column>
        <!-- <el-table-column
          prop="checksum"
          :label="$locale.main.system.check_sum">
        </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">{{ $locale.main.button.close }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AboutSystem',
  props: ['dialogVisible', 'closeDialog'],
  watch: {
    dialogVisible (val) {
      if (val && this.count) {
        this.loading = true
        this.count = false
        this.services.map(async (item) => {
          let name = item.name
          if (name.includes('.v2')) {
            name = 'v2/' + name.replace('.v2', '')
          }
          let response = await this.$http.get(`${this.$config.api}/${name}/status`)
          // заменить буквы на пустую строку (идентично другая регулярка / ^\D+ / )
          let filtered = ''
          if (response.data.release) {
            filtered = response.data.release.replace(/^[^\d]+/, '')
          }
          this.$set(item, 'release', filtered)
          this.$set(item, 'release_date', response.data.release_date)
          // this.$set(item, 'checksum', response.data.checksum)
        })
        this.loading = false
      }
    }
  },
  data () {
    return {
      loading: false,
      count: true,
      services: [
        {
          title: this.$locale.main.system.access_editor,
          name: 'accesseditor.v2'
        },
        {
          title: this.$locale.main.system.registry_service,
          name: 'registryservice'
        },
        {
          title: this.$locale.main.system.report_editor,
          name: 'reporteditor'
        },
        {
          title: this.$locale.main.system.interface_editor,
          name: 'interfaceeditor'
        },
        {
          title: this.$locale.main.system.menu_editor,
          name: 'menueditor'
        },
        {
          title: this.$locale.main.system.etl_editor,
          name: 'etleditor'
        },
        {
          title: this.$locale.main.system.etl_editor_v2,
          name: 'etleditor.v2'
        },
        {
          title: this.$locale.main.system.task_editor,
          name: 'taskeditor'
        },
        {
          title: this.$locale.main.system.object_editor,
          name: 'objecteditor'
        },
        {
          title: this.$locale.main.system.bi_editor,
          name: 'bieditor'
        },
        {
          title: this.$locale.main.system.logic_editor,
          name: 'logiceditor.v2'
        },
        {
          title: this.$locale.main.system.map_editor,
          name: 'mapeditor'
        },
        {
          title: this.$locale.main.system.system_service,
          name: 'systemservice'
        },
        {
          title: this.$locale.main.system.database_service,
          name: 'databaseservice'
        },
        {
          title: this.$locale.main.system.default_authentication,
          name: 'defaultauthentication'
        },
        {
          title: this.$locale.main.system.data_warehouse_service,
          name: 'datawarehouseservice'
        },
        {
          title: this.$locale.main.system.databus,
          name: 'databusservice'
        },
        {
          title: this.$locale.main.system.fiasaddress_service,
          name: 'fiasaddressservice'
        },
        {
          title: this.$locale.main.system.sync_service,
          name: 'syncservice'
        },
        {
          title: this.$locale.main.system.activity_service,
          name: 'activityservice'
        },
        {
          title: this.$locale.main.system.crypto_service,
          name: 'cryptoservice'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
