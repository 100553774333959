<template>
    <section class="changelogs-viewer" style="height: calc(100vh - 45px); overflow: hidden">
      <div class="left-side">
        <el-row class="main-table changelog-table">
          <el-col class="mini">
            <el-table
                :indent="0"
                class="registry custom_scrollbar"
                height="calc(100vh - 60px)"
                :data="serviceList"
                stripe
                border
                ref="changelog_table"
                highlight-current-row
                @current-change="loadChangeLog"
            >
              <!--
              <el-table-column
                  prop="encrypt"
                  align="left"
                  header-align="center"
                  width="40"
              >
                <template slot-scope="scope">
                  <i :class="scope.row.encrypt ? 'el-icon-lock' : ''" :title="$locale.main.system.encrypt"></i>
                </template>
              </el-table-column>
              -->
              <el-table-column
                  prop="name"
                  :label="$locale.activity_service.activity_panel.service"
                  align="left"
                  header-align="center"
                  width="290"
              ></el-table-column>
              <el-table-column
                  prop="version"
                  :label="$locale.address_service.regions.columns.version"
                  align="left"
                  header-align="center"
                  width="100"
              ></el-table-column>
              <el-table-column
                prop="release_date"
                :label="$locale.main.fields.date"
                align="left"
                header-align="center"
                width="140"
              ></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div class="right-side" style="height: calc(100vh - 50px)">
        <div ref="changelog-viewer" class="custom-scrollbar" style="height: 100%;">
          <span v-html="content"></span>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'ChangeLogTable',
  data () {
    return {
      serviceList: [],
      content: ''
    }
  },
  methods: {
    async getServiceListData() {
      this.$http
        .get(`${this.$config.api}/releaseeditor/services`)
        .then(response => {
          this.serviceList = response.data;
          for (let i = 0; i < this.serviceList.length; i++) {
            if (this.serviceList[i].release_date !== null) {
              let temp = this.serviceList[i].release_date.split(' ')[0];
              temp = temp.split('-');
              temp.reverse();
              temp = temp.join('.');
              this.serviceList[i].release_date = temp;
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    loadChangeLog (service) {
      if (service !== null) {
        this.$http
          .get(`${this.$config.api}/changelogs/` + service.code + '.md')
          .then(response => {
            let str = response.data.replace(/^(?:###+)\s*(.+?)$/gm,"<h3><b>$1</b></h3>");
            str = str.replace(/^(?:##+)\s*(.+?)$/gm,"<h2><b>$1</b></h2>");
            str = str.replace(/^(?:#+)\s*(.+?)$/gm,"<h1><b>$1</b></h1>");
            this.content = str.replace(/^\s-\s\s\W/gm, function(str) {
              return str.toUpperCase();
            });
          })
          .catch(error => console.log(error))
      }
    }
  },
  mounted() {
    this.getServiceListData().then(result => {
      this.loadChangeLog(this.serviceList[0]);
    });
  }
}
</script>

<style type="text/css">
  .changelogs-viewer .left-side {
    display: inline-block;
    width: 530px;
    height: calc(100vh - 60px);
  }
  .changelogs-viewer .right-side {
    display: inline-block;
    float: right;
    width: calc(100% - 540px);
    height: 100%;
  }
  .changelogs-viewer .right-side span {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  .changelogs-viewer .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }
  .changelogs-viewer .right-side div {
    padding: 20px;
  }
  .changelogs-viewer .changelog-table .registry tbody td .cell {
    font-size: 14px;
  }
  .changelogs-viewer table tbody tr td:nth-child(1):hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .changelogs-viewer h1 {
    margin-block-end: -20px;
  }
  .changelogs-viewer h2 {
    margin-block-end: -20px;
  }
  .changelogs-viewer h3 {
    margin-block-end: -10px;
  }
</style>
