import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerGroupCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerGroupCommandRepositoryInterface'
import LayerGroupUpdateCommand from '@/services/MapEditor/application/command/LayerGroupUpdateCommand'
import { LayerGroupDTO } from '@/services/MapEditor/domain/model/Layer/LayerGroup'

export default class LayerGroupUpdateHandler implements CommandHandlerInterface
{
    private repo: LayerGroupCommandRepositoryInterface;

    constructor(repo: LayerGroupCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: LayerGroupUpdateCommand): Promise<void>
    {
        let item: LayerGroupDTO = {
            guid: command.getGuid(),
            name: command.getName(),
            parent_id: command.getParentId()
        };
        return this.repo.update(item);
    }
}