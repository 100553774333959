<template>
  <div class="window" :style="{zIndex: zIndex}">
    <div class="overlay" @click="closeWindow(false)">
      <div class="content" @click="closeWindow(true)">
        <div class="header">Коллекция окон</div>
        <el-scrollbar :style="{height:'calc(100% - 29px - 28px - 40px)'}" wrap-style="overflow-x:hidden;" ref="scroll">
          <div class="settings">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="icon_button" @click="addWindow">
                  Добавить
                </div>
                <draggable :value="value" @input="$emit('update:value', $event)" group="people">
                  <div class="condition_row" :class="{'active':  activeWindow === _window}" v-for="(_window, index) in value" :key="_window.guid" @click="activeWindow = _window">
                    <div>
                      <div class="setting-label">{{index + 1}}. {{_window.name}}</div>
                      <i class="el-icon-close close_button" @click="remove(index)"></i>
                    </div>
                    <div class="setting-label-mini">Ширина: <b>{{_window.width.value + _window.width.type}}</b></div>
                    <div class="setting-label-mini">Высота: <b>{{_window.height.value + _window.height.type}}</b></div>
                  </div>
                </draggable>
              </el-col>
              <el-col :span="12">
                <div class="editor" v-if="activeWindow">
                  <div class="setting-label">
                    Название
                  </div>
                  <el-input v-model="activeWindow.name"></el-input>
                  <el-col :span="12" style="padding: 0">
                    <div class="setting-label">
                      Ширина
                    </div>
                    <el-input type="number" v-model="activeWindow.width.value">
                      <el-button slot="append"
                                 @click="activeWindow.width.type = switchSizeValue(activeWindow.width.type)"
                      >{{ activeWindow.width.type }}</el-button>
                    </el-input>
                  </el-col>
                  <el-col :span="12" style="padding: 0">
                    <div class="setting-label">
                      Высота
                    </div>
                    <el-input type="number" v-model="activeWindow.height.value">
                      <el-button slot="append"
                                 @click="activeWindow.height.type = switchSizeValue(activeWindow.height.type)"
                      >{{ activeWindow.height.type }}</el-button>
                    </el-input>
                  </el-col>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
        <div class="button" @click="closeWindow(false)">Сохранить и закрыть</div>
      </div>
    </div>
  </div>
</template>

<script>
import { PopupManager } from 'element-ui/src/utils/popup'
import draggable from 'vuedraggable'
export default {
  name: 'WindowsSettingsWindow',
  components: { draggable },
  props: {
    active: Boolean,
    value: {
      type: Array
    }
  },
  inject: ['getEventBus'],
  data () {
    return {
      isClickedOnContent: false,
      zIndex: 0,
      activeWindow: null
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('update:value', val)
      }
    }
  },
  methods: {
    switchSizeValue (value) {
      return value === '%' ? 'px' : '%'
    },
    remove (index) {
      this.value.splice(index, 1)
      this.activeWindow = null
    },
    addWindow () {
      this.value.push({
        name: 'Новое окно',
        guid: this.generateGuid(),
        width: {
          type: '%',
          value: 50
        },
        height: {
          type: '%',
          value: 50
        },
        structure: {}
      })
    },
    closeWindow (isClickedOnContent) {
      if (isClickedOnContent) {
        this.isClickedOnContent = true
      } else {
        if (this.isClickedOnContent) {
          this.isClickedOnContent = false
        } else {
          this.$emit('update:active', false)
        }
      }
    }
  }
}
</script>

<style scoped>
.window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-block;
}

.window .overlay {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center
}

.window .content {
  width: 80%;
  height: 80%;
  overflow: hidden;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  margin-top: 15px;
  height: 28px;
  color: #2C2D35;
}

.settings {
  padding: 5px 24px;
}

.button {
  display: inline-block;
  float: right;
  margin-right: 5px;
}
</style>
<style scoped src="../../../main.scss" lang="scss"></style>
<style scoped lang="scss">
@import '../../../variables';
.settings {
  & .icon_button {
    display: inline-block;
    background: $hover_block_color;
    margin-bottom: 5px;
    &:hover {
      background: $active_block_color;
    }
  }
}
.condition_row {
  cursor: pointer;
  padding: 10px 0px;
  &:hover {
    background: $hover_block_color;
  }
  &.active {
    background: $active_block_color;
  }
  & .setting-label {
    margin-bottom: 5px;
    width: calc(100% - 30px);
    display: inline-block;
  }
  & .setting-label-mini {
    text-transform: initial;
    padding-left: 10px;
  }
  .close_button {
    color: #949FA8;
    cursor: pointer;
    margin-right: 5px;
    vertical-align: top;
    &:hover {
      color: $active_text_color;
    }
  }
}
.editor {
  .setting-label {
    margin-top: 15px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
