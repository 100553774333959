import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import ConfigurationElementsEditorEntitiesQuery
  from '@/services/SyncService/application/query/ConfigurationElementsEditorEntitiesQuery'
import ConfigurationElementRepositoryInterface from '@/services/SyncService/domain/repository/ConfigurationElementRepositoryInterface'

export default class ConfigurationElementsEditorEntitiesHandler implements QueryHandlerInterface {
  private repo: ConfigurationElementRepositoryInterface

  constructor (repo: ConfigurationElementRepositoryInterface) {
    this.repo = repo
  }

  execute (query: ConfigurationElementsEditorEntitiesQuery): Promise<any> {
    return this.repo.getEditorEntities(query.getPayload())
  }
}
