
import Vue from 'vue'
import ThemeVariables from '@/services/CssEditor/infrastructure/components/ThemeVariables.json'

export default Vue.extend({
  name: 'VariableTable',

  computed: {
    variables () {
      if (this.filterText !== '') {
        const filterText = this.filterText.toLowerCase()

        return this.variableList.filter(v => {
          const fieldVariable = v.variable.toLowerCase()
          const fieldLabel = v.label.toLowerCase()

          return fieldVariable.indexOf(filterText) !== -1 || fieldLabel.indexOf(filterText) !== -1
        })
      }

      return this.variableList
    }
  },

  data () {
    return {
      filterText: '',

      variableList: ThemeVariables
    }
  }
})
