<template>
  <div class="transformer_form">
    <el-form ref="form" v-loading="!isLoaded" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.etl_editor_v2.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.alias" prop="alias">
        <el-input v-model="model.alias" :placeholder="$locale.etl_editor_v2.labels.alias" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.type" prop="transformer_type_id">
        <el-select v-model="model.transformer_type_id" :placeholder="$locale.etl_editor_v2.labels.type" @change="changeType" :disabled="!isNew">
          <el-option
            v-for="item in transformerTypes"
            :key="item.id"
            :label="$locale.etl_editor_v2.types[item.name]"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <properties-form v-if="isLoadedProperties" v-model="model.properties" :properties="transformerTypeProperties"></properties-form>
    </el-form>
  </div>
</template>

<script>
import TransformerByGuidQuery from '@/services/EtlEditor/application/query/TransformerByGuidQuery'
import TransformerTypePropertiesQuery from '@/services/EtlEditor/application/query/TransformerTypePropertiesQuery'
import TransformerTypesQuery from '@/services/EtlEditor/application/query/TransformerTypesQuery'
import Transformer from '@/services/EtlEditor/domain/model/Transformer'
import TransformerCreateCommand from '@/services/EtlEditor/application/command/TransformerCreateCommand'
import TransformerUpdateCommand from '@/services/EtlEditor/application/command/TransformerUpdateCommand'

import PropertiesForm from '../properties/PropertiesForm'

export default {
  name: 'TransformerForm',

  props: {
    value: Object
  },

  inject: ['getQueryBus', 'getCommandBus'],

  components: {
    PropertiesForm
  },

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    transformerCreated () {
      return this.$store.getters['Transformer/getTransformerLocation']
    },

    isLoadedProperties () {
      return this.transformerTypeProperties.length > 0
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    transformerCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new TransformerByGuidQuery(
            location.replace('/transformers/', '')
          )
        ).then((response) => {
          if (response.guid) {
            this.$emit('input', response)
          }
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: this.$locale.etl_editor_v2.rules.name, trigger: 'blur' }
        ],
        transformer_type_id: [
          { required: true, message: this.$locale.etl_editor_v2.rules.type, trigger: 'change' }
        ]
      },

      isLoaded: false,

      transformerTypes: [],
      transformerTypeProperties: []
    }
  },

  mounted () {
    if (!this.transformerTypes.length) {
      this.getQueryBus().execute(new TransformerTypesQuery()).then((response) => {
        if (response.length) {
          this.transformerTypes.push(...response)
        }
      })
    }

    if (!this.transformerTypeProperties.length && this.model.transformer_type_id) {
      this.getQueryBus().execute(new TransformerTypePropertiesQuery(this.model.transformer_type_id)).then((response) => {
        if (response.length) {
          this.transformerTypeProperties.push(...response)
        }
      })
    }

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    changeType (value) {
      this.transformerTypeProperties = []
      this.getQueryBus().execute(new TransformerTypePropertiesQuery(value)).then((response) => {
        this.model.properties = []

        if (response.length) {
          response.forEach(property => {
            this.model.properties.push({
              id: property.id,
              type: property.type,
              value: this.getDefValue(property)
            })
          })

          this.transformerTypeProperties.push(...response)
        }
      })
    },

    getDefValue (prop) {
      if (['boolean', 'integer', 'array', 'object'].indexOf(prop.primitive_type) !== -1 && prop.default_value !== null) {
        return JSON.parse(prop.default_value)
      } else {
        return prop.default_value
      }
    },

    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let model = Transformer.create(this.value)

      if (this.isNew) {
        return new TransformerCreateCommand(
          model.getName(),
          model.getTransformerTypeId(),
          model.getAlias(),
          model.getProperties(),
          model.getParentTreeElementId()
        )
      }

      return new TransformerUpdateCommand(
        model.getGuid(),
        model.getName(),
        model.getTransformerTypeId(),
        model.getAlias(),
        model.getProperties()
      )
    }
  }
}
</script>

<style scoped></style>
