import AbstractEntitiesQueryHandler from '@/core/application/query/AbstractEntitiesQueryHandler'
import LogsRepositoryInterface from '@/services/LogService/domain/repository/LogsRepositoryInterface'
import LogsByResourceAndDateQuery from '@/services/LogService/application/query/LogsByResourceAndDateQuery'

export default class LogsByResourceAndDateHandler extends AbstractEntitiesQueryHandler {
  private repository: LogsRepositoryInterface

  constructor (repo: LogsRepositoryInterface) {
    super(repo)
    this.repository = repo
  }

  execute (query: LogsByResourceAndDateQuery): Promise<any> {
    return this.repository.getLogsByResourceAndDate(query.getPayload(), query.getResource())
  }
}
