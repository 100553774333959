
import mixins from 'vue-typed-mixins'
import ProcedureCreateCommand from '@/services/LogicEditor/application/command/ProcedureCreateCommand'
import ProcedureUpdateCommand from '@/services/LogicEditor/application/command/ProcedureUpdateCommand'
import ProcedureByGuidQuery from '@/services/LogicEditor/application/query/ProcedureByGuidQuery'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'ProcedureForm',

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Procedure/isProcedureLoading']
    },

    procedureCreated () {
      return this.$store.getters['Procedure/getProcedureLocation']
    },

    procedureError () {
      return this.$store.getters['Procedure/getError']
    },

    rules () {
      const rules = {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$t('logic_editor_v2.rules.type'), trigger: 'change' }
        ],
        language: [
          { required: true, message: this.$t('logic_editor_v2.rules.language'), trigger: 'change' }
        ],
        name_db: [
          { required: true, message: this.$t('logic_editor_v2.rules.name_db'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('logic_editor_v2.rules.code'), trigger: 'blur' }
        ]
      }

      if (this.model.type === 'function') {
        rules['return_type'] = [
          { required: true, message: this.$t('logic_editor_v2.rules.return_type'), trigger: 'blur' }
        ]
      }

      return rules
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    procedureCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ProcedureByGuidQuery(
            location.replace('/procedures/', '')
          )
        ).then(response => {
          this.model = response
          this.$emit('created', response)
        })
      }
    },

    procedureError (error) {
      console.log('procedureError', error)

      if (typeof error.code !== 'undefined') {
        this.$alert(error.message.replaceAll('\n', '<br>'), 'Ошибка', {
          dangerouslyUseHTMLString: true,
          customClass: 'procedure_message'
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      showSql: false,

      options: {
        tabSize: 4,
        mode: 'text/x-pgsql',
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        collapseIdentical: true,
        highlightDifferences: false
      },

      accordionActive: null,

      procedureTypes: [
        { label: 'Функция', value: 'function' },
        { label: 'Процедура', value: 'procedure' }
      ],

      languages: [
        { label: 'SQL', value: 'sql' },
        { label: 'PL PG SQL', value: 'plpgsql' }
      ]
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              if (typeof callback !== 'undefined') {
                callback()
              }
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      if (this.isNew) {
        return new ProcedureCreateCommand(this.value)
      }

      return new ProcedureUpdateCommand(
        this.value
      )
    },

    allowNameDb () {
      this.model.name_db = this.model.name_db.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z]/ug, '')
    }
  }
})
