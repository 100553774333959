<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle" v-loading="loading" @click="onClick"
      :data-type="source.type"
      :data-source-id="source.entityId"
      :data-attribute="name || null"
      :data-alias="editorAlias || null">
    {{value}}
    <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
      <i class="el-icon-question"></i>
    </el-tooltip>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import RegistryCard from '@/components/RegistryCard/index.vue'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor.ts'

import FilterBuilder, { EComponentTypes } from '../utils'
import Dashboard from '@/components/Dashboard'
import { isNumeric, deepCompare, isEmpty } from '@/helpers'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'a-label',
  components: {
    Dashboard,
    RegistryCard
  },
  inject: {
    getViewer: {
      default: () => {
        return {}
      }
    },
    isEditor: {
      default: () => false
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },

    /* Зависимости ActionExecutor */
    getCard: {
      default: () => {}
    },
    getParentDashboard: {
      default: () => {}
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },
  mixins: [mixin, VisibleMixin, refreshComponentsMixin],
  props: {
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    editorAlias: {
      type: String,
      description: 'alias'
    },
    text: {
      type: String,
      description: 'text',
      default: 'Текст'
    },
    source: {
      type: Object,
      editor: 'Source',
      default: () => {
        return {
          type: null,
          entityId: null,
          valueField: {}
        }
      }
    },
    format: {
      type: String,
      description: 'form_date',
      options: {
        tooltip: {
          content: 'xref_format_date',
          show: true
        }
      }
    },
    action: {
      type: Object,
      editor: 'ButtonAction',
      default: () => {
        return {
          type: 'execute_plugin',
          dashboard: {
            id: null,
            isFullscreen: false,
            window_width: 25,
            window_title: null,
            breadcrumbByButton: true
          },
          command: {
            id: null,
            success_text: null,
            failure_text: null
          },
          card: {
            registryId: null,
            type: null,
            fieldId: null,
            constantRecordId: null,
            cardId: null,
            frameGuid: null,
            containerAlias: null,
            isWindow: false,
            windowWidth: 25,
            windowTitle: null,
            defaults: []
          },
          reports: {
            id: null,
            guid: null,
            name: null,
            formatType: null,
            viewType: null,
            registryId: null,
            assocFieldId: null,
            openSavedVersion: false
          },
          url: {
            url: null,
            openType: null
          }
        }
      }
    },
    pluginName: {
      type: String,
      description: 'plugin',
      editor: 'Plugin'
    },
    align: {
      type: String,
      description: 'align'
    },
    margin: {
      type: String,
      description: 'margin',
      default: '5px 10px'
    },
    block: {
      type: Boolean,
      description: 'full_line',
      default: true
    },
    size: {
      type: String,
      description: 'size_font'
    },
    weight: {
      type: Boolean,
      description: 'weight'
    },
    tooltip: {
      description: 'tooltip',
      type: String
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      loading: false,
      value: null,
      oldFilter: {},
      oldPayload: null
    }
  },
  computed: {
    variableValue () {
      if (this.source.type === 'variable' && this.source.valueField.name) {
        let value = null
        let isGetModel = this.getModel() && typeof this.getModel()[this.source.valueField.name] !== 'undefined'
        let isGetRawData = this.getRawData() && typeof this.getRawData()[this.source.valueField.name] !== 'undefined'

        // проверить является ли переменная (source.valueField.name) ссылкой
        let isXref = null
        if (isGetRawData) {
          try {
            isXref = Array.isArray(JSON.parse(this.getRawData()[this.source.valueField.name]))
          } catch (e) {
            isXref = Array.isArray(this.getRawData()[this.source.valueField.name])
          }
        }
        if (isGetModel && !isXref) {
          value = this.getModel()[this.source.valueField.name]
        } else if (isGetRawData) {
          value = this.getRawData()[this.source.valueField.name]
        }
        const isNumber = isNumeric(value)
        const saveValue = value

        try {
          value = JSON.parse(value)
        } catch (e) {
        }

        // JSON.parse() окгругляет большие значения  № 66231706600005424133-> № 66231706600005400000
        const format = (this.format || '').trim()

        if (isNumber && !Array.isArray(value) && !format) {
          return saveValue
        }

        if (value && Array.isArray(value)) {
          value = value.map(item => item.name).join(',')
        } else if (value && typeof value === 'object' && value?.address) {
          value = value.address
        }
        if (value && format && format.length > 0) {
          value = this.$moment(value).format(format)
        }
        if (value == null || value === undefined || value === '') {
          return this.text
        } else {
          return value
        }
      }
      return null
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.label
      )

      const filters = builder.buildAsApiQl()

      if (filters.length > 0) {
        return {
          where: {
            and: [...filters]
          }
        }
      }

      return {}
    },
    queryValue () {
      if (this.source.type === 'query' && !this.isEditor()) {
        let returnObj = {}
        this.source.queryParameters.forEach(el => {
          let value
          if (el.type === 'field') value = this.getModel()[el.attribute]
          if (el.type === 'constant') value = el.attribute
          if (el.type === 'current_user') value = this.$store.getters['Authorization/userId']

          let formattedObj = { [el.alias]: value || null }
          returnObj = Object.assign(returnObj, formattedObj)
        })
        return returnObj
      }
      return null
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.size) {
        css += ';font-size: ' + this.size
      }
      if (this.weight) {
        css += ';font-weight: bold'
      }

      return css
    }
  },
  watch: {
    dataFilters (val) {
      if (deepCompare(val, this.oldFilter)) {
        return
      }
      this.loadData()
    },
    text (val) {
      this.value = val
    },
    variableValue () {
      this.loadData()
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    },
    queryValue: {
      handler: async function (value) {
        if (!this.isEditor()) {
          let queryParams = {
            query_parameters: value
          }
          let data = {}
          if (deepCompare(value, this.oldPayload)) {
            return
          }
          try {
            data = await this.getQueryData(this.source.entityId, queryParams)
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
          let finalValue = this.text
          if (isEmpty(data)) {
            finalValue = ''
          }
          for (const alias in data) {
            finalValue = finalValue.replaceAll(`{{${alias}}}`, data[alias])
          }
          this.value = finalValue
          this.$set(this, 'value', finalValue)
          this.$emit('input', this.value)
        }
      },
      deep: true
    }
  },

  async mounted () {
    this.value = this.text
    if (!this.isEditor() && /user.attr_[0-9]+_/i.test(this.value)) {
      this.value = await this.$store.getters['Authorization/userAttributeData'](this.value.match(/attr_[0-9]+_/gi)[0])
    }
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (this.source.type === 'extended_object' && this.source.entityId && this.source.valueField.id && !this.isEditor()) {
        this.loading = true
        let data = this.hasCashInLabel()

        if (isEmpty(data)) {
          this.oldFilter = this.dataFilters
          try {
            data = await this.getExtendedObjectData(this.source.entityId, this.dataFilters)
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
        }
        if (!isEmpty(data)) {
          this.value = data[this.source.valueField.name] || this.text
        } else {
          this.value = this.text
        }
      } else if (this.source.type === 'variable' && this.source.valueField.name) {
        this.value = this.variableValue
      } else if (this.source.type === 'query' && this.source.entityId && !this.isEditor()) {
        this.loading = true
        let data = this.hasCashInLabel()

        if (isEmpty(data)) {
          let queryParams = {
            query_parameters: this.queryValue
          }
          this.oldPayload = this.queryValue
          try {
            data = await this.getQueryData(this.source.entityId, queryParams)
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
        }
        let finalValue = this.text
        if (isEmpty(data)) {
          finalValue = ''
        }

        for (const alias in data) {
          finalValue = finalValue.replaceAll(`{{${alias}}}`, data[alias])
        }
        this.value = finalValue
        this.$set(this, 'value', finalValue)
      }
      // Если указан атрибут - прокидывать значения ( для фильтров )
      if (this.name) {
        this.$emit('input', this.value)
      }
    },
    // проверить есть ли кеш
    hasCashInLabel () {
      let data = null
      if (this.$attrs?.cashData && Object.keys(this.$attrs.cashData).length) {
        data = this.$attrs.cashData
        // console.warn('берем закешированные', data)
        this.loading = false
        this.$attrs.cashData = {}
      }

      return data
    },
    async getExtendedObjectData (id, payload) {
      let response = null
      try {
        response = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${id}`, payload, {
          hideNotification: true
        })
        response = response.data[0]
        return response
      } catch (error) {
        throw Error(`Ошибка получения расширенной таблицы ${error.message}`)
      }
    },
    async getQueryData (id, payload) {
      let response = null
      try {
        response = await this.$http.post(`${this.$config.api}/datawarehouseservice/query/${id}`, payload, {
          hideNotification: true
        })
        response = response.data[0]
        return response
      } catch (error) {
        throw Error(`Ошибка получения запроса ${error.message}`)
      }
    },
    async onClick (event) {
      ActionExecutor.execute(this, { readonly: this._isReadonly, pluginName: this.pluginName, action: this.action, event: event })
    }
  }
}
</script>

<style scoped>

</style>
