import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import TopicCommandRepositoryInterface from '@/services/Databus/domain/repository/TopicCommandRepositoryInterface'
import TopicUpdateCommand from '@/services/Databus/application/command/TopicUpdateCommand'
import { TopicDTO } from '@/services/Databus/domain/model/Topic/Topic'

export default class TopicUpdateHandler implements CommandHandlerInterface
{
    private repo: TopicCommandRepositoryInterface;

    constructor(repo: TopicCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: TopicUpdateCommand): Promise<void>
    {
        let item: TopicDTO = {
            guid: command.getGuid(),
            code: command.getCode(),
            name: command.getName(),
            description: command.getDescription(),
            type: command.getType(),
            params: command.getParams()
        };
        return this.repo.update(item);
    }
}