
import Vue from 'vue'
import ApprovalForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalForm.vue'
import LogicForm from '@/services/LogicEditor/infrastructure/components/forms/LogicForm.vue'
import StateForm from '@/services/LogicEditor/infrastructure/components/forms/StateForm/index.vue'
import LogicGroupForm from '@/services/LogicEditor/infrastructure/components/forms/LogicGroupForm.vue'
import ConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ConstraintForm.vue'
import ViewConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ViewConstraintForm.vue'
import DisableConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/DisableConstraintForm.vue'
import ListenerForm from '@/services/LogicEditor/infrastructure/components/forms/ListenerForm.vue'
import CommandForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/index.vue'
import RelatedObjectForm from '@/services/LogicEditor/infrastructure/components/forms/RelatedObjectForm/RelatedObjectForm.vue'
import FormulaForm from '@/services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import RowFormulaForm from '@/services/LogicEditor/infrastructure/components/forms/RowFormulaForm.vue'
import ProcedureForm from '@/services/LogicEditor/infrastructure/components/forms/ProcedureForm/ProcedureForm.vue'

export default Vue.extend({
  name: 'EditorElementLayout',

  components: {
    ApprovalForm,
    LogicForm,
    StateForm,
    LogicGroupForm,
    ConstraintForm,
    ViewConstraintForm,
    DisableConstraintForm,
    ListenerForm,
    CommandForm,
    RelatedObjectForm,
    FormulaForm,
    RowFormulaForm,
    ProcedureForm
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    elementType: {
      type: String,
      required: true
    },

    objectId: {
      type: Number,
      default: null
    },

    logicId: {
      type: Number,
      default: null
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      if (typeof this.value.guid === 'undefined') {
        return true
      }

      return this.value.guid === null
    },

    showRecalculateFormula () {
      return this.elementType === 'formula' && !this.isNew && this.value.is_stored
    },

    approvalCreated () {
      return this.$store.getters['Approval/getApprovalLocation']
    },

    commandCreated () {
      return this.$store.getters['Command/getCommandLocation']
    },

    constraintCreated () {
      return this.$store.getters['Constraint/getConstraintLocation']
    },

    formulaCreated () {
      return this.$store.getters['Formula/getFormulaLocation']
    },

    listenerCreated () {
      return this.$store.getters['Listener/getListenerLocation']
    },

    logicCreated () {
      return this.$store.getters['Logic/getLogicLocation']
    },

    logicGroupCreated () {
      return this.$store.getters['LogicGroup/getLogicGroupLocation']
    },

    relatedObjectCreated () {
      return this.$store.getters['RelatedObject/getRelatedObjectLocation']
    },

    rowFormulaCreated () {
      return this.$store.getters['RowFormula/getRowFormulaLocation']
    },

    stateCreated () {
      return this.$store.getters['State/getStateLocation']
    },

    procedureCreated () {
      return this.$store.getters['Procedure/getProcedureLocation']
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    approvalCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/approvals/', ''))
      }
    },

    commandCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/commands/', ''))
      }
    },

    constraintCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/constraints/', ''))
      }
    },

    formulaCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/formulas/', ''))
      }
    },

    listenerCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/listeners/', ''))
      }
    },

    logicCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/logic/', ''), true)
      }
    },

    logicGroupCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/logic_groups/', ''))
      }
    },

    relatedObjectCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/related_objects/', ''))
      }
    },

    rowFormulaCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/row_formulas/', ''))
      }
    },

    stateCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/states/', ''))
      }
    },

    procedureCreated (location) {
      if (location !== null) {
        this.$emit('add-element', location.replace('/procedures/', ''))
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    recalculateFormula () {
      this.$http.post(`${this.$config.api}/v2/logiceditor/formulas/${this.value.guid}/recalculate`)
    },

    submit () {
      this.$refs.form.submit(() => {
        this.$emit('submit', this.isNew, this.elementType, this.value)
      })
    }
  }
})
