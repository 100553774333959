import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=13b49442&scoped=true&"
import script from "./MainLayout.vue?vue&type=script&lang=js&"
export * from "./MainLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=13b49442&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b49442",
  null
  
)

export default component.exports