import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import SourceQueryRepositoryInterface from '@/services/MapEditor/domain/repository/SourceQueryRepositoryInterface'
import store from '@/store'
import { GetTypes, GetTypeProperties, GetSourceByGuid, GetSourceProjects, GetSources, CountSources } from '@/services/MapEditor/infrastructure/store/modules/Source/action-types'
import { GetProjectSources } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class SourceQueryRepository extends AbstractQueryRepository implements SourceQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetSources(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetSourceByGuid(guid));
    }

    getCount(payload: string[][]): Promise<any>
    {
        return store.dispatch(new CountSources(payload));
    }

    getTypes(): Promise<any>
    {
        return store.dispatch(new GetTypes());
    }

    getTypeProperties(val: string): Promise<any>
    {
        return store.dispatch(new GetTypeProperties(val));
    }

    getByJoinTable(projectGuid: string, payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetProjectSources(projectGuid, payload));
    }

    getParentRecords(guid: string): Promise<any>
    {
        return store.dispatch(new GetSourceProjects(guid));
    }
}