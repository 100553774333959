export default class RegionUpdateCommand {
  private readonly code: string;
  private readonly isUpdatable: boolean;
  private readonly name: string;
  private readonly gar_tables: Array<string>

  constructor (code: string, isUpdatable: boolean, name: string, gar_tables: Array<string>) {
    this.code = code
    this.isUpdatable = isUpdatable
    this.name = name
    this.gar_tables = gar_tables
  }

  getGarTables (): Array<string> {
    return this.gar_tables
  }

  getClassName (): string {
    return 'RegionUpdateCommand'
  }

  getCode () {
    return this.code
  }

  getIsUpdatable () {
    return this.isUpdatable
  }

  getName () {
    return this.name
  }
}
