import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StateFieldTypesQuery from '@/services/LogicEditor/application/query/StateFieldTypesQuery'
import StateQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/StateQueryRepositoryInterface'

export default class StateFieldTypesHandler implements QueryHandlerInterface {
  private repository: StateQueryRepositoryInterface

  constructor (repository: StateQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: StateFieldTypesQuery): Promise<any> {
    return this.repository.getStateFieldTypes()
  }
}
