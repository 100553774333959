import { render, staticRenderFns } from "./SchemaViewer.vue?vue&type=template&id=b3249176&scoped=true&"
import script from "./SchemaViewer.vue?vue&type=script&lang=js&"
export * from "./SchemaViewer.vue?vue&type=script&lang=js&"
import style0 from "./SchemaViewer.vue?vue&type=style&index=0&id=b3249176&prod&scoped=true&lang=css&"
import style1 from "./SchemaViewer.vue?vue&type=style&index=1&id=b3249176&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3249176",
  null
  
)

export default component.exports