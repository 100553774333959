<template>
  <div class="panel">
    <el-tooltip content="Свойства" placement="bottom" effect="light" :open-delay="500">
      <popover-button
        :icon="icons.settings"
        :options="fileOptions"
      >
        <div class="options">
          <div
            v-for="(option, index) in fileOptions"
            class="list_item"
            :key="index"
            @click="getEventBus().$emit('closePopover');getEventBus().$emit(option.event, $event)"
          >
            {{ option.name }}
          </div>
        </div>
      </popover-button>
    </el-tooltip>
    <div class="button" style="margin-left: auto" @click="getEventBus().$emit('save')">Сохранить</div>
<!--    <popover-button
      title="Добавить"
      :icon="icons.plus"
    >
      <slot slot-scope="{ isActive }">
        <widget-list
          :isShown="isActive"
        ></widget-list>
      </slot>
    </popover-button>-->
  </div>
</template>

<script>
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons'
import PopoverButton from './PopoverButton.vue'
import WidgetList from './WidgetList'

const FILE_OPTIONS = [
  // { name: 'Сохранить', event: 'save' },
  // { name: 'Настройки', event: 'add' },
  { name: 'Импортировать', event: 'import' },
  { name: 'Экспортировать', event: 'export' }
  // { name: 'Настройка источника', event: 'card-source-settings' },
  // { name: 'Cохранить как шаблон', event: 'openTemplateDialog' }
]

export default {
  name: 'Toolbar',
  inject: ['getEventBus'],
  components: {
    PopoverButton,
    WidgetList
  },
  data () {
    return {
      fileOptions: FILE_OPTIONS,
      icons: {
        settings: faCog,
        plus: faPlus
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../main.scss";

.panel {
  $padding: 30px;
  width: calc(100% - 60px);
  border-bottom: 1px solid #D2D6DA;
  padding: 0 $padding;
  height: 40px;
  display: flex;
  align-items: center;

  & .status-panel {
    flex: 1;
    text-align: center;

    & .sub {
      color: #949FA8;
    }
  }
}

.options {
  & .list_item {
    font-size: 14px;
    padding: 10px 20px;

    &:hover {
      background: $hover_block_color;
      cursor: pointer;
      color: $active_text_color;
    }
  }
}
</style>
