<template>
  <el-upload
    ref="upload"
    class="xsd-upload"
    name="zipfile"
    list-type="text"
    :headers="headers"
    :action="`${this.$config.api}/xsd2jsonconverter/xsdservice/zip_files`"
    :auto-upload="true"
    :multiple="false"
    :file-list="fileList"
    :on-change="onUploadChange"
    :on-success="onUploadSuccess"
    :on-error="onUploadError"
    :on-remove="onUploadRemove"
    :accept="allowTypes.join(', ')"
    :limit="1"
    :drag="true"
  >
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">Перетащите файл или <em>нажмите для выбора</em></div>

    <div class="el-upload__tip" slot="tip">Разрешены только ZIP архивы содержащие XSD схемы</div>
  </el-upload>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'XsdUpload',

  props: {
    value: Object,

    placeholder: {
      type: String,
      default: 'Выберите ZIP файл'
    }
  },

  computed: {
    fileName () {
      if (this.fileList.length > 0) {
        return this.fileList[0].name
      }

      return null
    },

    headers () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  },

  data () {
    return {
      fileList: [],

      allowTypes: ['application/x-zip-compressed', 'application/zip']
    }
  },

  methods: {
    clearFiles () {
      this.$refs.upload.clearFiles()
    },

    onUploadChange (file, fileList) {
      this.fileList = [file]

      if (!this.allowTypes.includes(file.raw.type)) {
        this.fileList = []

        this.$message.error(
          this.$t('etl_editor.xsd_import.zip_file')
        )

        return false
      }

      this.fileList = fileList
    },

    onUploadSuccess (response, file, fileList) {
      console.log('onUploadSuccess', response)

      this.$emit('input', response)

      console.log(this.value)
    },

    // eslint-disable-next-line handle-callback-err
    onUploadError (error, file, fileList) {
      console.log('onUploadError', error)

      this.$message.error('При загрузке ZIP файла возникла ошибка')
    },

    onUploadRemove (file, fileList) {
      console.log('onRemove', file)

      this.$emit('on-remove', file, fileList)
    }
  }
})
</script>

<style lang="scss">
.xsd-upload {
  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .el-icon-upload {
      margin: 0;
    }
  }
}
</style>
