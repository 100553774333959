import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'

export default class RequestParametersQuery extends AbstractEntitiesQuery {
  private guid: string;

  constructor (guid) {
    super()
    this.guid = guid
  }

  getClassName (): string {
    return 'RequestParametersQuery'
  }

  getGuid (): string {
    return this.guid
  }
}
