<template>
  <el-dialog
    :title="$locale.login.two_factor_auth"
    :visible.sync="opened"
    width="30%">
    <template v-if="authData.secret_is_set">
      <div style="text-align: center; margin-bottom: 10px;word-break: break-word;">
        {{ $locale.login.totp_secret_set }}
      </div>
    </template>
    <template v-else>
      <div style="text-align: center; margin-bottom: 10px;word-break: break-word;">
        {{ $locale.login.totp_secret_does_not_set }}
        <div>
          <img :src="authData.qr_code"/>
        </div>
        <span>QR code действителен еще <b>{{timer}}</b></span>
      </div>
    </template>
    <el-input v-model="code" :maxlength="6" @keyup.enter.native="checkCode"></el-input>
    <el-alert style="margin-top: 10px" v-show="error" :title="error" type="error" :closable="false" show-icon></el-alert>
    <div slot="footer" style="text-align: center">
      <el-button v-loading="loading" type="primary" @click="checkCode">{{ $locale.main.button.confirm }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TOTPAuthentication',
  props: {
    authData: {
      type: Object
    }
  },
  watch: {
    opened (value) {
      if (!value) {
        this.$emit('close')
      }
    },
    code (value) {
      this.code = value.trim()
    }
  },
  computed: {
    timer () {
      if (!this.expires) {
        return null
      }
      let time = this.expires / 60
      let minutes = parseInt(time)
      let seconds = Math.round((time - minutes) * 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    }
  },
  data () {
    return {
      error: null,
      loading: false,
      code: null,
      opened: true,
      expires: null,
      intervalTimer: null
    }
  },
  mounted () {
    if (this.authData.expires) {
      this.expires = this.authData.expires
      setInterval(() => {
        if (this.expires > 0) {
          this.expires--
        } else {
          clearInterval(this.intervalTimer)
        }
      }, 1000)
    }
  },
  methods: {
    checkCode () {
      this.error = null
      this.loading = true
      this.$http.post(`${this.$config.api}/defaultauthentication/auth/totp_check`, {
        guid: this.authData.guid,
        code: this.code
      }).then((response) => {
        this.$store.dispatch(`Authorization/login`, response.data.uuid)
          .then(() => {
            this.$router.push('/')
          })
          .catch(() => {
            this.error = 'Ошибка авторизации, пожалуйста, обновите страницу и попробуйте снова'
          })
          .finally(() => {
            this.loading = false
          })
      }).catch((error) => {
        this.loading = false
        if (error.response.status === 404) {
          this.error = 'Неверный код'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
