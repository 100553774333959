import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetTaskByGuid, GetTaskObjects, GetTaskObjectByTaskId } from '@/services/EtlEditor/infrastructure/store/modules/Task/action-types'
import TaskQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskQueryRepositoryInterface'

export default class TaskQueryRepository extends AbstractQueryRepository implements TaskQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetTaskByGuid(guid))
  }

  getTaskObjects (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTaskObjects(payload))
  }

  getTaskObjectByTaskId (taskId: number): Promise<any> {
    return store.dispatch(new GetTaskObjectByTaskId(taskId))
  }
}
