
import Vue, { VNode } from 'vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'

import Constant from '@/core/infrastructure/components/FilterType/Constant/index.vue'
import TargetTableField from '@/core/infrastructure/components/FilterType/TargetTableField.vue'
import Parameter from '@/core/infrastructure/components/FilterType/Parameter.vue'
import UsersTable from '@/core/infrastructure/components/FilterType/UsersTable.vue'
import Sql from '@/core/infrastructure/components/FilterType/Sql.vue'

export default Vue.extend({
  name: 'RuleFieldForm',

  components: {
    TreeSelect,

    Constant,
    TargetTableField,
    Parameter,
    UsersTable,
    Sql
  },

  computed: {
    showFieldValueInput (): boolean {
      return ['current_user', 'current_datetime'].indexOf(this.model.rule_field_type_id) === -1
    },

    fieldType () {
      let fieldType = null

      if (this.model.field_id !== null) {
        let field = this.findFieldById(this.model.field_id, this.fields)

        if (field) {
          fieldType = field.entity_type_id
        }
      }

      return fieldType
    },

    isPropValue () {
      return ['constant', 'parameter', 'sql'].indexOf(this.model.rule_field_type_id) !== -1
    }
  },

  props: {
    value: Object,

    ruleFieldTypes: {
      type: Array,
      required: true
    },

    targetObjectId: {
      type: Number,
      required: true
    },

    fields: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    fieldType () {
      this.loadXrefList()
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        field_id: [
          { required: true, message: 'Please select field', trigger: 'change' }
        ],
        rule_field_type_id: [
          { required: true, message: 'Please select type', trigger: 'change' }
        ],
        users_field_id: [
          { required: true, message: 'Please select users field', trigger: 'change' }
        ],
        target_table_field_id: [
          { required: true, message: 'Please select target table field', trigger: 'change' }
        ],
        'properties.name': [
          { required: true, message: 'Please input parameter name', trigger: 'blur' }
        ],
        'properties.value': [
          { required: true, message: 'Please input parameter value', trigger: 'blur' }
        ],
        'properties.sql': [
          { required: true, message: 'Please input SQL', trigger: 'blur' }
        ]
      },

      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return [
            'boolean_field', 'xref_field', 'xref_multi_field',
            'string_field', 'integer_field', 'float_field',
            'date_field', 'time_field', 'datetime_field', 'text_field'
          ].indexOf(data.entity_type_id) === -1
        }
      },

      mappedValueProp: {
        constant: 'value',
        parameter: 'name',
        sql: 'sql'
      },

      mappedValidProp: {
        constant: 'properties.value',
        sql: 'properties.sql',
        parameter: 'properties.name',
        target_table_field: 'target_table_field_id',
        users_table: 'users_field_id'
      },

      mappedFilterType: {
        sql: 'Sql',
        constant: 'Constant',
        target_table_field: 'TargetTableField',
        parameter: 'Parameter',
        users_table: 'UsersTable'
      },

      xrefList: []
    }
  },

  methods: {
    validate (): Boolean {
      let isValid = false

      this.$refs.ruleFieldForm.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    renderContent (h, { node, data, store }): VNode {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    findFieldById (id, items) {
      let field = null

      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          field = items[i]
          break
        }

        if (items[i].children.length > 0) {
          field = this.findFieldById(id, items[i].children)

          if (field) {
            break
          }
        }
      }

      return field
    },

    changeField (value) {
      this.model.target_table_field_id = null
      this.model.users_field_id = null
      this.model.properties = {
        name: null,
        value: null,
        sql: null,
        is_array: false
      }
    },

    changeFieldType (value) {
      if (value === 'constant') {
        this.loadXrefList()
      }

      this.model.target_table_field_id = null
      this.model.users_field_id = null
      this.model.properties = {
        name: null,
        value: null,
        sql: null,
        is_array: false
      }
    },

    loadXrefList () {
      if (
        ['xref_field', 'xref_multi_field'].indexOf(this.fieldType) !== -1 &&
        this.model.rule_field_type_id === 'constant'
      ) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${this.model.field_id}/data`)
          .then((response) => {
            this.xrefList = response.data
          })
          .catch((error) => console.log(error))
      }
    }
  }
})
