
import Vue from 'vue'
import CssForm from '@/services/CssEditor/infrastructure/components/form/CssForm.vue'
import GroupForm from '@/services/CssEditor/infrastructure/components/form/GroupForm.vue'
import CssClassesTreeQuery from '@/services/CssEditor/application/query/CssClassesTreeQuery'
import CssClassByGuidQuery from '@/services/CssEditor/application/query/CssClassByGuidQuery'
import { CssClassDTO, CssClassTreeDTO, ICssClassTreeDTO } from '@/services/CssEditor/domain/model/CssClass'
import Node from 'element-ui/packages/tree/src/model/node'
import GroupByGuidQuery from '@/services/CssEditor/application/query/GroupByGuidQuery'
import { GroupDTO } from '@/services/CssEditor/domain/model/Group'
import CssClassesSearchQuery from '@/services/CssEditor/application/query/CssClassesSearchQuery'
import GroupDeleteCommand from '@/services/CssEditor/application/command/GroupDeleteCommand'
import CssClassDeleteCommand from '@/services/CssEditor/application/command/CssClassDeleteCommand'
import CssClassTreeByGuidQuery from '@/services/CssEditor/application/query/CssClassTreeByGuidQuery'

export default Vue.extend({
  name: 'CssGrid',

  components: {
    CssForm,
    GroupForm
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],

  watch: {
    searchText () {
      if (!this.isSearchText) {
        this.isSearch = false
        this.searchData = []
      }
    },

    groupCreated (location) {
      if (location !== null) {
        this.addElement('group', location.replace('/groups/', '')) // /groups/a5fad4b7-e6e3-4c44-80a7-fda063e5836a
      }
    },

    cssClassCreated (location) {
      if (location !== null) {
        this.addElement('css_class', location.replace('/css_classes/', ''))
      }
    }
  },

  computed: {
    treeProps () {
      /*
        ref="tree"
        node-key="guid"
        :key="isSearch ? 'searchTree' : 'defaultTree'"
        :lazy="!isSearch"
        :data="searchData"
        :draggable="!isSearch"
        :props="treeProps"
        :load="loadNode"
        :expand-on-click-node="false"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
       */
      return {
        ref: 'tree', // Vue ID компонента
        nodeKey: 'guid', // Основной уникальный ключ узла
        key: this.isSearch ? 'searchTree' : 'defaultTree', // Меняем ключ, что бы перерендерить компонент с новыми свойствами
        lazy: !this.isSearch, // Для поиска отключаем ленивую загрузку
        data: this.isSearch ? this.searchData : [], // Для поиска передаём найденные данные в дерево
        draggable: !this.isSearch, // Блокируем drag & drop если сейчас активен поиск
        props: {
          label: 'name',
          isLeaf: 'is_leaf',
          children: 'children'
        },
        expandOnClickNode: false,
        load: this.loadNode,
        // allowDrop: this.allowDrop,
        // allowDrag: this.allowDrag,
        defaultExpandAll: this.isSearch
      }
    },

    groupCreated () {
      return this.$store.getters['Group/getGroupLocation']
    },

    cssClassCreated () {
      return this.$store.getters['CssClass/getCssClassLocation']
    },

    isSearchText () {
      return !!this.searchText
    },

    isSearchLoading () {
      if (this.isSearch) {
        return this.$store.getters['CssClass/isCssClassLoading']
      }

      return false
    },

    isNew () {
      if (!this.editorModel) {
        return false
      }

      return typeof this.editorModel.guid === 'undefined' || this.editorModel.guid === null
    }
  },

  data () {
    return {
      isSearch: false,
      searchData: [],
      selectedNode: null,
      elementTypes: ['group', 'css_class'],
      allowDuplicateElementTypes: ['css_class'],
      editorModel: null,
      searchText: '',
      forms: {
        group: 'GroupForm',
        css_class: 'CssForm'
      },
      dto: {
        group: GroupDTO,
        css_class: CssClassDTO
      },
      formType: null
    }
  },

  methods: {
    getLabel (node, data): string {
      const iconClass = this.getTreeIcon(node)
      const icon = iconClass
        ? `<span class="node-label__icon ${iconClass}"></span>`
        : ''

      if (this.isSearch) {
        const searchClass = data.is_search
          ? ' is-search'
          : ''

        return `${icon}<span class="node-label__name${searchClass}">${node.label}</span><span class="node-label__info">(id: ${data.id}, type: ${data.type})</span>`
      }

      return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.id}, type: ${data.type})</span>`
    },

    getTreeIcon (node): string|null {
      if (node.data.type === 'group') {
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      }

      return null
    },

    async loadNode (node, resolve): Promise<void> {
      if (node.level === 0) {
        // Logic (root)
        resolve(await this.loadElements())
      } else {
        // TreeElement (root by logicId)
        resolve(await this.loadElements(node.data.guid))
      }
    },

    loadElements (parentGuid: string|null = null): Promise<CssClassTreeDTO[]> {
      if (!parentGuid) {
        return this.getQueryBus().execute(
          new CssClassesTreeQuery({ is_null: 'parent_guid', order: 'type:desc,name:asc' })
        )
      }

      return this.getQueryBus().execute(
        new CssClassesTreeQuery({ parent_guid: parentGuid, order: 'type:desc,name:asc' })
      )
    },

    async handleSelected (node, data): Promise<void> {
      if (this.selectedNode === node) {
        this.handleClose()
        return
      }

      this.selectedNode = node

      if (data.type === 'css_class') {
        this.editorModel = await this.getQueryBus().execute(
          new CssClassByGuidQuery(data.guid)
        )
      } else if (data.type === 'group') {
        this.editorModel = await this.getQueryBus().execute(
          new GroupByGuidQuery(data.guid)
        )
      }

      this.formType = this.forms[data.type] || null
    },

    handleCreate (type: string, node: Node|null = null): void {
      console.log('CssEditor->CssClassTree->handleCreate', type, node)

      const defaultModel = this.dto[type] || null

      if (defaultModel) {
        this.editorModel = defaultModel.create(
          node ? node.data.id : null
        )
      }

      this.formType = this.forms[type] || null
    },

    async addElement (type: string, guid: string): Promise<void> {
      const queries = {
        group: GroupByGuidQuery,
        css_class: CssClassByGuidQuery
      }

      const QueryClass = queries[type] || null

      if (!QueryClass) {
        return
      }

      this.editorModel = await this.getQueryBus().execute(
        new QueryClass(guid)
      )

      const cssClassTreeDto = await this.getQueryBus().execute(
        new CssClassTreeByGuidQuery(guid)
      )

      console.log('addElement::cssClassTreeDto', cssClassTreeDto)

      if (cssClassTreeDto !== null) {
        if (this.selectedNode && this.selectedNode.data.type === 'group') {
          this.$refs.tree.append(cssClassTreeDto, this.selectedNode)
        } else {
          const node = new Node({
            parent: this.$refs.tree.root,
            store: this.$refs.tree.store,
            data: cssClassTreeDto
          })

          node.level = 1

          this.$refs.tree.root.childNodes.push(node)
        }

        this.selectedNode = this.$refs.tree.getNode(cssClassTreeDto)
      }
    },

    handleClose () {
      this.editorModel = null
      this.formType = null
      this.selectedNode = null
    },

    handleSave () {
      if (this.$refs.form && this.editorModel !== null) {
        this.$refs.form.submit(() => {
          if (this.isNew === false) {
            const node = this.$refs.tree.getNode(this.editorModel.guid)
            if (node) {
              node.data.name = this.editorModel.name
              this.selectedNode = node
            }
          }

          this.$http
            .post(`${this.$config.api}/nodeutils/build_themes`, null, {
              hideNotification: true
            })
            .then(() => {
              const themeLink = `${this.$config.api}/files/csseditor/themes/default.min.css`

              localStorage.setItem('theme-link', themeLink)

              const themeTag = document.getElementById('themeCss')
              const customThemeLink = localStorage.getItem('theme-link')

              if (themeTag && customThemeLink) {
                themeTag.setAttribute('href', `${customThemeLink}?v=${Math.random()}`)
              }
            })
            .catch((error) => {
              const data = error?.response?.data || null

              if (data && data.code === 'compiler_error') {
                this.$alert(data.message, this.$t('main.message.error'), {
                  type: 'error',
                  dangerouslyUseHTMLString: true
                })
              }
            })
        })
      }
    },

    async handleDelete (node: Node, data: ICssClassTreeDTO): Promise<void> {
      if (data.type === 'group') {
        await this.getCommandBus().execute(
          new GroupDeleteCommand(data.guid)
        )

        this.$refs.tree.remove(data)
      } else if (data.type === 'css_class') {
        await this.getCommandBus().execute(
          new CssClassDeleteCommand(data.guid)
        )

        this.$refs.tree.remove(data)
      }

      this.formType = null
    },

    async handleSearch (type: string = 'keyup'): Promise<void> {
      if (type === 'input' && this.searchText.length < 3) {
        return
      }

      this.isSearch = true

      const expressions = []
      const elementId = parseInt(this.searchText)

      if (!isNaN(elementId)) {
        // Либо ищем по ID сущности
        expressions.push({
          eq: {
            id: elementId
          }
        })
      } else {
        // Ищем по имени сущности
        expressions.push({
          like: {
            name: `%${this.searchText}%`
          }
        })

        // expressions.push({
        //   like: {
        //     class_name: `%${this.searchText}%`
        //   }
        // })
        //
        // expressions.push({
        //   like: {
        //     tags: `%${this.searchText}%`
        //   }
        // })
      }

      this.searchData = await this.getQueryBus().execute(
        new CssClassesSearchQuery({
          where: {
            or: expressions
          },
          order: 'order_type:asc,name:asc'
        })
      )
    }
  }
})
