
import Vue from 'vue'
import MainToolbar from '@/services/MapEditor/infrastructure/components/MainToolbar/index.vue'
import LayerPanel from '@/services/MapEditor/infrastructure/components/LayerPanel/index.vue'
import ProjectPanel from '@/services/MapEditor/infrastructure/components/ProjectPanel/index.vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import FeatureUpdateHandler from '@/services/MapEditor/application/handler/command/FeatureUpdateHandler'
import LayerCreateHandler from '@/services/MapEditor/application/handler/command/LayerCreateHandler'
import LayerUpdateHandler from '@/services/MapEditor/application/handler/command/LayerUpdateHandler'
import LayerByGuidHandler from '@/services/MapEditor/application/handler/query/LayerByGuidHandler'
import LayersHandler from '@/services/MapEditor/application/handler/query/LayersHandler'
import LayerGroupCreateHandler from '@/services/MapEditor/application/handler/command/LayerGroupCreateHandler'
import LayerGroupUpdateHandler from '@/services/MapEditor/application/handler/command/LayerGroupUpdateHandler'
import LayerTreeElementDeleteHandler from '@/services/MapEditor/application/handler/command/LayerTreeElementDeleteHandler'
import LayerTreeElementLayerPutHandler from '@/services/MapEditor/application/handler/command/LayerTreeElementLayerPutHandler'
import LayerTreeElementsHandler from '@/services/MapEditor/application/handler/query/LayerTreeElementsHandler'
import LayerTreeElementByGuidHandler from '@/services/MapEditor/application/handler/query/LayerTreeElementByGuidHandler'
import LayerTreeAddChildElementHandler from '@/services/MapEditor/application/handler/command/LayerTreeAddChildElementHandler'
import LayerGeoJsonHandler from '@/services/MapEditor/application/handler/query/LayerGeoJsonHandler'
import PointStyleImageCreateHandler from '@/core/application/command/modules/PointStyleImage/PointStyleImageCreateHandler'
import PointStyleImageByGuidHandler from '@/core/application/query/modules/PointStyleImage/PointStyleImageByGuidHandler'
import ProjectTreeElementProjectCreateHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementProjectCreateHandler'
import ProjectTreeElementProjectPutHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementProjectPutHandler'
import ProjectTreeElementGroupCreateHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementGroupCreateHandler'
import ProjectTreeElementDeleteHandler from '@/services/MapEditor/application/handler/command/ProjectTreeElementDeleteHandler'
import ProjectTreeAddChildElementHandler from '@/services/MapEditor/application/handler/command/ProjectTreeAddChildElementHandler'
import ProjectCreateHandler from '@/services/MapEditor/application/handler/command/ProjectCreateHandler'
import ProjectUpdateHandler from '@/services/MapEditor/application/handler/command/ProjectUpdateHandler'
import ProjectDeleteHandler from '@/services/MapEditor/application/handler/command/ProjectDeleteHandler'
import ProjectGroupUpdateHandler from '@/services/MapEditor/application/handler/command/ProjectGroupUpdateHandler'
import ProjectTreeElementByGuidHandler from '@/services/MapEditor/application/handler/query/ProjectTreeElementByGuidHandler'
import ProjectTreeElementsHandler from '@/services/MapEditor/application/handler/query/ProjectTreeElementsHandler'
import ProjectSourcesHandler from '@/services/MapEditor/application/handler/query/ProjectSourcesHandler'
import ProjectLayersHandler from '@/services/MapEditor/application/handler/query/ProjectLayersHandler'
import ProjectStylesHandler from '@/services/MapEditor/application/handler/query/ProjectStylesHandler'
import ProjectCoordinateSystemsHandler from '@/services/MapEditor/application/handler/query/ProjectCoordinateSystemsHandler'
import SourceCreateHandler from '@/services/MapEditor/application/handler/command/SourceCreateHandler'
import SourceUpdateHandler from '@/services/MapEditor/application/handler/command/SourceUpdateHandler'
import SourceDeleteHandler from '@/services/MapEditor/application/handler/command/SourceDeleteHandler'
import ProjectSourceDeleteHandler from '@/services/MapEditor/application/handler/command/ProjectSourceDeleteHandler'
import ProjectLayerDeleteHandler from '@/services/MapEditor/application/handler/command/ProjectLayerDeleteHandler'
import StrokeStyleCreateHandler from '@/core/application/command/modules/StrokeStyle/StrokeStyleCreateHandler'
import StrokeStyleByGuidHandler from '@/core/application/query/modules/StrokeStyle/StrokeStyleByGuidHandler'
import StyleDeleteHandler from '@/services/MapEditor/application/handler/command/StyleDeleteHandler'
import CoordinateSystemCreateHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemCreateHandler'
import CoordinateSystemUpdateHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemUpdateHandler'
import CoordinateSystemDeleteHandler from '@/services/MapEditor/application/handler/command/CoordinateSystemDeleteHandler'
import CoordinateSystemBySridHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemBySridHandler'
import CoordinateSystemsByUserIdHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemsByUserIdHandler'
import CoordinateSystemsHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemsHandler'
import CoordinateSystemsCountHandler from '@/services/MapEditor/application/handler/query/CoordinateSystemsCountHandler'
import ProjectsHandler from '@/services/MapEditor/application/handler/query/ProjectsHandler'
import ProjectsCountHandler from '@/services/MapEditor/application/handler/query/ProjectsCountHandler'
import ProjectByGuidHandler from '@/services/MapEditor/application/handler/query/ProjectByGuidHandler'
import SourcesHandler from '@/services/MapEditor/application/handler/query/SourcesHandler'
import SourcesCountHandler from '@/services/MapEditor/application/handler/query/SourcesCountHandler'
import StrokeStyleCommandRepository from '@/core/infrastructure/domain/repository/modules/StrokeStyleCommandRepository'
import StrokeStyleQueryRepository from '@/core/infrastructure/domain/repository/modules/StrokeStyleQueryRepository'
import StylesHandler from '@/services/MapEditor/application/handler/query/StylesHandler'
import StylesCountHandler from '@/services/MapEditor/application/handler/query/StylesCountHandler'
import StyleByGuidHandler from '@/services/MapEditor/application/handler/query/StyleByGuidHandler'
import SourceByGuidHandler from '@/services/MapEditor/application/handler/query/SourceByGuidHandler'
import SourceProjectsHandler from '@/services/MapEditor/application/handler/query/SourceProjectsHandler'
import SourceTypesHandler from '@/services/MapEditor/application/handler/query/SourceTypesHandler'
import SourceTypePropertiesHandler from '@/services/MapEditor/application/handler/query/SourceTypePropertiesHandler'
import StyleTypesHandler from '@/services/MapEditor/application/handler/query/StyleTypesHandler'
import StyleTypePropertiesHandler from '@/services/MapEditor/application/handler/query/StyleTypePropertiesHandler'
import StyleProjectsHandler from '@/services/MapEditor/application/handler/query/StyleProjectsHandler'
import EntitiesHandler from '@/services/MapEditor/application/handler/query/EntitiesHandler'
import EntityByIdHandler from '@/services/MapEditor/application/handler/query/EntityByIdHandler'
import FeatureCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/FeatureCommandRepository'
import LayerCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/LayerCommandRepository'
import LayerQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/LayerQueryRepository'
import LayerGroupCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/LayerGroupCommandRepository'
import LayerTreeElementCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/LayerTreeElementCommandRepository'
import LayerTreeElementQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/LayerTreeElementQueryRepository'
import PointStyleImageCommandRepository from '@/core/infrastructure/domain/repository/modules/PointStyleImageCommandRepository'
import PointStyleImageQueryRepository from '@/core/infrastructure/domain/repository/modules/PointStyleImageQueryRepository'
import ProjectTreeElementQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectTreeElementQueryRepository'
import ProjectTreeElementCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectTreeElementCommandRepository'
import ProjectQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectQueryRepository'
import ProjectCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectCommandRepository'
import ProjectGroupCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/ProjectGroupCommandRepository'
import SourceQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/SourceQueryRepository'
import SourceCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/SourceCommandRepository'
import StyleQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/StyleQueryRepository'
import StyleCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/StyleCommandRepository'
import EntitуQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/EntitуQueryRepository'
import CoordinateSystemCommandRepository from '@/services/MapEditor/infrastructure/domain/repository/CoordinateSystemCommandRepository'
import CoordinateSystemQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/CoordinateSystemQueryRepository'
import GeoJsonQueryRepository from '@/services/MapEditor/infrastructure/domain/repository/GeoJsonQueryRepository'

export default {
  name: 'MapEditor',
  components: {
    MainToolbar,
    LayerPanel,
    ProjectPanel
  },
  computed: {
    mapId() {
      if (this.mapIdValue == null) {
        this.mapIdValue = this.generateGuid();
      }
      return this.mapIdValue;
    }
  },
  data() {
    return {
      mapIdValue: null,
      mapInstance: null,
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'FeatureUpdateCommand': new FeatureUpdateHandler(
            new FeatureCommandRepository()
          ),
          'LayerCreateCommand': new LayerCreateHandler(
            new LayerCommandRepository()
          ),
          'LayerUpdateCommand': new LayerUpdateHandler(
            new LayerCommandRepository()
          ),
          'LayerGroupCreateCommand': new LayerGroupCreateHandler(
            new LayerGroupCommandRepository()
          ),
          'LayerGroupUpdateCommand': new LayerGroupUpdateHandler(
            new LayerGroupCommandRepository()
          ),
          'LayerTreeElementDeleteCommand': new LayerTreeElementDeleteHandler(
            new LayerTreeElementCommandRepository()
          ),
          'LayerTreeElementLayerPutCommand': new LayerTreeElementLayerPutHandler(
            new LayerTreeElementCommandRepository()
          ),
          'LayerTreeAddChildElementCommand': new LayerTreeAddChildElementHandler(
            new LayerTreeElementCommandRepository()
          ),
          'PointStyleImageCreateCommand': new PointStyleImageCreateHandler(
            new PointStyleImageCommandRepository()
          ),
          'ProjectTreeElementProjectCreateCommand': new ProjectTreeElementProjectCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeElementProjectPutCommand': new ProjectTreeElementProjectPutHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeElementGroupCreateCommand': new ProjectTreeElementGroupCreateHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeElementDeleteCommand': new ProjectTreeElementDeleteHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectTreeAddChildElementCommand': new ProjectTreeAddChildElementHandler(
            new ProjectTreeElementCommandRepository()
          ),
          'ProjectCreateCommand': new ProjectCreateHandler(
            new ProjectCommandRepository()
          ),
          'ProjectUpdateCommand': new ProjectUpdateHandler(
            new ProjectCommandRepository()
          ),
          'ProjectDeleteCommand': new ProjectDeleteHandler(
            new ProjectCommandRepository()
          ),
          'ProjectGroupUpdateCommand': new ProjectGroupUpdateHandler(
            new ProjectGroupCommandRepository()
          ),
          'SourceCreateCommand': new SourceCreateHandler(
            new SourceCommandRepository()
          ),
          'SourceUpdateCommand': new SourceUpdateHandler(
            new SourceCommandRepository()
          ),
          'SourceDeleteCommand': new SourceDeleteHandler(
            new SourceCommandRepository()
          ),
          'ProjectSourceDeleteCommand': new ProjectSourceDeleteHandler(
            new SourceCommandRepository()
          ),
          'ProjectLayerDeleteCommand': new ProjectLayerDeleteHandler(
            new LayerCommandRepository()
          ),
          'StrokeStyleCreateCommand': new StrokeStyleCreateHandler(
            new StrokeStyleCommandRepository()
          ),
          'StyleDeleteCommand': new StyleDeleteHandler(
            new StyleCommandRepository()
          ),
          'CoordinateSystemCreateCommand': new CoordinateSystemCreateHandler(
            new CoordinateSystemCommandRepository()
          ),
          'CoordinateSystemUpdateCommand': new CoordinateSystemUpdateHandler(
            new CoordinateSystemCommandRepository()
          ),
          'CoordinateSystemDeleteCommand': new CoordinateSystemDeleteHandler(
            new CoordinateSystemCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'LayerByGuidQuery': new LayerByGuidHandler(
            new LayerQueryRepository()
          ),
          'LayersQuery': new LayersHandler(
            new LayerQueryRepository()
          ),
          'LayerTreeElementsQuery': new LayerTreeElementsHandler(
            new LayerTreeElementQueryRepository()
          ),
          'LayerTreeElementByGuidQuery': new LayerTreeElementByGuidHandler(
            new LayerTreeElementQueryRepository()
          ),
          'LayerGeoJsonQuery': new LayerGeoJsonHandler(
            new GeoJsonQueryRepository()
          ),
          'PointStyleImageByGuidQuery': new PointStyleImageByGuidHandler(
            new PointStyleImageQueryRepository()
          ),
          'ProjectTreeElementByGuidQuery': new ProjectTreeElementByGuidHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'ProjectTreeElementsQuery': new ProjectTreeElementsHandler(
            new ProjectTreeElementQueryRepository()
          ),
          'ProjectsQuery': new ProjectsHandler(
            new ProjectQueryRepository()
          ),
          'ProjectsCountQuery': new ProjectsCountHandler(
            new ProjectQueryRepository()
          ),
          'ProjectByGuidQuery': new ProjectByGuidHandler(
            new ProjectQueryRepository()
          ),
          'SourcesQuery': new SourcesHandler(
            new SourceQueryRepository()
          ),
          'SourcesCountQuery': new SourcesCountHandler(
            new SourceQueryRepository()
          ),
          'SourceByGuidQuery': new SourceByGuidHandler(
            new SourceQueryRepository()
          ),
          'SourceProjectsQuery': new SourceProjectsHandler(
            new SourceQueryRepository()
          ),
          'SourceTypesQuery': new SourceTypesHandler(
            new SourceQueryRepository()
          ),
          'SourceTypePropertiesQuery': new SourceTypePropertiesHandler(
            new SourceQueryRepository()
          ),
          'StrokeStyleByGuidQuery': new StrokeStyleByGuidHandler(
            new StrokeStyleQueryRepository()
          ),
          'StylesQuery': new StylesHandler(
            new StyleQueryRepository()
          ),
          'StylesCountQuery': new StylesCountHandler(
            new StyleQueryRepository()
          ),
          'StyleByGuidQuery': new StyleByGuidHandler(
            new StyleQueryRepository()
          ),
          'StyleTypesQuery': new StyleTypesHandler(
            new StyleQueryRepository()
          ),
          'StyleTypePropertiesQuery': new StyleTypePropertiesHandler(
            new StyleQueryRepository()
          ),
          'StyleProjectsQuery': new StyleProjectsHandler(
            new StyleQueryRepository()
          ),
          'ProjectSourcesQuery': new ProjectSourcesHandler(
            new SourceQueryRepository()
          ),
          'ProjectLayersQuery': new ProjectLayersHandler(
            new LayerQueryRepository()
          ),
          'ProjectStylesQuery': new ProjectStylesHandler(
            new StyleQueryRepository()
          ),
          'ProjectCoordinateSystemsQuery': new ProjectCoordinateSystemsHandler(
            new CoordinateSystemQueryRepository()
          ),
          'EntitiesQuery': new EntitiesHandler(
            new EntitуQueryRepository()
          ),
          'EntityByIdQuery': new EntityByIdHandler(
            new EntitуQueryRepository()
          ),
          'CoordinateSystemsQuery': new CoordinateSystemsHandler(
            new CoordinateSystemQueryRepository()
          ),
          'CoordinateSystemsCountQuery': new CoordinateSystemsCountHandler(
            new CoordinateSystemQueryRepository()
          ),
          'CoordinateSystemBySridQuery': new CoordinateSystemBySridHandler(
            new CoordinateSystemQueryRepository()
          ),
          'CoordinateSystemsByUserIdQuery': new CoordinateSystemsByUserIdHandler(
            new CoordinateSystemQueryRepository()
          )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus/* ,
      getProjectTabsContainer: this.getProjectTabsContainer */
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    },
    /* getProjectTabsContainer() {
      return this.$refs.projectTabsContainer;
    }, */
    mapInstanceCreated (map, mapId) {
      if (this.mapIdValue == mapId) {
        this.mapInstance = map;
      }
    }
  },
  mounted() {
    this.getEventBus().$on('mapInstanceCreated', this.mapInstanceCreated);
  }
}
