<template>
  <div class="fields_panel">
    <el-col :span="18">
      <el-scrollbar :style="{height:'100%'}" wrap-style="overflow-x:hidden;" ref="scroll">
        <el-tree
        ref="tree"
        :data="fields"
        :props="props"
        node-key="id"
        :filter-node-method="filterNode"
        :expand-on-click-node="false">
            <span class="custom-tree-node" slot-scope="{ node, data }" @click="add(data, $event)">
              <span
                :title="node.label.length > 33 ? node.label +' '+ data.id : ''"
              >
                <font-awesome-icon icon="calculator" style="padding-right: 3px" v-if="getFormulaIcon(node, data)"/>
                <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
                <b>{{
                    node.label.length > 33 ? `${node.label.substring(0, 33)}...` : node.label
                  }}</b>
                <span style="color: #7C838B; padding-left: 6px;"> {{
                    $locale.object_editor.entity_types[data.entity_type_id] || data.entity_type_id
                  }} id {{ data.id }}</span>
              </span>
            </span>
      </el-tree>
      </el-scrollbar>
    </el-col>
    <!-- <el-col :span="6">
      test
    </el-col> -->
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import RegistryFieldsQuery from '@/services/InterfaceEditor/application/query/RegistryFieldsQuery'

library.add(faCalculator)

export default {
  name: 'RegistryFields',
  props: {
    registryId: Number
  },
  components: {
    FontAwesomeIcon
  },
  inject: ['getQueryBus', 'getEventBus'],
  mounted () {
    this.loadRegistryFields()
  },
  data () {
    return {
      fields: [],
      props: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      }
    }
  },
  methods: {
    add (data, event) {
      this.getEventBus().$emit('addComponent', {
        type: 'registry',
        component: data.entity_type_id,
        event: event,
        registryProperties: data.properties,
        name: `attr_${data.id}_`,
        label: data.name
      })
      this.getEventBus().$emit('closePopover')
    },
    filterNode (value, data) {
      if ((value || '').trim().length === 0) {
        return true
      }
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
        data.id.toString().indexOf(value) !== -1
    },
    getTreeIcon (node, data) {
      if (data.entity_type_id === 'registry_group' && !node.expanded) {
        return 'el-icon-folder'
      } else if (data.entity_type_id === 'registry_group' && node.expanded) {
        return 'el-icon-folder-opened'
      }
    },
    getFormulaIcon (node, data) {
      return !!(data.properties && data.properties.find(property => property.id === 'formula_id') && data.properties.find(property => property.id === 'formula_id').value)
    },
    async loadRegistryFields () {
      if (!this.registryId) {
        console.warn('RegistryId is null')
        return false
      }
      const { data } = await this.getQueryBus().execute(new RegistryFieldsQuery(this.registryId))

      this.fields = data
    }
  }
}
</script>

<style scoped lang="scss">
.fields_panel {
  height: 300px;
  & .el-col {
    height: 100%;
    padding-bottom: 10px;
  }
}
.custom-tree-node {
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 90%;
}
::v-deep .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
}

::v-deep .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
</style>
