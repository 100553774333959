import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RelatedObjectDeleteCommand from '@/services/LogicEditor/application/command/RelatedObjectDeleteCommand'
import RelatedObjectCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RelatedObjectCommandRepositoryInterface'

export default class RelatedObjectDeleteHandler implements CommandHandlerInterface {
    private repository: RelatedObjectCommandRepositoryInterface;

    constructor (repository: RelatedObjectCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RelatedObjectDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
