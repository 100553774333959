import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import RouteCommandRepositoryInterface from '@/services/Databus/domain/repository/RouteCommandRepositoryInterface'
import RouteCreateCommand from '@/services/Databus/application/command/RouteCreateCommand'
import { RouteDTO } from '@/services/Databus/domain/model/Route/Route'

export default class RouteCreateHandler implements CommandHandlerInterface
{
    private repo: RouteCommandRepositoryInterface;

    constructor(repo: RouteCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: RouteCreateCommand): Promise<void>
    {
        let item: RouteDTO = {
            topic: command.getTopic(),
            task_id: command.getTaskId(),
            file_name: command.getFileName(),
            priority: command.getPriority(),
            type: command.getType(),
            commands: command.getCommands(),
            properties: command.getProperties(),
            is_bigdata: command.getIsBigData()
        };
        return this.repo.insert(item);
    }
}
