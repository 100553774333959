
import Vue from 'vue'
import { LicenseManager, VirtualList, SetFilter, GetContextMenuItemsParams, MenuItemDef, ProcessRowGroupForExportParams, ProcessCellForExportParams } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import ColumnTypes from './ColumnTypes'
import RuLocale from './locale/ru'
import ResultColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/ResultColumnsBuilder'
import CountNumberRecords from '@/core/infrastructure/components/Grid/infrastructure/components/StatusBar/CountNumberRecords'
import RowClassRulesBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/RowClassRules'
import PercentageFieldHeader from '@/core/infrastructure/components/Grid/infrastructure/components/Headers/PercentageFieldHeader'

import XrefFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/XrefFilter'
import DisabledFitler from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/disabledFitler'
import DateFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/dateFilter'
import DateTimeFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/dateTimeFilter'
import TimeFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/timeFilter'
import BooleanFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/booleanFilter'
import BooleanFloatingFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/booleanFloatingFilter'
import StringSetFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/StringSetFilter'
import AddressFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/AddressFilter'
import GarAddressFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/GarAddressFilter'

import stringField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/stringField'
import booleanField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/booleanField'
import xrefField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/xrefField'
import htmlField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/htmlField'
import dateField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/dateField.js'
import xrefMultiField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/xrefMultiField.js'
import timeField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/timeField.js'
import textField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/textField.js'
import integerField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/integerField.js'
import floatField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/floatField.js'
import datetimeField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/datetimeField.js'
import addressField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/addressField.js'
import garAddressField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/garAddressField.js'
import garAddressMultiField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/garAddressMultiField.js'
import fileField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/fileField.js'
import ExportValueFormatter from '@/core/infrastructure/components/Grid/infrastructure/service/ExportValueFormatter'
import PercentageSparklineField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/percentageSparklineField.js'

import { isNumeric } from '@/helpers/index'

export default Vue.extend({
  name: 'Ag-grid',

  components: {
    AgGridVue,
    PercentageFieldHeader,
    addressFilter: AddressFilter,
    garAddressFilter: GarAddressFilter,
    xrefFilter: XrefFilter,
    booleanFilter: BooleanFilter,
    booleanFloatingFilter: BooleanFloatingFilter,
    dateTimeFilter: DateTimeFilter,
    timeFilter: TimeFilter,
    agDateInput: DateFilter,
    stringSetFilter: StringSetFilter,
    stringField: stringField,
    booleanField: booleanField,
    xrefField: xrefField,
    htmlField: htmlField,
    dateField: dateField,
    xrefMultiField: xrefMultiField,
    timeField: timeField,
    textField: textField,
    integerField: integerField,
    floatField: floatField,
    datetimeField: datetimeField,
    addressField: addressField,
    garAddressField: garAddressField,
    garAddressMultiField: garAddressMultiField,
    fileField: fileField,
    disabledFitler: DisabledFitler,
    statusBarComponent: CountNumberRecords,
    percentageSparklineField: PercentageSparklineField
  },

  inject: {
    getEventBus: {
      default: () => false
    },
    getQueryBus: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    },
    isEditor: {
      default: () => () => false
    }
  },

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    dataSourceService: {
      type: Object,
      default: () => { return {} }
    },
    pagination: {
      type: Boolean
    },
    sideBar: {
      type: Boolean,
      default: false
    },
    closeToolPanel: {
      type: Boolean
    },
    floatingFilter: {
      type: Boolean
    },
    rowDoubleClicked: {
      type: Boolean
    },
    groupUseEntireRow: {
      type: Boolean
    },
    pageSize: {
      type: Number
    },
    cacheBlockSize: {
      type: Number
    },
    rowHeight: {
      type: Number
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    checkBoxSelection: {
      type: Boolean,
      default: false
    },
    headerCheckBoxSelection: {
      type: Boolean,
      default: false
    },
    isPivotMode: {
      type: Boolean
    },
    showCount: {
      type: Boolean,
      default: false
    },
    disabledColumnHeader: {
      type: Boolean
    },
    hideHeader: {
      type: Boolean
    },
    suppressGroupRowsSticky: {
      type: Boolean
    },
    wrapHeader: {
      type: Boolean
    },
    filterModel: {
      // type: Object,
      // default: () => { return {} }
    },
    readonly: {
      type: Boolean
    },
    theme: {
      type: String
    },
    CSS: {
      type: String
    },
    CSSClasses: {
      type: String
    },
    rowClassRulesProps: {
      type: Array
    },
    contextMenu: {
      type: Array,
      default () {
        return [
          'copy',
          'copyWithHeaders',
          'paste',
          'separator',
          'chartRange',
          'export'
        ]
      }
    },
    primaryField: {
      type: String
    },
    isAutoRefresh: {
      type: Boolean,
      default: false
    }
  },

  created () {
    // после обновления до версии 28 предупреждение в консоли
    // Если таблица содерижт одинаковые id (такое бывает О-о) - то записи начинают прыгать
    // https://www.ag-grid.com/vue-data-grid/server-side-model-selection/#providing-row-ids
    // UPD: без getRowId не будет работать SSRM Transactions (удаление, добавление, обновление записей)
    // Расширенная таблица и запрос не имеют уникальные поля - getRowId присвоить null
    console.log('%c%s', 'color: red;', 'grid created', this.dataSourceService)
    let isAnalyticTable = false
    // if (['extended_object', 'query'].includes(this.dataSourceService.type) && this.isAutoRefresh) {
    //   isAnalyticTable = true
    // }
    // if (['extended_object', 'query'].includes(this.dataSourceService.type) && !this.isAutoRefresh) {
    //   this.getRowId = null
    //   return
    // }
    if (['extended_object', 'query'].includes(this.dataSourceService.type)) {
      console.log('аналитическая таблица')
      isAnalyticTable = true
    }

    if (isAnalyticTable) {
      // проверить есть ли уникальное (ключевое поле в таблице). Поле выведено в таблицу
      const isFoundUniqueKey = this.columns.filter(item => item.field === this.primaryField)
      if (!isFoundUniqueKey.length) {
        this.getRowId = null
        console.warn(`Не найдено уникальное поле. Таблица работает с ошибками. getRowId = null`)
        return
      }
    }

    this.getRowId = (params) => {
      let uniqueKey = isAnalyticTable ? params.data[this.primaryField] : params.data.id
      const parentKeysJoined = (params.parentKeys || []).join('$$$')
      if (uniqueKey != null) {
        return `${parentKeysJoined} rowID{{${uniqueKey}}}`
      }
      const rowGroupCols = params.columnApi.getRowGroupColumns()
      const thisGroupCol = rowGroupCols[params.level]
      return parentKeysJoined + params.data[thisGroupCol.getColDef().field]
    }
  },

  beforeMount () {
    this.context = { componentParent: this }
    if (this.rowHeight) {
      this.gridOptions.rowHeight = this.rowHeight
    }
    LicenseManager.setLicenseKey(this.$config.ag_grid_license)
    this.groupDisplayType = this.groupUseEntireRow ? 'groupRows' : null
  },

  data () {
    return {
      selectedRows: null,
      getRowId: null,
      groupDisplayType: null,
      defaultExcelExportParams: {
        processCellCallback: ExportValueFormatter.getProcessCellCallback()
      },
      defaultCsvExportParams: {
        processCellCallback: ExportValueFormatter.getProcessCellCallback()
      },
      gridOptions: {
        rowHeight: null,
        rowModelType: Object.keys(this.dataSourceService).length ? 'serverSide' : 'clientSide',
        pagination: this.pagination,
        paginationPageSize: this.pageSize,
        rowSelection: this.multiSelection ? 'multiple' : 'single',
        columnTypes: ColumnTypes,
        enableRangeSelection: true,
        enableCharts: true,
        pivotMode: this.isPivotMode,
        suppressPropertyNamesCheck: true,
        sideBar: {
          toolPanels: ['columns', 'filters'],
          hiddenByDefault: this.sideBar,
          defaultToolPanel: this.closeToolPanel ? '' : 'columns'
        },
        localeText: RuLocale,
        context: null,
        defaultColDef: {
          sortable: !this.disabledColumnHeader,
          enableValue: true,
          resizable: !this.disabledColumnHeader,
          autoHeight: false,
          wrapHeaderText: this.wrapHeader,
          autoHeaderHeight: this.wrapHeader,
          floatingFilter: this.floatingFilter,
          filterParams: {
            buttons: ['apply', 'reset'],
            filters: [
              {
                floatingFilterComponent: 'filter'
              }
            ]
          },
          menuTabs: this.disabledColumnHeader ? [] : ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
          columnsMenuParams: {
            suppressColumnFilter: this.disabledColumnHeader
            // suppressColumnSelectAll: true,
            // suppressColumnExpandAll: true,
            // suppressSyncLayoutWithGrid: true
          }
        },
        rowClass: 'ag_grid_row',
        getContextMenuItems: (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
          return this.contextMenu as (string | MenuItemDef)[]
        },
        onFilterOpened: function () {}
      },
      excelStyles: [
        {
          id: 'fontFamily',
          font: { color: '#000000', size: 12, fontName: 'Times New Roman' }
        },
        {
          id: 'header',
          font: { color: '#000000', size: 12, fontName: 'Times New Roman' }
        }
      ],
      gridApi: undefined,
      columnApi: undefined,
      rowClassRules: null,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'statusBarComponent',
            key: 'statusBarCompKey'
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              // only show count and sum ('min', 'max', 'avg' won't be shown)
              aggFuncs: ['count', 'sum']
            }
          }
        ]
      }
    }
  },

  computed: {
    resultColumns () {
      console.log(this.columns)
      // console.log('%c%s', 'color: red;', 'grid resultColumns', this.columns)
      const cols = ResultColumnsBuilder.build(this.columns, this)
      if (cols[0]) {
        // Чекбокс в первый столбец таблицы
        // console.log('%c%s', 'color: red;', this.gridOptions.rowModelType)
        if (this.gridOptions.rowModelType === 'clientSide') {
        // headerCheckboxSelection is NOT supported for Server Side Row Model
        // UPD: v29 headerCheckboxSelection is supported for Server Side Row Model
          cols[0].headerCheckboxSelectionFilteredOnly = true
        }
        cols[0].headerCheckboxSelection = this.headerCheckBoxSelection
        cols[0].checkboxSelection = this.checkBoxSelection
      }

      // console.log('%c%s', 'color: red;', 'cols', cols)

      console.log('cols', cols)
      return cols
    },
    themeAgGrid () {
      if (this.theme) {
        return this.theme
      }
      return 'ag-theme-alpine'
    }
  },

  methods: {
    onFilterChanged () {
      // console.log('%c%s', 'color: red;', 'onFilterChanged')
      if (this.getEventBus) {
        this.getEventBus().$emit('selectedRows', [])
      }
      this.gridApi.deselectAll()
    },
    processCellForClipboard (params) {
      const colDef = params.column.getColDef()

      if (colDef?.cellRenderer === 'xrefField' || colDef?.cellRenderer === 'xrefMultiField') {
        let parced = null
        try {
          parced = JSON.parse(params.value)
          return parced.map(item => item.name)
        } catch (error) {
          return params.value
        }
      }

      return params.value
    },

    editRecord (row) {
      if (this.rowDoubleClicked) {
        this.$emit('edit-record', row.data)
      }
    },

    getResultColumns () {
      return this.resultColumns
    },

    load () {
      // console.log('%c%s', 'color: red;', 'grid load')
      if (Object.keys(this.dataSourceService).length && this.isPivotMode) {
        this.gridApi?.setServerSideDatasource(this.dataSourceService)
      }
      this.gridApi?.refreshServerSide({ purge: true })
    },

    getFilterModel () {
      return this.gridApi.getFilterModel()
    },

    delete (row) {
      this.gridApi.deselectAll()
    },

    onSelectionChanged () {
      if (this.getEventBus) {
        // console.warn('onSelectionChanged событие ')
        this.getSelectedRows({ isNotEmit: false })
      }
    },
    // получить местонахождение записи в группировочной таблице.
    getRouteToNode (toggledNodes) {
      const ids = []
      const stack = toggledNodes

      function sliceStr (str) {
        // const index = str.indexOf(']')
        const regex = /rowID{{\d+}}/g
        const matches = str.match(regex) || []
        let ids = matches.reduce((acc, item) => {
          acc.push(item.replace('rowID{{', '').replace('}}', ''))
          return acc
        }, [])

        console.log({ ids })
        return ids
      }

      while (stack.length) {
        const node = stack.pop()
        if (node) {
          if (isNumeric(node)) {
            ids.push((node))
          } else {
            // вырезать айди из строки
            let idByString = sliceStr(node)
            if (idByString.length) {
              ids.push(...idByString)
            }
          }
        }
        if (node.toggledNodes?.length) {
          node.toggledNodes.forEach(item => {
            stack.push(item)
          })
        }
      }

      return ids
    },
    // получить id выделенных строк
    getIdSelectedRows () {
      let selectionState = this.gridApi.getServerSideSelectionState()
      console.log('%c%s', 'color: red;', 'getIdSelectedRows', { selectionState })
      let res = []
      if (selectionState?.toggledNodes) {
        res = this.getRouteToNode(selectionState.toggledNodes)
      }

      return res
    },
    getSelectedRows (object = { isNotEmit: true }) {
      const { isNotEmit } = object
      // console.log({ isNotEmit })
      // есть чекбокс выделить все записи
      if (this.headerCheckBoxSelection) {
        const selectionState = this.gridApi.getServerSideSelectionState()
        console.log('%c%s', 'color: red;', 'getSelectedRows', { selectionState })
        const selected = []
        // ничего не выделено
        if (!selectionState.selectAll && !selectionState.toggledNodes.length) {
          if (isNotEmit) return selected
          this.getEventBus().$emit('selectedRows', selected)
        }

        // выделено все
        if (selectionState?.selectAll) {
          this.gridApi.forEachNode(function (node) {
            selected.push(node.data)
          })
          console.log('%c%s', 'color: red;', 'selectAll', { selected })
          if (isNotEmit) return selected
          this.getEventBus().$emit('selectedRows', selected)
          return
        }
        // Не выделено все
        const findIds = this.getIdSelectedRows()

        if (findIds.length) {
          this.gridApi.forEachNode(function (node) {
            if (findIds.includes(node.data.id + '')) {
              selected.push(node.data)
            }
          })
        }

        if (isNotEmit) return selected
        this.getEventBus().$emit('selectedRows', selected)
        return
      }
      if (isNotEmit) return this.gridApi.getSelectedRows()
      this.getEventBus().$emit('selectedRows', this.gridApi.getSelectedRows())
    },

    getColumnApi () {
      return this.columnApi
    },

    onGridReady (params) {
      // console.log('%c%s', 'color: red;', 'grid onGridReady')
      this.gridApi = params.api
      this.columnApi = params.columnApi
      if (!this.pagination) {
        let statusBarComponent = this.gridApi.getStatusPanel('statusBarCompKey')
        statusBarComponent.setVisible(!statusBarComponent.isVisible())
      }
      this.$emit('grid-ready')
      this.afterRender()
      this.$nextTick(() => {
        this.setVisibleColumns()
      })
      if (this.rowClassRulesProps?.length) {
        this.setColorRows()
      }
    },
    setColorRows () {
      let modelCard = this.getModel()
      this.rowClassRules = RowClassRulesBuilder.build({ rules: this.rowClassRulesProps, modelCard, componentType: 'agGrid' })
    },
    setVisibleColumns () {
      console.log(this.columnApi)
      this.resultColumns.forEach(item => {
        if (item.hide !== undefined) {
          this.columnApi.setColumnVisible(item.field, !item.hide)
        }
      })
    },
    findChildKeys (item, result = []) {
      if (item.hide) {
        if (item.children?.length) {
          item.children.forEach(el => {
            result.push(el.field)
            this.findChildKeys(el, result)
          })
        }
      }
      return result
    },

    afterRender () {
      // console.log('%c%s', 'color: red;', 'grid afterRender PIVOT', this.isPivotMod)
      if (Object.keys(this.dataSourceService).length) {
        this.gridApi.setServerSideDatasource(this.dataSourceService)
      }
      // this.columnApi.setPivotMode(this.isPivotMode)
    }
  }
})
