import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace interfaceTemplateAPI {

  export class SaveTemplate implements APIRequest<[]> {
    response: []
    path: string
    method = HTTPMethod.POST
    parse = (data: AxiosResponse) => data.data
    constructor (public params?: Record<string, any>) {
      this.path = `/reporteditor/reports/`
    }
  }
  export class UpdateTemplate implements APIRequest<string> {
    response: string
    path: string
    method = HTTPMethod.PUT
    // hideNotification = true
    parse = (data: AxiosResponse) => data.data
    constructor (public params: Record<string, any>) {
      this.path = `reporteditor/reports/${params.id}`
    }
  }
  export class GetListInterfaceTemplate implements APIRequest<any> {
    response: any
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor () {
      this.path = '/reporteditor/reports?report_type=interface_template'
    }
  }
  export class GetTemplateContent implements APIRequest<any> {
    response: any
    path: string
    method = HTTPMethod.GET
    parse = (data: AxiosResponse) => data.data
    constructor (id: number) {
      this.path = `/reporteditor/reports/${id}`
    }
  }
}
