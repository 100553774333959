import axios from 'axios'
import { cloneObject, getUserFullName } from '@/helpers'

export class MessageApi {
  constructor () {
    this.baseUrl = `${config.api}/${config.notification_publisher}/messages`
  }

  getAuthors () {
    return axios
      .get(`${this.baseUrl}/authors`)
      .then((response) => response.data.map((author) => {
        author.fullname = getUserFullName(author)

        return author
      }))
      .catch(() => [])
  }

  getMessages (payload) {
    return axios
      .post(this.baseUrl, payload, {
        hideNotification: true
      })
      .then((response) => response.data.map((msg) => {
        // Есть случаи когда отсутствует автор
        if (!msg.author) {
          msg.author = {
            id: 0,
            name: 'Система',
            midname: '',
            surname: '',
            avatar: null
          }
        }

        msg.author.fullname = getUserFullName(msg.author)

        return msg
      }))
      .catch((e) => {
        console.log('[ERROR] MessageApi.getMessages', e)

        return []
      })
  }

  getNumberUnreadMessages (payload) {
    if (!payload) {
      payload = {
        '*': { func: 'count' },
        where: {
          and: [
            { is_null: 'read_date' }
          ]
        }
      }
    }

    payload = cloneObject(payload)

    if (!payload['*']) {
      payload['*'] = { func: 'count' }
    }

    if (!payload.where) {
      payload.where = {
        and: [
          { is_null: 'read_date' }
        ]
      }
    }

    return axios
      .post(this.baseUrl, payload, {
        hideNotification: true
      })
      .then((response) => response.data[0]?.count || 0)
      .catch(() => 0)
  }

  getNumberMessages (payload) {
    if (!payload) {
      payload = {
        '*': { func: 'count' }
      }
    }

    payload = cloneObject(payload)

    if (!payload['*']) {
      payload['*'] = { func: 'count' }
    }

    return axios
      .post(this.baseUrl, payload, {
        hideNotification: true
      })
      .then((response) => response.data[0]?.count || 0)
      .catch(() => 0)
  }

  readMessage (message) {
    return axios
      .put(`${this.baseUrl}/read`, {
        message_id: message.id
      }, {
        hideNotification: true
      })
  }

  readMessages () {
    return axios.put(`${this.baseUrl}/read-all`, null, {
      hideNotification: true
    })
  }
}
