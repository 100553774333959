import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import NotificationUpdateCommand from '@/services/NotificationEditor/application/command/NotificationUpdateCommand'
import NotificationCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/NotificationCommandRepositoryInterface'

export default class NotificationUpdateHandler implements CommandHandlerInterface {
  private repository: NotificationCommandRepositoryInterface;

  constructor (repository: NotificationCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: NotificationUpdateCommand): Promise<void> {
    return this.repository.update(command.getDto())
  }
}
