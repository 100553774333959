
import Vue from 'vue'
import TreeElementLayout from '@/services/LogicEditor/infrastructure/components/TreeElementLayout.vue'
import EditorElementLayout from '@/services/LogicEditor/infrastructure/components/EditorElementLayout.vue'
import MainMixin from '@/services/LogicEditor/infrastructure/mixins/MainMixin'
import LogicTreeElementByElementGuidQuery from '@/services/LogicEditor/application/query/LogicTreeElementByElementGuidQuery'
import LogicByGuidQuery from '@/services/LogicEditor/application/query/LogicByGuidQuery'

export default Vue.extend({
  name: 'LogicEditor',

  mixins: [MainMixin],

  components: {
    TreeElementLayout,
    EditorElementLayout
  },

  props: {
    externalEntityGuid: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      editor: {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    }
  },

  methods: {
    closeEditor () {
      this.editor = {
        title: '',
        model: {},
        form: null,
        node: null,
        elementType: null
      }
    },

    createElement (elementType, parentNode) {
      this.editor.form = this.form[elementType]
      this.editor.title = this.$t('logic_editor_v2.element_types.' + elementType)
      this.editor.node = parentNode
      this.editor.elementType = elementType
      this.editor.model = this.defaultModel[elementType].create()

      if (['view_constraint', 'disable_constraint'].includes(elementType)) {
        this.editor.model.constraint_type_id = elementType
      }

      if (elementType !== 'logic') {
        if (parentNode.data.element_type) {
          this.editor.model.logic_id = parentNode.data.logic_id
          this.editor.model.parent_tree_element_id = parentNode.data.id
        } else {
          this.editor.model.logic_id = parentNode.data.id
        }
      }
    },

    async updateElement (node, data) {
      const elementType = data.element_type ? data.element_type : 'logic'
      const elementGuid = elementType === 'logic' ? data.guid : data.element_guid

      this.editor.model = await this.queryBus.execute(new this.queryByGuid[elementType](elementGuid))
      this.editor.form = this.form[elementType]
      this.editor.title = `${data.name} (id: ${data.element_id || data.id})`
      this.editor.node = node
      this.editor.elementType = elementType
    },

    async deleteElement (node, data) {
      const elementType = data.element_type ? data.element_type : 'logic'
      const elementGuid = elementType === 'logic' ? data.guid : data.element_guid

      await this.getCommandBus().execute(
        new this.deleteCommands[elementType](elementGuid)
      )
    },

    async duplicateElement (node, data) {
      const allowDuplicateElementTypes = [
        'formula', 'row_formula', 'state',
        'listener', 'command', 'constraint',
        'view_constraint', 'disable_constraint', 'approval',
        'related_object', 'procedure'
      ]
      const elementType = data.element_type ? data.element_type : 'logic'

      if (!allowDuplicateElementTypes.includes(elementType)) {
        return
      }

      this.$refs.treeElement.closeEditor()

      const elementGuid = elementType === 'logic' ? data.guid : data.element_guid
      const parentNode = node.parent

      this.editor.model = await this.queryBus.execute(new this.queryByGuid[elementType](elementGuid))
      this.editor.model.id = null
      this.editor.model.guid = null
      this.editor.model.name += ' (copy)'

      if (this.editor.model.alias) {
        this.editor.model.alias += Math.random()
      }

      // Форматируем подчинённые данные
      if (elementType === 'state') {
        this.editor.model.state_fields = this.editor.model.state_fields.map(item => {
          delete item.id
          delete item.guid

          return item
        })
      } else if (elementType === 'approval') {
        this.editor.model.approval_stages = this.editor.model.approval_stages.map(item => {
          delete item.id
          delete item.guid

          return item
        })
      }

      this.editor.form = this.form[elementType]
      this.editor.title = `${data.name} (${this.$t('main.labels.duplication')})`
      this.editor.node = parentNode
      this.editor.elementType = elementType

      if (elementType !== 'logic') {
        if (parentNode.data.element_type) {
          this.editor.model.logic_id = parentNode.data.logic_id
          this.editor.model.parent_tree_element_id = parentNode.data.id
        } else {
          this.editor.model.logic_id = parentNode.data.id
        }
      }

      this.$refs.treeElement.updateNode(parentNode)
    },

    async onAddElement (guid, isLogic = false) {
      const query = !isLogic
        ? new LogicTreeElementByElementGuidQuery(guid)
        : new LogicByGuidQuery(guid)

      const response = await this.getQueryBus().execute(query)

      if (typeof response.code !== 'undefined' && ['invalid_guid', 'entity_not_found'].includes(response.code)) {
        this.$msgbox({
          title: this.$t('main.message.error'),
          message: this.$t('logic_editor_v2.messages.saving_error')
        })

        return
      }

      const parentNode = !isLogic
        ? this.editor.node
        : null

      this.$refs.treeElement.addNode(response, parentNode)

      const node = await this.$refs.treeElement.getNodeByElementGuid(response.element_guid || response.guid)
      if (node) {
        this.editor.title = `${node.data.name} (id: ${node.data.element_id || node.data.id})`
        this.editor.node = node

        this.$refs.treeElement.updateNode(node)
      }
    },

    async onSubmit (isNew, elementType, value) {
      if (!isNew) {
        if (elementType !== 'logic') {
          const node = await this.$refs.treeElement.getNodeByElementGuid(value.guid)
          if (node) {
            node.data.name = value.name

            this.editor.title = `${node.data.name} (id: ${node.data.element_id || node.data.id})`
            this.editor.node = node

            this.$refs.treeElement.updateNode(node)
          }
        }

        this.editor.model = await this.queryBus.execute(new this.queryByGuid[elementType](value.guid))
      }
    }
  }
})
