import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'
import LogicCreateCommand from '@/services/LogicEditor/application/command/LogicCreateCommand'
import LogicCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicCommandRepositoryInterface'

export default class LogicCreateHandler implements CommandHandlerInterface {
    private repository: LogicCommandRepositoryInterface;

    constructor (repository: LogicCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: LogicCreateCommand): Promise<void> {
      let item: LogicDTO = {
        alias: command.getAlias(),
        description: command.getDescription(),
        entity_id: command.getEntityId()
      }

      return this.repository.insert(item)
    }
}
