<template>
  <div class="block-settings-panel">
    <div class="tabs">
      <div
        class="tab-item"
        :class="{ 'is-active': collapseModel === 'main' }"
        @click="collapseModel = 'main'"
      >
        {{ $t('interface_editor.block_settings.main') }}
      </div>

      <div
        class="tab-item"
        :class="{ 'is-active': collapseModel === 'interactive' }"
        @click="collapseModel = 'interactive'"
      >
        {{ $t('interface_editor.block_settings.interactive') }}
      </div>

      <div
        class="tab-item"
        :class="{ 'is-active': collapseModel === 'tabs' }"
        @click="collapseModel = 'tabs'"
      >
        {{ $t('interface_editor.block_settings.tabs') }}
      </div>

      <div
        class="tab-item"
        :class="{ 'is-active': collapseModel === 'replication' }"
        @click="collapseModel = 'replication'"
      >
        {{ $t('interface_editor.block_settings.replication') }}
      </div>

      <div
        class="tab-item"
        :class="{ 'is-active': collapseModel === 'behaviour' }"
        @click="collapseModel = 'behaviour'"
      >
        {{ $t('interface_editor.block_settings.behaviour') }}
      </div>
    </div>

    <div class="tab-panels custom_scrollbar">
      <div
        class="tab-panel-item"
        :class="{ 'is-active': collapseModel === 'main' }"
      >
        <div class="block">
          <div class="setting-label">{{ $t('interface_editor.block_settings.position') }} / <string-select v-model="block.sticky" :options="stickyTypes"></string-select></div>
          <div class="sizes">
            <div v-for="(item, index) in sizes" :key="index">
              <span class="name">{{ item.name }}</span>
              <span class="data">
                <click-input
                  v-model="block[item.name]"
                  class="editor"
                ></click-input>
                <span class="type" @click="changeSizeType(item.name)">{{block.sizeTypes[item.name]}}</span>
              </span>
              <template v-if="item.calculated">
                <span class="name">Calc</span>
                <span class="data">
                  <click-input
                    @input="changeCalc(item.name, $event)"
                    :value='calcValues[item.name]'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
              </template>
              <template v-if="item.minMax && block.sizeTypes[item.name] === '%'">
                <span class="name">Min</span>
                <span class="data">
                  <click-input
                    @input="changeMin(item.name, $event)"
                    :value='(block.minMax || {})[`min${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`] || 0'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
                <span class="name">Max</span>
                <span class="data">
                  <click-input
                    @input="changeMax(item.name, $event)"
                    :value='(block.minMax || {})[`max${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`] || 0'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.center')}}</div>
          <div @click="changeOnCenter('vertical')">
            <div class="setting-label-mini">{{$t('interface_editor.block_settings.vertical')}}</div>
            <i
              v-if="(block.onCenter || {}).vertical"
              class="el-icon-check" style="margin-left: 5px; color: #32B84D;"></i>
            <i
              v-else
              class="el-icon-close" style="margin-left: 5px;color: #949FA8;"></i>
          </div>
          <div @click="changeOnCenter('horizontal')">
            <el-tooltip placement="left" effect="dark" :content="'Воспользуйтесь новым выравниванием снизу (адаптивно)'">
              <div class="setting-label-mini">{{$t('interface_editor.block_settings.horizontal')}}</div>
            </el-tooltip>
            <i
              v-if="(block.onCenter || {}).horizontal"
              class="el-icon-check" style="margin-left: 5px; color: #32B84D;"></i>
            <i
              v-else
              class="el-icon-close" style="margin-left: 5px;color: #949FA8;"></i>
          </div>
          <div @click="changeOnCenter('horizontalAdaptive')" style="margin-top: 10px">
            <el-tooltip placement="left" effect="dark" :content="'В тестовом режимe'">
              <div class="setting-label-mini">{{$t('interface_editor.block_settings.horizontal_adaptive')}}</div>
            </el-tooltip>
            <i
              v-if="(block.onCenter || {}).horizontalAdaptive"
              class="el-icon-check" style="margin-left: 5px; color: #32B84D;"></i>
            <i
              v-else
              class="el-icon-close" style="margin-left: 5px;color: #949FA8;"></i>
          </div>
        </div>
        <div class="block">
          <div class="setting-label" style="display: inline-block">{{$t('interface_editor.block_settings.stretchable')}}</div>
          <el-checkbox v-model="block.isStretched" style="margin-left: 10px;"></el-checkbox>
          <div class="setting-label" style="display: inline-block">{{$t('interface_editor.block_settings.scroll_hover')}}</div>
          <el-checkbox v-model="block.isScrollHover" style="margin-left: 10px;"></el-checkbox>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.style_css')}}</div>
          <codemirror ref="styleEditor" v-model="block.style" class="block_style_code" :options="options" style="height: 150px;"></codemirror>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.class_css')}}</div>
          <el-input
            v-model="block.className"
            size="mini"
          ></el-input>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.nickname')}}</div>
          <el-input
            v-model="block.alias"
            size="mini"
          ></el-input>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.choose_cont')}}</div>
          <el-select v-model="selectedParent" value-key="guid" filterable clearable placeholder="Выбрать" size="mini">
            <el-option
              v-for="item in allParentsBlocks"
              :key="item.guid"
              :label="item.alias"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.sticky_to')}}</div>
          <el-select
            :value="block.stickyTo ? block.stickyTo.guid : null"
            @input="changeSticky($event)"
            value-key="guid" filterable clearable placeholder="Выбрать" size="mini">
            <el-option
              v-for="item in neighbors"
              :key="item.guid"
              :label="item.alias"
              :value="item.guid">
              <span
                style="display: block"
                @mouseenter="highlightContainer(item.guid, true)"
                @mouseleave="highlightContainer(item.guid, false)"
              >{{ item.alias }}</span>
            </el-option>
          </el-select>
        </div>
      </div>

      <div
        class="tab-panel-item"
        :class="{ 'is-active': collapseModel === 'interactive' }"
      >
        <div class="block">
          <interactive-settings
            :settings="block.interactive"
            :source-id="block.replication ? block.replication.additionalData.sourceId: null"
            :source-type="block.replication ? block.replication.additionalData.sourceType : ''"
            :is-headers="block.replication ? block.replication.additionalData.isAttrsSelectedFromSource : null"
            ref="interactive_settings"
            @change="$set(block, 'interactive', $event)"
          ></interactive-settings>
        </div>
      </div>

      <div
        class="tab-panel-item"
        :class="{ 'is-active': collapseModel === 'tabs' }"
      >
        <div class="block">
          <tabs-settings
            ref="tab_settings"
            :settings="block.tabs"
            :tab-settings-service="tabSettingsService"
            @change="$set(block, 'tabs', $event)"
            @remove-tab="$emit('remove-tab', $event)"
          ></tabs-settings>
        </div>
      </div>

      <div
        class="tab-panel-item"
        :class="{ 'is-active': collapseModel === 'replication' }"
      >
        <div class="block">
          <replication-settings
            :settings="block.replication"
            ref="replication_settings"
            @change="$set(block, 'replication', $event)"
          ></replication-settings>
        </div>
      </div>

      <div
        class="tab-panel-item"
        :class="{ 'is-active': collapseModel === 'behaviour' }"
      >
        <div class="block">
          <editor-conditions
            :options="{ label: 'hide_conditions' }"
            :value="blockSettings.hiddenConditions[block.guid]"
            :value-watcher="false"
            @change="onChangeBlockHiddenConditions"
          ></editor-conditions>
        </div>
      </div>
    </div>

<!--    <el-collapse accordion v-model="collapseModel" :key="block.guid">
      <el-collapse-item :title="$t('interface_editor.block_settings.main')" name="main">
        <div class="block">
          <div class="setting-label">{{ $t('interface_editor.block_settings.position') }} / <string-select v-model="block.sticky" :options="stickyTypes"></string-select></div>
          <div class="sizes">
            <div v-for="(item, index) in sizes" :key="index">
              <span class="name">{{ item.name }}</span>
              <span class="data">
                <click-input
                  v-model="block[item.name]"
                  class="editor"
                ></click-input>
                <span class="type" @click="changeSizeType(item.name)">{{block.sizeTypes[item.name]}}</span>
              </span>
              <template v-if="item.calculated">
                <span class="name">Calc</span>
                <span class="data">
                  <click-input
                    @input="changeCalc(item.name, $event)"
                    :value='calcValues[item.name]'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
              </template>
              <template v-if="item.minMax && block.sizeTypes[item.name] === '%'">
                <span class="name">Min</span>
                <span class="data">
                  <click-input
                    @input="changeMin(item.name, $event)"
                    :value='(block.minMax || {})[`min${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`] || 0'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
                <span class="name">Max</span>
                <span class="data">
                  <click-input
                    @input="changeMax(item.name, $event)"
                    :value='(block.minMax || {})[`max${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`] || 0'
                    class="editor"
                  ></click-input>
                  <span class="type">px</span>
                </span>
              </template>
            </div>
          </div>
            </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.center')}}</div>
          <div @click="changeOnCenter('vertical')">
            <div class="setting-label-mini">{{$t('interface_editor.block_settings.vertical')}}</div>
            <i
              v-if="(block.onCenter || {}).vertical"
              class="el-icon-check" style="margin-left: 5px; color: #32B84D;"></i>
            <i
              v-else
              class="el-icon-close" style="margin-left: 5px;color: #949FA8;"></i>
          </div>
          <div @click="changeOnCenter('horizontal')">
            <div class="setting-label-mini">{{$t('interface_editor.block_settings.horizontal')}}</div>
            <i
              v-if="(block.onCenter || {}).horizontal"
              class="el-icon-check" style="margin-left: 5px; color: #32B84D;"></i>
            <i
              v-else
              class="el-icon-close" style="margin-left: 5px;color: #949FA8;"></i>
          </div>
        </div>
        <div class="block">
          <div class="setting-label" style="display: inline-block">{{$t('interface_editor.block_settings.stretchable')}}</div>
          <el-checkbox v-model="block.isStretched" style="margin-left: 10px;"></el-checkbox>
          <div class="setting-label" style="display: inline-block">{{$t('interface_editor.block_settings.scroll_hover')}}</div>
          <el-checkbox v-model="block.isScrollHover" style="margin-left: 10px;"></el-checkbox>
        </div>
        <div class="block">
              <div class="setting-label">{{$t('interface_editor.block_settings.style_css')}}</div>
              <codemirror ref="styleEditor" v-model="block.style" class="block_style_code" :options="options" style="height: 150px;"></codemirror>
            </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.class_css')}}</div>
          <el-input
            v-model="block.className"
            size="mini"
          ></el-input>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.nickname')}}</div>
          <el-input
            v-model="block.alias"
            size="mini"
          ></el-input>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.choose_cont')}}</div>
          <el-select v-model="selectedParent" value-key="guid" filterable clearable placeholder="Выбрать" size="mini">
            <el-option
              v-for="item in allParentsBlocks"
              :key="item.guid"
              :label="item.alias"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="block">
          <div class="setting-label">{{$t('interface_editor.block_settings.sticky_to')}}</div>
          <el-select
            :value="block.stickyTo ? block.stickyTo.guid : null"
            @input="changeSticky($event)"
            value-key="guid" filterable clearable placeholder="Выбрать" size="mini">
            <el-option
              v-for="item in neighbors"
              :key="item.guid"
              :label="item.alias"
              :value="item.guid">
              <span
                style="display: block"
                @mouseenter="highlightContainer(item.guid, true)"
                @mouseleave="highlightContainer(item.guid, false)"
              >{{ item.alias }}</span>
            </el-option>
          </el-select>
        </div>
      </el-collapse-item>

      <el-collapse-item name="interactive">
        <template slot="title">
          {{$t('interface_editor.block_settings.interactive')}}
        </template>
        <div class="block">
          <interactive-settings
            :settings="block.interactive"
            :source-id="block.replication ? block.replication.additionalData.sourceId: null"
            :source-type="block.replication ? block.replication.additionalData.sourceType : ''"
            :is-headers="block.replication ? block.replication.additionalData.isAttrsSelectedFromSource : null"
            ref="interactive_settings"
            @change="$set(block, 'interactive', $event)"
          ></interactive-settings>
        </div>
      </el-collapse-item>

      <el-collapse-item name="tabs">
        <template slot="title">
          {{$t('interface_editor.block_settings.tabs')}}<i v-show="hasTabs" class="header-icon el-icon-check"></i>
        </template>
        <div class="block">
          <tabs-settings
            ref="tab_settings"
            :settings="block.tabs"
            :tab-settings-service="tabSettingsService"
            @change="$set(block, 'tabs', $event)"
            @remove-tab="$emit('remove-tab', $event)"
          ></tabs-settings>
        </div>
      </el-collapse-item>

      <el-collapse-item name="replication">
        <template slot="title">
          {{$t('interface_editor.block_settings.replication')}}<i v-show="hasReplicationSettings" class="header-icon el-icon-check"></i>
        </template>
        <div class="block">
          <replication-settings
            :settings="block.replication"
            ref="replication_settings"
            @change="$set(block, 'replication', $event)"
          ></replication-settings>
        </div>
      </el-collapse-item>
      <el-collapse-item name="behavior">
        <template slot="title">
          {{$t('interface_editor.block_settings.behaviour')}}
        </template>
        <div class="block">
          <editor-conditions
            :options="{label: 'hide_conditions'} "
            :value="blockSettings.hiddenConditions[block.guid]"
            :value-watcher="false"
            @change="onChangeBlockHiddenConditions"
          ></editor-conditions>
        </div>
      </el-collapse-item>
  </el-collapse>-->
  </div>
</template>

<script>
import ClickInput from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/ClickInput.vue'
import StringSelect from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/StringSelect.vue'
import InteractiveSettings from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/InteractiveSettings.vue'
import TabsSettings from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/TabsSettings.vue'
import ReplicationSettings from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/ReplicationSettings.vue'
// import EditorConditions from '@/components/InterfaceEditor/components/editor/editor-conditions.vue'
import EditorConditions from '@/components/InterfaceEditor/components/editor/Condition/EditorExpression.vue'

export default {
  name: 'BlockSettings',
  components: { EditorConditions, ClickInput, StringSelect, InteractiveSettings, TabsSettings, ReplicationSettings },
  props: {
    block: {
      type: Object
    },
    mainParentBlock: {
      type: Object
    },
    tabSettingsService: {
      type: Object
    },
    blockSettings: Object
  },
  inject: {
    setActive: {
      default: () => () => {}
    },
    getCurrentViewer: {
      default: () => {}
    },
    stickyTo: {
      default: () => {}
    }
  },
  data () {
    return {
      neighbors: [],
      collapseModel: 'main',
      calc: {
        width: {
          type: null, value: null
        },
        height: {
          type: null, value: null
        }
      },
      options: {
        tabSize: 2,
        mode: 'text/css',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true
      },
      allParentsBlocks: [],
      selectedParent: null
    }
  },
  watch: {
    selectedParent: {
      handler (guid) {
        this.setActive(guid)
      }
    }
  },
  methods: {
    highlightContainer (guid, value) {
      const block = this.getCurrentViewer().getStore().getByGuid(guid)
      if (block) {
        this.$set(block, 'isHighlight', value)
      }
    },
    changeSticky (guid) {
      this.stickyTo(this.block.guid, guid)
    },
    onChangeBlockHiddenConditions (value) {
      if ((!value || value.type === 'never') && this.blockSettings?.hiddenConditions && this.blockSettings.hiddenConditions[this.block.guid]) {
        delete this.blockSettings.hiddenConditions[this.block.guid]
      } else {
        this.$set(this.blockSettings.hiddenConditions, this.block.guid, value)
      }
    },
    getAllParentBlocks (parentBlock) {
      if (parentBlock?.alias) {
        this.allParentsBlocks.push(parentBlock)
      }
      if (parentBlock.children?.length) {
        parentBlock.children.forEach(item => {
          if (item.children instanceof Array && item.children.length) this.getAllParentBlocks(item)
        })
      }
    },
    changeOnCenter (type) {
      if (Object.keys(this.block.onCenter || {}).length === 0) {
        this.$set(this.block, 'onCenter', {
          horizontal: false,
          vertical: false,
          horizontalAdaptive: false
        })
      }
      this.$set(this.block.onCenter, type, !this.block.onCenter[type])
    },
    changeSizeType (type) {
      this.block.sizeTypes[type] = this.block.sizeTypes[type] === 'px' ? '%' : 'px'
    },
    changeMin (type, value) {
      this.setMinMax()
      this.$set(this.block.minMax, `min${type.charAt(0).toUpperCase() + type.slice(1)}`, value)
    },
    setMinMax () {
      if (Object.keys(this.block.minMax || {}).length === 0) {
        this.$set(this.block, 'minMax', {
          minWidth: undefined,
          maxWidth: undefined,
          minHeight: undefined,
          maxHeight: undefined
        })
      }
    },
    changeMax (type, value) {
      this.setMinMax()
      this.$set(this.block.minMax, `max${type.charAt(0).toUpperCase() + type.slice(1)}`, value)
    },
    changeCalc (type, value) {
      if (Object.keys(this.block[`${type}Calc`] || {}).length === 0) {
        this.$set(this.block, `${type}Calc`, { value: undefined, type: undefined })
      }
      if (value === 0) {
        this.block[`${type}Calc`].value = undefined
        this.block[`${type}Calc`].type = undefined
      } else if (value > 0) {
        this.block[`${type}Calc`].value = Math.abs(value)
        this.block[`${type}Calc`].type = '+'
      } else {
        this.block[`${type}Calc`].value = Math.abs(value)
        this.block[`${type}Calc`].type = '-'
      }
    }
  },
  mounted () {
    if (this.block.parentGuid) {
      const parent = this.getCurrentViewer().getStore().getByGuid(this.block.parentGuid)
      this.neighbors = parent.children
        .filter((item) => item.guid !== this.block.guid)
        .map((item) => {
          return {
            guid: item.guid,
            alias: item.alias || item.guid
          }
        })
    } else {
      this.neighbors = this.getCurrentViewer().getStore().blocks
        .filter((item) => item.guid !== this.block.guid)
        .map((item) => {
          return {
            guid: item.guid,
            alias: item.alias || item.guid
          }
        })
    }
    this.allParentsBlocks = []
    if (this.mainParentBlock) {
      this.getAllParentBlocks(this.mainParentBlock)
    }
    this.$nextTick(() => {
      this.$refs.styleEditor.codemirror.refresh()
    })
  },
  computed: {
    hasReplicationSettings () {
      return this.block.replication?.additionalData?.sourceType && this.block.replication?.additionalData?.sourceId
    },
    stickyTypes () {
      return [
        { id: 'tl', name: this.$t('interface_editor.block_settings.top_left') },
        { id: 'tr', name: this.$t('interface_editor.block_settings.top_right') },
        { id: 'bl', name: this.$t('interface_editor.block_settings.bottom_left') },
        { id: 'br', name: this.$t('interface_editor.block_settings.bottom_right') }
      ]
    },
    hasTabs () {
      return this.block.tabs?.list?.length > 0
    },
    calcValues () {
      let answer = {
        width: 0,
        height: 0
      }
      if (this.block.widthCalc && this.block.widthCalc.type && this.block.widthCalc.value) {
        answer.width = parseInt(`${this.block.widthCalc.type}${this.block.widthCalc.value}`)
      }
      if (this.block.heightCalc && this.block.heightCalc.type && this.block.heightCalc.value) {
        answer.height = parseInt(`${this.block.heightCalc.type}${this.block.heightCalc.value}`)
      }

      return answer
    },
    sizes () {
      let sizeTypes = []
      switch (this.block.sticky) {
        case 'tl':
          sizeTypes.push({ name: 'top', calculated: false }, { name: 'left', calculated: false })
          break
        case 'tr':
          sizeTypes.push({ name: 'top', calculated: false }, { name: 'right', calculated: false })
          break
        case 'bl':
          sizeTypes.push({ name: 'bottom', calculated: false }, { name: 'left', calculated: false })
          break
        case 'br':
          sizeTypes.push({ name: 'bottom', calculated: false }, { name: 'right', calculated: false })
          break
      }
      return [
        ...sizeTypes,
        { name: 'width', calculated: true, minMax: true },
        { name: 'height', calculated: true, minMax: true }
      ]
    }
  }
}
</script>

<style lang="scss">
.block-settings-panel {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}
</style>

<style scoped lang="scss">
.block {
  padding: 10px 10px 10px 0;
}
.header-icon {
  margin-left: 5px;
  color: #32B84D;
}
::v-deep .el-collapse-item__header {
  font-size: 15px;
  font-weight: bold;
  color: #606266;
  background: #F9FAFA;
  padding-left: 10px;
}
::v-deep .el-collapse-item__content {
  padding-left: 5px;
}
</style>
<style>
.block_style_code .CodeMirror {
  height: 150px !important;
}
.block_style_code.px15 .CodeMirror {
  height: 24px !important;
}
</style>

<style src="../../../main.scss" scoped lang="scss"></style>
