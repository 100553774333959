import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'
import RowFormulaCreateCommand from '@/services/LogicEditor/application/command/RowFormulaCreateCommand'
import RowFormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RowFormulaCommandRepositoryInterface'

export default class RowFormulaCreateHandler implements CommandHandlerInterface {
    private repository: RowFormulaCommandRepositoryInterface;

    constructor (repository: RowFormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RowFormulaCreateCommand): Promise<void> {
      let item: RowFormulaDTO = {
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        object_id: command.getObjectId(),
        alias_field_id: command.getAliasFieldId(),
        target_alias_value: command.getTargetAliasValue(),
        value: command.getValue(),
        ast: command.getAst(),
        prepared_statement: command.getPreparedStatement(),
        has_target_rows: command.getHasTargetRows(),
        logic_id: command.getLogicId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
