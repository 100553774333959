<template>
  <div>
    <el-dialog
        title="Настройка слушателей"
        :visible.sync="showListenersSettings"
        :before-close="beforeClose"
        width="50%">
      <div class="listener-setting-header">
          <h3>{{ $locale.interface_editor.listeners_warning }}</h3>
        <el-form>
          <el-form-item>
            <div>
              <el-tooltip class="item" effect="dark" content="Добавить" placement="top" :open-delay="500">
                <el-button
                    type="success"
                    icon="el-icon-plus"
                    class="el-button--square listener__add"
                    @click="addListener">
                </el-button>
              </el-tooltip>
            </div>
            <div
              v-for="(listener, index) in localValue"
              v-bind:key="index"
              class="listener-setting">
              <div class="listener-setting-name">
                <el-input
                    v-model.trim="listener.name">
                </el-input>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="deleteListener(index)"
                style="margin-left: 10px">
                </el-button>
              </div>
              <editor-button-action
                  :value='listener.action'
                  :source-id="sourceId"
                  :source-type="sourceType"
                  :is-headers="true"
                  :component-type="1"
                  @change="$set(listener, 'action', $event)">
              </editor-button-action>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/ButtonAction/EditorButtonAction.vue'

export default {
  name: 'listenersSettings',
  components: { EditorButtonAction },
  data () {
    return {
      sourceType: 'registry',
      localValue: this.value || []
    }
  },
  props: {
    showListenersSettings: {
      type: Boolean
    },
    sourceId: {
      type: Number
    },
    value: {
      type: Array
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    beforeClose () {
      this.$emit('update:showListenersSettings', false)
    },
    addListener () {
      this.localValue.push({ name: '',
        action: {
          type: 'execute_command',
          command: {
            id: null,
            success_text: null,
            failure_text: null,
            is_card_close: null,
            close_type: null,
            is_save_card: null,
            is_reopen_card: null,
            componentsGuid: [],
            is_save_card_before_command: false
          },
          filters: []
        } })
    },
    deleteListener (index) {
      this.localValue.splice(index, 1)
    }
  }
}

</script>

<style>
.listener-setting-header {
  position: relative;
}

.listener-setting {
  margin-top: 20px;
  padding: 5px;
}

.listener__add {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -37px;
}
.listener-setting-name {
  display: flex;
}
</style>
