import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StyleQueryRepositoryInterface from '../../../domain/repository/StyleQueryRepositoryInterface'
import StyleProjectsQuery from '../../query/StyleProjectsQuery'

export default class StyleProjectsHandler implements QueryHandlerInterface
{
    private repo: StyleQueryRepositoryInterface;

    constructor(repo: StyleQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: StyleProjectsQuery): Promise<any>
    {
        return this.repo.getParentRecords(query.getGuid());
    }
}