import { jsonParse } from '@/helpers'
import { isEqual, lt, lte, gt, gte, isNull, includes } from 'lodash'

export default {
  methods: {
    checkConditions (data, sourceRegistry = false, fields = {}) {
      if (!data || Object.keys(data).length === 0 || data.type === 'never') {
        return false
      } else if (data.type === 'always') {
        return true
      }
      let isXref = false

      // Новый формат
      if (data.isComplex) {
        console.group('Новый формат условий')

        if (!this.isValidExpr({ type: 'group', expression: data.expressions })) {
          return false
        }

        const result = this.checkConditionByExpressionGroup(
          data.expressions,
          sourceRegistry
            ? fields
            : this.getModel()
        )

        console.groupEnd()

        return result
      }

      // Поддержка старого формата
      let result = false
      data.conditions.forEach((condition) => {
        if (!condition.attribute || !condition.type) {
          return false
        }
        let conditionResult = false
        let attribute
        if (sourceRegistry) {
          attribute = fields[`attr_${condition.attribute}_`]
        } else {
          attribute = this.getModel()[condition.attribute]
        }
        try {
          attribute = JSON.parse(attribute)
        } catch (e) {

        }
        if (attribute instanceof Array) {
          isXref = true
          attribute = attribute.map(item => item.id || item).join('|||')
        }
        let value = condition.value
        if (value === 'user_id') {
          value = this.$store.getters['Authorization/userId']
        }
        if (value === 'role_id') {
          value = this.$store.getters['Authorization/roleId']
        }
        switch (condition.type) {
          case 'contains_in_array':
            let attrArray = (attribute || '').toString().split('|||')
            conditionResult = attrArray.includes(value.toString())
            break

          case 'not_contains_in_array':
            let _attrArray = (attribute || '').toString().split('|||')
            conditionResult = !_attrArray.includes(value.toString())
            break
          case 'contains':
            const _attr = (attribute || '').toString().split('|||')
            _attr.forEach((item) => {
              if (item.indexOf(value) !== -1) {
                conditionResult = true
              }
            })
            break
          case 'not_contains':
            let attr = attribute + ''
            let valueArray = value + ''
            if (attr?.length && valueArray?.length) {
              // /\s*[\s,]\s*/ - разбивает строку на подстроки, используя  пробельные символы или запятые в качестве разделителей.
              let arrAttr = attr.replace(/\|\|\|/gi, ' ').split(/\s*[\s,]\s*/)
              let arrvalue = valueArray.split(',')
              arrvalue = arrvalue.map(item => item.trim())
              // предполагается, что это одна строка
              if (arrAttr.length === 1 && !isXref) {
                conditionResult = !attr.includes(arrvalue)
                if (arrvalue.length > 1) {
                  conditionResult = !arrvalue.includes(attr)
                }
                break
              }
              conditionResult = !arrAttr.some(el => arrvalue.includes(el))
            }
            break
          case 'equals':
            if (attribute == value) {
              conditionResult = true
            }
            break
          case 'not_equals':
            if (attribute != value) {
              conditionResult = true
            }
            break
          case 'is_null':
            if (
              attribute === null ||
              typeof attribute === 'undefined' ||
              attribute === '' ||
              attribute === false
            ) {
              conditionResult = true
            }
            break
          case 'is_not_null':
            if (
              attribute !== null &&
              typeof attribute !== 'undefined' &&
              attribute !== '' &&
              attribute !== false
            ) {
              conditionResult = true
            }
            break
          default:
            break
        }
        condition.result = conditionResult
      })

      if (data.condition_type === 'and') {
        if (data.conditions.length === data.conditions.filter(item => item.result).length) {
          result = true
        }
      } else if (data.condition_type === 'or') {
        if (data.conditions.find(item => item.result)) {
          result = true
        }
      }

      return result
    },

    /**
     * Вернёт результат условия по объекту группы с выражениями
     *
     * @param exprGroup
     * @param dataModel
     * @return {boolean}
     */
    checkConditionByExpressionGroup (exprGroup, dataModel) {
      const { logicalOperator = 'and', children = [] } = exprGroup
      const result = []

      for (const expr of children) {
        const exprResult = this.expressionResult(expr, dataModel)

        if (logicalOperator === 'and' && !exprResult) {
          // Бросаем всё выходим! Смысла проверять остальные условия нет!
          return false
        }

        result.push(exprResult)
      }

      if (logicalOperator === 'and') {
        return !result.includes(false)
      }

      return result.includes(true)
    },

    /**
     * Возвращаяет результат выражения (условия) и групп выражений (условий)
     *
     * @param expression
     * @param dataModel
     * @return {boolean|*|boolean}
     */
    expressionResult (expression, dataModel) {
      if (!this.isValidExpr(expression)) {
        return false
      }

      if (expression.type === 'group') {
        return this.checkConditionByExpressionGroup(expression.expression, dataModel)
      } else {
        const { operator } = expression.expression

        const xValue = this.getExpressionValue(expression, 'x', 'xType', dataModel)
        const yValue = this.getExpressionValue(expression, 'y', 'yType', dataModel)

        console.log(xValue, typeof xValue, operator, typeof yValue, yValue)

        switch (operator) {
          case 'in':
          case 'not_in':
            if (xValue === null || yValue == null) {
              return false
            }

            let result = false

            if (Array.isArray(xValue) && Array.isArray(yValue)) {
              result = includes(xValue, ...yValue)
            } else if (Array.isArray(xValue) && !Array.isArray(yValue)) {
              result = includes(xValue, yValue)
            } else if (!Array.isArray(xValue) && Array.isArray(yValue)) {
              result = includes(yValue, xValue)
            } else {
              return false
            }

            return operator === 'in'
              ? result
              : !result
          case 'eq':
            return isEqual(xValue, yValue)
          case 'neq':
            return !isEqual(xValue, yValue)
          case 'lt':
            return lt(xValue, yValue)
          case 'lte':
            return lte(xValue, yValue)
          case 'gt':
            return gt(xValue, yValue)
          case 'gte':
            return gte(xValue, yValue)
          case 'is_null':
            return isNull(xValue)
          case 'is_not_null':
            return !isNull(xValue)
          default:
            return false
        }
      }
    },

    /**
     * Проверяет объект выражения на целостность
     *
     * @param expr
     * @return {boolean}
     */
    isValidExpr (expr) {
      if (!expr.type || !expr.expression) {
        return false
      }

      if (expr.type === 'group') {
        const { children = [] } = expr.expression

        return children.length > 0
      } else if (expr.type === 'expression') {
        return true
      }

      return false
    },

    /**
     * Вычисляет значения X или Y в выражении для проверки
     *
     * @param expr
     * @param propValue
     * @param propType
     * @param dataModel
     * @return {string|string[]}
     */
    getExpressionValue (expr, propValue, propType, dataModel) {
      const expression = expr.expression
      const valueType = expression[propType] || 'attribute'

      let value = (expression[propValue] || '')
      if (typeof value === 'string') {
        value = value.trim()
      }

      console.log(value, dataModel)

      let result = null
      if (valueType === 'attribute' || valueType === 'component') {
        if ((/^[0-9]+/).test(value)) {
          result = jsonParse(dataModel[`attr_${value}_`])
        } else if ((/^attr_[0-9]+_/).test(value)) {
          result = jsonParse(dataModel[value])
        } else {
          result = typeof dataModel[value] !== 'undefined'
            ? jsonParse(dataModel[value])
            : null
        }
      } else if (valueType === 'currentUser') {
        result = this.$store.getters['Authorization/userId']
      } else if (valueType === 'currentRole') {
        result = this.$store.getters['Authorization/roleId']
      } else if (valueType === 'currentDate') {
        result = new Date().toJSON().slice(0, 10)
      } else {
        result = jsonParse(value)
      }

      // if (typeof result === 'string') {
      //   result = result.trim()
      // } else if (typeof result === 'undefined') {
      if (typeof result === 'undefined') {
        result = null
      } else if (result instanceof Array) {
        result = result.map(item => item.id || item)
      }

      switch (expression.operator) {
        case 'in':
        case 'not_in':
          if (result === null) {
            result = []
          } else if (!Array.isArray(result)) {
            result = [result]
          } else if (typeof result === 'string') {
            result = result.split('|||').map(v => v.trim())
          }

          return result
        case 'equals':
        case 'not_equals':
        case 'gt':
        case 'gte':
        case 'lt':
        case 'lte':
          if (result === null) {
            result = ''
          } else if (Array.isArray(result)) {
            if (!result.length) {
              return null
            }

            if (result[0]) {
              result = result[0]
            } else {
              result = JSON.stringify(result)
            }
          } else if (typeof result === 'object') {
            return JSON.stringify(result)
          }

          return result
        case 'is_null':
        case 'is_not_null':
          if (
            typeof result === 'undefined' ||
            result === null ||
            result === '' ||
            result === false ||
            (Array.isArray(result) && result.length === 0) ||
            (!Array.isArray(result) && typeof result === 'object' && Object.keys(result).length === 0)
          ) {
            return null
          }

          return result
        default:
          return result
      }
    }
  }
}
