<template>
  <el-form class="update-query-form" :class="{ 'sql-fullscreen': showSql }" ref="form" :rules="rules" label-position="top" label-width="100px" :model="model" size="mini">
    <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
      <el-input v-model="model.name"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
      <el-input v-model="model.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.description')" prop="description">
      <el-input v-model="model.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.sql_statement')" prop="sql_statement">
      <el-button
        type="primary"
        icon="el-icon-full-screen"
        style="position:absolute;top: 15px;right: 30px;z-index: 100;"
        @click="showSql = true"
      ></el-button>

      <codemirror v-model="model.sql_statement" :options="options"></codemirror>
    </el-form-item>

    <el-form-item>
      <el-checkbox :label="$t('bi_editor.form.label.is_materialized')" v-model="model.is_materialized"></el-checkbox>
    </el-form-item>

    <el-form-item>
      <el-checkbox :label="$t('bi_editor.form.label.has_view')" v-model="model.has_view"></el-checkbox>
    </el-form-item>

    <el-dialog
      :title="$t('bi_editor.form.label.sql_statement')"
      :visible.sync="showSql"
      :close-on-click-modal="false"
      show-close
      center
      width="70%"
      fullscreen
    >
      <codemirror v-model="model.sql_statement" :options="options"></codemirror>
    </el-dialog>
  </el-form>
</template>

<script>
export default {
  name: 'UpdateQuery',

  props: {
    value: Object,

    rules: Object
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value,

      showSql: false,

      options: {
        tabSize: 4,
        mode: 'text/x-pgsql',
        theme: 'base16-light',
        lineNumbers: true,
        line: true,
        collapseIdentical: true,
        highlightDifferences: false
      }
    }
  },

  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
}
</script>

<style>
.update-query-form .vue-codemirror .CodeMirror {
  width: 100%;
  height: 600px;
}

.update-query-form.sql-fullscreen .vue-codemirror .CodeMirror {
  height: 88vh !important;
}
</style>
