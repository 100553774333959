
import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RoleDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  alias?: Nullable<string>
  is_readable_default?: Nullable<boolean>
  is_addable_default?: Nullable<boolean>
  is_editable_default?: Nullable<boolean>
  is_deletable_default?: Nullable<boolean>
  is_menu_visible_default?: Nullable<boolean>
  is_profile_available?: Nullable<boolean>
  is_associated_with_db_role?: Nullable<boolean>
  root_menu_id?: Nullable<number>
  dashboards?: Array<number> = []
  available_roles?: Array<number> = []
  is_registration_available?: Nullable<boolean>
  is_hide_top_menu?: Nullable<boolean>

  constructor (
    {
      id,
      guid,
      name,
      alias,
      is_readable_default,
      is_addable_default,
      is_editable_default,
      is_deletable_default,
      is_menu_visible_default,
      is_profile_available,
      is_associated_with_db_role,
      root_menu_id,
      dashboards,
      available_roles,
      is_registration_available,
      is_hide_top_menu
    }:
        {
            id?: Nullable<number>,
            guid?: Nullable<string>,
            name?: Nullable<string>,
            alias?: Nullable<string>,
            is_readable_default?: Nullable<boolean>,
            is_addable_default?: Nullable<boolean>,
            is_editable_default?: Nullable<boolean>,
            is_deletable_default?: Nullable<boolean>,
            is_menu_visible_default?: Nullable<boolean>,
            is_profile_available?: Nullable<boolean>,
            is_associated_with_db_role?: Nullable<boolean>,
            root_menu_id?: Nullable<number>,
            dashboards?: Array<number>,
            available_roles?: Array<number>,
            is_registration_available?: Nullable<boolean>,
            is_hide_top_menu?: Nullable<boolean>
        }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.alias = alias
    this.is_readable_default = is_readable_default
    this.is_addable_default = is_addable_default
    this.is_editable_default = is_editable_default
    this.is_deletable_default = is_deletable_default
    this.is_menu_visible_default = is_menu_visible_default
    this.is_profile_available = is_profile_available
    this.is_associated_with_db_role = is_associated_with_db_role
    this.root_menu_id = root_menu_id
    this.dashboards = dashboards
    this.available_roles = available_roles
    this.is_registration_available = is_registration_available
    this.is_hide_top_menu = is_hide_top_menu
  }
}

export default class Role extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private alias: string
  private isReadableDefault: Nullable<boolean>
  private isAddableDefault: Nullable<boolean>
  private isEditableDefault: Nullable<boolean>
  private isDeletableDefault: Nullable<boolean>
  private isMenuVisibleDefault: Nullable<boolean>
  private isProfileAvailable?: Nullable<boolean>
  private isAssociatedWithDbRole?: Nullable<boolean>
  private rootMenuId: Nullable<number>
  private dashboards: Array<number> = []
  private availableRoles: Array<number> = []
  private profile: Array<any>
  private isRegistrationAvailable?: Nullable<boolean>
  private isHideTopMenu?: Nullable<boolean>


  constructor (
    id: Nullable<number>,
    guid: Nullable<string>,
    name: string,
    alias: string,
    isReadableDefault: Nullable<boolean> = false,
    isAddableDefault: Nullable<boolean> = false,
    isEditableDefault: Nullable<boolean> = false,
    isDeletableDefault: Nullable<boolean> = false,
    isMenuVisibleDefault: Nullable<boolean> = false,
    isProfileAvailable: Nullable<boolean> = false,
    isAssociatedWithDbRole: Nullable<boolean> = false,
    rootMenuId: Nullable<number>,
    dashboards: Array<number>,
    availableRoles: Array<number>,
    isRegistrationAvailable: Nullable<boolean> = false,
    isHideTopMenu: Nullable<boolean> = false
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.alias = alias
    this.isReadableDefault = isReadableDefault
    this.isAddableDefault = isAddableDefault
    this.isEditableDefault = isEditableDefault
    this.isDeletableDefault = isDeletableDefault
    this.isMenuVisibleDefault = isMenuVisibleDefault
    this.isProfileAvailable = isProfileAvailable
    this.isAssociatedWithDbRole = isAssociatedWithDbRole
    this.rootMenuId = rootMenuId
    this.dashboards = dashboards
    this.availableRoles = availableRoles
    this.isRegistrationAvailable = isRegistrationAvailable
    this.isHideTopMenu = isHideTopMenu
  }

  static create (dto: RoleDTO): Role {
    return new Role(
      dto.id,
      dto.guid,
      dto.name,
      dto.alias,
      dto.is_readable_default,
      dto.is_addable_default,
      dto.is_editable_default,
      dto.is_deletable_default,
      dto.is_menu_visible_default,
      dto.is_profile_available,
      dto.is_associated_with_db_role,
      dto.root_menu_id,
      dto.dashboards,
      dto.available_roles,
      dto.is_registration_available,
      dto.is_hide_top_menu
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setAlias (alias: string): void {
    this.alias = alias
  }

  getAlias (): string {
    return this.alias
  }

  setIsReadable (isReadableDefault: boolean): void {
    this.isReadableDefault = isReadableDefault
  }

  getIsReadable (): boolean {
    return this.isReadableDefault
  }

  setIsAddable (isAddableDefault: boolean): void {
    this.isAddableDefault = isAddableDefault
  }

  getIsAddable (): boolean {
    return this.isAddableDefault
  }

  setIsEditable (isEditableDefault: boolean): void {
    this.isEditableDefault = isEditableDefault
  }

  getIsEditable (): boolean {
    return this.isEditableDefault
  }

  setIsDeletable (isDeletableDefault: boolean): void {
    this.isDeletableDefault = isDeletableDefault
  }

  getIsDeletable (): boolean {
    return this.isDeletableDefault
  }

  setIsMenuVisible (isMenuVisibleDefault: boolean): void {
    this.isMenuVisibleDefault = isMenuVisibleDefault
  }

  getIsMenuVisible (): boolean {
    return this.isMenuVisibleDefault
  }

  setIsProfileAvailable (isProfileAvailable: boolean): void {
    this.isProfileAvailable = isProfileAvailable
  }

  getIsProfileAvailable (): boolean {
    return this.isProfileAvailable
  }

  setIsAssociatedWithDbRole (isAssociatedWithDbRole: boolean): void {
    this.isAssociatedWithDbRole = isAssociatedWithDbRole
  }

  getIsAssociatedWithDbRole (): boolean {
    return this.isAssociatedWithDbRole
  }

  setRootMenuId (rootMenuId: number): void {
    this.rootMenuId = rootMenuId
  }

  getRootMenuId (): number {
    return this.rootMenuId
  }

  setDashboards (dashboards: Array<number>): void {
    this.dashboards = dashboards
  }

  getDashboards (): Array<number> {
    return this.dashboards
  }

  setAvailableRoles (availableRoles: Array<number>): void {
    this.availableRoles = availableRoles
  }

  getAvailableRoles (): Array<number> {
    return this.availableRoles
  }

  setProfileRights (profile: Array<any>): void {
    this.profile = profile
  }

  setIsRegistrationAvailable (isRegistrationAvailable: boolean): void {
    this.isRegistrationAvailable = isRegistrationAvailable
  }

  getIsRegistrationAvailable (): boolean {
    return this.isRegistrationAvailable
  }

  setIsHideTopMenu (isHideTopMenu: boolean): void {
    this.isHideTopMenu = isHideTopMenu
  }

  getIsHideTopMenu (): boolean {
    return this.isHideTopMenu
  }
}
