import { Nullable } from '@/core/domain/type/types'

export default class RoleCreateCommand {
    private name: string;
    private alias: string;
    private isReadableDefault: boolean;
    private isAddableDefault: boolean;
    private isEditableDefault: boolean;
    private isDeletableDefault: boolean;
    private isMenuVisibleDefault: boolean;
    private isProfileAvailable: boolean;
    private isAssociatedWithDbRole: boolean;
    private rootMenuId: Nullable<number>;
    private dashboards: Array<number>;
    private availableRoles: Array<number>;
    private isRegistrationAvailable: boolean;
    private isHideTopMenu: boolean;

    constructor (
      name: string,
      alias: string,
      isReadableDefault: boolean,
      isAddableDefault: boolean,
      isEditableDefault: boolean,
      isDeletableDefault: boolean,
      isMenuVisibleDefault: boolean,
      isProfileAvailable: boolean,
      isAssociatedWithDbRole: boolean,
      rootMenuId: Nullable<number>,
      dashboards: Array<number>,
      availableRoles: Array<number>,
      isRegistrationAvailable: boolean,
      isHideTopMenu: boolean
    ) {
      this.name = name
      this.alias = alias
      this.isReadableDefault = isReadableDefault
      this.isAddableDefault = isAddableDefault
      this.isEditableDefault = isEditableDefault
      this.isDeletableDefault = isDeletableDefault
      this.isMenuVisibleDefault = isMenuVisibleDefault
      this.isProfileAvailable = isProfileAvailable
      this.isAssociatedWithDbRole = isAssociatedWithDbRole
      this.rootMenuId = rootMenuId
      this.dashboards = dashboards
      this.availableRoles = availableRoles
      this.isRegistrationAvailable = isRegistrationAvailable
      this.isHideTopMenu = isHideTopMenu
    }

    getClassName (): string {
      return 'RoleCreateCommand'
    }

    getName (): string {
      return this.name
    }

    getAlias (): string {
      return this.alias
    }

    getIsReadable (): boolean {
      return this.isReadableDefault
    }

    getIsAddable (): boolean {
      return this.isAddableDefault
    }

    getIsEditable (): boolean {
      return this.isEditableDefault
    }

    getIsDeletable (): boolean {
      return this.isDeletableDefault
    }

    getIsMenuVisible (): boolean {
      return this.isMenuVisibleDefault
    }

    getIsProfileAvailable (): boolean {
      return this.isProfileAvailable
    }

    getIsAssociatedWithDbRole (): boolean {
      return this.isAssociatedWithDbRole
    }

    getRootMenuId (): Nullable<number> {
      return this.rootMenuId
    }

    getDashboards (): Array<number> {
      return this.dashboards
    }

    getAvailableRoles (): Array<number> {
      return this.availableRoles
    }

    getIsRegistrationAvailable (): boolean {
      return this.isRegistrationAvailable
    }

    getIsHideTopMenu (): boolean {
      return this.isHideTopMenu
    }
}
