<template>
  <div>
    <el-alert
        v-show="roleDto == null"
        :title="$locale.access_editor.right_object.role_not_found"
        type="error"
        show-icon>
    </el-alert>
    <el-table
        class="custom_scrollbar"
        height="calc(100vh - 228px)"
        ref="registryRightsTable"
        v-loading="loading"
        :data="rightTree"
        style="width: 100%;margin-bottom: 20px;"
        row-key="entity_id"
        lazy
        :load="loadChildRights"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        border>
      <el-table-column prop="name" :label="$locale.access_editor.right_object.object_list" header-align="center"></el-table-column>
      <el-table-column prop="is_readable" :label="$locale.access_editor.roles_table.read" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.is_readable !== null"
              size="mini"
              v-model="scope.row.is_readable"
              @click="saveRegistryRights(scope)"
              :type="scope.row.is_readable ? 'success' : 'danger'"
          >{{scope.row.is_readable ? $locale.access_editor.yes : $locale.access_editor.no}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_addable" :label="$locale.access_editor.roles_table.add" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.is_addable !== null"
              size="mini"
              v-model="scope.row.is_addable"
              @click="saveRegistryRights(scope)"
              :type="scope.row.is_addable ? 'success' : 'danger'"
          >{{scope.row.is_addable ? $locale.access_editor.yes : $locale.access_editor.no}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_editable" :label="$locale.access_editor.roles_table.edit" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.is_editable !== null"
              size="mini"
              v-model="scope.row.is_editable"
              @click="saveRegistryRights(scope)"
              :type="scope.row.is_editable ? 'success' : 'danger'"
          >{{scope.row.is_editable ? $locale.access_editor.yes : $locale.access_editor.no}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="is_deletable" :label="$locale.access_editor.roles_table.delete" width="150" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button
              v-if="scope.row.is_deletable !== null"
              size="mini"
              v-model="scope.row.is_deletable"
              @click="saveRegistryRights(scope)"
              :type="scope.row.is_deletable ? 'success' : 'danger'"
          >{{scope.row.is_deletable ? $locale.access_editor.yes : $locale.access_editor.no}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

import RegistryRight, { RegistryRightDTO } from '@/services/AccessEditor/domain/model/Right/RegistryRight'
import Role, { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import RegistryRightsQuery from '@/services/AccessEditor/application/query/RegistryRightsQuery'
import RegistryRightsUpdateCommand from '@/services/AccessEditor/application/command/RegistryRightsUpdateCommand'

export default {
  name: 'RightObject',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      loading: false,
      roleDto: null,
      rightTree: [],
      registryRightsDTO: null
    }
  },
  created: function () {
    this.getEventBus().$on('editRoleEvent', (data) => {
      this.roleDto = data
      if (data == null) {
        this.roleDto = new RoleDTO({})
      }
    })
  },
  watch: {
    roleDto: function () {
      if (this.roleDto != null) {
        this.loading = true
        this.loadRootRights(null)
      } else {
        this.$message({
          message: this.$locale.access_editor.right_object.role_not_found,
          type: 'warning'
        })
      }
    }
  },
  methods: {
    async loadRootRights () {
      await this.getQueryBus().execute(
        new RegistryRightsQuery({
          guid: this.roleDto.guid
        })).then(data => {
        this.rightTree = this.fixHasChild(data.reverse())
        this.loading = false
      })
    },
    async loadChildRights (row, treeNode, resolve) {
      await this.getQueryBus().execute(
        new RegistryRightsQuery({
          guid: this.roleDto.guid,
          parent_id: row.entity_id
        })
      ).then(data => {
        let result = []
        let temp = data.reverse()
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].is_field == false) {
            result.push(temp[i])
          }
        }
        result = this.fixHasChild(result)
        if (result == null || typeof result === 'undefined' || result.length < 1) {
          row.hasChildren = false
        } else {
          resolve(result)
        }
        this.loading = false
      })
    },
    fixHasChild (items) {
      if (items !== null && typeof items !== 'undefined') {
        for (let i = 0; i < items.length; i++) {
          if (items[i].entity_type_id == 'registry_group' || items[i].entity_type_id == 'field_group') {
            items[i].hasChildren = true
          }
        }
      }
      return items
    },
    saveRegistryRights (item) {
      let key = item.column.property
      item.row[key] = !item.row[key]
      this.getCommandBus().execute(
        new RegistryRightsUpdateCommand(
          item.row.role_guid,
          item.row.entity_id,
          item.row.is_addable,
          item.row.is_readable,
          item.row.is_editable,
          item.row.is_deletable
        )
      ).then(data => {
        this.$refs.registryRightsTable.store.$data.states.treeData[item.row.entity_id].loaded = false
        this.$refs.registryRightsTable.store.$data.states.treeData[item.row.entity_id].expanded = false
        this.$refs.registryRightsTable.store.loadOrToggle(item.row)
        this.updateChildrenNodeView(item.row)
      })
    },
    updateChildrenNodeView (row) {
      if (row.entity_type_id == 'registry' || row.entity_type_id == 'registry_group' || row.entity_type_id == 'field_group') {
        if (typeof this.$refs.registryRightsTable.store.$data.states.lazyTreeNodeMap[row.entity_id] !== 'undefined') {
          for (let i = 0; i < this.$refs.registryRightsTable.store.$data.states.lazyTreeNodeMap[row.entity_id].length; i++) {
            this.updateChildrenNodeView(this.$refs.registryRightsTable.store.$data.states.lazyTreeNodeMap[row.entity_id][i])
          }
          this.$refs.registryRightsTable.store.$data.states.treeData[row.entity_id].loaded = false
          this.$refs.registryRightsTable.store.$data.states.treeData[row.entity_id].expanded = false
        }
      }
    }
  },
  mounted () {
    this.loading = true
  }
}
</script>
