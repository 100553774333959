import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetTemplateByGuid } from '@/services/EtlEditor/infrastructure/store/modules/Template/action-types'
import TemplateQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TemplateQueryRepositoryInterface'

export default class TemplateQueryRepository extends AbstractQueryRepository implements TemplateQueryRepositoryInterface {
  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetTemplateByGuid(guid))
  }
}
