import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetTreeElements, GetTreeElementByGuid, GetTreeElementByElementGuid } from '@/services/EtlEditor/infrastructure/store/modules/TreeElement/action-types'
import TreeElementQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TreeElementQueryRepositoryInterface'

export default class TreeElementQueryRepository extends AbstractQueryRepository implements TreeElementQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetTreeElements(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetTreeElementByGuid(guid))
  }

  getByElementGuid (elementGuid: string): Promise<any> {
    return store.dispatch(new GetTreeElementByElementGuid(elementGuid))
  }
}
