<template>
  <div class="tree_mapping">
    <Split class="split_container">
      <SplitArea :size="50" class="split_column">
        <div class="tool_box">
          <template v-if="extractorType === 'xml'">
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.add" placement="top">
              <el-button class="tool_button" type="default" icon="el-icon-plus" size="mini" :disabled="addRootDisabled" @click="add('element')">
                {{ $locale.main.button.add }}
              </el-button>
            </el-tooltip>
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.import" placement="top">
              <el-button style="float: right;" class="tool_button" type="default" icon="el-icon-upload" size="mini" @click="importScheme()"></el-button>
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.add" placement="top">
              <el-dropdown trigger="click" @command="add($event)">
                <el-button type="default" icon="el-icon-plus" size="mini">
                  {{ $locale.main.button.add }}
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="element">
                    {{ $locale.etl_editor_v2.mapping.element_types.element }}
                  </el-dropdown-item>
                  <el-dropdown-item command="array">
                    {{ $locale.etl_editor_v2.mapping.element_types.array }}
                  </el-dropdown-item>
                  <el-dropdown-item command="constant">
                    {{ $locale.etl_editor_v2.mapping.element_types.constant }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-tooltip>
          </template>
        </div>
        <el-scrollbar>
          <el-tree
            v-if="!importDialog.show"
            ref="tree"
            lazy
            node-key="id"
            :load="loadNode"
            :props="treeProps"
            :expand-on-click-node="false"
            :default-expanded-keys="expanded">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span
                  :class="{ 'selected-node': selectedNode === node }"
                  @click="edit(node, data)"
                  style="width: 100%;">
                  <span :class="`node node__${data.element_type_id}`">
                    {{ `[${$locale.etl_editor_v2.mapping.element_types[data.element_type_id]}]` }}
                  </span> {{ node.label }}
                </span>
                <span v-if="selectedNode && selectedNode.data.guid === data.guid">
                  <template v-if="['element', 'array'].indexOf(selectedNode.data.element_type_id) !== -1">
                    <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.add" placement="top">
                      <el-dropdown trigger="click" @command="add($event, false)">
                        <el-button type="text" icon="el-icon-plus" size="small">
                        {{ $locale.main.button.add }}
                      </el-button>
                        <el-dropdown-menu v-if="selectedNode.data.element_type_id === 'element'" slot="dropdown">
                          <el-dropdown-item command="element">{{ $locale.etl_editor_v2.mapping.element_types.element }}</el-dropdown-item>
                          <el-dropdown-item v-if="extractorType === 'xml'" command="attribute">
                            {{ $locale.etl_editor_v2.mapping.element_types.attribute }}
                          </el-dropdown-item>
                          <el-dropdown-item command="array">{{ $locale.etl_editor_v2.mapping.element_types.array }}</el-dropdown-item>
                        </el-dropdown-menu>
                        <el-dropdown-menu v-if="selectedNode.data.element_type_id === 'array'" slot="dropdown">
                          <el-dropdown-item command="element">{{ $locale.etl_editor_v2.mapping.element_types.element }}</el-dropdown-item>
                          <el-dropdown-item command="array">{{ $locale.etl_editor_v2.mapping.element_types.array }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-tooltip>
                  </template>
                  <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.delete" placement="top">
                    <el-button class="text_danger" type="text" icon="el-icon-delete" size="small" @click="remove(node, data)">
                      {{ $locale.main.button.delete }}
                    </el-button>
                  </el-tooltip>
                </span>
              </span>
          </el-tree>
        </el-scrollbar>
      </SplitArea>
      <SplitArea :size="50" class="split_column">
        <div class="tool_box">
          <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.tooltip.save" placement="top">
            <el-button
              class="tool_button"
              icon="el-icon-success"
              type="default"
              size="mini"
              :disabled="!editor.form"
              @click="save()">
              {{ $locale.main.button.save }}
            </el-button>
          </el-tooltip>
        </div>
        <el-scrollbar>
          <component
            ref="form"
            v-if="editor.form"
            v-model="editor.model"
            :is="editor.form"
            :key="editor.id">
          </component>
        </el-scrollbar>
      </SplitArea>
    </Split>

    <XsdImportDialog ref="xsdImport" v-model="importDialog"></XsdImportDialog>
  </div>
</template>

<script>
import Mapping, { MappingDTO } from '@/services/EtlEditor/domain/model/Mapping'
import MappingByGuidQuery from '@/services/EtlEditor/application/query/MappingByGuidQuery'
import MappingsQuery from '@/services/EtlEditor/application/query/MappingsQuery'
import MappingCreateCommand from '@/services/EtlEditor/application/command/MappingCreateCommand'
import MappingDeleteCommand from '@/services/EtlEditor/application/command/MappingDeleteCommand'
import MappingUpdateCommand from '@/services/EtlEditor/application/command/MappingUpdateCommand'

import Node from 'element-ui/packages/tree/src/model/node'

import ArrayForm from './ArrayForm'
import AttributeForm from './AttributeForm'
import ConstantForm from './ConstantForm'
import ElementForm from './ElementForm'
import XsdImportDialog from './XsdImportDialog'

export default {
  name: 'Tree',

  props: {
    taskId: {
      type: Number,
      required: true
    },

    type: String,
    extractorType: {
      type: String,
      default: null
    },

    loaders: Array,
    transformers: Array
  },

  components: {
    ArrayForm,
    AttributeForm,
    ConstantForm,
    ElementForm,
    XsdImportDialog
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    createdMapping () {
      return this.$store.getters['Mapping/getMappingLocation']
    }
  },

  watch: {
    createdMapping (location) {
      if (location) {
        this.getQueryBus().execute(
          new MappingByGuidQuery(location.replace('/mappings/', ''))
        ).then(response => {
          if (response.guid) {
            this.editor.model = response
            this.addNode(response, this.editor.node)
          }
        })
      }
    }
  },

  provide () {
    return {
      getLoaders: () => {
        return this.loaders
      },

      getTransformers: () => {
        return this.transformers
      },

      getTargetFields: () => {
        return this.targetFields
      }
    }
  },

  data () {
    return {
      form: {
        array: 'ArrayForm',
        attribute: 'AttributeForm',
        constant: 'ConstantForm',
        element: 'ElementForm'
      },

      editor: {
        id: null,
        title: null,
        form: null,
        model: {},
        node: null
      },

      isEdit: false,

      selectedNode: null,

      targetFields: [],
      elementTypes: [],

      expanded: [],

      addRootDisabled: false,

      treeProps: {
        children: 'children',
        label: 'source_column',
        isLeaf: 'is_leaf'
      },

      importDialog: {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      }
    }
  },

  methods: {
    add (elementTypeId, parentNode = null) {
      let dto = MappingDTO.create(
        this.taskId,
        `new-${elementTypeId}`,
        parentNode ? parentNode.data.id : null,
        elementTypeId
      )

      let model = Mapping.create(dto)
      this.getCommandBus().execute(
        new MappingCreateCommand(
          model.getTaskId(),
          model.getParentId(),
          model.getSourceFieldId(),
          model.getSourceColumn(),
          model.getSourceAlias(),
          model.getTargetFieldId(),
          model.getTargetColumn(),
          model.getTargetAlias(),
          model.getIsKey(),
          model.getIsRequired(),
          model.getIsLoadXrefTableValues(),
          model.getXrefFieldId(),
          model.getXrefCondition(),
          model.getLoaderId(),
          model.getTransformerId(),
          model.getRowOrder(),
          model.getValue(),
          model.getCondition(),
          model.getElementTypeId(),
          model.getDescription()
        )
      )
    },

    edit (node, data) {
      if (this.isEdit) {
        return
      }

      this.selectedNode = node
      this.isEdit = true

      this.editor.id = this.generateGuid()
      this.editor.form = this.form[data.element_type_id]
      this.editor.title = this.$locale.etl_editor_v2.mapping.headlines.create[data.element_type_id]
      this.editor.model = data
      this.editor.node = node

      this.targetFields = []
      if (this.model.loader_object_id !== null) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?object_id=${this.model.loader_object_id}&is_field=true`)
          .then((response) => {
            this.targetFields.push(...response.data.data)
          })
      }
    },

    remove (node, data) {
      this.$confirm(this.$locale.main.message.delete, this.$locale.main.message_title.warning, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        this.closeEditor()

        this.$refs.tree.remove(node.data.id)

        if (this.extractorType === 'xml') {
          this.addRootDisabled = this.$refs.tree.root.childNodes.length > 0
        }

        this.getCommandBus().execute(
          new MappingDeleteCommand(data.guid)
        )
      })
    },

    selected (node) {
      if (this.selectedNode === node) {
        this.closeEditor()
        return
      }

      this.closeEditor()
      this.edit(node, node.data)
    },

    closeEditor () {
      this.isEdit = false
      this.selectedNode = null
      this.editor = {
        id: null,
        title: null,
        form: null,
        model: {},
        node: null
      }
    },

    importScheme () {
      // if (this.$refs.tree.root.childNodes.length > 0) {
      //   this.$message.error(this.$locale.etl_editor.xsd_import.import_root_error)
      //   return
      // }

      this.importDialog.show = true
    },

    addNode (data, parentNode) {
      data.is_leaf = ['element', 'array', 'constant'].indexOf(data.element_type_id) === -1

      let node = new Node({
        parent: parentNode || this.$refs.tree.root,
        store: parentNode ? parentNode.store : this.$refs.tree.store,
        data: data,
        level: parentNode ? parentNode.level + 1 : 1
      })

      if (parentNode) {
        // added child
        parentNode.childNodes.push(node)

        // auto expanded node children
        this.expanded = [parentNode.data.id]
      } else {
        // added root (only one)
        if (this.extractorType === 'xml') {
          if (this.$refs.tree.root.childNodes.length < 1) {
            this.$refs.tree.root.childNodes.push(node)

            this.addRootDisabled = true
          }
        } else {
          this.$refs.tree.root.childNodes.push(node)
        }
      }
    },

    save () {
      this.$refs.form.submit(() => {
        let model = Mapping.create(this.editor.model)
        this.getCommandBus().execute(
          new MappingUpdateCommand(
            model.getGuid(),
            model.getSourceFieldId(),
            model.getSourceColumn(),
            model.getSourceAlias(),
            model.getTargetFieldId(),
            model.getTargetColumn(),
            model.getTargetAlias(),
            model.getIsKey(),
            model.getIsRequired(),
            model.getIsLoadXrefTableValues(),
            model.getXrefFieldId(),
            model.getXrefCondition(),
            model.getLoaderId(),
            model.getTransformerId(),
            model.getRowOrder(),
            model.getValue(),
            model.getCondition(),
            model.getElementTypeId(),
            model.getDescription()
          )
        )
      })
    },

    async loadNode (node, resolve) {
      resolve(await this.loadObjects(node.level === 0 ? null : node.data.id))
    },

    async loadObjects (parentId = null) {
      let response = await this.getQueryBus().execute(
        new MappingsQuery(
          !parentId
            ? { is_null: 'parent_id', task_id: this.taskId, order: 'row_order:asc,id:desc' }
            : { parent_id: parentId, task_id: this.taskId, order: 'row_order:asc,id:desc' }
        )
      )

      response.forEach(item => {
        item.is_leaf = ['element', 'array', 'constant'].indexOf(item.element_type_id) === -1
      })

      if (!parentId && this.extractorType === 'xml') {
        this.addRootDisabled = response.length > 0
      }

      return response
    }
  }
}
</script>

<style scoped></style>
