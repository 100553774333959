import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import SchemaRepositoryInterface from '@/services/ApiService/domain/repository/SchemaRepositoryInterface'

export default class SchemasHandler implements QueryHandlerInterface {
  private repo: SchemaRepositoryInterface
  constructor (repo: SchemaRepositoryInterface) {
    this.repo = repo
  }

  execute (query: AbstractEntitiesQuery): Promise<any> {
    return this.repo.getSchemas(query.getPayload())
  }
}
