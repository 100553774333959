import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class LogicCreateCommand implements CommandInterface {
  private entityId: number
  private description: Nullable<string>
  private alias: Nullable<string>

  constructor (
    entityId: number,
    description: Nullable<string>,
    alias: Nullable<string>
  ) {
    this.entityId = entityId
    this.description = description
    this.alias = alias
  }

  getClassName (): string {
    return 'LogicCreateCommand'
  }

  getEntityId (): number {
    return this.entityId
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
