import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class TemplateCreateCommand implements CommandInterface {
  private name: string
  private alias: Nullable<string>
  private fieldId: number
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    fieldId: number,
    alias: Nullable<string>,
    parentTreeElementId: number
  ) {
    this.name = name
    this.fieldId = fieldId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'TemplateCreateCommand'
  }

  getName (): string {
    return this.name
  }

  getFileId (): number {
    return this.fieldId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
