export const sizeClasses: string[] = ['px', 'em', 'rem', '%', 'VW', 'VH', 'VMIN', 'VMAX', 'FR', 'EX', 'CH']

export function getSizeClass (value: string) {
  value = value.replace(/[0-9\.]+/, '')
  if (!sizeClasses.includes(value)) {
    value = 'px'
  }
  return value
}

export default {
  sizeClasses,
  getSizeClass
}
