
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import SnapshotsHandler from '@/services/SyncService/application/handler/query/SnapshotsHandler'
import SnapshotsCountHandler from '@/services/SyncService/application/handler/query/SnapshotsCountHandler'
import SnapshotRepository from '@/services/SyncService/infrastructure/domain/repository/SnapshotRepository'
import SnapshotPanel from '@/services/SyncService/infrastructure/components/SnapshotPanel/index.vue'
import MigrationsHandler from '@/services/SyncService/application/handler/query/MigrationsHandler'
import MigrationRepository from '@/services/SyncService/infrastructure/domain/repository/MigrationRepository'
import MigrationsCountHandler from '@/services/SyncService/application/handler/query/MigrationsCountHandler'
import SnapshotsFilterHandler from './application/handler/query/SnapshotsFilterHandler'
import SnapshotsSchemaFilterValuesHandler from '@/services/SyncService/application/handler/query/SnapshotsSchemaFilterValuesHandler'
import SnapshotsTablesFilterValuesHandler from './application/handler/query/SnapshotsTablesFilterValuesHandler'
import ConfigurationElementsEditorEntitiesHandler
  from '@/services/SyncService/application/handler/query/ConfigurationElementsEditorEntitiesHandler'
import ConfigurationElementRepository
  from '@/services/SyncService/infrastructure/domain/repository/ConfigurationElementRepository'
import ConfigurationElementsExportHandler
  from '@/services/SyncService/application/handler/query/ConfigurationElementsExportHandler'

export default {
  name: 'SyncService',
  components: {
    SnapshotPanel
  },
  data () {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({})
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'SnapshotsQuery': new SnapshotsHandler(
            new SnapshotRepository()
          ),
          'SnapshotsCountQuery': new SnapshotsCountHandler(
            new SnapshotRepository()
          ),
          'MigrationsQuery': new MigrationsHandler(
            new MigrationRepository()
          ),
          'MigrationsCountQuery': new MigrationsCountHandler(
            new MigrationRepository()
          ),
          'SnapshotsFilterQuery': new SnapshotsFilterHandler(
            new SnapshotRepository()
          ),
          'SnapshotsSchemaFilterValuesQuery': new SnapshotsSchemaFilterValuesHandler(
            new SnapshotRepository()
          ),
          'SnapshotsTablesFilterValuesQuery': new SnapshotsTablesFilterValuesHandler(
            new SnapshotRepository()
          ),
          'ConfigurationElementsEditorEntitiesQuery': new ConfigurationElementsEditorEntitiesHandler(
            new ConfigurationElementRepository()
          ),
          'ConfigurationElementsExportQuery': new ConfigurationElementsExportHandler(
            new ConfigurationElementRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    }
  },
  mounted () {
  }
}
