import CommandInterface from '@/core/application/command/CommandInterface'
import { GroupDTO } from '@/services/CssEditor/domain/model/Group'

export default class GroupCreateCommand implements CommandInterface {
  private dto: GroupDTO;

  constructor (
    dto: GroupDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'GroupCreateCommand'
  }

  getDto (): GroupDTO {
    return this.dto
  }
}
