import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import GroupDeleteCommand from '@/services/NotificationEditor/application/command/GroupDeleteCommand'
import GroupCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/GroupCommandRepositoryInterface'

export default class GroupDeleteHandler implements CommandHandlerInterface {
  private repository: GroupCommandRepositoryInterface;

  constructor (repository: GroupCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: GroupDeleteCommand): Promise<void> {
    return this.repository.delete(command.getId())
  }
}
