import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class MappingElementTypeDTO {
  id?: Nullable<string>
  name?: Nullable<string>

  constructor ({ id, name }) {
    this.id = id
    this.name = name
  }
}

export class MappingDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  is_leaf?: Nullable<boolean>
  task_id?: number
  parent_id?: Nullable<number>
  source_field_id: Nullable<number>
  source_column: Nullable<string>
  source_alias: Nullable<string>
  target_field_id: Nullable<number>
  target_column: Nullable<string>
  target_alias: Nullable<string>
  is_key: Nullable<boolean>
  is_required: Nullable<boolean>
  is_load_xref_table_values: Nullable<boolean>
  xref_field_id: Nullable<number>
  xref_condition: Nullable<string>
  loader_id: Nullable<number>
  value: Nullable<string>
  row_order: number
  condition: Nullable<string>
  element_type_id: Nullable<string>
  description: Nullable<string>
  transformer_id: Nullable<number>
  loader_object_id?: Nullable<number>
  source_field_entity_type_id?: Nullable<number>
  source_field_name?: Nullable<string>
  target_field_entity_type_id?: Nullable<string>
  target_field_name?: Nullable<string>

  constructor (
    {
      id,
      guid,
      is_leaf,
      task_id,
      parent_id,
      source_field_id,
      source_column,
      source_alias,
      target_field_id,
      target_column,
      target_alias,
      is_key,
      is_required,
      is_load_xref_table_values,
      xref_field_id,
      xref_condition,
      loader_id,
      value,
      row_order,
      condition,
      element_type_id,
      description,
      transformer_id,
      loader_object_id,
      source_field_entity_type_id,
      source_field_name,
      target_field_entity_type_id,
      target_field_name
    }: {
      id?: Nullable<number>,
      guid?: Nullable<string>,
      is_leaf?: Nullable<boolean>,
      task_id?: number,
      parent_id?: Nullable<number>,
      source_field_id: Nullable<number>,
      source_column: Nullable<string>,
      source_alias: Nullable<string>,
      target_field_id: Nullable<number>,
      target_column: Nullable<string>,
      target_alias: Nullable<string>,
      is_key: Nullable<boolean>,
      is_required: Nullable<boolean>,
      is_load_xref_table_values: Nullable<boolean>,
      xref_field_id: Nullable<number>,
      xref_condition: Nullable<string>,
      loader_id: Nullable<number>,
      value: Nullable<string>,
      row_order: number,
      condition: Nullable<string>,
      element_type_id: Nullable<string>,
      description: Nullable<string>,
      transformer_id: Nullable<number>,
      loader_object_id?: Nullable<number>
      source_field_entity_type_id?: Nullable<number>
      source_field_name?: Nullable<string>
      target_field_entity_type_id?: Nullable<string>
      target_field_name?: Nullable<string>
    }
  ) {
    this.id = id
    this.guid = guid
    this.is_leaf = is_leaf
    this.task_id = task_id
    this.parent_id = parent_id
    this.source_field_id = source_field_id
    this.source_column = source_column
    this.source_alias = source_alias
    this.target_field_id = target_field_id
    this.target_column = target_column
    this.target_alias = target_alias
    this.is_key = is_key
    this.is_required = is_required
    this.is_load_xref_table_values = is_load_xref_table_values
    this.xref_field_id = xref_field_id
    this.xref_condition = xref_condition
    this.loader_id = loader_id
    this.value = value
    this.row_order = row_order
    this.condition = condition
    this.element_type_id = element_type_id
    this.description = description
    this.transformer_id = transformer_id
    this.loader_object_id = loader_object_id
    this.source_field_entity_type_id = source_field_entity_type_id
    this.source_field_name = source_field_name
    this.target_field_entity_type_id = target_field_entity_type_id
    this.target_field_name = target_field_name
  }

  static create (
    taskId: number,
    sourceColumn: Nullable<string> = null,
    parentId: Nullable<number> = null,
    elementTypeId: Nullable<string> = null
  ): MappingDTO {
    return new MappingDTO({
      task_id: taskId,
      parent_id: parentId,
      source_field_id: null,
      source_column: sourceColumn,
      source_alias: null,
      target_field_id: null,
      target_column: null,
      target_alias: null,
      is_key: false,
      is_required: false,
      is_load_xref_table_values: false,
      xref_field_id: null,
      xref_condition: null,
      loader_id: null,
      value: null,
      row_order: 0,
      condition: null,
      element_type_id: elementTypeId,
      description: null,
      transformer_id: null,
      loader_object_id: null,
      source_field_entity_type_id: null,
      source_field_name: null,
      target_field_entity_type_id: null,
      target_field_name: null
    })
  }
}

export default class Mapping extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private taskId: number
  private parentId: Nullable<number>
  private sourceFieldId: Nullable<number>
  private sourceColumn: Nullable<string>
  private sourceAlias: Nullable<string>
  private targetFieldId: Nullable<number>
  private targetColumn: Nullable<string>
  private targetAlias: Nullable<string>
  private isKey: boolean
  private isRequired: boolean
  private isLoadXrefTableValues: boolean
  private xrefFieldId: Nullable<number>
  private xrefCondition: Nullable<string>
  private loaderId: Nullable<number>
  private transformerId: Nullable<number>
  private rowOrder: Nullable<number>
  private value: Nullable<string>
  private condition: Nullable<string>
  private elementTypeId: Nullable<string>
  private description: Nullable<string>

  constructor (
    taskId: number,
    parentId: Nullable<number>,
    sourceFieldId: Nullable<number>,
    sourceColumn: Nullable<string>,
    sourceAlias: Nullable<string>,
    targetFieldId: Nullable<number>,
    targetColumn: Nullable<string>,
    targetAlias: Nullable<string>,
    isKey: boolean,
    isRequired: boolean,
    isLoadXrefTableValues: boolean,
    xrefFieldId: Nullable<number>,
    xrefCondition: Nullable<string>,
    loaderId: Nullable<number>,
    transformerId: Nullable<number>,
    rowOrder: Nullable<number>,
    value: Nullable<string>,
    condition: Nullable<string>,
    elementTypeId: Nullable<string>,
    description: Nullable<string>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.taskId = taskId
    this.parentId = parentId
    this.sourceFieldId = sourceFieldId
    this.sourceColumn = sourceColumn
    this.sourceAlias = sourceAlias
    this.targetFieldId = targetFieldId
    this.targetAlias = targetAlias
    this.isKey = isKey
    this.isRequired = isRequired
    this.isLoadXrefTableValues = isLoadXrefTableValues
    this.xrefFieldId = xrefFieldId
    this.xrefCondition = xrefCondition
    this.loaderId = loaderId
    this.transformerId = transformerId
    this.rowOrder = rowOrder
    this.value = value
    this.condition = condition
    this.elementTypeId = elementTypeId
    this.description = description
  }

  static create (dto: MappingDTO): Mapping {
    return new Mapping(
      dto.task_id,
      dto.parent_id,
      dto.source_field_id,
      dto.source_column,
      dto.source_alias,
      dto.target_field_id,
      dto.target_column,
      dto.target_alias,
      dto.is_key,
      dto.is_required,
      dto.is_load_xref_table_values,
      dto.xref_field_id,
      dto.xref_condition,
      dto.loader_id,
      dto.transformer_id,
      dto.row_order,
      dto.value,
      dto.condition,
      dto.element_type_id,
      dto.description,
      dto.id,
      dto.guid
    )
  }

  getId () {
    return this.id
  }

  getGuid (): string {
    return this.guid
  }

  getTaskId (): number {
    return this.taskId
  }

  getParentId (): Nullable<number> {
    return this.parentId
  }

  getSourceFieldId (): Nullable<number> {
    return this.sourceFieldId
  }

  getSourceColumn (): Nullable<string> {
    return this.sourceColumn
  }

  getSourceAlias (): Nullable<string> {
    return this.sourceAlias
  }

  getTargetFieldId (): Nullable<number> {
    return this.targetFieldId
  }

  getTargetColumn (): Nullable<string> {
    return this.targetColumn
  }

  getTargetAlias (): Nullable<string> {
    return this.targetAlias
  }

  getIsKey (): boolean {
    return this.isKey
  }

  getIsRequired (): boolean {
    return this.isRequired
  }

  getIsLoadXrefTableValues (): boolean {
    return this.isLoadXrefTableValues
  }

  getXrefFieldId (): Nullable<number> {
    return this.xrefFieldId
  }

  getXrefCondition (): Nullable<string> {
    return this.xrefCondition
  }

  getLoaderId (): Nullable<number> {
    return this.loaderId
  }

  getTransformerId (): Nullable<number> {
    return this.transformerId
  }

  getRowOrder (): Nullable<number> {
    return this.rowOrder
  }

  getValue (): Nullable<string> {
    return this.value
  }

  getCondition (): Nullable<string> {
    return this.condition
  }

  getElementTypeId (): Nullable<string> {
    return this.elementTypeId
  }

  getDescription (): Nullable<string> {
    return this.description
  }
}
