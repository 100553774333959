export default class PointStyleImageUpdateCommand
{
    private guid: string;
    private name: string;
    private image: File;

    constructor(
        guid: string,
        name: string,
        image: File
    ) {
        this.guid = guid;
        this.name = name;
        this.image = image;
    }

    getClassName(): string
    {
        return 'PointStyleImageUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getImage(): File
    {
        return this.image;
    }
}