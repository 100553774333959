export default class CoordinateSystemUpdateCommand
{
    private srid: number;
    private name: string;
    private proj4text: string;

    constructor(srid: number, name: string, proj4text: string)
    {
        this.srid = srid;
        this.name = name;
        this.proj4text = proj4text;
    }

    getClassName(): string
    {
        return 'CoordinateSystemUpdateCommand';
    }

    getSrid(): number
    {
        return this.srid;
    }

    getName(): string
    {
        return this.name;
    }

    getProj4text(): string
    {
        return this.proj4text;
    }
}