
import Vue from 'vue'
import TagInput from '@/core/infrastructure/components/TagInput.vue'
import VariableTable from '@/services/CssEditor/infrastructure/components/VariableTable.vue'
import CssClassCreateCommand from '@/services/CssEditor/application/command/CssClassCreateCommand'
import CssClassUpdateCommand from '@/services/CssEditor/application/command/CssClassUpdateCommand'

export default Vue.extend({
  name: 'CssForm',

  inject: ['getCommandBus'],

  components: {
    TagInput,
    VariableTable
  },

  props: {
    value: Object
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.modelValue)) {
          this.modelValue = value
        }
      }
    },

    modelValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      }
    }
  },

  computed: {
    isNew () {
      return typeof this.modelValue.guid === 'undefined'
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('css_editor.rules.name'), trigger: 'blur' }
        ],
        class_name: [
          { required: true, message: this.$t('css_editor.rules.className'), trigger: 'blur' }
        ],
        css: [
          { required: true, message: this.$t('css_editor.rules.css'), trigger: 'blur' }
        ]
      }
    }
  },

  data () {
    return {
      modelValue: {
        id: undefined,
        guid: undefined,
        name: 'string',
        description: '',
        alias: null,
        class_name: '',
        css: '',
        miniature: null,
        tags: ''
      },

      options: {
        tabSize: 2,
        mode: 'text/x-scss',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true,
        lineNumbers: true,
        line: true,
        collapseIdentical: true,
        highlightDifferences: false
      },

      dialogVisible: false,
      variableVisible1: false,
      variableVisible2: false
    }
  },

  mounted () {
    this.modelValue = this.value
  },

  methods: {
    submit (callback?: () => void) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.executeCommand(callback)
        } else {
          return false
        }
      })
    },

    executeCommand (callback?: () => void) {
      this.getCommandBus()
        .execute(
          this.isNew
            ? new CssClassCreateCommand(this.modelValue)
            : new CssClassUpdateCommand(this.modelValue)
        ).then(() => {
          if (callback) {
            callback()
          }
        })
    }
  }
})
