import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import EventQueryRepositoryInterface from '../../../domain/repository/EventQueryRepositoryInterface'
import EventsTreeQuery from '../../query/EventsTreeQuery'

export default class EventsTreeHandler implements QueryHandlerInterface
{
    private repo: EventQueryRepositoryInterface;

    constructor(repo: EventQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: EventsTreeQuery): Promise<any>
    {
        return this.repo.getEventTree(
          query.getGuid()
        );
    }
}
