export function arrayRecursiveFind (array: Array<any>, id: number) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return array[i]
    }

    if (array[i].children.length > 0) {
      let result = arrayRecursiveFind(array[i].children, id)

      if (result) {
        return result
      }
    }
  }

  return null
}
