<template>
  <div class="settings">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="icon_button" @click="addRoute()">
          Добавить
        </div>
        <el-scrollbar :style="{height:'calc(100% - 34px)'}" wrap-style="overflow-x:hidden;" class="object_editor" ref="scroll">
          <el-tree
            ref="tree"
            node-key="guid"
            :data="localValue"
            :expand-on-click-node="false"
            :props="{
              children: 'children',
              label: 'name'
            }"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }"  @click="activeRoute = data">
              <span :title="node.label" :class="{ 'selected-node': activeRoute && activeRoute.guid === data.guid }">
                <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
                <b :class="{'pl-5': (data.children || []).length > 0}">{{
                    node.label.length > 55 ? `${node.label.substring(0, 55)}...` : node.label
                  }}</b>
              </span>
              <span>
                <el-button type="text" size="small" @click="addRoute(data)"><i class="el-icon-plus el-icon&#45;&#45;left"></i>
                  Доб.
                </el-button>
                <el-button type="text" size="small" style="color:red" @click.stop="remove(data.guid)"><i class="el-icon-delete el-icon&#45;&#45;left"></i>
                  Уд.
                </el-button>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </el-col>
      <el-col :span="12">
        <el-scrollbar :style="{height:'calc(100% - 30px)'}" wrap-style="overflow-x:hidden; padding-right: 15px">
          <div class="editor" v-if="activeRoute && activeRoute.guid">
            <div class="setting-label">
              Название
            </div>
            <el-input v-model="activeRoute.name"></el-input>
            <div class="setting-label">
              Псевдоним (URL)
            </div>
            <el-input v-model="activeRoute.alias"></el-input>
            <div class="setting-label">
              Вкладка
            </div>
            <div>
              <el-popover
                ref="elementSelectorPopover"
                placement="bottom"
                trigger="hover"
                :open-delay="300"
              >
                <el-input
                  slot="reference"
                  ref="elementSelectorInput"
                  :value="activeRoute.tabGuid"
                  :placeholder="$locale.interface_editor.toolbar.element_selector"
                ></el-input>

                <el-scrollbar class="tree-scroll" wrap-style="max-height: 300px;">
                  <ElementSelector
                    :active-block-guid="activeRoute.tabGuid"
                    :disable-types="['container', 'tabContainer']"
                    :hide-components="true"
                    :blocks="blocks"
                    :components="components"
                    :currentViewer="currentViewer"
                    @set-active-tab="(tabContainerGuid, tabGuid) => $set(activeRoute, 'tabGuid', tabGuid)"
                  ></ElementSelector>
                </el-scrollbar>
              </el-popover>
            </div>
            <div class="setting-label">
              Список детализаций
              <span v-if="(activeRoute.detailing || []).length === 0" class="add-detailing" @click="addDetailing">Добавить<font-awesome-icon
                :icon="icons.plus"></font-awesome-icon></span>
            </div>
            <div class="detailing">
              <div class="detailing-row" v-for="(detailing) in activeRoute.detailing" :key="detailing.guid">
<!--                <div>
                  <el-input class="alias-input" size="mini" v-model="detailing.alias">
                    <template slot="prepend">{{ index + 1 }}</template>
                  </el-input>
                  <i class="el-icon-close close_button" @click.stop="removeDetailing(index)"></i>
                </div>
                <div class="setting-label">ID карточки</div>
                <el-input size="mini" v-model="detailing.source.card_id"></el-input>
                <div class="setting-label">ID записи</div>
                <el-input size="mini" v-model="detailing.source.record_id"></el-input>-->
                <div class="setting-label">Контейнер</div>
                <div>
                  <el-popover
                    ref="elementSelectorPopover"
                    placement="bottom"
                    trigger="hover"
                    :open-delay="300"
                  >
                    <el-input
                      slot="reference"
                      ref="elementSelectorInput"
                      :value="detailing.containerGuid"
                      :placeholder="$locale.interface_editor.toolbar.element_selector"
                    ></el-input>

                    <el-scrollbar class="tree-scroll" wrap-style="max-height: 300px;">
                      <ElementSelector
                        :disable-types="['tab']"
                        :hide-components="true"
                        :active-block-guid="detailing.containerGuid"
                        :blocks="blocks"
                        :components="components"
                        :currentViewer="currentViewer"
                        @set-active-block="guid => $set(detailing, 'containerGuid', guid)"
                      ></ElementSelector>
                    </el-scrollbar>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="setting-label">
              Ограничение открытия карточек
              <span class="add-detailing" @click="addConstraint">Добавить<font-awesome-icon
                :icon="icons.plus"></font-awesome-icon></span>
            </div>
            <div class="detailing-row" v-for="(constraint, index) in activeRoute.constraints" :key="constraint.guid">
              <constraint-row
                style="width: calc(100% - 20px); display: inline-block"
                :value="constraint"
                @input="$set(constraint, 'registryId', $event.registryId);$set(constraint, 'cardId', $event.cardId)"
              >
              </constraint-row>
              <i class="el-icon-close close_button" style="margin: 7px 0px 0px 5px" @click.stop="removeConstraint(index)"></i>
            </div>
          </div>
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ElementSelector from '@/services/InterfaceEditor/infrastructure/components/Toolbar/ElementSelector.vue'
import ConstraintRow from '@/services/InterfaceEditor/infrastructure/components/RoutingSettings/ConstraintRow.vue'

export default {
  name: 'RoutingSettings',
  inject: ['getCurrentViewer'],
  components: {
    ConstraintRow,
    ElementSelector,
    draggable,
    FontAwesomeIcon
  },
  props: {
    value: {
      type: Array
    },
    blocks: {
      type: Array
    },
    components: {
      type: Object
    }
  },
  data () {
    return {
      currentViewer: this.getCurrentViewer(),
      activeRoute: undefined,
      localValue: this.value || [],
      icons: {
        plus: faPlus
      }
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    getTreeIcon (node, data) {
      if (data.children?.length > 0) {
        if (!node.expanded) {
          return 'el-icon-folder'
        } else if (node.expanded) {
          return 'el-icon-folder-opened'
        }
      }
    },
    addDetailing () {
      if (!this.activeRoute) {
        return
      }
      this.activeRoute.detailing.push({
        alias: null,
        guid: this.generateGuid(),
        source: {},
        containerGuid: null
      })
    },
    addRoute (route = null) {
      let object = {
        name: 'Новый роут',
        guid: this.generateGuid(),
        alias: null,
        detailing: [],
        tabGuid: null,
        children: [],
        constraints: []
      }
      if (route) {
        if (!route.hasOwnProperty('children')) {
          this.$set(route, 'children', [])
        }
        route.children.push(object)
      } else {
        this.localValue.push(object)
      }
    },
    remove (guid) {
      this.activeRoute = undefined
      this.$refs.tree.remove(guid)
    },
    removeDetailing (index) {
      this.activeRoute.detailing.splice(index, 1)
    },
    addConstraint () {
      if (!this.activeRoute) {
        return
      }
      if (!Array.isArray(this.activeRoute.constraints)) {
        this.$set(this.activeRoute, 'constraints', [])
      }
      this.activeRoute.constraints.push({
        guid: this.generateGuid(),
        registryId: null,
        cards: []
      })
    },
    removeConstraint (index) {
      this.activeRoute.constraints.splice(index, 1)
    }
  }
}
</script>

<style scoped src="../../../main.scss" lang="scss"></style>
<style scoped lang="scss">
@import '../../../variables';

.settings {
  height: calc(100% - 10px);
  padding: 5px 24px;

  & .icon_button {
    display: inline-block;
    background: $hover_block_color;
    margin-bottom: 5px;

    &:hover {
      background: $active_block_color;
    }
  }

  & > .el-row {
    height: 100%;

    & > .el-col {
      height: 100%;
    }
  }
}

.route_row, .detailing-row {
  cursor: pointer;
  padding: 10px 0px;

  &:hover {
    background: $hover_block_color;
  }

  &.active {
    background: $active_block_color;
  }

  & .setting-label {
    margin-bottom: 5px;
    width: calc(100% - 30px);
    display: inline-block;
  }

  & .setting-label-mini {
    text-transform: initial;
    padding-left: 10px;
  }

  .close_button {
    color: #949FA8;
    cursor: pointer;
    margin-right: 5px;
    vertical-align: top;

    &:hover {
      color: $active_text_color;
    }
  }
}

.detailing-row {
  & .alias-input {
    width: calc(100% - 22px);
    margin-right: 3px;
  }

  .close_button {
    vertical-align: middle;
  }
}

.editor {
  .setting-label {
    margin-top: 15px;
  }
}

.add-detailing {
  float: right;
  cursor: pointer;
  color: #949FA8;

  &:hover {
    color: #606266;
  }

  & svg {
    margin-left: 5px;
  }
}
</style>
