import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class RowFormulaCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private objectId: number
  private aliasFieldId: number
  private targetAliasValue: string
  private value: string
  private ast: string
  private preparedStatement: string
  private hasTargetRows: boolean
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    objectId: number,
    aliasFieldId: number,
    targetAliasValue: string,
    value: string,
    ast: string,
    preparedStatement: string,
    hasTargetRows: boolean,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId: Nullable<number>
  ) {
    this.name = name
    this.description = description
    this.objectId = objectId
    this.aliasFieldId = aliasFieldId
    this.targetAliasValue = targetAliasValue
    this.ast = ast
    this.preparedStatement = preparedStatement
    this.hasTargetRows = hasTargetRows
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'RowFormulaCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getObjectId (): number {
    return this.objectId
  }

  getAliasFieldId (): number {
    return this.aliasFieldId
  }

  getTargetAliasValue (): string {
    return this.targetAliasValue
  }

  getValue (): string {
    return this.value
  }

  getAst (): string {
    return this.ast
  }

  getPreparedStatement (): string {
    return this.preparedStatement
  }

  getHasTargetRows (): boolean {
    return this.hasTargetRows
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
