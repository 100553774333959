import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { MappingDTO } from '@/services/EtlEditor/domain/model/Mapping'
import MappingUpdateCommand from '@/services/EtlEditor/application/command/MappingUpdateCommand'
import MappingCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/MappingCommandRepositoryInterface'

export default class MappingUpdateHandler implements CommandHandlerInterface {
    private repository: MappingCommandRepositoryInterface;

    constructor (repository: MappingCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: MappingUpdateCommand): Promise<void> {
      let item: MappingDTO = {
        guid: command.getGuid(),
        source_field_id: command.getSourceFieldId(),
        source_column: command.getSourceColumn(),
        source_alias: command.getSourceAlias(),
        target_field_id: command.getTargetFieldId(),
        target_column: command.getTargetColumn(),
        target_alias: command.getTargetAlias(),
        is_key: command.getIsKey(),
        is_required: command.getIsRequired(),
        is_load_xref_table_values: command.getIsLoadXrefTableValues(),
        xref_field_id: command.getXrefFieldId(),
        xref_condition: command.getXrefCondition(),
        loader_id: command.getLoaderId(),
        value: command.getValue(),
        row_order: command.getRowOrder(),
        condition: command.getCondition(),
        element_type_id: command.getElementTypeId(),
        description: command.getDescription(),
        transformer_id: command.getTransformerId()
      }

      return this.repository.update(item)
    }
}
