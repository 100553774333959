import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import StateOperationTypeEntityTypesQuery from '@/services/LogicEditor/application/query/StateOperationTypeEntityTypesQuery'
import StateQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/StateQueryRepositoryInterface'

export default class StateOperationTypeEntityTypesHandler implements QueryHandlerInterface {
  private repository: StateQueryRepositoryInterface

  constructor (repository: StateQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: StateOperationTypeEntityTypesQuery): Promise<any> {
    return this.repository.getStateOperationTypeEntityTypes()
  }
}
