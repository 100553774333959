
export interface ISchemaTreeDTO {
  id?: number;
  guid?: string;
  entity_type_id: string;
  is_schema?: boolean;
  name?: string;
  parent_id?: number;
  properties?: object[][];
  row_order?: number;
  schema?: string;
}

export class SchemaTreeDTO {
  id?: number;
  guid?: string;
  entity_type_id: string;
  is_schema?: boolean;
  name?: string;
  parent_id?: number;
  properties?: object[][];
  row_order?: number;
  schema?: string;

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: ISchemaTreeDTO) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.row_order = data.row_order
    this.parent_id = data.parent_id
    this.properties = data.properties
    this.entity_type_id = data.entity_type_id
    this.is_schema = data.is_schema
    this.schema = data.schema
  }
}
