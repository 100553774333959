import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import LayerCommandRepositoryInterface from '../../../domain/repository/LayerCommandRepositoryInterface'
import ProjectLayerDeleteCommand from '@/services/MapEditor/application/command/ProjectLayerDeleteCommand'

export default class ProjectLayerDeleteHandler implements CommandHandlerInterface
{
    private repo: LayerCommandRepositoryInterface;

    constructor(repo: LayerCommandRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(command: ProjectLayerDeleteCommand): Promise<any>
    {
        return this.repo.deleteFromProject(command.getId(), command.getProjectGuid());
    }
}