import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import CommandDeleteCommand from '@/services/LogicEditor/application/command/CommandDeleteCommand'
import CommandCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandCommandRepositoryInterface'

export default class CommandDeleteHandler implements CommandHandlerInterface {
    private repository: CommandCommandRepositoryInterface;

    constructor (repository: CommandCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: CommandDeleteCommand): Promise<void> {
      return this.repository.delete(command.getGuid())
    }
}
