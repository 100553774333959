import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import CoordinateSystemQueryRepositoryInterface from '../../../domain/repository/CoordinateSystemQueryRepositoryInterface'
import CoordinateSystemsByUserIdQuery from '../../query/CoordinateSystemsByUserIdQuery'

export default class CoordinateSystemsByUserIdHandler implements QueryHandlerInterface
{
    private repo: CoordinateSystemQueryRepositoryInterface;

    constructor(repo: CoordinateSystemQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: CoordinateSystemsByUserIdQuery): Promise<any>
    {
        return this.repo.getByUserId(query.getUserId());
    }
}