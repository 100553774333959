import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetPlugins } from '@/services/LogicEditor/infrastructure/store/modules/Plugin/action-types'
import PluginQueryRepositoryInterface from '@/services/LogicEditor/domain/repository/PluginQueryRepositoryInterface'

export default class PluginQueryRepository extends AbstractQueryRepository implements PluginQueryRepositoryInterface {
  get (): Promise<any> {
    return store.dispatch(new GetPlugins())
  }
}
