<template>
  <el-form ref="argumentForm" :model="model" :rules="rules" label-position="top" size="mini">
    <template v-if="type === 'column'">
      <el-form-item :label="$t('main.calculator.labels.current_arguments')" prop="field_id">
        <tree-select
          v-model="model.field_id"
          :data="fields"
          :props="treeProps"
          :placeholder="$t('main.calculator.labels.field')"
          :render-content="renderTree"
          @change="changeCurrentArgument"
        ></tree-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="add()">{{ $t('main.button.add') }}</el-button>
      </el-form-item>
    </template>

    <template v-else>
      <el-form-item :label="$t('calculator.labels.row')" prop="target_alias_value">
        <el-select
          value-key="name"
          filterable
          v-model="model.target_alias_value"
          :placeholder="$t('calculator.labels.row')">
          <el-option v-for="item in aliasValues" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('main.calculator.labels.field')" prop="field_id">
        <tree-select
          v-model="model.field_id"
          :data="fields"
          :props="treeProps"
          :placeholder="$t('main.calculator.labels.field')"
          :render-content="renderTree"
        ></tree-select>
      </el-form-item>

      <el-form-item :label="$t('main.calculator.labels.filters')" prop="filter_field_id">
        <tree-select
          v-model="model.filter_field_id"
          :data="fields"
          :props="treeProps"
          :multiple="true"
          :placeholder="$t('main.calculator.labels.filters')"
          :render-content="renderTree"
        ></tree-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="add()">{{ $t('main.button.add') }}</el-button>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import TreeSelect from '@/components/Common/TreeSelect'

export default {
  name: 'ArgumentCurrentTableForm',

  components: {
    TreeSelect
  },

  props: {
    value: Object,

    fields: {
      type: Array,
      default () {
        return []
      }
    },

    aliasValues: {
      type: Array,
      default () {
        return []
      }
    },

    addArgument: {
      type: Function,
      default () {
        return function () {
        }
      }
    },

    changeArgument: {
      type: Function,
      default () {
        return function (value) {
        }
      }
    },

    type: {
      type: String,
      default: 'column',
      validate (value) {
        return ['column', 'row'].includes(value)
      }
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    model () {
      this.$emit('input', this.model)
    }
  },

  data () {
    return {
      model: this.value,

      treeProps: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children'
      },

      rules: {
        field_id: [{ required: true, message: 'Select argument', trigger: 'change' }],
        target_alias_value: [{ required: true, message: 'Select target alias value', trigger: 'change' }],
        filter_field_id: [{ required: true, message: 'Select filter argument', trigger: 'change' }]
      }
    }
  },

  methods: {
    changeCurrentArgument (value) {
      this.changeArgument(value)
    },

    add () {
      this.$refs.argumentForm.validate((valid) => {
        if (valid) {
          this.addArgument()
        } else {
          return false
        }
      })
    },

    renderTree (h, { node, data, store }) {
      const attr = `attr_${data.id}_`

      return h('span', {
        class: {
          'custom-tree-node': true
        }
      }, [
        h('span', node.label),
        h(
          'span',
          {
            class: 'el-tree-node__attribute'
          },
          attr
        )
      ])
    }
  }
}
</script>

<style scoped></style>
