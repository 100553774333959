import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ProjectQueryRepositoryInterface from '@/services/MapEditor/domain/repository/ProjectQueryRepositoryInterface'
import store from '@/store'
import { GetProjectByGuid, GetProjects, CountProjects } from '@/services/MapEditor/infrastructure/store/modules/Project/action-types'

export default class ProjectQueryRepository extends AbstractQueryRepository implements ProjectQueryRepositoryInterface
{
    get(payload: string[][]): Promise<any>
    {
        return store.dispatch(new GetProjects(payload));
    }

    getByGuid(guid: string): Promise<any>
    {
        return store.dispatch(new GetProjectByGuid(guid));
    }

    getCount(payload: string[][]): Promise<any>
    {
        return store.dispatch(new CountProjects(payload));
    }
}