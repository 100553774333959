<template>
  <el-form-item class="subMenu" :label="$locale.menu_editor.label.select_report">
    <el-select v-model="localProperties[0].value">
      <el-option v-for="item in listReport" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import Reports from '../Models/report/Reports'
export default {
  name: 'report',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'report_id', 'type': 'report', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || [],
      listReport: []
    }
  },
  async mounted () {
    this.listReport = await Reports.get()
  }
}
</script>

<style scoped>
</style>
