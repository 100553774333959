import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import GroupUpdateCommand from '@/services/NotificationEditor/application/command/GroupUpdateCommand'
import GroupCommandRepositoryInterface from '@/services/NotificationEditor/domain/repository/GroupCommandRepositoryInterface'

export default class GroupUpdateHandler implements CommandHandlerInterface {
  private repository: GroupCommandRepositoryInterface;

  constructor (repository: GroupCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: GroupUpdateCommand): Promise<void> {
    return this.repository.update(command.getDto())
  }
}
