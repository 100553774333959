
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import RolePanel from '@/services/AccessEditor/infrastructure/components/RolePanel/index.vue'
import UserPanel from '@/services/AccessEditor/infrastructure/components/UserPanel/index.vue'
import UserProfilePanel from '@/services/AccessEditor/infrastructure/components/UserPanel/UserProfilePanel/index.vue'
import ConnectionPanel from '@/services/AccessEditor/infrastructure/components/ConnectionPanel/index.vue'

import DashboardsHandler from '@/services/AccessEditor/application/handler/query/DashboardsHandler'
import DashboardQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/DashboardQueryRepository'

import MenuHandler from '@/services/AccessEditor/application/handler/query/MenuHandler'
import MenuQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/MenuQueryRepository'

import ConnectionsHandler from '@/services/AccessEditor/application/handler/query/ConnectionsHandler'
import ConnectionsCountHandler from '@/services/AccessEditor/application/handler/query/ConnectionsCountHandler'
import ConnectionPropertiesHandler from '@/services/AccessEditor/application/handler/query/ConnectionPropertiesHandler'
import ConnectionCreateHandler from '@/services/AccessEditor/application/handler/command/ConnectionCreateHandler'
import ConnectionUpdateHandler from '@/services/AccessEditor/application/handler/command/ConnectionUpdateHandler'
import ConnectionDeleteHandler from '@/services/AccessEditor/application/handler/command/ConnectionDeleteHandler'
import ConnectionQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/ConnectionQueryRepository'
import ConnectionPropertiesQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/ConnectionPropertiesQueryRepository'
import ConnectionCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/ConnectionCommandRepository'

import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'
import RolesHandler from '@/services/AccessEditor/application/handler/query/RolesHandler'
import RolesCountHandler from '@/services/AccessEditor/application/handler/query/RolesCountHandler'
import RoleByGuidHandler from '@/services/AccessEditor/application/handler/query/RoleByGuidHandler'
import RoleCreateHandler from '@/services/AccessEditor/application/handler/command/RoleCreateHandler'
import RoleUpdateHandler from '@/services/AccessEditor/application/handler/command/RoleUpdateHandler'
import RoleDeleteHandler from '@/services/AccessEditor/application/handler/command/RoleDeleteHandler'
import RoleQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleQueryRepository'
import RoleCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleCommandRepository'

import RoleProfileQuery from '@/services/AccessEditor/application/query/RoleProfileQuery'
import RoleProfileHandler from '@/services/AccessEditor/application/handler/query/RoleProfileHandler'
import RoleProfileRightUpdateHandler from '@/services/AccessEditor/application/handler/command/RoleProfileRightUpdateHandler'
import RoleProfileRightCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleProfileRightCommandRepository'

import TopicRightsHandler from '@/services/AccessEditor/application/handler/query/TopicRightsHandler'
import TopicRightsCountHandler from '@/services/AccessEditor/application/handler/query/TopicRightsCountHandler'
import TopicRightUpdateHandler from '@/services/AccessEditor/application/handler/command/TopicRightUpdateHandler'
import TopicRightQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/TopicRightQueryRepository'
import TopicRightCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/TopicRightCommandRepository'

import RegistryRightsHandler from '@/services/AccessEditor/application/handler/query/RegistryRightsHandler'
import RegistryRightQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/RegistryRightQueryRepository'
import RegistryRightsUpdateHandler from '@/services/AccessEditor/application/handler/command/RegistryRightsUpdateHandler'
import RegistryRightsCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/RegistryRightsCommandRepository'

import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import UserCardQuery from '@/services/AccessEditor/application/query/UserCardQuery'
import UsersHandler from '@/services/AccessEditor/application/handler/query/UsersHandler'
import UsersCountHandler from '@/services/AccessEditor/application/handler/query/UsersCountHandler'
import UserByGuidHandler from '@/services/AccessEditor/application/handler/query/UserByGuidHandler'
import UserApiKeyHandler from '@/services/AccessEditor/application/handler/query/UserApiKeyHandler'
import UserAvatarHandler from '@/services/AccessEditor/application/handler/query/UserAvatarHandler'
import UserPasswordHandler from '@/services/AccessEditor/application/handler/query/UserPasswordHandler'
import UserPasswordRulesHandler from '@/services/AccessEditor/application/handler/query/UserPasswordRulesHandler'
import UserPasswordCheckHandler from '@/services/AccessEditor/application/handler/query/UserPasswordCheckHandler'
import UserCardHandler from '@/services/AccessEditor/application/handler/query/UserCardHandler'
import UserQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserQueryRepository'
import UserCreateHandler from '@/services/AccessEditor/application/handler/command/UserCreateHandler'
import UserUpdateHandler from '@/services/AccessEditor/application/handler/command/UserUpdateHandler'
import UserDeleteHandler from '@/services/AccessEditor/application/handler/command/UserDeleteHandler'
import UsersUploadFromFileHandler from '@/services/AccessEditor/application/handler/command/UsersUploadFromFileHandler'
import UserCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserCommandRepository'
import UserCardQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserCardQueryRepository'



export default {
  name: 'AccessEditor',
  components: {
    RolePanel,
    UserPanel,
    ConnectionPanel,
    UserProfilePanel
  },
  props: {
    isProfile: Boolean
  },
  data () {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'RoleCreateCommand': new RoleCreateHandler(
            new RoleCommandRepository()
          ),
          'RoleUpdateCommand': new RoleUpdateHandler(
            new RoleCommandRepository()
          ),
          'RoleDeleteCommand': new RoleDeleteHandler(
            new RoleCommandRepository()
          ),
          'RegistryRightsUpdateCommand': new RegistryRightsUpdateHandler(
            new RegistryRightsCommandRepository()
          ),
          'TopicRightUpdateCommand': new TopicRightUpdateHandler(
            new TopicRightCommandRepository()
          ),
          'RoleProfileRightUpdateCommand': new RoleProfileRightUpdateHandler(
            new RoleProfileRightCommandRepository()
          ),
          'UserCreateCommand': new UserCreateHandler(
            new UserCommandRepository()
          ),
          'UserUpdateCommand': new UserUpdateHandler(
            new UserCommandRepository()
          ),
          'UserDeleteCommand': new UserDeleteHandler(
            new UserCommandRepository()
          ),
          'UsersUploadFromFileCommand': new UsersUploadFromFileHandler(
            new UserCommandRepository()
          ),
          'ConnectionCreateCommand': new ConnectionCreateHandler(
            new ConnectionCommandRepository()
          ),
          'ConnectionUpdateCommand': new ConnectionUpdateHandler(
            new ConnectionCommandRepository()
          ),
          'ConnectionDeleteCommand': new ConnectionDeleteHandler(
            new ConnectionCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'DashboardsQuery': new DashboardsHandler(
            new DashboardQueryRepository()
          ),
          'MenuQuery': new MenuHandler(
            new MenuQueryRepository()
          ),
          'RolesQuery': new RolesHandler(
            new RoleQueryRepository()
          ),
          'RolesCountQuery': new RolesCountHandler(
            new RoleQueryRepository()
          ),
          'RoleProfileQuery': new RoleProfileHandler(
            new RoleQueryRepository()
          ),
          'RoleByGuidQuery': new RoleByGuidHandler(
            new RoleQueryRepository()
          ),
          'TopicRightsQuery': new TopicRightsHandler(
            new TopicRightQueryRepository()
          ),
          'TopicRightsCountQuery': new TopicRightsCountHandler(
            new TopicRightQueryRepository()
          ),
          'UsersQuery': new UsersHandler(
            new UserQueryRepository()
          ),
          'UsersCountQuery': new UsersCountHandler(
            new UserQueryRepository()
          ),
          'UserCardQuery': new UserCardHandler(
            new UserCardQueryRepository()
          ),
          'UserByGuidQuery': new UserByGuidHandler(
            new UserQueryRepository()
          ),
          'UserPasswordQuery': new UserPasswordHandler(
            new UserQueryRepository()
          ),
          'UserPasswordRulesQuery': new UserPasswordRulesHandler(
            new UserQueryRepository()
          ),
          'UserPasswordCheckQuery': new UserPasswordCheckHandler(
            new UserQueryRepository()
          ),
          'UserApiKeyQuery': new UserApiKeyHandler(
            new UserQueryRepository()
          ),
          'UserAvatarQuery': new UserAvatarHandler(
            new UserQueryRepository()
          ),
          'ConnectionsQuery': new ConnectionsHandler(
            new ConnectionQueryRepository()
          ),
          'ConnectionsCountQuery': new ConnectionsCountHandler(
            new ConnectionQueryRepository()
          ),
          'ConnectionPropertiesQuery': new ConnectionPropertiesHandler(
            new ConnectionPropertiesQueryRepository()
          ),
          'RegistryRightsQuery': new RegistryRightsHandler(
            new RegistryRightQueryRepository()
          )
        })
      ),
      activeEntity: 'roles',
      rolesList: [],
      currentRole: null,
      rolePermissions: null,
      currentUser: null,
      cardParams: null
    }
  },
  created: function () {
    let currentUser = this.$store.getters['Authorization/userId']
    if (Number.isInteger(currentUser)) {
      this.getQueryBus().execute(
        new UsersQuery({
          id: currentUser,
          limit: 1,
          offset: 0
        })
      ).then(data => {
        this.currentUser = data[0]
        this.getCardParams()
        this.loadRolePermission()
        this.loadAllRoles()
      })
    }
  },
  watch: {
    currentUser: {
      handler: function () {

      },
      deep: true
    },
    cardParams: {
      handler: function () {

      },
      deep: true
    },
    rolesList: {
      handler: function () {

      },
      deep: true
    },
    rolePermissions: {
      handler: function () {

      },
      deep: true
    },
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    },
    getCardParams () {
      this.getQueryBus().execute(
        new UserCardQuery({})
      ).then(data => {
        if (data.status === 'ok') {
          this.cardParams = data.card
        } else {
          console.log('Error! User card parameters not found!')
          this.cardParams.registryId = null
          this.cardParams.cardId = null
        }
      })
    },
    loadRolePermission () {
      let roleId = 0
      if (this.currentUser !== null) {
        roleId = this.currentUser.role_id
      }
      this.getQueryBus().execute(
        new RoleProfileQuery(roleId)
      ).then(data => {
        if (data.length > 0) {
          this.rolePermissions = {}
          for (let i = 0; i < data.length; i++) {
            this.rolePermissions[data[i].user_field_id] = {
              is_readable: this.currentUser.is_admin ? true : data[i].is_readable,
              is_editable: this.currentUser.is_admin ? true : data[i].is_editable
            }
          }
        }
      })
    },
    loadAllRoles () {
      this.getQueryBus().execute(
        new RolesQuery({})
      ).then(data => {
        this.rolesList = data
        for (let i = 0; i < this.rolesList.length; i++) {
          if (this.rolesList[i].id === this.currentUser.role_id) {
            this.currentRole = this.rolesList[i]
            break
          }
        }
      })
    }
  },
  mounted () {
    this.getEventBus().$on('rolesListIsChanged', (data) => {
      this.loadAllRoles()
    })
  }
}
