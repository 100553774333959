<template>
<el-form-item class="subMenu" :label="$locale.menu_editor.label.select_plugin">
    <el-select v-model="localProperties[0].value" filterable>
      <el-option v-for="item in plugins" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    </el-form-item>
</template>

<script>
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { PluginAPI } from '@/services/LogicEditor/infrastructure/api/PluginAPI'

export default {
  name: 'plugin',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'plugin_id', 'type': 'plugin', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || [],
      plugins: []
    }
  },
  async mounted () {
    try {
      let response = await APIClient.shared.request(new PluginAPI.GetPlugins())
      this.plugins = response
    } catch (error) {
      console.log('plugin', error)
    }
  }
}
</script>

<style scoped>
</style>
