import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'
import RowFormulaUpdateCommand from '@/services/LogicEditor/application/command/RowFormulaUpdateCommand'
import RowFormulaCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/RowFormulaCommandRepositoryInterface'

export default class RowFormulaUpdateHandler implements CommandHandlerInterface {
    private repository: RowFormulaCommandRepositoryInterface;

    constructor (repository: RowFormulaCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: RowFormulaUpdateCommand): Promise<void> {
      let item: RowFormulaDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        object_id: command.getObjectId(),
        alias_field_id: command.getAliasFieldId(),
        target_alias_value: command.getTargetAliasValue(),
        value: command.getValue(),
        ast: command.getAst(),
        prepared_statement: command.getPreparedStatement(),
        has_target_rows: command.getHasTargetRows()
      }

      return this.repository.update(item)
    }
}
