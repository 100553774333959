<template>
  <el-form v-loading="loading" class="card_and_dashboard">
    <InterfaceEditor
      ref="interface_editor"
      v-if="version === 1"
      :registry-fields="registry_fields"
      :entityTitle="`${name} (${registry_name})`"
      :registryId="registry_id"
      :model="data"
      :get-additional-settings="getAdditionalSettings"
      @set-additional-settings="setAdditionalSettings"
      @save="saveCard">
      <template v-slot:settings>
<!--        <el-form-item :label="$locale.interface_editor.operations">
          <el-select v-model="operations" multiple collapse-tags value-key="id">
            <el-option
              v-for="(item, index) in available_operations"
              :key="index"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item :label="$locale.interface_editor.default_state">
          <el-select v-model="defaultState" value-key="id" clearable>
            <el-option
              v-for="(item, index) in states"
              :key="index"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_add">
          <el-input v-model="titleForAdd"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_edit">
          <el-input v-model="titleForEdit"></el-input>
        </el-form-item>
        <el-form-item
          :label="`${$locale.logic_editor.listeners.title} / ${$locale.logic_editor.listeners.translate.update_event}`">
          <el-input v-model="listeners.update_event"></el-input>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.name_btn_save }}
          <el-input type="text" v-model="buttonSave.text" :placeholder="$locale.main.button.save"></el-input>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.close_on_add }}
          <el-checkbox v-model="buttonSave.closeCardOnAdd" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.close_on_edit }}
          <el-checkbox v-model="buttonSave.closeCardOnEdit" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.hide_save }}
          <el-checkbox v-model="buttonSave.btnSaveHidden" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.hide_close }}
          <el-checkbox v-model="buttonClose.btnCloseHidden" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          Уведомлять при закрытии карточки
          <el-checkbox v-model="buttonClose.confirmCloseCard" style="margin-left: 5px;"></el-checkbox>
          <div>Текст сообщения</div>
          <el-input type="text" :disabled="!buttonClose.confirmCloseCard" v-model="buttonClose.confirmCloseCardText"></el-input>
        </el-form-item>
      </template>
    </InterfaceEditor>
    <interface-editor-v2
      v-else-if="version === 2"
      ref="interface_editor_v2"
      :registry-id="registry_id"
      :registry-fields="registry_fields"
      :get-additional-settings="getAdditionalSettings"
      :model="data"
      @save="saveCard"
      @set-additional-settings="setAdditionalSettings"
    >
    <template v-slot:settings>
<!--        <el-form-item :label="$locale.interface_editor.operations">
          <el-select v-model="operations" multiple collapse-tags value-key="id">
            <el-option
              v-for="(item, index) in available_operations"
              :key="index"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item :label="$locale.interface_editor.default_state">
          <el-select v-model="defaultState" value-key="id" clearable>
            <el-option
              v-for="(item, index) in states"
              :key="index"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_add">
          <el-input v-model="titleForAdd"></el-input>
        </el-form-item>
        <el-form-item :label="$locale.interface_editor.title_for_edit">
          <el-input v-model="titleForEdit"></el-input>
        </el-form-item>
        <el-form-item
          :label="`${$locale.logic_editor.listeners.title} / ${$locale.logic_editor.listeners.translate.update_event}`">
          <el-input v-model="listeners.update_event"></el-input>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.name_btn_save }}
          <el-input type="text" v-model="buttonSave.text" :placeholder="$locale.main.button.save"></el-input>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.close_on_add }}
          <el-checkbox v-model="buttonSave.closeCardOnAdd" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.close_on_edit }}
          <el-checkbox v-model="buttonSave.closeCardOnEdit" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.hide_save }}
          <el-checkbox v-model="buttonSave.btnSaveHidden" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          {{ $locale.interface_editor.settings.card.hide_close }}
          <el-checkbox v-model="buttonClose.btnCloseHidden" style="margin-left: 5px;"></el-checkbox>
        </el-form-item>
        <el-form-item>
          Уведомлять при закрытии карточки
          <el-checkbox v-model="buttonClose.confirmCloseCard" style="margin-left: 5px;"></el-checkbox>
          <div>Текст сообщения</div>
          <el-input type="text" :disabled="!buttonClose.confirmCloseCard" v-model="buttonClose.confirmCloseCardText"></el-input>
        </el-form-item>
    </template>
    </interface-editor-v2>
  </el-form>
</template>

<script>
import Card from './Models/Card'
import InterfaceEditor from '@/components/InterfaceEditor/index.vue'
import InterfaceEditorV2 from '@/services/InterfaceEditor/index.vue'
import Registry from './Models/Registry'
import { cloneObject, getDefaultValues } from '../RegistryCard/utils'

export default {
  name: 'CardEditor',
  components: {
    InterfaceEditor,
    InterfaceEditorV2
  },
  props: ['registry_id', 'card_id'],
  data () {
    return {
      data: {},
      loading: false,
      error: false,
      error_text: 'всё очень плохо',
      structure: {},
      name: null,
      is_default: false,
      registry_fields: [],
      registry_name: null,
      available_operations: [],
      operations: [],
      defaultState: null,
      states: [],
      titleForEdit: null,
      titleForAdd: null,
      listeners: {
        update_event: null
      },
      buttonSave: {
        text: this.$locale.main.button.save,
        closeCardOnEdit: false,
        closeCardOnAdd: false,
        btnSaveHidden: false
      },
      buttonClose: {
        btnCloseHidden: false,
        confirmCloseCard: false,
        confirmCloseCardText: ''
      },
      version: null,
      alternativeSource: {
        isActive: false,
        fields: []
      },
      alias: null,
      cardListeners: []
    }
  },
  async mounted () {
    this.loading = true
    if (!this.registry_id || !this.card_id) {
      this.error = true
      return false
    }
    await this.loadCard(this.card_id)
    this.available_operations = await this.getApprovalButtons(this.registry_id)
    this.states = await this.getRegistryStates(this.registry_id)

    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  methods: {
    async loadDefaultValues () {
      const defaultValues = await getDefaultValues(this.structure, this)

      const defaultData = {}

      console.group()

      for (const key of Object.keys(defaultValues)) {
        if (typeof this.data[key] === 'undefined') {
          defaultData[key] = defaultValues[key]

          console.log(key, defaultData[key])
        }
      }

      console.log(this.data)

      console.groupEnd()

      this.data = Object.assign(
        cloneObject(this.data || {}),
        cloneObject(defaultData)
      )
    },
    async loadCard (cardId) {
      let card = await Card.find(cardId)
      this.name = card.name
      this.alias = card.alias
      this.is_default = card.is_default
      this.structure = JSON.parse(card.structure)
      this.operations = JSON.parse(card.structure).operations || []
      this.defaultState = JSON.parse(card.structure).defaultState
      this.titleForEdit = JSON.parse(card.structure).titleForEdit || card.name
      this.titleForAdd = JSON.parse(card.structure).titleForAdd || card.name
      this.listeners = JSON.parse(card.structure).listeners || this.listeners
      this.buttonClose.btnCloseHidden = JSON.parse(card.structure).btnCloseHidden || false
      this.buttonClose.confirmCloseCard = JSON.parse(card.structure).confirmCloseCard || false
      this.buttonClose.confirmCloseCardText = JSON.parse(card.structure).confirmCloseCardText || this.$locale.interface_editor.main.confirm_close_card_text
      this.buttonSave.text = JSON.parse(card.structure).buttonSave?.text || this.$locale.main.button.save
      this.buttonSave.btnSaveHidden = JSON.parse(card.structure).buttonSave?.btnSaveHidden || false
      this.buttonSave.closeCardOnEdit = JSON.parse(card.structure).buttonSave?.closeCardOnEdit || false
      this.buttonSave.closeCardOnAdd = JSON.parse(card.structure).buttonSave?.closeCardOnAdd || false
      this.alternativeSource.isActive = card.uses_alternative_source || false
      this.alternativeSource.fields = card.card_fields || []
      this.cardListeners = JSON.parse(card.structure).cardListeners || []
      if (typeof this.structure.version !== 'undefined') {
        this.version = this.structure.version
      } else {
        this.version = 1
      }
      if (this.version === 1) {
        await this.loadRegistryData(this.registry_id)
      }
      if (this.version === 2) {
        await this.loadRegistryFields(this.registry_id)
      }
      this.$nextTick(() => {
        this.loadInterface(this.structure)
      })
      await this.loadDefaultValues()
    },
    loadInterface (data) {
      if (this.version === 1) {
        this.$refs.interface_editor.loadState(data)
      } else if (this.version === 2) {
        this.$refs.interface_editor_v2.loadInterface(data)
      }
    },
    async loadRegistryData (registryId) {
      let registry = new Registry({ id: registryId })
      let me = this
      let structure = await registry.structure().first().catch(() => {
        me.error = true
      })
      if (structure) {
        this.registry_name = structure.name
        await this.loadRegistryFields(registryId)
      }
    },
    async loadRegistryFields (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      this.registry_fields = this.deepSearch(data.data.data)
    },
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array) this.deepSearch(el.children)
      }
      return arr
    },
    // Сохранить карточку
    async saveCard (data) {
      this.loading = true
      try {
        let card = new Card({ id: this.card_id })
        await card.sync({
          id: this.card_id,
          name: this.name,
          alias: this.alias,
          is_default: this.is_default,
          uses_alternative_source: this.alternativeSource.isActive,
          card_fields: this.alternativeSource.fields,
          structure: Object.assign(data, {
            version: this.version,
            operations: this.operations,
            defaultState: this.defaultState,
            titleForAdd: this.titleForAdd,
            titleForEdit: this.titleForEdit,
            listeners: this.listeners,
            btnCloseHidden: this.buttonClose.btnCloseHidden,
            confirmCloseCard: this.buttonClose.confirmCloseCard,
            confirmCloseCardText: this.buttonClose.confirmCloseCardText,
            buttonSave: this.buttonSave
          })
        })
        this.loading = false
      } catch (e) {
        console.log(e, 'src/components/CardEditor/index.vue')
        this.loading = false
      }
    },
    getAdditionalSettings () {
      return {
        name: this.name,
        is_default: this.is_default,
        version: this.version,
        operations: this.operations,
        defaultState: this.defaultState,
        titleForAdd: this.titleForAdd,
        titleForEdit: this.titleForEdit,
        listeners: this.listeners,
        btnCloseHidden: this.buttonClose.btnCloseHidden,
        confirmCloseCard: this.buttonClose.confirmCloseCard,
        confirmCloseCardText: this.buttonClose.confirmCloseCardText,
        buttonSave: this.buttonSave,
        alternativeSource: this.alternativeSource
      }
    },
    setAdditionalSettings (data) {
      this.name = data.name
      this.is_default = data.is_default
      this.version = data.version
      this.operations = data.operations
      this.defaultState = data.defaultState
      this.titleForAdd = data.titleForAdd
      this.titleForEdit = data.titleForEdit
      this.listeners = data.listeners
      this.btnCloseHidden = data.buttonClose?.btnCloseHidden || false
      this.confirmCloseCard = data.buttonClose?.confirmCloseCard || false
      this.confirmCloseCardText = data.buttonClose?.confirmCloseCardText || this.$locale.interface_editor.main.confirm_close_card_text
      this.buttonSave = data.buttonSave
      this.alternativeSource.isActive = data.alternativeSource?.isActive || false
      this.alternativeSource.fields = data.alternativeSource?.fields || []
    },
    async getApprovalButtons (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?object_id=${registryId}&properties[is_approval_button]=true`)
      return data.data.data.map((item) => {
        return { id: item.id, name: item.name, type: 'approval', visible: false }
      })
    },
    async getRegistryStates (registryId) {
      let logicData = await this.$http.get(`${this.$config.api}/v2/logiceditor/logic?entity_id=${registryId}`)
      let logicId = (logicData.data[0] || {}).id
      if (logicId) {
        let data = await this.$http.get(`${this.$config.api}/v2/logiceditor/states?logic_id=${logicId}`)
        return data.data
      }
      return []
    }
  }
}
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  margin-top: 15px;
  /*padding-left: 20px;*/
  height: 28px;
  color: #2C2D35;
}

.heading {
  margin-left: 4px;
}
</style>
