import CommandInterface from '@/core/application/command/CommandInterface'
import { RequestTreeDTO } from '@/services/ApiService/domain/model/Request/RequestTree'

export default class CreateRequestCommand implements CommandInterface {
  private dto: RequestTreeDTO;

  constructor (
    dto: RequestTreeDTO
  ) {
    this.dto = dto
  }

  getClassName (): string {
    return 'CreateRequestCommand'
  }

  getDto (): RequestTreeDTO {
    return this.dto
  }
}
