<template>
  <el-form-item  class="subMenu" :label="$locale.menu_editor.label.select_command">
    <el-select v-model="localProperties[0].value">
      <el-option v-for="item in listCommand" :key="item.id" :label="item.label" :value="item.value"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'command',
  props: ['properties'],
  watch: {
    localProperties: {
      handler: function (val) {
        this.$emit('properties', [{ 'id': 'command_id', 'type': 'command', 'value': val[0].value }])
      },
      deep: true
    }
  },
  data () {
    return {
      localProperties: this.properties || {},
      listCommand: [
        {
          value: 'test',
          label: 'test'
        },
        {
          value: 'test',
          label: 'test'
        },
        {
          value: 'test',
          label: 'test'
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
