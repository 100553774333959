
  import TopicsQuery from '@/services/Databus/application/query/TopicsQuery'
  import TasksQuery from '@/services/Databus/application/query/TasksQuery'
  import RoutesQuery from '@/services/Databus/application/query/RoutesQuery'
  import RoutesCountQuery from '@/services/Databus/application/query/RoutesCountQuery'
  import CommandsQuery from '@/services/LogicEditor/application/query/CommandsQuery'
  import Route, { RouteDTO } from '@/services/Databus/domain/model/Route/Route'
  import RouteCreateCommand from '@/services/Databus/application/command/RouteCreateCommand'
  import RouteUpdateCommand from '@/services/Databus/application/command/RouteUpdateCommand'
  import RouteDeleteCommand from '@/services/Databus/application/command/RouteDeleteCommand'


  export default {
    name: 'RouteGisgkhPanel',
    data () {
      return {
        loading: false,
        topicType: 'gis_gkh_request',
        routesPageSize: 0,
        defaultRoutesPageSize: 100,
        routesCurrentPage: 0,
        routesPageLimit: 100,
        routesCount: 0,
        allRoutesCount: 0,
        routes: [],
        route: null,
        routeDto: null,
        isDuplicate: false,
        informationPanelHeight: 0,
        isCreateRouteWindowVisible: false,
        topics: [],
        tasks: [],
        commands: [],
        types: [{
          id: "response",
          name: this.$locale.databus.route_gisgkh_panel.response
        },{
          id: "status",
          name: this.$locale.databus.route_gisgkh_panel.status
        },{
          id: "reject",
          name: this.$locale.databus.route_gisgkh_panel.reject
        },{
          id: "error",
          name: this.$locale.databus.route_gisgkh_panel.error
        }],
        createRouteRules: {
          type: {
            required: true,
            message: this.$locale.main.message.required_field,
            trigger: 'change'
          },
          topic: {
            required: true,
            message: this.$locale.main.message.required_field,
            trigger: 'change'
          },
          task_id: {
            required: false,
            message: this.$locale.main.message.required_field,
            trigger: 'change'
          },
        }
      }
    },
    inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
    watch: {
      routeCreated: function (location) {
        this.loadRoutes(() => {
          this.closeCreateRouteWindow();
        });
      },
      isRouteLoading: (state) => state,
      getSaveRouteError: function (err) {
        if (err.code == 'duplicate_key_violation_exception') {
          this.isDuplicate = true;
          this.$message({
            message: this.$locale.databus.route_gisgkh_panel.duplicate_route_code,
            type: 'warning'
          });
        }
      }
    },
    computed: {
      isRouteLoading() {
        return this.$store.getters['Route/isLoading'];
      },
      routeCreated() {
        return this.$store.getters['Route/getLocation'];
      },
      getSaveRouteError() {
        return this.$store.getters['Route/getError'];
      }
    },
    methods: {
      loadRoutes(callback) {
        if (this.allRoutesCount == 0) {
          this.countAndLoadRoutes(callback);
        } else {
          this.loadAllRoutes(callback);
        }
      },
      async countAndLoadRoutes(callback) {
        await this.getQueryBus().execute(
          new RoutesCountQuery({
            topic_type: this.topicType
          })
        ).then(data => {
          this.routesCount = data[0].count;
          this.loadAllRoutes(callback);
        });
      },
      async loadAllRoutes(callback) {
        await this.getQueryBus().execute(
          new RoutesQuery({
            limit: this.routesPageLimit,
            offset: this.routesCurrentPage,
            topic_type: this.topicType
          })
        ).then(data => {
          this.routesPageSize = this.routesPageLimit;
          this.routes = data;
          if (typeof callback === "function") {
            callback();
          }
        });
      },
      createRoute() {
        this.routeDto = new RouteDTO({});
        this.routeDto.priority = 0;
        this.isCreateRouteWindowVisible = true;
        this.loadTopics();
        this.loadTasks();
        this.loadCommands();
      },
      updateRoute() {
        if (this.route !== null) {
          this.isCreateRouteWindowVisible = true;
          this.loadTopics();
          this.loadTasks();
          this.loadCommands();
        }
        else {
          this.$message({
            message: this.$locale.main.message.select_record,
            type: 'warning'
          });
        }
      },
      saveRoute() {
        let form = this.$refs.route_gisgkh_panel;
        form.validate(valid => {
          if (valid) {
            let route = Route.create(this.routeDto);
            if (route.getId() == null) {
              this.getCommandBus().execute(
                new RouteCreateCommand(
                  route.getTopic(),
                  route.getTaskId(),
                  route.getFileName(),
                  route.getPriority(),
                  route.getType(),
                  route.getCommands(),
                  route.getProperties(),
                  route.getIsBigData()
                )
              );
            } else {
              this.getCommandBus().execute(
                new RouteUpdateCommand(
                  route.getGuid(),
                  route.getTopic(),
                  route.getTaskId(),
                  route.getFileName(),
                  route.getPriority(),
                  route.getType(),
                  route.getCommands(),
                  route.getProperties(),
                  route.getIsBigData()
                )
              ).then(() => {
                if (this.isDuplicate) {
                  this.isDuplicate = false;
                } else {
                  this.closeCreateRouteWindow();
                }
              });
            }
          }
        });
      },
      closeCreateRouteWindow() {
        this.isCreateRouteWindowVisible = false;
      },
      changeRoute(routeDto) {
        if (routeDto !== null) {
          this.route = Route.create(routeDto);
          this.routeDto = routeDto;
        } else {
          this.route = null;
          this.routeDto = null;
        }
      },
      deleteRoute() {
        if (this.route == null) {
          this.$message({
            message: this.$locale.main.message.select_record,
            type: 'warning'
          });
        } else {
          this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
            confirmButtonText: this.$locale.main.button.delete,
            cancelButtonText: this.$locale.main.button.cancel,
            type: 'warning'
          }).then(async () => {

            this.getCommandBus().execute(
              new RouteDeleteCommand(
                this.route.getGuid()
              )
            ).then((response) => {
              this.route = null;
              this.$refs.gisgkh_response_routes_table.setCurrentRow(null);
              this.loadRoutes();
            });

          }).catch((error) => { console.log(error); })
        }
      },
      handleRoutesPageChange(val) {
        val--;
        this.routesCurrentPage = (val * this.routesPageLimit);
        this.loadRoutes();
      },
      async loadTopics() {
        this.topics = await this.getQueryBus().execute(
          new TopicsQuery({
            type: this.topicType
          })
        );
      },
      async loadTasks() {
        this.tasks = await this.getQueryBus().execute(
          new TasksQuery()
        );
      },
      getCommandNameById(id) {
        let command = this.commands.find(item => item.id == id);
        if (typeof command == 'undefined') {
          return '';
        }
        return command.name;
      },
      async loadCommands() {
        this.commands = await this.getQueryBus().execute(
          new CommandsQuery({
            fields: 'id,name',
            order: 'name:asc'
          })
        );
      },
      dblClickChangeRoute(selectedRoute) {
        this.route = Route.create(selectedRoute);
        this.routeDto = selectedRoute;
        this.updateRoute()
      },
      fixClearableTask() {
        if (typeof this.routeDto.task_id == 'string') {
          this.routeDto.task_id = null;
        }
      }
    },
    mounted () {
      this.loadRoutes();
      this.loadCommands();
    }
  }
