import { Nullable } from '@/core/domain/type/types'

export default class LayerUpdateCommand
{
    private guid: string;
    private name: string;
    private description: Nullable<string>;
    private sourceId: number;
    private type: string;
    private srsHandling: string;
    private declaredCoordinateSystem: number;
    private nativeCoordinateSystem: Nullable<number>;
    private pointStyleId: Nullable<number>;
    private linestringStyleId: Nullable<number>;
    private polygonStyleId: Nullable<number>;
    private labelStyleId: Nullable<number>;
    private iconStyleTypeId: Nullable<string>;
    private constraintFilter: Nullable<string>;
    private isClustered: boolean;
    private nativeBboxXmin: number;
    private nativeBboxYmin: number;
    private nativeBboxXmax: number;
    private nativeBboxYmax: number;
    private latlonBboxXmin: number;
    private latlonBboxYmin: number;
    private latlonBboxXmax: number;
    private latlonBboxYmax: number;
    private properties: object;
    private projectId: number;
    private parentId: Nullable<number>;

    constructor(
        guid: string,
        name: string,
        description: Nullable<string>,
        sourceId: number,
        type: string,
        srsHandling: string,
        declaredCoordinateSystem: number,
        nativeCoordinateSystem: Nullable<number>,
        pointStyleId: Nullable<number>,
        linestringStyleId: Nullable<number>,
        polygonStyleId: Nullable<number>,
        labelStyleId: Nullable<number>,
        iconStyleTypeId: Nullable<string>,
        constraintFilter: Nullable<string>,
        isClustered: boolean = false,
        nativeBboxXmin: number,
        nativeBboxYmin: number,
        nativeBboxXmax: number,
        nativeBboxYmax: number,
        latlonBboxXmin: number,
        latlonBboxYmin: number,
        latlonBboxXmax: number,
        latlonBboxYmax: number,
        properties: object = {},
        projectId: number,
        parentId: Nullable<number>
    ) {
        this.guid = guid;
        this.name = name;
        this.description = description;
        this.sourceId = sourceId;
        this.type = type;
        this.srsHandling = srsHandling;
        this.declaredCoordinateSystem = declaredCoordinateSystem;
        this.nativeCoordinateSystem = nativeCoordinateSystem;
        this.pointStyleId = pointStyleId;
        this.linestringStyleId = linestringStyleId;
        this.polygonStyleId = polygonStyleId;
        this.labelStyleId = labelStyleId;
        this.iconStyleTypeId = iconStyleTypeId;
        this.constraintFilter = constraintFilter;
        this.isClustered = isClustered;
        this.nativeBboxXmin = nativeBboxXmin;
        this.nativeBboxYmin = nativeBboxYmin;
        this.nativeBboxXmax = nativeBboxXmax;
        this.nativeBboxYmax = nativeBboxYmax;
        this.latlonBboxXmin = latlonBboxXmin;
        this.latlonBboxYmin = latlonBboxYmin;
        this.latlonBboxXmax = latlonBboxXmax;
        this.latlonBboxYmax = latlonBboxYmax;
        this.properties = properties;
        this.projectId = projectId;
        this.parentId = parentId;
    }

    getClassName(): string
    {
        return 'LayerUpdateCommand';
    }

    getGuid(): string
    {
        return this.guid;
    }

    getName(): string
    {
        return this.name;
    }

    getDescription(): Nullable<string>
    {
        return this.description;
    }

    getSourceId(): number
    {
        return this.sourceId;
    }

    getType(): string
    {
        return this.type;
    }

    getSrsHandling(): string
    {
        return this.srsHandling;
    }

    getDeclaredCoordinateSystem(): number
    {
        return this.declaredCoordinateSystem;
    }

    getNativeCoordinateSystem(): Nullable<number>
    {
        return this.nativeCoordinateSystem;
    }

    getPointStyleId(): Nullable<number>
    {
        return this.pointStyleId;
    }

    getLinestringStyleId(): Nullable<number>
    {
        return this.linestringStyleId;
    }

    getPolygonStyleId(): Nullable<number>
    {
        return this.polygonStyleId;
    }

    getLabelStyleId(): Nullable<number>
    {
        return this.labelStyleId;
    }

    getIconStyleTypeId(): Nullable<string>
    {
        return this.iconStyleTypeId;
    }

    getConstraintFilter(): Nullable<string>
    {
        return this.constraintFilter;
    }

    getIsClustered(): boolean
    {
        return this.isClustered;
    }

    getNativeBboxXmin(): number
    {
        return this.nativeBboxXmin;
    }

    getNativeBboxYmin(): number
    {
        return this.nativeBboxYmin;
    }

    getNativeBboxXmax(): number
    {
        return this.nativeBboxXmax;
    }

    getNativeBboxYmax(): number
    {
        return this.nativeBboxYmax;
    }

    getLatlonBboxXmin(): number
    {
        return this.latlonBboxXmin;
    }

    getLatlonBboxYmin(): number
    {
        return this.latlonBboxYmin;
    }

    getLatlonBboxXmax(): number
    {
        return this.latlonBboxXmax;
    }

    getLatlonBboxYmax(): number
    {
        return this.latlonBboxYmax;
    }

    getProperties(): object
    {
        return this.properties;
    }

    getProjectId(): number
    {
        return this.projectId;
    }

    getParentId(): Nullable<number>
    {
        return this.parentId;
    }
}