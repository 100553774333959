<template>
  <component :is="component">
    <template v-slot:loginForm="slotProps">
      <div class="loginPage_title">{{ $locale.login.title }}</div>

      <el-form :rules="rules" :model="model" ref="form" @submit.native.prevent="submit" v-show="!slotProps.hideLogin">
        <el-form-item prop="login">
          <el-input
            v-model="model.login"
            class="loginPage_input"
            autocomplete="off"
            focus
            :placeholder="$locale.access_editor.users_item.login"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="model.password"
            class="loginPage_input"
            type="password"
            autocomplete="off"
            :show-password="true"
            :placeholder="$locale.access_editor.users_item.password"
          ></el-input>
        </el-form-item>
        <el-checkbox v-if="ldap" v-model="model.ldap">{{ $locale.login.ldap }}</el-checkbox>
        <div style="margin-top: 10px">
          <div v-html="slotProps.esiaText"></div>
          <el-link v-if="esia && !slotProps.hideEsia" @click="esiaLogin" type="primary">{{ $locale.login.esia }}
            <i v-show="loadingEsia" class="el-icon-view el-icon-loading"></i>
          </el-link>
        </div>
        <el-form-item>
          <el-button
            :loading="loading"
            class="loginPage_btn"
            native-type="submit"
            :disabled="!!slotProps.disabledSubmitBtn"
          >{{ $locale.login.submit }}
          </el-button>
        </el-form-item>
        <div v-if="notVerified">
          <span style="display: block; text-align: center">{{ $locale.login.verification_info }}</span>
          <el-form-item>
            <el-button
                :loading="loading"
                class="loginPage_btn"
                style="background: #ff4500"
                @click="verifyUser"
            >{{ $locale.login.verification }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-alert v-show="error" :title="error" type="error" :closable="false" show-icon></el-alert>
      <SMSAuthentication
        v-if="twoFactorAuthType === 'sms'"
        @close="twoFactorAuthType = null"
        :sms-auth-data="twoFactorAuthData"
      ></SMSAuthentication>
      <TOTPAuthentication
        v-if="twoFactorAuthType === 'totp'"
        @close="twoFactorAuthType = null"
        :auth-data="twoFactorAuthData"
      ></TOTPAuthentication>
      <el-drawer
        direction="btt"
        custom-class="drawer"
        :visible.sync="drawer"
        :close-on-press-escape="false"
        :show-close="false"
        :with-header="false"
        :wrapperClosable="false"
        >
        <div class="drawer__body">
          <div class="drawer__content">Настоящий ресурс использует сервис веб-аналитики Яндекс Метрика,
          предоставляемый компанией ООО «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16 (далее — Яндекс),
          сервис Яндекс Метрика использует файлы "cookie" с целью сбора технических данных посетителей для
          обеспечения работоспособности и улучшения качества обслуживания.
          Продолжая использовать ресурс, Вы автоматически соглашаетесь с использованием данных технологий.
          Собранная при помощи cookie информация не может идентифицировать вас, однако может помочь нам
          улучшить работу нашего сайта. Информация об использовании вами данного сайта, собранная при помощи cookie,
          будет передаваться Яндексу и храниться на сервере Яндекса в Российской Федерации.
          </div>
          <el-button style="margin-left: auto;" type="primary" @click="agreedCookie">Принимаю</el-button>
        </div>
      </el-drawer>
    </template>
  </component>
</template>

<script>
import defaultPage from './defaultPage/index.vue'
import Template from '@/components/EtlEditor/Properties/Template'
import SMSAuthentication from '@/components/LoginPage/SMSAuthentication'
import TOTPAuthentication from '@/components/LoginPage/TOTPAuthentication'
import _localStorage from '@/helpers'

export default {
  name: 'login-page',
  components: {
    TOTPAuthentication,
    SMSAuthentication,
    Template,
    defaultPage
  },
  data () {
    return {
      model: {
        login: null,
        password: null,
        ldap: !!this.$config.ldap_enabled
      },
      twoFactorAuthType: null,
      twoFactorAuthData: {},
      notVerified: false,
      error: null,
      loading: false,
      loadingEsia: false,
      component: null,
      drawer: false,
      rules: {
        login: {
          required: true,
          message: this.$locale.login.errors.login_required,
          trigger: 'change'
        },
        password: {
          required: true,
          message: this.$locale.login.errors.password_required,
          trigger: 'change'
        }
      }
    }
  },
  computed: {
    project () {
      return this.$config.project
    },
    esia () {
      return this.$config.esia_enabled
    },
    ldap () {
      return this.$config.ldap_enabled
    }
  },
  methods: {
    async esiaLogin () {
      this.loadingEsia = true
      await this.$store.dispatch(`Authorization/EsiaLogin`)
      this.loadingEsia = false
    },
    submit () {
      this.error = null
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const { login, password, ldap } = this.model
          let type = ldap ? 'LDAP' : 'default'
          this.$store.dispatch(`Authorization/${type}Login`, { login, password, ldap })
            .then((res) => {
              if (res?.two_factor_auth_type) {
                if (res.two_factor_auth_type === 'sms') {
                  this.twoFactorAuthType = 'sms'
                  this.twoFactorAuthData = res
                } else if (res.two_factor_auth_type === 'totp') {
                  this.twoFactorAuthType = 'totp'
                  this.twoFactorAuthData = res
                } else {
                  this.$router.push('/')
                }
              } else {
                this.$router.push('/')
              }
            })
            .catch((error) => {
              if (error.response?.data?.code === 'sms_verification_service_error') {
                this.error = this.$locale.login.errors.sms_auth_error
              } else if (error.response?.data?.code === 'user_blocked') {
                this.error = this.$locale.login.errors.user_blocked
              } else if (error.response?.data?.code === 'user_not_verified') {
                this.notVerified = true
              } else {
                this.error = this.$locale.login.errors.wrong_user
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    },
    showCookie () {
      const str = document.cookie
      const pattern = /is_cookies_agreed=true/
      const isAgreed = pattern.test(str)
      if (!isAgreed) {
        this.drawer = true
      }
    },
    agreedCookie () {
      // +1 день от текущей даты
      let oneDay = 86400e3
      let date = new Date(Date.now() + oneDay).toUTCString()
      document.cookie = `is_cookies_agreed = true; expires= ${date}`
      this.drawer = false
    },
    verifyUser () {
      this.$http.get(`${this.$config.api}/defaultauthentication/sendmail/${this.model.login}`).then((response) => {
        if (response.data.result == 'success') {
          console.log(response.data.result)
          this.$message({
            message: this.$locale.login.verification_ok,
            type: 'success'
          })
          this.notVerified = false
        } else {
          console.log(response.data.message)
          this.$message({
            message: this.$locale.login.verification_error,
            type: 'warning'
          })
        }
      })
    }
  },
  async mounted () {
    if (this.$config.user_cookie) {
      this.showCookie()
    }
    console.log(this.$config.project)
    _localStorage.clearExcept(['redirect_url', 'theme-link'])

    try {
      await submodule.getPage('LoginPage')
      this.component = () => submodule.getPage('LoginPage')
    } catch (e) {
      try {
        await import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
        this.component = () => import(`@/plugins/${this.$config.project}/infrastructure/components/LoginPage`)
      } catch (e) {
        try {
          await import(`@/components/LoginPage/${this.$config.project}`)
          this.component = () => import(`@/components/LoginPage/${this.$config.project}`)
        } catch (error) {
          this.component = 'defaultPage'
          console.log(error)
        }
      }
    }
  }
}
</script>

<style>
.loginPage_wrapper {
  display: flex;
  height: 100%;
  background: #e5e5e5;
}

.loginPage_box {
  margin: auto;
  width: 380px;
  height: auto;
}

.loginPage_logo {
  height: 68px;
}

.loginPage_title {
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 21px;
  line-height: 24px;
  color: #2c2d35;
}

.loginPage_btn {
  width: 100%;
  margin-top: 28px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  background: #2139C4;
  line-height: 25px;
}

.loginPage_btn:hover,
.loginPage_btn:active,
.loginPage_btn:focus {
  background: #2139C4;
  color: #ffffff;
}

.loginPage_input .el-input__inner {
  height: 50px;
  font-size: 15px;
  line-height: 50px;
}

.project_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  /* background: #F4F5F6; */
}

.project_title {
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
  line-height: 20px;
  color: #004099;
}

.PublicHearing_background {
  background: url(./PublicHearing/resources/background.jpg) no-repeat;
}

.Rgis_background {
  background: url(./Rgis/resources/background.jpg) no-repeat;
  background-size: cover;
}
.drawer {
  height: auto !important;
  background: aliceblue;
}
.drawer__body {
  display: flex;
  font-size: 1.2rem;
  height: auto !important;
  padding: 1.2rem;
  align-items: center;
}
.drawer__content {
  color: gray;
}

@media screen and (max-width: 750px) {
    .drawer__body {
       font-size: 1rem;
    }
}
</style>
