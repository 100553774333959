<template>
  <div class="component-preview">
    <h4>Alert</h4>

    <div>
      <el-alert
        title="success alert"
        description="more text description"
        type="success"
        show-icon
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="info alert"
        description="more text description"
        type="info"
        show-icon
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="warning alert"
        description="more text description"
        type="warning"
        show-icon
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="error alert"
        description="more text description"
        type="error"
        show-icon
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="success alert"
        description="more text description"
        type="success"
        show-icon
        effect="dark"
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="info alert"
        description="more text description"
        type="info"
        show-icon
        effect="dark"
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="warning alert"
        description="more text description"
        type="warning"
        show-icon
        effect="dark"
      ></el-alert>
    </div>

    <div style="margin-top: 20px;">
      <el-alert
        title="error alert"
        description="more text description"
        type="error"
        show-icon
        effect="dark"
      ></el-alert>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AlertPreview',

  data () {
    return {
      checkboxGroupA1: ['Shanghai'],
      checkboxGroupA2: ['Shanghai'],
      checkboxGroupA3: ['Shanghai'],
      checkboxGroupA4: ['Shanghai'],
      cities: ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen'],
      checked1: true,
      checked2: false,
      checked3: false,
      checked4: true,
      checkboxGroupB1: [],
      checkboxGroupB2: []
    }
  }
})
</script>
