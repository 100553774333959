<template>
  <div class="component-preview">
    <h4>Radio Button</h4>

    <div>
      <el-radio v-model="radio" label="1">Option A</el-radio>

      <el-radio v-model="radio" label="2">Option B</el-radio>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio1">
        <el-radio-button label="New York"></el-radio-button>
        <el-radio-button label="Washington"></el-radio-button>
        <el-radio-button label="Los Angeles"></el-radio-button>
        <el-radio-button label="Chicago"></el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio1">
        <el-radio-button label="New York"></el-radio-button>
        <el-radio-button label="Washington"></el-radio-button>
        <el-radio-button label="Los Angeles"></el-radio-button>
        <el-radio-button label="Chicago"></el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio2" size="medium">
        <el-radio-button label="New York" ></el-radio-button>
        <el-radio-button label="Washington"></el-radio-button>
        <el-radio-button label="Los Angeles"></el-radio-button>
        <el-radio-button label="Chicago"></el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio3" size="small">
        <el-radio-button label="New York"></el-radio-button>
        <el-radio-button label="Washington" disabled ></el-radio-button>
        <el-radio-button label="Los Angeles"></el-radio-button>
        <el-radio-button label="Chicago"></el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio4" disabled size="mini">
        <el-radio-button label="New York"></el-radio-button>
        <el-radio-button label="Washington"></el-radio-button>
        <el-radio-button label="Los Angeles"></el-radio-button>
        <el-radio-button label="Chicago"></el-radio-button>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio v-model="radio1" label="New York" border></el-radio>
      <el-radio v-model="radio1" label="Washington" border></el-radio>
    </div>

    <div style="margin-top: 20px">
      <el-radio v-model="radio2" label="New York" border size="medium"></el-radio>
      <el-radio v-model="radio2" label="Washington" border size="medium"></el-radio>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio3" size="small">
        <el-radio label="New York" border></el-radio>
        <el-radio label="Washington" border disabled></el-radio>
      </el-radio-group>
    </div>

    <div style="margin-top: 20px">
      <el-radio-group v-model="radio4" size="mini" disabled>
        <el-radio label="New York" border></el-radio>
        <el-radio label="Washington" border></el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'RadioPreview',

  data () {
    return {
      radio: '1',

      radio1: 'New York',
      radio2: 'New York',
      radio3: 'New York',
      radio4: 'New York'
    }
  }
})
</script>
