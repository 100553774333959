export default class RasterCreateCommand
{
    private formData: FormData;

    constructor(formData: FormData)
    {
        this.formData = formData;
    }

    getClassName(): string
    {
        return 'RasterCreateCommand';
    }

    getFormData(): FormData
    {
        return this.formData;
    }
}