<template>
  <div class="select-panel" ref="raster_panel">
    <el-container class="tools">
      <el-tooltip class="item" effect="dark" :content="$locale.main.button.add" placement="top">
        <el-button size="mini" icon="el-icon-plus" circle @click="addEditRaster('add')"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.raster_library.select_from_folder" placement="top">
        <el-button size="mini" icon="el-icon-folder" circle @click="showRasterFolderTree"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.main.button.edit" placement="top">
        <el-button :disabled="raster == null" icon="el-icon-edit" size="mini" circle  @click="addEditRaster('edit')"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.main.button.delete" placement="top">
        <el-button :disabled="raster == null && this.multipleSelection.length == 0" icon="el-icon-delete" size="mini" circle @click="deleteRaster"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.main.button.update" placement="top">
        <el-button icon="el-icon-refresh" size="mini" circle @click="refreshRasters"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.raster_library.publish" placement="top">
        <el-button :disabled="raster == null" size="mini" icon="el-icon-upload" circle @click="showRasterPublishForm(true)"></el-button>
      </el-tooltip>
      <span class="accent-splitter">|</span>
      <el-tooltip class="item" effect="dark" :content="$locale.raster_library.create_cog" placement="top">
        <el-button :disabled="raster == null" icon="el-icon-cloudy" size="mini" circle @click="createCOG"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" :content="$locale.raster_library.jobs" placement="top">
        <el-button icon="el-icon-tickets" size="mini" circle @click="showRasterJobPanel(true)"></el-button>
      </el-tooltip>
      <el-input
        :placeholder="$locale.raster_library.search_by_name"
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchByName">
      </el-input>
      <el-input
        :placeholder="$locale.raster_library.search_by_name"
        prefix-icon="el-icon-search"
        v-model="searchName1"
        @keyup.enter.native="searchByName1">
      </el-input>
    </el-container>  
    <el-row class="raster-grid">
      <el-col :style="rasterInfoShow && raster !== null ? 'height: 90%; width: 70%;' : 'height: 90%;'">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="rasters"
          stripe
          border
          ref="rasters_table"
          row-key="id"
          current-row-key="id"
          highlight-current-row
          :row-style="rowStyle"
          @current-change="changeRaster"
          @selection-change="selectionChange"
          @row-dblclick="rowClick"
        >
        <!-- <el-table-column
          type="selection"
          width="55">
        </el-table-column> -->
        <el-table-column
          prop="id"
          :label="$locale.raster_library.id"
          sortable
          width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.raster_library.name"
          sortable
          width="340"
        ></el-table-column>
        <el-table-column
          prop="description"
          :label="$locale.raster_library.description"
          width="340"
        ></el-table-column>
        <el-table-column
          prop="format"
          :label="$locale.raster_library.format"
          sortable
          width="120"
        ></el-table-column>
       <el-table-column
          :label="$locale.raster_library.size"
          width="130"
        >
          <template slot-scope="prop">
            {{ prop.row.width + 'x' + prop.row.height }}
          </template>
        </el-table-column>
        <el-table-column
          prop="data_type"
          :label="$locale.raster_library.data_type"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="create_date"
          :label="$locale.raster_library.create_date"
          sortable
          width="160"
        >
          <template slot-scope="prop">
            {{ prop.row.create_date | moment("DD.MM.Y H:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="edit_date"
          :label="$locale.raster_library.edit_date"
          sortable
          width="160"
        >
          <template slot-scope="prop">
            {{ prop.row.edit_date == '0001-01-01T00:00:00' ? '' : prop.row.edit_date | moment("DD.MM.Y H:mm:ss") }}
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            :page-size="pageLimit"
            layout="total, prev, pager, next"
            :total="count"
            @current-change="handlePageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
      <el-col 
        v-if="!rasterInfoShow ? rasterInfoShow : raster !== null" 
        :style="rasterInfoShow && raster !== null ? 'height: 100%; width: 30%;' : 'height: 100%;'">
        <RasterInformationPanel class="information custom_scrollbar" :raster="raster" />
      </el-col>
    </el-row>
    <RasterForm v-if="rasterFormShow" :type="rasterFormType" :raster="raster" />
    <RasterPublishForm v-if="rasterPublishFormShow" :raster="raster" />
    <RasterFolderTree v-if="rasterFolderTreeShow" />
    <RasterJobPanel v-if="rasterJobPanelShow" />
  </div>
</template>

<script type="ts">
import RasterForm from '@/services/RasterLibrary/infrastructure/components/RasterForm/index.vue'
import RasterPublishForm from '@/services/RasterLibrary/infrastructure/components/RasterPublishForm/index.vue'
import RasterFolderTree from '@/services/RasterLibrary/infrastructure/components/RasterFolderTree/index.vue'
import RasterInformationPanel from '@/services/RasterLibrary/infrastructure/components/RasterInformationPanel/index.vue'
import RasterJobPanel from '@/services/RasterLibrary/infrastructure/components/RasterJobPanel/index.vue'
import RastersQuery from '@/services/RasterLibrary/application/query/RastersQuery'
import RastersCountQuery from '@/services/RasterLibrary/application/query/RastersCountQuery'
import RasterDeleteCommand from '@/services/RasterLibrary/application/command/RasterDeleteCommand'
import Raster from '@/services/RasterLibrary/domain/model/Raster/Raster'

import Vue from 'vue'
import 'moment/locale/ru'
const moment = require('moment');
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(require('vue-moment'));

export default {
  name: 'RasterPanel',
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  components: {
    RasterForm,
    RasterInformationPanel,
    RasterJobPanel,
    RasterPublishForm,
    RasterFolderTree
  },
  data() {
    return {
      loading: false,
      pageLimit: 10,
      pageOffset: 1,
      count: 0,
      rasters: [],
      raster: null,
      multipleSelection: [],
      searchName: '',
      searchName1: '',
      rasterInfoShow: false,
      rasterFormShow: false,
      rasterPublishFormShow: false,
      rasterFolderTreeShow: false,
      rasterFormType: 'add',
      rasterJobPanelShow: false,
      //hubConnection: null
    }
  },
  computed: {
    rasterCreated() {
      return this.$store.getters['Raster/getLocation'];
    }
  },
  methods: {
    showRasterForm(show) {
      this.rasterFormShow = show;
    },
    showRasterPublishForm(show) {
      this.rasterPublishFormShow = show;
    },
    showRasterFolderTree(show) {
      this.rasterFolderTreeShow = show;
    },
    showRasterInfo(show) {
      this.rasterInfoShow = show;
    },
    showRasterJobPanel(show) {
      this.rasterJobPanelShow = show;
    },
    addEditRaster(type) {
      if (type == 'edit' && this.raster == null) {
        return;
      }
      this.rasterFormType = type;
      this.rasterFormShow = true;
    },
    deleteRaster() {
      const deleting = this.multipleSelection.length == 0 ?  [this.raster.getAllProperties()] : this.multipleSelection;
      if (deleting.length == 0) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        })
        .then(async () => {
          let deletingGuids = [];
          deleting.forEach((item) => {
            deletingGuids.push(item.guid);
          });
          this.getCommandBus().execute(new RasterDeleteCommand(deletingGuids[0], {RasterIds: deletingGuids}))
          .then((response) => {
            this.raster = null;
            this.$refs.rasters_table.setCurrentRow(null);
            this.rasterInfoShow = false;
            this.loadRasters(true);
          })
          .catch((error) => { console.log(error); });
        });
      }
    },
    async refreshRasters() {
      await this.loadRasters();
      this.$message({
        showClose: true,
        message: this.$locale.raster_library.success_refresh,
        type: 'success'
      });
    },
    changeRaster(rasterDto) {
      this.raster = Raster.create(rasterDto);
    },
    selectionChange(val) {
      this.multipleSelection = val;
    },
    rowClick() {
      this.showRasterInfo(true);
    },
    rowStyle({row}) {
      if (!row.is_valid) {
        return {'background-color': 'rgba(255, 0, 0, 0.2)'};
      }
      return {};
    },
    /* tableRowClassName({row}) {
      if (!row.is_valid) {
        return 'invalid-row';
      }
      return '';
    }, */
    handlePageChange(val) { console.log("handlePageChange", val);
      this.pageOffset = (val - 1) * this.pageLimit + 1;
      this.loadRasters();
    },
    async loadRasters(forceCount) {
      this.loading = true;
      if (this.count == 0 || forceCount) {
        await this.countAndLoadRasters();
      } else {
        await this.loadAll();
      }
      this.loading = false;
    },
    async countAndLoadRasters() {
      const data = await this.getQueryBus().execute(new RastersCountQuery());
      this.count = data.count;      
      this.loadAll();
    },
    async loadAll() {
      let payload = {
        limit: this.pageLimit,
        offset: this.pageOffset
      };
      const data = await this.getQueryBus().execute(new RastersQuery(payload));
      this.rasters = data;
    },
    async searchByName() {
      let payload = {};
      if (/* this.searchName &&  */this.searchName.length > 0) {
        payload['name'] = this.searchName;        
      } else {
        payload["limit"] = this.pageLimit;
        payload["offset"] = this.pageOffset;
      }
      const data = await this.getQueryBus().execute(new RastersQuery(payload))
      if (Array.isArray(data) && data.length) {
        this.rasters = data;
      } else {
        this.$message({
          showClose: true,
          message: this.$locale.raster_library.warning_rasters_not_found,
          type: 'warning'
        });
      }
    },
    async searchByName1() {
      let payload = {};
      if (/* this.searchName1 &&  */this.searchName1.length > 0) {
        payload['name1'] = this.searchName1;        
      } else {
        payload["limit"] = this.pageLimit;
        payload["offset"] = this.pageOffset;
      }
      const data = await this.getQueryBus().execute(new RastersQuery(payload))
      if (Array.isArray(data) && data.length) {
        this.rasters = data;
      } else {
        this.$message({
          showClose: true,
          message: this.$locale.raster_library.warning_rasters_not_found,
          type: 'warning'
        });
      }
    },
    /* setHubConnection(connection) {
      this.hubConnection = connection;
    }, */
    createCOG() {
      if (this.raster == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
        return;
      }    
    }
  },
  mounted() {
    this.loadRasters();
    this.getEventBus().$on('showRasterForm', this.showRasterForm);
    this.getEventBus().$on('showRasterPublishForm', this.showRasterPublishForm);
    this.getEventBus().$on('showRasterFolderTree', this.showRasterFolderTree);
    this.getEventBus().$on('showRasterInfoPanel', this.showRasterInfo);
    this.getEventBus().$on('showRasterJobPanel', this.showRasterJobPanel);   
    this.getEventBus().$on('loadRasters', this.loadRasters);
    //this.getEventBus().$on('setHubConnection', this.setHubConnection);
  }
}
</script>