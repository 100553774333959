<template>
    <span
      :class="{
      'reference': true,
      'icon_button': true,
      'disabled': disabled,
      'mini': mini
      }"
      @click="$emit('click')"
    ><font-awesome-icon :icon="icon" v-if="icon"></font-awesome-icon>
      <span v-if="(title || '').length" class="label" style="margin-left: 5px">{{ title }}</span>
      </span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SimpleButton',
  inject: ['getEventBus'],
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {},
    title: String,
    mini: {
      type: Boolean,
      default: false
    }
  }
}
</script>
