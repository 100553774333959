

export default {
  name: 'WorkerQueuePanel',
  data () {
    return {
      loading: false,
      workerQueuesPageSize: 100,
      workerQueuesCount: 0,
      allWorkerQueuesCount: 0,
      workerQueues: [],
      workerQueue: null
    }
  },
  inject: ['getQueryBus', 'getCommandBus'],
  methods: {
    async loadWorkerQueues() {
      let data = await this.$http.get(`${this.$config.api}/databusservice/utilities/worker/queue`);
      this.workerQueues = data.data.records;
      this.workerQueuesCount = data.length;
    },
    async restartRecord(guid) {
      let data = await this.$http.post(`${this.$config.api}/databusservice/utilities/worker/queue/restart/${guid}`);
      this.loadWorkerQueues();
    }
  },
  mounted () {
    this.loadWorkerQueues();
  }
}
