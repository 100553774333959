<template>
  <el-dialog
    class="saveTemplate"
    title="Сохранить шаблон"
    :visible.sync="showSaveTemplate"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="30%"
  >
    <div>
      <el-radio-group v-model="container" size="mini">
        <el-radio-button label="all">Сохранить все</el-radio-button>
        <el-radio-button :disabled="isDisabledRadioBtn" label="notAll"
          >Сохранить активный контейнер</el-radio-button
        >
      </el-radio-group>
    </div>
    <el-form
      ref="form"
      :label-position="'top'"
      :model="form"
      label-width="120px"
      size="mini"
      style="margin-top: 20px"
    >
      <el-form-item
        label="Название"
        prop="name"
        :rules="[
          { required: true, message: 'Введите название', trigger: 'blur' },
        ]"
      >
        <el-input ref="nameInput" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item
        :label="this.$locale.main.label_input.image"
        label-width="180px"
      >
        <el-upload
          ref="upload"
          action=""
          list-type="picture-card"
          :on-change="changeImage"
          :on-remove="removeImage"
          :file-list="images"
          :auto-upload="false"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel" size="small">Отмена</el-button>
      <el-button
        type="primary"
        :disabled="isDisabledBtnSave"
        @click="saveTemplate"
        size="small"
        >Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'saveTemplate',
  props: {
    showSaveTemplate: {
      type: Boolean
    },
    activeBlock: {
      type: Object
    }
  },
  computed: {
    isDisabledBtnSave () {
      if (this.form.name) return false
      return true
    },
    isDisabledRadioBtn () {
      if (this.activeBlock && Object.keys(this.activeBlock).length) return false
      return true
    }
  },
  data () {
    return {
      container: 'all',
      images: [],
      form: {
        name: null,
        image: {}
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.nameInput.focus()
    })
  },
  methods: {
    cancel () {
      this.$emit('update:showSaveTemplate', false)
    },
    async saveTemplate () {
      let file
      if (this.$refs.upload.uploadFiles.length) {
        file = this.$refs.upload.uploadFiles[0].raw
      }
      if (file) {
        try {
          await this.saveImage(file)
        } catch (error) {
          console.log('ошибка сохранения', error)
        }
      }
      this.$emit('save-temlate-to-report-editor', { form: this.form, container: this.container })
      this.$emit('update:showSaveTemplate', false)
    },
    changeImage () {
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
      // try {
      //   this.form.image = await this.toBase64(file)
      // } catch (error) {
      //   console.log('ошибка чтения', error)
      // }
    },
    async saveImage (file) {
      const fd = new FormData()
      fd.append('file', file)
      let res = await this.$http.post(this.$config.api + '/registryservice/files', fd, { hideNotification: true })
      this.form.image = { file_id: res.data.id, filename: res.data.filename }
    },
    // перевести изображение в base64
    // toBase64 (file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.addEventListener('load', () => {
    //       return resolve(reader.result)
    //     }, { once: true })
    //   })
    // },
    removeImage () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      this.form.image = null
      this.images = []
    }
  }
}
</script>
<style lang="scss" scoped>
.saveTemplate ::v-deep .el-form-item--mini .el-form-item__error {
  left: 4%;
}
</style>
