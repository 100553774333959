import LayerTreeElementCommandRepositoryInterface from '@/services/MapEditor/domain/repository/LayerTreeElementCommandRepositoryInterface'
import store from '@/store'
import { LayerTreeElementDTO } from '@/services/MapEditor/domain/model/Layer/LayerTreeElement'
import { DeleteLayerTreeElement, UpdateParent, PutLayerTreeLayerElement } from '@/services/MapEditor/infrastructure/store/modules/LayerTreeElement/action-types'

export default class LayerTreeElementCommandRepository implements LayerTreeElementCommandRepositoryInterface
{
    delete(guid: string, payload: string[][]): Promise<void>
    {
        return store.dispatch(new DeleteLayerTreeElement(guid, payload));
    }

    updateParent(item: LayerTreeElementDTO): Promise<void>
    {
        return store.dispatch(new UpdateParent(item));
    }

    putLayer(item: LayerTreeElementDTO): Promise<void>
    {
        return store.dispatch(new PutLayerTreeLayerElement(item));
    }
}
