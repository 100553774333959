import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class FormulaUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private formulaTypeId: string
  private roleId: Nullable<number>
  private entityId: Nullable<number>
  private sqlStatements: Nullable<object>
  private dslStatement: Nullable<string>
  private isDsl: boolean
  private isStored: boolean
  private isBasic: boolean
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    formulaTypeId: string,
    isDsl: boolean,
    isStored: boolean,
    isBasic: boolean,
    roleId: Nullable<number>,
    entityId: Nullable<number>,
    sqlStatements: Nullable<object>,
    dslStatement: Nullable<string>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.formulaTypeId = formulaTypeId
    this.roleId = roleId
    this.entityId = entityId
    this.sqlStatements = sqlStatements
    this.dslStatement = dslStatement
    this.isDsl = isDsl
    this.isStored = isStored
    this.isBasic = isBasic
    this.alias = alias
  }

  getClassName (): string {
    return 'FormulaUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getFormulaTypeId (): string {
    return this.formulaTypeId
  }

  getRoleId (): Nullable<number> {
    return this.roleId
  }

  getEntityId (): Nullable<number> {
    return this.entityId
  }

  getSqlStatements (): Nullable<object> {
    return this.sqlStatements
  }

  getDslStatement (): Nullable<string> {
    return this.dslStatement
  }

  getIsDsl (): boolean {
    return this.isDsl
  }

  getIsStored (): boolean {
    return this.isStored
  }

  getIsBasic (): boolean {
    return this.isBasic
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
