import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'
import NameTooLong from '@/core/domain/exception/NameTooLong'
import NameMustNotBeEmpty from '@/core/domain/exception/NameMustNotBeEmpty'

export class SettingDTO
{
    id?: Nullable<number>;
    name?: Nullable<string>;
    value?: Nullable<string>;
    description?: Nullable<string>;

    constructor({ id, name, value, description}: {id?: Nullable<number>, name?: Nullable<string>, value?: Nullable<string>, description?: Nullable<string>}) {
        this.id = id;
        this.name = name;
        this.value = value;
        this.description = description;
    }
}

export default class Setting extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private name: string;
    private value: string;
    private description: Nullable<string>;

    constructor(
        id: Nullable<number>,
        name: string,
        value: string,
        description: Nullable<string>
    ) {
        super();
        this.id = id;
        this.name = name;
        this.value = value;
        this.description = description;
    }

    static create(dto: SettingDTO): Setting
    {
        return new Setting(
            dto.id,
            dto.name,
            dto.value,
            dto.description
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setName(name: string): void
    {
        if (name.length > 255) {
            throw new NameTooLong();
        }
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }

    assertInvariants(): void
    {
        if (this.name.length == 0) {
            throw new NameMustNotBeEmpty();
        }

        if (this.name.length > 255) {
            throw new NameTooLong();
        }
    }

    setValue(value: string): void
    {
        this.value = value;
    }

    getValue(): string
    {
        return this.value;
    }

    setDescription(description: string): void
    {
        this.description = description;
    }

    getDescription(): string
    {
        return this.description;
    }

}
