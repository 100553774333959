<template>
  <el-dialog :title="localValue.title" :visible.sync="localValue.show" :before-close="cancel" :close-on-click-modal="false">
    <component
      ref="form"
      :is="localValue.component"
      v-model="localValue.model"
      :title="localValue.title"
      :cancel="cancel"
      :save="save"
      :hide-mapping="hideMapping"
      :hide-extra-tasks="true"
      :show-type="true"
      :in-modal="true">
    </component>

    <span slot="footer" class="dialog-footer">
      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('etl_editor.tooltip.cancel')" placement="top">
        <el-button @click="cancel" size="small">{{ $t('main.button.cancel') }}</el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" :content="$t('etl_editor.tooltip.save')" placement="top">
        <el-button :open-delay="300" type="primary" @click="submit" size="small">
          <span class="el-icon-success"></span> {{ $t('main.button.save') }}
        </el-button>
      </el-tooltip>
    </span>
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import LoaderCreate from '../render/forms/LoaderCreate'
import LoaderUpdate from '../render/forms/LoaderUpdate'
import ExtractorCreate from '../render/forms/ExtractorCreate'
import ExtractorUpdate from '../render/forms/ExtractorUpdate'
import TransformerCreate from '../render/forms/TransformerCreate'
import TransformerUpdate from '../render/forms/TransformerUpdate'
import TaskUpdate from '../render/forms/TaskUpdate'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'DialogForm',

  props: {
    value: Object,

    cancel: {
      type: Function,
      default: function () {
      }
    },

    save: {
      type: Function,
      default: async function (isNew) {
      }
    },

    hideMapping: {
      type: Boolean,
      default: true
    }
  },

  components: {
    LoaderCreate,
    LoaderUpdate,
    ExtractorCreate,
    ExtractorUpdate,
    TransformerCreate,
    TransformerUpdate,
    TaskUpdate
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.localValue)) {
          this.localValue = cloneObject(value)
        }
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    }
  },

  data () {
    return {
      localValue: this.value
    }
  },

  methods: {
    submit () {
      this.$refs.form.submit(this.save)
    }
  }
})
</script>
