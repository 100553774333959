import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import ConnectionQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/ConnectionQueryRepositoryInterface'
import store from '@/store'
import { GetConnections, GetCountConnections } from '@/services/AccessEditor/infrastructure/store/modules/Connection/action-types'

export default class ConnectionQueryRepository extends AbstractQueryRepository implements ConnectionQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetConnections(payload))
  }

  getCount (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetCountConnections(payload))
  }
}
