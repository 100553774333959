
import Vue from 'vue'
import InputColor from '@/services/CssEditor/infrastructure/components/InputColor.vue'
import InputSize from '@/services/CssEditor/infrastructure/components/InputSize.vue'
import InputShadow from '@/services/CssEditor/infrastructure/components/input-shadow/InputShadow.vue'
import InputBorderRadius from '@/services/CssEditor/infrastructure/components/InputBorderRadius.vue'
import InputWeight from '@/services/CssEditor/infrastructure/components/InputWeight.vue'
import ThemeVariables from '@/services/CssEditor/infrastructure/components/ThemeVariables.json'
import ThemeVariableGroups from '@/services/CssEditor/infrastructure/components/ThemeVariableGroups.json'
import ScssVariableViewer from '@/services/CssEditor/infrastructure/components/ScssVariableViewer.vue'
import ColorPreview from '@/services/CssEditor/infrastructure/components/ColorPreview.vue'
import TypographyPreview from '@/services/CssEditor/infrastructure/components/TypographyPreview.vue'
import RadioPreview from '@/services/CssEditor/infrastructure/components/RadioPreview.vue'
import ButtonPreview from '@/services/CssEditor/infrastructure/components/ButtonPreview.vue'
import CheckboxPreview from '@/services/CssEditor/infrastructure/components/CheckboxPreview.vue'
import InputPreview from '@/services/CssEditor/infrastructure/components/InputPreview.vue'
import AlertPreview from '@/services/CssEditor/infrastructure/components/AlertPreview.vue'
import { defaultProperties } from '@/services/CssEditor/domain/model/Theme'

export interface IThemeVariable {
  label: string;
  variable: string;
  defaultValue: any;
  group: string;
  subGroup: string;
  type: string;
  isEditable: boolean;
}

export interface IDataGroups {
  label: string;
  value: string;
  children?: IDataGroups[];
}

export interface IDataHistory {
  variable: string;
  newValue: any;
  oldValue: any;
}

export interface IData {
  group: string;
  groups: IDataGroups[];
  mappedComponents: {
    [type: string]: {
      name: string,
      variables: {
        label: string,
        value: string
      }[]
    };
  },
  modelValue: {
    id?: number;
    guid?: string;
    name: string;
    properties: {
      [variable: string]: any;
    }
  },
  themeVariables: IThemeVariable[];
  historyUndo: IDataHistory[];
  historyRedo: IDataHistory[];
  lockChange: boolean;
}

export default Vue.extend({
  name: 'ThemeForm',

  components: {
    AlertPreview,
    InputColor,
    InputSize,
    InputShadow,
    InputBorderRadius,
    ScssVariableViewer,
    InputWeight,
    ColorPreview,
    TypographyPreview,
    RadioPreview,
    ButtonPreview,
    CheckboxPreview,
    InputPreview
  },

  props: {
    value: Object
  },

  computed: {
    activeVariables (): IThemeVariable[] {
      return this.themeVariables.filter(tv => tv.group === this.group)
    },

    subGroups () {
      return this.groups.find(g => g.value === this.group)?.children || []
    },

    disabledUndo () {
      return this.historyUndo.length < 1
    },

    disabledRedo () {
      return this.historyRedo.length < 1
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.modelValue)) {
          this.modelValue = value
        }
      }
    },

    modelValue: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      }
    }
  },

  data (): IData {
    return {
      group: 'color',

      groups: ThemeVariableGroups,

      mappedComponents: {
        color: {
          name: 'InputColor',
          variables: ThemeVariables
            .filter(v => v.group === 'color')
            .map(v => ({ label: v.label, value: v.variable }))
        },
        font_size: {
          name: 'InputSize',
          variables: ThemeVariables
            .filter(v => v.group === 'typography' && v.subGroup === 'font_size')
            .map(v => ({ label: v.label, value: v.variable }))
        },
        border_radius: {
          name: 'InputBorderRadius',
          variables: ThemeVariables
            .filter(v => v.group === 'border' && v.subGroup === 'radius')
            .map(v => ({ label: v.label, value: v.variable }))
        },
        box_shadow: {
          name: 'InputShadow',
          variables: ThemeVariables
            .filter(v => v.group === 'border' && v.subGroup === 'shadow')
            .map(v => ({ label: v.label, value: v.variable }))
        },
        font_weight: {
          name: 'InputWeight',
          variables: ThemeVariables
            .filter(v => v.group === 'typography' && v.subGroup === 'font_weight')
            .map(v => ({ label: v.label, value: v.variable }))
        },
        line_height: {
          name: 'InputSize',
          variables: []
        },
        input_string: {
          name: 'el-input',
          variables: []
        }
      },

      modelValue: {
        id: 1,
        name: '123',
        properties: defaultProperties
      },

      themeVariables: ThemeVariables,

      historyUndo: [],
      historyRedo: [],
      lockChange: false
    }
  },

  mounted () {
    this.modelValue = this.value
  },

  methods: {
    handleChangeVariable (variable: string, [newValue, oldValue]) {
      // const [newValue, oldValue] = value

      console.log(newValue, oldValue)

      // if (this.lockChange) {
      //   this.lockChange = false
      //
      //   return
      // }

      this.historyUndo.push({
        variable,
        newValue,
        oldValue
      })

      this.historyRedo = []
    },

    handleUndo () {
      if (!this.historyUndo.length) {
        return
      }

      const lastIndex = this.historyUndo.length - 1
      const history = this.historyUndo[lastIndex]

      this.historyRedo.push(history)

      this.historyUndo.splice(lastIndex, 1)

      this.lockChange = true

      this.modelValue.properties[history.variable] = history.oldValue
    },

    handleRedo () {
      if (!this.historyRedo.length) {
        return
      }

      const lastIndex = this.historyRedo.length - 1
      const history = this.historyRedo[lastIndex]

      this.historyUndo.push(history)

      this.historyRedo.splice(lastIndex, 1)

      this.lockChange = true

      this.modelValue.properties[history.variable] = history.newValue
    }
  }
})
