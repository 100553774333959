<template>

  <div class="loginPage_wrapper">
    <el-card class="loginPage_box">
      <div class="project_logo">
        <div class="loginPage_logo"></div>
      </div>
      <slot name="loginForm"></slot>
    </el-card>
  </div>

</template>

<script>
export default {
  name: 'login-default'
}
</script>

<style lang="scss" scoped>

</style>
