
export interface IRequest {
  id?: number;
  guid?: string;
  name: string;
  row_order: number;
  parent_id?: number;
  element_type?: string;
  protocol_type?: string;
  request_type?: string;
  method_type_id?: string;
  url: string;
  connection_id?: number;
  topic_id?: number;
  properties?: object[][];
}

export class Request {
  id?: number;
  guid?: string;
  name: string;
  row_order: number;
  parent_id?: number;
  element_type?: string;
  protocol_type?: string;
  request_type?: string;
  method_type_id?: string;
  url: string;
  connection_id?: number;
  topic_id?: number;
  properties?: object[][];

  /**
   * Заполнение данными
   *
   * @param data
   */
  constructor (data: IRequest) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.row_order = data.row_order
    this.parent_id = data.parent_id
    this.element_type = data.element_type
    this.protocol_type = data.protocol_type
    this.request_type = data.request_type
    this.method_type_id = data.method_type_id
    this.url = data.url
    this.connection_id = data.connection_id
    this.topic_id = data.topic_id
    this.properties = data.properties
  }

  /**
   * Создать пустую модель
   */
  static create () {
    return new Request({
      name: '',
      row_order: 0,
      element_type: '',
      protocol_type: '',
      request_type: '',
      method_type_id: '',
      url: ''
    })
  }
}
