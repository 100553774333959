<template>
  <el-tree
    class="xsd-parser"
    ref="tree"
    node-key="id"
    show-checkbox
    check-strictly
    :data="data.file_list"
    :props="treeProps"
    :default-expand-all="true"
    @check-change="checkNode"
  ></el-tree>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'XsdParser',

  props: {
    value: Array,

    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      treeProps: {
        label: 'name',
        disabled: function (data) {
          return !data.is_leaf
        }
      },
      checkedNodeId: 0
    }
  },

  methods: {
    checkNode (node, checked) {
      if (checked && !this.checkedNodeId) {
        // this.$refs.tree.setCheckedKeys([node.id])

        this
          .$confirm(`Вы действительно хотите импортировать файл "${node.name}"?`, 'Подтверждение',
            {
              confirmButtonText: this.$t('main.button.yes'),
              cancelButtonText: this.$t('main.button.no'),
              type: 'warning'
            })
          .then(() => {
            this.$http
              .post(`${this.$config.api}/xsd2jsonconverter/xsdservice/zip_parse`, {
                guid: this.data.guid,
                filename: node.name
              })
              .then((response) => {
                this.$emit('input', response.data.json)
                this.checkedNodeId = node.id
                this.$refs.tree.setCheckedKeys([node.id])
              })
              .catch(() => {
                this.$refs.tree.setCheckedKeys([this.checkedNodeId])
              })
          })
          .catch(() => {
            this.checkedNodeId = 0
            this.$refs.tree.setCheckedKeys([])
          })
      }

      if ((checked || !checked) && this.checkedNodeId) {
        this.$refs.tree.setCheckedKeys([this.checkedNodeId])
      }
    }
  }
})
</script>

<style scoped>
.xsd-parser {
  margin-top: 15px;
}
</style>
