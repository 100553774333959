export default class ApprovalStage {
  public approveCommandElements: any[] = []
  public cancelCommandElements: any[] = []

  public name: string
  public id?: number
  public guid?: string

  public cancelGateway: any
  public approveGateway: any
  public element: any
  public lastElement: any
  public lastCancelElement: any
  public blockedStages: any[] = []
  public cancelStageElement: any
  public returnStageGuid?: string

  public constructor (name: string) {
    this.name = name
  }

  public isParallelCancelGateway () {
    return this.cancelCommandElements.length > 1
  }

  public isParallelApproveGateway () {
    return this.approveCommandElements.length > 1
  }

  public getApproveCommandsGuid () {
    return this.approveCommandElements.map((item) => item.businessObject.accentGuid)
  }

  public getCancelCommandsGuid () {
    return this.cancelCommandElements.map((item) => item.businessObject.accentGuid)
  }
}
