import RequestRepositoryInterface from '@/services/ApiService/domain/repository/RequestRepositoryInterface'
import AbstractEntitiesQuery from '@/core/application/query/AbstractEntitiesQuery'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'

export default class RequestSchemeEntitiesHandler implements QueryHandlerInterface {
  private repo: RequestRepositoryInterface
  constructor (repo: RequestRepositoryInterface) {
    this.repo = repo
  }

  execute (query: AbstractEntitiesQuery): Promise<any> {
    return this.repo.getRequestSchemeEntities()
  }
}
