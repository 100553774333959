<template>
  <div class="extractor_form">
    <el-form ref="form" v-loading="isLoading" :model="model" :rules="rules" label-position="top" size="mini">
      <el-form-item :label="$locale.etl_editor_v2.labels.name" prop="name">
        <el-input v-model="model.name" :placeholder="$locale.etl_editor_v2.labels.name"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.alias" prop="alias">
        <el-input v-model="model.alias" :placeholder="$locale.etl_editor_v2.labels.alias" size="mini"></el-input>
      </el-form-item>

      <el-form-item :label="$locale.etl_editor_v2.labels.type" prop="extractor_type_id">
        <el-select v-model="model.extractor_type_id" :placeholder="$locale.etl_editor_v2.labels.type" @change="changeType" :disabled="!isNew">
          <el-option
            v-for="item in extractorTypes"
            :key="item.id"
            :label="$locale.etl_editor_v2.types[item.name]"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <properties-form v-if="isLoadedProperties" v-model="model.properties" :properties="extractorTypeProperties"></properties-form>
    </el-form>
  </div>
</template>

<script>
import ExtractorByGuidQuery from '@/services/EtlEditor/application/query/ExtractorByGuidQuery'
import ExtractorTypePropertiesQuery from '@/services/EtlEditor/application/query/ExtractorTypePropertiesQuery'
import ExtractorTypesQuery from '@/services/EtlEditor/application/query/ExtractorTypesQuery'
import Extractor from '@/services/EtlEditor/domain/model/Extractor'
import ExtractorCreateCommand from '@/services/EtlEditor/application/command/ExtractorCreateCommand'
import ExtractorUpdateCommand from '@/services/EtlEditor/application/command/ExtractorUpdateCommand'

import PropertiesForm from '../properties/PropertiesForm'

export default {
  name: 'ExtractorForm',

  props: {
    value: Object
  },

  inject: ['getQueryBus', 'getCommandBus'],

  components: {
    PropertiesForm
  },

  computed: {
    isNew () {
      return typeof this.model.guid === 'undefined' || this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Extractor/isExtractorLoading']
    },

    extractorCreated () {
      return this.$store.getters['Extractor/getExtractorLocation']
    },

    isLoadedProperties () {
      return this.extractorTypeProperties.length > 0
    }
  },

  watch: {
    value () {
      this.model = this.value
    },

    extractorCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ExtractorByGuidQuery(
            location.replace('/extractors/', '')
          )
        ).then((response) => {
          if (response.guid) {
            this.$emit('input', response)
          }
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      rules: {
        name: [
          { required: true, message: this.$locale.etl_editor_v2.rules.name, trigger: 'blur' }
        ],
        extractor_type_id: [
          { required: true, message: this.$locale.etl_editor_v2.rules.type, trigger: 'change' }
        ]
      },

      extractorTypes: [],
      extractorTypeProperties: []
    }
  },

  mounted () {
    if (!this.extractorTypes.length) {
      this.getQueryBus().execute(new ExtractorTypesQuery()).then((response) => {
        if (response.length) {
          this.extractorTypes.push(...response)
        }
      })
    }

    if (!this.extractorTypeProperties.length && this.model.extractor_type_id) {
      this.getQueryBus().execute(new ExtractorTypePropertiesQuery(this.model.extractor_type_id)).then((response) => {
        if (response.length) {
          this.extractorTypeProperties.push(...response)
        }
      })
    }
  },

  methods: {
    changeType (value) {
      this.extractorTypeProperties = []
      this.getQueryBus().execute(new ExtractorTypePropertiesQuery(value)).then((response) => {
        this.model.properties = []

        if (response.length) {
          response.forEach(property => {
            this.model.properties.push({
              id: property.id,
              type: property.type,
              value: this.getDefValue(property)
            })
          })

          this.extractorTypeProperties.push(...response)
        }
      })
    },

    getDefValue (prop) {
      if (['boolean', 'integer', 'array', 'object'].indexOf(prop.primitive_type) !== -1 && prop.default_value !== null) {
        return JSON.parse(prop.default_value)
      } else {
        return prop.default_value
      }
    },

    submit (callback) {
      this.$emit('input', this.model)

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(this.getCommand())
            .then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let model = Extractor.create(this.value)

      if (this.isNew) {
        return new ExtractorCreateCommand(
          model.getName(),
          model.getExtractorTypeId(),
          model.getAlias(),
          model.getProperties(),
          model.getParentTreeElementId()
        )
      }

      return new ExtractorUpdateCommand(
        model.getGuid(),
        model.getName(),
        model.getExtractorTypeId(),
        model.getAlias(),
        model.getProperties()
      )
    }
  }
}
</script>

<style scoped></style>
