import TaskQueryRepositoryInterface from '@/services/EtlEditor/domain/repository/TaskQueryRepositoryInterface'
import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import TaskObjectsQuery from '@/services/EtlEditor/application/query/TaskObjectsQuery'

export default class TaskObjectsHandler implements QueryHandlerInterface {
  private repository: TaskQueryRepositoryInterface;

  constructor (repository: TaskQueryRepositoryInterface) {
    this.repository = repository
  }

  execute (query: TaskObjectsQuery): Promise<any> {
    return this.repository.getTaskObjects(query.getPayload())
  }
}
