import store from '@/store'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import SourceQueryRepositoryInterface from '@/services/NotificationEditor/domain/repository/SourceQueryRepositoryInterface'
import {
  GetSource,
  GetSourceByGuid,
  GetSourceByIdAndType
} from '@/services/NotificationEditor/infrastructure/store/modules/NotificationTreeElement/action-types'

export default class SourceQueryRepository extends AbstractQueryRepository implements SourceQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetSource(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetSourceByGuid(guid))
  }

  getByIdAndType (id: number, type: string): Promise<any> {
    return store.dispatch(new GetSourceByIdAndType(id, type))
  }
}
