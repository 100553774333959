

export default {
  name: "AffineTransformationInformationPanel",
  inject: ['getEventBus', 'getQueryBus'],
  props: {
    affineTransformation: Object, 
    labelPosition: {
      type: String,
      default: 'left'
    }
  }
}
