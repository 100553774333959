import CommandHandlerInterface from "@/core/application/command/CommandHandlerInterface";
import RegionCommandRepositoryInterface
  from "@/services/GarAddressService/domain/repository/RegionCommandRepositoryInterface";
import RegionUpdateCommand from "@/services/GarAddressService/application/command/RegionUpdateCommand";
import RegionDTO from "@/services/GarAddressService/domain/model/Region";

export default class RegionUpdateHandler implements CommandHandlerInterface {
  private repository: RegionCommandRepositoryInterface;

  constructor (repository: RegionCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: RegionUpdateCommand): Promise<void> {
    let region: RegionDTO = {
      code: command.getCode(),
      is_updatable: command.getIsUpdatable(),
      name: command.getName(),
      gar_tables: command.getGarTables()
    }
    return this.repository.update(region)
  }
}
