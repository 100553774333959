import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import ThemeCreateCommand from '@/services/CssEditor/application/command/ThemeCreateCommand'
import ThemeCommandRepositoryInterface from '@/services/CssEditor/domain/repository/ThemeCommandRepositoryInterface'

export default class ThemeCreateCommandHandler implements CommandHandlerInterface {
  private repository: ThemeCommandRepositoryInterface;

  constructor (repository: ThemeCommandRepositoryInterface) {
    this.repository = repository
  }

  execute (command: ThemeCreateCommand): Promise<void> {
    return this.repository.insert(command.getDto())
  }
}
