<template>
  <div class="select-panel" ref="source_select_panel">
    <el-container class="tools">
      <el-button style="display: block;" size="mini" icon="el-icon-plus" circle @click="createSource"></el-button>
      <el-button icon="el-icon-edit" size="mini" circle  @click="editSource"></el-button>
      <el-button icon="el-icon-delete" size="mini" circle  @click="deleteSource"></el-button>
      <el-checkbox v-if="!hideAll" v-model="allSources" :label="$locale.map_editor.source_panel.all_sources"></el-checkbox>
      <el-input
        :placeholder="$locale.map_editor.shared.search_by_name"
        prefix-icon="el-icon-search"
        v-model="searchName"
        @keyup.enter.native="searchByName">
      </el-input>
    </el-container>  
    <el-row style="height:calc(100% - 64px)">
      <el-col style="height: 100%" v-bind:class="{ 'mini': source !== null }">
        <el-table
          :indent="0"
          class="custom_scrollbar"
          height="100%"
          v-loading="loading"
          :data="sources"
          stripe
          border
          ref="sources_table"
          row-key="guid"
          current-row-key="guid"
          highlight-current-row
          @current-change="changeSource"
        >
        <el-table-column
          prop="id"
          :label="$locale.main.fields.id"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$locale.main.fields.name"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="source_type_id"
          :label="$locale.main.fields.type"
        >
          <template slot-scope="scope">
            {{ $locale.map_editor.source_types[scope.row.source_type_id] }}
          </template>
        </el-table-column>
        </el-table>
        <el-footer style="height: 32px">
          <el-pagination
            class="source-pagination"
            :page-size="sourcesPageSize"
            :layout="allSources ? 'total, prev, pager, next' : 'total'"
            :total="sourcesCount"
            @current-change="handleSourcesPageChange"
          ></el-pagination>
        </el-footer>
      </el-col>
      <el-col v-if="source !== null" v-bind:class="{ 'mini': source !== null }">
        <SourceInformationPanel :show-projects="true" label-position="top" :style="{
          'max-height': `${informationPanelHeight}px`,
          'overflow-y': 'auto'
        }" class="information custom_scrollbar" :source="source" />
      </el-col>
    </el-row>     
  </div>
</template>

<script type="ts">
import ProjectSourcesQuery from '@/services/MapEditor/application/query/ProjectSourcesQuery'
import SourcesQuery from '@/services/MapEditor/application/query/SourcesQuery'
import SourcesCountQuery from '@/services/MapEditor/application/query/SourcesCountQuery'
import SourceInformationPanel from '@/services/MapEditor/infrastructure/components/SourceInformationPanel/index.vue'
import Source from '@/services/MapEditor/domain/model/Source/Source'
import SourceDeleteCommand from '@/services/MapEditor/application/command/SourceDeleteCommand'
import ProjectSourceDeleteCommand from '@/services/MapEditor/application/command/ProjectSourceDeleteCommand'

export default {
  name: 'SourceSelectPanel',
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  props: ['formId', 'parentFormId', 'projectGuid', 'hideAll'],
  components: {
    SourceInformationPanel
  },
  data() {
    return {
      loading: false,
      sourcesPageSize: 0,
      defaultSourcesPageSize: 100,
      sourcesCurrentPage: 0,
      sourcesPageLimit: 100,
      sourcesCount: 0,
      allSourcesCount: 0,
      allSources: false,
      sources: [],
      source: null,
      searchName: null,
      informationPanelHeight: 0
    }
  },
  computed: {
    sourceCreated() {
      return this.$store.getters['Source/getLocation'];
    }
  },
  watch: {
    allSources (val) {
      this.source = null;
      this.$refs.sources_table.setCurrentRow(null);
      if (val) {        
        this.sourcesPageSize = this.defaultSourcesPageSize;
      }
      this.loadSources();
    }
  },
  created: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  beforeDestroy: function() {
    window.addEventListener('resize', this.resizePanel);
  },
  methods: {
    resizePanel() {
      if (typeof this.$refs.source_select_panel !== 'undefined') {
        this.informationPanelHeight = this.$refs.source_select_panel.clientHeight - 36;
      }
    },
    changeSource(sourceDto) {
      this.source = Source.create(sourceDto);
    },
    submit(callback) {
      this.getEventBus().$emit('sourceSelected', this.source, this.parentFormId);
      if (typeof callback == 'function') {
        callback();
      }
    },
    createSource() {
      this.getEventBus().$emit('createForm', 'source', {'parentFormId': this.formId, 'sourceCreatedCallback': this.refreshSource});
    },
    editSource() {
      if (this.source == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.getEventBus().$emit('createForm', 'source_edit', {'parentFormId': this.formId, 'source': this.source, 'sourceUpdatedCallback': this.refreshSource});
      }
    },
    async refreshSource(source) {
      await this.loadSources();
      this.selectSource(source);
    },
    deleteSource() {
      if (this.source == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          let command;
          if (this.allSources) {
            command = new SourceDeleteCommand(
              this.source.getGuid()
            );          
          } else {
              command = new ProjectSourceDeleteCommand(
              this.source.getId(),
              this.projectGuid
            );
          }
          this.getCommandBus().execute(
            command
          ).then((response) => {
            this.source = null;
            this.$refs.sources_table.setCurrentRow(null);
            this.loadSources();
          });
        }).catch((error) => { console.log(error); })
      }
    },
    selectSource(source) {
      let rows = this.sources.filter(el => {
        return el.guid == source.getGuid()
      });
      if (rows.length) {
        this.$refs.sources_table.setCurrentRow(rows[0]);
      }
    },
    handleSourcesPageChange(val) {
      val--;
      this.sourcesCurrentPage = (val * this.sourcesPageLimit);
      this.loadSources();
    },
    loadSources(callback) {
      if (!this.allSources) {
        this.loadProjectSources(callback);        
      } else {
        if (this.allSourcesCount == 0) {
          this.countAndLoadSources(callback);
        } else {
          this.loadAllSources(callback);
        }      
      }
    },
    async loadProjectSources(callback) {
      await this.getQueryBus().execute(
        new ProjectSourcesQuery(this.projectGuid)
      ).then(data => {
        this.sourcesPageSize = data.length;
        this.sourcesCount = data.length;
        this.sources = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async countAndLoadSources(callback) {
      await this.getQueryBus().execute(
        new SourcesCountQuery()
      ).then(data => {
        this.sourcesCount = data[0].count;      
        this.loadAllSources(callback);
      });
    },
    async loadAllSources(callback) {
      await this.getQueryBus().execute(
        new SourcesQuery({
          limit: this.sourcesPageLimit,
          offset: this.sourcesCurrentPage
        })
      ).then(data => {
        this.sourcesPageSize = this.sourcesPageLimit;
        this.sources = data;
        if (typeof callback == 'function') {
          callback();
        }
      });
    },
    async searchByName() {
      let payload = {};
      let query;

      if (this.searchName != null && this.searchName != "") {
        if (this.allSources) {
          payload["where"] = {
            "and": [
              {
                "like": { "name": `%${this.searchName}%`}
              }
            ]
          };
        } else {
          payload["where"] = {
            "and": [
              {
                "like": { "c.name": `%${this.searchName}%`}
              }
            ]
          };
        }
      }
      if (this.allSources) {
        payload["limit"] = this.sourcesPageLimit;
        payload["offset"] = this.sourcesCurrentPage;
        query = new SourcesQuery(payload);
      } else {
        query = new ProjectSourcesQuery(this.projectGuid, payload);
      }

      await this.getQueryBus().execute(query).then(data => {
        this.sourcesCount = data.length;
        this.sources = data;
      });
    } 
  },
  mounted() {
    this.loadSources(() => {
      this.resizePanel();
    });   
  }
}
</script>