import QueryInterface from '@/core/application/query/QueryInterface'

export default class EventsTreeQuery implements QueryInterface
{
    private guid: string;

    constructor(guid: string)
    {
        this.guid = guid;
    }

    getClassName(): string
    {
        return 'EventsTreeQuery';
    }

    getGuid(): string
    {
        return this.guid;
    }
}
