import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'
import StateCreateCommand from '@/services/LogicEditor/application/command/StateCreateCommand'
import StateCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/StateCommandRepositoryInterface'

export default class StateCreateHandler implements CommandHandlerInterface {
    private repository: StateCommandRepositoryInterface;

    constructor (repository: StateCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: StateCreateCommand): Promise<void> {
      let item: StateDTO = {
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        is_complex: command.getIsComplex(),
        properties: command.getProperties(),
        state_fields: command.getStateFields(),
        logic_id: command.getLogicId(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
