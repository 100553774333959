import LogicGroupCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/LogicGroupCommandRepositoryInterface'
import store from '@/store'
import { CreateLogicGroup, UpdateLogicGroup, DeleteLogicGroup } from '@/services/LogicEditor/infrastructure/store/modules/LogicGroup/action-types'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'

export default class LogicGroupCommandRepository implements LogicGroupCommandRepositoryInterface {
  insert (item: LogicGroupDTO): Promise<void> {
    return store.dispatch(new CreateLogicGroup(item))
  }

  update (item: LogicGroupDTO): Promise<void> {
    return store.dispatch(new UpdateLogicGroup(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteLogicGroup(guid))
  }
}
