import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { TransformerDTO } from '@/services/EtlEditor/domain/model/Transformer'
import TransformerCreateCommand from '@/services/EtlEditor/application/command/TransformerCreateCommand'
import TransformerCommandRepositoryInterface from '@/services/EtlEditor/domain/repository/TransformerCommandRepositoryInterface'

export default class TransformerCreateHandler implements CommandHandlerInterface {
    private repository: TransformerCommandRepositoryInterface;

    constructor (repository: TransformerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: TransformerCreateCommand): Promise<void> {
      let item: TransformerDTO = {
        name: command.getName(),
        transformer_type_id: command.getTransformerTypeId(),
        alias: command.getAlias(),
        properties: command.getProperties(),
        parent_tree_element_id: command.getParentTreeElementId()
      }

      return this.repository.insert(item)
    }
}
