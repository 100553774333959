<template>
  <div class="raster-form custom_scrollbar">
    <el-form :rules="formRules" id="form" ref="form" v-if="dto" class="raster-form-inner-body" :model="dto">
      <el-card class="box-card">
        <el-form-item :label="$locale.raster_library.name">
          <el-input v-model="dto.name"></el-input>        
        </el-form-item>
        <el-form-item :label="$locale.raster_library.description">
          <el-input v-model="dto.description"></el-input>        
        </el-form-item>
        <div v-if="this.type == 'add'" style="text-align: center;">  
          <span>{{$locale.raster_library.files}}</span>
          <el-upload
            ref="upload"
            action=""
            list-type="picture-card"
            :http-request="submit"
            :on-change="uploadChange"
            :file-list="fileList"
            :auto-upload="false"
          />
        </div>
      </el-card> 
    </el-form>
    <el-footer>
      <el-button @click="submit">
        <span v-if="loading"><i class="el-icon-loading"></i></span>
        <span v-else>{{$locale.main.button.save}}</span>
      </el-button>
      <el-button @click="close">{{$locale.main.button.cancel}}</el-button>
    </el-footer>
  </div>
</template>

<script type="ts">
import Raster, { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'
import RasterCreateCommand from '@/services/RasterLibrary/application/command/RasterCreateCommand'
import RasterUpdateCommand from '@/services/RasterLibrary/application/command/RasterUpdateCommand'
import RasterByGuidQuery from '@/services/RasterLibrary/application/query/RasterByGuidQuery'

export default {
  name: 'RasterForm',
  props: ['type', 'raster'],
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  watch: {
    rasterCreated: function (location) {
      this.getQueryBus().execute(new RasterByGuidQuery(location.replace('/rasters/', '')))
      .then((rasterDto) => {
        const raster = Raster.create(rasterDto);
        if (typeof this.rasterCreatedCallback == 'function') {
          this.rasterCreatedCallback(raster);
        }
        this.getEventBus().$emit('rasterCreated', raster);        
      });
    }
  },
  data() {
    return {
      dto: null,
      fileList: [],
      fileName: null,
      loading: false,
      formRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: ['change', 'blur']
        }
      }
    }
  },
  computed: {
    rasterCreated() {
      return this.$store.getters['Raster/getLocation'];
    }
  },
  methods: {
    uploadChange(file, fileList) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png' && file.raw.type !== 'image/tiff') {
        fileList.splice(fileList.length-1, 1);
        this.$message.error(this.$locale.raster_library.error_mime_type);
        return false;
      }
    },
    submit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.type == 'edit') {
            if (!this.dto.guid) {
                return;
            }
            this.loading = true;
            this.getCommandBus().execute(
              new RasterUpdateCommand(
                this.dto.guid,
                this.dto.name,
                this.dto.description
              )
            )
            .then((res) => {
              this.loading = false;
              this.getEventBus().$emit('RasterUpdated', this.raster);
              this.getEventBus().$emit('loadRasters');
            })
            .catch((reason) => {
              this.showError(reason);
              this.loading = false;   
            });;
          } else {
            const files = this.$refs.upload.uploadFiles;
            if (files.length == 0) {
              this.$message.error(this.$locale.raster_library.error_file_not_attached);
              return;  
            }
            const formData = new FormData();
            formData.append('name', this.dto.name);
            formData.append('description', this.dto.description);
            formData.append('file', files[0].raw);
            /*
            if (files.length > 0) {
              files.forEach((file, index) => {
                formData.append('file', file.raw);
              });
            } 
            */
            this.loading = true;
            this.getCommandBus().execute(new RasterCreateCommand(formData))
            .then((response) => {
              this.loading = false;
              this.getEventBus().$emit('loadRasters', true);
            })
            .catch((reason) => {
              this.showError(reason);
              this.loading = false;    
            });
          }
          this.close();
        } else {
          this.$message.error(this.$locale.raster_library.error_empty_fields);  
        }
      });
    },
    close() {
      this.getEventBus().$emit('showRasterForm', false);
    },
    showError(error) {
      if (error && error.parameters) {
        this.$message.error('Произошли следующие ошибки ' + error.parameters.join(', '));
      }
    }
  },
  mounted() {
    if (this.type == 'edit') {
      if (typeof this.raster !== 'undefined') {
        this.dto = new RasterDTO({
          guid: this.raster.getGuid(),
          name: this.raster.getName(),
          description: this.raster.getDescription()
        });
      }
    } else {      
      this.dto = new RasterDTO({});
    }
  }
}
</script>