<template>
  <div style="padding-top: 15px" class="etl_editor">
    <el-container>
      <el-header height="">{{ $locale.etl_editor.header.title }}</el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="35">
            <el-container>
              <el-scrollbar style="height: calc(100vh - 96px);width: 100%;">
                <tree-source
                  ref="source"
                  :external-task-id="externalTaskId"
                  class="tree-source"
                  :add-entity="addEntity"
                  :edit-entity="editEntity"
                  :delete-entity="deleteEntity"
                  :view-log="viewLog" />
              </el-scrollbar>
            </el-container>
          </SplitArea>
          <SplitArea :size="65">
            <el-main>
              <div v-show="editorModel.title" class="panel-title">{{ editorModel.title }}</div>

              <component
                v-if="editorModel.component"
                :key="generateGuid()"
                :ref="editorModel.component"
                v-model="editorModel.model"
                :is="editorModel.component"
                :cancel="cancel"
                :save="save">
                <div class="el-form-footer">
                  <el-form-item>
                    <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.cancel" placement="top">
                      <el-button type="default" @click="cancel" size="small">
                        <span class="el-icon-close"></span> {{ $locale.main.button.cancel }}
                      </el-button>
                    </el-tooltip>
                    <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.etl_editor.tooltip.save" placement="top">
                      <el-button style="float: right;" type="primary" @click="submit" size="small">
                        <span class="el-icon-success"></span> {{ $locale.main.button.save }}
                      </el-button>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </component>
            </el-main>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>

    <import-log-dialog ref="log" :show="importLogViewId !== null" :close="closeLog" />
  </div>
</template>

<script>
import Task from './Models/Task'
import Extractor from './Models/Extractor'
import Loader from './Models/Loader'
import Template from './Models/Template'
import Transformer from './Models/Transformer'

import TreeSource from './render/TreeSource'

import TaskCreate from './render/forms/TaskCreate'
import ExtractorCreate from './render/forms/ExtractorCreate'
import LoaderCreate from './render/forms/LoaderCreate'
import TemplateCreate from './render/forms/TemplateCreate'
import TransformerCreate from './render/forms/TransformerCreate'

import TaskUpdate from './render/forms/TaskUpdate'
import LoaderUpdate from './render/forms/LoaderUpdate'
import ExtractorUpdate from './render/forms/ExtractorUpdate'
import TemplateUpdate from './render/forms/TemplateUpdate'
import TransformerUpdate from './render/forms/TransformerUpdate'

import ImportLogDialog from './render/ImportLogDialog'

import Panel from '@/components/Common/Panel'

export default {
  name: 'EtlEditor',

  components: {
    TreeSource,

    TaskCreate,
    ExtractorCreate,
    LoaderCreate,
    TemplateCreate,
    TransformerCreate,

    TaskUpdate,
    LoaderUpdate,
    ExtractorUpdate,
    TemplateUpdate,
    TransformerUpdate,

    ImportLogDialog,

    Panel
  },

  props: {
    externalTaskId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      models: {
        task: Task,
        loader: Loader,
        extractor: Extractor,
        template: Template,
        transformer: Transformer
      },

      createForm: {
        task: 'TaskCreate',
        loader: 'LoaderCreate',
        extractor: 'ExtractorCreate',
        template: 'TemplateCreate',
        transformer: 'TransformerCreate'
      },

      editForm: {
        task: 'TaskUpdate',
        loader: 'LoaderUpdate',
        extractor: 'ExtractorUpdate',
        template: 'TemplateUpdate',
        transformer: 'TransformerUpdate'
      },

      editorModel: {
        isNew: true,
        component: null,
        title: null,
        model: null,
        node: null
      },

      defaultModel: {
        loader: {
          name: null,
          loader_type_id: null
        },
        extractor: {
          name: null,
          extractor_type_id: null
        },
        task: {
          name: null,
          loader_id: null,
          extractor_id: null,
          code: null
        },
        template: {
          name: null,
          file_id: null
        },
        transformer: {
          name: null,
          transformer_type_id: null
        }
      },

      typeProperty: {
        extractor: 'extractor_type_id',
        loader: 'loader_type_id',
        transformer: 'transformer_type_id'
      },

      importLogViewId: null
    }
  },

  methods: {
    submit () {
      this.$refs[this.editorModel.component].submit(this.save)
    },

    async save () {
      let node = this.editorModel.node

      if (this.editorModel.isNew) {
        if (['loader', 'extractor', 'transformer'].indexOf(node.data.model) !== -1) {
          this.editorModel.model[this.typeProperty[node.data.model]] = node.data.type
          this.editorModel.model.properties = {}
        }

        let response = await this.editorModel.model.save()

        this.$refs.source.addNode(node, response)

        this.$nextTick(() => {
          let tree = this.$refs.source.getTree()
          let newNode = tree.getNode(response.id)

          this.$refs.source.selectEntity(newNode, newNode.data)
        })
      } else {
        let response = await this.editorModel.model.save()

        node.data.name = response.name
      }
    },

    cancel () {
      this.editorModel = {
        isNew: true,
        component: null,
        title: null,
        model: null,
        node: null
      }
    },

    addEntity (node, data) {
      this.editorModel.isNew = true
      this.editorModel.title = this.$locale.etl_editor.form.title[`create_${data.model}`]
      this.editorModel.component = this.createForm[data.model]
      this.editorModel.model = new this.models[data.model](this.defaultModel[data.model])
      this.editorModel.node = node
    },

    async editEntity (node, data) {
      if (this.editorModel.node !== node) {
        this.editorModel.isNew = false
        this.editorModel.title = this.$locale.etl_editor.form.title[`edit_${data.model}`]
        this.editorModel.model = await this.models[data.model].find(data.id)

        if (['loader', 'extractor', 'transformer'].indexOf(data.model) !== -1) {
          this.editorModel.model.properties.forEach((item) => {
            if (['integer', 'template', 'object', 'query', 'field', 'topic', 'coordinate_system', 'procedure'].indexOf(item.type) !== -1) {
              item.value = item.value !== null ? (typeof item.value === 'string' ? parseInt(item.value) : item.value) : null
            } else if (['field_multi', 'boolean'].indexOf(item.type) !== -1) {
              item.value = item.value !== null ? (typeof item.value === 'string' ? JSON.parse(item.value) : item.value) : null
            }
          })
        }

        this.editorModel.component = this.editForm[data.model]
        this.editorModel.node = node
      }
    },

    deleteEntity (node, data) {
      this.$confirm(this.$locale.main.message.delete, this.$locale.main.message_title.warning, {
        confirmButtonText: this.$locale.main.button.ok,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        let model = await this.models[data.model].find(data.id)

        if (this.editorModel.model.guid === model.guid) {
          this.cancel()
        }

        await model.delete()

        this.$refs.source.deleteNode(node)
      })
    },

    viewLog (data) {
      this.importLogViewId = data.id
      this.$refs.log.load(data.id)
    },

    closeLog () {
      this.importLogViewId = null
    }
  }
}
</script>

<style type="text/css">
.etl_editor .title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-left: 24px;
  margin-bottom: 14px;
  height: 28px;
  color: #2C2D35;
}

.etl_editor .el-menu {
  margin-left: 5px;
}

.etl_editor .el-menu-item,
.etl_editor .el-submenu__title {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 30px;
}

.etl_editor .el-submenu__title:hover .hover {
  display: inline-block;
}

.etl_editor .hover {
  display: none;
}

.etl_editor .etl_icon2 {
  float: right;
  line-height: 40px;
  margin-right: 10%;
}

.etl_editor .panel-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 20px;
  margin: -20px -20px 0 -20px;
  color: #616161;
  border-bottom: 1px solid #e6e6e6;
}

.etl_editor .table_mapping:not(.full_screen),
.etl_editor .tree_mapping:not(.full_screen),
.etl_editor .el-form-footer {
  margin-left: -20px;
  margin-right: -20px;
}

.etl_editor .el-header {
  border-bottom: 1px solid #efefef;
  font-size: 24px;
  font-weight: 700;
  padding: 0px 20px 10px 20px;
}

.etl_editor .el-scrollbar__wrap {
  overflow-x: hidden;
}

.etl_editor .el-main {
  border-left: 1px solid #efefef;
  padding-bottom: 0;
}

.etl_editor .el-main-menu {
  overflow: inherit;
}

.el-loader {
  background-color: #fff;
  position: absolute;
  left: 0;
  margin-left: 5px;
  margin-top: -20px;
  width: calc(100% - 6px);
  height: 100%;
}

.el-loader .el-loader-icon {
  text-align: center;
  width: 50px;
  margin: calc(45vh - (50px / 2)) auto;
}

.el-loader i {
  font-size: 38px;
  color: #ccc;
}

.etl_editor .el-form--label-top .el-form-item__label {
  padding: 0 0 0;
}

.etl_editor .btn-mapping {
  margin-top: 8px;
  margin-right: 30px;
  right: 0;
  position: absolute;
}

.etl_editor .el-collapse {
  margin-left: -20px;
  margin-right: -20px;
}

.etl_editor .el-collapse-item__title {
  font-size: 16px;
  padding: 0 20px;
  color: #7d7d7d;
  display: block;
  width: 100%;
  margin-right: -20px;
  border-bottom: 1px solid #EBEEF5;
}

.etl_editor .el-collapse-item__title.is-active {
  font-weight: 600;
  border-bottom-color: #EBEEF5;
}

.etl_editor .el-collapse-item__content .el-form {
  padding: 0 20px;
}

.etl_editor .panel-scroll {
  height: calc(100vh - 479px); /* 255px */
  /*margin-left: -20px;
  margin-right: -20px;*/
}

.etl_editor .panel-scroll .el-scrollbar__bar.is-vertical {
  z-index: 1001;
}

.etl_editor .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.etl_editor .tree-source .el-tree {
  border: none;
}

.etl_editor .el-form-footer {
  border-top: 1px solid #e6e6e6;
  padding: 15px;
}
.etl_editor .el-form-footer .el-form-item {
  margin: 0;
}
.etl_editor .el-select,
.etl_editor .el-input,
.etl_editor .el-input-number {
  width: 100%;
}
.etl_editor .form_scrollbar {
  height: calc(100vh - 270px);
}
.etl_editor .modal_form {
  padding: 15px;
}
.etl_editor .form_scrollbar_modal {
  height: 400px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

/* For task forms */
.etl_editor .task_form .el-select {
  width: auto;
}
</style>
